import {
  GET_UNIT_MASTER_LIST_SUCCESS,
  GET_UNIT_MASTER_LIST_FAIL,
  GET_UNIT_MASTER_DETAIL_SUCCESS,
  GET_UNIT_MASTER_DETAIL_FAIL,
  ADD_NEW_UNIT_MASTER_SUCCESS,
  ADD_NEW_UNIT_MASTER_FAIL,
  UPDATE_UNIT_MASTER_SUCCESS,
  UPDATE_UNIT_MASTER_FAIL,
  DELETE_UNIT_MASTER_SUCCESS,
  DELETE_UNIT_MASTER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  unit: [],
  addUnit: [],
  unitDetail: {},
  error: {},
  loading: true,
}

const unitMaster = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UNIT_MASTER_LIST_SUCCESS:
      return {
        ...state,
        unit: action.payload,
        loading: false,
      }

    case GET_UNIT_MASTER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_UNIT_MASTER_DETAIL_SUCCESS:
      return {
        ...state,
        unitDetail: action.payload,
        loading: false,
      }

    case GET_UNIT_MASTER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_UNIT_MASTER_SUCCESS:
      return {
        ...state,
        addUnit: [...state.addUnit, action.payload],
      }

    case ADD_NEW_UNIT_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_UNIT_MASTER_SUCCESS:
      return {
        ...state,
        addUnit: state.addUnit.map(unit =>
          unit.id === action.payload.id ? { ...unit, ...action.payload } : unit
        ),
      }

    case UPDATE_UNIT_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_UNIT_MASTER_SUCCESS:
      return {
        ...state,
        unit: state.unit.data.filter(
          unit => unit.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_UNIT_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default unitMaster
