import {
  GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_FAIL,
  GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_SUCCESS,
  GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_FAIL,
  GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_SUCCESS,
  GET_REPORT_FOR_CUSTOMER_OUTSTANDING_FAIL,
  GET_REPORT_FOR_CUSTOMER_OUTSTANDING_SUCCESS,
  GET_LEDGER_DETAIL_FAIL,
  GET_LEDGER_DETAIL_SUCCESS,
  GET_FINANCIAL_YEAR_FOR_OUTSTANDING_FAIL,
  GET_FINANCIAL_YEAR_FOR_OUTSTANDING_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  allProjects: [],
  allCompanies: [],
  financialYear: [],
  projectOccupancyDetail: {},
  outstandingReport: {},
  ledgerData: {},
  error: {},
  loading: true,
}

const oustandingReports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_SUCCESS:
      return {
        ...state,
        allProjects: action.payload,
        loading: false,
        // loading: true
      }

    case GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LEDGER_DETAIL_SUCCESS:
      return {
        ...state,
        ledgerData: action.payload,
      }

    case GET_LEDGER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_SUCCESS:
      return {
        ...state,
        allCompanies: action.payload,
        loading: false,
        // loading: true
      }

    case GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_REPORT_FOR_CUSTOMER_OUTSTANDING_SUCCESS:
      return {
        ...state,
        outstandingReport: action.payload,
      }

    case GET_REPORT_FOR_CUSTOMER_OUTSTANDING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_FINANCIAL_YEAR_FOR_OUTSTANDING_SUCCESS:
      return {
        ...state,
        financialYear: action.payload,
        loading: false,
        // loading: true
      }

    case GET_FINANCIAL_YEAR_FOR_OUTSTANDING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default oustandingReports
