import {
  GET_RENEWALS,
  GET_RENEWALS_FAIL,
  GET_RENEWALS_SUCCESS,
  GET_RENEWAL_DETAIL,
  ADD_NEW_RENEWAL,
  ADD_RENEWAL_SUCCESS,
  ADD_RENEWAL_FAIL,
  UPDATE_RENEWAL,
  UPDATE_RENEWAL_SUCCESS,
  UPDATE_RENEWAL_FAIL,
  DECLINE_RENEWAL,
  DECLINE_RENEWAL_SUCCESS,
  DECLINE_RENEWAL_FAIL,
  GET_RENEWAL_DETAIL_FAIL,
  GET_RENEWAL_DETAIL_SUCCESS,
} from "./actionTypes"

export const getRenewals = (id) => ({
  type: GET_RENEWALS,
  id
})

export const getRenewalsSuccess = renewals => ({
  type: GET_RENEWALS_SUCCESS,
  payload: renewals,
})

export const addNewRenewal = (renewal, history) => ({
  type: ADD_NEW_RENEWAL,
  payload: { renewal, history },
})

export const addRenewalSuccess = renewal => ({
  type: ADD_RENEWAL_SUCCESS,
  payload: renewal,
})

export const addRenewalFail = error => ({
  type: ADD_RENEWAL_FAIL,
  payload: error,
})

export const updateRenewal = (id, renewal, history) => ({
  type: UPDATE_RENEWAL,
  payload: { id, renewal, history },
})

export const updateRenewalSuccess = renewal => ({
  type: UPDATE_RENEWAL_SUCCESS,
  payload: renewal,
})

export const updateRenewalFail = error => ({
  type: UPDATE_RENEWAL_FAIL,
  payload: error,
})

export const deleteRenewal = renewal => ({
  type: DECLINE_RENEWAL,
  payload: renewal,
})

export const deleteRenewalSuccess = renewal => ({
  type: DECLINE_RENEWAL_SUCCESS,
  payload: renewal,
})

export const deleteRenewalFail = error => ({
  type: DECLINE_RENEWAL_FAIL,
  payload: error,
})

export const getRenewalsFail = error => ({
  type: GET_RENEWALS_FAIL,
  payload: error,
})

export const getRenewalDetail = renewalId => ({
  type: GET_RENEWAL_DETAIL,
  renewalId,
})

export const getRenewalDetailSuccess = renewalDetails => ({
  type: GET_RENEWAL_DETAIL_SUCCESS,
  payload: renewalDetails,
})

export const getRenewalDetailFail = error => ({
  type: GET_RENEWAL_DETAIL_FAIL,
  payload: error,
})
