import React, { useEffect, useState } from "react"
import SimpleBar from "simplebar-react"

import { Card, Col, Container, Row, CardBody, CardTitle } from "reactstrap"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Spinners from "components/Common/Spinner"
import TypeOfRequest from "./Components/typeOfRequest"
import PieChart from "./Components/pieChart"
import OpenCloseTicketGraph from "./Components/openCloseTicketGraph"
import LatestTranaction from "./Components/LatestTranaction"
import MostTicketsCount from "./Components/mostTicketsCount"

const TicketsDashboard = () => {
  // const path = useLocation()
  // const dispatch = useDispatch()
  const navigate = useNavigate()
  // const serviceRequestDetailState = state =>
  //   state.serviceRequests.serviceRequestDetail.service_request
  // const loadingState = state => state.serviceRequests.loading
  // const loading = useSelector(loadingState) || []
  // const [isLoading, setLoading] = useState(loading)
  // const data = useSelector(serviceRequestDetailState) || []
  // const [serviceRequestId, setServiceRequestId] = useState()

  // const fetchData = () => {
  //   const serviceRequestId = path.pathname.split("/view-service-requests/")[1]
  //   setServiceRequestId(serviceRequestId)
  //   dispatch(getServiceRequestDetail(serviceRequestId))
  // }

  // useEffect(() => {
  //   fetchData()
  // }, [dispatch, useLocation])

  return (
    <React.Fragment>
      {/* {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : ( */}
      <div className="page-content ">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={12}>
              <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 ">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  Tickets Dashboard
                </div>
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <TypeOfRequest></TypeOfRequest>
          </Row>
          <Row>
            <OpenCloseTicketGraph></OpenCloseTicketGraph>
          </Row>
          <Row>
            <Col xs="6">
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardBody style={{ flex: 1 }}>
                  <PieChart />
                </CardBody>
              </Card>
            </Col>
            <Col xs="6">
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardBody style={{ flex: 1 }}>
                  <MostTicketsCount />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <LatestTranaction />
          </Row>
        </Container>
      </div>
      {/* )} */}
    </React.Fragment>
  )
}

export default TicketsDashboard
