import React, { useEffect, useState } from "react"
import { Col, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

//flatpickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"
import { Link } from "react-router-dom"
import { getProjectFilterForCustomerIndex, getServiceRequestTicketList } from "store/actions"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  const serviceTicketListState = state => state.ServiceTicket.serviceTicket.data
  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const ticketList = useSelector(serviceTicketListState) || []
  const allProjectState = state => state.CustomerIndex.projectFilter.project
  const projectList = useSelector(allProjectState) || []
  const [statusValue, setStatusValue] = useState('')
  const [project, setProject] = useState('')

  const handleSelectStatus = ele => {
    setStatusValue(ele.value || "")
  }
 
  const handleProject = ele => {
    let data = ele.value
    setProject(data || "")
  }
 
  const handleSearch = () => {
    const value = {
      project_id: project,
      user_id: user.user_id,
      status: statusValue,
    }
    dispatch(getServiceRequestTicketList(value))
  }

  useEffect(() => {
    if (user) {
      dispatch(getProjectFilterForCustomerIndex(user.user_id))
    }
  }, [dispatch, user])

  return (
    <React.Fragment>
      <Col xxl={2} lg={6}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Status"
          onChange={e => handleSelectStatus(e.target)}
        >
          <option disabled>Status</option>
          <option value="">All</option>
          <option value="1">New</option>
          <option value="2">Assigned</option>
          <option value="3">Submitted for Approval</option>
          <option value="4">Work in Progress</option>
          <option value="5">Under Review</option>
          <option value="6">Rework</option>
          <option value="7">Closed</option>
          <option value="8">PO Stage</option>
          <option value="9">Approved By Project Manager</option>
          <option value="10">Additional Requests</option>
          <option value="11">Estimate Rejected</option>
          <option value="12">Sent to Project Manager for Approval</option>
        </select>
      </Col>
      <Col xxl={2} lg={4}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Project"
          onChange={e => handleProject(e.target)}
        >
          <option value="">Select Project</option>
          {projectList &&
            projectList.map((project, index) => (
              <option key={index} value={project.id}>
                {project.project_name}
              </option>
            ))}
        </select>
      </Col>
    
      
      <Col xxl={2} lg={4}>
        <div className="mb-3 mb-xxl-0">
          <button type="button" className="btn btn-soft-secondary w-100" onClick={handleSearch}>
            <i className="mdi mdi-filter-outline align-middle"></i> Submit
          </button>
        </div>
      </Col>
    
    </React.Fragment>
  )
}
export default JobListGlobalFilter
