import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PROJECTS,
  GET_PROJECT_DETAIL,
  ADD_NEW_PROJECT,
  ADD_NEW_PROJECT_UNIT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  GET_PROJECT_NAME,
  GET_GALLERY,
  ADD_GALLERY,
  UPDATE_GALLERY,
  DELETE_GALLERY,
  GET_PROJECT_UNIT,
  UPDATE_PROJECT_UNIT,
  DELETE_PROJECT_UNIT,
  GET_PROJECT_UNIT_DETAIL,
  GET_VIDEO,
  GET_VIDEO_DETAIL,
  ADD_VIDEO,
  DELETE_VIDEO,
  GET_PROJECT_MANAGER,
  GET_FACILITIES_AVAILABLE,
  GET_COMPANY_FOR_PROJECT,
  GET_COMPANY_DETAIL_FOR_PROJECT,
  GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT,
  GET_COMPANY_LOCATION_FOR_PROJECT,
} from "./actionTypes"
import {
  getProjectsSuccess,
  getProjectsFail,
  getProjectNameSuccess,
  getProjectNameFail,
  getProjectDetailSuccess,
  getProjectDetailFail,
  addProjectFail,
  addProjectSuccess,
  addProjectUnitSuccess,
  addProjectUnitFail,
  updateProjectSuccess,
  updateProjectFail,
  deleteProjectSuccess,
  deleteProjectFail,
  getGallerySuccess,
  getGalleryFail,
  addGallerySuccess,
  addGalleryFail,
  updateGallerySuccess,
  updateGalleryFail,
  deleteGallerySuccess,
  deleteGalleryFail,
  getProjectUnitSuccess,
  getProjectUnitFail,
  updateProjectUnitSuccess,
  updateProjectUnitFail,
  deleteProjectUnitSuccess,
  deleteProjectUnitFail,
  getProjectUnitDetailSuccess,
  getProjectUnitDetailFail,
  getVideoDetailSuccess,
  getVideoDetailFail,
  getVideoSuccess,
  getVideoFail,
  addVideoSuccess,
  addVideoFail,
  deleteVideoSuccess,
  deleteVideoFail,
  getProjectManagerSuccess,
  getProjectManagerFail,
  getFacilitiesAvailableSuccess,
  getFacilitiesAvailableFail,
  getCompanyForProjectSuccess,
  getCompanyForProjectFail,
  getCompanyDetailForProjectSuccess,
  getCompanyDetailForProjectFail,
  getCompanyLocationForProjectSuccess,
  getCompanyLocationForProjectFail,
  getCompanyLocationDetailforProjectSuccess,
  getCompanyLocationDetailforProjectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProjects,
  getProjectName,
  getProjectDetails,
  addNewProject,
  addNewProjectUnit,
  updateProject,
  deleteProject,
  getGallery,
  addGallery,
  updateGallery,
  deleteGallery,
  getProjectUnit,
  updateProjectUnit,
  deleteProjectUnit,
  getProjectUnitDetail,
  getVideo,
  getVideoDetail,
  addVideo,
  deleteVideo,
  getProjectManager,
  getFacilitiesAvailable,
  getCompanyForProject,
  getCompanyDetailForProject,
  getCompanyLocationForProject,
  getCompanyLocationDetailForProject,
} from "helpers/fakebackend_helper"

function* fetchProjects({ id }) {
  try {
    const response = yield call(getProjects, id)
    yield put(getProjectsSuccess(response))
  } catch (error) {
    yield put(getProjectsFail(error))
  }
}
function* fetchProjectName({ id }) {
  try {
    const response = yield call(getProjectName, id)
    yield put(getProjectNameSuccess(response))
  } catch (error) {
    yield put(getProjectNameFail(error))
  }
}

function* fetchProjectDetail({ projectId }) {
  try {
    const response = yield call(getProjectDetails, projectId)
    yield put(getProjectDetailSuccess(response))
  } catch (error) {
    yield put(getProjectDetailFail(error))
  }
}

function* onUpdateProject({ payload: { id, project } }) {
  try {
    const response = yield call(updateProject, id, project)
    yield put(updateProjectSuccess(response))
  } catch (error) {
    yield put(updateProjectFail(error))
  }
}

function* onDeleteProject({ payload: project }) {
  try {
    const response = yield call(deleteProject, project)
    yield put(deleteProjectSuccess(response))
    // toast.success("Project Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteProjectFail(error))
    // toast.error("Project Delete Failded", { autoClose: 2000 })
  }
}

function* onAddNewProject({ payload: project }) {
  try {
    const response = yield call(addNewProject, project)
    yield put(addProjectSuccess(response))
  } catch (error) {
    yield put(addProjectFail(error))
  }
}

function* onAddNewProjectUnit({ payload: project }) {
  try {
    const response = yield call(addNewProjectUnit, project)
    yield put(addProjectUnitSuccess(response))
  } catch (error) {
    yield put(addProjectUnitFail(error))
  }
}

function* fetchGallery({ userId }) {
  try {
    const response = yield call(getGallery, userId)
    yield put(getGallerySuccess(response))
  } catch (error) {
    yield put(getGalleryFail(error))
  }
}

function* onAddGallery({ payload: user }) {
  try {
    const response = yield call(addGallery, user)
    yield put(addGallerySuccess(response))
  } catch (error) {
    yield put(addGalleryFail(error))
  }
}

function* onUpdateGallery({ payload: { id, user } }) {
  try {
    const response = yield call(updateGallery, id, user)
    yield put(updateGallerySuccess(response))
    // toast.success("User Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateGalleryFail(error))
    // toast.error("User Update Failded", { autoClose: 2000 })
  }
}

function* onDeleteGallery({ gallery }) {
  try {
    const response = yield call(deleteGallery, gallery)
    yield put(deleteGallerySuccess(response))
    // toast.success("User Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteGalleryFail(error))
    // toast.error("User Delete Failded", { autoClose: 2000 })
  }
}

function* fetchProjectUnit({ userId }) {
  try {
    const response = yield call(getProjectUnit, userId)
    yield put(getProjectUnitSuccess(response))
  } catch (error) {
    yield put(getProjectUnitFail(error))
  }
}

function* onUpdateProjectUnit({ payload: { id, user } }) {
  try {
    const response = yield call(updateProjectUnit, id, user)
    yield put(updateProjectUnitSuccess(response))
    // toast.success("User Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateProjectUnitFail(error))
    // toast.error("User Update Failded", { autoClose: 2000 })
  }
}

function* onDeleteProjectUnit({ payload: user }) {
  try {
    const response = yield call(deleteProjectUnit, user)
    yield put(deleteProjectUnitSuccess(response))
    // toast.success("User Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteProjectUnitFail(error))
    // toast.error("User Delete Failded", { autoClose: 2000 })
  }
}

function* fetchProjectUnitDetail({ userId }) {
  try {
    const response = yield call(getProjectUnitDetail, userId)
    yield put(getProjectUnitDetailSuccess(response))
  } catch (error) {
    yield put(getProjectUnitDetailFail(error))
  }
}

//VIDEO

function* fetchVideo({ userId }) {
  try {
    const response = yield call(getVideo, userId)
    yield put(getVideoSuccess(response))
  } catch (error) {
    yield put(getVideoFail(error))
  }
}

function* fetchVideoDetail({ userId }) {
  try {
    const response = yield call(getVideoDetail, userId)
    yield put(getVideoDetailSuccess(response))
  } catch (error) {
    yield put(getVideoDetailFail(error))
  }
}

function* onAddVideo({ payload: user }) {
  try {
    const response = yield call(addVideo, user)
    yield put(addVideoSuccess(response))
    // toast.success("User Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addVideoFail(error))
    // toast.error("User Add Failded", { autoClose: 2000 })
  }
}

function* onDeleteVideo({ gallery }) {
  try {
    const response = yield call(deleteVideo, gallery)
    yield put(deleteVideoSuccess(response))
    // toast.success("User Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteVideoFail(error))
    // toast.error("User Delete Failded", { autoClose: 2000 })
  }
}

function* fetchProjectManager() {
  try {
    const response = yield call(getProjectManager)
    yield put(getProjectManagerSuccess(response))
  } catch (error) {
    yield put(getProjectManagerFail(error))
  }
}

function* fetchFacilitiesAvailable() {
  try {
    const response = yield call(getFacilitiesAvailable)
    yield put(getFacilitiesAvailableSuccess(response))
  } catch (error) {
    yield put(getFacilitiesAvailableFail(error))
  }
}

function* fetchCompanyForProject() {
  try {
    const response = yield call(getCompanyForProject)
    yield put(getCompanyForProjectSuccess(response))
  } catch (error) {
    yield put(getCompanyForProjectFail(error))
  }
}

function* fetchCompanyDetailForProject({ companyId }) {
  try {
    const response = yield call(getCompanyDetailForProject, companyId)
    yield put(getCompanyDetailForProjectSuccess(response))
  } catch (error) {
    yield put(getCompanyDetailForProjectFail(error))
  }
}

function* fetchCompanyLocationForProject({ companyId }) {
  try {
    const response = yield call(getCompanyLocationForProject, companyId)
    yield put(getCompanyLocationForProjectSuccess(response))
  } catch (error) {
    yield put(getCompanyLocationForProjectFail(error))
  }
}
function* fetchCompanyLocationDetailForProject({ companyId }) {
  try {
    const response = yield call(getCompanyLocationDetailForProject, companyId)
    yield put(getCompanyLocationDetailforProjectSuccess(response))
  } catch (error) {
    yield put(getCompanyLocationDetailforProjectFail(error))
  }
}

function* projectsSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects)
  yield takeEvery(GET_PROJECT_MANAGER, fetchProjectManager)
  yield takeEvery(GET_PROJECT_NAME, fetchProjectName)
  yield takeEvery(GET_PROJECT_DETAIL, fetchProjectDetail)
  yield takeEvery(ADD_NEW_PROJECT, onAddNewProject)
  yield takeEvery(ADD_NEW_PROJECT_UNIT, onAddNewProjectUnit)
  yield takeEvery(UPDATE_PROJECT, onUpdateProject)
  yield takeEvery(DELETE_PROJECT, onDeleteProject)
  yield takeEvery(GET_GALLERY, fetchGallery)
  yield takeEvery(ADD_GALLERY, onAddGallery)
  yield takeEvery(UPDATE_GALLERY, onUpdateGallery)
  yield takeEvery(DELETE_GALLERY, onDeleteGallery)
  yield takeEvery(GET_PROJECT_UNIT, fetchProjectUnit)
  yield takeEvery(UPDATE_PROJECT_UNIT, onUpdateProjectUnit)
  yield takeEvery(DELETE_PROJECT_UNIT, onDeleteProjectUnit)
  yield takeEvery(GET_PROJECT_UNIT_DETAIL, fetchProjectUnitDetail)
  yield takeEvery(GET_VIDEO, fetchVideo)
  yield takeEvery(GET_VIDEO_DETAIL, fetchVideoDetail)
  yield takeEvery(ADD_VIDEO, onAddVideo)
  yield takeEvery(DELETE_VIDEO, onDeleteVideo)
  yield takeEvery(GET_FACILITIES_AVAILABLE, fetchFacilitiesAvailable)
  yield takeEvery(GET_COMPANY_FOR_PROJECT, fetchCompanyForProject)
  yield takeEvery(GET_COMPANY_DETAIL_FOR_PROJECT, fetchCompanyDetailForProject)
  yield takeEvery(
    GET_COMPANY_LOCATION_FOR_PROJECT,
    fetchCompanyLocationForProject
  )
  yield takeEvery(
    GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT,
    fetchCompanyLocationDetailForProject
  )
}

export default projectsSaga
