import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./vendorSummaryReportContainer"
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import { getVendorInvoices } from "store/actions"

const VendorSummaryReport = () => {
  const navigate = useNavigate()
  document.title = "Vendor Summary Report"
  const dispatch = useDispatch()
  const vendorSummaryList = state =>
    state.vendorSummaryReport.vendorInvoices.data
  const vendorInvoices = useSelector(vendorSummaryList) || []

  const loadingState = state => state.vendorSummaryReport.loading
  const loading = useSelector(loadingState) || []
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    dispatch(getVendorInvoices())
  }, [dispatch])

  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">{cellProps.row.index + 1}</h5>
            </>
          )
        },
      },

      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.vendor_name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Vendor Code",
        accessor: "vendor_code",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.vendor_code}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Tickets",
        accessor: "ticket_count",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={`/reports/vendor-summary-report/vendor-ticket-data/${cellProps.row.original.vendor_id}`}
                >
                  {cellProps.row.original.ticket_count}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Invoice Raised",
        accessor: "total_invoice_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={`/reports/vendor-summary-report/vendor-invoice-data/${cellProps.row.original.vendor_id}`}
                >
                  {cellProps.row.original.total_invoice_amount}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={`/reports/vendor-summary-report/vendor-invoice-Payment-data/${cellProps.row.original.vendor_id}`}
                >
                  {cellProps.row.original.amount_paid}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Tds Deducted",
        accessor: "total_tds_paid",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={`/reports/vendor-summary-report/vendor-tds-data/${cellProps.row.original.vendor_id}`}
                >
                  {cellProps.row.original.total_tds_paid}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Outstanding Payable",
        accessor: "outstanding_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={`/reports/vendor-summary-report/vendor-outstanding-amount-report/${cellProps.row.original.vendor_id}`}
                >
                  {cellProps.row.original.outstanding_amount}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {permission
                .filter(
                  item =>
                    item.page === "/vendor-summary-report/vendor-ledger/:id" &&
                    item.access === 1
                )
                .map(index => (
                  <Link
                    key={index}
                    to={`/reports/vendor-summary-report/vendor-ledger/${cellProps.row.original.vendor_id}`}
                    className="text-primary"
                  >
                    View Vendor Ledger
                    <i
                      className="mdi mdi-chevron-right font-size-18"
                      id="viewtooltip"
                      style={{ fontSize: "18px", color: "#3366cc" }}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target="viewtooltip"
                    >
                      View Vendor Ledger
                    </UncontrolledTooltip>
                  </Link>
                ))}
            </div>
          )
        },
      },
    ],
    []
  )

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Vendor Summary"
            breadcrumbItem="Vendor Summary Report"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={vendorInvoices || []}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorSummaryReport
