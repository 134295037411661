import { customerOutstandingListExcel, downloadBillPaymentExcel } from "helpers/fakebackend_helper"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal, Table } from "reactstrap"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { customerOutstandingList, paymentAmountList } from "store/actions"

const CustomerOustandingModal = ({ isOpen, toggle, filterResponse }) => {
  const dispatch = useDispatch()

  const paymentListState = state =>
    state.Dashboard?.customerOutstandingList?.data
  const paymentList = useSelector(paymentListState)

  useEffect(() => {
    if(isOpen===true){
    dispatch(customerOutstandingList({ filter: filterResponse }))
    }
  }, [filterResponse, dispatch,isOpen])

  const handleExport = async () => {
    // Construct the URL for download
    const value = {
      filter: filterResponse,
      type: "customerOutstanding",
    }
    const downloadUrl = await customerOutstandingListExcel(value)
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Customer Oustanding List
        </h5>
        <button
          onClick={handleExport}
          type="button"
          className="btn btn-primary btn-sm me-4"
        >
          Export
        </button>
        <button
          onClick={toggle}
          type="button"
          className="close mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <SimpleBar style={{ maxHeight: "60vh", overflowX: "auto" }}>
          <div style={{ minWidth: "100%" }}>
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Amount Paid</th>
                  <th>TDS</th>
                  <th>Outstanding <br/>Amount</th>
                </tr>
              </thead>
              <tbody>
                {((paymentList && paymentList) || []).map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{row.bill_date}</td>
                    <td>{row.total_amount}</td>
                    <td>{row.amount_paid}</td>
                    <td>{row.tds_amount}</td>
                    <td>{row.outstanding_amount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </SimpleBar>
      </div>
    </Modal>
  )
}

export default CustomerOustandingModal
