import {
  GET_ALL_ENGAGEMENT_LIST_SUCCESS,
  GET_ALL_ENGAGEMENT_LIST_FAIL,
  GET_APPROVAL_PENDING_LIST_SUCCESS,
  GET_APPROVAL_PENDING_LIST_FAIL,
  GET_BILLING_SETUP_PENDING_LIST_SUCCESS,
  GET_BILLING_SETUP_PENDING_LIST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  engagementList: [],
  approvalPendingList: [],
  billingSetupPendingList: [],
  error: {},
  loading: true,
}

const serviceEngagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ENGAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        engagementList: action.payload,
        loading: true,
      }

    case GET_ALL_ENGAGEMENT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_APPROVAL_PENDING_LIST_SUCCESS:
      return {
        ...state,
        approvalPendingList: action.payload,
        loading: true,
      }

    case GET_APPROVAL_PENDING_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BILLING_SETUP_PENDING_LIST_SUCCESS:
      return {
        ...state,
        billingSetupPendingList: action.payload,
        loading: true,
      }

    case GET_BILLING_SETUP_PENDING_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default serviceEngagement
