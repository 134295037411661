import React, { useEffect, useState } from "react"
import FlatPickr from "react-flatpickr"
import LargeModal from "../EmailModal/emailModal"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { API_URL } from "helpers/url_helper"
import { downloadGstR1Excell } from "helpers/fakebackend_helper"
import { getRenewals } from "store/actions"
import { useLocation } from "react-router-dom"

const RenewalDetailList = (props) => {

  const location = useLocation();
  const { row } = location.state || {};
  console.log(row)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)
  document.title = "Renewal Detail"
  const dispatch = useDispatch()

  const RenewalState = state => state.renewals.renewals.counts

  const RenewalList = useSelector(RenewalState) || []

  console.log(RenewalList)

  const validation = useFormik({
    initialValues: {
      company_id: "",
      location_id: "",
      month: new Date().getMonth() + 1 || "6",
      year: new Date().getFullYear() || "2024",
      status: 1,
    },
  })

  console.log(validation.values)

  useEffect(() => {
    if (user.user_id) {
      dispatch(getRenewals(user.user_id))
    }
  }, [user])

  const handleExport = async () => {
    // Construct the URL for download
    const downloadUrl = await downloadGstR1Excell(validation.values)
  }

  const [selectedOption, setSelectedOption] = useState("")

  const [customerId, setCustomerID] = useState()
  const [bill, setBillNo] = useState()
  const sendMailModal = (value, bill_no) => {
    setCustomerID(value)
    setBillNo(bill_no)
    toggleModalLarge()
    // dispatch(sendEmailToCustomer(value))
  }

  const handleChange = event => {
    const selectedAction = event.target.value
    if (selectedAction === "Renew") {
      navigate(`/billing/edit-bill/${cellProps.row.original.billing_id}`)
    } else if (selectedAction === "Delete") {
      onClickDelete(cellProps.row.original.billing_id)
    } else if (selectedAction === "Send Reminder Mail") {
      sendMailModal(
        cellProps.row.original.billing_id,
        cellProps.row.original.bill_no
      )
    }
    event.target.value = "Action"
  }

  return (
    <React.Fragment>
      <LargeModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        customerId={1}
        bill={1}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Renewal" breadcrumbItem="Renewal" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="h4">Renewal Report </CardTitle>
                    </Col>
                    {/* <Col className="text-end">
                      <button
                        onClick={handleExport}
                        type="button"
                        className="btn btn-primary btn-sm me-4"
                      >
                        Export
                      </button>
                    </Col> */}
                  </Row>
                  {RenewalList && RenewalList.length > 0 ? (
                    <div className="table-responsive">
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Renewal Overdue</th>
                            <th>0 to 30 days</th>
                            <th>30 to 60 days</th>
                            <th>60 to 90 days</th>
                            <th>90+ days</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(RenewalList || []).map((row, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{row?.billing?.customer_name}</td>
                              <td>{row?.client?.gst_no}</td>
                              <td>{row?.billing?.invoice_no}</td>
                              <td>{row?.billing?.invoice_date}</td>
                              <td>{row?.location?.gst_no}</td>
                              <td>
                                <select
                                  className="form-select"
                                  value={selectedOption}
                                  onChange={handleChange()}
                                >
                                  <option value="">Select an option</option>
                                  <option value="Renew">Renew</option>
                                  <option value="Send Reminder Mail">
                                    Send Reminder Mail
                                  </option>
                                  <option value="Decline">Decline</option>
                                </select>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <Alert color="warning" role="alert">
                      No Data...
                    </Alert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RenewalDetailList
