export const GET_VENDOR = "GET_VENDOR"
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS"
export const GET_VENDOR_FAIL = "GET_VENDOR_FAIL"

export const GET_VENDOR_CONTACT = "GET_VENDOR_CONTACT"
export const GET_VENDOR_CONTACT_SUCCESS = "GET_VENDOR_CONTACT_SUCCESS"
export const GET_VENDOR_CONTACT_FAIL = "GET_VENDOR_CONTACT_FAIL"

export const GET_VENDORS_LIST = "GET_VENDORS_LIST"
export const GET_VENDORS_LIST_SUCCESS = "GET_VENDORS_LIST_SUCCESS"
export const GET_VENDORS_LIST_FAIL = "GET_VENDORS_LIST_FAIL"

export const GET_VENDOR_SERVICE_MASTER_LIST = "GET_VENDOR_SERVICE_MASTER_LIST"
export const GET_VENDOR_SERVICE_MASTER_LIST_SUCCESS = "GET_VENDOR_SERVICE_MASTER_LIST_SUCCESS"
export const GET_VENDOR_SERVICE_MASTER_LIST_FAIL = "GET_VENDOR_SERVICE_MASTER_LIST_FAIL"

export const ADD_NEW_VENDORS = "ADD_NEW_VENDORS"
export const ADD_NEW_VENDORS_SUCCESS = "ADD_NEW_VENDORS_SUCCESS"
export const ADD_NEW_VENDORS_FAIL = "ADD_NEW_VENDORS_FAIL"

export const ADD_NEW_VENDORS_DOCUMENTS = "ADD_NEW_VENDORS_DOCUMENTS"
export const ADD_NEW_VENDORS_DOCUMENTS_SUCCESS = "ADD_NEW_VENDORS_DOCUMENTS_SUCCESS"
export const ADD_NEW_VENDORS_DOCUMENTS_FAIL = "ADD_NEW_VENDORS_DOCUMENTS_FAIL"

export const ADD_NEW_VENDORS_CONTACTS = "ADD_NEW_VENDORS_CONTACTS"
export const ADD_NEW_VENDORS_CONTACTS_SUCCESS = "ADD_NEW_VENDORS_CONTACTS_SUCCESS"
export const ADD_NEW_VENDORS_CONTACTS_FAIL = "ADD_NEW_VENDORS_CONTACTS_FAIL"

export const UPDATE_VENDORS = "UPDATE_VENDORS"
export const UPDATE_VENDORS_SUCCESS = "UPDATE_VENDORS_SUCCESS"
export const UPDATE_VENDORS_FAIL = "UPDATE_VENDORS_FAIL"

export const DELETE_VENDORS = "DELETE_VENDORS"
export const DELETE_VENDORS_SUCCESS = "DELETE_VENDORS_SUCCESS"
export const DELETE_VENDORS_FAIL = "DELETE_VENDORS_FAIL"

export const DELETE_VENDOR_DOCUMENT = "DELETE_VENDOR_DOCUMENT"
export const DELETE_VENDOR_DOCUMENT_SUCCESS = "DELETE_VENDOR_DOCUMENT_SUCCESS"
export const DELETE_VENDOR_DOCUMENT_FAIL = "DELETE_VENDOR_DOCUMENT_FAIL"

export const DELETE_VENDOR_CONTACT = "DELETE_VENDOR_CONTACT"
export const DELETE_VENDOR_CONTACT_SUCCESS = "DELETE_VENDOR_CONTACT_SUCCESS"
export const DELETE_VENDOR_CONTACT_FAIL = "DELETE_VENDOR_CONTACT_FAIL"

export const GET_VENDOR_CODE = "GET_VENDOR_CODE"
export const GET_VENDOR_CODE_SUCCESS = "GET_VENDOR_CODE_SUCCESS"
export const GET_VENDOR_CODE_FAIL = "GET_VENDOR_CODE_FAIL"

export const ADD_VENDOR_PAYMENT = "ADD_VENDOR_PAYMENT"
export const ADD_VENDOR_PAYMENT_SUCCESS = "ADD_VENDOR_PAYMENT_SUCCESS"
export const ADD_VENDOR_PAYMENT_FAIL = "ADD_VENDOR_PAYMENT_FAIL"

export const GET_VENDOR_LIST_IN_PAYMENT = "GET_VENDOR_LIST_IN_PAYMENT"
export const GET_VENDOR_LIST_IN_PAYMENT_SUCCESS = "GET_VENDOR_LIST_IN_PAYMENT_SUCCESS"
export const GET_VENDOR_LIST_IN_PAYMENT_FAIL = "GET_VENDOR_LIST_IN_PAYMENT_FAIL"

export const GET_VENDOR_PAYMENT_DETAIL = "GET_VENDOR_PAYMENT_DETAIL"
export const GET_VENDOR_PAYMENT_DETAIL_SUCCESS = "GET_VENDOR_PAYMENT_DETAIL_SUCCESS"
export const GET_VENDOR_PAYMENT_DETAIL_FAIL = "GET_VENDOR_PAYMENT_DETAIL_FAIL"

export const GET_VENDOR_PAYMENT_DATA = "GET_VENDOR_PAYMENT_DATA"
export const GET_VENDOR_PAYMENT_DATA_SUCCESS = "GET_VENDOR_PAYMENT_DATA_SUCCESS"
export const GET_VENDOR_PAYMENT_DATA_FAIL = "GET_VENDOR_PAYMENT_DATA_FAIL"
