import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  FormFeedback,
  Alert,
} from "reactstrap"
import {
  addNewBillingReceipt,
  getApplicableRateList,
  getAssignedProjectUnitListInBilling,
  getBillInvoiceNoList,
  getBillingClientDetail,
  getBillingClientList,
  getBillingClientListInReceipt,
  getBillingCompanyDetail,
  getBillingCompanyDetailInReceipt,
  getBillingCompanyList,
  getBillingCompanyListInReceipt,
  getCities,
  getCountries,
  getGstLocationDetail,
  getGstLocationList,
  getInvoiceTypeMasterList,
  getOutstandingAmountDetailInBillingReceipt,
  getPaymentMode,
  getPlaceOfSupply,
  getProjectDetailInBilling,
  getProjectListInBilling,
  getProjectUnitDetailInBilling,
  getReceiptNumber,
  getStates,
  getSupplyList,
} from "store/actions"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  addNewServiceRequest,
  getClientInvoices,
  getMaintenanceIssues,
  getProjectUnitCategory,
  getProjectUnitDetail,
  getServiceRequestType,
  getSupportTickets,
} from "store/actions"
import { createSelector } from "reselect"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"
// import LargeModal from "./detailedSummaryModal"

const AddNewReceipt = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [clientId, setClientId] = useState()
  const [billingDetails, setBillingDetails] = useState([])
  const [response, setResponse] = useState()

  const [Index, setIndex] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const BillingState = state => state.billing
  const path = useLocation()

  const AllBillingProperties = createSelector(
    BillingState,

    billing => ({
      clientDetail: billing.clientDetail.client,
      engagementService: billing.serviceEngagement.project_unit,
      billingCompanyList: billing.companyListInReceipt.companies,
      billingCompanyDetail: billing.companyDetailInReceipt.company,
      typeOfSupplyList: billing.supplyList.data,
      placeOfSupplyList: billing.placeOfSupply.data,
      applicableRateList: billing.applicableRateList.data,
      invoiceTypeMasterList: billing.billInvoiceNo.billings,
      invoiceNumber: billing.receiptNumber.receipt_number,
      paymentMode: billing.paymentMode.data,
      receiptNumber: billing.receiptNumber.invoice_number,
      outstandingAmount: billing.amountDetailInReceipt.outstanding_amount,
      totalAmount: billing.amountDetailInReceipt.total_amount,
      billingCompanylocation: billing.gstLocationDetail.data,
      gstLocationList: billing.gstLocationList.data,
      //
      projectList: billing.projectList.data,
      projectDetail: billing.projectDetail.data,
      projectUnitDetail: billing.projectUnitDetail.project_unit,
      assignedProjectUnitList: billing.assignedProjectUnit.project_units,
      clientList: billing.clientList.data,
    })
  )

  const {
    clientList,
    clientDetail,
    engagementService,
    billingCompanyList,
    billingCompanyDetail,
    typeOfSupplyList,
    placeOfSupplyList,
    applicableRateList,
    invoiceTypeMasterList,
    invoiceNumber,
    paymentMode,
    receiptNumber,
    outstandingAmount,
    totalAmount,
    billingCompanylocation,
    gstLocationList,
    //
    projectList,
    projectDetail,
    projectUnitDetail,
    assignedProjectUnitList,
  } = useSelector(AllBillingProperties)

  console.log(outstandingAmount, totalAmount)
  useEffect(() => {
    // dispatch(getSupplyList())
    // dispatch(getPlaceOfSupply())
    // dispatch(getApplicableRateList())
    dispatch(getPaymentMode())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      dispatch(getBillingCompanyListInReceipt(user.user_id))
    }
  }, [user])

  ////////

  const roundToTwo = (num) => {
    return Math.round(num * 100) / 100;
  };
  
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      project_id: "",
      client_id: "",
      client_project_unit_id: "",
      //
      customer_id: "",
      company_id: "",
      location_id: "",
      receipt_no: "",
      receipt_date: new Date().toISOString().split("T")[0] || "",
      amount: 0,
      outstanding_amount: 0,
      amount_show: 0,
      description: "",
      receipt_no: "",
      payment_type: "",
      ins_no: "",
      bank_account_number: "",
      cheque_no: "",
      date: "",
      billing_components: [],
      unutilized_amount: 0,
      //
      invoice_amount: 0,
      tds_amount: 0,
      billing_id: "",
    },
    validationSchema: Yup.object().shape({
      customer_id: Yup.number().required("Please select Customer"),
      // company_id: Yup.number().required("Please select Company"),
      // receipt_date: Yup.date()
      // .required("Receipt date is required")
      // .min(
      //   Yup.ref(new Date().toISOString().split("T")[0]),
      //   "Receipt date cannot be in the past. Please select today's date or a future date."
      // ),
      // amount: Yup.string()
      // .matches(/^\d+(\.\d{1,2})?$/, "Amount must be a valid number with up to two decimal places")
      // .required("Please Enter Amount"),
      amount: Yup.number()
        .typeError("Amount must be a valid number")
        .positive("Amount must be a positive number")
        .required("Please Enter Amount"),
      payment_type: Yup.number().required("Payment Type is required"),
      // invoice_amount: Yup.number().required("Billing Id is required"),
      invoice_amount: Yup.number()
        .min(0)
        .test(
          "max",
          "Combination of Bill amount and TDS amount should not exceed outstanding amount",
          function (value) {
            const outstandingAmount = Number(
              this.parent.outstanding_amount || 0
            )
            const tdsAmount = Number(this.parent.tds_amount || 0)

            const totalAmount = Number(value) + tdsAmount
            return totalAmount <= outstandingAmount
          }
        ),
    }),

    onSubmit: async values => {
      dispatch(addNewBillingReceipt(props.router.navigate, values))
    },
  })

  useEffect(() => {
    if (projectDetail) {
      validation.setFieldValue("location_id", projectDetail.location_id)
      validation.setFieldValue("company_id", projectDetail.company_id)
    }
  }, [projectDetail])

  useEffect(() => {
    if (invoiceNumber) {
      validation.setFieldValue("receipt_no", invoiceNumber)
    }
  }, [invoiceNumber])

  console.log(clientList)

  // useEffect(() => {
  //   if (validation.values.company_id) {
  //     dispatch(getGstLocationList(validation.values.company_id))
  //   }
  // }, [validation.values.company_id])

  const [totalBillingComponentAmount, setTotalBillingComponentAmount] =
    useState()
  useEffect(() => {
    if (validation.values.billing_components) {
      // Calculate the total amount of all billing components
      const totalBillingComponentAmount =
        validation.values.billing_components.reduce(
          (total, component) =>
            Number(total) +
            Number(component.amount) +
            Number(component.tds_amount),
          0
        )

      console.log("totalBillingComponentAmount", totalBillingComponentAmount)
      setTotalBillingComponentAmount(totalBillingComponentAmount)
      // Calculate the unutilized amount by subtracting the total billing component amount from the total amount
      const unutilized = validation.values.amount - totalBillingComponentAmount

      validation.setFieldValue("unutilized_amount", unutilized)
    }
  }, [validation.values.amount, validation.values.billing_components])

  useEffect(() => {
    if (validation.values.amount) {
      validation.setFieldValue("unutilized_amount", validation.values.amount)
    }
  }, [validation.values.amount])

  console.log(validation)
  useEffect(() => {
    if (validation.values.client_project_unit_id) {
      dispatch(getBillInvoiceNoList(validation.values.client_project_unit_id))
    }
  }, [validation.values.client_project_unit_id])

  useEffect(() => {
    console.log("innnnnn", outstandingAmount)
    if (outstandingAmount) {
      validation.setFieldValue("outstanding_amount", outstandingAmount)
    }
  }, [outstandingAmount, totalAmount, response])

  useEffect(() => {
    if (totalAmount) {
      validation.setFieldValue("amount_show", totalAmount)
    }
  }, [totalAmount, outstandingAmount, response])

  useEffect(() => {
    if (validation.values.location_id && validation.values.receipt_date) {
      dispatch(
        getReceiptNumber({
          location_id: validation.values.location_id,
          receipt_date: validation.values.receipt_date,
        })
      )
    }
  }, [validation.values.location_id, validation.values.receipt_date])

  useEffect(() => {
    if (validation?.values?.billing_id) {
      console.log("157", validation.values.billing_id)
      const response = dispatch(
        getOutstandingAmountDetailInBillingReceipt(validation.values.billing_id)
      )
      setResponse(response)
    }
  }, [validation.values.billing_id])

  useEffect(() => {
    if (receiptNumber) {
      validation.setFieldValue("receipt_no", receiptNumber)
    }
  }, [receiptNumber])

  useEffect(() => {
    if (validation.values.customer_id) {
      const clientId = validation.values.customer_id
      setClientId(clientId)
      dispatch(dispatch(getBillingClientDetail(clientId)))
    }
  }, [validation.values.customer_id])

  useEffect(() => {
    if (validation.values.company_id) {
      // dispatch(getBillingCompanyDetailInReceipt(validation.values.company_id))
    }
  }, [dispatch, validation.values.company_id])

  const [modal_large, setmodal_large] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }

  const handleAddButtonClick = () => {
    const index = Index
    const Unsettled = validation.values.unutilized_amount
    const outstanding_amount = validation.values.outstanding_amount
    const invoiceType = validation.values.billing_id
    const invoiceAmount = validation.values.invoice_amount
    const tdsAmount = validation.values.tds_amount

    const totalAmount = Number(invoiceAmount) + Number(tdsAmount)
    console.log(totalAmount)

    if (totalAmount > outstanding_amount) {
      console.log("swal", totalAmount, outstanding_amount)
      return Swal.fire({
        text: "Amount is greater than Oustanding amount",
        icon: "warning",
      })
    } else if (Unsettled < totalAmount) {
      return Swal.fire({
        text: "Total Invoice Amount cannot exceed Unsettled Amount",
        icon: "warning",
      })
    } else if (invoiceType === "") {
      return Swal.fire({
        text: "Please Select Bill no",
        icon: "warning",
      })
    }
    {
      setBillingDetails(prevState => [
        ...prevState,
        {
          billing_id: invoiceType,
          amount: invoiceAmount,
          tds_amount: tdsAmount,
        },
      ])
      validation.setFieldValue(`billing_id`, "")
      validation.setFieldValue(`invoice_amount`, 0)
      validation.setFieldValue(`tds_amount`, 0)
      validation.setFieldValue(`outstanding_amount`, 0)
      validation.setFieldValue(`amount_show`, 0)
      // Clear any existing error messages
    }
  }

  useEffect(() => {
    validation.setFieldValue("billing_components", billingDetails)
  }, [billingDetails])

  const handleRemoveButtonClick = indexToRemove => {
    setBillingDetails(prevState =>
      prevState.filter((_, index) => index !== indexToRemove)
    )
  }

  //receipt new format change
  useEffect(() => {
    if (user) {
      dispatch(getProjectListInBilling(user.user_id))
    }
  }, [user])
  useEffect(() => {
    // console.log("validation.values.customer_id", validation.values.customer_id)
    if (validation.values.customer_id) {
      const value = {
        project_id: validation.values.project_id,
        client_id: validation.values.customer_id,
      }
      dispatch(getAssignedProjectUnitListInBilling(value))
    }
  }, [validation.values.customer_id])

  useEffect(() => {
    if (validation.values.project_id) {
      dispatch(getBillingClientList(validation.values.project_id))
      dispatch(getProjectDetailInBilling(validation.values.project_id))
    }
  }, [validation.values.project_id])

  useEffect(() => {
    if (validation.values.client_project_unit_id) {
      dispatch(
        getProjectUnitDetailInBilling(validation.values.client_project_unit_id)
      )
    }
  }, [validation.values.client_project_unit_id])

  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
                Add New Receipt
              </div>
            </CardTitle>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.project_id &&
                              validation.errors.project_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="project_id"
                            value={validation.values.project_id || ""}
                          >
                            <option value="">Select Project</option>
                            {(projectList || []).map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_id &&
                            validation.errors.project_id && (
                              <div className="invalid-feedback">
                                {validation.errors.project_id}
                              </div>
                            )}
                        </div>
                        {validation.values.project_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Project Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_name">
                                        Project Name
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="property_type">
                                        Property Type
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.property_type}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_use">
                                        Project Use
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_use}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_area_sqft">
                                        Project Area
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_area_sqft}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_type">
                                        Project Type
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_type}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="total_floors">
                                        Total Floors
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.total_floors}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="location">Location</Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.location}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_manager_id">
                                        Project Manager
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.full_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="parking_available">
                                        Parking Available
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                        projectDetail.parking_available === 1
                                          ? "Yes"
                                          : "No"}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="parking_type">
                                        Parking Type
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.parking_type}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="contract_terms_start_date">
                                        Contract Start Date
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.contract_terms_start_date}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="contract_terms_end_date">
                                        Contract End Date
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.contract_terms_end_date}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.customer_id &&
                              validation.errors.customer_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="customer_id"
                            value={validation.values.customer_id || ""}
                          >
                            <option value="">Select Client</option>
                            {(clientList || []).map(client => (
                              <option key={client.id} value={client.id}>
                                {client.customer_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.customer_id &&
                            validation.errors.customer_id && (
                              <div className="invalid-feedback">
                                {validation.errors.customer_id}
                              </div>
                            )}
                        </div>
                        {validation.values.customer_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Client Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="customer_code">
                                        Customer Code
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.customer_code}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="customer_name">
                                        Customer Name
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.customer_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="email_address">
                                        Email Address
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.email_address}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="mobile_no">
                                        Mobile Number
                                      </Label>
                                      <p>
                                        {clientDetail && clientDetail.mobile_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="address">Address</Label>
                                      <p>
                                        {clientDetail && clientDetail.address}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pincode">Pincode</Label>
                                      <p>
                                        {clientDetail && clientDetail.pincode}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="country_name">
                                        Country
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.country_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="state_name">State</Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.state_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="city_name">City</Label>
                                      <p>
                                        {clientDetail && clientDetail.city_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="gst_no">GST Number</Label>
                                      <p>
                                        {clientDetail && clientDetail.gst_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pan_no">PAN Number</Label>
                                      <p>
                                        {clientDetail && clientDetail.pan_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.client_project_unit_id &&
                              validation.errors.client_project_unit_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="client_project_unit_id"
                            value={validation.values.client_project_unit_id}
                          >
                            <option value="">Select Project Unit</option>
                            {(assignedProjectUnitList || []).map(company => (
                              <option key={company.id} value={company.id}>
                                {company.unit_code}
                              </option>
                            ))}
                          </select>
                          {validation.touched.client_project_unit_id &&
                            validation.errors.client_project_unit_id && (
                              <div className="invalid-feedback">
                                {validation.errors.client_project_unit_id}
                              </div>
                            )}
                        </div>
                        {validation.values.client_project_unit_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Project Unit Detail (
                                  {projectUnitDetail &&
                                    projectUnitDetail.unit_code}
                                  )
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_name">
                                        Project Name
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.project_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="brand_name">Brand</Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.brand_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="revenue_share">
                                        Revenue Share
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          (projectUnitDetail.revenue_share === 1
                                            ? "Monthly"
                                            : "Annually")}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="revenue_share_percentage">
                                        Revenue Share(%)
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.revenue_share_percentage}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="rent_payment_schedule">
                                        Rent Payment Schedule
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          (projectUnitDetail.rent_payment_schedule ===
                                          1
                                            ? "Monthly"
                                            : projectUnitDetail.rent_payment_schedule ===
                                              2
                                            ? "Quarterly"
                                            : projectUnitDetail.rent_payment_schedule ===
                                              3
                                            ? "Half Yearly"
                                            : "Yearly")}{" "}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="customer_lock_in_period">
                                        Customer Lock-In Period(Months)
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.customer_lock_in_period}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="notice_period">
                                        Notice Period(Months)
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.notice_period}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="security_deposit_for_rent">
                                        Security Deposit for Rent
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.security_deposit_for_rent}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="security_deposit_for_electricity">
                                        Security Deposit for Electricity
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.security_deposit_for_electricity}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="security_deposit_for_maintenance">
                                        Security Deposit for Maintenance
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.security_deposit_for_maintenance}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="agreement_start_date">
                                        Agreement Start Date
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.agreement_start_date}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="agreement_end_date">
                                        Agreement End Date
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.agreement_end_date}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="receipt_date">Receipt Date*</Label>
                          <Input
                            type="date"
                            id="receipt_date"
                            name="receipt_date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.receipt_date}
                            className={`form-control ${
                              validation.touched.receipt_date &&
                              validation.errors.receipt_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.receipt_date &&
                          validation.errors.receipt_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.receipt_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="amount">Payment Amount*</Label>
                          <Input
                            type="text"
                            name="amount"
                            className="form-control"
                            id="amount"
                            placeholder="Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount || ""}
                            invalid={
                              validation.touched.amount &&
                              validation.errors.amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.amount &&
                          validation.errors.amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="unutilized_amount">
                            Unsettled Amount
                          </Label>
                          <Input
                            type="text"
                            disabled
                            name="unutilized_amount"
                            className="form-control"
                            id="unutilized_amount"
                            placeholder="Unsettled Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.unutilized_amount || 0}
                            invalid={
                              validation.touched.unutilized_amount &&
                              validation.errors.unutilized_amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.unutilized_amount &&
                          validation.errors.unutilized_amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.unutilized_amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="receipt_no">Receipt Number*</Label>
                          <Input
                            type="text"
                            name="receipt_no"
                            readOnly
                            className="form-control"
                            id="receipt_no"
                            placeholder="Receipt Number"
                            onBlur={validation.handleBlur}
                            value={validation.values.receipt_no || ""}
                            invalid={
                              validation.touched.receipt_no &&
                              validation.errors.receipt_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.receipt_no &&
                          validation.errors.receipt_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.receipt_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="payment_type">Payment Type*</Label>
                          <select
                            className={`form-select ${
                              validation.touched.payment_type &&
                              validation.errors.payment_type
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="payment_type"
                            value={validation.values.payment_type || ""}
                          >
                            {!validation.values.payment_type && (
                              <option>Select Payment Mode</option>
                            )}
                            {paymentMode &&
                              paymentMode.map((mode, index) => (
                                <option key={index} value={mode.id}>
                                  {mode.payment_mode}
                                </option>
                              ))}
                          </select>
                          {validation.touched.payment_type &&
                          validation.errors.payment_type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.payment_type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="description">Description</Label>

                          <Input
                            type="text"
                            name="description"
                            className="form-control"
                            id="description"
                            placeholder="Enter Description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={
                              validation.touched.description &&
                              validation.errors.description
                                ? true
                                : false
                            }
                          />
                          {validation.touched.description &&
                          validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <>
                      <Row>
                        {(validation.values.payment_type === "1" ||
                          validation.values.payment_type === "2" ||
                          validation.values.payment_type === "3") && (
                          <Col lg={4}>
                            <div className=" mb-2">
                              <Label htmlFor="ins_no" className="mt-2">
                                Ins No.
                              </Label>

                              <input
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                className={`form-control  ${
                                  validation.touched.ins_no &&
                                  validation.errors.ins_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="ins_no"
                                value={validation.values.ins_no || ""}
                              />
                              {validation.touched.ins_no &&
                              validation.errors.ins_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.ins_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}
                        {validation.values.payment_type === "3" && (
                          <>
                            <Col lg={4}>
                              <div className=" mb-2">
                                <Label
                                  htmlFor="bank_account_number"
                                  className="mt-2"
                                >
                                  Bank Name
                                </Label>

                                <input
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  className={`form-control  ${
                                    validation.touched.bank_account_number &&
                                    validation.errors.bank_account_number
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="bank_account_number"
                                  value={
                                    validation.values.bank_account_number || ""
                                  }
                                />
                                {validation.touched.bank_account_number &&
                                validation.errors.bank_account_number ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.bank_account_number}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className=" mb-2">
                                <Label htmlFor="date" className="mt-2">
                                  Ins Date
                                </Label>

                                <input
                                  type="date"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  className={`form-control ${
                                    validation.touched.date &&
                                    validation.errors.date
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="date"
                                  value={validation.values.date || ""}
                                />
                                {validation.touched.date &&
                                validation.errors.date ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.date}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row key={Index}>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">Billing Item Details</CardTitle>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="billing_id">Bill No*</Label>
                          <select
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.billing_id || ""}
                            name="billing_id"
                          >
                            <option>Select Bill No</option>
                            {(invoiceTypeMasterList || []).map(
                              invoice =>
                                // Check if the billing ID is not included in billing_components
                                !validation.values.billing_components.some(
                                  component =>
                                    Number(component.billing_id) ===
                                    Number(invoice.billing_id)
                                ) && (
                                  <option
                                    key={invoice.billing_id}
                                    value={invoice.billing_id}
                                  >
                                    Bill no : {invoice.bill_no} - Outstanding
                                    Amount : {invoice.outstanding_amount} - Bill
                                    Date : {invoice.bill_date}
                                  </option>
                                )
                            )}
                          </select>
                          {validation.touched.billing_id &&
                          validation.errors.billing_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.billing_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      {parseFloat(validation.values.outstanding_amount) !==
                      0 ? (
                        <>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="amount_show">
                                Invoice Amount
                              </Label>
                              <Input
                                type="text"
                                disabled
                                name="amount_show"
                                className="form-control"
                                id="amount_show"
                                placeholder="Amount"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.amount_show || 0}
                                invalid={
                                  validation.touched.amount_show &&
                                  validation.errors.amount_show
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.amount_show &&
                              validation.errors.amount_show ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.amount_show}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="outstanding_amount">
                                Outstanding Amount
                              </Label>
                              <Input
                                type="text"
                                name="outstanding_amount"
                                disabled
                                className="form-control"
                                id="outstanding_amount"
                                placeholder="Outstanding Amount"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  roundToTwo(
                                    parseFloat(validation.values.outstanding_amount || 0) -
                                    parseFloat(validation.values.invoice_amount || 0) -
                                    parseFloat(validation.values.tds_amount || 0)
                                  )
                                }
                                invalid={
                                  validation.touched.outstanding_amount &&
                                  validation.errors.outstanding_amount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.outstanding_amount &&
                              validation.errors.outstanding_amount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.outstanding_amount}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </>
                      ) : (
                        <Col lg={2}>
                          <Label></Label>
                          <Alert
                            color="success"
                            role="alert"
                            className="text-center"
                          >
                            Please Select Bill
                          </Alert>
                        </Col>
                      )}
                    </Row>
                    {parseFloat(validation.values.outstanding_amount) !== 0 ||
                      validation.values.billing_id === "" ? (
                      <>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="invoice_amount">
                                Receipt Payment*
                              </Label>
                              <Input
                                type="text"
                                id="invoice_amount"
                                placeholder="Invoice Amount"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.invoice_amount || ""}
                                name="invoice_amount"
                                invalid={
                                  validation.touched.invoice_amount &&
                                  validation.errors.invoice_amount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.invoice_amount &&
                              validation.errors.invoice_amount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.invoice_amount}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="tds_amount">TDS Amount*</Label>
                              <Input
                                type="text"
                                id="tds_amount"
                                placeholder="Enter tds Amount"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tds_amount || ""}
                                name="tds_amount"
                                invalid={
                                  validation.touched.tds_amount &&
                                  validation.errors.tds_amount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.tds_amount &&
                              validation.errors.tds_amount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tds_amount}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    {parseFloat(validation.values.outstanding_amount) !== 0 ? (
                      <>
                        <Row>
                          <Col>
                            <div className="mb-3 text-end">
                              <button
                                type="button"
                                className="btn btn-primary w-md me-2"
                                style={{ width: "10%" }}
                                onClick={handleAddButtonClick}
                              >
                                Add
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {billingDetails.length === 0 ? (
                      <p className="text-center">No Record found</p>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Bill</th>
                            <th>Receipt Payment</th>

                            <th>TDS Amount</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billingDetails.map((billing, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {invoiceTypeMasterList
                                  .filter(
                                    item =>
                                      Number(item.billing_id) ===
                                      Number(billing.billing_id)
                                  )
                                  .map(filteredItem => {
                                    return (
                                      <span key={filteredItem.billing_id}>
                                        {filteredItem.bill_no}
                                      </span>
                                    )
                                  })}
                              </td>
                              <td>{billing.amount}</td>
                              <td>{billing.tds_amount}</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => handleRemoveButtonClick(index)}
                                  style={{
                                    border: "none",
                                    background: "none",
                                    padding: 0,
                                    cursor: "pointer",
                                  }}
                                >
                                  <i
                                    className="mdi mdi-delete"
                                    style={{ color: "red", fontSize: "18px" }}
                                  />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button
                    type="submit"
                    className="btn btn-primary w-md me-2"
                    style={{ width: "10%" }}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddNewReceipt)

AddNewReceipt.propTypes = {
  history: Proptypes.object,
}
