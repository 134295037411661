import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip,
  Row,
  Col,
  CardTitle,
  Label,
  Container,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap"

// import { recentfile } from "../../../common/data";
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Breadcrumb from "components/Common/Breadcrumb"
import { getAssignedProjectUnit } from "store/actions"
import { approveProject } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

const ProjectUnitsApprovalView = () => {
  const [clientId, setClientId] = useState()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const viewAssignedProjectUnitState = state => state.clients.pastEmp
  const data = useSelector(viewAssignedProjectUnitState)

  const approveProjectApi = async value => {
    const response = await approveProject(clientId, { approval_status: value })
    if (response.status === true) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: response.message,
      }).then(() => {
        navigate("/clients/service-engagement/all-engagement-list")
      })
    }
  }

  const fetchData = async () => {
    const url = new URL(window.location.href)
    const clientId = url.pathname.split("/view-project-units-approval/")[1]
    setClientId(clientId)

    try {
      dispatch(getAssignedProjectUnit(clientId))

      // setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey", marginRight: "15px" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              <span
                style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
              >
                {" "}
                Assigned Project Unit
              </span>
            </div>
          </Col>
          <Col>
            <Breadcrumb title="Clients " />
          </Col>
        </Row>
        <Container fluid={true}>
          <Card>
            <CardBody>
              {data && (
                <Row>
                  <Col>
                    <CardTitle className="mb-3">
                      {" "}
                      <div className="d-flex gap-2 mb-3">
                        <i
                          className="bx bx-receipt font-size-20"
                          style={{ color: "#3366cc", marginTop: "5px" }}
                        ></i>
                        <span
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          {" "}
                          Assigned Project Unit
                        </span>
                      </div>
                    </CardTitle>
                    <CardSubtitle className="mb-3">
                      {" "}
                      {/* <span>Approval Status : </span>
                      <span
                        style={{
                          color:
                            data && data.project_unit?.approval_status === 1
                              ? "#22bb33"
                              : data.project_unit?.approval_status === 2
                              ? "#f0ad4e"
                              : data.project_unit?.approval_status === 3
                              ? "#bb2124"
                              : "#000000",
                          fontWeight: "500",
                          fontSize: "13px",
                        }}
                      >
                        {data && data.project_unit?.approval_status === 1
                          ? "Approved"
                          : data.project_unit?.approval_status === 2
                          ? "Pending"
                          : "Rejected"}
                      </span>
                      <br /> */}
                      <span>Billing Setup Status : </span>
                      <span
                        style={{
                          color:
                            data && data.project_unit?.billing_setup === 1
                              ? "#22bb33"
                              : data.project_unit?.billing_setup === 2
                              ? "#f0ad4e"
                              : data.project_unit?.billing_setup === 3
                              ? "#bb2124"
                              : "#000000",
                          fontWeight: "500",
                          fontSize: "13px",
                        }}
                      >
                        {data && data.project_unit?.billing_setup === 1
                          ? "Approved"
                          : data.project_unit?.billing_setup === 2
                          ? "Pending"
                          : "Rejected"}
                      </span>
                    </CardSubtitle>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={4}>
                        <Label className="form-label">Project*</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.project_name}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Project Unit* </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.unit_code}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          Minimum Monthly Rent*
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.minimum_monthly_rent}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">Revenue Share* </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.revenue_share === 1
                            ? "Monthly"
                            : "Annualy"}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Revenue Share(%)* </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.revenue_share_percentage}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          Rent Payment Schedule*
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.rent_payment_schedule && (
                            <span>
                              {data.project_unit.rent_payment_schedule === 1
                                ? "Monthly"
                                : data.project_unit.rent_payment_schedule === 2
                                ? "Quaterly"
                                : data.project_unit.rent_payment_schedule === 3
                                ? "Half Yearly"
                                : data.project_unit.rent_payment_schedule === 4
                                ? "Yearly"
                                : ""}
                            </span>
                          )}{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">
                          Customer Lock-in Period* (Months)
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.customer_lock_in_period}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          Notice Period (Months)
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.notice_period}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          Security Deposit For Rent
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.security_deposit_for_rent}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">
                          Security Deposit For Electricity
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data &&
                            data.project_unit?.security_deposit_for_electricity}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          Security Deposit For Maintenance
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {(data &&
                            data.project_unit
                              ?.security_deposit_for_maintenance) ||
                            "NA"}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          Agreement Start Date*{" "}
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.agreement_start_date}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">
                          {" "}
                          Agreement End Date*{" "}
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.agreement_end_date}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          Rent Escalation(%){" "}
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data &&
                            data.project_unit?.rent_escalation_percentage}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          Registration cost for Agreement on Company{" "}
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data &&
                            data.project_unit
                              ?.registration_cost_for_agreement_on_company}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">
                          {" "}
                          Rent Escalation Period (Months){" "}
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_unit?.rent_escalation_period}
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Col>
                      <CardTitle className="mb-3">
                        Rent and Maintenance{" "}
                      </CardTitle>

                      <Row>
                        <Col>
                          <Label className="form-label">Invoice Type</Label>
                          {data?.project_unit?.billing_components?.map(
                            (item, index) => (
                              <p
                                key={index}
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {item.description}
                              </p>
                            )
                          )}
                        </Col>

                        <Col>
                          <Label className="form-label">Amount</Label>
                          {data?.project_unit?.billing_components?.map(
                            (item, index) => (
                              <p
                                key={index}
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {item.amount}
                              </p>
                            )
                          )}
                        </Col>

                        <Col>
                          <Label className="form-label">SAC / HSN Code</Label>
                          {data?.project_unit?.billing_components?.map(
                            (item, index) => (
                              <p
                                key={index}
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {item.sac_hsn_id}
                              </p>
                            )
                          )}
                        </Col>

                        <Col>
                          <Label className="form-label">GST Rate</Label>
                          {data?.project_unit?.billing_components?.map(
                            (item, index) => (
                              <p
                                key={index}
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {item.gst_percentage}
                              </p>
                            )
                          )}
                        </Col>
                        <Col>
                          <Label className="form-label">GST Amount</Label>
                          {data?.project_unit?.billing_components?.map(
                            (item, index) => (
                              <p
                                key={index}
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {item.gst_amount}
                              </p>
                            )
                          )}
                        </Col>
                        <Col>
                          <Label className="form-label">Total</Label>
                          {data?.project_unit?.billing_components?.map(
                            (item, index) => (
                              <p
                                key={index}
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {item.total_amount}
                              </p>
                            )
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Row>
                      <Col>
                        <div className="mb-3 text-end">
                          <button
                            type="submit"
                            className="btn btn-success l-md me-2"
                            onClick={() => {
                              approveProjectApi("1")
                            }}
                          >
                            Approve{" "}
                          </button>

                          <button
                            type="submit"
                            className="btn btn-danger l-md"
                            onClick={() => {
                              approveProjectApi("3")
                            }}
                          >
                            Reject{" "}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectUnitsApprovalView
