import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import { addNewFileConfiguration } from "helpers/fakebackend_helper"

const FileConfiguration = props => {
  const { data } = props

  const validation1 = useFormik({
    initialValues: {
      //file configuration
      file_system: (data && data.file_system) || "",
      folder_path: (data && data.folder_path) || "",
      aws_access_key_id: (data && data.aws_access_key_id) || "",
      aws_secret_key: (data && data.aws_secret_key) || "",
      aws_region: (data && data.aws_region) || "",
      aws_bucket: (data && data.aws_bucket) || "",
      aws_endpoint: (data && data.aws_endpoint) || "",
    },
    validation1Schema: {
      //file configuration
      file_system: Yup.string().required("Please Select Your File System"),
      folder_path: Yup.string().required("Please Select Folder Path"),
      aws_access_key_id: Yup.string().required(
        "Please Enter AWS Access Key Id"
      ),
      aws_secret_key: Yup.string().required("Please Enter AWS Secret Key"),
      aws_region: Yup.string().required("Please Enter AWS Region"),
      aws_bucket: Yup.string().required("Please Enter AWS Bucket"),
      aws_endpoint: Yup.string().required("Please Enter AWS Endpoint"),
    },

    onSubmit: async values => {
      try {
        const response = await addNewFileConfiguration(values)
        if (response && response.status === true) {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your File Configuration has been Submitted.",
          })
        }
      } catch (error) {
        console.error("Network Error:", error.response.data.message)
      }
    },
  })

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">File Configuration</CardTitle>
        <Form autoComplete="off">
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="file_system">Your File System</Label>
                <select
                  name="file_system"
                  placeholder="Select File System"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.file_system || ""}
                  className={`form-select ${
                    validation1.touched.file_system &&
                    validation1.errors.file_system
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="0">Select File System</option>
                  <option value="Local-Server">Local-Server</option>
                  <option value="AWS-S3-Bucket">AWS-S3-Bucket</option>
                </select>
                {validation1.touched.file_system &&
                validation1.errors.file_system ? (
                  <FormFeedback type="invalid">
                    {validation1.errors.file_system}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            {validation1.values.file_system === "Local-Server" && (
              <Col>
                <div className="mb-3">
                  <Label htmlFor="folder_path">Folder Path</Label>
                  <Input
                    type="text"
                    name="folder_path"
                    className="form-control"
                    id="formrow-path-Input"
                    placeholder="Enter Folder Path"
                    onChange={validation1.handleChange}
                    onBlur={validation1.handleBlur}
                    value={validation1.values.folder_path || ""}
                    invalid={
                      validation1.touched.folder_path &&
                      validation1.errors.folder_path
                        ? true
                        : false
                    }
                  />
                  {validation1.touched.folder_path &&
                  validation1.errors.folder_path ? (
                    <FormFeedback type="invalid">
                      {validation1.errors.folder_path}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            )}

            {validation1.values.file_system === "AWS-S3-Bucket" && (
              <>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="aws_access_key_id">AWS Access Key Id</Label>
                    <Input
                      type="text"
                      id="formrow-aws-access-key"
                      name="aws_access_key_id"
                      placeholder="Enter your AWS Access Key Id"
                      onChange={validation1.handleChange}
                      onBlur={validation1.handleBlur}
                      value={validation1.values.aws_access_key_id || ""}
                      className={`form-control ${
                        validation1.touched.aws_access_key_id &&
                        validation1.errors.aws_access_key_id
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="aws_secret_key">AWS Secret Key </Label>
                    <Input
                      type="text"
                      id="formrow-aws-secret-key"
                      name="aws_secret_key"
                      placeholder="Enter your AWS Access Key Id"
                      onChange={validation1.handleChange}
                      onBlur={validation1.handleBlur}
                      value={validation1.values.aws_secret_key || ""}
                      className={`form-control ${
                        validation1.touched.aws_secret_key &&
                        validation1.errors.aws_secret_key
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                  </div>
                </Col>
              </>
            )}
          </Row>

          {validation1.values.file_system === "AWS-S3-Bucket" && (
            <>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="aws_region">AWS Region</Label>
                    <Input
                      type="text"
                      id="formrow-aws-region"
                      name="aws_region"
                      placeholder="Enter your AWS Access Key Id"
                      onChange={validation1.handleChange}
                      onBlur={validation1.handleBlur}
                      value={validation1.values.aws_region || ""}
                      className={`form-control ${
                        validation1.touched.aws_region &&
                        validation1.errors.aws_region
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="aws_bucket">AWS Bucket</Label>
                    <Input
                      type="text"
                      id="formrow-aws-bucket"
                      name="aws_bucket"
                      placeholder="Enter your AWS Bucket"
                      onChange={validation1.handleChange}
                      onBlur={validation1.handleBlur}
                      value={validation1.values.aws_bucket || ""}
                      className={`form-control ${
                        validation1.touched.aws_bucket &&
                        validation1.errors.aws_bucket
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="aws_endpoint">AWS Endpoint</Label>
                    <Input
                      type="text"
                      id="formrow-aws-endpoint"
                      name="aws_endpoint"
                      placeholder="Enter your AWS Endpoint"
                      onChange={validation1.handleChange}
                      onBlur={validation1.handleBlur}
                      value={validation1.values.aws_endpoint || ""}
                      className={`form-control ${
                        validation1.touched.aws_endpoint &&
                        validation1.errors.aws_endpoint
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary save-user"
                onClick={validation1.handleSubmit}
              >
                Save Changes
              </button>
            </div>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}
export default FileConfiguration
