import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  FormFeedback,
} from "reactstrap"
import {
  addCreditNote,
  addNewBill,
  getApplicableRateList,
  getAssignedProjectUnitListInBilling,
  getBillingClientDetail,
  getBillingClientList,
  getBillingCompanyDetail,
  getBillingData,
  getComponentsForCreditNote,
  getCreditNoteInvoiceList,
  getCreditNoteReasons,
  getCreditNumber,
  getInvoiceTypeMasterList,
  getPlaceOfSupply,
  getProjectDetailInBilling,
  getProjectListInBilling,
  getProjectUnitDetailInBilling,
  getSacHsnCodeBilling,
  getSupplyList,
} from "store/actions"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { createSelector } from "reselect"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"

const AddCreditNote = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [clientId, setClientId] = useState()
  const BillingState = state => state.billing
  const AllBillingProperties = createSelector(
    BillingState,

    billing => ({
      clientList: billing.clientList.data,
      clientDetail: billing.clientDetail.client,
      engagementService: billing.serviceEngagement.project_unit,
      billingCompanyList: billing.companyList.companies,
      billingCompanyDetail: billing.companyDetail.company,
      billingCompanylocation: billing.companyDetail.location,
      typeOfSupplyList: billing.supplyList.data,
      placeOfSupplyList: billing.placeOfSupply.data,
      applicableRateList: billing.applicableRateList.data,
      invoiceTypeMasterList: billing.invoiceTypeMasterList.data,
      sacHsnCode: billing.codesBilling.data,
      assignedProjectUnitList: billing.assignedProjectUnit.project_units,
      projectList: billing.projectList.data,
      projectDetail: billing.projectDetail.data,
      projectUnitDetail: billing.projectUnitDetail.project_unit,
      //
      creditNumber: billing.creditNumber.credit_number,
      invoiceList: billing?.creditNoteInvoiceList?.billings,
      reasonsList: billing?.creditNoteReasons?.companies,
      billingData: billing.billingData.billing,
    })
  )

  const {
    clientList,
    clientDetail,
    billingCompanyDetail,
    typeOfSupplyList,
    placeOfSupplyList,
    creditNumber,
    sacHsnCode,
    assignedProjectUnitList,
    projectList,
    projectDetail,
    projectUnitDetail,
    billingCompanylocation,
    //
    invoiceList,
    reasonsList,
    billingData,
  } = useSelector(AllBillingProperties)

  console.log("creditNumber", creditNumber)
  useEffect(() => {
    dispatch(getSupplyList())
    dispatch(getPlaceOfSupply())
    dispatch(getApplicableRateList())
    dispatch(getInvoiceTypeMasterList())
    dispatch(getSacHsnCodeBilling())
    dispatch(getCreditNoteReasons())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      dispatch(getProjectListInBilling(user.user_id))
    }
  }, [user])

  //FORMIK VALIDATION
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      client: "",
      client_id: "",
      company_id: "",
      client_project_unit_id: "",
      project_id: "",
      location_id: "",
      company_id: "",
      credit_note_date: new Date().toISOString().split("T")[0] || "",
      place_of_supply_id: "",
      type_of_supply_id: "1",
      gst_rate: "",
      tax_amount: "",

      billing_components: [],
      //
      remarks: "",
      credit_note_no: "",
      billing_id: "",
      reason_id: "",
      reason: "",
    },
    validationSchema: Yup.object().shape({
      // client_id: Yup.number().required("Please select Customer"),
      billing_id: Yup.number().required("Please select Original Billing Invoice"),
      // company_id: Yup.number().required("Please select Company"),
      // place_of_supply: Yup.number().required("Please select Place of Supply"),
      // type_of_supply: Yup.number().required("Please select Type of Supply"),
      // tax_amount: Yup.number(),
      // credit_note_date: Yup.string().required("Credit Note date is required"),
    }),

    onSubmit: async values => {
      dispatch(addCreditNote(props.router.navigate, values))
    },
  })

  useEffect(() => {
    if (validation.values.billing_id) {
      dispatch(getBillingData(validation.values.billing_id))
    }
  }, [validation.values.billing_id])

  console.log("projectUnitDetail", projectUnitDetail)

  useEffect(() => {
    if (validation.values.client_id) {
      const value = {
        project_id: validation.values.project_id,
        client_id: validation.values.client_id,
      }
      dispatch(getAssignedProjectUnitListInBilling(value))
    }
  }, [validation.values.client_id])

  useEffect(() => {
    if (validation.values.project_id) {
      dispatch(getBillingClientList(validation.values.project_id))
      dispatch(getProjectDetailInBilling(validation.values.project_id))
    }
  }, [validation.values.project_id])

  useEffect(() => {
    if (validation.values.client_project_unit_id) {
      dispatch(
        getProjectUnitDetailInBilling(validation.values.client_project_unit_id)
      )
      // dispatch(
      //   getComponentsForCreditNote(validation.values.client_project_unit_id)
      // )
      dispatch(
        getCreditNoteInvoiceList(validation.values.client_project_unit_id)
      )
    }
  }, [validation.values.client_project_unit_id])

  useEffect(() => {
    let placeOfSupplyValue = ""

    if (clientDetail?.state) {
      placeOfSupplyValue = clientDetail?.state
    } else if (billingCompanyDetail?.state) {
      placeOfSupplyValue = billingCompanyDetail?.state
    }

    validation.setFieldValue("place_of_supply", placeOfSupplyValue)
  }, [clientDetail?.state, billingCompanyDetail?.state])

  useEffect(() => {
    if (billingCompanylocation && billingCompanylocation.id) {
      validation.setFieldValue("location_id", billingCompanylocation.id)
    }
  }, [billingCompanylocation])

  console.log("validation.values", validation.values)
  useEffect(() => {
    if (validation.values.client_id) {
      const clientId = validation.values.client_id
      setClientId(clientId)
      dispatch(dispatch(getBillingClientDetail(clientId)))
    }
    // validation.setFieldValue("client_id", clientId)
  }, [validation.values.client_id])

  useEffect(() => {
    if (validation.values.client_project_unit_id) {
      console.log("company")
      dispatch(
        getBillingCompanyDetail(validation.values.client_project_unit_id)
      )
    }
  }, [validation.values.client_project_unit_id])

  useEffect(() => {
    console.log("invoicenumber")
    if (billingCompanylocation && validation.values.credit_note_date) {
      dispatch(
        getCreditNumber({
          location_id: billingCompanylocation.id,
          credit_note_date: validation.values.credit_note_date,
        })
      )
    }
  }, [billingCompanylocation, validation.values.credit_note_date])

  useEffect(() => {
    if (billingCompanyDetail) {
      validation.setFieldValue("company_id", billingCompanyDetail.id)
    }
  }, [billingCompanyDetail])

  // useEffect(() => {
  //   //turning this off until client requests to turn it on.
  //   if (creditNumber) {
  //     validation.setFieldValue("credit_note_no", creditNumber)
  //   }
  // }, [creditNumber])

  const [consumption, setConsumption] = useState({
    Electricity: 0,
    Gas: 0,
    Water: 0,
    DG: 0,
  })

  useEffect(() => {
    if (billingData) {
      const billingComponents = billingData.billing_components
        .filter(component => component?.check === 1) // Filter components with check equal to 1
        .map(component => {
          let amt_per_unit = component?.amt_per_unit || 0
          let rate_per_unit = component?.rate_per_unit || 0
          let unit = component?.unit || 0
          let ConsumptionType = component?.consumption_type
  
          return {
            invoice_type_id: component?.invoice_type_id,
            invoice_type: component?.invoice_type || "",
            amt_per_unit: amt_per_unit,
            consumption_type: component?.consumption_type,
            unit: unit,
            rate_per_unit: rate_per_unit,
            sac_hsm_id: component?.sac_hsm_id || "",
            description: component?.description || "",
            sac_hsn: component?.sac_hsm_code || "",
            gst_percentage: component?.gst_percentage || "",
            discount: 0,
            check: component?.check || 0,
          }
        })
      
      console.log("Updated billing components:", billingComponents)
  
      validation.setFieldValue("billing_components", billingComponents)
      validation.setFieldValue("place_of_supply_id", billingData.place_of_supply)
      validation.setFieldValue("type_of_supply_id", billingData.type_of_supply)
    }
  }, [billingData])
  
  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Form>
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
                Create Credit Note
              </div>
            </CardTitle>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="mb-3">Client Details</CardTitle> */}
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.project_id &&
                              validation.errors.project_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="project_id"
                            value={validation.values.project_id || ""}
                          >
                            <option value="">Select Project</option>
                            {(projectList || []).map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_id &&
                            validation.errors.project_id && (
                              <div className="invalid-feedback">
                                {validation.errors.project_id}
                              </div>
                            )}
                        </div>
                        {validation.values.project_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Project Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_name">
                                        Project Name
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="property_type">
                                        Property Type
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.property_type}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_use">
                                        Project Use
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_use}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_area_sqft">
                                        Project Area
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_area_sqft}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_type">
                                        Project Type
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.project_type}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="total_floors">
                                        Total Floors
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.total_floors}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="location">Location</Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.location}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_manager_id">
                                        Project Manager
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.full_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="parking_available">
                                        Parking Available
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                        projectDetail.parking_available === 1
                                          ? "Yes"
                                          : "No"}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="parking_type">
                                        Parking Type
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.parking_type}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="contract_terms_start_date">
                                        Contract Start Date
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.contract_terms_start_date}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="contract_terms_end_date">
                                        Contract End Date
                                      </Label>
                                      <p>
                                        {projectDetail &&
                                          projectDetail.contract_terms_end_date}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.client_id &&
                              validation.errors.client_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="client_id"
                            value={validation.values.client_id || ""}
                          >
                            <option value="">Select Client</option>
                            {(clientList || []).map(client => (
                              <option key={client.id} value={client.id}>
                                {client.customer_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.client_id &&
                            validation.errors.client_id && (
                              <div className="invalid-feedback">
                                {validation.errors.client_id}
                              </div>
                            )}
                        </div>
                        {validation.values.client_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Client Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="customer_code">
                                        Customer Code
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.customer_code}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="customer_name">
                                        Customer Name
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.customer_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="email_address">
                                        Email Address
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.email_address}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="mobile_no">
                                        Mobile Number
                                      </Label>
                                      <p>
                                        {clientDetail && clientDetail.mobile_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="address">Address</Label>
                                      <p>
                                        {clientDetail && clientDetail.address}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pincode">Pincode</Label>
                                      <p>
                                        {clientDetail && clientDetail.pincode}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="country_name">
                                        Country
                                      </Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.country_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="state_name">State</Label>
                                      <p>
                                        {clientDetail &&
                                          clientDetail.state_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="city_name">City</Label>
                                      <p>
                                        {clientDetail && clientDetail.city_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="gst_no">GST Number</Label>
                                      <p>
                                        {clientDetail && clientDetail.gst_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pan_no">PAN Number</Label>
                                      <p>
                                        {clientDetail && clientDetail.pan_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.client_project_unit_id &&
                              validation.errors.client_project_unit_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="client_project_unit_id"
                            value={validation.values.client_project_unit_id}
                          >
                            <option value="">Select Project Unit</option>
                            {(assignedProjectUnitList || []).map(company => (
                              <option key={company.id} value={company.id}>
                                {company.unit_code}
                              </option>
                            ))}
                          </select>
                          {validation.touched.client_project_unit_id &&
                            validation.errors.client_project_unit_id && (
                              <div className="invalid-feedback">
                                {validation.errors.client_project_unit_id}
                              </div>
                            )}
                        </div>
                        {validation.values.client_project_unit_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Project Unit Detail (
                                  {projectUnitDetail &&
                                    projectUnitDetail.unit_code}
                                  )
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="project_name">
                                        Project Name
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.project_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="brand_name">Brand</Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.brand_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="revenue_share">
                                        Revenue Share
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          (projectUnitDetail.revenue_share === 1
                                            ? "Monthly"
                                            : "Annually")}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="revenue_share_percentage">
                                        Revenue Share(%)
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.revenue_share_percentage}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="rent_payment_schedule">
                                        Rent Payment Schedule
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          (projectUnitDetail.rent_payment_schedule ===
                                          1
                                            ? "Monthly"
                                            : projectUnitDetail.rent_payment_schedule ===
                                              2
                                            ? "Quarterly"
                                            : projectUnitDetail.rent_payment_schedule ===
                                              3
                                            ? "Half Yearly"
                                            : "Yearly")}{" "}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="customer_lock_in_period">
                                        Customer Lock-In Period(Months)
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.customer_lock_in_period}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="notice_period">
                                        Notice Period(Months)
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.notice_period}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="security_deposit_for_rent">
                                        Security Deposit for Rent
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.security_deposit_for_rent}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="security_deposit_for_electricity">
                                        Security Deposit for Electricity
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.security_deposit_for_electricity}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="security_deposit_for_maintenance">
                                        Security Deposit for Maintenance
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.security_deposit_for_maintenance}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="agreement_start_date">
                                        Agreement Start Date
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.agreement_start_date}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="agreement_end_date">
                                        Agreement End Date
                                      </Label>
                                      <p>
                                        {projectUnitDetail &&
                                          projectUnitDetail.agreement_end_date}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        {validation.values.client_project_unit_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Company Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="name">Company Name</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="address_line_1">
                                        Address
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.address_line_1}{" "}
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.address_line_2}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pin_code">Pincode</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.pin_code}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="country_name">
                                        Country
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.country_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="state_name">State</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.state_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="city_name">City</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.city_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                      <Col lg={4}>
                        {validation.values.client_project_unit_id && (
                          <Card>
                            <CardBody
                              style={{
                                backgroundColor: "#EEEEEE",
                                borderRadius: "15px",
                              }}
                            >
                              <CardTitle className="mb-3">
                                Billing Company's Location Detail
                              </CardTitle>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Address</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.location_title}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Country</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.country_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">State</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.state_name}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">City</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.city_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">GST No.</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.gst_no}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="billing_id">Original Invoice No. & Date</Label>
                          <select
                            className={`form-select ${
                              validation.touched.billing_id &&
                              validation.errors.billing_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="billing_id"
                            value={validation.values.billing_id}
                          >
                            <option value="">Select Original Invoice</option>
                            {(invoiceList || []).map(inv => (
                              <option
                                key={inv.billing_id}
                                value={inv.billing_id}
                              >
                                {inv.bill_no} dt. {inv.bill_date}{" "}
                                {/* {inv.bill_status === 1 ? "Paid" : "Unpaid"} */}
                              </option>
                            ))}
                          </select>
                          {validation.touched.billing_id &&
                            validation.errors.billing_id && (
                              <div className="invalid-feedback">
                                {validation.errors.billing_id}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {projectUnitDetail && (
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">
                        Billing Item Details
                      </CardTitle>
                      <Row>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Invoice Components</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Description</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Unit</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Rate</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Amount</Label>
                          </div>
                        </Col>

                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>SAC / HSN Code</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>GST Rate</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>GST Amount</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label>Total</Label>
                          </div>
                        </Col>
                        <Col lg={1}>
                          <div className="mb-3">
                            <Label> </Label>
                          </div>
                        </Col>
                      </Row>
                      {validation.values.billing_components.map(
                        (row, key) =>
                          row.check === 1 && (
                            <Row key={key}>
                              <Col lg={1} className="d-flex align-items-center">
                                <div className="mb-3">
                                  <p
                                    htmlFor={`invoice_type_${key}`}
                                    className="form-label"
                                  >
                                    {row.invoice_type}
                                  </p>
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`description_${key}`}
                                    name={`billing_components[${key}].description`}
                                    placeholder="Enter Description"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.description || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.description &&
                                    validation.errors.billing_components?.[key]
                                      ?.description && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.description
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`unit${key}`}
                                    name={`billing_components[${key}].unit`}
                                    placeholder="Enter Unit"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.unit || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.unit &&
                                    validation.errors.billing_components?.[key]
                                      ?.unit && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.unit
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`rate_per_unit${key}`}
                                    name={`billing_components[${key}].rate_per_unit`}
                                    placeholder="Enter Rate"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.rate_per_unit || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.rate_per_unit &&
                                    validation.errors.billing_components?.[key]
                                      ?.rate_per_unit && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.rate_per_unit
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`amount_${key}`}
                                    disabled
                                    name={`billing_components[${key}].amt_per_unit`}
                                    placeholder="Enter Amount"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.unit *
                                        validation.values.billing_components[
                                          key
                                        ]?.rate_per_unit || 0
                                    }
                                  />
                                </div>
                              </Col>

                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    id={`sac_hsn_code_${key}`}
                                    name={`billing_components[${key}].sac_hsn`}
                                    placeholder="NA"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    disabled
                                    value={
                                      validation.values.billing_components[key]
                                        ?.sac_hsn || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.sac_hsn_code &&
                                    validation.errors.billing_components?.[key]
                                      ?.sac_hsn_code && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.sac_hsn_code
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    disabled
                                    id={`gst_percentage${key}`}
                                    name={`billing_components[${key}].gst_percentage`}
                                    placeholder="NA"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.billing_components[key]
                                        ?.gst_percentage || ""
                                    }
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.gst_percentage &&
                                    validation.errors.billing_components?.[key]
                                      ?.gst_percentage && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.gst_percentage
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>

                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      (validation.values.billing_components[key]
                                        ?.unit *
                                        validation.values.billing_components[
                                          key
                                        ]?.rate_per_unit -
                                        validation.values.billing_components[
                                          key
                                        ]?.discount) *
                                        (validation.values.billing_components[
                                          key
                                        ]?.gst_percentage /
                                          100) || "0"
                                    }
                                    id={`gst_amount${key}`}
                                    name={`billing_components[${key}].gst_amount`}
                                    placeholder="GST Amount"
                                    disabled
                                  />
                                  {validation.touched.billing_components?.[key]
                                    ?.gst_amount &&
                                    validation.errors.billing_components?.[key]
                                      ?.gst_amount && (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors.billing_components[
                                            key
                                          ]?.gst_amount
                                        }
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col lg={1}>
                                <div className="mb-3">
                                  <Input
                                    type="text"
                                    name={`billing_components[${key}].total`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      parseFloat(
                                        validation.values.billing_components[
                                          key
                                        ]?.unit *
                                          validation.values.billing_components[
                                            key
                                          ]?.rate_per_unit || 0
                                      ) -
                                        parseFloat(
                                          validation.values.billing_components[
                                            key
                                          ]?.discount || 0
                                        ) +
                                        (((parseFloat(
                                          validation.values.billing_components[
                                            key
                                          ]?.unit *
                                            validation.values
                                              .billing_components[key]
                                              ?.rate_per_unit
                                        ) || 0) -
                                          parseFloat(
                                            validation.values
                                              .billing_components[key]
                                              ?.discount || 0
                                          )) *
                                          (parseFloat(
                                            validation.values
                                              .billing_components[key]
                                              ?.gst_percentage
                                          ) /
                                            100) || 0) || "0"
                                    }
                                    id={`total_${key}`}
                                    placeholder="Total"
                                    disabled
                                  />
                                </div>
                              </Col>
                            </Row>
                          )
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4} xxl={3}>
                        <div className="mb-3">
                          <Label htmlFor="credit_note_date">
                            Credit Note Date
                          </Label>
                          <Input
                            type="date"
                            id="credit_note_date"
                            name="credit_note_date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.credit_note_date}
                            className={`form-control ${
                              validation.touched.credit_note_date &&
                              validation.errors.credit_note_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.credit_note_date &&
                            validation.errors.credit_note_date && (
                              <FormFeedback type="invalid">
                                {validation.errors.credit_note_date}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={4} xxl={3}>
                        <div className="mb-3">
                          <Label htmlFor="credit_note_no">
                            Credit Note Number*
                          </Label>
                          <Input
                            type="text"
                            name="credit_note_no"
                            placeholder="Enter Credit Note No"
                            className={`form-control`}
                            id="credit_note_no"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.credit_note_no || ""}
                          />
                        </div>
                      </Col>

                      <Col lg={4} xxl={3}>
                        <div className="mb-3">
                          <Label htmlFor="type_of_supply_id">Type of supply</Label>
                          <select
                            className={`form-select ${
                              validation.touched.type_of_supply_id &&
                              validation.errors.type_of_supply_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="type_of_supply_id"
                            value={validation.values.type_of_supply_id}
                          >
                            <option value="">Select Type of supply</option>
                            {(typeOfSupplyList || []).map(supply => (
                              <option key={supply.id} value={supply.id}>
                                {supply.gst_type_of_supply}
                              </option>
                            ))}
                          </select>
                          {validation.touched.type_of_supply_id &&
                            validation.errors.type_of_supply_id && (
                              <div className="invalid-feedback">
                                {validation.errors.type_of_supply_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={4} xxl={3}>
                        <div className="mb-3">
                          <Label htmlFor="place_of_supply_id">
                            Place of supply
                          </Label>
                          <select
                            className={`form-select ${
                              validation.touched.place_of_supply_id &&
                              validation.errors.place_of_supply_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="place_of_supply_id"
                            value={validation.values.place_of_supply_id}
                          >
                            <option>Select Place of supply</option>
                            {(placeOfSupplyList || []).map(supply => (
                              <option key={supply.id} value={supply.id}>
                                {supply.state}
                              </option>
                            ))}
                          </select>
                          {validation.touched.place_of_supply_id &&
                            validation.errors.place_of_supply_id && (
                              <div className="invalid-feedback">
                                {validation.errors.place_of_supply_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={4} xxl={3}>
                        <div className="mb-3">
                          <Label htmlFor="reason_id">Reason</Label>
                          <select
                            className={`form-select ${
                              validation.touched.reason_id &&
                              validation.errors.reason_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="reason_id"
                            value={validation.values.reason_id}
                          >
                            <option>Select Reason</option>
                            {(reasonsList || []).map(reason => (
                              <option
                                key={reason.reason_id}
                                value={reason.id}
                              >
                                {reason.reason}
                              </option>
                            ))}
                          </select>
                          {validation.touched.reason_id &&
                            validation.errors.reason_id && (
                              <div className="invalid-feedback">
                                {validation.errors.reason_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      {/* {Number(validation.values.reason_id) === 7 && (
                        <Col lg={4} xxl={6}>
                          <div className="mb-3">
                            <Label htmlFor="reason">Other Reasons</Label>
                            <Input
                              type="textarea"
                              name="reason"
                              className={`form-control`}
                              id="reason"
                              onChange={validation.handleChange}
                              placeholder="Enter Reason"
                              value={validation.values.reason || ""}
                            />
                          </div>
                          {validation.touched.reason &&
                            validation.errors.reason && (
                              <div className="invalid-feedback">
                                {validation.errors.reason}
                              </div>
                            )}
                        </Col>
                      )} */}
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="remarks">Remark</Label>
                          <Input
                            type="textarea"
                            name="remarks"
                            className={`form-control`}
                            id="remarks"
                            onChange={validation.handleChange}
                            placeholder="Enter Remark"
                            value={validation.values.remarks || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button
                    type="button"
                    className="btn btn-primary w-md me-4"
                    style={{ width: "10%" }}
                    onClick={validation.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddCreditNote)

AddCreditNote.propTypes = {
  history: Proptypes.object,
}
