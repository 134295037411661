import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"

// Crypto Redux States
import {
  GET_MATERIAL_MASTER_LIST,
  GET_MATERIAL_MASTER_DETAIL,
  ADD_MATERIAL_MASTER,
  UPDATE_MATERIAL_MASTER,
  DELETE_MATERIAL_MASTER,
  GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER,
  ADD_NEW_SACHSNCODE_MATERIAL_MASTER,
} from "./actionTypes"
import {
  getMaterialMasterListSuccess,
  getMaterialMasterListFail,
  getMaterialMasterDetailSuccess,
  getMaterialMasterDetailFail,
  addNewMaterialMasterSuccess,
  addNewMaterialMasterFail,
  updateMaterialMasterSuccess,
  updateMaterialMasterFail,
  deleteMaterialMasterSuccess,
  deleteMaterialMasterFail,
  getSacHsnCodeForMaterialMasterSuccess,
  getSacHsnCodeForMaterialMasterFail,
  addNewSACHSNCodeMaterialMasterSuccess,
  addNewSACHSNCodeMaterialMasterFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMaterialMasterList,
  getMaterialMasterDetail,
  addNewMaterialMaster,
  updateMaterialMaster,
  deleteMaterialMaster,
  getSacHsnCodeForMaterialMaster,
  addNewSACHSNCodeMaterialMaster,
} from "helpers/fakebackend_helper"

function* fetchMaterialMasterList() {
  try {
    const response = yield call(getMaterialMasterList)
    yield put(getMaterialMasterListSuccess(response))
  } catch (error) {
    yield put(getMaterialMasterListFail(error))
  }
}

function* fetchMaterialMasterDetail({ materialId }) {
  try {
    const response = yield call(getMaterialMasterDetail, materialId)
    yield put(getMaterialMasterDetailSuccess(response))
  } catch (error) {
    yield put(getMaterialMasterDetailFail(error))
  }
}

function* onAddNewMaterialMaster({ payload: { materials, history } }) {
  try {
    const response = yield call(addNewMaterialMaster, materials)
    if (response.status === true) {
      yield put(addNewMaterialMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Material Master Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/material-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewMaterialMasterFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onUpdateMaterialMaster({ payload: { id, material, history } }) {
  try {
    const response = yield call(updateMaterialMaster, id, material)
    if (response && response.status === true) {
      yield put(updateMaterialMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Material Master Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/material-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateMaterialMasterFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteMaterialMaster({ payload: code }) {
  try {
    const response = yield call(deleteMaterialMaster, code)
    console.log(response)
    const response2 = yield put(deleteMaterialMasterSuccess(response))
    console.log(response2)
    // yield put(deleteSACHSNCodeSuccess(response))
    if (response2.payload.status) {
      const materialMasterResponse = yield call(getMaterialMasterList)
      yield put(getMaterialMasterListSuccess(materialMasterResponse))
    } else {
      console.error("Error in delete Material Master:", response.error)
    }
  } catch (error) {
    yield put(deleteMaterialMasterFail(error))
  }
}

function* fetchSACHSNCodeForMaterialMaster() {
  try {
    const response = yield call(getSacHsnCodeForMaterialMaster)
    yield put(getSacHsnCodeForMaterialMasterSuccess(response))
  } catch (error) {
    yield put(getSacHsnCodeForMaterialMasterFail(error))
  }
}

function* onAddNewSACHSNCode({ payload: { codes } }) {
  try {
    const response = yield call(addNewSACHSNCodeMaterialMaster, codes)
    if (response.status === true) {
      yield put(addNewSACHSNCodeMaterialMasterSuccess(response))
      const response2 = yield call(getSacHsnCodeForMaterialMaster)
      if (response2.status === true) {
        yield put(getSacHsnCodeForMaterialMasterSuccess(response2))
      }
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your SAC / HSN Code Master Has Been Created.",
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewSACHSNCodeMaterialMasterFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* materialMasterSaga() {
  yield takeEvery(GET_MATERIAL_MASTER_LIST, fetchMaterialMasterList)
  yield takeEvery(GET_MATERIAL_MASTER_DETAIL, fetchMaterialMasterDetail)
  yield takeEvery(ADD_MATERIAL_MASTER, onAddNewMaterialMaster)
  yield takeEvery(UPDATE_MATERIAL_MASTER, onUpdateMaterialMaster)
  yield takeEvery(DELETE_MATERIAL_MASTER, onDeleteMaterialMaster)
  yield takeEvery(
    GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER,
    fetchSACHSNCodeForMaterialMaster
  )
  yield takeEvery(ADD_NEW_SACHSNCODE_MATERIAL_MASTER, onAddNewSACHSNCode)
}

export default materialMasterSaga
