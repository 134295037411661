import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ROLES,
  GET_MODULES,
  GET_ROLE_DETAIL,
  ADD_NEW_ROLE,
  DELETE_ROLE,
  UPDATE_ROLE,
  
  GET_MANAGE_PERMISSION
} from "./actionTypes"
import {
  getRolesSuccess,
  getRolesFail,
  getModulesSuccess,
  getModulesFail,
  getRoleDetailSuccess,
  getRoleDetailFail,
  addRoleFail,
  addRoleSuccess,
  updateRoleSuccess,
  updateRoleFail,
  deleteRoleSuccess,
  deleteRoleFail,
  getMenuSuccess,
  getMenuFail,
  getManagePermissionSuccess,
  getManagePermissionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getRoles,
  getRoleDetails,
  addNewRole,
  updateRole,
  deleteRole,
  getModules,
  getMenu,
  getManagePermission,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"


function* fetchRoles() {
  try {
    const response = yield call(getRoles)
    yield put(getRolesSuccess(response))
  } catch (error) {
    yield put(getRolesFail(error))
  }
}
function* fetchModules({ id }) {
  try {
    const response = yield call(getModules, id)
    yield put(getModulesSuccess(response))
  } catch (error) {
    yield put(getModulesFail(error))
  }
}

function* fetchManagePermission({ id }) {
  try {
    const response = yield call(getManagePermission, id)
    yield put(getManagePermissionSuccess(response))
  } catch (error) {
    yield put(getManagePermissionFail(error))
  }
}

function* fetchRoleDetail({ roleId }) {
  try {
    const response = yield call(getRoleDetails, roleId)
    yield put(getRoleDetailSuccess(response))
  } catch (error) {
    yield put(getRoleDetailFail(error))
  }
}

function* onUpdateRole({ payload: { id, role } }) {
  try {
    const response = yield call(updateRole, id, role)
    yield put(updateRoleSuccess(response))
  } catch (error) {
    yield put(updateRoleFail(error))
  }
}

function* onDeleteRole({ payload: role }) {
  try {
    const response = yield call(deleteRole, role)
    yield put(deleteRoleSuccess(response))
  } catch (error) {
    yield put(deleteRoleFail(error))
  }
}

function* onAddNewRole({ payload: role }) {
  try {
    const response = yield call(addNewRole, role)
    yield put(addRoleSuccess(response))
  } catch (error) {
    yield put(addRoleFail(error))
  }
}



function* rolesSaga() {
  yield takeEvery(GET_ROLES, fetchRoles)
  yield takeEvery(GET_MODULES, fetchModules)
  yield takeEvery(GET_MANAGE_PERMISSION, fetchManagePermission)
  yield takeEvery(GET_ROLE_DETAIL, fetchRoleDetail)
  yield takeEvery(ADD_NEW_ROLE, onAddNewRole)
  yield takeEvery(UPDATE_ROLE, onUpdateRole)
  yield takeEvery(DELETE_ROLE, onDeleteRole)

}

export default rolesSaga
