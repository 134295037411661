import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Col,
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { deleteProjectUnit, getProjectUnit } from "store/actions"
import DeleteModal from "components/Common/DeleteModal"

const ProjectUnitView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const projectUnitState = state => state.projects.units.data
  const ProjectUnits = useSelector(projectUnitState) || []
  const [projectId, setProjectId] = useState()
  const projectActiveDocuments = async () => {
    const url = new URL(window.location.href)
    const projectId = url.pathname.split("/project-details/")[1]
    setProjectId(projectId)
    try {
      const response = await dispatch(getProjectUnit(projectId))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    projectActiveDocuments()
  }, [dispatch])

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = userData => {
    // dispatch(deleteVendor(userData))
    setDeleteId({ id: userData })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    // if (deleteId && deleteId.id) {
    dispatch(deleteProjectUnit(deleteId.id))
    // }
    // onPaginationPageChange(1)
    setDeleteModal(false)
    navigate(`/project-details/${deleteId.id}`)
    dispatch(getProjectUnit(projectId))
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        delete="vendor"
      />
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th scope="col">Serial No</th>
              <th scope="col">Unit Code</th>
              <th scope="col">Chargeable Unit Area</th>
              <th scope="col">Unit Category</th>
              <th scope="col" colSpan="2">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {ProjectUnits.map((item, key) => (
              <tr key={key}>
                <td>
                  <Link to="#" className="text-dark fw-medium">
                    {key + 1}
                  </Link>
                </td>
                <td>{item.unit_code}</td>
                <td>{item.unit_area_sqft} sqft</td>
                <td>{item.category_title}</td>
                <td>
                  <div className="d-flex gap-3">
                    {permission
                      .filter(
                        item =>
                          item.page === "project/edit-project-unit/:id" &&
                          item.access === 1
                      )
                      .map(index => (
                        <Link
                          key={index}
                          to={`/project/edit-project-unit/${item.id}`}
                          className="text-dark fw-medium"
                        >
                          <i
                            className="mdi mdi-pencil font-size-18"
                            id="viewtooltip"
                            style={{ color: "#3366cc" }}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="viewtooltip"
                          >
                            View
                          </UncontrolledTooltip>
                        </Link>
                      ))}
                    {/* <Link to="#" className="text-dark fw-medium">
                      <i
                        className="mdi mdi-delete font-size-18"
                        style={{ color: "#3366cc" }}
                      />
                    </Link> */}
                    {permission
                      .filter(
                        item =>
                          item.page === "/projects/delete-project-unit" &&
                          item.access === 1
                      )
                      .map(index => (
                        <Link
                          key={index}
                          to="#"
                          className="text-danger"
                          onClick={() => {
                            const userData = item.id
                            onClickDelete(userData)
                          }}
                        >
                          <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            style={{ color: "#3366cc" }}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="deletetooltip"
                          >
                            Delete
                          </UncontrolledTooltip>
                        </Link>
                      ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Row>
        <Col>
          <div className="mb-3 text-end mt-4">
            {permission
              .filter(
                item =>
                  item.page === "add-new-project-unit" && item.access === 1
              )
              .map((item, index) => (
                <button
                  key={index}
                  className="btn btn-primary  me-2"
                  onClick={() => navigate(`/add-new-project-unit`)}
                >
                  {/* <i
                className="mdi mdi-pencil me-1"
                style={{ color: "#40baff" }}
              ></i> */}
                  Add New Project Unit
                </button>
              ))}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ProjectUnitView
