import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FACILITIES,
  GET_FACILITY_DETAIL,
  ADD_NEW_FACILITY,
  DELETE_FACILITY,
  UPDATE_FACILITY,
} from "./actionTypes"
import {
  getFacilitiesSuccess,
  getFacilitiesFail,
  getFacilityDetailSuccess,
  getFacilityDetailFail,
  addFacilityFail,
  addFacilitySuccess,
  updateFacilitySuccess,
  updateFacilityFail,
  deleteFacilitySuccess,
  deleteFacilityFail,
} from "./actions"

import {
  getFacilities,
  getFacilityDetails,
  addNewFacility,
  updateFacility,
  deleteFacility,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

function* fetchFacilities() {
  try {
    const response = yield call(getFacilities)
    yield put(getFacilitiesSuccess(response))
  } catch (error) {
    yield put(getFacilitiesFail(error))
  }
}

function* fetchFacilityDetail({ facilityId }) {
  try {
    const response = yield call(getFacilityDetails, facilityId)
    yield put(getFacilityDetailSuccess(response))
  } catch (error) {
    yield put(getFacilityDetailFail(error))
  }
}

function* onUpdateFacility({ payload: { id, facility, history } }) {
  try {
    const response = yield call(updateFacility, id, facility)
    if (response && response.status === true) {
      yield put(updateFacilitySuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Facility Master Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/facility-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateFacilityFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteFacility({ payload: facility }) {
  try {
    const response = yield call(deleteFacility, facility)
    yield put(deleteFacilitySuccess(response))
  } catch (error) {
    yield put(deleteFacilityFail(error))
  }
}

function* onAddNewFacility({ payload: { facility, history } }) {
  try {
    const response = yield call(addNewFacility, facility)
    if (response.status === true) {
      yield put(addFacilitySuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Facility Master Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/facility-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addFacilityFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* facilitysSaga() {
  yield takeEvery(GET_FACILITIES, fetchFacilities)
  yield takeEvery(GET_FACILITY_DETAIL, fetchFacilityDetail)
  yield takeEvery(ADD_NEW_FACILITY, onAddNewFacility)
  yield takeEvery(UPDATE_FACILITY, onUpdateFacility)
  yield takeEvery(DELETE_FACILITY, onDeleteFacility)
}

export default facilitysSaga
