import {
  GET_MATERIAL_MASTER_LIST,
  GET_MATERIAL_MASTER_LIST_SUCCESS,
  GET_MATERIAL_MASTER_LIST_FAIL,
  GET_MATERIAL_MASTER_DETAIL,
  GET_MATERIAL_MASTER_DETAIL_SUCCESS,
  GET_MATERIAL_MASTER_DETAIL_FAIL,
  ADD_MATERIAL_MASTER,
  ADD_MATERIAL_MASTER_SUCCESS,
  ADD_MATERIAL_MASTER_FAIL,
  UPDATE_MATERIAL_MASTER,
  UPDATE_MATERIAL_MASTER_SUCCESS,
  UPDATE_MATERIAL_MASTER_FAIL,
  DELETE_MATERIAL_MASTER,
  DELETE_MATERIAL_MASTER_SUCCESS,
  DELETE_MATERIAL_MASTER_FAIL,
  GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER,
  GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_SUCCESS,
  GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_FAIL,
  ADD_NEW_SACHSNCODE_MATERIAL_MASTER,
  ADD_NEW_SACHSNCODE_MATERIAL_MASTER_SUCCESS,
  ADD_NEW_SACHSNCODE_MATERIAL_MASTER_FAIL,
} from "./actionTypes"

export const getMaterialMasterList = () => ({
  type: GET_MATERIAL_MASTER_LIST,
})

export const getMaterialMasterListSuccess = materials => ({
  type: GET_MATERIAL_MASTER_LIST_SUCCESS,
  payload: materials,
})
export const getMaterialMasterListFail = error => ({
  type: GET_MATERIAL_MASTER_LIST_FAIL,
  payload: error,
})

export const getMaterialMasterDetail = materialId => ({
  type: GET_MATERIAL_MASTER_DETAIL,
  materialId,
})

export const getMaterialMasterDetailSuccess = materialDetail => ({
  type: GET_MATERIAL_MASTER_DETAIL_SUCCESS,
  payload: materialDetail,
})

export const getMaterialMasterDetailFail = error => ({
  type: GET_MATERIAL_MASTER_DETAIL_FAIL,
  payload: error,
})

export const addNewMaterialMaster = (materials, history) => ({
  type: ADD_MATERIAL_MASTER,
  payload: { materials, history },
  status: true,
})

export const addNewMaterialMasterSuccess = materials => ({
  type: ADD_MATERIAL_MASTER_SUCCESS,
  payload: materials,
})

export const addNewMaterialMasterFail = error => ({
  type: ADD_MATERIAL_MASTER_FAIL,
  payload: error,
})

export const updateMaterialMaster = (id, material, history) => ({
  type: UPDATE_MATERIAL_MASTER,
  payload: { id, material, history },
})

export const updateMaterialMasterSuccess = material => ({
  type: UPDATE_MATERIAL_MASTER_SUCCESS,
  payload: material,
})

export const updateMaterialMasterFail = error => ({
  type: UPDATE_MATERIAL_MASTER_FAIL,
  payload: error,
})

export const deleteMaterialMaster = material => ({
  type: DELETE_MATERIAL_MASTER,
  payload: material,
})

export const deleteMaterialMasterSuccess = material => ({
  type: DELETE_MATERIAL_MASTER_SUCCESS,
  payload: material,
})

export const deleteMaterialMasterFail = error => ({
  type: DELETE_MATERIAL_MASTER_FAIL,
  payload: error,
})

export const getSacHsnCodeForMaterialMaster = () => ({
  type: GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER,
})

export const getSacHsnCodeForMaterialMasterSuccess = code => ({
  type: GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_SUCCESS,
  payload: code,
})
export const getSacHsnCodeForMaterialMasterFail = error => ({
  type: GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_FAIL,
  payload: error,
})

export const addNewSACHSNCodeMaterialMaster = (codes) => ({
  type: ADD_NEW_SACHSNCODE_MATERIAL_MASTER,
  payload: { codes },
  status: true,
})

export const addNewSACHSNCodeMaterialMasterSuccess = codes => ({
  type: ADD_NEW_SACHSNCODE_MATERIAL_MASTER_SUCCESS,
  payload: codes,
})

export const addNewSACHSNCodeMaterialMasterFail = error => ({
  type: ADD_NEW_SACHSNCODE_MATERIAL_MASTER_FAIL,
  payload: error,
})
