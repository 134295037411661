/* VENDORS */
export const GET_VENDORS = "GET_VENDORS"
export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS"
export const GET_VENDORS_FAIL = "GET_VENDORS_FAIL"

/* VENDORS DETAIL*/
export const GET_VENDOR_DETAIL = "GET_VENDOR_DETAIL"
export const GET_VENDOR_DETAIL_SUCCESS = "GET_VENDOR_DETAIL_SUCCESS"
export const GET_VENDOR_DETAIL_FAIL = "GET_VENDOR_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_VENDOR = "ADD_NEW_VENDOR"
 export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS"
 export const ADD_VENDOR_FAIL = "ADD_VENDOR_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_VENDOR = "UPDATE_VENDOR"
 export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS"
 export const UPDATE_VENDOR_FAIL = "UPDATE_VENDOR_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_VENDOR = "DELETE_VENDOR"
 export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS"
 export const DELETE_VENDOR_FAIL = "DELETE_VENDOR_FAIL"
