import {
  GET_DOCUMENTS_FAIL,
  GET_DOCUMENTS_SUCCESS,
  GET_ACTIVE_DOCUMENTS_FAIL,
  GET_ACTIVE_DOCUMENTS_SUCCESS,
  GET_DOCUMENT_DETAIL_FAIL,
  GET_DOCUMENT_DETAIL_SUCCESS,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAIL,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAIL,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  documents: [],
  activeDocuments: [],
  documentDetail: {},
  error: {},
  loading: true
};

const documents = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        loading: true
        // loading: true
      };

    case GET_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: [...state.documents, action.payload],
      };

    case GET_ACTIVE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        activeDocuments: action.payload,
        loading: true
      };

    case GET_ACTIVE_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: [...state.documents, action.payload],
      };

    case ADD_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_DOCUMENT_DETAIL_SUCCESS:
      return {
        ...state,
        documentDetail: action.payload,
      };

    case UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: state.documents.map(document =>
          document.id.toString() === action.payload.id.toString()
            ? { document, ...action.payload }
            : document
        ),
      };

    case UPDATE_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: state.documents.filter(
          document => document.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    case GET_DOCUMENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default documents;
