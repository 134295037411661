import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import Swal from "sweetalert2"

import {
  CardImg,
  Button,
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardSubtitle,
  Container,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  FormFeedback,
} from "reactstrap"
import Dropzone from "react-dropzone"
import classnames from "classnames"

// Breadcrumb

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { deleteGallery, getGallery, getVideo, deleteVideo } from "store/actions"
import { addGallery, addVideo } from "helpers/fakebackend_helper"
import DeleteModal from "components/Common/DeleteModal"

const EditProjectGallery = () => {
  //meta title

  const navigate = useNavigate()
  document.title = "Project Gallery "
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [selectedFiles, setselectedFiles] = useState([])
  const [galleryMode, setGalleryMode] = useState(true)

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const dispatch = useDispatch()
  const path = useLocation()
  const getGalleryState = state => state.projects.galleries.data
  const Gallery = useSelector(getGalleryState) || []
  const [userId, setUserId] = useState()

  const fetchData = () => {
    const url = new URL(window.location.href)
    const userId = url.pathname.split("/edit-project-gallery/")[1]
    setUserId(userId)
    const response = dispatch(getGallery(userId))
    dispatch(getVideo(userId))
  }
  useEffect(() => {
    fetchData()
  }, [dispatch])

  const getVideoState = state => state.projects.videos.data
  const Video = useSelector(getVideoState) || []

  const embedYouTubeUrl = url => {
    const videoId = url?.split("v=")[1]
    return `https://www.youtube.com/embed/${videoId}`
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    // initialValues: window.location.href.includes('edit')
    initialValues: {
      project_id: userId,

      files: selectedFiles,
      video_url: "",
    },
    validationSchema: Yup.object({
      project_id: Yup.string().required("Please Enter Your Service Code"),
      files: Yup.mixed(),
      video_url: Yup.string().matches(
        /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
        "Invalid YouTube Video URL"
      ),
    }),
    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Uploading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        const response = await addGallery(values)

        if (response && response.status === true) {
          loadingSwal.close()

          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Gallery has been updated.",
          }).then(dispatch(getGallery(userId)))
          // validation.resetForm();
        } else {
          Swal.fire({
            title: "failure",
            icon: "error",
            text: "Your work has not been saved.",
          })
        }
      } catch (error) {
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  useEffect(() => {
    const pathName = path.pathname
    const userId = pathName.split("/edit-project-gallery/")[1]
    setUserId(userId)
    validation.setFieldValue("project_id", userId)
  }, [dispatch, path])

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = (users, type) => {
    setDeleteId({ id: users })
    setDeleteModal(true)
    if (type === "video") {
      handleDeleteVideo({ id: users })
      setDeleteModal(false)
    } else {
      handleDeletePhoto({ id: users })
      setDeleteModal(false)
    }
  }

  const handleDeleteVideo = users => {
    dispatch(deleteVideo(users.id))
    dispatch(getVideo(userId))
    dispatch(getVideo(userId))
  }

  const handleDeletePhoto = users => {
    dispatch(deleteGallery(users.id))
    dispatch(getGallery(userId))
    dispatch(getGallery(userId))
  }

  const handleVideo = async () => {
    try {
      const loadingSwal = Swal.fire({
        title: "Uploading......",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })
      const response = await addVideo(validation.values)

      loadingSwal.close()
      if (response && response.status === true) {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Your Video has been Added.",
        }).then(() => {
          // validation.resetForm();
          // validation.fields.forEach(field => {
          //   validation.setFieldValue(field.name, ''); // Set to empty string or initial value
          // });
          dispatch(getVideo(userId))
        })
      } else {
        Swal.fire({
          title: "failure",
          icon: "error",
          text: "Your Video has not been saved.",
        })
      }
    } catch (error) {
      console.error("Error:", error)
      Swal.fire({
        title: "Error",
        icon: "error",
        text:
          error.response.data.message ||
          "An error occurred while saving your work.",
      })
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={onClickDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          {/* <Row className="justify-content-center">
            <Col lg={11}>
              <CardTitle className="h4">Stacks - Horizontal</CardTitle>
            </Col>
            <Col lg={1} >
              <button className="btn btn-primary">Your Button</button>
            </Col>
          </Row> */}

          <Row>
            <Col className="col-12">
              <Link to={`/project-details/${userId}`}>
                <CardTitle className="h4">
                  <i
                    className="mdi mdi-arrow-left mb-2"
                    //  onClick={navigate(`/project-details/${userId}`)}
                  ></i>
                  Edit Project Gallery
                </CardTitle>
              </Link>
              <Card>
                <CardBody>
                  <h6 className="card-title">Upload Gallery Picture</h6>
                  {/* <CardSubtitle className="mb-3">
                    {" "}
                    DropzoneJS is an open source library that provides
                    drag’n’drop file uploads with image previews.
                  </CardSubtitle> */}
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>

                  <div className="text-center mt-4">
                    <button
                      type="button"
                      onClick={validation.handleSubmit}
                      className="btn btn-primary "
                    >
                      Upload Gallery Picture
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    Upload Youtube Video Link
                  </CardTitle>

                  <div className="hstack gap-3">
                    <input
                      className="form-control me-auto"
                      type="text"
                      name="video_url"
                      placeholder="Upload Your Youtube Video Link..."
                      aria-label="Upload Your Youtube Video Link..."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleVideo}
                    >
                      Submit
                    </button>
                  </div>

                  {validation.touched.video_url &&
                  validation.errors.video_url ? (
                    <div className="mt-2 text-danger">
                      {validation.errors.video_url}
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Stacks - Horizontal</CardTitle>
                  <h6 className="mb-3">Horizontal Stacks Examples</h6> */}
                  <Nav tabs className="nav-tabs-custom ">
                    {/* <Nav tabs className="nav-tabs-custom nav-justified"> */}
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Photos</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Videos</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          {/* <ProjectGalleryView galleryMode={galleryMode}  galleryId={userId}/> */}
                          <Row>
                            {Gallery.map(item => (
                              <Col key={item.id} md={6} xl={3}>
                                <Card>
                                  <Link>
                                    <Button
                                      variant="danger"
                                      className="delete-button"
                                      onClick={() => {
                                        onClickDelete(item.id, "photo")
                                      }}
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        // margin: '5px',
                                        background: "transparent",
                                        border: "none",
                                      }}
                                    >
                                      {/* <i className=" mdi mdi-close font-size-20"></i> */}
                                      <i
                                        className="mdi mdi-close font-size-24"
                                        id="deletetooltip"
                                        style={{ color: "white" }}
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="deletetooltip"
                                      >
                                        Delete
                                      </UncontrolledTooltip>
                                    </Button>
                                  </Link>
                                  <CardImg
                                    top
                                    className="rounded-3 img-fluid"
                                    src={item.image_paths}
                                    alt={`Image ${item.id}`}
                                    style={{ height: "250px" }}
                                  />
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        {Video.map((video, id) => (
                          <Col lg={4} key={id}>
                            <Card>
                              <CardBody>
                                <Button
                                  className="delete-button mt-3"
                                  onClick={() => {
                                    onClickDelete(video.id, "video")
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    background: "transparent",
                                    border: "none",
                                    color: "black",
                                    zIndex: 999,
                                    marginRight: "15px",
                                  }}
                                >
                                  <i
                                    className="mdi mdi-close font-size-24"
                                    id="deletetooltip"
                                    style={{ color: "white" }}
                                  />
                                </Button>
                                {/* <div >
            {video.video_url}
          </div> */}
                                <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                                  <iframe
                                    title={`video-${id}`}
                                    className="embed-responsive-item"
                                    src={embedYouTubeUrl(video.video_url)}
                                  />
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditProjectGallery
