import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PROFILE_DETAIL } from "./actionTypes"
import { getProfileDetailSuccess, getProfileDetailFail } from "./actions"

//Include Both Helper File with needed methods
import { getProfileDetail } from "helpers/fakebackend_helper"

function* fetchMyProfileDetail({ userId }) {
  try {
    const response = yield call(getProfileDetail, userId)
    yield put(getProfileDetailSuccess(response))
  } catch (error) {
    yield put(getProfileDetailFail(error))
  }
}

function* myProfileSaga() {
  yield takeEvery(GET_PROFILE_DETAIL, fetchMyProfileDetail)
}

export default myProfileSaga
