import React, { useEffect, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  getProjectUnitDetail,
  getProposalDetail,
  getProposals,
} from "store/actions"
import { AcceptProposal } from "helpers/fakebackend_helper"
import LargeModal from "./rejectApprovalModal"
import Swal from "sweetalert2"

const ProposalView = () => {
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  const path = useLocation()
  const [modalLarge1, setModalLarge1] = useState(false)
  const toggleModalLarge1 = () => setModalLarge1(!modalLarge1)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const projectUnitDetailState = state =>
    state.proposals.proposalDetail.proposal
  const data = useSelector(projectUnitDetailState) || []
  const [proposalId, setProposalId] = useState()

  const fetchData = () => {
    const proposalId = path.pathname.split("/view-vendor-proposal/")[1]
    setProposalId(proposalId)
    dispatch(getProposalDetail(proposalId))
  }

  useEffect(() => {
    fetchData()
  }, [dispatch, useLocation])

  const handleAccept = async status => {
    let loadingSwal
    const value = {
      proposal_id: proposalId,
      proposal_status: 1,
    }

    try {
      loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })

      const response = await AcceptProposal(value)

      if (response && response.status === true) {
        loadingSwal.close()

        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Proposal has been Approved",
        }).then(result => {
          if (result.isConfirmed) {
            dispatch(getProposalDetail(proposalId))
          }
        })
      } else {
        loadingSwal.close()

        Swal.fire({
          title: "Failure",
          icon: "error",
          text: " Proposal Approval request has not been Submitted.",
        })
      }
    } catch (error) {
      console.error("Error:", error)
      if (loadingSwal) {
        loadingSwal.close()
      }

      Swal.fire({
        title: "Error",
        icon: "error",
        text:
          error.response?.data?.message ||
          "An error occurred while Submitting Request.",
      })
    }
  }

  const handleReject = () => {
    toggleModalLarge1()
  }
  return (
    <React.Fragment>
      <LargeModal
        isOpen={modalLarge1}
        toggle={toggleModalLarge1}
        id={proposalId}
      />
      <div className="page-content ">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={12}>
              <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 ">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  Proposal Details
                </div>
              </CardTitle>
              <Row className="justify-content-center">
                <Col lg={8}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <Row className="mb-3">
                            <Col lg={4}>
                              <Label className="form-label ">Project</Label>
                              <p
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {data?.project_name}
                              </p>
                            </Col>
                            <Col lg={4}>
                              <Label className="form-label">Proposal No.</Label>
                              <p
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {data?.proposal_no}
                              </p>
                            </Col>
                            <Col lg={4}>
                              <Label className="form-label ">
                                Proposal Date
                              </Label>
                              <p
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {data?.formatted_proposal_date}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg={4}>
                              <Label className="form-label">
                                Proposal Purpose
                              </Label>
                              <p
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {data?.proposal_title}
                              </p>
                            </Col>

                            <Col lg={4}>
                              <Label className="form-label ">
                                Goods & Services Category
                              </Label>
                              <p
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {data?.proposal_type}
                              </p>
                            </Col>
                            <Col lg={4}>
                              <Label className="form-label ">
                                Taxable Amount
                              </Label>
                              <p
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {data?.taxable_amount}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col lg={4}>
                              <Label className="form-label">GST Rate(%)</Label>
                              <p
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {data?.gst_percentage}
                              </p>
                            </Col>

                            <Col lg={4}>
                              <Label className="form-label ">GST Amount</Label>
                              <p
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {data?.tax_amount}
                              </p>
                            </Col>
                            <Col lg={4}>
                              <Label className="form-label">Total Amount</Label>
                              <p
                                className="text-dark"
                                style={{ fontWeight: 600 }}
                              >
                                {data?.invoice_amount}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <Label className="form-label">Status</Label>
                              <p
                                style={{
                                  fontWeight: 500,
                                  color:
                                    data.proposal_status === 0
                                      ? "blue"
                                      : data.proposal_status === 1
                                      ? "green"
                                      : data.proposal_status === 2
                                      ? "red"
                                      : data.proposal_status === 3
                                      ? "orange"
                                      : "black",
                                }}
                              >
                                {data.proposal_status === 0
                                  ? "Pending"
                                  : data.proposal_status === 1
                                  ? "Approved"
                                  : data.proposal_status === 2
                                  ? "Rejected"
                                  : data.proposal_status === 3
                                  ? "Billed"
                                  : "NA"}
                              </p>
                            </Col>
                            {data.proposal_status === 2 && (
                              <Col lg={4}>
                                <Label className="form-label">
                                  Rejected Reason
                                </Label>
                                <p
                                  className="text-dark"
                                  style={{ fontWeight: 600 }}
                                >
                                  {data?.reject_reason}
                                </p>
                              </Col>
                            )}
                          </Row>
                          <Row className="mb-3"></Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={8}>
                  <Card>
                    <CardBody>
                      <Row className="justify-content-center">
                        <Col lg={6}>
                          <button
                            className=" btn btn-warning ms-2 w-25"
                            onClick={() => {
                              const proposalFilePath = data.proposal_file_path
                              if (proposalFilePath) {
                                window.open(proposalFilePath, "_blank")
                              }
                            }}
                          >
                            View Proposal
                          </button>

                          {data && data.proposal_status === 0 ? (
                            <>
                              {permission
                                .filter(
                                  item =>
                                    item.page === "vendor-proposal-accept-reject" &&
                                    item.access === 1
                                )
                                .map((item, index) => (
                                  <button
                                    key={index}
                                    className=" btn btn-primary ms-2 w-25"
                                    onClick={() => {
                                      handleAccept()
                                    }}
                                  >
                                    Approve
                                  </button>
                                ))}
                              {permission
                                .filter(
                                  item =>
                                    item.page === "vendor-proposal-accept-reject" &&
                                    item.access === 1
                                )
                                .map((item, index) => (
                                  <button
                                    key={index}
                                    className=" btn btn-danger ms-2 w-25"
                                    onClick={() => {
                                      handleReject()
                                    }}
                                  >
                                    Reject
                                  </button>
                                ))}
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProposalView
