import {
  GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_FAIL,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS_FAIL,
  GET_ALL_PROJECTS_SUCCESS,
  GET_GST_R1_REPORT,
  GET_GST_R1_REPORT_FAIL,
  GET_GST_R1_REPORT_SUCCESS,
  GET_PROJECT_OCCUPANCY_DETAIL,
  GET_PROJECT_OCCUPANCY_DETAIL_FAIL,
  GET_PROJECT_OCCUPANCY_DETAIL_SUCCESS,
} from "./actionTypes"

export const getAllProjects = id => ({
  type: GET_ALL_PROJECTS,
  id,
})

export const getAllProjectsSuccess = allProjects => ({
  type: GET_ALL_PROJECTS_SUCCESS,
  payload: allProjects,
})

export const getAllProjectsFail = error => ({
  type: GET_ALL_PROJECTS_FAIL,
  payload: error,
})

export const getProjectOccupancyDetail = projectId => ({
  type: GET_PROJECT_OCCUPANCY_DETAIL,
  projectId,
})

export const getProjectOccupancyDetailSuccess = projectOccupancyReport => ({
  type: GET_PROJECT_OCCUPANCY_DETAIL_SUCCESS,
  payload: projectOccupancyReport,
})

export const getProjectOccupancyDetailFail = error => ({
  type: GET_PROJECT_OCCUPANCY_DETAIL_FAIL,
  payload: error,
})

export const getAllCompanies = () => ({
  type: GET_ALL_COMPANIES,
})

export const getAllCompaniesSuccess = allCompanies => ({
  type: GET_ALL_COMPANIES_SUCCESS,
  payload: allCompanies,
})

export const getAllCompaniesFail = error => ({
  type: GET_ALL_COMPANIES_FAIL,
  payload: error,
})

export const getGstR1Report = companyData => ({
  type: GET_GST_R1_REPORT,
  companyData,
})

export const getGstR1ReportSuccess = companyData => ({
  type: GET_GST_R1_REPORT_SUCCESS,
  payload: companyData,
})

export const getGstR1ReportFail = error => ({
  type: GET_GST_R1_REPORT_FAIL,
  payload: error,
})


