import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

// Crypto Redux States
import {
  GET_VENDORS,
  GET_VENDOR_DETAIL,
  ADD_NEW_VENDOR,
  DELETE_VENDOR,
  UPDATE_VENDOR,
} from "./actionTypes"
import {
  getVendorsSuccess,
  getVendorsFail,
  getVendorDetailSuccess,
  getVendorDetailFail,
  addVendorFail,
  addVendorSuccess,
  updateVendorSuccess,
  updateVendorFail,
  deleteVendorSuccess,
  deleteVendorFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getVendors,
  getVendorDetails,
  addNewVendor,
  updateVendor,
  deleteVendor,
} from "helpers/fakebackend_helper"

function* fetchVendors() {
  try {
    const response = yield call(getVendors)
    yield put(getVendorsSuccess(response))
  } catch (error) {
    yield put(getVendorsFail(error))
  }
}

function* fetchVendorDetail({ vendorId }) {
  try {
    const response = yield call(getVendorDetails, vendorId)
    yield put(getVendorDetailSuccess(response))
  } catch (error) {
    yield put(getVendorDetailFail(error))
  }
}

function* onUpdateVendor({ payload: { id, vendor, history } }) {
  try {
    const response = yield call(updateVendor, id, vendor)
    if (response && response.status === true) {
      yield put(updateVendorSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Vendor Master Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/vendor-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateVendorFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteVendor({ payload: vendor }) {
  try {
    const response = yield call(deleteVendor, vendor)
    yield put(deleteVendorSuccess(response))
    if (response.status === true) {
    } else {
      console.error("Error in updateVendor:", response.error)
    }
  } catch (error) {
    yield put(deleteVendorFail(error))
  }
}

function* onAddNewVendor({ payload: { vendor, history } }) {
  try {
    const response = yield call(addNewVendor, vendor)
    if (response.status === true) {
      yield put(addVendorSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Vendor Master Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/vendor-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addVendorFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* vendorsSaga() {
  yield takeEvery(GET_VENDORS, fetchVendors)
  yield takeEvery(GET_VENDOR_DETAIL, fetchVendorDetail)
  yield takeEvery(ADD_NEW_VENDOR, onAddNewVendor)
  yield takeEvery(UPDATE_VENDOR, onUpdateVendor)
  yield takeEvery(DELETE_VENDOR, onDeleteVendor)
}

export default vendorsSaga
