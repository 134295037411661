import {
  GET_UNIT_MASTER_LIST,
  GET_UNIT_MASTER_LIST_SUCCESS,
  GET_UNIT_MASTER_LIST_FAIL,
  GET_UNIT_MASTER_DETAIL,
  GET_UNIT_MASTER_DETAIL_SUCCESS,
  GET_UNIT_MASTER_DETAIL_FAIL,
  ADD_NEW_UNIT_MASTER,
  ADD_NEW_UNIT_MASTER_SUCCESS,
  ADD_NEW_UNIT_MASTER_FAIL,
  UPDATE_UNIT_MASTER,
  UPDATE_UNIT_MASTER_SUCCESS,
  UPDATE_UNIT_MASTER_FAIL,
  DELETE_UNIT_MASTER,
  DELETE_UNIT_MASTER_SUCCESS,
  DELETE_UNIT_MASTER_FAIL,
} from "./actionTypes"

export const getUnitMasterList = () => ({
  type: GET_UNIT_MASTER_LIST,
})

export const getUnitMasterListSuccess = units => ({
  type: GET_UNIT_MASTER_LIST_SUCCESS,
  payload: units,
})
export const getUnitMasterListFail = error => ({
  type: GET_UNIT_MASTER_LIST_FAIL,
  payload: error,
})

export const getUnitMasterDetail = unitId => ({
  type: GET_UNIT_MASTER_DETAIL,
  unitId,
})

export const getUnitMasterDetailSuccess = unitDetail => ({
  type: GET_UNIT_MASTER_DETAIL_SUCCESS,
  payload: unitDetail,
})

export const getUnitMasterDetailFail = error => ({
  type: GET_UNIT_MASTER_DETAIL_FAIL,
  payload: error,
})

export const addNewUnitMaster = (unit, history) => ({
  type: ADD_NEW_UNIT_MASTER,
  payload: { unit, history },
  status: true,
})

export const addNewUnitMasterSuccess = unit => ({
  type: ADD_NEW_UNIT_MASTER_SUCCESS,
  payload: unit,
})

export const addNewUnitMasterFail = error => ({
  type: ADD_NEW_UNIT_MASTER_FAIL,
  payload: error,
})

export const updateUnitMaster = (id, unit, history) => ({
  type: UPDATE_UNIT_MASTER,
  payload: { id, unit, history },
})

export const updateUnitMasterSuccess = unit => ({
  type: UPDATE_UNIT_MASTER_SUCCESS,
  payload: unit,
})

export const updateUnitMasterFail = error => ({
  type: UPDATE_UNIT_MASTER_FAIL,
  payload: error,
})

export const deleteUnitMaster = unit => ({
  type: DELETE_UNIT_MASTER,
  payload: unit,
})

export const deleteUnitMasterSuccess = unit => ({
  type: DELETE_UNIT_MASTER_SUCCESS,
  payload: unit,
})

export const deleteUnitMasterFail = error => ({
  type: DELETE_UNIT_MASTER_FAIL,
  payload: error,
})
