import React, { useEffect, useState } from "react"
import { Col, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

//flatpickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"
import {
  getAllServiceRequestList,
  getCustomerFilterForConsumption,
  getProjectFilterForCustomerIndex,
  getServiceRequests,
  getServiceRequestTicketList,
} from "store/actions"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  const serviceRequestState = state =>
    state.serviceRequests.serviceRequests.service_requests

  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const allProjectState = state => state.CustomerIndex.projectFilter.project
  const projectList = useSelector(allProjectState) || []
  const customerState = state => state.consumptionReport.customerFilter.data

  const customerList = useSelector(customerState) || []

  const [statusValue, setStatusValue] = useState("")
  const [project, setProject] = useState("")
  const [customer, setCustomer] = useState("")

  const handleSelectStatus = ele => {
    setStatusValue(ele.value || "")
  }

  const handleProject = ele => {
    let data = ele.value
    setProject(data || "")
  }
  const handleCustomer = ele => {
    let data = ele.value
    setCustomer(data || "")
  }

  const handleSearch = () => {
    if (user) {
      const value = {
        project_id: project,
        client_id: customer,
        status: statusValue,
        user_id: user.user_id,
      }
      console.log(value)
      dispatch(getAllServiceRequestList(value))
    }
  }

  useEffect(() => {
    if (user) {
      dispatch(getProjectFilterForCustomerIndex(user.user_id))
    }
  }, [dispatch, user])

  useEffect(() => {
    if (user) {
      dispatch(getCustomerFilterForConsumption(user.user_id))
    }
  }, [user])

  return (
    <React.Fragment>
      <Col xxl={2} lg={6}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Status"
          onChange={e => handleSelectStatus(e.target)}
        >
          <option value="">Status</option>
          <option value="">All</option>
          <option value="1">Open</option>
          <option value="2">Ticket Created</option>
          <option value="3">In Progress</option>
          <option value="4">Ticket Closed</option>
          <option value="5">Declined</option>
          <option value="6">Assigned</option>
        </select>
      </Col>
      <Col xxl={2} lg={4}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Project"
          onChange={e => handleProject(e.target)}
        >
          <option value="">Select Project</option>
          {projectList &&
            projectList.map((project, index) => (
              <option key={index} value={project.id}>
                {project.project_name}
              </option>
            ))}
        </select>
      </Col>
      <Col xxl={2} lg={4}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Customer"
          onChange={e => handleCustomer(e.target)}
        >
          <option value="">Select Customer</option>
          {customerList &&
            customerList.map((customer, index) => (
              <option key={index} value={customer.id}>
                {customer.customer_name}
              </option>
            ))}
        </select>
      </Col>

      <Col xxl={1} lg={4}>
        <div className="mb-3 mb-xxl-0">
          <button
            type="button"
            className="btn btn-soft-secondary w-100"
            onClick={handleSearch}
          >
            <i className="mdi mdi-filter-outline align-middle"></i> Submit
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default JobListGlobalFilter
