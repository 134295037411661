import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

// Load permissions from localStorage if available
const persistedPermissions = localStorage.getItem("permission")
const initialState = {
  error: "",
  loading: false,
  permission: persistedPermissions ? JSON.parse(persistedPermissions) : [],
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      }
    case LOGIN_SUCCESS:
      const { user_permissions } = action.payload || []
      localStorage.setItem("permission", JSON.stringify(user_permissions))
      return {
        ...state,
        loading: false,
        isUserLogout: false,
        permission: user_permissions || [],
      }
    case LOGOUT_USER:
      return {
        ...state,
        loading: true,
      }
    case LOGOUT_USER_SUCCESS:
      localStorage.removeItem("permission")
      return {
        ...state,
        loading: false,
        isUserLogout: true,
        isAuthenticated: false,
        permission: [],
      }
    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
        isInitialized: true,
      }
    default:
      return state
  }
}

export default login
