import React, { useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"

const ClientDetail = props => {
  const { clientProfile } = props
  return (
    <React.Fragment>
      <Container fluid={true}>
        {clientProfile && (
          <Row>
            <Col>
              <CardTitle className="mb-3">Client Identities</CardTitle>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Customer Type </Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {clientProfile.customer_type === 1 ? "Individual" : "Brand"}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Customer Name </Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {clientProfile.customer_name}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Customer Code</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {clientProfile.customer_code}
                  </p>
                </Col>
              </Row>
            </Col>
            <hr />
            <Col>
              <CardTitle className="mb-3">Contact Details</CardTitle>

              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label"> Email Address (H.O)</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {clientProfile.email_address}{" "}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Mobile No. (H.O)</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {clientProfile.mobile_no}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Address</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {clientProfile.address}
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Pincode</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {clientProfile.pincode}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Country</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {clientProfile.country_name}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">State</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {clientProfile.state_name}
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">City</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {clientProfile.city_name}
                  </p>
                </Col>
              </Row>
            </Col>
            <hr />
            <Col>
              <CardTitle className="mb-3">Document Identities</CardTitle>

              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">PAN No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {clientProfile.pan_no}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Aadhar No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {clientProfile.aadhar_no}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">TAN No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {clientProfile.tan_no}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Label className="form-label">GST No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {clientProfile.gst_no}
                  </p>
                </Col>
              </Row>
            </Col>
            <hr />
            <Col>
              <CardTitle className="mb-3">Sign In details</CardTitle>

              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Login Username</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {clientProfile.login_username}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">is Active</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {clientProfile.is_active===1?"Yes":"No"}
                  </p>
                </Col>
              </Row>
            </Col>
            <hr />
          </Row>
        )}
      </Container>
    </React.Fragment>
  )
}

export default ClientDetail
