import React from "react"
import { Navigate } from "react-router-dom"

// AUTHENTICATION & PAGES
import Login from "../VipPages/Authentication/Login"
import Logout from "../VipPages/Authentication/Logout"
import UpdatePass from "../VipPages/AuthenticationInner/UpdatePass"
import Pages404 from "../VipPages/Utility/pages-404"
import ForgetPassword from "VipPages/Authentication/ForgetPassword"

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

// Dashboard
import Dashboard from "../VipPages/Dashboard/index"
import VacantProjectUnitList from "../VipPages/Dashboard/vacantProjectUnitList/vacantProjectUnitList"
import MyProfile from "VipPages/myProfile/myProfile"
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//SERVICE REQUESTS

import AllRequestsList from "VipPages/ServiceRequests/Requests/allRequestsList"
import ViewServiceRequest from "VipPages/ServiceRequests/ViewServiceRequest/viewServiceRequest"
import LookingForAdditionalUnitList from "VipPages/ServiceRequests/Dashboard/Components/LookingForAdditionalUnitList/lookingForAdditionalUnitList"
import MaintenanceIssueList from "VipPages/ServiceRequests/Dashboard/Components/MaintenanceIssueList/maintenanceIssueList"
import SupportTicketList from "VipPages/ServiceRequests/Dashboard/Components/SupportTicketList/supportTicketList"

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//TICKETS

import TicketsList from "VipPages/Tickets/TicketList/ticketsList"
import TicketView from "VipPages/Tickets/TicketView/ticketView"
import TicketsDashboard from "VipPages/Tickets/Dashboard/ticketsDashboard"
import CriticalPriorityTicketList from "VipPages/Tickets/Dashboard/Components/criticalTicketsList/criticalPriorityTicketsList"
import HighPriorityTicketList from "VipPages/Tickets/Dashboard/Components/highTickets/highPriorityTicketsList"
import MediumPriorityTicketList from "VipPages/Tickets/Dashboard/Components/mediumTickets/mediumPriorityList"
import LowPriorityTicketList from "VipPages/Tickets/Dashboard/Components/lowTickets/lowPriorityList"

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//CLIENTS
import AllClients from "VipPages/Clients/allClients"
import AddNewClient from "VipPages/Clients/addNewClient"
import EditClient from "VipPages/Clients/editClient"
import ClientView from "VipPages/Clients/viewClient"
import AssignedProject from "VipPages/Clients/components/assignedProjectUnit"
import ClientDetail from "VipPages/Clients/components/clientDetail"
import ClientDocuments from "VipPages/Clients/components/clientDocuments"
import AddNewAssignedProjectUnit from "VipPages/Clients/components/newAssignedProjectUnit"
import EditNewAssignedProjectUnit from "VipPages/Clients/components/editAssignedProjectUnit"
import ViewAssignedProject from "VipPages/Clients/components/viewAssignedProjectUnit"
import ProjectUnitsApproval from "VipPages/Clients/projectUnitsApproval"
import ProjectUnitsApprovalView from "VipPages/Clients/components/ProjectUnitsApprovalView"
import AllEngagementList from "VipPages/Clients/ServiceEngagement/AllEngagement/allEngagementList"
import ApprovalPendingList from "VipPages/Clients/ServiceEngagement/ApprovalPending/approvalPendingList"
import BillingSetupPendingList from "VipPages/Clients/ServiceEngagement/BillingSetupPending/billingSetupPendingList"
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//VENDORS
import AllVendor from "VipPages/Vendor/allVendor"
import AddNewVendor from "VipPages/Vendor/addNewVendor"
import EditVendor from "VipPages/Vendor/editVendor"
import VendorsContacts from "VipPages/Vendor/editVendorComponents/Contacts"
import VendorDetail from "VipPages/Vendor/viewVendor"
import VendorsDocuments from "VipPages/Vendor/editVendorComponents/Documents"
import AddNewVendorContact from "VipPages/Vendor/components/addNewVendorContact"
import EditVendorContact from "VipPages/Vendor/components/editNewContacts"
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//PROJECTS
import Projects from "VipPages/Project/allProjects"
import AddProjectUnit from "VipPages/Project/addNewProjectUnit"
import AddNewProject from "VipPages/Project/addNewProject"
import ProjectDetails from "VipPages/Project/projectProfile"
import EditProjectGallery from "VipPages/Project/components/ProjectGallery/editProjectGallery"
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//USERS
import AddNewUser from "VipPages/UserManagement/addNewUser"
import User from "VipPages/UserManagement/user"
import UserDetails from "VipPages/UserManagement/userProfile"
import EmptyUsers from "VipPages/UserManagement/emptyUsers"
import Addnewpastemployment from "VipPages/UserManagement/components/newPastEmployment"
import Viewpastemployment from "VipPages/UserManagement/components/viewPastEmploymentsViewFile"
import ManageUserPermission from "VipPages/UserManagement/ManageUserPermission/manageUserPermission"
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//BILLING
import BillingSetup from "VipPages/Billing/BillingSetup/billingSetup"
import EditBillingSetup from "VipPages/Billing/EditBillingSetup/EditBillingSetup"
import BillingRegister from "VipPages/Billing/BillingRegister/billingRegister"
import CreateNewBill from "VipPages/Billing/CreateNewBill/createNewBill"
import Receipt from "VipPages/Billing/ReceiptsList/receiptsList"
import AddNewReceipt from "VipPages/Billing/AddNewReceipt/addNewReceipt"
import RecordPayment from "VipPages/Billing/RecordPayment/recordPayment"
import EditBill from "VipPages/Billing/EditBill/editBill"
import InvoicePreview from "VipPages/Billing/InvoicePreview/invoicePreview"
import CreditInvoicePreview from "VipPages/Billing/CreditInvoicePreview/creditPreview"
import EditReceipt from "VipPages/Billing/EditReceipt/editReceipt"
import BillingSetupPreview from "VipPages/Billing/BillingSetup/billingSetupPreview"
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//REGIONAL SETTINGS (SETTINGS)
import RegionalSettings from "VipPages/RegionalSettings"
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//USER ROLE MANAGEMENT (SETTINGS)
import UserRoleManagement from "VipPages/UserRoleManagement/userRoleManagement"
import NewUser from "VipPages/UserRoleManagement/newUserRole"
import ManagePermission from "VipPages/UserRoleManagement/userRolePermission"
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//COMPANY (SETTINGS)
import AccountSetup from "VipPages/AccountSetup/company"
import AccountSetupPage from "../VipPages/AccountSetup/companySetupPage"
import CompanyView from "VipPages/AccountSetup/viewCompany"
import AddCompanyLocation from "VipPages/AccountSetup/LocationComponents/addCompanyLocation"
import EditCompanyLocation from "VipPages/AccountSetup/LocationComponents/editCompanyLocation"
import ViewCompanyLocation from "VipPages/AccountSetup/LocationComponents/locationView"
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

//MASTER CONFIGURATION (SETTINGS)
//VENDOR MASTER
import VenderService from "VipPages/MasterConfiguration/VenderMaster/venderService"
import NewVenderService from "VipPages/MasterConfiguration/VenderMaster/newVenderService"
//FACILITY MASTER
import Facility from "VipPages/MasterConfiguration/FacilityMaster/facility"
import NewFacilityMaster from "VipPages/MasterConfiguration/FacilityMaster/newFacilityMaster"
//DOCUMENT MASTER
import DocumentService from "VipPages/MasterConfiguration/DocumentMaster/documentService"
import NewDocumentService from "VipPages/MasterConfiguration/DocumentMaster/newDocumentService"
//EXPENSE MASTER
import ExpenseService from "VipPages/MasterConfiguration/ExpenseMaster/expenseService"
import NewExpenseService from "VipPages/MasterConfiguration/ExpenseMaster/newExpenseService"
//MAINTAINENCE MASTER
import MaintenenceService from "VipPages/MasterConfiguration/MaintenenceMaster/maintenenceService"
import NewMaintenenceService from "VipPages/MasterConfiguration/MaintenenceMaster/newMaintenenceService"
//CUSTOMER SUPPORT TICKET MASTER
import CustomerService from "VipPages/MasterConfiguration/CustomerTicketMaster/customerService"
import NewCustomerTicket from "VipPages/MasterConfiguration/CustomerTicketMaster/newCustomerTicket"
//INVOICE TYPE MASTER
import InvoiceTypeService from "VipPages/MasterConfiguration/InvoiceTypeMaster/invoiceTypeService"
import NewInvoiceService from "VipPages/MasterConfiguration/InvoiceTypeMaster/newInvoiceService"
//PROJECT UNIT MASTER
import ProjectUnit from "VipPages/MasterConfiguration/ProjectUnitCategoryMaster/projectUnit"
import NewProjectUnit from "VipPages/MasterConfiguration/ProjectUnitCategoryMaster/newProjectUnit"
//PROJECT COMPLIANCE MASTER
import ProjectService from "VipPages/MasterConfiguration/ProjectComplianceMaster/projectService"
import NewProjectService from "VipPages/MasterConfiguration/ProjectComplianceMaster/newProjectService"
//LANDLORD MASTER
import LandlordMaster from "VipPages/MasterConfiguration/LandlordMaster/landlordService"
import NewLandlordService from "VipPages/MasterConfiguration/LandlordMaster/newLandlordService"
import LandlordView from "VipPages/MasterConfiguration/LandlordMaster/LandlordMasterView/landlordView"

//SAC & HSN CODE MASTER
import SACHSNSERVICE from "VipPages/MasterConfiguration/SAC&HSNCodeMaster/SAC&HSNList"
import NewSACHSNCode from "VipPages/MasterConfiguration/SAC&HSNCodeMaster/newSAC&HSNCode"
import RecurringBills from "VipPages/Billing/RecurringBills/RecurringBillList"
import ServiceRequestsDashboard from "VipPages/ServiceRequests/Dashboard/serviceRequestsDashboard"

//MATERIAL MASTER
import MATERIALMASTERLIST from "VipPages/MasterConfiguration/MaterialsMaster/MaterialMasterList"
import NewMaterialMaster from "VipPages/MasterConfiguration/MaterialsMaster/newMaterialMaster"

//UNIT MASTER
import UNITMASTERLIST from "VipPages/MasterConfiguration/UnitMaster/UnitMasterList"
import NewUnitMaster from "VipPages/MasterConfiguration/UnitMaster/newUnitMaster"

import GstReports from "VipPages/Reports/gstReports"
import ProjectOccupancyReports from "VipPages/Reports/projectOccupancyReports"
import ReAssignViewServiceRequest from "VipPages/ServiceRequests/ViewServiceRequest/reAssignViewServiceRequest"
import AllConsumption from "VipPages/Billing/ConsumptionRegister/allConsumptions"
import NewConsumption from "VipPages/Billing/ConsumptionRegister/addConsumption"
import RenewalList from "VipPages/Renewals/RenewalList/renewalList"
import RenewalDetailList from "VipPages/Renewals/RenewalDetailList/renewalDetailList"

import Zero from "VipPages/Renewals/RenewalDetailList/Zero/zero"
import Sixty from "VipPages/Renewals/RenewalDetailList/Sixty/sixty"
import Ninety from "VipPages/Renewals/RenewalDetailList/Ninety/ninety"
import Thirty from "VipPages/Renewals/RenewalDetailList/Thirty/thirty"
import Overdue from "VipPages/Renewals/RenewalDetailList/Overdue/overdue"
import RenewAssignedProjectUnit from "VipPages/Renewals/RenewAgreement/renewAssignedProjectUnit"
import ViewRenewalUnit from "VipPages/Renewals/ViewUnitDetail/viewRenewalUnit"
import VendorBillHistoryList from "VipPages/Vendor/VendorBillingHistory/VendorBillHistoryList"
import AddNewVendorPayment from "VipPages/Vendor/AddNewPayment/addNewVendorPayment"
import EditVendorPayment from "VipPages/Vendor/EditPayment/editVendorPayment"
import ConsumptionReportList from "VipPages/Consumption/consumptionReportList"
import ApproveVendorPayment from "VipPages/Vendor/ApproveVendorPayment/approveVendorPayment"
import VendorPaymentHistoryList from "VipPages/Vendor/VendorPaymentList/VendorPaymentHistoryList"
import CustomerOustandingReport from "VipPages/CustomerOustandingReport/customerOustandingReportList"
import ViewLedger from "VipPages/CustomerOustandingReport/ViewLedger/viewLedger"
import VendorSummaryReports from "VipPages/Reports/vendorSummaryReport/vendorSummaryReports"
import OutstandingAmountData from "VipPages/Reports/vendorSummaryReport/OutstandingAmountData/outstandingAmountData"
import VendorTdsData from "VipPages/Reports/vendorSummaryReport/TdsData/vendorTdsData"
import VendorInvoiceData from "VipPages/Reports/vendorSummaryReport/VendorInvoiceData/vendorInvoiceData"
import VendorInvoicePayment from "VipPages/Reports/vendorSummaryReport/VendorInvoicePayment/vendorInvoicePayment"
import VendorTicketData from "VipPages/Reports/vendorSummaryReport/VendorTicket/vendorTicket"
import ClosedTicketsList from "VipPages/Dashboard/TicketList/closedTicketsList"
import WipTicketsList from "VipPages/Dashboard/TicketList/wipTicketsList"
import OpenTicketsList from "VipPages/Dashboard/TicketList/openTicketsList"
import AssignTicket from "VipPages/ServiceRequests/ViewServiceRequest/assignTicket"
import PendingRequestsList from "VipPages/Dashboard/ServiceRequestList/pendingRequestsList"
import AcceptedServiceRequest from "VipPages/Dashboard/ServiceRequestList/acceptedRequestsList"
import RejectedServiceRequest from "VipPages/Dashboard/ServiceRequestList/ticketClosedRequestsList"
import CustomerIndex from "VipPages/Clients/CustomerIndex/customerIndex"
import ProposalView from "VipPages/Proposal/proposalView"
import ProposalList from "VipPages/Proposal/proposalList"
import AddCreditNote from "VipPages/Billing/AddCreditNote/addCreditNote"
import CreditNoteList from "VipPages/Billing/CreditNoteList/creditNoteList"
import EditCreditNote from "VipPages/Billing/editCreditNote/editCreditNote"
import NewProposalTypeMaster from "VipPages/MasterConfiguration/ProposalTypeMaster/newProposalTypeMaster"
import ProposalTypeMasterList from "VipPages/MasterConfiguration/ProposalTypeMaster/proposalTypeMasterList"
import ReasonMasterList from "VipPages/MasterConfiguration/ReasonMaster/ReasonMasterList"
import NewReasonMaster from "VipPages/MasterConfiguration/ReasonMaster/newReasonMaster"
import AddDebitNote from "VipPages/Vendor/DebitNote/addDebitNote"
import VendorDebitNoteList from "VipPages/Vendor/DebitNoteList/VendorDebitNoteList"
import EditDebitNote from "VipPages/Vendor/DebitNote/editDebitNote"
import AddNewClientUser from "VipPages/Clients/ManageUser/addNewClientUser"
import EditNewClientUser from "VipPages/Clients/ManageUser/editNewClientUser"
import VendorLedger from "VipPages/Reports/vendorSummaryReport/VendorLedger/vendorLedger"
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

const publicRoutes = [
  // AUTHENTICATION & PAGES
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/update-password", component: <UpdatePass /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
  { path: "/pages-404", component: <Pages404 /> },
]

const authProtectedRoutes = [
  //DASHBOARD
  { path: "/dashboard", component: <Dashboard /> },
  {
    path: "/dashboard/vacant-project-unit-list",
    component: <VacantProjectUnitList />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "/profile", component: <MyProfile /> },

  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //SERVICE REQUEST
  {
    path: "/service-requests/service-request-dashboard",
    component: <ServiceRequestsDashboard />,
  },

  {
    path: "/service-requests/all-service-requests",
    component: <AllRequestsList />,
  },
  //dashboard {
  {
    path: "/service-requests/pending-service-requests",
    component: <PendingRequestsList />,
  },
  {
    path: "/service-requests/inprogress-service-requests",
    component: <AcceptedServiceRequest />,
  },
  {
    path: "/service-requests/closed-service-request",
    component: <RejectedServiceRequest />,
  },
  //}
  {
    path: "/service-requests/view-service-requests/:id",
    component: <ViewServiceRequest />,
  },
  {
    path: "/tickets/assign-ticket/:id",
    component: <AssignTicket />,
  },
  {
    path: "/service-requests/reassign-view-service-requests/:id",
    component: <ReAssignViewServiceRequest />,
  },

  {
    path: "/service-requests/looking-for-additional-project-unit",
    component: <LookingForAdditionalUnitList />,
  },
  {
    path: "/service-requests/facing-maintenance-issue",
    component: <MaintenanceIssueList />,
  },
  {
    path: "/service-requests/support-ticket",
    component: <SupportTicketList />,
  },

  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
  //TICKETS

  {
    path: "/tickets/tickets-list",
    component: <TicketsList />,
  },
  {
    path: "/tickets/closed-tickets-list",
    component: <ClosedTicketsList />,
  },
  {
    path: "/tickets/open-tickets-list",
    component: <OpenTicketsList />,
  },
  {
    path: "/tickets/wip-tickets-list",
    component: <WipTicketsList />,
  },
  {
    path: "/tickets/view-ticket/:id",
    component: <TicketView />,
  },
  {
    path: "/tickets/ticket-dashboard",
    component: <TicketsDashboard />,
  },
  {
    path: "/tickets/critical-priority-tickets-list",
    component: <CriticalPriorityTicketList />,
  },
  {
    path: "/tickets/high-priority-tickets-list",
    component: <HighPriorityTicketList />,
  },
  {
    path: "/tickets/medium-priority-tickets-list",
    component: <MediumPriorityTicketList />,
  },
  {
    path: "/tickets/low-priority-tickets-list",
    component: <LowPriorityTicketList />,
  },

  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //CLIENTS
  { path: "/clients", component: <AllClients /> },
  { path: "/clients/customer-index", component: <CustomerIndex /> },
  { path: "/clients/add-new-client", component: <AddNewClient /> },
  { path: "/clients/edit-client/:id", component: <EditClient /> },
  { path: "/clients/view-client/:id", component: <ClientView /> },
  {
    path: "/clients/view-client/client-details/:id",
    component: <ClientDetail />,
  },
  {
    path: "/clients/view-client/client-documents/:id",
    component: <ClientDocuments />,
  },
  {
    path: "/clients/view-client/assigned-project-unit/:id",
    component: <AssignedProject />,
  },
  {
    path: "/clients/view-client/add-new-assigned-project-unit/:id",
    component: <AddNewAssignedProjectUnit />,
  },
  {
    path: "/clients/view-client/edit-new-assigned-project-unit/:id",
    component: <EditNewAssignedProjectUnit />,
  },
  {
    path: "/clients/view-client/view-assigned-project-unit/:id",
    component: <ViewAssignedProject />,
  },
  {
    path: "/clients/project-units-approval",
    component: <ProjectUnitsApproval />,
  },
  {
    path: "/clients/project-units-approval/view-project-units-approval/:id",
    component: <ProjectUnitsApprovalView />,
  },
  {
    path: "/clients/service-engagement/all-engagement-list",
    component: <AllEngagementList />,
  },
  {
    path: "/clients/service-engagement/approval-pending-list",
    component: <ApprovalPendingList />,
  },
  {
    path: "/clients/service-engagement/billing-setup-pending-list",
    component: <BillingSetupPendingList />,
  },
//CLIENTS - Manage Clients
{ path: "/clients/manage-user/add-new-client-user/:id", component: <AddNewClientUser /> },
  { path: "/clients/manage-user/edit-client-user/:id", component: <EditNewClientUser /> },

  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //VENDORS
  { path: "/vendors", component: <AllVendor /> },
  { path: "/add-new-vendor", component: <AddNewVendor /> },
  { path: "/vendors/edit-vendor/:id", component: <EditVendor /> },
  { path: "/edit-vendors-documents", component: <VendorsDocuments /> },
  { path: "/edit-vendors-contacts", component: <VendorsContacts /> },
  { path: "/vendor/view-vendor/:id", component: <VendorDetail /> },
  {
    path: "/vendor/add-new-vendor-contact/:id",
    component: <AddNewVendorContact />,
  },
  { path: "/vendor/edit-vendor-contact/:id", component: <EditVendorContact /> },
  //vendor payment
  { path: "/vendor-bill-history", component: <VendorBillHistoryList /> }, 
  { path: "/add-debit-note", component: <VendorBillHistoryList /> }, 
  { path: "/vendor-payment-history", component: <VendorPaymentHistoryList /> }, 
  { path: "/add-vendor-payment", component: <AddNewVendorPayment /> },
  { path: "/edit-vendor-payment/:id", component: <EditVendorPayment /> },
  { path: "/approve-vendor-bill/:id", component: <ApproveVendorPayment /> },
  { path: "/vendor/add-debit-note", component: <AddDebitNote /> },
  { path: "/vendor/edit-debit-note/:id", component: <EditDebitNote /> },
  { path: "/vendor/debit-note-list", component: <VendorDebitNoteList /> },
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //PROJECT
  { path: "/projects", component: <Projects /> },
  { path: "/add-new-project-unit", component: <AddProjectUnit /> },
  { path: "/project/edit-project-unit/:id", component: <AddProjectUnit /> },
  { path: "/add-new-project", component: <AddNewProject /> },
  { path: "/edit-project/:id", component: <AddNewProject /> },
  { path: "/project-details/:id", component: <ProjectDetails /> },
  {
    path: "/project-gallery/edit-project-gallery/:id",
    component: <EditProjectGallery />,
  },
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //USERS
  { path: "/users/add-new-user", component: <AddNewUser /> },
  { path: "/users", component: <User /> },
  { path: "/users/user-details/:id", component: <UserDetails /> },
  { path: "/users/edit-user/:id", component: <AddNewUser /> },
  { path: "/empty-user", component: <EmptyUsers /> },
  {
    path: "/users/add-new-pastemployment/:id",
    component: <Addnewpastemployment />,
  },
  {
    path: "/users/edit-new-pastemployment/:id",
    component: <Addnewpastemployment />,
  },
  { path: "/users/view-pastemployment/:id", component: <Viewpastemployment /> },
  {
    path: "/users/user-details/manage-user-permission/:id",
    component: <ManageUserPermission />,
  },
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //BILLING
  { path: "/billing/billing-setup/:id", component: <BillingSetup /> },
  { path: "/billing/edit-billing-setup/:id", component: <EditBillingSetup /> },
  {
    path: "/billing/billing-setup-preview/:id",
    component: <BillingSetupPreview />,
  },
  { path: "/billing/billing-register", component: <BillingRegister /> },
  { path: "/billing/create-new-bill", component: <CreateNewBill /> },
  { path: "/billing/edit-bill/:id", component: <EditBill /> },
  { path: "/billing/receipt", component: <Receipt /> },
  { path: "/billing/recurring-bills", component: <RecurringBills /> },
  { path: "/billing/add-receipt", component: <AddNewReceipt /> },
  { path: "/billing/edit-receipt/:id", component: <EditReceipt /> },
  { path: "/billing/record-payment/:id", component: <RecordPayment /> },
  { path: "/billing/preview-invoice/:id", component: <InvoicePreview /> },
  { path: "/billing/add-credit-note", component: <AddCreditNote /> },
  { path: "/billing/edit-credit-note/:id", component: <EditCreditNote /> },
  { path: "/billing/credit-note-list", component: <CreditNoteList /> },
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
  //Credit-Invoice
  { path: "/billing/preview-credit/:id", component: <CreditInvoicePreview /> },

  //REGIONAL SETTINGS (SETTINGS)
  { path: "/settings/regional-settings", component: <RegionalSettings /> },
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //USER ROLE MANAGEMENT (SETTINGS)
  { path: "/settings/user-role-management", component: <UserRoleManagement /> },
  {
    path: "/settings/user-role-management/edit-role/:id",
    component: <NewUser />,
  },
  { path: "/settings/user-role-management/new-user", component: <NewUser /> },
  {
    path: "/settings/user-role-management/manage-permission/:id",
    component: <ManagePermission />,
  },
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //COMPANY (SETTINGS)
  { path: "/settings/companies", component: <AccountSetup /> },
  { path: "/settings/company-setup-page", component: <AccountSetupPage /> },
  { path: "/settings/edit-company/:id", component: <AccountSetupPage /> },
  { path: "/settings/view-company/:id", component: <CompanyView /> },
  { path: "/settings/edit-company-location/:id", component: <EditCompanyLocation /> },
  { path: "/settings/add-company-location/:id", component: <AddCompanyLocation /> },
  { path: "/settings/view-company-location/:id", component: <ViewCompanyLocation /> },
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  // MASTER CONFIGURATION (SETTINGS)
  //VENDOR MASTER
  { path: "/settings/vendor-master", component: <VenderService /> },
  { path: "/settings/new-vendor", component: <NewVenderService /> },
  { path: "/settings/edit-vendor/:id", component: <NewVenderService /> },
  //FACILITY MASTER
  { path: "/settings/facility-master", component: <Facility /> },
  { path: "/settings/new-facility-master", component: <NewFacilityMaster /> },
  {
    path: "/settings/edit-facility-master/:id",
    component: <NewFacilityMaster />,
  },
  //DOCUMENT SERVICE MASTER
  { path: "/settings/document-master", component: <DocumentService /> },
  {
    path: "/settings/new-document-master-service",
    component: <NewDocumentService />,
  },
  {
    path: "/settings/edit-document-master-service/:id",
    component: <NewDocumentService />,
  },
  //EXPENSE MASTER
  { path: "/settings/expense-master", component: <ExpenseService /> },
  {
    path: "/settings/new-expense-master-service",
    component: <NewExpenseService />,
  },
  {
    path: "/settings/edit-expense-master-service/:id",
    component: <NewExpenseService />,
  },
  //MAINTAINENCE MASTER
  { path: "/settings/maintenence-service", component: <MaintenenceService /> },
  {
    path: "/settings/new-maintenence-master-service",
    component: <NewMaintenenceService />,
  },
  {
    path: "/settings/edit-maintenence-master-service/:id",
    component: <NewMaintenenceService />,
  },
  //CUSTOMER TICKET MASTER
  { path: "/settings/customer-ticket-master", component: <CustomerService /> },
  { path: "/settings/new-customer-ticket", component: <NewCustomerTicket /> },
  {
    path: "/settings/edit-customer-ticket/:id",
    component: <NewCustomerTicket />,
  },
  //INVOICE TYPE MASTER
  { path: "/settings/invoice-master", component: <InvoiceTypeService /> },
  {
    path: "/settings/new-invoice-service-master",
    component: <NewInvoiceService />,
  },
  {
    path: "/settings/edit-invoice-service-master/:id",
    component: <NewInvoiceService />,
  },
  //PROJECT UNIT MASTER
  { path: "/settings/project-unit-service", component: <ProjectUnit /> },
  { path: "/settings/new-project-unit", component: <NewProjectUnit /> },
  { path: "/settings/edit-project-unit/:id", component: <NewProjectUnit /> },
  //PROJECT COMPLIANCE MASTER
  { path: "/settings/project-service", component: <ProjectService /> },
  { path: "/settings/new-project-service", component: <NewProjectService /> },
  {
    path: "/settings/edit-project-service/:id",
    component: <NewProjectService />,
  },
  //LANDLORD MASTER
  { path: "/settings/landlord-service", component: <LandlordMaster /> },
  {
    path: "/settings/new-landlord-master-service",
    component: <NewLandlordService />,
  },
  {
    path: "/settings/edit-landlord-master-service/:id",
    component: <NewLandlordService />,
  },
  {
    path: "/settings/landlord-service/view-landlord-service/:id",
    component: <LandlordView />,
  },
  //SAC & HSN CODE MASTER
  { path: "/settings/sac-hsn-code-master", component: <SACHSNSERVICE /> },
  { path: "/settings/new-sac-hsn-code-master", component: <NewSACHSNCode /> },
  {
    path: "/settings/edit-sac-hsn-code-master/:id",
    component: <NewSACHSNCode />,
  },
  //MATERIAL MASTER
  { path: "/settings/material-master", component: <MATERIALMASTERLIST /> },
  { path: "/settings/new-material-master", component: <NewMaterialMaster /> },
  {
    path: "/settings/edit-material-master/:id",
    component: <NewMaterialMaster />,
  },

  //UNIT MASTER

  { path: "/settings/unit-master", component: <UNITMASTERLIST /> },
  { path: "/settings/new-unit-master", component: <NewUnitMaster /> },
  {
    path: "/settings/edit-unit-master/:id",
    component: <NewUnitMaster />,
  },
  //reason MASTER

  { path: "/settings/reason-master", component: <ReasonMasterList /> },
  { path: "/settings/new-reason-master", component: <NewReasonMaster /> },
  {
    path: "/settings/edit-reason-master/:id",
    component: <NewReasonMaster />,
  },
  //proposal master
  { path: "/settings/proposal-type-master", component: <ProposalTypeMasterList /> },
  { path: "/settings/new-proposal-type-master", component: <NewProposalTypeMaster /> },
  {
    path: "/settings/edit-proposal-type-master/:id",
    component: <NewProposalTypeMaster />,
  },

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

  //GST Report
  {
    path: "/reports/gst-report",
    component: <GstReports />,
  },
  {
    path: "/reports/customer-oustanding-report",
    component: <CustomerOustandingReport />,
  },
  //vendor Summary Report
  {
    path: "/reports/vendor-summary-report",
    component: <VendorSummaryReports />,
  },
  {
    path: "/reports/vendor-summary-report/vendor-outstanding-amount-report/:id",
    component: <OutstandingAmountData />,
  },
  {
    path: "/reports/vendor-summary-report/vendor-tds-data/:id",
    component: <VendorTdsData />,
  },
  {
    path: "/reports/vendor-summary-report/vendor-invoice-data/:id",
    component: <VendorInvoiceData />,
  },
  {
    path: "/reports/vendor-summary-report/vendor-invoice-Payment-data/:id",
    component: <VendorInvoicePayment />,
  },
  {
    path: "/reports/vendor-summary-report/vendor-ticket-data/:id",
    component: <VendorTicketData />,
  },

  //clientledger 
  {
    path: "/view-ledger/:id?",
    component: <ViewLedger />,
  },
  //vendor ledger
  {
    path: "/reports/vendor-summary-report/vendor-ledger/:id",
    component: <VendorLedger />,
  },
  {
    path: "/reports/project-occupancy-report",
    component: <ProjectOccupancyReports />,
  },

  //Consumption Report
  {
    path: "/consumption/consumption-report",
    component: <ConsumptionReportList />,
  },
  
  {
    path: "/consumption/add-new-consumption",
    component: <NewConsumption />,
    },
    
    //Renewals
      {
        path: "/renewals",
        component: <RenewalList />,
      },
      {
        path: "/renewals/renewal-detail/:id",
        component: <RenewalDetailList />,
        component: <RenewalDetailList />,
        component: <RenewalDetailList />,
        component: <RenewalDetailList />,
        component: <RenewalDetailList />,
      },
      {
        path: "/renewals/renewal-detail/thirtydays/:id",
        component: <Thirty />,
      },
      {
        path: "/renewals/renewal-detail/sixtydays/:id",
        component: <Sixty />,
      },
      {
        path: "/renewals/renewal-detail/ninetydays/:id",
        component: <Ninety />,
      },
      {
        path: "/renewals/renewal-detail/zerodays/:id",
        component: <Zero />,
      },
      {
        path: "/renewals/renewal-detail/overdue/:id",
        component: <Overdue />,
      },
      {
        path: "/renewals/renew-project-unit/:id",
        component: <RenewAssignedProjectUnit />,
      },
      {
        path: "/renewals/view-project-unit/:id",
        component: <ViewRenewalUnit />,
      },
//PROPOSAL 
      {
        path: "/vendor-proposal",
        component: <ProposalList/>,
      },
      
      {
        path: "/view-vendor-proposal/:id",
        component: <ProposalView/>,
      },
]

export { authProtectedRoutes, publicRoutes }
