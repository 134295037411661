import {
  GET_BILLING_CLIENT_DETAIL,
  GET_BILLING_CLIENT_DETAIL_SUCCESS,
  GET_BILLING_CLIENT_DETAIL_FAIL,
  GET_SERVICE_ENGAGEMENT,
  GET_SERVICE_ENGAGEMENT_SUCCESS,
  GET_SERVICE_ENGAGEMENT_FAIL,
  GET_BILLING_COMPANY_LIST,
  GET_BILLING_COMPANY_LIST_SUCCESS,
  GET_BILLING_COMPANY_LIST_FAIL,
  GET_BILLING_COMPANY_DETAIL,
  GET_BILLING_COMPANY_DETAIL_SUCCESS,
  GET_BILLING_COMPANY_DETAIL_FAIL,
  GET_SUPPLY_LIST,
  GET_SUPPLY_LIST_SUCCESS,
  GET_SUPPLY_LIST_FAIL,
  GET_PLACE_OF_SUPPLY,
  GET_PLACE_OF_SUPPLY_SUCCESS,
  GET_PLACE_OF_SUPPLY_FAIL,
  GET_APPLICABLE_RATE_LIST,
  GET_APPLICABLE_RATE_LIST_SUCCESS,
  GET_APPLICABLE_RATE_LIST_FAIL,
  GET_INVOICE_TYPE_MASTER_LIST,
  GET_INVOICE_TYPE_MASTER_LIST_SUCCESS,
  GET_INVOICE_TYPE_MASTER_LIST_FAIL,
  ADD_BILLING_SETUP,
  ADD_BILLING_SETUP_SUCCESS,
  ADD_BILLING_SETUP_FAIL,
  GET_BILLING_CLIENT_LIST,
  GET_BILLING_CLIENT_LIST_SUCCESS,
  GET_INVOICE_NUMBER_FAIL,
  GET_INVOICE_NUMBER_SUCCESS,
  GET_INVOICE_NUMBER,
  ADD_NEW_BILL,
  ADD_NEW_BILL_SUCCESS,
  ADD_NEW_BILL_FAIL,
  GET_BILLING_CLIENT_LIST_FAIL,
  GET_BILLING_REGISTER_SUCCESS,
  GET_BILLING_REGISTER,
  GET_BILLING_REGISTER_FAIL,
  GET_BILLING_DATA,
  GET_BILLING_DATA_SUCCESS,
  GET_BILLING_DATA_FAIL,
  EDIT_BILL,
  EDIT_BILL_SUCCESS,
  EDIT_BILL_FAIL,
  GET_INVOICE_PREVIEW_DETAIL,
  GET_INVOICE_PREVIEW_DETAIL_SUCCESS,
  GET_INVOICE_PREVIEW_DETAIL_FAIL,
  GET_PAYMENT_MODE,
  GET_PAYMENT_MODE_SUCCESS,
  GET_PAYMENT_MODE_FAIL,
  ADD_RECORD_PAYMENT,
  ADD_RECORD_PAYMENT_SUCCESS,
  ADD_RECORD_PAYMENT_FAIL,
  DELETE_BILLING_REGISTER_FAIL,
  DELETE_BILLING_REGISTER_SUCCESS,
  DELETE_BILLING_REGISTER,
  GET_RECORD_PAYMENT_DETAIL_SUCCESS,
  GET_RECORD_PAYMENT_DETAIL_FAIL,
  GET_RECORD_PAYMENT_DETAIL,
  ADD_BILLING_RECEIPT,
  ADD_BILLING_RECEIPT_SUCCESS,
  ADD_BILLING_RECEIPT_FAIL,
  GET_BILLING_RECEIPT_LIST,
  GET_BILLING_RECEIPT_LIST_SUCCESS,
  GET_BILLING_RECEIPT_LIST_FAIL,
  EDIT_BILLING_RECEIPT,
  EDIT_BILLING_RECEIPT_SUCCESS,
  EDIT_BILLING_RECEIPT_FAIL,
  GET_BILL_INVOICE_NO_LIST_SUCCESS,
  GET_BILL_INVOICE_NO_LIST_FAIL,
  GET_BILL_INVOICE_NO_LIST,
  GET_RECEIPT_NUMBER,
  GET_RECEIPT_NUMBER_SUCCESS,
  GET_RECEIPT_NUMBER_FAIL,
  SEND_MAIL_TO_CUSTOMER,
  SEND_MAIL_TO_CUSTOMER_SUCCESS,
  SEND_MAIL_TO_CUSTOMER_FAIL,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_FAIL,
  GET_BILLING_RECEIPT_DETAIL,
  GET_BILLING_RECEIPT_DETAIL_SUCCESS,
  GET_BILLING_RECEIPT_DETAIL_FAIL,
  DELETE_BILLING_RECORD,
  DELETE_BILLING_RECORD_SUCCESS,
  DELETE_BILLING_RECORD_FAIL,
  GET_BILLING_SETUP_DETAIL,
  GET_BILLING_SETUP_DETAIL_SUCCESS,
  GET_BILLING_SETUP_DETAIL_FAIL,
  EDIT_BILLING_SETUP_FAIL,
  EDIT_BILLING_SETUP,
  DELETE_BILLING_RECORD_PAYMENT,
  DELETE_BILLING_RECORD_PAYMENT_SUCCESS,
  DELETE_BILLING_RECORD_PAYMENT_FAIL,
  GET_SACHSN_CODE_BILLING,
  GET_SACHSN_CODE_BILLING_SUCCESS,
  GET_SACHSN_CODE_BILLING_FAIL,
  GET_SACHSN_CODE_BILLING_SETUP,
  GET_SACHSN_CODE_BILLING_SETUP_SUCCESS,
  GET_SACHSN_CODE_BILLING_SETUP_FAIL,
  GET_RECURRING_BILL,
  GET_RECURRING_BILL_SUCCESS,
  GET_RECURRING_BILL_FAIL,
  GET_ASSIGNED_PROJECT_UNIT_IN_BILLING,
  GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_SUCCESS,
  GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_FAIL,
  GET_PROJECT_LIST_IN_BILLING,
  GET_PROJECT_LIST_IN_BILLING_SUCCESS,
  GET_PROJECT_LIST_IN_BILLING_FAIL,
  GET_PROJECT_DETAIL_IN_BILLING,
  GET_PROJECT_DETAIL_IN_BILLING_SUCCESS,
  GET_PROJECT_DETAIL_IN_BILLING_FAIL,
  GET_PROJECT_UNIT_DETAIL_IN_BILLING,
  GET_PROJECT_UNIT_DETAIL_IN_BILLING_SUCCESS,
  GET_PROJECT_UNIT_DETAIL_IN_BILLING_FAIL,
  GET_BILLING_CLIENT_LIST_IN_RECEIPT,
  GET_BILLING_CLIENT_LIST_IN_RECEIPT_SUCCESS,
  GET_BILLING_CLIENT_LIST_IN_RECEIPT_FAIL,
  GET_BILLING_COMPANY_LIST_IN_RECEIPT,
  GET_BILLING_COMPANY_LIST_IN_RECEIPT_SUCCESS,
  GET_BILLING_COMPANY_LIST_IN_RECEIPT_FAIL,
  GET_BILLING_COMPANY_DETAIL_IN_RECEIPT,
  GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_SUCCESS,
  GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_FAIL,
  GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT,
  GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_SUCCESS,
  GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_FAIL,
  GET_GST_LOCATION_LIST_FAIL,
  GET_GST_LOCATION_LIST_SUCCESS,
  GET_GST_LOCATION_LIST,
  GET_GST_LOCATION_DETAIL,
  GET_GST_LOCATION_DETAIL_SUCCESS,
  GET_GST_LOCATION_DETAIL_FAIL,
  GET_COMPANY_LOCATION_FILTER,
  GET_COMPANY_FILTER,
  GET_COMPANY_FILTER_SUCCESS,
  GET_COMPANY_FILTER_FAIL,
  GET_COMPANY_LOCATION_FILTER_SUCCESS,
  GET_COMPANY_LOCATION_FILTER_FAIL,
  GET_CUSTOMER_FILTER,
  GET_CUSTOMER_FILTER_SUCCESS,
  GET_CUSTOMER_FILTER_FAIL,
  GET_PROJECT_FILTER,
  GET_PROJECT_FILTER_SUCCESS,
  GET_PROJECT_FILTER_FAIL,
  GET_FINANCIAL_YEAR_FILTER,
  GET_FINANCIAL_YEAR_FILTER_SUCCESS,
  GET_FINANCIAL_YEAR_FILTER_FAIL,
  GET_COMPANY_FILTER_FOR_RECEIPT,
  GET_COMPANY_FILTER_FOR_RECEIPT_SUCCESS,
  GET_COMPANY_FILTER_FOR_RECEIPT_FAIL,
  GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT,
  GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_SUCCESS,
  GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_FAIL,
  GET_CUSTOMER_FILTER_FOR_RECEIPT,
  GET_CUSTOMER_FILTER_FOR_RECEIPT_SUCCESS,
  GET_CUSTOMER_FILTER_FOR_RECEIPT_FAIL,
  GET_PROJECT_FILTER_FOR_RECEIPT,
  GET_PROJECT_FILTER_FOR_RECEIPT_SUCCESS,
  GET_PROJECT_FILTER_FOR_RECEIPT_FAIL,
  GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT,
  GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_SUCCESS,
  GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_FAIL,
  GET_RECEIPT_PREVIEW,
  GET_RECEIPT_PREVIEW_SUCCESS,
  GET_RECEIPT_PREVIEW_FAIL,
  SEND_RECEIPT_MAIL_TO_CUSTOMER,
  SEND_RECEIPT_MAIL_TO_CUSTOMER_SUCCESS,
  SEND_RECEIPT_MAIL_TO_CUSTOMER_FAIL,
  GET_CONSUMPTION_REGISTER,
  GET_CONSUMPTION_REGISTER_SUCCESS,
  GET_CONSUMPTION_REGISTER_FAIL,
  ADD_NEW_CONSUMPTION,
  ADD_NEW_CONSUMPTION_SUCCESS,
  ADD_NEW_CONSUMPTION_FAIL,
  EDIT_CONSUMPTION,
  EDIT_CONSUMPTION_SUCCESS,
  EDIT_CONSUMPTION_FAIL,
  GET_CONSUMPTION_REGISTER_LIST,
  GET_CONSUMPTION_REGISTER_LIST_SUCCESS,
  GET_CONSUMPTION_REGISTER_LIST_FAIL,
  DELETE_CONSUMPTION_REGISTER,
  DELETE_CONSUMPTION_REGISTER_SUCCESS,
  DELETE_CONSUMPTION_REGISTER_FAIL,
  GET_CONSUMPTION_DATA,
  GET_CONSUMPTION_DATA_SUCCESS,
  GET_CONSUMPTION_DATA_FAIL,
  GET_CREDIT_NUMBER,
  GET_CREDIT_NUMBER_SUCCESS,
  GET_CREDIT_NUMBER_FAIL,
  GET_BILLING_CREDIT_NOTE_LIST,
  GET_BILLING_CREDIT_NOTE_LIST_SUCCESS,
  GET_BILLING_CREDIT_NOTE_LIST_FAIL,
  GET_COMPONENTS_FOR_CREDIT_NOTE,
  GET_COMPONENTS_FOR_CREDIT_NOTE_SUCCESS,
  GET_COMPONENTS_FOR_CREDIT_NOTE_FAIL,
  ADD_CREDIT_NOTE_SUCCESS,
  ADD_CREDIT_NOTE_FAIL,
  GET_CREDIT_NOTE_REASONS,
  GET_CREDIT_NOTE_REASONS_SUCCESS,
  GET_CREDIT_NOTE_REASONS_FAIL,
  GET_CREDIT_NOTE_INVOICE_LIST,
  GET_CREDIT_NOTE_INVOICE_LIST_SUCCESS,
  GET_CREDIT_NOTE_INVOICE_LIST_FAIL,
  ADD_CREDIT_NOTE,
  GET_CREDIT_DATA,
  GET_CREDIT_DATA_SUCCESS,
  GET_CREDIT_DATA_FAIL,
  EDIT_CREDIT_NOTE,
  EDIT_CREDIT_NOTE_SUCCESS,
  EDIT_CREDIT_NOTE_FAIL,
  DELETE_CREDIT_NOTE,
  DELETE_CREDIT_NOTE_SUCCESS,
  DELETE_CREDIT_NOTE_FAIL,
  GET_CREDIT_INVOICE_PREVIEW_DETAIL,
  GET_CREDIT_INVOICE_PREVIEW_DETAIL_SUCCESS,
  GET_CREDIT_INVOICE_PREVIEW_DETAIL_FAIL,
} from "./actionTypes"

export const getBillingRegisterList = id => ({
  type: GET_BILLING_REGISTER,
  id,
})
export const getBillingRegisterListSuccess = client => ({
  type: GET_BILLING_REGISTER_SUCCESS,
  payload: client,
})

export const getBillingRegisterListFail = error => ({
  type: GET_BILLING_REGISTER_FAIL,
  payload: error,
})

export const getBillingClientList = id => ({
  type: GET_BILLING_CLIENT_LIST,
  id,
})
export const getBillingClientListSuccess = client => ({
  type: GET_BILLING_CLIENT_LIST_SUCCESS,
  payload: client,
})

export const getBillingClientListFail = error => ({
  type: GET_BILLING_CLIENT_LIST_FAIL,
  payload: error,
})
export const getBillingClientListInReceipt = id => ({
  type: GET_BILLING_CLIENT_LIST_IN_RECEIPT,
  id,
})
export const getBillingClientListInReceiptSuccess = client => ({
  type: GET_BILLING_CLIENT_LIST_IN_RECEIPT_SUCCESS,
  payload: client,
})

export const getBillingClientListInReceiptFail = error => ({
  type: GET_BILLING_CLIENT_LIST_IN_RECEIPT_FAIL,
  payload: error,
})
export const getBillingCompanyListInReceipt = id => ({
  type: GET_BILLING_COMPANY_LIST_IN_RECEIPT,
  id,
})
export const getBillingCompanyListInReceiptSuccess = client => ({
  type: GET_BILLING_COMPANY_LIST_IN_RECEIPT_SUCCESS,
  payload: client,
})

export const getBillingCompanyListInReceiptFail = error => ({
  type: GET_BILLING_COMPANY_LIST_IN_RECEIPT_FAIL,
  payload: error,
})

export const getBillingClientDetail = clientId => ({
  type: GET_BILLING_CLIENT_DETAIL,
  clientId,
})

export const getBillingClientDetailSuccess = client => ({
  type: GET_BILLING_CLIENT_DETAIL_SUCCESS,
  payload: client,
})

export const getBillingClientDetailFail = error => ({
  type: GET_BILLING_CLIENT_DETAIL_FAIL,
  payload: error,
})

export const getEngagementService = projectId => ({
  type: GET_SERVICE_ENGAGEMENT,
  projectId,
})

export const getEngagementServiceSuccess = project => ({
  type: GET_SERVICE_ENGAGEMENT_SUCCESS,
  payload: project,
})

export const getEngagementServiceFail = error => ({
  type: GET_SERVICE_ENGAGEMENT_FAIL,
  payload: error,
})

export const getBillingCompanyList = unit_id => ({
  type: GET_BILLING_COMPANY_LIST,
  unit_id,
})

export const getBillingCompanyListSuccess = company => ({
  type: GET_BILLING_COMPANY_LIST_SUCCESS,
  payload: company,
})

export const getBillingCompanyListFail = error => ({
  type: GET_BILLING_COMPANY_LIST_FAIL,
  payload: error,
})

export const getBillingCompanyDetail = companyId => ({
  type: GET_BILLING_COMPANY_DETAIL,
  companyId,
})

export const getBillingCompanyDetailSuccess = company => ({
  type: GET_BILLING_COMPANY_DETAIL_SUCCESS,
  payload: company,
})

export const getBillingCompanyDetailFail = error => ({
  type: GET_BILLING_COMPANY_DETAIL_FAIL,
  payload: error,
})
export const getBillingCompanyDetailInReceipt = companyId => ({
  type: GET_BILLING_COMPANY_DETAIL_IN_RECEIPT,
  companyId,
})

export const getBillingCompanyDetailInReceiptSuccess = company => ({
  type: GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_SUCCESS,
  payload: company,
})

export const getBillingCompanyDetailInReceiptFail = error => ({
  type: GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_FAIL,
  payload: error,
})

export const getSupplyList = () => ({
  type: GET_SUPPLY_LIST,
})

export const getSupplyListSuccess = supplyList => ({
  type: GET_SUPPLY_LIST_SUCCESS,
  payload: supplyList,
})

export const getSupplyListFail = error => ({
  type: GET_SUPPLY_LIST_FAIL,
  payload: error,
})

export const getPlaceOfSupply = () => ({
  type: GET_PLACE_OF_SUPPLY,
})

export const getPlaceOfSupplySuccess = supplyList => ({
  type: GET_PLACE_OF_SUPPLY_SUCCESS,
  payload: supplyList,
})

export const getPlaceOfSupplyFail = error => ({
  type: GET_PLACE_OF_SUPPLY_FAIL,
  payload: error,
})

export const getApplicableRateList = () => ({
  type: GET_APPLICABLE_RATE_LIST,
})

export const getApplicableRateListSuccess = rateList => ({
  type: GET_APPLICABLE_RATE_LIST_SUCCESS,
  payload: rateList,
})

export const getApplicableRateListFail = error => ({
  type: GET_APPLICABLE_RATE_LIST_FAIL,
  payload: error,
})

export const getInvoiceTypeMasterList = () => ({
  type: GET_INVOICE_TYPE_MASTER_LIST,
})

export const getInvoiceTypeMasterListSuccess = invoiceList => ({
  type: GET_INVOICE_TYPE_MASTER_LIST_SUCCESS,
  payload: invoiceList,
})

export const getInvoiceTypeMasterListFail = error => ({
  type: GET_INVOICE_TYPE_MASTER_LIST_FAIL,
  payload: error,
})

export const addBillingSetup = (history, bill) => ({
  type: ADD_BILLING_SETUP,
  payload: { history, bill },
})

export const addBillingSetupSuccess = bill => ({
  type: ADD_BILLING_SETUP_SUCCESS,
  payload: bill,
})

export const addBillingSetupFail = error => ({
  type: ADD_BILLING_SETUP_FAIL,
  payload: error,
})
//////edit billing setup
export const editBillingSetup = (history, billId, bill) => ({
  type: EDIT_BILLING_SETUP,
  payload: { history, billId, bill },
})

export const editBillingSetupSuccess = bill => ({
  type: EDIT_BILLING_SETUP_FAIL,
  payload: bill,
})

export const editBillingSetupFail = error => ({
  type: EDIT_BILLING_SETUP_FAIL,
  payload: error,
})
//add new Bill
export const addNewBill = (history, bill) => ({
  type: ADD_NEW_BILL,
  payload: { history, bill },
})

export const addNewBillSuccess = bill => ({
  type: ADD_NEW_BILL_SUCCESS,
  payload: bill,
})

export const addNewBillFail = error => ({
  type: ADD_NEW_BILL_FAIL,
  payload: error,
})
//add new Bill
export const editBill = (billingId, history, bill) => ({
  type: EDIT_BILL,
  payload: { billingId, history, bill },
})

export const editBillSuccess = bill => ({
  type: EDIT_BILL_SUCCESS,
  payload: bill,
})

export const editBillFail = error => ({
  type: EDIT_BILL_FAIL,
  payload: error,
})

//invoice gernerate api

export const getInvoiceNumber = companyId => ({
  type: GET_INVOICE_NUMBER,
  companyId,
})

export const getInvoiceNumberSuccess = client => ({
  type: GET_INVOICE_NUMBER_SUCCESS,
  payload: client,
})

export const getInvoiceNumberFail = error => ({
  type: GET_INVOICE_NUMBER_FAIL,
  payload: error,
})
//invoice gernerate api

//get billing data
export const getBillingData = billingId => ({
  type: GET_BILLING_DATA,
  billingId,
})
export const getBillingDataSuccess = billing => ({
  type: GET_BILLING_DATA_SUCCESS,
  payload: billing,
})

export const getBillingDataFail = error => ({
  type: GET_BILLING_DATA_FAIL,
  payload: error,
})

// get billing Invoice details

export const getInvoicePreviewDetail = billId => ({
  type: GET_INVOICE_PREVIEW_DETAIL,
  billId,
})

export const getInvoicePreviewDetailSuccess = bill => ({
  type: GET_INVOICE_PREVIEW_DETAIL_SUCCESS,
  payload: bill,
})

export const getInvoicePreviewDetailFail = error => ({
  type: GET_INVOICE_PREVIEW_DETAIL_FAIL,
  payload: error,
})

//get payment mode

export const getPaymentMode = clientId => ({
  type: GET_PAYMENT_MODE,
  clientId,
})
export const getPaymentModeSuccess = client => ({
  type: GET_PAYMENT_MODE_SUCCESS,
  payload: client,
})

export const getPaymentModeFail = error => ({
  type: GET_PAYMENT_MODE_FAIL,
  payload: error,
})

//add new Bill
export const addRecordPayment = (history, recordPayment) => ({
  type: ADD_RECORD_PAYMENT,
  payload: { history, recordPayment },
})

export const addRecordPaymentSuccess = recordPayment => ({
  type: ADD_RECORD_PAYMENT_SUCCESS,
  payload: recordPayment,
})

export const addRecordPaymentFail = error => ({
  type: ADD_RECORD_PAYMENT_FAIL,
  payload: error,
})

//delete
export const deleteBillingRegister = billId => ({
  type: DELETE_BILLING_REGISTER,
  billId,
})

export const deleteBillingRegisterSuccess = billId => ({
  type: DELETE_BILLING_REGISTER_SUCCESS,
  payload: billId,
})

export const deleteBillingRegisterFail = error => ({
  type: DELETE_BILLING_REGISTER_FAIL,
  payload: error,
})

export const getRecordPaymentDetail = billId => ({
  type: GET_RECORD_PAYMENT_DETAIL,
  billId,
})

export const getRecordPaymentDetailSuccess = bill => ({
  type: GET_RECORD_PAYMENT_DETAIL_SUCCESS,
  payload: bill,
})

export const getRecordPaymentDetailFail = error => ({
  type: GET_RECORD_PAYMENT_DETAIL_FAIL,
  payload: error,
})

//add new Receipt
export const addNewBillingReceipt = (history, receipt) => ({
  type: ADD_BILLING_RECEIPT,
  payload: { history, receipt },
})

export const addNewBillingReceiptSuccess = receipt => ({
  type: ADD_BILLING_RECEIPT_SUCCESS,
  payload: receipt,
})

export const addNewBillingReceiptFail = error => ({
  type: ADD_BILLING_RECEIPT_FAIL,
  payload: error,
})

//get billing receit list

export const getBillingReceiptList = values => ({
  type: GET_BILLING_RECEIPT_LIST,
  payload: { values },
})
export const getBillingReceiptListSuccess = receipt => ({
  type: GET_BILLING_RECEIPT_LIST_SUCCESS,
  payload: receipt,
})

export const getBillingReceiptListFail = error => ({
  type: GET_BILLING_RECEIPT_LIST_FAIL,
  payload: error,
})

//edit new Bill
export const editBillingReceipt = (paymentId, history, bill) => ({
  type: EDIT_BILLING_RECEIPT,
  payload: { paymentId, history, bill },
})

export const editBillingReceiptSuccess = bill => ({
  type: EDIT_BILLING_RECEIPT_SUCCESS,
  payload: bill,
})

export const editBillingReceiptFail = error => ({
  type: EDIT_BILLING_RECEIPT_FAIL,
  payload: error,
})

///Bill no. Number

export const getBillInvoiceNoList = companyId => ({
  type: GET_BILL_INVOICE_NO_LIST,
  companyId,
})

export const getBillInvoiceNoListSuccess = invoiceList => ({
  type: GET_BILL_INVOICE_NO_LIST_SUCCESS,
  payload: invoiceList,
})

export const getBillInvoiceNoListFail = error => ({
  type: GET_BILL_INVOICE_NO_LIST_FAIL,
  payload: error,
})

//receipt gernerate api
export const getReceiptNumber = companyId => ({
  type: GET_RECEIPT_NUMBER,
  companyId,
})

export const getReceiptNumberSuccess = client => ({
  type: GET_RECEIPT_NUMBER_SUCCESS,
  payload: client,
})

export const getReceiptNumberFail = error => ({
  type: GET_RECEIPT_NUMBER_FAIL,
  payload: error,
})
//send mail to customer
export const sendEmailToCustomer = (customerId, billId) => ({
  type: SEND_MAIL_TO_CUSTOMER,
  payload: { customerId, billId },
})

export const sendEmailToCustomerSuccess = billId => ({
  type: SEND_MAIL_TO_CUSTOMER_SUCCESS,
  payload: billId,
})

export const sendEmailToCustomerFail = error => ({
  type: SEND_MAIL_TO_CUSTOMER_FAIL,
  payload: error,
})

//download invoice
export const downloadInvoice = billId => ({
  type: DOWNLOAD_INVOICE,
  billId,
})

export const downloadInvoiceSuccess = bill => ({
  type: DOWNLOAD_INVOICE_SUCCESS,
  payload: bill,
})

export const downloadInvoiceFail = error => ({
  type: DOWNLOAD_INVOICE_FAIL,
  payload: error,
})

//billing receipt details
export const getBillingReceiptDetail = receiptId => ({
  type: GET_BILLING_RECEIPT_DETAIL,
  receiptId,
})

export const getBillingReceiptDetailSuccess = receipt => ({
  type: GET_BILLING_RECEIPT_DETAIL_SUCCESS,
  payload: receipt,
})

export const getBillingReceiptDetailFail = error => ({
  type: GET_BILLING_RECEIPT_DETAIL_FAIL,
  payload: error,
})

//delete
export const deleteBillingRecord = billId => ({
  type: DELETE_BILLING_RECORD,
  billId,
})

export const deleteBillingRecordSuccess = billId => ({
  type: DELETE_BILLING_RECORD_SUCCESS,
  payload: billId,
})

export const deleteBillingRecordFail = error => ({
  type: DELETE_BILLING_RECORD_FAIL,
  payload: error,
})

export const getBillingSetupDetail = billId => ({
  type: GET_BILLING_SETUP_DETAIL,
  billId,
})

export const getBillingSetupDetailSuccess = bill => ({
  type: GET_BILLING_SETUP_DETAIL_SUCCESS,
  payload: bill,
})

export const getBillingSetupDetailFail = error => ({
  type: GET_BILLING_SETUP_DETAIL_FAIL,
  payload: error,
})

//delete record payment and receipt

//delete
export const deleteBillingReceipt = billId => ({
  type: DELETE_BILLING_RECORD_PAYMENT,
  billId,
})

export const deleteBillingReceiptSuccess = billId => ({
  type: DELETE_BILLING_RECORD_PAYMENT_SUCCESS,
  payload: billId,
})

export const deleteBillingReceiptFail = error => ({
  type: DELETE_BILLING_RECORD_PAYMENT_FAIL,
  payload: error,
})

export const getSacHsnCodeBillingSetup = () => ({
  type: GET_SACHSN_CODE_BILLING_SETUP,
})

export const getSacHsnCodeBillingSetupSuccess = codes => ({
  type: GET_SACHSN_CODE_BILLING_SETUP_SUCCESS,
  payload: codes,
})

export const getSacHsnCodeBillingSetupFail = error => ({
  type: GET_SACHSN_CODE_BILLING_SETUP_FAIL,
  payload: error,
})

export const getSacHsnCodeBilling = () => ({
  type: GET_SACHSN_CODE_BILLING,
})

export const getSacHsnCodeBillingSuccess = codes => ({
  type: GET_SACHSN_CODE_BILLING_SUCCESS,
  payload: codes,
})

export const getSacHsnCodeBillingFail = error => ({
  type: GET_SACHSN_CODE_BILLING_FAIL,
  payload: error,
})

export const getRecurringBillList = clientId => ({
  type: GET_RECURRING_BILL,
  clientId,
})
export const getRecurringBillListSuccess = client => ({
  type: GET_RECURRING_BILL_SUCCESS,
  payload: client,
})

export const getRecurringBillListFail = error => ({
  type: GET_RECURRING_BILL_FAIL,
  payload: error,
})
//Project Unit list
export const getAssignedProjectUnitListInBilling = value => ({
  type: GET_ASSIGNED_PROJECT_UNIT_IN_BILLING,
  value,
})
export const getAssignedProjectUnitListInBillingSuccess = client => ({
  type: GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_SUCCESS,
  payload: client,
})

export const getAssignedProjectUnitListInBillingFail = error => ({
  type: GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_FAIL,
  payload: error,
})

//project List in billing

export const getProjectListInBilling = id => ({
  type: GET_PROJECT_LIST_IN_BILLING,
  id,
})
export const getProjectListInBillingSuccess = projectList => ({
  type: GET_PROJECT_LIST_IN_BILLING_SUCCESS,
  payload: projectList,
})

export const getProjectListInBillingFail = error => ({
  type: GET_PROJECT_LIST_IN_BILLING_FAIL,
  payload: error,
})

// get billing Project detail
export const getProjectDetailInBilling = projectId => ({
  type: GET_PROJECT_DETAIL_IN_BILLING,
  projectId,
})

export const getProjectDetailInBillingSuccess = project => ({
  type: GET_PROJECT_DETAIL_IN_BILLING_SUCCESS,
  payload: project,
})

export const getProjectDetailInBillingFail = error => ({
  type: GET_PROJECT_DETAIL_IN_BILLING_FAIL,
  payload: error,
})
//project Unit details
export const getProjectUnitDetailInBilling = projectUnitId => ({
  type: GET_PROJECT_UNIT_DETAIL_IN_BILLING,
  projectUnitId,
})

export const getProjectUnitDetailInBillingSuccess = project => ({
  type: GET_PROJECT_UNIT_DETAIL_IN_BILLING_SUCCESS,
  payload: project,
})

export const getProjectUnitDetailInBillingFail = error => ({
  type: GET_PROJECT_UNIT_DETAIL_IN_BILLING_FAIL,
  payload: error,
})

//get Amount details details
export const getOutstandingAmountDetailInBillingReceipt = invoiceId => ({
  type: GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT,
  invoiceId,
})

export const getOutstandingAmountDetailInBillingReceiptSuccess = project => ({
  type: GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_SUCCESS,
  payload: project,
})

export const getOutstandingAmountDetailInBillingReceiptFail = error => ({
  type: GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_FAIL,
  payload: error,
})

export const getGstLocationList = id => ({
  type: GET_GST_LOCATION_LIST,
  id,
})
export const getGstLocationListSuccess = client => ({
  type: GET_GST_LOCATION_LIST_SUCCESS,
  payload: client,
})

export const getGstLocationListFail = error => ({
  type: GET_GST_LOCATION_LIST_FAIL,
  payload: error,
})

//get gst detail
export const getGstLocationDetail = locationId => ({
  type: GET_GST_LOCATION_DETAIL,
  locationId,
})

export const getGstLocationDetailSuccess = location => ({
  type: GET_GST_LOCATION_DETAIL_SUCCESS,
  payload: location,
})

export const getGstLocationDetailFail = error => ({
  type: GET_GST_LOCATION_DETAIL_FAIL,
  payload: error,
})

//GET COMPANY FILTER

export const getCompanyFilter = userId => ({
  type: GET_COMPANY_FILTER,
  userId,
})

export const getCompanyFilterSuccess = companyFilter => ({
  type: GET_COMPANY_FILTER_SUCCESS,
  payload: companyFilter,
})

export const getCompanyFilterFail = error => ({
  type: GET_COMPANY_FILTER_FAIL,
  payload: error,
})

//GET COMPANY LOCATION FILTER

export const getCompanyLocationFilter = userId => ({
  type: GET_COMPANY_LOCATION_FILTER,
  userId,
})

export const getCompanyLocationFilterSuccess = locationFilter => ({
  type: GET_COMPANY_LOCATION_FILTER_SUCCESS,
  payload: locationFilter,
})

export const getCompanyLocationFilterFail = error => ({
  type: GET_COMPANY_LOCATION_FILTER_FAIL,
  payload: error,
})

//GET CUSTOMER FILTER

export const getCustomerFilter = userId => ({
  type: GET_CUSTOMER_FILTER,
  userId,
})

export const getCustomerFilterSuccess = customerFilter => ({
  type: GET_CUSTOMER_FILTER_SUCCESS,
  payload: customerFilter,
})

export const getCustomerFilterFail = error => ({
  type: GET_CUSTOMER_FILTER_FAIL,
  payload: error,
})

//GET PROJECT FILTER

export const getProjectFilter = userId => ({
  type: GET_PROJECT_FILTER,
  userId,
})

export const getProjectFilterSuccess = projectFilter => ({
  type: GET_PROJECT_FILTER_SUCCESS,
  payload: projectFilter,
})

export const getProjectFilterFail = error => ({
  type: GET_PROJECT_FILTER_FAIL,
  payload: error,
})

//GET FINANCIAL YEAR FILTER

export const getFinancialYearFilter = userId => ({
  type: GET_FINANCIAL_YEAR_FILTER,
  userId,
})

export const getFinancialYearFilterSuccess = financialYearFilter => ({
  type: GET_FINANCIAL_YEAR_FILTER_SUCCESS,
  payload: financialYearFilter,
})

export const getFinancialYearFilterFail = error => ({
  type: GET_FINANCIAL_YEAR_FILTER_FAIL,
  payload: error,
})

//FOR RECEIPT
//GET COMPANY FILTER

export const getCompanyFilterForReceipt = userId => ({
  type: GET_COMPANY_FILTER_FOR_RECEIPT,
  userId,
})

export const getCompanyFilterForReceiptSuccess = companyFilter => ({
  type: GET_COMPANY_FILTER_FOR_RECEIPT_SUCCESS,
  payload: companyFilter,
})

export const getCompanyFilterForReceiptFail = error => ({
  type: GET_COMPANY_FILTER_FOR_RECEIPT_FAIL,
  payload: error,
})

//GET COMPANY LOCATION FILTER

export const getCompanyLocationFilterForReceipt = userId => ({
  type: GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT,
  userId,
})

export const getCompanyLocationFilterForReceiptSuccess = locationFilter => ({
  type: GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_SUCCESS,
  payload: locationFilter,
})

export const getCompanyLocationFilterForReceiptFail = error => ({
  type: GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_FAIL,
  payload: error,
})

//GET CUSTOMER FILTER

export const getCustomerFilterForReceipt = userId => ({
  type: GET_CUSTOMER_FILTER_FOR_RECEIPT,
  userId,
})

export const getCustomerFilterForReceiptSuccess = customerFilter => ({
  type: GET_CUSTOMER_FILTER_FOR_RECEIPT_SUCCESS,
  payload: customerFilter,
})

export const getCustomerFilterForReceiptFail = error => ({
  type: GET_CUSTOMER_FILTER_FOR_RECEIPT_FAIL,
  payload: error,
})

//GET PROJECT FILTER

export const getProjectFilterForReceipt = userId => ({
  type: GET_PROJECT_FILTER_FOR_RECEIPT,
  userId,
})

export const getProjectFilterForReceiptSuccess = projectFilter => ({
  type: GET_PROJECT_FILTER_FOR_RECEIPT_SUCCESS,
  payload: projectFilter,
})

export const getProjectFilterForReceiptFail = error => ({
  type: GET_PROJECT_FILTER_FOR_RECEIPT_FAIL,
  payload: error,
})

//GET FINANCIAL YEAR FILTER

export const getFinancialYearFilterForReceipt = userId => ({
  type: GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT,
  userId,
})

export const getFinancialYearFilterForReceiptSuccess = financialYearFilter => ({
  type: GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_SUCCESS,
  payload: financialYearFilter,
})

export const getFinancialYearFilterForReceiptFail = error => ({
  type: GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_FAIL,
  payload: error,
})

export const getReceiptPreview = id => ({
  type: GET_RECEIPT_PREVIEW,
  id,
})

export const getReceiptPreviewSuccess = receiptPreview => ({
  type: GET_RECEIPT_PREVIEW_SUCCESS,
  payload: receiptPreview,
})

export const getReceiptPreviewFail = error => ({
  type: GET_RECEIPT_PREVIEW_FAIL,
  payload: error,
})

export const sendReceiptEmailToCustomer = (customerId, receiptId) => ({
  type: SEND_RECEIPT_MAIL_TO_CUSTOMER,
  payload: { customerId, receiptId },
})

export const sendReceiptEmailToCustomerSuccess = receiptId => ({
  type: SEND_RECEIPT_MAIL_TO_CUSTOMER_SUCCESS,
  payload: receiptId,
})

export const sendReceiptEmailToCustomerFail = error => ({
  type: SEND_RECEIPT_MAIL_TO_CUSTOMER_FAIL,
  payload: error,
})

//consumption register

export const getConsumptionRegisterList = consumption => ({
  type: GET_CONSUMPTION_REGISTER_LIST,
  consumption,
})
export const getConsumptionRegisterListSuccess = client => ({
  type: GET_CONSUMPTION_REGISTER_LIST_SUCCESS,
  payload: client,
})

export const getConsumptionRegisterListFail = error => ({
  type: GET_CONSUMPTION_REGISTER_LIST_FAIL,
  payload: error,
})
// /add new Consumption
export const addNewConsumption = (history, consumption) => ({
  type: ADD_NEW_CONSUMPTION,
  payload: { history, consumption },
})

export const addNewConsumptionSuccess = consumption => ({
  type: ADD_NEW_CONSUMPTION_SUCCESS,
  payload: consumption,
})

export const addNewConsumptionFail = error => ({
  type: ADD_NEW_CONSUMPTION_FAIL,
  payload: error,
})
//edit new Consumption
export const editConsumption = (consumptionId, history, consumption) => ({
  type: EDIT_CONSUMPTION,
  payload: { consumptionId, history, consumption },
})

export const editConsumptionSuccess = consumption => ({
  type: EDIT_CONSUMPTION_SUCCESS,
  payload: consumption,
})

export const editConsumptionFail = error => ({
  type: EDIT_CONSUMPTION_FAIL,
  payload: error,
})

//delete
export const deleteConsumptionRegister = consumptionId => ({
  type: DELETE_CONSUMPTION_REGISTER,
  consumptionId,
})

export const deleteConsumptionRegisterSuccess = consumptionId => ({
  type: DELETE_CONSUMPTION_REGISTER_SUCCESS,
  payload: consumptionId,
})

export const deleteConsumptionRegisterFail = error => ({
  type: DELETE_CONSUMPTION_REGISTER_FAIL,
  payload: error,
})

//get billing data
export const getConsumptionData = consumptionId => ({
  type: GET_CONSUMPTION_DATA,
  consumptionId,
})
export const getConsumptionDataSuccess = billing => ({
  type: GET_CONSUMPTION_DATA_SUCCESS,
  payload: billing,
})

export const getConsumptionDataFail = error => ({
  type: GET_CONSUMPTION_DATA_FAIL,
  payload: error,
})

export const getBillingCreditNoteList = id => ({
  type: GET_BILLING_CREDIT_NOTE_LIST,
  id,
})
export const getBillingCreditNoteListSuccess = client => ({
  type: GET_BILLING_CREDIT_NOTE_LIST_SUCCESS,
  payload: client,
})

export const getBillingCreditNoteListFail = error => ({
  type: GET_BILLING_CREDIT_NOTE_LIST_FAIL,
  payload: error,
})

export const getCreditNumber = companyId => ({
  type: GET_CREDIT_NUMBER,
  companyId,
})

export const getCreditNumberSuccess = client => ({
  type: GET_CREDIT_NUMBER_SUCCESS,
  payload: client,
})

export const getCreditNumberFail = error => ({
  type: GET_CREDIT_NUMBER_FAIL,
  payload: error,
})

export const getComponentsForCreditNote = companyId => ({
  type: GET_COMPONENTS_FOR_CREDIT_NOTE,
  companyId,
})

export const getComponentsForCreditNoteSuccess = client => ({
  type: GET_COMPONENTS_FOR_CREDIT_NOTE_SUCCESS,
  payload: client,
})

export const getComponentsForCreditNoteFail = error => ({
  type: GET_COMPONENTS_FOR_CREDIT_NOTE_FAIL,
  payload: error,
})
export const addCreditNote = (history, data) => ({
  type: ADD_CREDIT_NOTE,
  payload: { history, data },
})

export const addCreditNoteSuccess = client => ({
  type: ADD_CREDIT_NOTE_SUCCESS,
  payload: client,
})

export const addCreditNoteFail = error => ({
  type: ADD_CREDIT_NOTE_FAIL,
  payload: error,
})
export const editCreditNote = (billingId,history, data) => ({
  type: EDIT_CREDIT_NOTE,
  payload: {billingId, history, data },
})

export const editCreditNoteSuccess = client => ({
  type: EDIT_CREDIT_NOTE_SUCCESS,
  payload: client,
})

export const editCreditNoteFail = error => ({
  type: EDIT_CREDIT_NOTE_FAIL,
  payload: error,
})
export const getCreditNoteReasons = companyId => ({
  type: GET_CREDIT_NOTE_REASONS,
  companyId,
})

export const getCreditNoteReasonsSuccess = client => ({
  type: GET_CREDIT_NOTE_REASONS_SUCCESS,
  payload: client,
})

export const getCreditNoteReasonsFail = error => ({
  type: GET_CREDIT_NOTE_REASONS_FAIL,
  payload: error,
})
export const getCreditNoteInvoiceList = companyId => ({
  type: GET_CREDIT_NOTE_INVOICE_LIST,
  companyId,
})

export const getCreditNoteInvoiceListSuccess = client => ({
  type: GET_CREDIT_NOTE_INVOICE_LIST_SUCCESS,
  payload: client,
})

export const getCreditNoteInvoiceListFail = error => ({
  type: GET_CREDIT_NOTE_INVOICE_LIST_FAIL,
  payload: error,
})

//get billing data
export const getCreditData = billingId => ({
  type: GET_CREDIT_DATA,
  billingId,
})
export const getCreditDataSuccess = billing => ({
  type: GET_CREDIT_DATA_SUCCESS,
  payload: billing,
})

export const getCreditDataFail = error => ({
  type: GET_CREDIT_DATA_FAIL,
  payload: error,
})

//delete
export const deleteCreditNote = billId => ({
  type: DELETE_CREDIT_NOTE,
  billId,
})

export const deleteCreditNoteSuccess = billId => ({
  type: DELETE_CREDIT_NOTE_SUCCESS,
  payload: billId,
})

export const deleteCreditNoteFail = error => ({
  type: DELETE_CREDIT_NOTE_FAIL,
  payload: error,
})

// get billing Invoice details

export const getCreditInvoicePreviewDetail = billId => ({
  type: GET_CREDIT_INVOICE_PREVIEW_DETAIL,
  billId,
})

export const getCreditInvoicePreviewDetailSuccess = bill => ({
  type: GET_CREDIT_INVOICE_PREVIEW_DETAIL_SUCCESS,
  payload: bill,
})

export const getCreditInvoicePreviewDetailFail = error => ({
  type: GET_CREDIT_INVOICE_PREVIEW_DETAIL_FAIL,
  payload: error,
})