import {
  GET_FACILITIES_FAIL,
  GET_FACILITIES_SUCCESS,
  GET_FACILITY_DETAIL_FAIL,
  GET_FACILITY_DETAIL_SUCCESS,
  ADD_FACILITY_SUCCESS,
  ADD_FACILITY_FAIL,
  UPDATE_FACILITY_SUCCESS,
  UPDATE_FACILITY_FAIL,
  DELETE_FACILITY_SUCCESS,
  DELETE_FACILITY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  facilities: [],
  addFacility:[],
  facilityDetail: {},
  error: {},
  loading: true
};

const facilities = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FACILITIES_SUCCESS:
      return {
        ...state,
        facilities: action.payload,
        loading: true
      };

    case GET_FACILITIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_FACILITY_SUCCESS:
      return {
        ...state,
        addFacility: [...state.addFacility, action.payload],
      };

    case ADD_FACILITY_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_FACILITY_DETAIL_SUCCESS:
      return {
        ...state,
        facilityDetail: action.payload,
      };

    case UPDATE_FACILITY_SUCCESS:
      return {
        ...state,
        addFacility: state.addFacility.map(facility =>
          facility.id === action.payload.id
            ? { facility, ...action.payload }
            : facility
        ),
      };

    case UPDATE_FACILITY_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_FACILITY_SUCCESS:
      return {
        ...state,
        facilities: state.facilities.facilities.filter(
          facility => facility.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_FACILITY_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    case GET_FACILITY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default facilities;
