import {
  GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING,
  GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_FAIL,
  GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_SUCCESS,
  GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING,
  GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_FAIL,
  GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_SUCCESS,
  GET_REPORT_FOR_CUSTOMER_OUTSTANDING,
  GET_REPORT_FOR_CUSTOMER_OUTSTANDING_FAIL,
  GET_REPORT_FOR_CUSTOMER_OUTSTANDING_SUCCESS,
  GET_LEDGER_DETAIL,
  GET_LEDGER_DETAIL_FAIL,
  GET_LEDGER_DETAIL_SUCCESS,
  GET_FINANCIAL_YEAR_FOR_OUTSTANDING,
  GET_FINANCIAL_YEAR_FOR_OUTSTANDING_SUCCESS,
  GET_FINANCIAL_YEAR_FOR_OUTSTANDING_FAIL,
} from "./actionTypes"

export const getAllProjectsForCustomerOustanding = id => ({
  type: GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING,
  id,
})

export const getAllProjectsForCustomerOustandingSuccess = allProjects => ({
  type: GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_SUCCESS,
  payload: allProjects,
})

export const getAllProjectsForCustomerOustandingFail = error => ({
  type: GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_FAIL,
  payload: error,
})

export const getLedgerDetail = value => ({
  type: GET_LEDGER_DETAIL,
  value,
})

export const getLedgerDetailSuccess = projectOccupancyReport => ({
  type: GET_LEDGER_DETAIL_SUCCESS,
  payload: projectOccupancyReport,
})

export const getLedgerDetailFail = error => ({
  type: GET_LEDGER_DETAIL_FAIL,
  payload: error,
})

export const getAllCompaniesForCustomerOustanding = () => ({
  type: GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING,
})

export const getAllCompaniesForCustomerOustandingSuccess = allCompanies => ({
  type: GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_SUCCESS,
  payload: allCompanies,
})

export const getAllCompaniesForCustomerOustandingFail = error => ({
  type: GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_FAIL,
  payload: error,
})

export const getReportForCustomerOustanding = companyData => ({
  type: GET_REPORT_FOR_CUSTOMER_OUTSTANDING,
  companyData,
})

export const getReportForCustomerOustandingSuccess = companyData => ({
  type: GET_REPORT_FOR_CUSTOMER_OUTSTANDING_SUCCESS,
  payload: companyData,
})

export const getReportForCustomerOustandingFail = error => ({
  type: GET_REPORT_FOR_CUSTOMER_OUTSTANDING_FAIL,
  payload: error,
})

export const getFinancialYearForOutstanding = id => ({
  type: GET_FINANCIAL_YEAR_FOR_OUTSTANDING,
  id,
})

export const getFinancialYearForOutstandingSuccess = financialYear => ({
  type: GET_FINANCIAL_YEAR_FOR_OUTSTANDING_SUCCESS,
  payload: financialYear,
})

export const getFinancialYearForOutstandingFail = error => ({
  type: GET_FINANCIAL_YEAR_FOR_OUTSTANDING_FAIL,
  payload: error,
})


