import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./userRoleContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

// import {
//   getUsers as onGetUsers,
//   addNewUser as onAddNewUser,
//   updateUser as onUpdateUser,
//   deleteUser as onDeleteUser,
// } from "store/contacts/actions"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { deleteRole, getRoles } from "store/actions"

const UserList = props => {
  const navigate = useNavigate()

  const handleAccountant = () => {
    navigate("/settings/user-role-management/accountant")
  }

  const handleRole = () => {
    navigate("/settings/user-role-management/new-user")
  }
  const handleEdit = () => {
    navigate("/settings/user-role-management/new-user")
  }

  //meta title
  document.title = "User Role List"

  const dispatch = useDispatch()
  const [contact, setContact] = useState()
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (contact && contact.name) || "",
      employeeCode: (contact && contact.employeeCode) || "",
      role: (contact && contact.role) || "",
      email: (contact && contact.email) || "",
      phoneNumber: (contact && contact.phoneNumber) || "",
      username: (contact && contact.username) || "",
      propertyType: (contact && contact.propertyType) || "",
      propertyName: (contact && contact.propertyName) || "",
      projectType: (contact && contact.projectType) || "",
      projectArea: (contact && contact.projectArea) || "",
      userDocument: (contact && contact.userDocument) || "",
      facilityMaster: (contact && contact.facilityMaster) || "",
      facilityFees: (contact && contact.facilityFees) || "",
      isActive: (contact && contact.isActive) || "",
      documentTitle: (contact && contact.documentTitle) || "",
      applicableFor: (contact && contact.applicableFor) || "",
      isMandatory: (contact && contact.isMandatory) || "",
      expenseTitle: (contact && contact.expenseTitle) || "",
      maintenanceIssue: (contact && contact.maintenanceIssue) || "",
      ticketTopic: (contact && contact.ticketTopic) || "",
      invoiceType: (contact && contact.invoiceType) || "",
      projectCategoryTitle: (contact && contact.projectCategoryTitle) || "",
      isPromotionalUnit: (contact && contact.isPromotionalUnit) || "",
      complianceTitle: (contact && contact.complianceTitle) || "",
      dependentCompliance: (contact && contact.dependentCompliance) || "",
      entity: (contact && contact.entity) || "",
      landlordName: (contact && contact.landlordName) || "",
      img: (contact && contact.img) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      designation: Yup.string().required("Please Enter Your Designation"),
      tags: Yup.array().required("Please Enter Tag"),
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
        .required("Please Enter Your Email"),
      projects: Yup.string().required("Please Enter Your Project"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: contact.id,
          name: values.name,
          designation: values.designation,
          tags: values.tags,
          email: values.email,
          projects: values.projects,
        }
        // update user
        dispatch(onUpdateUser(updateUser))
        setIsEdit(false)
        validation.resetForm()
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          designation: values["designation"],
          email: values["email"],
          tags: values["tags"],
          projects: values["projects"],
        }
        // save new user
        dispatch(onAddNewUser(newUser))
        validation.resetForm()
      }
      toggle()
    },
  })

  const userRoleState = state => state.roles.roles.roles
  const loadingState = state => state.roles.loading
  const data = useSelector(userRoleState) || []
  const loadingData = useSelector(loadingState)

  console.log(data)
  console.log(loadingData)

  const userRolesProperties = createSelector(
    userRoleState,
    loadingState,
    (userRoles, loading) => {
      console.log("Intermediate Facilities:", userRoles)
      return {
        userRoles: userRoles,
        loading: loading,
      }
    }
  )

  const { userRoles, loading } = useSelector(userRolesProperties)
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  useEffect(() => {
    const response = dispatch(getRoles())
    console.log(response)
    setIsEdit(false)
  }, [dispatch])

  useEffect(() => {
    setContact(userRoles)
    setIsEdit(false)
  }, [userRoles])

  useEffect(() => {
    if (!isEmpty(userRoles) && !!isEdit) {
      setContact(userRoles)
      setIsEdit(false)
    }
  }, [userRoles])

  const [userList, setUserList] = useState([])
  // const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setLoading] = useState(loading)

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Role",
        accessor: "role",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.role}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to={`/settings/user-role-management/edit-role/${cellProps.row.original.role}`}
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original.role;
                  handleUserClick(userData);
                }}
              >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" style={{ color: '#3366cc' }}/>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              {permission
                .filter(
                  item =>
                    item.page ===
                      "/user-role-management/manage-permission/:id" &&
                    item.access === 1
                )
                .map((item, index) => (
                  <Link
                    to={`/settings/user-role-management/manage-permission/${cellProps.row.original.id}`}
                    className="mt-1"
                    key={index}
                  >
                    Manage Permission
                  </Link>
                ))}
              {permission
                .filter(
                  item =>
                    item.page === "/user-role-management/edit-role/:id" &&
                    item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to={`/settings/user-role-management/edit-role/${cellProps.row.original.id}`}
                  >
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                ))}
              {permission
                .filter(
                  item =>
                    item.page === "delete-user-role" &&
                    item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      const userData = cellProps.row.original
                      onClickDelete(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                ))}
            </div>
          )
        },
      },
    ],
    []
  )

  // const toggle = () => {
  //   setModal(!modal);
  // };
  // const handleUserClick = () => {
  //   console.log('handleclick pressed')
  //   navigate('/settings/add-new-user-role');

  // };

  const handleUserClick = arg => {
    const user = arg
    const editUrl = `/users/edit-user/${user.id}`
    console.log("Navigating to:", editUrl)

    navigate(editUrl)
  }

  // const handleUserClick = arg => {
  //   const user = arg;

  //   setContact({
  //     id: user.id,
  //     name: user.name,
  //     designation: user.designation,
  //     email: user.email,
  //     tags: user.tags,
  //     projects: user.projects,
  //   });
  //   setIsEdit(true);

  //   toggle();
  // };

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = users => {
    setContact(users)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (contact && contact.id) {
      dispatch(deleteRole(contact.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    dispatch(getRoles())
    dispatch(getRoles())
  }

  const handleUserClicks = () => {
    navigate(`/settings/user-role-management/new-user`)
    // setUserList("");
    // setIsEdit(false);
    // toggle();
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Settings" breadcrumbItem="User Role Management" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}

            {/* <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit User" : "Add User"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Insert Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name &&
                              validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name &&
                          validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Designation</Label>
                        <Input
                          name="designation"
                          label="Designation"
                          placeholder="Insert Designation"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.designation || ""}
                          invalid={
                            validation.touched.designation &&
                              validation.errors.designation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.designation &&
                          validation.errors.designation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.designation}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          placeholder="Insert Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email &&
                              validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email &&
                          validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Option</Label>
                        <Input
                          type="select"
                          name="tags"
                          className="form-select"
                          multiple={true}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.tags || []}
                          invalid={
                            validation.touched.tags &&
                              validation.errors.tags
                              ? true
                              : false
                          }
                        >
                          <option>Photoshop</option>
                          <option>illustrator</option>
                          <option>Html</option>
                          <option>Php</option>
                          <option>Java</option>
                          <option>Python</option>
                          <option>UI/UX Designer</option>
                          <option>Ruby</option>
                          <option>Css</option>
                        </Input>
                        {validation.touched.tags &&
                          validation.errors.tags ? (
                          <FormFeedback type="invalid">
                            {validation.errors.tags}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Projects</Label>
                        <Input
                          name="projects"
                          label="Projects"
                          type="text"
                          placeholder="Insert Projects"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.projects || ""}
                          invalid={
                            validation.touched.projects &&
                              validation.errors.projects
                              ? true
                              : false
                          }
                        />
                        {validation.touched.projects &&
                          validation.errors.projects ? (
                          <FormFeedback type="invalid">
                            {validation.errors.projects}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                    
                  </Row>
                </Form>
              </ModalBody>
            </Modal> */}
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default withRouter(UserList)
