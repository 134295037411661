import {
  GET_REASON_MASTER_LIST,
  GET_REASON_MASTER_LIST_SUCCESS,
  GET_REASON_MASTER_LIST_FAIL,
  GET_REASON_MASTER_DETAIL,
  GET_REASON_MASTER_DETAIL_SUCCESS,
  GET_REASON_MASTER_DETAIL_FAIL,
  ADD_NEW_REASON_MASTER,
  ADD_NEW_REASON_MASTER_SUCCESS,
  ADD_NEW_REASON_MASTER_FAIL,
  UPDATE_REASON_MASTER,
  UPDATE_REASON_MASTER_SUCCESS,
  UPDATE_REASON_MASTER_FAIL,
  DELETE_REASON_MASTER,
  DELETE_REASON_MASTER_SUCCESS,
  DELETE_REASON_MASTER_FAIL,
} from "./actionTypes"

export const getReasonMasterList = () => ({
  type: GET_REASON_MASTER_LIST,
})

export const getReasonMasterListSuccess = reasons => ({
  type: GET_REASON_MASTER_LIST_SUCCESS,
  payload: reasons,
})
export const getReasonMasterListFail = error => ({
  type: GET_REASON_MASTER_LIST_FAIL,
  payload: error,
})

export const getReasonMasterDetail = reasonId => ({
  type: GET_REASON_MASTER_DETAIL,
  reasonId,
})

export const getReasonMasterDetailSuccess = reasonDetail => ({
  type: GET_REASON_MASTER_DETAIL_SUCCESS,
  payload: reasonDetail,
})

export const getReasonMasterDetailFail = error => ({
  type: GET_REASON_MASTER_DETAIL_FAIL,
  payload: error,
})

export const addNewReasonMaster = (reason, history) => ({
  type: ADD_NEW_REASON_MASTER,
  payload: { reason, history },
  status: true,
})

export const addNewReasonMasterSuccess = reason => ({
  type: ADD_NEW_REASON_MASTER_SUCCESS,
  payload: reason,
})

export const addNewReasonMasterFail = error => ({
  type: ADD_NEW_REASON_MASTER_FAIL,
  payload: error,
})

export const updateReasonMaster = (id, reason, history) => ({
  type: UPDATE_REASON_MASTER,
  payload: { id, reason, history },
})

export const updateReasonMasterSuccess = reason => ({
  type: UPDATE_REASON_MASTER_SUCCESS,
  payload: reason,
})

export const updateReasonMasterFail = error => ({
  type: UPDATE_REASON_MASTER_FAIL,
  payload: error,
})

export const deleteReasonMaster = reason => ({
  type: DELETE_REASON_MASTER,
  payload: reason,
})

export const deleteReasonMasterSuccess = reason => ({
  type: DELETE_REASON_MASTER_SUCCESS,
  payload: reason,
})

export const deleteReasonMasterFail = error => ({
  type: DELETE_REASON_MASTER_FAIL,
  payload: error,
})
