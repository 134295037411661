import React from "react"
import { Link, useNavigate } from "react-router-dom"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { updatePass } from "store/actions"
import { useDispatch } from "react-redux"

// import images
import profile from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import lightlogo from "../../assets/images/logo-light.svg"
import VLogo from "../../assets/images/VIP/V1nobg.png"
import accessToken from "helpers/jwt-token-access/accessToken"
import authHeader from "helpers/jwt-token-access/auth-token-header"
const UpdatePass = (props) => {
  const dispatch = useDispatch()
const navigate = useNavigate()
  //meta title
  document.title = "Update Password"


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your New Password"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please Enter Your Confirm Password"),
    }),

    onSubmit: async values => {
      

      try {
        const response = dispatch(updatePass(values, navigate))
        if (response.status === true) {
          navigate("/dashboard")
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Network Error:", error.message)
      }
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Update Password</h5>
                        {/* <p>Update Password </p> */}
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={VLogo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={VLogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={VLogo}
                              alt=""
                              className="rounded-circle"
                              height="50"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="p-2">
                    <div
                      className="alert alert-success text-center mb-4"
                      role="alert"
                    >
                      {" "}
                      Enter your New Password{" "}
                    </div>

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <div className="mb-3">
                          <Label className="form-label">Update Password</Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter New password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label className="form-label">
                            Re-Enter Password
                          </Label>
                          <Input
                            name="confirm_password"
                            className="form-control"
                            placeholder="Re-Enter password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password || ""}
                            invalid={
                              validation.touched.confirm_password &&
                              validation.errors.confirm_password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirm_password &&
                          validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirm_password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <div className="text-end">
                        <button className="btn btn-primary w-md " type="submit">
                          Update Password
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} VIP MALL </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdatePass
