import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SERVICE_TICKETS_LIST,
  GET_SERVICE_TICKETS_DETAIL,
  ADD_MATERIAL_IN_TICKET,
  ADD_MANPOWER_IN_TICKET,
  ADD_OTHER_COST_IN_TICKET,
  SEND_FOR_REVIEW_IN_TICKET,
  UPDATE_STATUS_IN_TICKET,
} from "./actionTypes"
import {
  getServiceRequestTicketListSuccess,
  getServiceRequestTicketListFail,
  getServiceRequestTicketDetailSuccess,
  getServiceRequestTicketDetailFail,
  addMaterialInTicketSuccess,
  addMaterialInTicketFail,
  addManpowerInTicketSuccess,
  addManpowerInTicketFail,
  addOtherCostInTicketSuccess,
  addOtherCostInTicketFail,
  sendForReviewInTicketSuccess,
  sendForReviewInTicketFail,
  updateStatusInTicketSuccess,
  updateStatusInTicketFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  addManpowerInTicket,
  addMaterialInTicket,
  addOtherCostInTicket,
  sendForReviewInTicket,
  getServiceRequestTicketDetail,
  getServiceRequestTicketList,
  updateStatusInTicket,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

function* fetchServiceRequestTicketList({ userId }) {
  try {
    const response = yield call(getServiceRequestTicketList, userId)
    yield put(getServiceRequestTicketListSuccess(response))
  } catch (error) {
    yield put(getServiceRequestTicketListFail(error))
  }
}

function* fetchServiceRequestTicketDetail({ ticketId }) {
  try {
    const response = yield call(getServiceRequestTicketDetail, ticketId)

    yield put(getServiceRequestTicketDetailSuccess(response))
  } catch (error) {
    yield put(getServiceRequestTicketDetailFail(error))
  }
}
//material
function* onAddMaterialInTicket({ payload: { ticketId, data } }) {
  try {
    const response = yield call(addMaterialInTicket, ticketId, data)
    yield put(addMaterialInTicketSuccess(response))
    if(response.status === true){
      yield call(getServiceRequestTicketDetail, ticketId)
      yield put(getServiceRequestTicketDetailSuccess(response))

    }
  } catch (error) {
    yield put(addMaterialInTicketFail(error))
  }
}
//manpower

function* onAddManpowerInTicket({ payload: { ticketId, data } }) {
  try {
    const response = yield call(addManpowerInTicket, ticketId, data)
    yield put(addManpowerInTicketSuccess(response))
    if(response.status === true){
      yield call(getServiceRequestTicketDetail, ticketId)
      yield put(getServiceRequestTicketDetailSuccess(response))

    }
  } catch (error) {
    yield put(addManpowerInTicketFail(error))
  }
}

//other cost
function* onAddOtherCostInTicket({ payload: { ticketId, data } }) {
  try {
    const response = yield call(addOtherCostInTicket, ticketId, data)
    yield put(addOtherCostInTicketSuccess(response))
    if(response.status === true){
      yield call(getServiceRequestTicketDetail, ticketId)
      yield put(getServiceRequestTicketDetailSuccess(response))

    }
  } catch (error) {
    yield put(addOtherCostInTicketFail(error))
  }
}

//proposal
function* onSendForReviewInTicket({ payload: { ticketId, data } }) {
  try {
    const response = yield call(sendForReviewInTicket, ticketId, data)
    yield put(sendForReviewInTicketSuccess(response))
    if(response.status === true){
      yield call(getServiceRequestTicketDetail, ticketId)
      yield put(getServiceRequestTicketDetailSuccess(response))

    }
  } catch (error) {
    yield put(sendForReviewInTicketFail(error))
  }
}
//update status
function* onUpdateStatusInTicket({ payload: { ticketId, data } }) {
  try {
    console.log('hello')
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    const response = yield call(updateStatusInTicket, ticketId, data);
    yield put(updateStatusInTicketSuccess(response));

    console.log(response)
    if (response.status === true) {
      loadingSwal.close();
      const response2 = yield call(getServiceRequestTicketDetail, ticketId);
      if(response2.status === true){
      yield put(getServiceRequestTicketDetailSuccess(response));  
      }
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "You Ticket status has been Updated",
      });
    } else {
      loadingSwal.close();

      Swal.fire({
        title: "Failure",
        icon: "error",
        text: "There Was An Issue.",
      });
    }
  } catch (error) {
    yield put(updateStatusInTicketFail(error));
  }
}

function* serviceTicketSaga() {
  yield takeEvery(GET_SERVICE_TICKETS_LIST, fetchServiceRequestTicketList)
  yield takeEvery(GET_SERVICE_TICKETS_DETAIL, fetchServiceRequestTicketDetail)
  yield takeEvery(ADD_MATERIAL_IN_TICKET, onAddMaterialInTicket)
  yield takeEvery(ADD_MANPOWER_IN_TICKET, onAddManpowerInTicket)
  yield takeEvery(ADD_OTHER_COST_IN_TICKET, onAddOtherCostInTicket)
  yield takeEvery(SEND_FOR_REVIEW_IN_TICKET, onSendForReviewInTicket)
  yield takeEvery(UPDATE_STATUS_IN_TICKET, onUpdateStatusInTicket)
}

export default serviceTicketSaga
