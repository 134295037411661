import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import TypeOfRequest from "./Components/count"
import Category from "./Components/activity"
import Graph from "./Components/newCustomerGraph"
import LineGraph from "./Components/bills&PaymentGraph"
import BarGraphThick from "./Components/newUnitsAssignedGraph"

import classNames from "classnames"

//import Charts

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import TicketActivity from "./Components/ticketActivity"

const Dashboard = props => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  //meta title
  document.title = "Dashboard"

  return (
    <React.Fragment>
      <div className="page-content">
        {" "}
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={12}>
              <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 ">
                  <i
                    className="mdi mdi-home font-size-20"
                    style={{ color: "grey" }}
                  ></i>
                  Dashboard
                </div>
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <TypeOfRequest></TypeOfRequest>
          </Row>
          {(user && user.user_role === 1) ||
          (user && user.user_role === 15) ||
          (user && user.user_role === 16) ? (
            <>
              <Row>
                <Category />
              </Row>
              <Row>
                <Col lg={6}>
                  <TicketActivity />
                </Col>
                <Col lg={6}>
                  <Graph />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <BarGraphThick />
                </Col>
                <Col lg={6}>
                  <LineGraph />
                </Col>
              </Row>
            </>
          ) : null}
        </Container>{" "}
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
