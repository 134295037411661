import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"

// Crypto Redux States
import {
  GET_PROPOSAL_TYPE_MASTER_LIST,
  GET_PROPOSAL_TYPE_MASTER_DETAIL,
  ADD_NEW_PROPOSAL_TYPE_MASTER,
  UPDATE_PROPOSAL_TYPE_MASTER,
  DELETE_PROPOSAL_TYPE_MASTER,
} from "./actionTypes"
import {
  getProposalMasterListSuccess,
  getProposalMasterListFail,
  getProposalMasterDetailSuccess,
  getProposalMasterDetailFail,
  addNewProposalMasterSuccess,
  addNewProposalMasterFail,
  updateProposalMasterSuccess,
  updateProposalMasterFail,
  deleteProposalMasterSuccess,
  deleteProposalMasterFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProposalMasterList,
  getProposalMasterDetail,
  addNewProposalMaster,
  updateProposalMaster,
  deleteProposalMaster,
} from "helpers/fakebackend_helper"

function* fetchProposalMasterList() {
  try {
    const response = yield call(getProposalMasterList)
    yield put(getProposalMasterListSuccess(response))
  } catch (error) {
    yield put(getProposalMasterListFail(error))
  }
}

function* fetchProposalMasterDetail({ proposalId }) {
  try {
    const response = yield call(getProposalMasterDetail, proposalId)
    yield put(getProposalMasterDetailSuccess(response))
  } catch (error) {
    yield put(getProposalMasterDetailFail(error))
  }
}

function* onAddNewProposalMaster({ payload: { proposal, history } }) {
  try {
    const response = yield call(addNewProposalMaster, proposal)
    if (response.status === true) {
      yield put(addNewProposalMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Proposal Master Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/proposal-type-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewProposalMasterFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onUpdateProposalMaster({ payload: { id, proposal, history } }) {
  try {
    const response = yield call(updateProposalMaster, id, proposal)
    if (response && response.status === true) {
      yield put(updateProposalMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Proposal Master Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/proposal-type-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateProposalMasterFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteProposalMaster({ payload: proposal }) {
  try {
    const response = yield call(deleteProposalMaster, proposal)
    if (response.status) {
      const response2 = yield put(deleteProposalMasterSuccess(response))

      const proposalMasterListResponse = yield call(getProposalMasterList)
      if (proposalMasterListResponse.status) {
        yield put(getProposalMasterListSuccess(proposalMasterListResponse))
      } else {
        console.error("Error in delete Proposal Master:", response.error)
      }
    }
  } catch (error) {
    yield put(deleteProposalMasterFail(error))
  }
}

function* proposalMasterSaga() {
  yield takeEvery(GET_PROPOSAL_TYPE_MASTER_LIST, fetchProposalMasterList)
  yield takeEvery(GET_PROPOSAL_TYPE_MASTER_DETAIL, fetchProposalMasterDetail)
  yield takeEvery(ADD_NEW_PROPOSAL_TYPE_MASTER, onAddNewProposalMaster)
  yield takeEvery(UPDATE_PROPOSAL_TYPE_MASTER, onUpdateProposalMaster)
  yield takeEvery(DELETE_PROPOSAL_TYPE_MASTER, onDeleteProposalMaster)
}

export default proposalMasterSaga
