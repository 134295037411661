import {
    GET_VENDOR_SUCCESS,
    GET_VENDOR_FAIL,
    GET_VENDORS_LIST_SUCCESS,
    GET_VENDORS_LIST_FAIL,
    ADD_NEW_VENDORS_SUCCESS,
    ADD_NEW_VENDORS_FAIL,
    UPDATE_VENDORS_SUCCESS,
    UPDATE_VENDORS_FAIL,
    DELETE_VENDORS_SUCCESS,
    DELETE_VENDORS_FAIL,
    DELETE_VENDOR_DOCUMENT_SUCCESS,
    DELETE_VENDOR_DOCUMENT_FAIL,
    DELETE_VENDOR_CONTACT_SUCCESS,
    DELETE_VENDOR_CONTACT_FAIL,
    GET_VENDOR_SERVICE_MASTER_LIST_SUCCESS,
    GET_VENDOR_SERVICE_MASTER_LIST_FAIL,
    GET_VENDOR_CONTACT_FAIL,
    GET_VENDOR_CONTACT_SUCCESS,
    GET_VENDOR_CODE_SUCCESS,
    GET_VENDOR_CODE_FAIL,
    ADD_VENDOR_PAYMENT_SUCCESS,
    ADD_VENDOR_PAYMENT_FAIL,
    GET_VENDOR_LIST_IN_PAYMENT_SUCCESS,
    GET_VENDOR_LIST_IN_PAYMENT_FAIL,
    GET_VENDOR_PAYMENT_DETAIL_FAIL,
    GET_VENDOR_PAYMENT_DETAIL_SUCCESS,
    GET_VENDOR_PAYMENT_DATA_SUCCESS,
    GET_VENDOR_PAYMENT_DATA_FAIL,
  } from "./actionTypes"
  
  const INIT_STATE = {
    vendor: [],
    vendorListInPayment: [],
    vendorServiceMasterList: [],
    addVendorPayment: [],
    vendor_code:[],
    vendorsList: {},
    vendorContact: {},
    vendorPaymentDetail: {},
    vendorPaymentData: {},
    error: {},
    loading: true,
  }
  
  const vendors = (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case GET_VENDOR_SUCCESS:
        return {
          ...state,
          vendor: action.payload,
          loading: true,
        }
  
      case GET_VENDOR_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      case GET_VENDOR_CONTACT_SUCCESS:
        return {
          ...state,
          vendorContact: action.payload,
          loading: true,
        }
  
      case GET_VENDOR_CONTACT_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case GET_VENDORS_LIST_SUCCESS:
        return {
          ...state,
          vendorsList: action.payload,
          loading: true,
        }
  
      case GET_VENDORS_LIST_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      case GET_VENDOR_SERVICE_MASTER_LIST_SUCCESS:
        return {
          ...state,
          vendorServiceMasterList: action.payload,
          loading: true,
        }
  
      case GET_VENDOR_SERVICE_MASTER_LIST_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      case ADD_NEW_VENDORS_SUCCESS:
        return {
          ...state,
          vendor: [...state.vendor, action.payload],
        }
  
      case ADD_NEW_VENDORS_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case UPDATE_VENDORS_SUCCESS:
        return {
          ...state,
          vendor: state.clients.map(vendor =>
            vendor.id.toString() === action.payload.id.toString()
              ? { vendor, ...action.payload }
              : vendor
          ),
        }
  
      case UPDATE_VENDORS_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case DELETE_VENDORS_SUCCESS:
        console.log(state)
        return {
          ...state,
          vendor: state.vendor.filter(
            vendor => vendor.id.toString() !== action.payload.toString()
          ),
        }
  
      case DELETE_VENDORS_FAIL:
        return {
          ...state,
          error: action.payload,
        }

        //DELETE DOCUMENT 
      case DELETE_VENDOR_DOCUMENT_SUCCESS:
        return {
          ...state,
          vendor: state.vendor.filter(
            vendor => vendor.id.toString() !== action.payload.toString()
          ),
        }
  
      case DELETE_VENDOR_DOCUMENT_FAIL:
        return {
          ...state,
          error: action.payload,
        }

        //DELETE CONTACT
      case DELETE_VENDOR_CONTACT_SUCCESS:
        return {
          ...state,
          vendor: state.vendor.filter(
            vendor => vendor.id.toString() !== action.payload.toString()
          ),
        }
  
      case DELETE_VENDOR_CONTACT_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
        case GET_VENDOR_CODE_SUCCESS:
          return {
            ...state,
            vendor_code: action.payload,
            loading: true,
          }
    
        case GET_VENDOR_CODE_FAIL:
          return {
            ...state,
            error: action.payload,
          }

          case ADD_VENDOR_PAYMENT_SUCCESS:
            return {
              ...state,
              addVendorPayment: [...state.addVendorPayment, action.payload],
            }
      
          case ADD_VENDOR_PAYMENT_FAIL:
            return {
              ...state,
              error: action.payload,
            }

            case GET_VENDOR_LIST_IN_PAYMENT_SUCCESS:
        return {
          ...state,
          vendorListInPayment: action.payload,
          loading: true,
        }
  
      case GET_VENDOR_LIST_IN_PAYMENT_FAIL:
        return {
          ...state,
          error: action.payload,
        }

        case GET_VENDOR_PAYMENT_DETAIL_SUCCESS:
          return {
            ...state,
            vendorPaymentDetail: action.payload,
            loading: true,
          }
    
        case GET_VENDOR_PAYMENT_DETAIL_FAIL:
          return {
            ...state,
            error: action.payload,
          }
        case GET_VENDOR_PAYMENT_DATA_SUCCESS:
          return {
            ...state,
            vendorPaymentData: action.payload,
            loading: true,
          }
    
        case GET_VENDOR_PAYMENT_DATA_FAIL:
          return {
            ...state,
            error: action.payload,
          }
      default:
        return state
    }
  }
  
  export default vendors
  