import {
  GET_PROFILE_DETAIL,
  GET_PROFILE_DETAIL_SUCCESS,
  GET_PROFILE_DETAIL_FAIL,
} from "./actionTypes"

export const getProfileDetail = userId => ({
  type: GET_PROFILE_DETAIL,
  userId,
})

export const getProfileDetailSuccess = detail => ({
  type: GET_PROFILE_DETAIL_SUCCESS,
  payload: detail,
})

export const getProfileDetailFail = error => ({
  type: GET_PROFILE_DETAIL_FAIL,
  payload: error,
})
