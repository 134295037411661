import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  UncontrolledCollapse,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"


import classnames from "classnames"
import LandlordDetailView from "./components/LandlordDetail/landlordDetailView"
import LandlordDocumentList from "./components/LandlordDocuments/landlordDocumentList"
import { getLandlordDetail } from "store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"

const LandlordView = () => {
  const dispatch = useDispatch()
  document.title = "Landlord View"
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const navigate = useNavigate()

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  

  const landlordDetailState = state => state.landlords.landlordDetail.data
  const landlordProfile = useSelector(landlordDetailState)

  const [landlordId, setProjectId] = useState()

  const landlordDetails = async () => {
    try {
      const url = new URL(window.location.href)
      const landlordId = url.pathname.split("/view-landlord-service/")[1]
      setProjectId(landlordId)
       dispatch(getLandlordDetail(landlordId))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    landlordDetails()
  }, [dispatch])

  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteBillingRegister(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    dispatch(getBillingRegisterList())
    dispatch(getBillingRegisterList())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <CardTitle className="h4" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 mb-3">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  {landlordProfile
                    ? landlordProfile.landlord_name
                    : "Project Type"}
                </div>
              </CardTitle>
            </Col>
            <Col>
              <div className="mb-3 text-end ">
                {permission
                  .filter(
                    item =>
                      item.page === "/edit-landlord-master-service/:id" && item.access === 1
                  )
                  .map((item, index) => (
                    <button
                      key={index}
                      className="btn btn-primary  me-2"
                      onClick={() => navigate(`/settings/edit-landlord-master-service/${landlordId}`)}
                    >
                      <i
                        className="mdi mdi-pencil me-1"
                        style={{ color: "#40baff" }}
                      ></i>
                      Edit Landlord
                    </button>
                  ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom ">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Landlord Details
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      {permission
                        .filter(
                          item =>
                            item.page === "view-document-landlord" &&
                            item.access === 1
                        )
                        .map((item, index) => (
                          <NavLink
                            key={index}
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleCustom("2")
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">
                            Landlord Documents
                            </span>
                          </NavLink>
                        ))}
                    </NavItem>
                   
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <LandlordDetailView landlordProfile={landlordProfile} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                        <LandlordDocumentList />
                        </Col>
                      </Row>
                    </TabPane>
                    
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LandlordView
