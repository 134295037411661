import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Label,
  Input,
  navigate,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2"
import { updateClient } from "helpers/fakebackend_helper"
import { getCities, getClients, getCountries, getStates } from "store/actions"

const EditClient = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const dispatch = useDispatch()

  const clientState = state => state.clients.clients.client
  const loadingState = state => state.clients.loading
  const clientData = useSelector(clientState)
  const loadingData = useSelector(loadingState)

  const clientProperties = createSelector(
    clientState,
    loadingState,
    (client, loading) => {
      return {
        client: client,
        loading: loading,
      }
    }
  )
  const { client, loading } = useSelector(clientProperties)

  const [clientId, setClientId] = useState()

  const [selectedOption, setSelectedOption] = useState(client?.is_active)

  const [selectedFile, setSelectedFile] = useState()
  const [imageURL, setImageURL] = useState(null)

  useEffect(() => {
    setSelectedFile(client?.image)
  }, [client?.image])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      image: "",
      customer_type: (client && client.customer_type) || "",
      customer_name: (client && client.customer_name) || "",
      customer_code: (client && client.customer_code) || "",
      email_address: (client && client.email_address) || "",
      mobile_no: (client && client.mobile_no) || "",
      address: (client && client.address) || "",
      pincode: (client && client.pincode) || "",
      city: (client && client.city) || "",
      state: (client && client.state) || "",
      country: (client && client.country) || "",
      pan_no: (client && client.pan_no) || "",
      aadhar_no: (client && client.aadhar_no) || "",
      tan_no: (client && client.tan_no) || "",
      gst_no: (client && client.gst_no) || "",
      login_username: (client && client.login_username) || "",
      is_active: (client && client.is_active) || 0,
    },
    validationSchema: Yup.object().shape({
      image: Yup.string(),
      customer_type: Yup.string().required("Please Select Customer Type"),
      customer_name: Yup.string().required("Please Enter Your Customer's Name"),
      customer_code: Yup.string().required("Please Enter Customer code"),
      email_address: Yup.string()
        .email("Invalid Email Format")
        .required("Please Enter Email Address"),
      mobile_no: Yup.string()
        .matches(
          /^[0-9]{10,12}$/,
          "Mobile Number must be between 10 to 12 digits"
        )
        .required("Please Enter Mobile Number "),
      address: Yup.string().required("Please Enter Address"),
      pincode: Yup.string()
        .matches(/^\d{6}$/, "Pincode must be a 6-digit number")
        .required("Please Enter Pincode"),
      city: Yup.string().required("Please Enter City"),
      state: Yup.string().required("Please Enter State"),
      country: Yup.string().required("Please Enter Country"),
      pan_no: Yup.string()
        .matches(
          /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
          "PAN Number must be in the format: ABCDE1234F"
        )
        .required("Please Enter PAN Number"),
      aadhar_no: Yup.string().matches(
        /^\d{12}$/,
        "Aadhar Number must be a 12-digit number"
      ),
      tan_no: Yup.string().matches(
        /^[A-Z]{4}\d{5}[A-Z]$/,
        "TAN Number must be in the format: XXXX12345X"
      ),
      gst_no: Yup.string().matches(
        /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[A-Z\d]{1}[A-Z\d]{1}$/,
        "GST Number must be in the format: 12ABCDE1234F1Z1"
      ),
      login_username: Yup.string().required("Please enter Login Username"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })

        const url = new URL(window.location.href)
        const clientId = url.pathname.split("/edit-client/")[1]
        setClientId(clientId)

        const response = await updateClient(clientId, values)
        if (response && response.status === true) {
          loadingSwal.close()

          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Client has been Updated.",
          }).then(result => {
            if (result.isConfirmed) {
              navigate("/clients")
            }
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "failure",
            icon: "error",
            text: "Your Client has not been saved.",
          })
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const navigate = useNavigate()
  const toggleToast = () => {
    setToast(!toast)
  }
  const toggleToast2 = () => {
    setToast2(!toast2)
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const handleChange = e => {
    setSelectedOption(e.target.value)
    validation.setFieldValue("is_active", e.target.value)
  }
  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState(validation.values.state)

  useEffect(() => {
    const fetchData = async () => {
      const url = new URL(window.location.href)
      const clientId = url.pathname.split("/edit-client/")[1]
      setClientId(clientId)

      dispatch(getClients(clientId))
      // Fetch countries
      await dispatch(getCountries())

      // If country is selected (edit mode), fetch states and cities
      if (validation.values.country) {
        await dispatch(getStates(validation.values.country))
        await dispatch(getCities(validation.values.state))
      }

      // Set initial values
      setCountryName(validation.values.country)
      setStateId(validation.values.state)
    }

    fetchData()
  }, [dispatch, validation.values.country, validation.values.state])

  console.log('validation.values.image',validation.values.image)
  const [imageTouched, setImageTouched] = useState(false)

  const handleFileChange = event => {
    const file = event.target.files[0]
    setSelectedFile(file)
    if (typeof file === "object") {
      setImageURL(URL.createObjectURL(file))
    } else {
      setImageURL(file)
    }
  }
  useEffect(() => {
    setSelectedFile(client?.image)
    setImageURL(client?.image)
  }, [client?.image])

  useEffect(() => {
    console.log('stage1')
    if (selectedFile && typeof selectedFile === "object") {
      if (validation.touched.image && !imageTouched) {
        validation.setFieldValue("image", selectedFile)
        setImageTouched(true)
      }
    }
  }, [
    selectedFile,
    validation.touched.image,
    imageTouched,
    validation.setFieldValue,
  ])

  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {" "}
              Edit Client
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Clients / Add New Client" />
        </Col>
      </Row>
      <div className="mt-3"></div>

      <Form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Card>
          <CardBody>
            <Row>
              <Col>
                <label
                  className="rounded-2"
                  style={{
                    width: "150px",
                    display: "flex",
                    // padding: "30px",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "10px",
                    border: "1px solid rgba(0, 0, 0, 0.10)",
                    flexDirection: "column",
                  }}
                >
                  <>
                    {imageURL ? (
                      <img
                        className="object-fit-fill rounded-2"
                        src={imageURL}
                        alt=""
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          maxHeight: "150px",
                          minHeight: "150px",
                        }}
                      />
                    ) : (
                      <i
                        className="mdi mdi-image-outline"
                        style={{
                          fontSize: "60px",
                          padding: "30px",
                        }}
                      ></i>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </>
                </label>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="customer_type">Customer Type*</Label>
                  <select
                    name="customer_type"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.customer_type}
                    className={`form-select ${
                      validation.touched.customer_type &&
                      validation.errors.customer_type
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.customer_type && (
                      <option>Select Customer Type</option>
                    )}
                    <option value="1">Individual</option>
                    <option value="2">Brand</option>

                    {/* {projects.map((project) => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))} */}
                  </select>
                  {validation.touched.customer_type &&
                  validation.errors.customer_type ? (
                    <FormFeedback type="invalid">
                      {validation.errors.customer_type}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="customer_name">Customer Name*</Label>
                  <Input
                    type="text"
                    name="customer_name"
                    className="form-control"
                    id="customer_name"
                    placeholder="Enter Customer's Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.customer_name || ""}
                    invalid={
                      validation.touched.customer_name &&
                      validation.errors.customer_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.customer_name &&
                  validation.errors.customer_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.customer_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="customer_code">Customer Code*</Label>
                  <Input
                    type="text"
                    name="customer_code"
                    className="form-control"
                    id="customer_code"
                    disabled
                    placeholder="Enter Customer's Code"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.customer_code || ""}
                    invalid={
                      validation.touched.customer_code &&
                      validation.errors.customer_code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.customer_code &&
                  validation.errors.customer_code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.customer_code}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="email_address">Email address*</Label>
                  <Input
                    type="text"
                    name="email_address"
                    className="form-control"
                    id="email_address"
                    placeholder="Enter Customer's Email Address"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email_address || ""}
                    invalid={
                      validation.touched.email_address &&
                      validation.errors.email_address
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email_address &&
                  validation.errors.email_address ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email_address}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="mobile_no">Mobile No.*</Label>
                  <Input
                    type="text"
                    name="mobile_no"
                    className="form-control"
                    id="mobile_no"
                    placeholder="Enter Customer's Mobile Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.mobile_no || ""}
                    invalid={
                      validation.touched.mobile_no &&
                      validation.errors.mobile_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.mobile_no &&
                  validation.errors.mobile_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.mobile_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="address">Address*</Label>
                  <Input
                    type="text"
                    name="address"
                    className="form-control"
                    id="address"
                    placeholder="Enter Customer's Address"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.address || ""}
                    invalid={
                      validation.touched.address && validation.errors.address
                        ? true
                        : false
                    }
                  />
                  {validation.touched.address && validation.errors.address ? (
                    <FormFeedback type="invalid">
                      {validation.errors.address}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="pincode">Pincode*</Label>
                  <Input
                    type="text"
                    name="pincode"
                    className="form-control"
                    id="pincode"
                    placeholder="Enter Pincode"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.pincode || ""}
                    invalid={
                      validation.touched.pincode && validation.errors.pincode
                        ? true
                        : false
                    }
                  />
                  {validation.touched.pincode && validation.errors.pincode ? (
                    <FormFeedback type="invalid">
                      {validation.errors.pincode}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="country">Country*</Label>
                  <select
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    // defaultValue="IN"
                    value={validation.values.country}
                    name="country"
                    className={`form-select ${
                      validation.touched.country && validation.errors.country
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.country && (
                      <option>Enter Your Country</option>
                    )}

                    {(countries || []).map(item => (
                      <option key={item.id} value={item.id}>
                        {item.country_name}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-state-Input">State*</Label>
                  <select
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="state"
                    value={validation.values.state}
                    className={`form-select ${
                      validation.touched.state && validation.errors.state
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.state && (
                      <option>Enter Your States</option>
                    )}

                    {(states || []).map(item => (
                      <option key={item.id} value={item.id}>
                        {item.state_name}
                      </option>
                    ))}
                  </select>
                  {validation.touched.state && validation.errors.state ? (
                    <FormFeedback type="invalid">
                      {validation.errors.state}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-city-Input">City*</Label>
                  <select
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="city"
                    value={validation.values.city}
                    className={`form-select ${
                      validation.touched.city && validation.errors.city
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.city && (
                      <option>Enter Your City</option>
                    )}

                    {(cities || []).map(item => (
                      <option key={item.id} value={item.id}>
                        {item.city_name}
                      </option>
                    ))}
                  </select>
                  {validation.touched.city && validation.errors.city ? (
                    <FormFeedback type="invalid">
                      {validation.errors.city}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Documents Identification</CardTitle>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="pan_no">PAN no*</Label>
                  <Input
                    type="text"
                    name="pan_no"
                    className="form-control"
                    id="pan_no"
                    placeholder="Enter Pan Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.pan_no || ""}
                    invalid={
                      validation.touched.pan_no && validation.errors.pan_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.pan_no && validation.errors.pan_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.pan_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="aadhar_no">Aadhar no</Label>
                  <Input
                    type="text"
                    name="aadhar_no"
                    className="form-control"
                    id="aadhar_no"
                    placeholder="Enter Aadhar Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.aadhar_no || ""}
                    invalid={
                      validation.touched.aadhar_no &&
                      validation.errors.aadhar_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.aadhar_no &&
                  validation.errors.aadhar_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.aadhar_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="tan_no">TAN no</Label>
                  <Input
                    type="text"
                    name="tan_no"
                    className="form-control"
                    id="tan_no"
                    placeholder="Enter TAN Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tan_no || ""}
                    invalid={
                      validation.touched.tan_no && validation.errors.tan_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.tan_no && validation.errors.tan_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.tan_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="gst_no">GST no</Label>
                  <Input
                    type="text"
                    name="gst_no"
                    className="form-control"
                    id="gst_no"
                    placeholder="Enter GST Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.gst_no || ""}
                    invalid={
                      validation.touched.gst_no && validation.errors.gst_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.gst_no && validation.errors.gst_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.gst_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Sign in details</CardTitle>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="login_username">Login Username*</Label>
                  <Input
                    type="text"
                    name="login_username"
                    className="form-control"
                    id="login_username"
                    placeholder="Enter Login Username"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.login_username || ""}
                    invalid={
                      validation.touched.login_username &&
                      validation.errors.login_username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.login_username &&
                  validation.errors.login_username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.login_username}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <Label htmlFor="is_active">Is Active*</Label>
                <Col lg="6">
                  <div className="form-check">
                    <Input
                      type="radio"
                      id="isActiveYes"
                      name="is_active"
                      value={1}
                      onChange={validation.handleChange}
                      // onClick={e => {
                      //   handleChange(e)
                      // }}

                      defaultChecked={validation.values.is_active === 1}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="isActiveYes"
                      style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      <h6>Yes</h6>
                    </Label>
                  </div>
                  <div className="form-check">
                    <Input
                      type="radio"
                      id="isActiveNo"
                      name="is_active"
                      value={0}
                      onChange={validation.handleChange}
                      defaultChecked={validation.values.is_active === 0}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="isActiveNo"
                      style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      <h6>No</h6>
                    </Label>
                  </div>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-md">
                    Edit{" "}
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </div>
  )
}

export default EditClient
