import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./VendorDebitNoteListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"

import { deleteDebitNote, getDebitNoteList } from "store/actions"
import formatNumberWithCommas from "components/Common/CommaSeparator"

const VendorDebitNoteList = () => {
  const navigate = useNavigate()
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  document.title = "Debit Note List "
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dispatch = useDispatch()
  const DebitNoteListState = state => state.debitNote.debitNoteList.data
  const DebitNoteList = useSelector(DebitNoteListState) || []

  useEffect(() => {
    const value = { user_id: user.user_id }
    dispatch(getDebitNoteList(value))
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                  {cellProps.row.index + 1}
              </h5>
            </>
          )
        },
      },

      {
        Header: "Debit Note No.",
        accessor: "debit_note_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.debit_note_no}
              </h5>
            </>
          )
        },
      },
      {
        Header: " Debit Note Date",
        accessor: "debit_note_date_formated",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.debit_note_date_formated}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Against Invoice No.",
        accessor: "invoice_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.invoice_no}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Against Invoice Date",
        accessor: "invoice_date_formated",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.invoice_date_formated}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                  {cellProps.row.original.vendor_name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project Name",
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                  {cellProps.row.original.project_name}
              </h5>
            </>
          )
        },
      },
      // {
      //   Header: "Company Name",
      //   accessor: "company_name",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return (
      //       <>
      //         <h5 className="font-size-14 mb-1">
      //           <Link className="text-dark" to="#">
      //             {cellProps.row.original.company_name}
      //           </Link>
      //         </h5>
      //       </>
      //     )
      //   },
      // },
      {
        Header: "Debit Amount",
        accessor: "all_total",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                  {formatNumberWithCommas(
                    cellProps.row.original.all_total
                  )}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {permission
                .filter(
                  item =>
                    item.page === "/edit-debit-note/:id" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to={`/vendor/edit-debit-note/${cellProps.row.original.debit_note_id}`}
                    className="text-success"
                   
                  >
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                ))}
              {permission
                .filter(
                  item => item.page === "delete-debit-Note" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      const userData = cellProps.row.original.debit_note_id
                      onClickDelete(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                ))}
              
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteDebitNote(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    dispatch(getDebitNoteList())
  }

  const handleUserClicks = () => {
    setUserList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Bills & Payment"
            breadcrumbItem="Debit Note List"
          />

          <Row>
            {/* {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : ( */}
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    isPagination={true}
                    columns={columns}
                    data={DebitNoteList || []}
                    isGlobalFilter={true}
                    isShowingPageLength={true}
                    isAddUserList={true}
                    iscustomPageSizeOptions={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    tableClass="table align-middle table-nowrap table-hover"
                    theadClass="table-light"
                    paginationDiv="col-sm-12 col-md-7"
                    pagination="pagination pagination-rounded justify-content-end mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
            {/* )} */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorDebitNoteList
