import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import TableContainer from "./vendorTicketContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import {  getVendorInvoices, getVendorTicketData } from "store/actions"

const VendorTicketData = () => {
  const navigate = useNavigate()
  document.title = "Vendor Summary Report"
  const dispatch = useDispatch()
  const vendorSummaryList = state => state.vendorSummaryReport.vendorTicketData.data
  const vendorInvoices = useSelector(vendorSummaryList) || []

  
  const loadingState = state => state.vendorSummaryReport.loading
  const loading = useSelector(loadingState) || []
  const [isLoading, setLoading] = useState(loading)

  const {id} = useParams()
  
  useEffect(() => {
    
    dispatch(getVendorTicketData(id))
  }, [id])

  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )


  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                  {cellProps.row.index + 1}
              </h5>
            </>
          )
        },
      },

      
      {
        Header: "Ticket No",
        accessor: "ticket_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                  {cellProps.row.original.ticket_no}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Details",
        accessor: "Details",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                  {cellProps.row.original.Details}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                  {cellProps.row.original.status}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">{cellProps.row.original.priority}</Link>
              </h5>
            </>
          )
        },
      },
    
     
    ],
    []
  )



  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Vendor Summary Report"
            breadcrumbItem="Vendor Ticket"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={vendorInvoices || []}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
         
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorTicketData
