import React from 'react'
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link, useNavigate } from 'react-router-dom'; 
import UserList from './userRoleList';
// import ContactsList from 'pages/Contacts/ContactList/contacts-list';

const userRoleManagement = () => {
  return (
    <>    
      <UserList/>     
    </>
);
}

export default userRoleManagement;