import {
  GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_FAIL,
  GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_SUCCESS,
  GET_FACING_MAINTENANCE_ISSUE_LIST_FAIL,
  GET_FACING_MAINTENANCE_ISSUE_LIST_SUCCESS,
  GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_FAIL,
  GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_SUCCESS,
  GET_RECENT_SERVICE_REQUEST_LIST_FAIL,
  GET_RECENT_SERVICE_REQUEST_LIST_SUCCESS,
  GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_FAIL,
  GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_SUCCESS,
  GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_FAIL,
  GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_SUCCESS,
  GET_SUPPORT_TICKET_LIST_FAIL,
  GET_SUPPORT_TICKET_LIST_SUCCESS,
  GET_TYPES_OF_REQUESTS_GRAPH_FAIL,
  GET_TYPES_OF_REQUESTS_GRAPH_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  count: [],
  additionalProjectUnitList: [],
  maintenanceIssueList: [],
  supportTicketList: [],
  recentRequests: [],
  typesOfRequestsGraph: [],
  requestsAssignmentGraph: [],
  requestsPieDigram:[],
  error: {},
  loading: true,
}

const serviceRequestDashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_SUCCESS:
      return {
        ...state,
        count: action.payload,
        loading: false,
      }

    case GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_SUCCESS:
      return {
        ...state,
        additionalProjectUnitList: action.payload,
        loading: false,
      }

    case GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_FACING_MAINTENANCE_ISSUE_LIST_SUCCESS:
      return {
        ...state,
        maintenanceIssueList: action.payload,
        loading: false,
      }

    case GET_FACING_MAINTENANCE_ISSUE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SUPPORT_TICKET_LIST_SUCCESS:
      return {
        ...state,
        supportTicketList: action.payload,
        loading: false,
      }

    case GET_SUPPORT_TICKET_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_RECENT_SERVICE_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        recentRequests: action.payload,
        loading: false,
      }

    case GET_RECENT_SERVICE_REQUEST_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_TYPES_OF_REQUESTS_GRAPH_SUCCESS:
        return {
          ...state,
          typesOfRequestsGraph: action.payload,
          loading: false,
          // loading: true
        }
  
      case GET_TYPES_OF_REQUESTS_GRAPH_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      case GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_SUCCESS:
        return {
          ...state,
          requestsAssignmentGraph: action.payload,
          loading: false,
          // loading: true
        }
  
      case GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      case GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_SUCCESS:
        return {
          ...state,
          requestsPieDigram: action.payload,
          loading: false,
          // loading: true
        }
  
      case GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    default:
      return state
  }
}

export default serviceRequestDashboard
