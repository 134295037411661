import { call, put, takeEvery } from "redux-saga/effects"

import {
  getProjectOccupancyDetailSuccess,
  getProjectOccupancyDetailFail,
  getAllProjectsSuccess,
  getAllProjectsFail,
  getAllCompaniesSuccess,
  getAllCompaniesFail,
  getGstR1ReportSuccess,
  getGstR1ReportFail,
} from "./actions"
import {
  getAllCompanies,
  getAllProjects,
  getGstR1Report,
  getProjectOccupancyDetails,
} from "helpers/fakebackend_helper"
import {
  GET_ALL_COMPANIES,
  GET_ALL_PROJECTS,
  GET_GST_R1_REPORT,
  GET_PROJECT_OCCUPANCY_DETAIL,
} from "./actionTypes"

function* fetchAllProjects({ id }) {
  try {
    const response = yield call(getAllProjects, id)
    yield put(getAllProjectsSuccess(response))
  } catch (error) {
    yield put(getAllProjectsFail(error))
  }
}

function* fetchProjectOccupancyDetail({ projectId }) {
  try {
    const response = yield call(getProjectOccupancyDetails, projectId)
    yield put(getProjectOccupancyDetailSuccess(response))
  } catch (error) {
    yield put(getProjectOccupancyDetailFail(error))
  }
}

function* fetchAllCompanies() {
  try {
    const response = yield call(getAllCompanies)
    yield put(getAllCompaniesSuccess(response))
  } catch (error) {
    yield put(getAllCompaniesFail(error))
  }
}

function* fetchGstR1Report({ companyData }) {
  try {
    const response = yield call(getGstR1Report, companyData)
    yield put(getGstR1ReportSuccess(response))
  } catch (error) {
    yield put(getGstR1ReportFail(error))
  }
}

function* maintainencesSaga() {
  yield takeEvery(GET_ALL_PROJECTS, fetchAllProjects)
  yield takeEvery(GET_PROJECT_OCCUPANCY_DETAIL, fetchProjectOccupancyDetail)
  yield takeEvery(GET_ALL_COMPANIES, fetchAllCompanies)
  yield takeEvery(GET_GST_R1_REPORT, fetchGstR1Report)
}

export default maintainencesSaga
