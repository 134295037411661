import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ALL_ENGAGEMENT_LIST,
  GET_APPROVAL_PENDING_LIST,
  GET_BILLING_SETUP_PENDING_LIST,
} from "./actionTypes"
import {
  getAllEngagementListSuccess,
  getAllEngagementListFail,
  getApprovalPendingListSuccess,
  getApprovalPendingListFail,
  getBillingSetupPendingListSuccess,
  getBillingSetupPendingListFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAllEngagementList,
  getApprovalPendingList,
  getBillingSetupPendingList,
} from "helpers/fakebackend_helper"

function* fetchAllEngagementList() {
  try {
    const response = yield call(getAllEngagementList)
    yield put(getAllEngagementListSuccess(response))
  } catch (error) {
    yield put(getAllEngagementListFail(error))
  }
}

function* fetchApprovalPendingList() {
  try {
    const response = yield call(getApprovalPendingList)
    yield put(getApprovalPendingListSuccess(response))
  } catch (error) {
    yield put(getApprovalPendingListFail(error))
  }
}

function* fetchBillingSetupPendingList() {
  try {
    const response = yield call(getBillingSetupPendingList)
    yield put(getBillingSetupPendingListSuccess(response))
  } catch (error) {
    yield put(getBillingSetupPendingListFail(error))
  }
}

function* serviceEngagementSaga() {
  yield takeEvery(GET_ALL_ENGAGEMENT_LIST, fetchAllEngagementList)
  yield takeEvery(GET_APPROVAL_PENDING_LIST, fetchApprovalPendingList)
  yield takeEvery(GET_BILLING_SETUP_PENDING_LIST, fetchBillingSetupPendingList)
}

export default serviceEngagementSaga
