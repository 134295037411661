import {

  GET_COUNT_FOR_TICKETS_DASHBOARD_FAIL,
  GET_COUNT_FOR_TICKETS_DASHBOARD_SUCCESS,
  GET_CRITICAL_PRIORITY_TICKET_LIST_FAIL,
  GET_CRITICAL_PRIORITY_TICKET_LIST_SUCCESS,
  GET_HIGH_PRIORITY_TICKET_LIST_FAIL,
  GET_HIGH_PRIORITY_TICKET_LIST_SUCCESS,
  GET_LOW_PRIORITY_TICKET_LIST_FAIL,
  GET_LOW_PRIORITY_TICKET_LIST_SUCCESS,
  GET_MEDIUM_PRIORITY_TICKET_LIST_FAIL,
  GET_MEDIUM_PRIORITY_TICKET_LIST_SUCCESS,
  GET_OPEN_CLOSE_TICKET_GRAPH_FAIL,
  GET_OPEN_CLOSE_TICKET_GRAPH_SUCCESS,
  GET_RECENT_TICKETS_LIST_FAIL,
  GET_RECENT_TICKETS_LIST_SUCCESS,
  GET_TICKETS_STATUS_PIE_DIGRAM_FAIL,
  GET_TICKETS_STATUS_PIE_DIGRAM_SUCCESS,
  GET_USER_VENDOR_WITH_MOST_TICKET_FAIL,
  GET_USER_VENDOR_WITH_MOST_TICKET_SUCCESS,

} from "./actionTypes"

const INIT_STATE = {
  count: [],
  criticalPriorityList: [],
  highPriorityList: [],
  mediumPriorityList: [],
  lowPriorityList: [],
  recentTickets: [],
  mostTickets: [],
  openCloseTicketGraph: [],
  ticketStatusPieDigram: [],
  error: {},
  loading: true,
}

const ticketsDashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNT_FOR_TICKETS_DASHBOARD_SUCCESS:
      return {
        ...state,
        count: action.payload,
        loading: false,
      }

    case GET_COUNT_FOR_TICKETS_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CRITICAL_PRIORITY_TICKET_LIST_SUCCESS:
      return {
        ...state,
        criticalPriorityList: action.payload,
        loading: false,
      }

    case GET_CRITICAL_PRIORITY_TICKET_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_HIGH_PRIORITY_TICKET_LIST_SUCCESS:
      return {
        ...state,
        highPriorityList: action.payload,
        loading: false,
      }

    case GET_HIGH_PRIORITY_TICKET_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_MEDIUM_PRIORITY_TICKET_LIST_SUCCESS:
      return {
        ...state,
        mediumPriorityList: action.payload,
        loading: false,
      }

    case GET_MEDIUM_PRIORITY_TICKET_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_LOW_PRIORITY_TICKET_LIST_SUCCESS:
      return {
        ...state,
        lowPriorityList: action.payload,
        loading: false,
      }

    case GET_LOW_PRIORITY_TICKET_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_RECENT_TICKETS_LIST_SUCCESS:
      return {
        ...state,
        recentTickets: action.payload,
        loading: false,
      }

    case GET_RECENT_TICKETS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_USER_VENDOR_WITH_MOST_TICKET_SUCCESS:
      return {
        ...state,
        mostTickets: action.payload,
        loading: false,
      }

    case GET_USER_VENDOR_WITH_MOST_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_OPEN_CLOSE_TICKET_GRAPH_SUCCESS:
        return {
          ...state,
          openCloseTicketGraph: action.payload,
          loading: false,
          // loading: true
        }
  
      case GET_OPEN_CLOSE_TICKET_GRAPH_FAIL:
        return {
          ...state,
          error: action.payload,
        }
   
      case GET_TICKETS_STATUS_PIE_DIGRAM_SUCCESS:
        return {
          ...state,
          ticketStatusPieDigram
          : action.payload,
          loading: false,
          // loading: true
        }
  
      case GET_TICKETS_STATUS_PIE_DIGRAM_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    default:
      return state
  }
}

export default ticketsDashboard
