/* GET_SERVICE_TICKETS_LIST */
export const GET_SERVICE_TICKETS_LIST = "GET_SERVICE_TICKETS_LIST"
export const GET_SERVICE_TICKETS_LIST_SUCCESS = "GET_SERVICE_TICKETS_LIST_SUCCESS"
export const GET_SERVICE_TICKETS_LIST_FAIL = "GET_SERVICE_TICKETS_LIST_FAIL"

/* GET_SERVICE_TICKETS_DETAIL */
export const GET_SERVICE_TICKETS_DETAIL = "GET_SERVICE_TICKETS_DETAIL"
export const GET_SERVICE_TICKETS_DETAIL_SUCCESS = "GET_SERVICE_TICKETS_DETAIL_SUCCESS"
export const GET_SERVICE_TICKETS_DETAIL_FAIL = "GET_SERVICE_TICKETS_DETAIL_FAIL"

/* ADD_MATERIAL_IN_TICKET */
export const ADD_MATERIAL_IN_TICKET = "ADD_MATERIAL_IN_TICKET"
export const ADD_MATERIAL_IN_TICKET_SUCCESS = "ADD_MATERIAL_IN_TICKET_SUCCESS"
export const ADD_MATERIAL_IN_TICKET_FAIL = "ADD_MATERIAL_IN_TICKET_FAIL"
/* ADD_MANPOWER_IN_TICKET */
export const ADD_MANPOWER_IN_TICKET = "ADD_MANPOWER_IN_TICKET"
export const ADD_MANPOWER_IN_TICKET_SUCCESS = "ADD_MANPOWER_IN_TICKET_SUCCESS"
export const ADD_MANPOWER_IN_TICKET_FAIL = "ADD_MANPOWER_IN_TICKET_FAIL"
/* ADD_OTHER_COST_IN_TICKET */
export const ADD_OTHER_COST_IN_TICKET = "ADD_OTHER_COST_IN_TICKET"
export const ADD_OTHER_COST_IN_TICKET_SUCCESS = "ADD_OTHER_COST_IN_TICKET_SUCCESS"
export const ADD_OTHER_COST_IN_TICKET_FAIL = "ADD_OTHER_COST_IN_TICKET_FAIL"
/* SEND_FOR_REVIEW_IN_TICKET */
export const SEND_FOR_REVIEW_IN_TICKET = "SEND_FOR_REVIEW_IN_TICKET"
export const SEND_FOR_REVIEW_IN_TICKET_SUCCESS = "SEND_FOR_REVIEW_IN_TICKET_SUCCESS"
export const SEND_FOR_REVIEW_IN_TICKET_FAIL = "SEND_FOR_REVIEW_IN_TICKET_FAIL"
/* UPDATE_STATUS_IN_TICKET */
export const UPDATE_STATUS_IN_TICKET = "UPDATE_STATUS_IN_TICKET"
export const UPDATE_STATUS_IN_TICKET_SUCCESS = "UPDATE_STATUS_IN_TICKET_SUCCESS"
export const UPDATE_STATUS_IN_TICKET_FAIL = "UPDATE_STATUS_IN_TICKET_FAIL"
