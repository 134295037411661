import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  getActiveUnits,
  getFacilitiesAvailable,
  getProjectName,
  getProjectUnit,
  getProjectUnitDetail,
  getUnits,
} from "store/actions"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import {
  addNewProjectUnit,
  updateProject,
  updateProjectUnit,
} from "helpers/fakebackend_helper"

const addnewprojectunit = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [projectId, setProjectId] = useState()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const projectUnitState = state => state.projects.projects.project_name
  const projects = useSelector(projectUnitState) || []

  const projectUnitDetailData = state => state.projects.projectUnitDetail.data
  const projectData = useSelector(projectUnitDetailData) || []
  const projectUnitCategoryState = state =>
    state.units.activeUnits.Unit_category
  const projectUnitCategory = useSelector(projectUnitCategoryState) || []
  const facilitiesAvailableState = state =>
    state.projects.facilities.compliances
  const facilitiesAvailable = useSelector(facilitiesAvailableState) || []

  const editData = () => {
    const url = new URL(window.location.href)
    const projectId = url.pathname.split("/edit-project-unit/")[1]
    setProjectId(projectId)
    setIsEditMode(true)
    dispatch(getProjectUnitDetail(projectId))
  }
  const ProjectUnitData = () => {
    dispatch(getActiveUnits())
  }

  useEffect(() => {
    dispatch(getFacilitiesAvailable())
  }, [dispatch])

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      editData()
    }

    const response = dispatch(getProjectName(user.user_id))
    ProjectUnitData()
  }, [])

  const [selectedOption1, setSelectedOption1] = useState(
    projectData?.deposit_type || "Fixed"
  )
  const [selectedOption2, setSelectedOption2] = useState(
    projectData?.late_fees_type || "Fixed"
  )
  const [selectedOption3, setSelectedOption3] = useState(
    projectData?.furnished || "Unfurnished"
  )
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: isEditMode ? true : false,

    initialValues: isEditMode
      ? {
          project_id: (projectData && projectData.project_id) || "",
          unit_code: (projectData && projectData.unit_code) || "",
          unit_area_sqft: (projectData && projectData.unit_area_sqft) || "",
          carpet_area_sqft: (projectData && projectData.carpet_area_sqft) || "",
          built_up_area_sqft:
            (projectData && projectData.built_up_area_sqft) || "",
          super_built_up_area_sqft:
            (projectData && projectData.super_built_up_area_sqft) || "",
          unit_category_id: (projectData && projectData.unit_category_id) || "",
          rent_type: (projectData && projectData.rent_type) || "",
          rent_amount: (projectData && projectData.rent_amount) || "",
          deposit_type: (projectData && projectData.deposit_type) || "Fixed",
          deposit_amount: (projectData && projectData.deposit_amount) || "",
          late_fees_type:
            (projectData && projectData.late_fees_type) || "Fixed",
          late_fees_amount: (projectData && projectData.late_fees_amount) || "",
          furnished: (projectData && projectData.furnished) || "Unfurnished",
          type_of_project_unit:
            (projectData && projectData.type_of_project_unit) || "",
          facilities_available:
            projectData && projectData.facilities_available
              ? JSON.parse(projectData.facilities_available)
              : [],
          minimum_sale_amount:
            (projectData && projectData.minimum_sale_amount) || "",
          maximum_sale_amount:
            (projectData && projectData.maximum_sale_amount) || "",
          floor_no: (projectData && projectData.floor_no) || "",
          maintenance_charges:
            (projectData && projectData.maintenance_charges) || "",
          security_dept_of_maintenance:
            (projectData && projectData.security_dept_of_maintenance) || "",
          security_dept_of_rent:
            (projectData && projectData.security_dept_of_rent) || "",
          security_dept_of_electricity:
            (projectData && projectData.security_dept_of_electricity) || "",
        }
      : {
          project_id: "",
          unit_code: "",
          unit_area_sqft: "",
          carpet_area_sqft: "",
          built_up_area_sqft: "",
          super_built_up_area_sqft: "",
          unit_category_id: "",
          rent_type: "",
          rent_amount: "",
          deposit_type: "Fixed",
          deposit_amount: "",
          late_fees_type: "Fixed",
          late_fees_amount: "",
          furnished: "Unfurnished",
          facilities_available: "",
          type_of_project_unit: "",
          minimum_sale_amount: "",
          maximum_sale_amount: "",
          rent_amount: "",
          floor_no: "",
          maintenance_charges: "",
          security_dept_of_maintenance: "",
          security_dept_of_rent: "",
          security_dept_of_electricity: "",
        },

    validationSchema: Yup.object({
      project_id: Yup.number(),
      unit_code: Yup.string().required("Please Enter Your Unit Code"),
      unit_area_sqft: Yup.string().matches(
        /^[0-9]+(\.[0-9]+)?$/,
        "Unit Area must contain only numbers"
      ),
      carpet_area_sqft: Yup.string().matches(
        /^[0-9]+(\.[0-9]+)?$/,
        "Carpet Area must contain only numbers"
      ),
      built_up_area_sqft: Yup.string().matches(
        /^[0-9]+(\.[0-9]+)?$/,
        "Built-up Area must contain only numbers"
      ),
      super_built_up_area_sqft: Yup.string().matches(
        /^[0-9]+(\.[0-9]+)?$/,
        "Super-built-up Area must contain only numbers"
      ),

      unit_category_id: Yup.string().required("Please Select Unit Category"),
      // rent_type: Yup.string().when("type_of_project_unit", {
      //   is: "1",
      //   then: () => Yup.string(),
      //   otherwise: () => Yup.string().required("Please Select Rent Type"),
      // }),
      // rent_amount: Yup.string().when("type_of_project_unit", {
      //   is: "1",
      //   then: () =>
      //     Yup.string().matches(
      //       /^[0-9]+(\.[0-9]+)?$/,
      //       "Rent Amount must only contain numbers"
      //     ),
      //   otherwise: () =>
      //     Yup.string()
      //       .matches(
      //         /^[0-9]+(\.[0-9]+)?$/,
      //         "Rent Amount must only contain numbers"
      //       )
      //       .required("Please Enter Rent Amount"),
      // }),
      // minimum_sale_amount: Yup.string().when("type_of_project_unit", {
      //   is: "2",
      //   then: () =>
      //     Yup.string().matches(
      //       /^[0-9]+(\.[0-9]+)?$/,
      //       "Minimum Sale Amount must only contain numbers"
      //     ),
      //   otherwise: () =>
      //     Yup.string().matches(
      //       /^[0-9]+(\.[0-9]+)?$/,
      //       "Minimum Sale Amount must only contain numbers"
      //     ),
      // }),
      // maximum_sale_amount: Yup.string().when("type_of_project_unit", {
      //   is: "2",
      //   then: () =>
      //     Yup.string().matches(
      //       /^[0-9]+(\.[0-9]+)?$/,
      //       "Maximum Sale Amount must only contain numbers"
      //     ),
      //   otherwise: () =>
      //     Yup.string().matches(
      //       /^[0-9]+(\.[0-9]+)?$/,
      //       "Maximum Sale Amount must only contain numbers"
      //     ),
      // }),
      security_dept_of_rent: Yup.string().when("type_of_project_unit", {
        is: "2",
        then: () =>
          Yup.string().matches(
            /^[0-9]+(\.[0-9]+)?$/,
            "Deposite Amount for Rent must only contain numbers"
          ),
      }),
      security_dept_of_maintenance: Yup.string().matches(
        /^[0-9]+(\.[0-9]+)?$/,
        "Deposite Amount for Maintenance must only contain numbers"
      ),
      security_dept_of_electricity: Yup.string().matches(
        /^[0-9]+(\.[0-9]+)?$/,
        "Deposite Amount for Electricity must only contain numbers"
      ),
      deposit_type: Yup.string(),
      deposit_amount: Yup.string().matches(
        /^[0-9]+(\.[0-9]+)?$/,
        "Deposite Amount must only contain numbers"
      ),
      late_fees_type: Yup.string(),
      late_fees_amount: Yup.string().matches(
        /^[0-9]+(\.[0-9]+)?$/,
        "Late Fees Amount must only contain numbers"
      ),
      furnished: Yup.string(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        if (isEditMode === true) {
          const response = await updateProjectUnit(projectId, values)

          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Project Unit has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate(-1)
              }
            })
          } else {
            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has not been saved.",
            })
          }
        } else if (isEditMode === false) {
          // const response = await dispatch(addNewVendor(values));
          const response = await addNewProjectUnit(values)
          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Project Unit has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate(-1)
              }
            })
          } else {
            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Project Unit has not been saved.",
            })
          }
        }
      } catch (error) {
        // Handle the error if needed
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const handleChange = (e, optionId) => {
    const { checked } = e.target
    let updatedProjectFacilities = [...validation.values.facilities_available]

    if (checked) {
      // Add the optionId to facilities_available array
      updatedProjectFacilities.push(String(optionId))
    } else {
      // Remove the optionId from facilities_available array
      updatedProjectFacilities = updatedProjectFacilities.filter(
        id => id !== String(optionId)
      )
    }

    validation.setFieldValue("facilities_available", updatedProjectFacilities)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <CardTitle className="h4" style={{ fontSize: "20px" }}>
            <div className="d-flex gap-2 mb-3">
              {isEditMode && (
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
              )}
              {!isEditMode ? "Add New Project Unit" : "Edit Project Unit"}
            </div>
          </CardTitle>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="project_id">Project Name*</Label>
                          <select
                            name="project_id"
                            // defaultValue={validation.values.project_id}
                            // className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.project_id}
                            className={`form-select ${
                              validation.touched.project_id &&
                              validation.errors.project_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.project_id && (
                              <option>Select Project</option>
                            )}

                            {projects.map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_id &&
                          validation.errors.project_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.project_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="unit_code">Unit Code*</Label>
                          <Input
                            type="text"
                            name="unit_code"
                            className="form-control"
                            id="unit_code"
                            placeholder="Enter Unit Code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.unit_code || ""}
                            invalid={
                              validation.touched.unit_code &&
                              validation.errors.unit_code
                                ? true
                                : false
                            }
                          />
                          {validation.touched.unit_code &&
                          validation.errors.unit_code ? (
                            <FormFeedback type="invalid">
                              {validation.errors.unit_code}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="unit_area_sqft">
                            Chargeable Unit Area(sq.ft)
                          </Label>
                          <Input
                            name="unit_area_sqft"
                            type="text"
                            className={`form-control ${
                              validation.touched.unit_area_sqft &&
                              validation.errors.unit_area_sqft
                                ? "is-invalid"
                                : ""
                            }`}
                            id="unit_area_sqft"
                            placeholder="Enter Chargeable Unit Area in sqft"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.unit_area_sqft || ""}
                          />
                          {validation.touched.unit_area_sqft &&
                          validation.errors.unit_area_sqft ? (
                            <FormFeedback type="invalid">
                              {validation.errors.unit_area_sqft}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="carpet_area_sqft">
                            Carpet Area(sq.ft)
                          </Label>
                          <Input
                            name="carpet_area_sqft"
                            type="text"
                            className={`form-control ${
                              validation.touched.carpet_area_sqft &&
                              validation.errors.carpet_area_sqft
                                ? "is-invalid"
                                : ""
                            }`}
                            id="carpet_area_sqft"
                            placeholder="Enter Carpet Area in sqft"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.carpet_area_sqft || ""}
                          />
                          {validation.touched.carpet_area_sqft &&
                          validation.errors.carpet_area_sqft ? (
                            <FormFeedback type="invalid">
                              {validation.errors.carpet_area_sqft}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="built_up_area_sqft">
                            Built-up Area(sq.ft)
                          </Label>
                          <Input
                            name="built_up_area_sqft"
                            type="text"
                            className={`form-control ${
                              validation.touched.built_up_area_sqft &&
                              validation.errors.built_up_area_sqft
                                ? "is-invalid"
                                : ""
                            }`}
                            id="built_up_area_sqft"
                            placeholder="Enter Built-up Area in sqft"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.built_up_area_sqft || ""}
                          />
                          {validation.touched.built_up_area_sqft &&
                          validation.errors.built_up_area_sqft ? (
                            <FormFeedback type="invalid">
                              {validation.errors.built_up_area_sqft}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="super_built_up_area_sqft">
                            Super Built-up Area(sq.ft)
                          </Label>
                          <Input
                            name="super_built_up_area_sqft"
                            type="text"
                            className={`form-control ${
                              validation.touched.super_built_up_area_sqft &&
                              validation.errors.super_built_up_area_sqft
                                ? "is-invalid"
                                : ""
                            }`}
                            id="super_built_up_area_sqft"
                            placeholder="Enter Super Built-up Area in sqft"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.super_built_up_area_sqft || ""
                            }
                          />
                          {validation.touched.super_built_up_area_sqft &&
                          validation.errors.super_built_up_area_sqft ? (
                            <FormFeedback type="invalid">
                              {validation.errors.super_built_up_area_sqft}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="floor_no">Floor No.</Label>
                          <Input
                            name="floor_no"
                            type="text"
                            className={`form-control ${
                              validation.touched.floor_no &&
                              validation.errors.floor_no
                                ? "is-invalid"
                                : ""
                            }`}
                            id="floor_no"
                            placeholder="Enter Floor No"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.floor_no || ""}
                          />
                          {validation.touched.floor_no &&
                          validation.errors.floor_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.floor_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="unit_category_id">
                            Unit Category*
                          </Label>
                          <select
                            name="unit_category_id"
                            defaultValue="0"
                            className={`form-control ${
                              validation.touched.unit_category_id &&
                              validation.errors.unit_category_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.unit_category_id}
                          >
                            <option value="0">Select Category</option>

                            {projectUnitCategory.map(units => (
                              <option key={units.id} value={units.id}>
                                {units.category_title}
                              </option>
                            ))}
                          </select>
                          {validation.touched.unit_category_id &&
                          validation.errors.unit_category_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.unit_category_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="type_of_project_unit">
                            Sale/Rent*
                          </Label>
                          <select
                            name="type_of_project_unit"
                            className={`form-select ${
                              validation.touched.type_of_project_unit &&
                              validation.errors.type_of_project_unit
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.type_of_project_unit}
                          >
                            {!validation.values.type_of_project_unit && (
                              <option>Select Sale/Rent</option>
                            )}
                            <option value="1">Sale</option>
                            <option value="2">Rent</option>
                          </select>
                          {validation.touched.type_of_project_unit &&
                          validation.errors.type_of_project_unit ? (
                            <FormFeedback type="invalid">
                              {validation.errors.type_of_project_unit}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="maintenance_charges">
                            Maintenance Charges*
                          </Label>
                          <Input
                            name="maintenance_charges"
                            type="text"
                            className={`form-control ${
                              validation.touched.maintenance_charges &&
                              validation.errors.maintenance_charges
                                ? "is-invalid"
                                : ""
                            }`}
                            id="maintenance_charges"
                            placeholder="Enter Maintenance charges Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.maintenance_charges || ""}
                          />
                          {validation.touched.maintenance_charges &&
                          validation.errors.maintenance_charges ? (
                            <FormFeedback type="invalid">
                              {validation.errors.maintenance_charges}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      {String(validation.values.type_of_project_unit) ===
                        "2" && (
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="security_dept_of_rent">
                              Security Deposit for Rent*
                            </Label>
                            <Input
                              type="text"
                              name="security_dept_of_rent"
                              className="form-control"
                              id="security_dept_of_rent"
                              placeholder="Enter Security Deposit for Rent"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.security_dept_of_rent || ""
                              }
                              invalid={
                                validation.touched.security_dept_of_rent &&
                                validation.errors.security_dept_of_rent
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.security_dept_of_rent &&
                            validation.errors.security_dept_of_rent ? (
                              <FormFeedback type="invalid">
                                {validation.errors.security_dept_of_rent}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="security_dept_of_electricity">
                            Security Deposit for Electricity*
                          </Label>
                          <Input
                            type="text"
                            name="security_dept_of_electricity"
                            className="form-control"
                            id="security_dept_of_electricity"
                            placeholder="Enter Security Deposit Amount for Electricity"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.security_dept_of_electricity ||
                              ""
                            }
                            invalid={
                              validation.touched.security_dept_of_electricity &&
                              validation.errors.security_dept_of_electricity
                                ? true
                                : false
                            }
                          />
                          {validation.touched.security_dept_of_electricity &&
                          validation.errors.security_dept_of_electricity ? (
                            <FormFeedback type="invalid">
                              {validation.errors.security_dept_of_electricity}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="security_dept_of_maintenance">
                            Security Deposit of Maintenance*
                          </Label>
                          <Input
                            type="text"
                            name="security_dept_of_maintenance"
                            className="form-control"
                            id="security_dept_of_maintenance"
                            placeholder="Enter Security Deposit Amount for Maintenance"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.security_dept_of_maintenance ||
                              ""
                            }
                            invalid={
                              validation.touched.security_dept_of_maintenance &&
                              validation.errors.security_dept_of_maintenance
                                ? true
                                : false
                            }
                          />
                          {validation.touched.security_dept_of_maintenance &&
                          validation.errors.security_dept_of_maintenance ? (
                            <FormFeedback type="invalid">
                              {validation.errors.security_dept_of_maintenance}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {String(validation.values.type_of_project_unit) === "1" && (
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="minimum_sale_amount">
                              Minimum Sale Amount*
                            </Label>
                            <Input
                              name="minimum_sale_amount"
                              type="text"
                              className={`form-control ${
                                validation.touched.minimum_sale_amount &&
                                validation.errors.minimum_sale_amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="minimum_sale_amount"
                              placeholder="Enter Minimum Sale Amount"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.minimum_sale_amount}
                            />
                            {validation.touched.minimum_sale_amount &&
                            validation.errors.minimum_sale_amount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.minimum_sale_amount}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="maximum_sale_amount">
                              Maximum Sale Amount*
                            </Label>
                            <Input
                              name="maximum_sale_amount"
                              type="text"
                              className={`form-control ${
                                validation.touched.maximum_sale_amount &&
                                validation.errors.maximum_sale_amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="maximum_sale_amount"
                              placeholder="Enter Maximum Sale Amount"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.maximum_sale_amount}
                            />
                            {validation.touched.maximum_sale_amount &&
                            validation.errors.maximum_sale_amount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.maximum_sale_amount}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    )}
                    {String(validation.values.type_of_project_unit) === "2" && (
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="rent_amount">Rent Amount*</Label>
                            <Input
                              name="rent_amount"
                              type="text"
                              className={`form-control ${
                                validation.touched.rent_amount &&
                                validation.errors.rent_amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="rent_amount"
                              placeholder="Enter Rent Amount"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.rent_amount}
                            />
                            {validation.touched.rent_amount &&
                            validation.errors.rent_amount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.rent_amount}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="rent_type">Rent Type*</Label>
                            <select
                              name="rent_type"
                              className={`form-select ${
                                validation.touched.rent_type &&
                                validation.errors.rent_type
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.rent_type}
                            >
                              {!validation.values.rent_type && (
                                <option>Select Rent Type</option>
                              )}
                              <option value="Monthly">Monthly</option>
                              <option value="Quarterly">Quarterly</option>
                              <option value="Yearly">Yearly</option>
                            </select>
                            {validation.touched.rent_type &&
                            validation.errors.rent_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.rent_type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col>
                        <Label htmlFor="furnished">Furnished?</Label>
                        <Col lg="6">
                          <div className="form-check">
                            <Input
                              type="radio"
                              id="unfurnished"
                              name="furnished"
                              value="Unfurnished"
                              checked={
                                validation.values.furnished === "Unfurnished"
                              }
                              onChange={validation.handleChange}
                              className={`form-check-input ${
                                validation.touched.furnished &&
                                validation.errors.furnished
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onBlur={validation.handleBlur}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="unfurnished"
                              style={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <h6>Unfurnished</h6>
                            </Label>
                          </div>

                          <div className="form-check">
                            <Input
                              type="radio"
                              id="fullyfurnished"
                              name="furnished"
                              value="Fully furnished"
                              checked={
                                validation.values.furnished ===
                                "Fully furnished"
                              }
                              onChange={validation.handleChange}
                              className={`form-check-input ${
                                validation.touched.furnished &&
                                validation.errors.furnished
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onBlur={validation.handleBlur}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="fullyfurnished"
                              style={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <h6>Fully Furnished</h6>
                            </Label>
                          </div>

                          <div className="form-check form-check">
                            <Input
                              type="radio"
                              id="semifurnished"
                              name="furnished"
                              value="Semi-furnished"
                              checked={
                                validation.values.furnished === "Semi-furnished"
                              }
                              onChange={validation.handleChange}
                              className={`form-check-input ${
                                validation.touched.furnished &&
                                validation.errors.furnished
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onBlur={validation.handleBlur}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="semifurnished"
                              style={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <h6>Semi Furnished</h6>
                            </Label>
                          </div>
                        </Col>
                        {validation.touched.furnished &&
                        validation.errors.furnished &&
                        validation.values.furnished === null ? (
                          <FormFeedback type="invalid">
                            {validation.errors.furnished}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col>
                        <div className="mb-2"></div>
                        <div>
                          <Label htmlFor="facilities_available">
                            Facilities Available
                          </Label>
                        </div>

                        <Col lg="6">
                          {facilitiesAvailable?.map(option => (
                            <div className="form-check" key={option.id}>
                              <Input
                                type="checkbox"
                                className="form-check-Input"
                                id={`facilities_available${option.id}`}
                                name="facilities_available"
                                value={option.id}
                                onClick={e => handleChange(e, option.id)} // Use onChange instead of onClick
                                checked={validation?.values?.facilities_available?.includes(
                                  String(option.id)
                                )}
                              />
                              <Label
                                className="form-check-Label"
                                htmlFor={`facilities_available${option.id}`}
                              >
                                <h6>{option.facility_name}</h6>
                              </Label>
                            </div>
                          ))}
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3 text-end">
                          <button
                            type="submit"
                            className="btn btn-primary l-md"
                          >
                            Submit
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default addnewprojectunit
