import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_INVOICES,
  GET_INVOICE_DETAIL,
  ADD_NEW_INVOICE,
  DELETE_INVOICE,
  UPDATE_INVOICE,
  GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT,
  GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT,
} from "./actionTypes"
import {
  getInvoicesSuccess,
  getInvoicesFail,
  getInvoiceDetailSuccess,
  getInvoiceDetailFail,
  addInvoiceFail,
  addInvoiceSuccess,
  updateInvoiceSuccess,
  updateInvoiceFail,
  deleteInvoiceSuccess,
  deleteInvoiceFail,
  getGstApplicableRateForInvoiceComponentSuccess,
  getGstApplicableRateForInvoiceComponentFail,
  getSACHSNCodeForInvoiceComponentSuccess,
  getSACHSNCodeForInvoiceComponentFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getInvoices,
  getInvoiceDetails,
  addNewInvoice,
  updateInvoice,
  deleteInvoice,
  getGstApplicableRateForInvoiceComponent,
  getSACHSNCodeForInvoiceComponent,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

function* fetchInvoices() {
  try {
    const response = yield call(getInvoices)
    yield put(getInvoicesSuccess(response))
  } catch (error) {
    yield put(getInvoicesFail(error))
  }
}

function* fetchInvoiceDetail({ invoiceId }) {
  try {
    const response = yield call(getInvoiceDetails, invoiceId)

    yield put(getInvoiceDetailSuccess(response))
  } catch (error) {
    yield put(getInvoiceDetailFail(error))
  }
}

function* onUpdateInvoice({ payload: { id, invoice } }) {
  try {
    const response = yield call(updateInvoice, id, invoice)
    yield put(updateInvoiceSuccess(response))
    // toast.success("Invoice Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateInvoiceFail(error))
    // toast.error("Invoice Update Failded", { autoClose: 2000 })
  }
  const response2 = yield put(updateFacilitySuccess(response))
}

function* onDeleteInvoice({ payload: invoice }) {
  try {
    const response = yield call(deleteInvoice, invoice)
    yield put(deleteInvoiceSuccess(response))
    // toast.success("Invoice Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteInvoiceFail(error))
    // toast.error("Invoice Delete Failded", { autoClose: 2000 })
  }
}

function* onAddNewInvoice({ payload: invoice }) {
  try {
    const response = yield call(addNewInvoice, invoice)
    yield put(addInvoiceSuccess(response))
    toast.success("Invoice Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addInvoiceFail(error))
    toast.error("Invoice Add Failded", { autoClose: 2000 })
  }
}

function* fetchGstApplicableRateForInvoiceComponent() {
  try {
    const response = yield call(getGstApplicableRateForInvoiceComponent)
    yield put(getGstApplicableRateForInvoiceComponentSuccess(response))
  } catch (error) {
    yield put(getGstApplicableRateForInvoiceComponentFail(error))
  }
}

function* fetchSACHSNCodeForInvoiceComponent() {
  try {
    const response = yield call(getSACHSNCodeForInvoiceComponent)
    yield put(getSACHSNCodeForInvoiceComponentSuccess(response))
  } catch (error) {
    yield put(getSACHSNCodeForInvoiceComponentFail(error))
  }
}

function* invoicesSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices)
  yield takeEvery(GET_INVOICE_DETAIL, fetchInvoiceDetail)
  yield takeEvery(ADD_NEW_INVOICE, onAddNewInvoice)
  yield takeEvery(UPDATE_INVOICE, onUpdateInvoice)
  yield takeEvery(DELETE_INVOICE, onDeleteInvoice)
  yield takeEvery(GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT, fetchGstApplicableRateForInvoiceComponent)
  yield takeEvery(GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT, fetchSACHSNCodeForInvoiceComponent)
}

export default invoicesSaga
