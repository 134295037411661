import React, { useEffect, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getLandlordDetail } from "store/actions"

const ProjectDetailView = props => {
  const dispatch = useDispatch()

  const landlordDetailState = state => state.landlords.landlordDetail.data
  const landlordProfile = useSelector(landlordDetailState)

  const [landlordId, setLandlordId] = useState()

  const landlordDetails = async () => {
    try {
      const url = new URL(window.location.href)
      const landlordId = url.pathname.split("/view-landlord-service/")[1]
      setLandlordId(landlordId)
      dispatch(getLandlordDetail(landlordId))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    landlordDetails()
  }, [dispatch])

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col>
            <CardTitle className="mb-3">Landlord Details</CardTitle>

            <Row className="mb-3">
              <Col lg={4}>
                <Label className="form-label">Landlord Name</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.landlord_name}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Account Holder Name</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.account_holder_name}
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Label className="form-label">Address</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.address_line_1}{" "}
                  {landlordProfile?.address_line_2}
                </p>
              </Col>

              <Col lg={4}>
                <Label className="form-label">Email </Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.email_id}
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Label className="form-label">Bank Account No.</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.bank_account_no}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Bank IFSC Card</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.bank_ifsc_code}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Label className="form-label">Bank name</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.bank_name}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Aadhar Card</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.adhar_card_no}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Label className="form-label">Entity</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.entity}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">PAN Card</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.pan_no}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Label className="form-label">Country</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.country}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">State</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {landlordProfile?.state}
                </p>
              </Col>
            </Row>
          </Col>
          <hr />
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ProjectDetailView
