import React, { useEffect, useState } from "react"

import { Link } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import Swal from "sweetalert2"
import {
  FormFeedback,
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap"

// import { recentfile } from "../../../common/data"
import {
  deleteClientDocument,
  getActiveDocuments,
  getClientDocuments,
} from "store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import DeleteModal from "components/Common/DeleteModal"
import { addClientDocuments } from "helpers/fakebackend_helper"

const ClientDocuments = () => {
  const dispatch = useDispatch()

  const ActiveDocumentsState = state =>
    state.documents.activeDocuments.active_documents
  const ActiveDocuments = useSelector(ActiveDocumentsState) || []

  const getDocumentationState = state => state.clients.docs.client_documents
  const docs = useSelector(getDocumentationState) || []
  const [clientId, setClientId] = useState()

  const projectActiveDocuments = async () => {
    try {
      const response = await dispatch(getActiveDocuments("Customer"))
    } catch (error) {
      console.error(error)
    }
  }
  const getDocuments = async () => {
    const url = new URL(window.location.href)
    const clientId = url.pathname.split("/view-client/")[1]
    try {
      const response = await dispatch(getClientDocuments(clientId))
    } catch (error) {
      console.error(error)
    }
  }
  const fetchData = () => {
    const url = new URL(window.location.href)
    const clientId = url.pathname.split("/view-client/")[1]
    setClientId(clientId)
  }
  useEffect(() => {
    fetchData()
    projectActiveDocuments()
    getDocuments()
  }, [dispatch])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      client_id: clientId,
      document_id: "",
      file: [],
    },

    validationSchema: Yup.object({
      user_id: Yup.number(),
      document_id: Yup.number().required("Please Select a Document type"),

      file: Yup.mixed(),
    }),
    onSubmit: async values => {
      let loadingSwal // Define loadingSwal outside the try-catch block

      try {
        loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })

        const response = await addClientDocuments(values)

        if (response && response.status === true) {
          loadingSwal.close()

          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Documents have been updated.",
          }).then(() => {
            dispatch(getClientDocuments(clientId))
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "Failure",
            icon: "error",
            text: "Your Documents have not been saved.",
          })
        }
      } catch (error) {
        console.error("Error:", error)
        if (loadingSwal) {
          // Ensure loadingSwal is defined before attempting to close it
          loadingSwal.close()
        }

        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response?.data?.message ||
            "An error occurred while saving your Documents.",
        })
      }
    },
  })

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = clientId => {
    setDeleteId({ id: clientId })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteClientDocument(deleteId.id))
    
    setDeleteModal(false)
    dispatch(getClientDocuments(clientId))
    dispatch(getClientDocuments(clientId))
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      {permission
        .filter(
          item => item.page === "add-client-documents" && item.access === 1
        )
        .map((item, index) => (
          <Form
            key={index}
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col lg={2}>
                <div className="mb-3">
                  <Label htmlFor="formrow-userRole-Input">Document</Label>
                  <select
                    name="document_id"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.document_id || ""}
                    className={`form-select ${
                      validation.touched.document_id &&
                      validation.errors.document_id
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.document_id && (
                      <option>Select Document</option>
                    )}

                    {ActiveDocuments.map(document => (
                      <option key={document.id} value={document.id}>
                        {document.document_title}
                      </option>
                    ))}
                  </select>
                  {validation.touched.document_id &&
                  validation.errors.document_id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.document_id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={7}>
                <div className="mb-3">
                  <Label htmlFor="file">Upload File</Label>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    onChange={e => {
                      const selectedFile = e.target.files[0]
                      validation.setFieldValue("file", selectedFile)

                      // validation.setFieldValue('file', [
                      //   { file: selectedFile, filename: selectedFile.name },
                      // ]);
                    }}
                    onBlur={validation.handleBlur}
                    className={`form-control ${
                      validation.touched.file && validation.errors.file
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {validation.touched.file && validation.errors.file && (
                    <div className="invalid-feedback">
                      {validation.errors.file}
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={3}>
                <div className="mb-3 text-end mt-4 ">
                  <button type="submit" className="btn btn-primary text-end">
                    Add Documents
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        ))}
      <div className="table-responsive" style={{ overflowX: "none" }}>
        <Table className="table align-middle table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th scope="col">Document Name</th>
              <th scope="col">Created At</th>
              <th scope="col" colSpan="2">
                Date Modified
              </th>
            </tr>
          </thead>
          {docs?.length > 0 ? (
            <tbody>
              {docs?.map((item, key) => (
                <tr key={key}>
                  <td>
                    <Link to="#" className="text-dark fw-medium">
                      <i className={item.icon}></i> {item.document_title}
                    </Link>
                  </td>
                  <td>{item.created_at}</td>
                  <td>{item.updated_at}</td>
                  <td>
                    <div className="d-flex gap-3">
                      {permission
                        .filter(
                          perm =>
                            perm.page === "download-client-document" &&
                            perm.access === 1
                        )
                        .map(index => (
                          <td key={index}>
                            <a
                              href={item.download_file}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-success"
                              download
                            >
                              <i
                                className="mdi mdi-download font-size-18"
                                id={`downloadtooltip-${key}`}
                                style={{ color: "#3366cc" }}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={`downloadtooltip-${key}`}
                              >
                                Download
                              </UncontrolledTooltip>
                            </a>
                          </td>
                        ))}
                      {permission
                        .filter(
                          perm =>
                            perm.page === "delete-client-document" &&
                            perm.access === 1
                        )
                        .map(index => (
                          <Link
                            key={index}
                            to="#"
                            className="text-danger"
                            onClick={() => {
                              onClickDelete(item.id)
                            }}
                          >
                            <i
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="deletetooltip"
                            >
                              Delete
                            </UncontrolledTooltip>
                          </Link>
                        ))}
                      {permission
                        .filter(
                          perm =>
                            perm.page === "view-client-document" &&
                            perm.access === 1
                        )
                        .map(index => (
                          <td key={index}>
                            {" "}
                            <a
                              href={item.file}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-success"
                            >
                              <i
                                className="mdi mdi-chevron-right font-size-18"
                                id={`edittooltip-${key}`}
                                style={{ color: "#3366cc" }}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={`edittooltip-${key}`}
                              >
                                View
                              </UncontrolledTooltip>
                            </a>
                          </td>
                        ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="4" className="text-center">
                  No Records Found
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
      {/* </div> */}
    </React.Fragment>
  )
}

export default ClientDocuments
