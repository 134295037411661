/* VENDORS */
export const GET_SACHSNCODE = "GET_SACHSNCODE"
export const GET_SACHSNCODE_SUCCESS = "GET_SACHSNCODE_SUCCESS"
export const GET_SACHSNCODE_FAIL = "GET_SACHSNCODE_FAIL"

/* VENDORS DETAIL*/
export const GET_SACHSNCODE_DETAIL = "GET_SACHSNCODE_DETAIL"
export const GET_SACHSNCODE_DETAIL_SUCCESS = "GET_SACHSNCODE_DETAIL_SUCCESS"
export const GET_SACHSNCODE_DETAIL_FAIL = "GET_SACHSNCODE_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_SACHSNCODE = "ADD_NEW_SACHSNCODE"
 export const ADD_NEW_SACHSNCODE_SUCCESS = "ADD_NEW_SACHSNCODE_SUCCESS"
 export const ADD_NEW_SACHSNCODE_FAIL = "ADD_NEW_SACHSNCODE_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_SACHSNCODE = "UPDATE_SACHSNCODE"
 export const UPDATE_SACHSNCODE_SUCCESS = "UPDATE_SACHSNCODE_SUCCESS"
 export const UPDATE_SACHSNCODE_FAIL = "UPDATE_SACHSNCODE_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_SACHSNCODE = "DELETE_SACHSNCODE"
 export const DELETE_SACHSNCODE_SUCCESS = "DELETE_SACHSNCODE_SUCCESS"
 export const DELETE_SACHSNCODE_FAIL = "DELETE_SACHSNCODE_FAIL"
