import React, { useEffect, useRef, useState } from "react"
import {
  Container,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Form,
  Label,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import "./account.css"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import {
  addNewCompany,
  deleteCompany,
  getCompanies,
  getCompanyDetail,
  updateCompany,
} from "../../store/company/actions"
import Spinners from "components/Common/Spinner"
import DeleteModal from "components/Common/DeleteModal"

const AccountSetup = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [allCompanies, setAllCompany] = useState(); // commented out as it's not declared
  const [loading, setLoading] = useState(true)

  const allCompanies = useSelector(state => state.companies.companies.companies)

  const companiesProperties = createSelector(
    state => state.companies.companies.companies,
    companies => ({
      error: companies.error,
    })
  )
  // const { error } = useSelector(companiesProperties);

  const fetchData = async () => {
    try {
      const response = await dispatch(getCompanies()) // assuming getCompanies is a thunk action
      // setAllCompany(response.companies); // commented out as it's not declared
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [dispatch])
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const handleAddAccount = () => {
    navigate("/settings/company-setup-page")
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }
  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = userData => {
    setDeleteId({ id: userData })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteCompany(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/settings/companies")
    dispatch(getCompanies())
    dispatch(getCompanies())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        delete="Company"
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="All Company" breadcrumbItem="All Company" />
          {loading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <div>
              {allCompanies &&
                allCompanies.map(company => (
                  <Card key={company.id}>
                    <CardBody>
                      <Form autoComplete="off">
                        <div className="frameyy">
                          <div className="add-photo-alternate-wrapperyy">
                            <img
                              src={company.logo}
                              alt=""
                              style={{ height: "140px", width: "140px" }}
                              className="object-fit-fill rounded-2"
                            />
                          </div>
                          <Col>
                            <Label className="text-wrapperyy">
                              {company.name}
                            </Label>
                            <div className="div-2">
                              <div className="div-3">
                                <div className="mt-4">
                                  <Label className="d-flex gap-2">
                                    <i className="dripicons-location"></i>
                                    {company.address_line_1}{" "}
                                    {company.address_line_2}
                                  </Label>
                                </div>
                              </div>
                              {/* <Row>
                                <Col>
                                  <Label className="d-flex gap-2">
                                    <i className="dripicons-phone "></i>
                                    {company.contact_phone}
                                  </Label>
                                </Col>
                                <Col>
                                  <Label className="d-flex gap-2">
                                    <i className="bx bx-envelope mt-1"></i>
                                    {company.account_email}
                                  </Label>
                                </Col>
                              </Row> */}
                            </div>
                          </Col>
                          {permission
                            .filter(
                              item =>
                                item.page === "/edit-company/:id" &&
                                item.access === 1
                            )
                            .map((item, index) => (
                              <Link
                                key={index}
                                to={`/settings/edit-company/${company.id}`}
                              >
                                <i
                                  className="mdi mdi-pencil font-size-24"
                                  id="edittooltip"
                                  style={{ fontSize: "40px" }}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="edittooltip"
                                >
                                  Edit
                                </UncontrolledTooltip>
                              </Link>
                            ))}
                          {permission
                            .filter(
                              item =>
                                item.page === "/delete-company" &&
                                item.access === 1
                            )
                            .map((item, index) => (
                              <Link
                                key={index}
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                  const userData = company.id
                                  onClickDelete(userData)
                                }}
                              >
                                <i
                                  className="mdi mdi-delete font-size-24"
                                  id="deletetooltip"
                                  style={{ color: "#3366cc" }}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="deletetooltip"
                                >
                                  Delete
                                </UncontrolledTooltip>
                              </Link>
                            ))}
                          {permission
                            .filter(
                              item =>
                                item.page === "/view-company/:id" &&
                                item.access === 1
                            )
                            .map((item, index) => (
                              <Link
                                key={index}
                                to={`/settings/view-company/${company.id}`}
                              >
                                <i
                                  className="mdi mdi-arrow-right"
                                  id="viewtooltip"
                                  style={{ fontSize: "40px" }}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="viewtooltip"
                                >
                                  View
                                </UncontrolledTooltip>
                              </Link>
                            ))}
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                ))}
              {allCompanies && allCompanies.length === 0 && (
                <div className="apartmentframe">
                  <div className="apartment-wrapper">
                    <i
                      className="mdi mdi-home-city"
                      style={{ fontSize: "60px" }}
                    ></i>
                  </div>
                  <div className="text-wrapper">No Companies</div>
                  <div className="div" onClick={handleAddAccount}>
                    <i className="bx bx-plus"></i>
                    <div className="text-wrapper-2">Add Company</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Container>
      </div>
    </>
  )
}

export default AccountSetup
