import {
  GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD,
  GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_FAIL,
  GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_SUCCESS,
  GET_FACING_MAINTENANCE_ISSUE_LIST,
  GET_FACING_MAINTENANCE_ISSUE_LIST_FAIL,
  GET_FACING_MAINTENANCE_ISSUE_LIST_SUCCESS,
  GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST,
  GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_FAIL,
  GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_SUCCESS,
  GET_RECENT_SERVICE_REQUEST_LIST,
  GET_RECENT_SERVICE_REQUEST_LIST_FAIL,
  GET_RECENT_SERVICE_REQUEST_LIST_SUCCESS,
  GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH,
  GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_FAIL,
  GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_SUCCESS,
  GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM,
  GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_FAIL,
  GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_SUCCESS,
  GET_SUPPORT_TICKET_LIST,
  GET_SUPPORT_TICKET_LIST_FAIL,
  GET_SUPPORT_TICKET_LIST_SUCCESS,
  GET_TYPES_OF_REQUESTS_GRAPH,
  GET_TYPES_OF_REQUESTS_GRAPH_FAIL,
  GET_TYPES_OF_REQUESTS_GRAPH_SUCCESS,
} from "./actionTypes"

export const getCountForServiceRequestDashboard = () => ({
  type: GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD,
})

export const getCountForServiceRequestDashboardSuccess = count => ({
  type: GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_SUCCESS,
  payload: count,
})

export const getCountForServiceRequestDashboardFail = error => ({
  type: GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_FAIL,
  payload: error,
})
export const getLookingForadditionalProjectUnitList = () => ({
  type: GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST,
})

export const getLookingForadditionalProjectUnitListSuccess =
  additionalProjectUnitList => ({
    type: GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_SUCCESS,
    payload: additionalProjectUnitList,
  })

export const getLookingForadditionalProjectUnitListFail = error => ({
  type: GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_FAIL,
  payload: error,
})
export const getFacingMaintenanceIssueList = () => ({
  type: GET_FACING_MAINTENANCE_ISSUE_LIST,
})

export const getFacingMaintenanceIssueListSuccess = maintenanceIssueList => ({
  type: GET_FACING_MAINTENANCE_ISSUE_LIST_SUCCESS,
  payload: maintenanceIssueList,
})

export const getFacingMaintenanceIssueListFail = error => ({
  type: GET_FACING_MAINTENANCE_ISSUE_LIST_FAIL,
  payload: error,
})
export const getSupportTicketList = () => ({
  type: GET_SUPPORT_TICKET_LIST,
})

export const getSupportTicketListSuccess = supportTicketList => ({
  type: GET_SUPPORT_TICKET_LIST_SUCCESS,
  payload: supportTicketList,
})

export const getSupportTicketListFail = error => ({
  type: GET_SUPPORT_TICKET_LIST_FAIL,
  payload: error,
})
export const getRecentServiceRequestList = () => ({
  type: GET_RECENT_SERVICE_REQUEST_LIST,
})

export const getRecentServiceRequestListSuccess = recentRequests => ({
  type: GET_RECENT_SERVICE_REQUEST_LIST_SUCCESS,
  payload: recentRequests,
})

export const getRecentServiceRequestListFail = error => ({
  type: GET_RECENT_SERVICE_REQUEST_LIST_FAIL,
  payload: error,
})

export const getTypesOfRequestsGraph = typesOfRequestsGraph => ({
  type: GET_TYPES_OF_REQUESTS_GRAPH,
  payload: typesOfRequestsGraph,
})

export const getTypesOfRequestsGraphSuccess = typesOfRequestsGraph => ({
  type: GET_TYPES_OF_REQUESTS_GRAPH_SUCCESS,
  payload: typesOfRequestsGraph,
})

export const getTypesOfRequestsGraphFail = error => ({
  type: GET_TYPES_OF_REQUESTS_GRAPH_FAIL,
  payload: error,
})
export const getServiceRequestsAssignmentGraph = requestsAssignmentGraph => ({
  type: GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH,
  payload: requestsAssignmentGraph,
})

export const getServiceRequestsAssignmentGraphSuccess = requestsAssignmentGraph => ({
  type: GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_SUCCESS,
  payload: requestsAssignmentGraph,
})

export const getServiceRequestsAssignmentGraphFail = error => ({
  type: GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_FAIL,
  payload: error,
})
export const getServiceRequestStatusPieDigram = requestsPieDigram => ({
  type: GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM,
  payload: requestsPieDigram,
})

export const getServiceRequestStatusPieDigramSuccess = requestsPieDigram => ({
  type: GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_SUCCESS,
  payload: requestsPieDigram,
})

export const getServiceRequestStatusPieDigramFail = error => ({
  type: GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_FAIL,
  payload: error,
})
