import {
  GET_VENDOR_INVOICES_DATA_FAIL,
  GET_VENDOR_INVOICES_DATA_SUCCESS,
  GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_FAIL,
  GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_SUCCESS,
  GET_VENDOR_INVOICES_DATA_WITH_TDS_FAIL,
  GET_VENDOR_INVOICES_DATA_WITH_TDS_SUCCESS,
  GET_VENDOR_INVOICES_FAIL,
  GET_VENDOR_INVOICES_PAYMENT_DATA_FAIL,
  GET_VENDOR_INVOICES_PAYMENT_DATA_SUCCESS,
  GET_VENDOR_INVOICES_SUCCESS,
  GET_VENDOR_LEDGER_FAIL,
  GET_VENDOR_LEDGER_SUCCESS,
  GET_VENDOR_TICKET_DATA_FAIL,
  GET_VENDOR_TICKET_DATA_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  vendorInvoices: [],
  vendorLedger: {},
  vendorInvoicesData: [],
  vendorInvoicesDataWithTds: [],
  vendorInvoicesDataWithOustandingAmount: [],
  vendorInvoicesPaymentData: [],
  vendorTicketData: [],
  error: {},
  loading: true,
}

const vendorSummaryReport = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VENDOR_INVOICES_SUCCESS:
      return {
        ...state,
        vendorInvoices: action.payload,
        loading: true,
      }

    case GET_VENDOR_INVOICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_VENDOR_INVOICES_DATA_SUCCESS:
      return {
        ...state,
        vendorInvoicesData: action.payload,
        loading: true,
      }

    case GET_VENDOR_INVOICES_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_VENDOR_INVOICES_DATA_WITH_TDS_SUCCESS:
      return {
        ...state,
        vendorInvoicesDataWithTds: action.payload,
        loading: true,
      }

    case GET_VENDOR_INVOICES_DATA_WITH_TDS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_SUCCESS:
      return {
        ...state,
        vendorInvoicesDataWithOustandingAmount: action.payload,
        loading: true,
      }

    case GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_VENDOR_INVOICES_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        vendorInvoicesPaymentData: action.payload,
        loading: true,
      }

    case GET_VENDOR_INVOICES_PAYMENT_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_VENDOR_TICKET_DATA_SUCCESS:
      return {
        ...state,
        vendorTicketData: action.payload,
        loading: true,
      }

    case GET_VENDOR_TICKET_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_VENDOR_LEDGER_SUCCESS:
      return {
        ...state,
        vendorLedger: action.payload,
        loading: true,
      }

    case GET_VENDOR_LEDGER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default vendorSummaryReport
