import {
    GET_MAINTAINENCES,
    GET_MAINTAINENCES_FAIL,
    GET_MAINTAINENCES_SUCCESS,
    GET_MAINTAINENCE_DETAIL,
    ADD_NEW_MAINTAINENCE,
    ADD_MAINTAINENCE_SUCCESS,
    ADD_MAINTAINENCE_FAIL,
    UPDATE_MAINTAINENCE,
    UPDATE_MAINTAINENCE_SUCCESS,
    UPDATE_MAINTAINENCE_FAIL,
    DELETE_MAINTAINENCE,
    DELETE_MAINTAINENCE_SUCCESS,
    DELETE_MAINTAINENCE_FAIL,
    GET_MAINTAINENCE_DETAIL_FAIL,
    GET_MAINTAINENCE_DETAIL_SUCCESS,
  } from "./actionTypes"
  
  export const getMaintainences = () => ({
    type: GET_MAINTAINENCES,
  })
  
  export const getMaintainencesSuccess = maintainences => ({
    type: GET_MAINTAINENCES_SUCCESS,
    payload: maintainences,
  })
  
  export const addNewMaintainence = maintainence => ({
    type: ADD_NEW_MAINTAINENCE,
    payload: maintainence,
  })
  
  export const addMaintainenceSuccess = maintainence => ({
    type: ADD_MAINTAINENCE_SUCCESS,
    payload: maintainence,
  })
  
  export const addMaintainenceFail = error => ({
    type: ADD_MAINTAINENCE_FAIL,
    payload: error,
  })
  
  export const updateMaintainence = (id,maintainence) => ({
    type: UPDATE_MAINTAINENCE,
    payload: {id,maintainence},
  })
  
  export const updateMaintainenceSuccess = maintainence => ({
    type: UPDATE_MAINTAINENCE_SUCCESS,
    payload: maintainence,
  })
  
  export const updateMaintainenceFail = error => ({
    type: UPDATE_MAINTAINENCE_FAIL,
    payload: error,
  })
  
  export const deleteMaintainence = maintainence => ({
    type: DELETE_MAINTAINENCE,
    payload: maintainence,
  })
  
  export const deleteMaintainenceSuccess = maintainence => ({
    type: DELETE_MAINTAINENCE_SUCCESS,
    payload: maintainence,
  })
  
  export const deleteMaintainenceFail = error => ({
    type: DELETE_MAINTAINENCE_FAIL,
    payload: error,
  })
  
  export const getMaintainencesFail = error => ({
    type: GET_MAINTAINENCES_FAIL,
    payload: error,
  })
  
  export const getMaintainenceDetail = maintainenceId => ({
    type: GET_MAINTAINENCE_DETAIL,
    maintainenceId,
  })
  
  export const getMaintainenceDetailSuccess = maintainenceDetails => ({
    type: GET_MAINTAINENCE_DETAIL_SUCCESS,
    payload: maintainenceDetails,
  })
  
  export const getMaintainenceDetailFail = error => ({
    type: GET_MAINTAINENCE_DETAIL_FAIL,
    payload: error,
  })
  