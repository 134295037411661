import React, { useEffect, useState } from "react"

import { Link } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import Swal from "sweetalert2"
import {
  FormFeedback,
  Table,
  Col,
  Row,
  Label,
  Form,
  UncontrolledTooltip,
  Input,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"

import {
  deleteCompanyLocation,
  getCities,
  getCompanyLocationList,
  getCountries,
  getStates,
} from "store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import LargeModal from "./editCompanyLocationModal"

import DeleteModal from "components/Common/DeleteModal"
import { addCompanyLocation } from "helpers/fakebackend_helper"

const CompanyLocation = () => {
  const [editId, setEditId] = useState()
  const [modal_large, setmodal_large] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }
  const dispatch = useDispatch()

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const getCompanyLocationListState = state => state.companies.location.data
  const location = useSelector(getCompanyLocationListState) || []
  const [companyId, setCompanyId] = useState()

  const getCompanyLocation = () => {
    const url = new URL(window.location.href)
    const companyId = url.pathname.split("/view-company/")[1]
    try {
      dispatch(getCompanyLocationList(companyId))
    } catch (error) {
      console.error(error)
    }
  }
  const fetchData = () => {
    const url = new URL(window.location.href)
    const companyId = url.pathname.split("/view-company/")[1]
    setCompanyId(companyId)
  }
  useEffect(() => {
    fetchData()
    getCompanyLocation()
    dispatch(getCountries())
  }, [dispatch])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_id: companyId,
      location_title: "",
      gst_no: "",
      state_id: "",
      city_id: "",
      country_id: "IN",
    },

    validationSchema: Yup.object({
      company_id: Yup.number(),
      location_title: Yup.string().required("Please Enter Address"),
      gst_no: Yup.string()
        .matches(
          /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/,
          "Invalid GST number"
        )
        .required("Please Enter GST Number"),
      state_id: Yup.number().required("Please Enter State"),
      city_id: Yup.number().required("Please Enter City"),
      country_id: Yup.string().required("Please Select Country"),
    }),
    onSubmit: async values => {
      let loadingSwal

      try {
        loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })

        const response = await addCompanyLocation(values)

        if (response && response.status === true) {
          loadingSwal.close()

          Swal.fire({
            title: "Success",
            icon: "success",
            text: "You Have Succesfully Added Location to Your Company.",
          }).then(() => {
            dispatch(getCompanyLocationList(companyId))
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "Failure",
            icon: "error",
            text: "There was an Issue While Adding Location to Company.",
          })
        }
      } catch (error) {
        console.error("Error:", error)
        if (loadingSwal) {
          loadingSwal.close()
        }

        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response?.data?.message ||
            "An error occurred while Adding Location to Company.",
        })
      }
    },
  })

  const [countryName, setCountryName] = useState(validation.values.country_id)
  const [stateId, setStateId] = useState("")
  useEffect(() => {
    dispatch(getStates(countryName))
  }, [countryName])
  useEffect(() => {
    if (validation.values.state_id) {
      dispatch(getCities(stateId))
    }
  }, [stateId])

  useEffect(() => {
    setCountryName(validation.values.country_id)
    setStateId(validation.values.state_id)
  }, [validation])

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = companyId => {
    setDeleteId({ id: companyId })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteCompanyLocation(deleteId.id))

    setDeleteModal(false)
    dispatch(getCompanyLocationList(companyId))
    dispatch(getCompanyLocationList(companyId))
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  const handleEditModal = id => {
    setEditId(id)
    toggleModalLarge()
  }
  return (
    <React.Fragment>
      <LargeModal isOpen={modalLarge} toggle={toggleModalLarge} id={editId} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <Row>
          <Col>
            <CardTitle> Added Locations</CardTitle>
          </Col>
          <Col className="text-end">
            {permission
              .filter(
                perm =>
                  perm.page === "/add-company-location/:id" && perm.access === 1
              )
              .map(index => (
                <Link
                  key={index}
                  to={`/settings/add-company-location/${companyId}`}
                  className="text-danger ms-2 "
                >
                  <button type="submit" className="btn btn-primary btn-sm">
                    Add Location
                  </button>
                </Link>
              ))}
          </Col>
        </Row>
        {permission
              .filter(
                perm =>
                  perm.page === "company-location-list" && perm.access === 1
              )
              .map(index => (<CardBody key={index}>
          <div className="table-responsive" style={{ overflowX: "none" }}>
            <Table className="table align-middle table-nowrap table-hover mb-0">
              <thead>
                <tr>
                  <th scope="col">Address</th>
                  <th scope="col">Country</th>
                  <th scope="col">State</th>
                  <th scope="col">City</th>
                  <th scope="col">GST No.</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {location?.length > 0 ? (
                <tbody>
                  {location?.map((item, key) => (
                    <tr key={key}>
                      <td>{item.location_title}</td>
                      <td>{item.country_name}</td>
                      <td>{item.state_name}</td>
                      <td>{item.city_name}</td>
                      <td>{item.gst_no}</td>

                      <td>
                        <div className="d-flex gap-3">
                          {permission
                            .filter(
                              perm =>
                                perm.page === "/edit-company-location/:id" &&
                                perm.access === 1
                            )
                            .map(index => (
                              <Link
                                key={index}
                                className="text-danger"
                                to={`/settings/edit-company-location/${item.id}`}
                              >
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  style={{ color: "#3366cc" }}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="edittooltip"
                                >
                                  Edit
                                </UncontrolledTooltip>
                              </Link>
                            ))}
                          {permission
                            .filter(
                              perm =>
                                perm.page === "delete-company-location" &&
                                perm.access === 1
                            )
                            .map(index => (
                              <Link
                                key={index}
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                  onClickDelete(item.id)
                                }}
                              >
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  style={{ color: "#3366cc" }}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="deletetooltip"
                                >
                                  Delete
                                </UncontrolledTooltip>
                              </Link>
                            ))}
                          {permission
                            .filter(
                              perm =>
                                perm.page === "/view-company-location/:id" &&
                                perm.access === 1
                            )
                            .map(index => (
                              <Link
                                key={index}
                                to={`/settings/view-company-location/${item.id}`}
                                className="text-danger"
                              >
                                <i
                                  className="mdi mdi-chevron-right font-size-18"
                                  id="addtooltip"
                                  style={{ color: "#3366cc" }}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="addtooltip"
                                >
                                  View
                                </UncontrolledTooltip>
                              </Link>
                            ))}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="4" className="text-center">
                      No Records Found
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        </CardBody>))}
      </Card>
    </React.Fragment>
  )
}

export default CompanyLocation
