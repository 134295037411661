import {
  GET_COUNT_FOR_DASHBOARD,
  GET_COUNT_FOR_DASHBOARD_SUCCESS,
  GET_COUNT_FOR_DASHBOARD_FAIL,
  GET_VACANT_PROJECT_UNIT_LIST,
  GET_VACANT_PROJECT_UNIT_LIST_SUCCESS,
  GET_VACANT_PROJECT_UNIT_LIST_FAIL,
  ACTIVITY_FILTER_SUCCESS,
  ACTIVITY_FILTER_FAIL,
  ACTIVITY_FILTER,
  NEW_CUSTOMER_LIST,
  NEW_CUSTOMER_LIST_SUCCESS,
  NEW_CUSTOMER_LIST_FAIL,
  BILL_AMOUNT_LIST,
  BILL_AMOUNT_LIST_SUCCESS,
  BILL_AMOUNT_LIST_FAIL,
  PAYMENT_AMOUNT_LIST,
  PAYMENT_AMOUNT_LIST_SUCCESS,
  PAYMENT_AMOUNT_LIST_FAIL,
  NEW_UNIT_ASSIGNED_LIST,
  NEW_UNIT_ASSIGNED_LIST_SUCCESS,
  NEW_UNIT_ASSIGNED_LIST_FAIL,
  NEW_CUSTOMER_GRAPH,
  NEW_CUSTOMER_GRAPH_SUCCESS,
  NEW_CUSTOMER_GRAPH_FAIL,
  NEW_UNITS_ASSIGNED_GRAPH,
  NEW_UNITS_ASSIGNED_GRAPH_SUCCESS,
  NEW_UNITS_ASSIGNED_GRAPH_FAIL,
  BILL_AND_PAYMENT_GRAPH,
  BILL_AND_PAYMENT_GRAPH_SUCCESS,
  BILL_AND_PAYMENT_GRAPH_FAIL,
  CUSTOMER_OUTSTANTDING_LIST,
  CUSTOMER_OUTSTANTDING_LIST_SUCCESS,
  CUSTOMER_OUTSTANTDING_LIST_FAIL,
  NEW_VENDOR_LIST,
  NEW_VENDOR_LIST_SUCCESS,
  NEW_VENDOR_LIST_FAIL,
  VENDOR_OUTSTANDING_LIST,
  VENDOR_OUTSTANDING_LIST_SUCCESS,
  VENDOR_OUTSTANDING_LIST_FAIL,
  VENDOR_PAYMENT_LIST,
  VENDOR_PAYMENT_LIST_SUCCESS,
  VENDOR_PAYMENT_LIST_FAIL,
  VENDOR_INVOICE_LIST,
  VENDOR_INVOICE_LIST_SUCCESS,
  VENDOR_INVOICE_LIST_FAIL,
  VENDOR_TICKET_LIST,
  VENDOR_TICKET_LIST_SUCCESS,
  VENDOR_TICKET_LIST_FAIL,
  STATUS_WISE_TICKET_LIST,
  STATUS_WISE_TICKET_LIST_SUCCESS,
  STATUS_WISE_TICKET_LIST_FAIL,
} from "./actionTypes"

export const getCountForDashboard = () => ({
  type: GET_COUNT_FOR_DASHBOARD,
})

export const getCountForDashboardSuccess = count => ({
  type: GET_COUNT_FOR_DASHBOARD_SUCCESS,
  payload: count,
})

export const getCountForDashboardFail = error => ({
  type: GET_COUNT_FOR_DASHBOARD_FAIL,
  payload: error,
})

export const getVacantProjectUnitList = () => ({
  type: GET_VACANT_PROJECT_UNIT_LIST,
})

export const getVacantProjectUnitListSuccess = vacantProjectUnitList => ({
  type: GET_VACANT_PROJECT_UNIT_LIST_SUCCESS,
  payload: vacantProjectUnitList,
})

export const getVacantProjectUnitListFail = error => ({
  type: GET_VACANT_PROJECT_UNIT_LIST_FAIL,
  payload: error,
})

export const ActivityFilter = activity => ({
  type: ACTIVITY_FILTER,
  payload: activity,
})

export const ActivityFilterSuccess = activity => ({
  type: ACTIVITY_FILTER_SUCCESS,
  payload: activity,
})

export const ActivityFilterFail = error => ({
  type: ACTIVITY_FILTER_FAIL,
  payload: error,
})

export const newCustomerList = newCustomerList => ({
  type: NEW_CUSTOMER_LIST,
  payload: newCustomerList,
})

export const newCustomerListSuccess = newCustomerList => ({
  type: NEW_CUSTOMER_LIST_SUCCESS,
  payload: newCustomerList,
})

export const newCustomerListFail = error => ({
  type: NEW_CUSTOMER_LIST_FAIL,
  payload: error,
})

export const newUnitAssignedList = unitList => ({
  type: NEW_UNIT_ASSIGNED_LIST,
  payload: unitList,
})

export const newUnitAssignedListSuccess = unitList => ({
  type: NEW_UNIT_ASSIGNED_LIST_SUCCESS,
  payload: unitList,
})

export const newUnitAssignedListFail = error => ({
  type: NEW_UNIT_ASSIGNED_LIST_FAIL,
  payload: error,
})

export const billAmountList = billList => ({
  type: BILL_AMOUNT_LIST,
  payload: billList,
})

export const billAmountListSuccess = billList => ({
  type: BILL_AMOUNT_LIST_SUCCESS,
  payload: billList,
})

export const billAmountListFail = error => ({
  type: BILL_AMOUNT_LIST_FAIL,
  payload: error,
})

export const paymentAmountList = paymentList => ({
  type: PAYMENT_AMOUNT_LIST,
  payload: paymentList,
})

export const paymentAmountListSuccess = paymentList => ({
  type: PAYMENT_AMOUNT_LIST_SUCCESS,
  payload: paymentList,
})

export const paymentAmountListFail = error => ({
  type: PAYMENT_AMOUNT_LIST_FAIL,
  payload: error,
})

export const newCustomerGraph = customerGraph => ({
  type: NEW_CUSTOMER_GRAPH,
  payload: customerGraph,
})

export const newCustomerGraphSuccess = customerGraph => ({
  type: NEW_CUSTOMER_GRAPH_SUCCESS,
  payload: customerGraph,
})

export const newCustomerGraphFail = error => ({
  type: NEW_CUSTOMER_GRAPH_FAIL,
  payload: error,
})

export const newUnitAssignedGraph = unitAssignedGraph => ({
  type: NEW_UNITS_ASSIGNED_GRAPH,
  payload: unitAssignedGraph,
})

export const newUnitAssignedGraphSuccess = unitAssignedGraph => ({
  type: NEW_UNITS_ASSIGNED_GRAPH_SUCCESS,
  payload: unitAssignedGraph,
})

export const newUnitAssignedGraphFail = error => ({
  type: NEW_UNITS_ASSIGNED_GRAPH_FAIL,
  payload: error,
})

export const billAndPaymentGraph = billPaymentGraph => ({
  type: BILL_AND_PAYMENT_GRAPH,
  payload: billPaymentGraph,
})

export const billAndPaymentGraphSuccess = billPaymentGraph => ({
  type: BILL_AND_PAYMENT_GRAPH_SUCCESS,
  payload: billPaymentGraph,
})

export const billAndPaymentGraphFail = error => ({
  type: BILL_AND_PAYMENT_GRAPH_FAIL,
  payload: error,
})
///////////
export const customerOutstandingList = data => ({
  type: CUSTOMER_OUTSTANTDING_LIST,
  payload: data,
})

export const customerOutstandingListSuccess = customerOutstandingList => ({
  type: CUSTOMER_OUTSTANTDING_LIST_SUCCESS,
  payload: customerOutstandingList,
})

export const customerOutstandingListFail = error => ({
  type: CUSTOMER_OUTSTANTDING_LIST_FAIL,
  payload: error,
})

//vendor activity

export const newVendorList = data => ({
  type: NEW_VENDOR_LIST,
  payload: data,
})

export const newVendorListSuccess = newVendorList => ({
  type: NEW_VENDOR_LIST_SUCCESS,
  payload: newVendorList,
})

export const newVendorListFail = error => ({
  type: NEW_VENDOR_LIST_FAIL,
  payload: error,
})
//
export const vendorOutstandingList = data => ({
  type: VENDOR_OUTSTANDING_LIST,
  payload: data,
})

export const vendorOutstandingListSuccess = vendorOutstandingList => ({
  type: VENDOR_OUTSTANDING_LIST_SUCCESS,
  payload: vendorOutstandingList,
})

export const vendorOutstandingListFail = error => ({
  type: VENDOR_OUTSTANDING_LIST_FAIL,
  payload: error,
})
//
export const vendorPaymentList = data => ({
  type: VENDOR_PAYMENT_LIST,
  payload: data,
})

export const vendorPaymentListSuccess = vendorPaymentList => ({
  type: VENDOR_PAYMENT_LIST_SUCCESS,
  payload: vendorPaymentList,
})

export const vendorPaymentListFail = error => ({
  type: VENDOR_PAYMENT_LIST_FAIL,
  payload: error,
})
//
export const vendorInvoiceList = data => ({
  type: VENDOR_INVOICE_LIST,
  payload: data,
})

export const vendorInvoiceListSuccess = vendorInvoiceList => ({
  type: VENDOR_INVOICE_LIST_SUCCESS,
  payload: vendorInvoiceList,
})

export const vendorInvoiceListFail = error => ({
  type: VENDOR_INVOICE_LIST_FAIL,
  payload: error,
})
//
export const vendorTicketList = data => ({
  type: VENDOR_TICKET_LIST,
  payload: data,
})

export const vendorTicketListSuccess = vendorTicketList => ({
  type: VENDOR_TICKET_LIST_SUCCESS,
  payload: vendorTicketList,
})

export const vendorTicketListFail = error => ({
  type: VENDOR_TICKET_LIST_FAIL,
  payload: error,
})
//
export const statusWiseTicketList = data => ({
  type: STATUS_WISE_TICKET_LIST,
  payload: data,
})

export const statusWiseTicketListSuccess = vendorTicketList => ({
  type: STATUS_WISE_TICKET_LIST_SUCCESS,
  payload: vendorTicketList,
})

export const statusWiseTicketListFail = error => ({
  type: STATUS_WISE_TICKET_LIST_FAIL,
  payload: error,
})
