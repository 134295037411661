import {
  GET_REASON_MASTER_LIST_SUCCESS,
  GET_REASON_MASTER_LIST_FAIL,
  GET_REASON_MASTER_DETAIL_SUCCESS,
  GET_REASON_MASTER_DETAIL_FAIL,
  ADD_NEW_REASON_MASTER_SUCCESS,
  ADD_NEW_REASON_MASTER_FAIL,
  UPDATE_REASON_MASTER_SUCCESS,
  UPDATE_REASON_MASTER_FAIL,
  DELETE_REASON_MASTER_SUCCESS,
  DELETE_REASON_MASTER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  reason: [],
  addReason: [],
  reasonDetail: {},
  error: {},
  loading: true,
}

const reasonMaster = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REASON_MASTER_LIST_SUCCESS:
      return {
        ...state,
        reason: action.payload,
        loading: false,
      }

    case GET_REASON_MASTER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_REASON_MASTER_DETAIL_SUCCESS:
      return {
        ...state,
        reasonDetail: action.payload,
        loading: false,
      }

    case GET_REASON_MASTER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_REASON_MASTER_SUCCESS:
      return {
        ...state,
        addReason: [...state.addReason, action.payload],
      }

    case ADD_NEW_REASON_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_REASON_MASTER_SUCCESS:
      return {
        ...state,
        addReason: state.addReason.map(reason =>
          reason.id === action.payload.id ? { ...reason, ...action.payload } : reason
        ),
      }

    case UPDATE_REASON_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_REASON_MASTER_SUCCESS:
      return {
        ...state,
        reason: state.reason.data.filter(
          reason => reason.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_REASON_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default reasonMaster
