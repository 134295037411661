import React, { useEffect, useState } from "react"
import SimpleBar from "simplebar-react"
import ReactApexChart from "react-apexcharts"
import * as Yup from "yup"
import Swal from "sweetalert2"
import {
  Card,
  Col,
  CardBody,
  Table,
  Row,
  Label,
  FormFeedback,
  Badge,
} from "reactstrap"
import { useFormik } from "formik"
import NewCustomerModal from "../Modal/newCustomerModal"
import NewUnitsAssignedModal from "../Modal/newUnitsAssignedModal"
import NewBillsModal from "../Modal/newBillsModal"
import PaymentsModal from "../Modal/paymentsModal"
import { ActivityFilter } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import CustomerOustandingModal from "../Modal/customerOustandingModal"
import NewVendorModal from "../Modal/newVendorModal"
import VendorInvoiceModal from "../Modal/vendorInvoiceModal"
import VendorOutstandingModal from "../Modal/vendorOutstandingModal"
import VendorPaymentModal from "../Modal/vendorPaymentModal"
import VendorTicketAssigned from "../Modal/vendorTicketAssigned"

const category = () => {
  const dispatch = useDispatch()
  const [modalLarge, setModalLarge] = useState(false)
  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const [modalLarge2, setModalLarge2] = useState(false)
  const toggleModalLarge2 = () => setModalLarge2(!modalLarge2)
  const [modalLarge3, setModalLarge3] = useState(false)
  const toggleModalLarge3 = () => setModalLarge3(!modalLarge3)
  const [modalLarge4, setModalLarge4] = useState(false)
  const toggleModalLarge4 = () => setModalLarge4(!modalLarge4)
  
  const [modalLarge5, setModalLarge5] = useState(false)
  const toggleModalLarge5 = () => setModalLarge5(!modalLarge5)
  const [modalLarge6, setModalLarge6] = useState(false)
  const toggleModalLarge6 = () => setModalLarge6(!modalLarge6)
  const [modalLarge7, setModalLarge7] = useState(false)
  const toggleModalLarge7 = () => setModalLarge7(!modalLarge7)
  const [modalLarge8, setModalLarge8] = useState(false)
  const toggleModalLarge8 = () => setModalLarge8(!modalLarge8)
  const [modalLarge9, setModalLarge9] = useState(false)
  const toggleModalLarge9 = () => setModalLarge9(!modalLarge9)
  const [modalLarge10, setModalLarge10] = useState(false)
  const toggleModalLarge10 = () => setModalLarge10(!modalLarge10)


  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed

    initialValues: {
      filter: "This Year",
    },

    validationSchema: Yup.object({}),
  })

  useEffect(() => {
    dispatch(ActivityFilter({ filter: validation.values.filter }))
  }, [validation.values.filter])

  const countState = state => state.Dashboard.activityCount.counts
  const count = useSelector(countState) || []

  const formatNumberWithCommas = number => {
    // If number is null or empty, return it as it is
    if (!number) {
      return number
    }

    // Convert number to string
    let numStr = String(number)

    // Split the string into integer and decimal parts
    let parts = numStr.split(".")

    // Format the integer part with commas
    let integerPart = parts[0]
    let lastThreeDigits = integerPart.slice(-3) // Get the last three digits
    let remainingDigits = integerPart.slice(0, -3) // Get the remaining digits
    let formattedInteger =
      remainingDigits !== ""
        ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          lastThreeDigits
        : lastThreeDigits

    // Combine integer and decimal parts with a dot
    // let formattedNumber = formattedInteger + (parts[1] ? "." + parts[1] : "")

    return formattedInteger
  }



  return (
    <React.Fragment>
      <NewCustomerModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        filterResponse={validation.values.filter}
      />
      <NewUnitsAssignedModal
        isOpen={modalLarge2}
        toggle={toggleModalLarge2}
        filterResponse={validation.values.filter}
      />
      <NewBillsModal
        isOpen={modalLarge3}
        toggle={toggleModalLarge3}
        filterResponse={validation.values.filter}
      />
      <PaymentsModal
        isOpen={modalLarge4}
        toggle={toggleModalLarge4}
        filterResponse={validation.values.filter}
      />
      <CustomerOustandingModal
        isOpen={modalLarge5}
        toggle={toggleModalLarge5}
        filterResponse={validation.values.filter}
      />
      <NewVendorModal
        isOpen={modalLarge6}
        toggle={toggleModalLarge6}
        filterResponse={validation.values.filter}
      />
      <VendorInvoiceModal
        isOpen={modalLarge7}
        toggle={toggleModalLarge7}
        filterResponse={validation.values.filter}
      />
      <VendorOutstandingModal
        isOpen={modalLarge8}
        toggle={toggleModalLarge8}
        filterResponse={validation.values.filter}
      />
      <VendorPaymentModal
        isOpen={modalLarge9}
        toggle={toggleModalLarge9}
        filterResponse={validation.values.filter}
      />
      <VendorTicketAssigned
        isOpen={modalLarge10}
        toggle={toggleModalLarge10}
        filterResponse={validation.values.filter}
      />

      <Card style={{ height: "476px" }}>
        <CardBody>
          <Row>
            <Col lg={6}>
              <Row>
                <Col className="d-flex align-items-center">
                  <h4 className="card-title mb-4">Activity</h4>
                </Col>
              </Row>
              <div className="table-responsive mt-4">
                <Table className="table align-middle mb-2">
                  <tbody>
                    <tr>
                      <td>
                        <h5 className="font-size-16 mb-1">New Customer</h5>
                        <p className="text-muted mb-0">
                          Represents the number of new customers acquired.
                        </p>
                      </td>
                      <td></td>
                      <td
                        style={{ cursor: "pointer", textAlign: "end" }}
                        onClick={toggleModalLarge}
                      >
                        <p className="text-muted mb-1">Customer Count</p>
                        <h5 className="mb-0 text-primary">
                          {count && count.client_count}
                        </h5>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h5 className="font-size-16  mb-1">
                          New Units Assigned
                        </h5>
                        <p className="text-muted mb-0">
                          Indicates the quantity of units assigned to customers.
                        </p>
                      </td>
                      <td></td>
                      <td
                        style={{ cursor: "pointer", textAlign: "end" }}
                        onClick={toggleModalLarge2}
                      >
                        <p className="text-muted mb-1">Unit Count</p>
                        <h5 className="mb-0 mb-0 text-warning">
                          {count && count.client_project_unit_count}
                        </h5>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h5 className="font-size-16  mb-1">Sales Invoice</h5>
                        <p className="text-muted mb-0 ">
                          Denotes the total Amount of Invoice generated.
                        </p>
                      </td>
                      <td></td>
                      <td
                        style={{ cursor: "pointer", textAlign: "end" }}
                        onClick={toggleModalLarge3}
                      >
                        <p className="text-muted mb-1">Amount</p>
                        <h5 className="mb-0 mb-0 text-danger">
                          {count && formatNumberWithCommas(count.billing_total)}
                        </h5>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h5 className="font-size-16 mb-1">Receipt Amount</h5>
                        <p className="text-muted mb-0">
                          Reflects the total Amount of payments received.
                        </p>
                      </td>
                      <td></td>
                      <td
                        style={{ cursor: "pointer", textAlign: "end" }}
                        onClick={toggleModalLarge4}
                      >
                        <p className="text-muted mb-1">Amount</p>
                        <h5 className="mb-0 mb-0 text-success">
                          {count &&
                            formatNumberWithCommas(count.bill_payment_total)}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 className="font-size-16 mb-1">
                          Outstanding Amount
                        </h5>
                        <p className="text-muted mb-0">
                          Reflects the total Outstanding Amount.
                        </p>
                      </td>
                      <td></td>
                      <td
                        style={{ cursor: "pointer", textAlign: "end" }}
                        onClick={toggleModalLarge5}
                      >
                        <p className="text-muted mb-1">Amount</p>
                        <h5 className="mb-0 mb-0 text-secondary">
                          {count &&
                            formatNumberWithCommas(count.customer_outstanding)}
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col className="d-flex align-items-center"></Col>
                <Col lg={4}>
                  <div className="mb-1">
                    <select
                      name="filter"
                      className={`form-select ${
                        validation.touched.filter && validation.errors.filter
                          ? "is-invalid"
                          : ""
                      }`}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.filter}
                    >
                      <option value="This Week">This Week</option>
                      <option value="This Month">This Month</option>
                      <option value="This Year">This Year</option>
                      <option value="Previous Week">Previous Week</option>
                      <option value="Previous Month">Previous Month</option>
                      <option value="Previous Year">Previous Year</option>
                    </select>
                    {validation.touched.filter && validation.errors.filter ? (
                      <FormFeedback type="invalid">
                        {validation.errors.filter}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <div className="table-responsive mt-4">
                <Table className="table align-middle mb-2">
                  <tbody>
                    <tr>
                      <td>
                        <h5 className="font-size-16 mb-1">New Vendor</h5>
                        <p className="text-muted mb-0">
                          Represents the number of new Vendors onboarded.
                        </p>
                      </td>
                      <td></td>
                      <td
                        style={{ cursor: "pointer", textAlign: "end" }}
                        onClick={toggleModalLarge6}
                      >
                        <p className="text-muted mb-1">Vendor Count</p>
                        <h5 className="mb-0 text-primary">
                          {count && count.vendor_count}
                        </h5>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h5 className="font-size-16  mb-1">
                          New Tickets Assigned To Vendor
                        </h5>
                        <p className="text-muted mb-0">
                          Indicates the quantity of Tickets assigned to Vendors.
                        </p>
                      </td>
                      <td></td>
                      <td
                        style={{ cursor: "pointer", textAlign: "end" }}
                        onClick={toggleModalLarge10}
                      >
                        <p className="text-muted mb-1">Ticket Count</p>
                        <h5 className="mb-0 mb-0 text-warning">
                          {count && count.vendor_ticket_count}
                        </h5>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h5 className="font-size-16  mb-1">Vendor Invoice</h5>
                        <p className="text-muted mb-0 ">
                          Denotes the total Amount of Invoice generated.
                        </p>
                      </td>
                      <td></td>
                      <td
                        style={{ cursor: "pointer", textAlign: "end" }}
                        onClick={toggleModalLarge7}
                      >
                        <p className="text-muted mb-1">Amount</p>
                        <h5 className="mb-0 mb-0 text-danger">
                          {count &&
                            formatNumberWithCommas(count.vendor_invoice_total)}
                        </h5>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h5 className="font-size-16 mb-1">Vendor Payment </h5>
                        <p className="text-muted mb-0">
                          Reflects the total Amount of payments.
                        </p>
                      </td>
                      <td></td>
                      <td
                        style={{ cursor: "pointer", textAlign: "end" }}
                        onClick={toggleModalLarge9}
                      >
                        <p className="text-muted mb-1">Amount</p>
                        <h5 className="mb-0 mb-0 text-success">
                          {count &&
                            formatNumberWithCommas(count.vendor_payment_total)}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5 className="font-size-16 mb-1">
                          Vendor Outstanding Amount
                        </h5>
                        <p className="text-muted mb-0">
                          Reflects the total Outstanding Amount.
                        </p>
                      </td>
                      <td></td>
                      <td
                        style={{ cursor: "pointer", textAlign: "end" }}
                        onClick={toggleModalLarge8}
                      >
                        <p className="text-muted mb-1">Amount</p>
                        <h5 className="mb-0 mb-0 text-secondary">
                          {count &&
                            formatNumberWithCommas(count.vendor_outstanding)}
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default category
