import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./projectUnitsContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { getAllAssignedProjects } from "store/actions"

const ProjectUnitsApproval = () => {
  const navigate = useNavigate()

  //meta title
  document.title = "Client List "

  const dispatch = useDispatch()
  const [customer, setCustomer] = useState()
  // validation

  const [userList, setUserList] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const loadingState = state => state.clients.loading
  const loadingData = useSelector(loadingState)
  const clientsState = state => state.clients.projectUnits.project_units
  const data = useSelector(clientsState) || []
  const ClientProperties = createSelector(
    clientsState,
    loadingState,
    (clients, loading) => ({
      clients: clients,
      loading: loading,
    })
  )

  const { clients, loading } = useSelector(ClientProperties)

  useEffect(() => {
    const response = dispatch(getAllAssignedProjects())
    setIsEdit(false)
  }, [dispatch])

  useEffect(() => {
    setCustomer(clients)
    setIsEdit(false)
  }, [clients])

  useEffect(() => {
    if (!isEmpty(clients) && !!isEdit) {
      setCustomer(clients)
      setIsEdit(false)
    }
  }, [clients])

  const [isLoading, setLoading] = useState(true)

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Customer",
        accessor: "customer",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.customer_type === 1
                    ? "Individual"
                    : "Brand"}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Project",
        accessor: "project",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.project}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Project Unit",
        accessor: "project_unit",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.project_unit}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Agreement Duratiom",
        accessor: "agreement_duration",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.agreement_start_date} - {cellProps.row.original.agreement_end_date}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.approval_status}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                // to={`/client-details/${cellProps.row.original.id}`}
                to={`/clients/project-units-approval/view-project-units-approval/${cellProps.row.original.id}`}
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original
                  handleUserClick(userData)
                }}
              >
                <i
                  className="mdi mdi-chevron-right font-size-18"
                  id="viewtooltip"
                  style={{ fontSize: "18px", color: "#3366cc" }}
                />
                <UncontrolledTooltip placement="bottom" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    setModal(!modal)
  }

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteClient(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/clients")
    dispatch(getClientsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Clients" breadcrumbItem="Project Unit(s) Approval" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default ProjectUnitsApproval
