import {
  GET_CLIENT_USER_LIST_SUCCESS,
  GET_CLIENT_USER_LIST_FAIL,
  GET_CLIENT_USER_DETAIL_SUCCESS,
  GET_CLIENT_USER_DETAIL_FAIL,
  ADD_NEW_CLIENT_USER_SUCCESS,
  ADD_NEW_CLIENT_USER_FAIL,
  UPDATE_CLIENT_USER_SUCCESS,
  UPDATE_CLIENT_USER_FAIL,
  DELETE_CLIENT_USER_SUCCESS,
  DELETE_CLIENT_USER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  user: [],
  addNewClientUser: [],
  editClientUser: [],
  userDetail: {},
  error: {},
  loading: true,
}

const clientUser = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT_USER_LIST_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      }

    case GET_CLIENT_USER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CLIENT_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
        loading: false,
      }

    case GET_CLIENT_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_CLIENT_USER_SUCCESS:
      return {
        ...state,
        addNewClientUser: [...state.addNewClientUser, action.payload],
        addNewClientUser: [...state.addNewClientUser, action.payload],
        addNewClientUser: [...state.addNewClientUser, action.payload],
      }

    case ADD_NEW_CLIENT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CLIENT_USER_SUCCESS:
      return {
        ...state,
        editClientUser: state.editClientUser.map(user =>
          user.id === action.payload.id ? { ...user, ...action.payload } : user
        ),
      }

    case UPDATE_CLIENT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CLIENT_USER_SUCCESS:
     
      return {
        ...state,
        user: state.user.client_users.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_CLIENT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default clientUser
