/* ROLES */
export const GET_ROLES = "GET_ROLES"
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS"
export const GET_ROLES_FAIL = "GET_ROLES_FAIL"

/* ROLES */
export const GET_MODULES = "GET_MODULES"
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS"
export const GET_MODULES_FAIL = "GET_MODULES_FAIL"

/* ROLES */
export const GET_MANAGE_PERMISSION = "GET_MANAGE_PERMISSION"
export const GET_MANAGE_PERMISSION_SUCCESS = "GET_MANAGE_PERMISSION_SUCCESS"
export const GET_MANAGE_PERMISSION_FAIL = "GET_MANAGE_PERMISSION_FAIL"

/* ROLES DETAIL*/
export const GET_ROLE_DETAIL = "GET_ROLE_DETAIL"
export const GET_ROLE_DETAIL_SUCCESS = "GET_ROLE_DETAIL_SUCCESS"
export const GET_ROLE_DETAIL_FAIL = "GET_ROLE_DETAIL_FAIL"

/**
 * add user
 */
export const ADD_NEW_ROLE = "ADD_NEW_ROLE"
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS"
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL"

/**
 * Edit user
 */
export const UPDATE_ROLE = "UPDATE_ROLE"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL"

/**
 * Delete user
 */
export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS"
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL"

