import React, { useEffect, useState } from "react"
import { Col, Button, Row, Input, Label } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"

import {
  getBillHistoryForVendorPayment,
  getBillHistoryList,
  getCompanyFilterForVendorPayment,
  getProjectFilterForVendorPayment,
  getProposals,
  getVendorFilterForVendorPayment,
  getVendorsList,
} from "store/actions"
import { getGoodsServiceList } from "helpers/fakebackend_helper"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  const vendorFilterState = state => state.vendors.vendorsList.data

  // const vendorFilterState = state => state.VendorPaymentList.vendorFilter.data
  const vendorList = useSelector(vendorFilterState) || []
  const projectstate = state => state.VendorPaymentList.projectFilter.data
  const projectList = useSelector(projectstate) || []
  const companystate = state => state.VendorPaymentList.companyFilter.data
  const companyList = useSelector(companystate) || []

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [project, setProject] = useState("")
  const [company, setCompany] = useState("")
  const [vendor, setVendor] = useState("")
  const [proposal_status, setProposalStatus] = useState("")
  const [status, setStatus] = useState("")
  const [good_services, setGoodsServices] = useState("")

  const dispatch = useDispatch()

  const handleSelectVendor = ele => {
    const value = ele.value
    setVendor(value)
  }
  const handleSelectProject = ele => {
    const value = ele.value
    setProject(value)
  }
  const handleSelectCompany = ele => {
    const value = ele.value
    setCompany(value)
  }
  const handleSelectStatus = ele => {
    const value = ele.value
    setProposalStatus(value)
  }
  

  const handleSearch = () => {
    const value = {
      project_id: project,
      vendor_id: vendor,
      proposal_status: proposal_status,
      proposal_type_id: good_services,
    }
    dispatch(getProposals(value))
  }

  useEffect(() => {
    if (user) {
      dispatch(getProjectFilterForVendorPayment(user.user_id))
    }
  }, [user])
  useEffect(() => {
    dispatch(getVendorsList())

  }, [dispatch])

 
  const [goodsServicesList , setGoodsServicesList] = useState([])

  useEffect(() => {
    const getGoodsServices = async () => {
      try {
       
        const response = await getGoodsServiceList()
        setGoodsServicesList(response.data)
      } catch (error) {
        console.error("Failed to fetch accepted proposals", error)
      }
    }

      getGoodsServices()
    
  }, [])

  const handleSelectGoodsServices = ele => {
    const value = ele.value
    setGoodsServices(value)
  }
  return (
    <React.Fragment>
      
      <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Project"
          onChange={e => handleSelectProject(e.target)}
        >
          <option value="">Select Project</option>
          {(projectList || []).map(item => (
            <option key={item.id} value={item.id}>
              {item.project_name}
            </option>
          ))}
        </select>
      </Col>
      <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Vendor"
          onChange={e => handleSelectVendor(e.target)}
        >
          <option value="">Select Vendor</option>
          {(vendorList || []).map(item => (
            <option key={item.vendor_id} value={item.vendor_id}>
              {item.vendor_name}{" "}( {item.vendor_code} )
            </option>
          ))}
        </select>
      </Col>
      <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Goods/Services Category"
          onChange={e => handleSelectGoodsServices(e.target)}
        >
          <option value="">Select Goods & Services Category</option>
          {(goodsServicesList || []).map(proposal => (
            <option key={proposal.id} value={proposal.id}>
              {proposal.proposal_type}
            </option>
          ))}
        </select>
      </Col>
      <Col xxl={2} lg={1}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          onChange={e => handleSelectStatus(e.target)}
        >
          <option value=''>Proposal Status</option>

          <option value="">All</option>
          <option value="1">Approved</option>
          <option value="2">Rejected</option>
          <option value="0">Pending</option>
          <option value="3">Billed</option>

        </select>
      </Col>
      

      <Col xxl={1} lg={1} className="mb-3">
        <div className="mb-3 mb-xxl-0">
          <button
            type="button"
            className="btn btn-soft-secondary w-100"
            onClick={handleSearch}
          >
            <i className="mdi mdi-filter-outline align-middle"></i> Submit
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default JobListGlobalFilter
