import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  getPastEmploymentDetail,
  getCountries,
  getStates,
  getCities,
  getAssignedProjectUnit,
  getProjectListForClient,
  getProjectUnitListForClient,
  getInvoiceTypeMasterClientList,
  getSacHsnCodeClient,
  getApplicableRateClientList,
} from "store/actions"
import {
  addAssignedProjectUnit,
  addNewRenewal,
  updateAssignedProjectUnit,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"

const RenewAssignedProjectUnit = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [assignedProjectId, setAssignedProjectId] = useState()
  const [pastEmploymentData, setPastEmploymentData] = useState()
  const projectListState = state => state.clients.projectList.project_units
  const projectListData = useSelector(projectListState) || []
  const projectUnitListState = state =>
    state.clients.projectUnitList.project_unit
  const projectUnitListData = useSelector(projectUnitListState) || []
  const InvoiceMasterState = state => state.clients.invoiceTypeMasterList.data
  const invoiceTypeMasterList = useSelector(InvoiceMasterState) || []
  const HSNMasterState = state => state.clients.codesClient.data
  const sacHsnCode = useSelector(HSNMasterState) || []
  const applicableRateListState = state => state.clients.applicableRateList.data
  const applicableRateList = useSelector(applicableRateListState) || []
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const AssignedProjectState = state => state.clients.pastEmp?.project_unit
  const AssignedProjectData = useSelector(AssignedProjectState) || []

  const editData = () => {
    const url = new URL(window.location.href)
    const assignedProjectId = url.pathname.split("/renew-project-unit/")[1]
    setAssignedProjectId(assignedProjectId)
    setIsEditMode(true)
    const response = dispatch(getAssignedProjectUnit(assignedProjectId))
    setPastEmploymentData(response)
  }

  useEffect(() => {
    editData()
  }, [])

  useEffect(() => {
    dispatch(getInvoiceTypeMasterClientList())
    dispatch(getSacHsnCodeClient())
    dispatch(getApplicableRateClientList())
  }, [dispatch])

  useEffect(() => {
    dispatch(getProjectListForClient(user.user_id))
  }, [dispatch])

  const [selectedOption, setSelectedOption] = useState(
    Boolean(AssignedProjectData?.is_active)
  )
  const [rows, setRows] = useState([{ id: 1 }])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      old_id: (AssignedProjectData && AssignedProjectData.id) || "",
      client_id: (AssignedProjectData && AssignedProjectData.client_id) || "",
      project: (AssignedProjectData && AssignedProjectData.project) || "",
      project_unit:
        (AssignedProjectData && AssignedProjectData.project_unit) || "",
      unit_code: (AssignedProjectData && AssignedProjectData.unit_code) || "",
      rent_escalation_percentage:
        (AssignedProjectData &&
          AssignedProjectData.rent_escalation_percentage) ||
        "",
      minimum_monthly_rent: "",

      revenue_share:
        (AssignedProjectData && AssignedProjectData.revenue_share) || "",
      revenue_share_percentage:
        (AssignedProjectData && AssignedProjectData.revenue_share_percentage) ||
        "",
      rent_payment_schedule:
        (AssignedProjectData && AssignedProjectData.rent_payment_schedule) ||
        "",
      customer_lock_in_period:
        (AssignedProjectData && AssignedProjectData.customer_lock_in_period) ||
        "",

      notice_period:
        (AssignedProjectData && AssignedProjectData.notice_period) || "",

      agreement_start_date: "",
      agreement_end_date: "",

      rent_escalation_period:
        (AssignedProjectData && AssignedProjectData.rent_escalation_period) ||
        "",
      registration_cost_for_agreement_on_company:
        (AssignedProjectData &&
          AssignedProjectData.registration_cost_for_agreement_on_company) ||
        "",
      brand_name: (AssignedProjectData && AssignedProjectData.brand_name) || "",
      security_deposit_for_rent:
        (AssignedProjectData &&
          AssignedProjectData.security_deposit_for_rent) ||
        "",
      security_deposit_for_electricity:
        (AssignedProjectData &&
          AssignedProjectData.security_deposit_for_electricity) ||
        "",
      security_deposit_for_maintenance:
        (AssignedProjectData &&
          AssignedProjectData.security_deposit_for_maintenance) ||
        "",
      document: "",
      document_url: (AssignedProjectData && AssignedProjectData.document) || "",
      // billing_components:
      //   (AssignedProjectData && AssignedProjectData.billing_components) || "",
      billing_components:
        AssignedProjectData?.billing_components?.map(component => ({
          id: component?.id || "",
          invoice_type_id: component?.invoice_type_id || "",
          description: component?.description || "",
          sac_hsm_id: component?.sac_hsm_id || "",
          sac_hsn: component?.sac_hsn?.sac_hsm_code || "",
          amount: component?.amt_per_unit || 0,
          gst_percentage: component?.gst_percentage || "",
          check: component?.check || 0,
        })) || [],
    },

    validationSchema: Yup.object({
      project: Yup.string().required("Please Select Project"),
      brand_name: Yup.string().required("Please Enter Brand Name"),
      security_deposit_for_rent: Yup.string().required(
        "Please Enter Security Deposit for Rent "
      ),
      security_deposit_for_electricity: Yup.string().required(
        "Please Enter Security Deposit for Electricity"
      ),
      security_deposit_for_maintenance: Yup.string().required(
        "Please Enter Security Deposit for Maintenance"
      ),

      project_unit: Yup.string().required("Please Select Project Unit"),
      minimum_monthly_rent: Yup.string(),
      revenue_share: Yup.string().required("Please Select Revenue Share"),
      revenue_share_percentage: Yup.string().required(
        "Please Enter Revenue Share Percentage(%)"
      ),
      rent_payment_schedule: Yup.string().required(
        "Please Select Rent Payment Schedule"
      ),
      customer_lock_in_period: Yup.string().required(
        "Please Enter Customer Lock in Period in Months"
      ),

      notice_period: Yup.string().required(
        "Please Enter Notice Period in Months"
      ),
      agreement_start_date: Yup.date().required(
        "Please Select Agreement Start Date"
      ),
      agreement_end_date: Yup.date().required(
        "Please Select Agreement End Date"
      ),
      rent_escalation_percentage: Yup.string(),
      rent_escalation_period: Yup.string(),
      registration_cost_for_agreement_on_company: Yup.string(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        const response = await addNewRenewal(values.client_id, values)

        if (response && response.status === true) {
          loadingSwal.close()
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Assigned Project Unit Agreement has been Renewed.",
          }).then(result => {
            if (result.isConfirmed) {
              navigate(-1)
            }
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "failure",
            icon: "error",
            text: "Your Assigned Project Unit Agreement has not been saved.",
          })
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  useEffect(() => {
    if (validation.values.rent_escalation_percentage && AssignedProjectData?.minimum_monthly_rent) {
      const escalationPercentage = Number(
        validation.values.rent_escalation_percentage
      )
      const originalRent = Number(
        AssignedProjectData?.minimum_monthly_rent || 0
      )
      const updatedMinimumMonthlyRent =
        originalRent + (originalRent * escalationPercentage) / 100

      validation.setFieldValue(
        "minimum_monthly_rent",
        updatedMinimumMonthlyRent
      )
    }
  }, [
    validation.values.rent_escalation_percentage,
    AssignedProjectData?.minimum_monthly_rent,
  ])

  console.log(validation.values)
  const handleChange = event => {
    setSelectedOption(event.target.value)

    validation.setFieldValue("approval_status", event.target.value)
  }
  function handlePreview(file) {
    if (file) {
      window.open(file, "_blank")
    }
  }

  useEffect(() => {
    if (validation.values.project !== "") {
      dispatch(getProjectUnitListForClient(validation.values.project))
    }
  }, [dispatch, validation.values.project])

  useEffect(() => {
    if (invoiceTypeMasterList) {
      // Update rows state based on the length of items array
      setRows(
        [...Array(invoiceTypeMasterList.length)].map((_, index) => ({
          id: index + 1,
        }))
      )
    }
  }, [invoiceTypeMasterList])

  const removeRow = id => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete this record?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      cancelButtonColor: "#6c757d", // Change this to your desired color
      confirmButtonColor: "#dc3545", // Change this to your desired color
    }).then(result => {
      if (result.isConfirmed) {
        const updatedRows = rows.filter(row => row.id !== id)
        setRows(updatedRows)
      }
    })
  }
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <CardTitle className="h4" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 mb-3">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  Renew
                </div>
              </CardTitle>
            </Col>
            <Col className="text-end">
              <CardTitle className="h4" style={{ fontSize: "15px" }}>
                {`${AssignedProjectData?.project_name} -> ${AssignedProjectData?.client_name} -> ${AssignedProjectData?.unit_code}`}
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form
                autoComplete="off"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="project">Project*</Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.project}
                            name="project"
                            disabled
                            className={`form-select ${
                              validation.touched.project &&
                              validation.errors.project
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Project</option>
                            {projectListData.map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project &&
                            validation.errors.project && (
                              <div className="invalid-feedback">
                                {validation.errors.project}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="brand_name">Brand*</Label>
                          <Input
                            type="text"
                            name="brand_name"
                            className="form-control"
                            id="brand_name"
                            placeholder="Enter Brand Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.brand_name || ""}
                            invalid={
                              validation.touched.brand_name &&
                              validation.errors.brand_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.brand_name &&
                          validation.errors.brand_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.brand_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="project_unit">Project Unit*</Label>
                          <input
                            type="text"
                            disabled
                            readOnly={true}
                            value={validation.values.unit_code}
                            name="project_unit"
                            className={`form-control ${
                              validation.touched.project_unit &&
                              validation.errors.project_unit
                                ? "is-invalid"
                                : ""
                            }`}
                          />

                          {validation.touched.project_unit &&
                            validation.errors.project_unit && (
                              <div className="invalid-feedback">
                                {validation.errors.project_unit}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="minimum_monthly_rent">
                            Minimum Monthly Rent
                          </Label>
                          <Input
                            type="text"
                            name="minimum_monthly_rent"
                            className="form-control"
                            id="minimum_monthly_rent"
                            placeholder="Enter Minimum monthly rent for the unit"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.minimum_monthly_rent || ""}
                            invalid={
                              validation.touched.minimum_monthly_rent &&
                              validation.errors.minimum_monthly_rent
                                ? true
                                : false
                            }
                          />
                          <span
                            className="text-muted ms-1"
                            style={{ font: "menu" }}
                          >
                            {" "}
                            Rent is Automatically Calculated based on Escalation
                            Percentage
                          </span>
                          {validation.touched.minimum_monthly_rent &&
                          validation.errors.minimum_monthly_rent ? (
                            <FormFeedback type="invalid">
                              {validation.errors.minimum_monthly_rent}
                            </FormFeedback>
                          ) : null}

                          <FormFeedback type="invalid">
                            This is Automatically Calculated{" "}
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="revenue_share">Revenue Share*</Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.revenue_share}
                            name="revenue_share"
                            className={`form-select ${
                              validation.touched.revenue_share &&
                              validation.errors.revenue_share
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Duration</option>
                            <option value="1">Monthly</option>
                            <option value="2">Annually</option>
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="revenue_share_percentage">
                            Revenue Share (%)*
                          </Label>
                          <Input
                            type="text"
                            name="revenue_share_percentage"
                            className="form-control"
                            id="revenue_share_percentage"
                            placeholder="Enter Share in Percentage"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.revenue_share_percentage || ""
                            }
                            invalid={
                              validation.touched.revenue_share_percentage &&
                              validation.errors.revenue_share_percentage
                                ? true
                                : false
                            }
                          />
                          {validation.touched.revenue_share_percentage &&
                          validation.errors.revenue_share_percentage ? (
                            <FormFeedback type="invalid">
                              {validation.errors.revenue_share_percentage}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="rent_payment_schedule">
                            Rent Payment Schedule*
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.rent_payment_schedule}
                            name="rent_payment_schedule"
                            className={`form-select ${
                              validation.touched.rent_payment_schedule &&
                              validation.errors.rent_payment_schedule
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Duration</option>
                            <option value="1">Monthly</option>
                            <option value="2">Quaterly</option>{" "}
                            <option value="3">Half Yearly</option>{" "}
                            <option value="4">Yearly</option>{" "}
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="customer_lock_in_period">
                            Customer Lock-in period* (Months)
                          </Label>
                          <Input
                            type="text"
                            name="customer_lock_in_period"
                            className="form-control"
                            id="customer_lock_in_period"
                            placeholder="Number of Months"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.customer_lock_in_period || ""
                            }
                            invalid={
                              validation.touched.customer_lock_in_period &&
                              validation.errors.customer_lock_in_period
                                ? true
                                : false
                            }
                          />
                          {validation.touched.customer_lock_in_period &&
                          validation.errors.customer_lock_in_period ? (
                            <FormFeedback type="invalid">
                              {validation.errors.customer_lock_in_period}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="notice_period">
                            Notice period* (Months)
                          </Label>
                          <Input
                            type="text"
                            name="notice_period"
                            className="form-control"
                            id="notice_period"
                            placeholder="Number of Months"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.notice_period || ""}
                            invalid={
                              validation.touched.notice_period &&
                              validation.errors.notice_period
                                ? true
                                : false
                            }
                          />
                          {validation.touched.notice_period &&
                          validation.errors.notice_period ? (
                            <FormFeedback type="invalid">
                              {validation.errors.notice_period}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="security_deposit_for_rent">
                            Security Deposit for Rent*
                          </Label>
                          <Input
                            type="text"
                            name="security_deposit_for_rent"
                            className="form-control"
                            id="security_deposit_for_rent"
                            placeholder="Enter Security Deposit for Rent"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.security_deposit_for_rent || ""
                            }
                            invalid={
                              validation.touched.security_deposit_for_rent &&
                              validation.errors.security_deposit_for_rent
                                ? true
                                : false
                            }
                          />
                          {validation.touched.security_deposit_for_rent &&
                          validation.errors.security_deposit_for_rent ? (
                            <FormFeedback type="invalid">
                              {validation.errors.security_deposit_for_rent}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="security_deposit_for_electricity">
                            Security Deposit for Electricity*
                          </Label>
                          <Input
                            type="text"
                            name="security_deposit_for_electricity"
                            className="form-control"
                            id="security_deposit_for_electricity"
                            placeholder="Enter Security Deposit Amount for Electricity"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values
                                .security_deposit_for_electricity || ""
                            }
                            invalid={
                              validation.touched
                                .security_deposit_for_electricity &&
                              validation.errors.security_deposit_for_electricity
                                ? true
                                : false
                            }
                          />
                          {validation.touched
                            .security_deposit_for_electricity &&
                          validation.errors.security_deposit_for_electricity ? (
                            <FormFeedback type="invalid">
                              {
                                validation.errors
                                  .security_deposit_for_electricity
                              }
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="security_deposit_for_maintenance">
                            Security Deposit of Maintenance*
                          </Label>
                          <Input
                            type="text"
                            name="security_deposit_for_maintenance"
                            className="form-control"
                            id="security_deposit_for_maintenance"
                            placeholder="Enter Security Deposit for Maintenance"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values
                                .security_deposit_for_maintenance || ""
                            }
                            invalid={
                              validation.touched
                                .security_deposit_for_maintenance &&
                              validation.errors.security_deposit_for_maintenance
                                ? true
                                : false
                            }
                          />
                          {validation.touched
                            .security_deposit_for_maintenance &&
                          validation.errors.security_deposit_for_maintenance ? (
                            <FormFeedback type="invalid">
                              {
                                validation.errors
                                  .security_deposit_for_maintenance
                              }
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="rent_escalation_percentage">
                            Rent Escalation(%)
                          </Label>
                          <Input
                            type="text"
                            name="rent_escalation_percentage"
                            className="form-control"
                            id="rent_escalation_percentage"
                            placeholder="Enter Rent Escalation in Percentage"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.rent_escalation_percentage || ""
                            }
                            invalid={
                              validation.touched.rent_escalation_percentage &&
                              validation.errors.rent_escalation_percentage
                                ? true
                                : false
                            }
                          />
                          {validation.touched.rent_escalation_percentage &&
                          validation.errors.rent_escalation_percentage ? (
                            <FormFeedback type="invalid">
                              {validation.errors.rent_escalation_percentage}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="rent_escalation_period">
                            Rent Escalation Period (Months)
                          </Label>
                          <Input
                            type="text"
                            name="rent_escalation_period"
                            className="form-control"
                            id="rent_escalation_period"
                            placeholder="No. of months to be entered"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.rent_escalation_period || ""
                            }
                            invalid={
                              validation.touched.rent_escalation_period &&
                              validation.errors.rent_escalation_period
                                ? true
                                : false
                            }
                          />
                          {validation.touched.rent_escalation_period &&
                          validation.errors.rent_escalation_period ? (
                            <FormFeedback type="invalid">
                              {validation.errors.rent_escalation_period}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="registration_cost_for_agreement_on_company">
                            Registration cost for agreement on company
                          </Label>
                          <Input
                            type="text"
                            name="registration_cost_for_agreement_on_company"
                            className="form-control"
                            id="registration_cost_for_agreement_on_company"
                            placeholder="Enter Registration cost for agreement on company"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values
                                .registration_cost_for_agreement_on_company ||
                              ""
                            }
                            invalid={
                              validation.touched
                                .registration_cost_for_agreement_on_company &&
                              validation.errors
                                .registration_cost_for_agreement_on_company
                                ? true
                                : false
                            }
                          />
                          {validation.touched
                            .registration_cost_for_agreement_on_company &&
                          validation.errors
                            .registration_cost_for_agreement_on_company ? (
                            <FormFeedback type="invalid">
                              {
                                validation.errors
                                  .registration_cost_for_agreement_on_company
                              }
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="agreement_start_date">
                            Lease Agreement Start Date*
                          </Label>
                          <Input
                            name="agreement_start_date"
                            type="date"
                            className={`form-control ${
                              validation.touched.agreement_start_date &&
                              validation.errors.agreement_start_date
                                ? "is-invalid"
                                : ""
                            }`}
                            id="agreement_start_date"
                            placeholder="Enter Start Date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.agreement_start_date || ""}
                          />
                          {validation.touched.agreement_start_date &&
                          validation.errors.agreement_start_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.agreement_start_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="agreement_end_date">
                            Lease Agreement End Date*
                          </Label>
                          <Input
                            name="agreement_end_date"
                            type="date"
                            className={`form-control ${
                              validation.touched.agreement_end_date &&
                              validation.errors.agreement_end_date
                                ? "is-invalid"
                                : ""
                            }`}
                            id="agreement_end_date"
                            placeholder="Enter Start Date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.agreement_end_date || ""}
                          />
                          {validation.touched.agreement_end_date &&
                          validation.errors.agreement_end_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.agreement_end_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <Label htmlFor="document">
                          Upload Lease Agreement Document
                          <i
                            className="mdi mdi-email"
                            onClick={() =>
                              handlePreview(validation.values.document_url)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {validation.values.document_url
                              ? "PREVIEW DOCUMENT"
                              : ""}
                          </i>
                        </Label>
                        <input
                          type="file"
                          id="document"
                          name="document"
                          onChange={e => {
                            const selectedFile = e.target.files[0]
                            validation.setFieldValue(`document`, selectedFile)
                          }}
                          onBlur={validation.handleBlur}
                          className={`form-control ${
                            validation.touched.document &&
                            validation.errors.document
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {validation.touched.document &&
                          validation.errors.document && (
                            <div className="invalid-feedback">
                              {validation.errors.document}
                            </div>
                          )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-3">
                          Rent and Maintenance Setup
                        </CardTitle>
                        <Row>
                          <Col lg={1} style={{ width: "50px" }}>
                            <div className="mb-3">
                              <Label></Label>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Invoice Type</Label>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Amount</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>SAC / HSN Code</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>GST Rate</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>GST Amount</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>Total</Label>
                            </div>
                          </Col>
                        </Row>
                        {validation?.values?.billing_components &&
                          validation?.values?.billing_components?.map(
                            (row, key) => (
                              <Row key={key}>
                                <Col
                                  lg={1}
                                  className="d-flex align-items-center justify-content-end"
                                  style={{ width: "50px" }}
                                >
                                  <div className="mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`record_${key}`}
                                      name={`billing_components[${key}].check`}
                                      onChange={e => {
                                        const { name, checked } = e.target
                                        validation.setFieldValue(
                                          name,
                                          checked ? 1 : 0
                                        ) // Update form field value
                                      }}
                                      onBlur={validation.handleBlur}
                                      checked={
                                        Number(
                                          validation.values.billing_components[
                                            key
                                          ]?.check
                                        ) === 1
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col
                                  lg={2}
                                  className="d-flex align-items-center"
                                >
                                  <div className="mb-3">
                                    <p
                                      htmlFor={`invoice_type_${key}`}
                                      className="form-label"
                                    >
                                      {row?.description}
                                    </p>
                                  </div>
                                </Col>

                                <Col lg={2}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`amount_${key}`}
                                      name={`billing_components[${key}].amount`}
                                      placeholder="Enter Amount"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.billing_components[
                                          key
                                        ]?.amount || ""
                                      }
                                    />
                                    {validation.touched.billing_components?.[
                                      key
                                    ]?.amount &&
                                      validation.errors.billing_components?.[
                                        key
                                      ]?.amount && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors
                                              .billing_components[key]?.amount
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`sac_hsn_code_${key}`}
                                      name={`billing_components[${key}].sac_hsn_code`}
                                      placeholder="NA"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      disabled
                                      value={
                                        validation.values.billing_components[
                                          key
                                        ]?.sac_hsn || ""
                                      }
                                    />
                                    {validation.touched.billing_components?.[
                                      key
                                    ]?.sac_hsn_code &&
                                      validation.errors.billing_components?.[
                                        key
                                      ]?.sac_hsn_code && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors
                                              .billing_components[key]
                                              ?.sac_hsn_code
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      disabled
                                      id={`gst_percentage${key}`}
                                      name={`billing_components[${key}].gst_percentage`}
                                      placeholder="NA"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.billing_components[
                                          key
                                        ]?.gst_percentage || ""
                                      }
                                    />
                                    {validation.touched.billing_components?.[
                                      key
                                    ]?.gst_percentage &&
                                      validation.errors.billing_components?.[
                                        key
                                      ]?.gst_percentage && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors
                                              .billing_components[key]
                                              ?.gst_percentage
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.billing_components[
                                          key
                                        ]?.amount *
                                          (validation.values.billing_components[
                                            key
                                          ]?.gst_percentage /
                                            100) || "0"
                                      }
                                      id={`gst_amount${key}`}
                                      name={`billing_components[${key}].gst_amount`}
                                      placeholder="GST Amount"
                                      disabled
                                    />
                                    {validation.touched.billing_components?.[
                                      key
                                    ]?.gst_amount &&
                                      validation.errors.billing_components?.[
                                        key
                                      ]?.gst_amount && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors
                                              .billing_components[key]
                                              ?.gst_amount
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      name={`billing_components[${key}].total`}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        (parseFloat(
                                          validation.values.billing_components[
                                            key
                                          ]?.amount
                                        ) || 0) +
                                        (parseFloat(
                                          validation.values.billing_components[
                                            key
                                          ]?.amount || 0
                                        ) *
                                          (parseFloat(
                                            validation.values
                                              .billing_components[key]
                                              ?.gst_percentage
                                          ) /
                                            100) || 0)
                                      }
                                      id={`total_${key}`}
                                      placeholder="Total"
                                      disabled
                                    />
                                  </div>
                                </Col>

                                {/* <Col>
                              <div className="mb-3">
                                <button
                                  type="button"
                                  // className="btn btn-danger "
                                  style={{
                                    border: "none",
                                    background: "none",
                                    padding: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeRow(row.id)}
                                >
                                  <i
                                    className="mdi mdi-delete"
                                    style={{
                                      color: "red",
                                      fontSize: "20px",
                                    }}
                                  />
                                </button>
                              </div>
                            </Col> */}
                              </Row>
                            )
                          )}
                        <Row>
                          <Col>
                            <div className="mb-3 text-start">
                              {/* <button
                                    type="button"
                                    className="btn btn-primary w-md me-2"
                                    style={{ width: "10%" }}
                                    onClick={addRow}
                                  >
                                    Add
                                  </button> */}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="mb-3 text-end">
                      <button type="submit" className="btn btn-primary l-md">
                        Renew
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default RenewAssignedProjectUnit
