import {
  GET_PROJECTS,
  GET_PROJECT_NAME,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  ADD_NEW_PROJECT_NAME,
  ADD_PROJECT_NAME_SUCCESS,
  ADD_PROJECT_NAME_FAIL,
  ADD_NEW_PROJECT_UNIT,
  ADD_PROJECT_UNIT_SUCCESS,
  ADD_PROJECT_UNIT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_GALLERY,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_FAIL,
  ADD_GALLERY,
  ADD_GALLERY_SUCCESS,
  ADD_GALLERY_FAIL,
  UPDATE_GALLERY,
  UPDATE_GALLERY_SUCCESS,
  UPDATE_GALLERY_FAIL,
  DELETE_GALLERY,
  DELETE_GALLERY_SUCCESS,
  DELETE_GALLERY_FAIL,
  GET_PROJECT_UNIT,
  GET_PROJECT_UNIT_SUCCESS,
  GET_PROJECT_UNIT_FAIL,
  UPDATE_PROJECT_UNIT,
  UPDATE_PROJECT_UNIT_SUCCESS,
  UPDATE_PROJECT_UNIT_FAIL,
  DELETE_PROJECT_UNIT,
  DELETE_PROJECT_UNIT_SUCCESS,
  DELETE_PROJECT_UNIT_FAIL,
  GET_PROJECT_UNIT_DETAIL,
  GET_PROJECT_UNIT_DETAIL_SUCCESS,
  GET_PROJECT_UNIT_DETAIL_FAIL,
  GET_VIDEO,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_FAIL,
  GET_VIDEO_DETAIL,
  GET_VIDEO_DETAIL_SUCCESS,
  GET_VIDEO_DETAIL_FAIL,
  ADD_VIDEO,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_FAIL,
  DELETE_VIDEO,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAIL,
  GET_PROJECT_MANAGER,
  GET_PROJECT_MANAGER_SUCCESS,
  GET_PROJECT_MANAGER_FAIL,
  GET_FACILITIES_AVAILABLE,
  GET_FACILITIES_AVAILABLE_SUCCESS,
  GET_FACILITIES_AVAILABLE_FAIL,
  GET_COMPANY_FOR_PROJECT,
  GET_COMPANY_FOR_PROJECT_SUCCESS,
  GET_COMPANY_FOR_PROJECT_FAIL,
  GET_COMPANY_DETAIL_FOR_PROJECT,
  GET_COMPANY_DETAIL_FOR_PROJECT_SUCCESS,
  GET_COMPANY_DETAIL_FOR_PROJECT_FAIL,
  GET_COMPANY_LOCATION_FOR_PROJECT,
  GET_COMPANY_LOCATION_FOR_PROJECT_SUCCESS,
  GET_COMPANY_LOCATION_FOR_PROJECT_FAIL,
  GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT,
  GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_SUCCESS,
  GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_FAIL,
} from "./actionTypes"

export const getProjects = id => ({
  type: GET_PROJECTS,
  id,
})

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})
export const getProjectName = id => ({
  type: GET_PROJECT_NAME,
  id,
})

export const getProjectNameSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})
export const getProjectNameFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
})

export const addNewProject = project => ({
  type: ADD_NEW_PROJECT,
  payload: project,
})

export const addProjectSuccess = project => ({
  type: ADD_PROJECT_SUCCESS,
  payload: project,
})

export const addProjectFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
})
export const addNewProjectName = project => ({
  type: ADD_NEW_PROJECT_NAME,
  payload: project,
})

export const addProjectNameSuccess = project => ({
  type: ADD_PROJECT_NAME_SUCCESS,
  payload: project,
})

export const addProjectNameFail = error => ({
  type: ADD_PROJECT_NAME_FAIL,
  payload: error,
})
export const addNewProjectUnit = project => ({
  type: ADD_NEW_PROJECT_UNIT,
  payload: project,
})

export const addProjectUnitSuccess = project => ({
  type: ADD_PROJECT_UNIT_SUCCESS,
  payload: project,
})

export const addProjectUnitFail = error => ({
  type: ADD_PROJECT_UNIT_FAIL,
  payload: error,
})

export const updateProject = (id, project) => ({
  type: UPDATE_PROJECT,
  payload: { id, project },
})

export const updateProjectSuccess = project => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
})

export const updateProjectFail = error => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
})

export const deleteProject = project => ({
  type: DELETE_PROJECT,
  payload: project,
})

export const deleteProjectSuccess = project => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
})

export const deleteProjectFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
})

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
})

export const getProjectDetail = projectId => ({
  type: GET_PROJECT_DETAIL,
  projectId,
})

export const getProjectDetailSuccess = projectDetails => ({
  type: GET_PROJECT_DETAIL_SUCCESS,
  payload: projectDetails,
})

export const getProjectDetailFail = error => ({
  type: GET_PROJECT_DETAIL_FAIL,
  payload: error,
})

// PROJECT GALLERY

export const getGallery = userId => ({
  type: GET_GALLERY,
  userId,
})

export const getGallerySuccess = gallery => ({
  type: GET_GALLERY_SUCCESS,
  payload: gallery,
})
export const getGalleryFail = error => ({
  type: GET_GALLERY_FAIL,
  payload: error,
})

export const addGallery = gallery => ({
  type: ADD_GALLERY,
  payload: gallery,
})

export const addGallerySuccess = gallery => ({
  type: ADD_GALLERY_SUCCESS,
  payload: gallery,
})

export const addGalleryFail = error => ({
  type: ADD_GALLERY_FAIL,
  payload: error,
})

export const updateGallery = (id, gallery) => ({
  type: UPDATE_GALLERY,
  payload: { id, gallery },
})

export const updateGallerySuccess = gallery => ({
  type: UPDATE_GALLERY_SUCCESS,
  payload: gallery,
})

export const updateGalleryFail = error => ({
  type: UPDATE_GALLERY_FAIL,
  payload: error,
})

export const deleteGallery = gallery => ({
  type: DELETE_GALLERY,
  gallery,
})

export const deleteGallerySuccess = gallery => ({
  type: DELETE_GALLERY_SUCCESS,
  payload: gallery,
})

export const deleteGalleryFail = error => ({
  type: DELETE_GALLERY_FAIL,
  payload: error,
})

// PROJECT UNIT

export const getProjectUnit = userId => ({
  type: GET_PROJECT_UNIT,
  userId,
})

export const getProjectUnitSuccess = project => ({
  type: GET_PROJECT_UNIT_SUCCESS,
  payload: project,
})
export const getProjectUnitFail = error => ({
  type: GET_PROJECT_UNIT_FAIL,
  payload: error,
})

export const getProjectUnitDetail = userId => ({
  type: GET_PROJECT_UNIT_DETAIL,
  userId,
})
export const getProjectUnitDetailSuccess = project => ({
  type: GET_PROJECT_UNIT_DETAIL_SUCCESS,
  payload: project,
})
export const getProjectUnitDetailFail = error => ({
  type: GET_PROJECT_UNIT_DETAIL_FAIL,
  payload: error,
})

export const updateProjectUnit = (id, project) => ({
  type: UPDATE_PROJECT_UNIT,
  payload: { id, project },
})

export const updateProjectUnitSuccess = project => ({
  type: UPDATE_PROJECT_UNIT_SUCCESS,
  payload: project,
})

export const updateProjectUnitFail = error => ({
  type: UPDATE_PROJECT_UNIT_FAIL,
  payload: error,
})

export const deleteProjectUnit = project => ({
  type: DELETE_PROJECT_UNIT,
  payload: project,
})

export const deleteProjectUnitSuccess = project => ({
  type: DELETE_PROJECT_UNIT_SUCCESS,
  payload: project,
})

export const deleteProjectUnitFail = error => ({
  type: DELETE_PROJECT_UNIT_FAIL,
  payload: error,
})

//VIDEO

export const getVideo = userId => ({
  type: GET_VIDEO,
  userId,
})

export const getVideoSuccess = videos => ({
  type: GET_VIDEO_SUCCESS,
  payload: videos,
})
export const getVideoFail = error => ({
  type: GET_VIDEO_FAIL,
  payload: error,
})

export const getVideoDetail = videos => ({
  type: GET_VIDEO_DETAIL,
  videos,
})

export const getVideoDetailSuccess = videos => ({
  type: GET_VIDEO_DETAIL_SUCCESS,
  payload: videos,
})

export const getVideoDetailFail = error => ({
  type: GET_VIDEO_DETAIL_FAIL,
  payload: error,
})

export const addVideo = videos => ({
  type: ADD_VIDEO,
  payload: videos,
})

export const addVideoSuccess = videos => ({
  type: ADD_VIDEO_SUCCESS,
  payload: videos,
})

export const addVideoFail = error => ({
  type: ADD_VIDEO_FAIL,
  payload: error,
})

export const deleteVideo = gallery => ({
  type: DELETE_VIDEO,
  gallery,
})

export const deleteVideoSuccess = gallery => ({
  type: DELETE_VIDEO_SUCCESS,
  payload: gallery,
})

export const deleteVideoFail = error => ({
  type: DELETE_VIDEO_FAIL,
  payload: error,
})

// GET PROJECT MANAGER

export const getProjectManager = () => ({
  type: GET_PROJECT_MANAGER,
})

export const getProjectManagerSuccess = projects => ({
  type: GET_PROJECT_MANAGER_SUCCESS,
  payload: projects,
})
export const getProjectManagerFail = error => ({
  type: GET_PROJECT_MANAGER_FAIL,
  payload: error,
})

// FACILITIES AVAILABLE
export const getFacilitiesAvailable = () => ({
  type: GET_FACILITIES_AVAILABLE,
})

export const getFacilitiesAvailableSuccess = facilities => ({
  type: GET_FACILITIES_AVAILABLE_SUCCESS,
  payload: facilities,
})
export const getFacilitiesAvailableFail = () => ({
  type: GET_FACILITIES_AVAILABLE_FAIL,
})

export const getCompanyForProject = () => ({
  type: GET_COMPANY_FOR_PROJECT,
})

export const getCompanyForProjectSuccess = company => ({
  type: GET_COMPANY_FOR_PROJECT_SUCCESS,
  payload: company,
})
export const getCompanyForProjectFail = error => ({
  type: GET_COMPANY_FOR_PROJECT_FAIL,
  payload: error,
})

export const getCompanyDetailForProject = companyId => ({
  type: GET_COMPANY_DETAIL_FOR_PROJECT,
  companyId,
})

export const getCompanyDetailForProjectSuccess = company => ({
  type: GET_COMPANY_DETAIL_FOR_PROJECT_SUCCESS,
  payload: company,
})
export const getCompanyDetailForProjectFail = error => ({
  type: GET_COMPANY_DETAIL_FOR_PROJECT_FAIL,
  payload: error,
})

export const getCompanyLocationForProject = companyId => ({
  type: GET_COMPANY_LOCATION_FOR_PROJECT,
  companyId,
})

export const getCompanyLocationForProjectSuccess = company => ({
  type: GET_COMPANY_LOCATION_FOR_PROJECT_SUCCESS,
  payload: company,
})
export const getCompanyLocationForProjectFail = error => ({
  type: GET_COMPANY_LOCATION_FOR_PROJECT_FAIL,
  payload: error,
})

export const getCompanyLocationDetailForProject = companyId => ({
  type: GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT,
  companyId,
})

export const getCompanyLocationDetailforProjectSuccess = company => ({
  type: GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_SUCCESS,
  payload: company,
})
export const getCompanyLocationDetailforProjectFail = error => ({
  type: GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_FAIL,
  payload: error,
})
