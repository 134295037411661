import {
  GET_CONSUMPTION_FILTER,
  GET_CONSUMPTION_FILTER_FAIL,
  GET_CONSUMPTION_FILTER_SUCCESS,
  GET_CUSTOMER_FILTER_FOR_CONSUMPTION,
  GET_CUSTOMER_FILTER_FOR_CONSUMPTION_FAIL,
  GET_CUSTOMER_FILTER_FOR_CONSUMPTION_SUCCESS,
  GET_PROJECT_FILTER_FOR_CONSUMPTION,
  GET_PROJECT_FILTER_FOR_CONSUMPTION_FAIL,
  GET_PROJECT_FILTER_FOR_CONSUMPTION_SUCCESS,
  GET_UNIT_FOR_CONSUMPTION,
  GET_UNIT_FOR_CONSUMPTION_FAIL,
  GET_UNIT_FOR_CONSUMPTION_SUCCESS,
} from "./actionTypes"

export const getCustomerFilterForConsumption = userId => ({
  type: GET_CUSTOMER_FILTER_FOR_CONSUMPTION,
  userId,
})

export const getCustomerFilterForConsumptionSuccess = customerFilter => ({
  type: GET_CUSTOMER_FILTER_FOR_CONSUMPTION_SUCCESS,
  payload: customerFilter,
})

export const getCustomerFilterForConsumptionFail = error => ({
  type: GET_CUSTOMER_FILTER_FOR_CONSUMPTION_FAIL,
  payload: error,
})

export const getProjectFilterForConsumption = userId => ({
  type: GET_PROJECT_FILTER_FOR_CONSUMPTION,
  userId,
})

export const getProjectFilterForConsumptionSuccess = projectFilter => ({
  type: GET_PROJECT_FILTER_FOR_CONSUMPTION_SUCCESS,
  payload: projectFilter,
})

export const getProjectFilterForConsumptionFail = error => ({
  type: GET_PROJECT_FILTER_FOR_CONSUMPTION_FAIL,
  payload: error,
})

export const getUnitForConsumption = data => ({
  type: GET_UNIT_FOR_CONSUMPTION,
  data,
})

export const getUnitForConsumptionSuccess = unit => ({
  type: GET_UNIT_FOR_CONSUMPTION_SUCCESS,
  payload: unit,
})

export const getUnitForConsumptionFail = error => ({
  type: GET_UNIT_FOR_CONSUMPTION_FAIL,
  payload: error,
})

export const getConsumptionFilter = data => ({
  type: GET_CONSUMPTION_FILTER,
  data,
})

export const getConsumptionFilterSuccess = unit => ({
  type: GET_CONSUMPTION_FILTER_SUCCESS,
  payload: unit,
})

export const getConsumptionFilterFail = error => ({
  type: GET_CONSUMPTION_FILTER_FAIL,
  payload: error,
})
