import PropTypes from "prop-types"
import React, { useState } from "react"

import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logoVip from "../../assets/images/VIP/V1nobg.png"
// import logoVip from "../../assets/images/VIP/FrameVip.png"
import CarouselPage from "./CarouselPage"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"


import { Link } from "react-router-dom"

const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false)

  // Your form validation logic
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      login_username: "",
      password: "",
    },
    validationSchema: Yup.object({
      login_username: Yup.string().required("Please Enter Your username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      try {
        const response = dispatch(loginUser(values, props.router.navigate))
      } catch (error) {
        console.error("Network Error:", error.message)
      }
    },
  })

  // Your Redux selectors and dispatch
  const dispatch = useDispatch()
  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
  }))
  const { error } = useSelector(LoginProperties)

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div
                      className="mb-4 mb-md-5  "
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginInline: "auto",
                      }}
                    >
                      <img
                        src={logoVip}
                        alt=""
                        height="200"
                        className="logo-dark-element"
                      />
                      <img
                        src={logoVip}
                        alt=""
                        height="200"
                        className="logo-light-element"
                      />

                      {/* <div
                        style={{
                          fontFamily: "Aboreto",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        VIP MALL
                      </div> */}
                    </div>

                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Sign In</h5>
                        <p className="text-muted">Your Management System</p>
                      </div>
                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                              name="login_username"
                              className="form-control"
                              placeholder="Enter username"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.login_username || ""}
                              invalid={
                                validation.touched.login_username &&
                                validation.errors.login_username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.login_username &&
                            validation.errors.login_username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.login_username}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Forgot password?
                            </Link>
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="password">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <div className="col">
                                <Input
                                  type={passwordShow ? "text" : "password"}
                                  // className="form-control"
                                  id="password"
                                  placeholder="Create Password"
                                  name="password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password || ""}
                                  className={`form-control ${
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div>
                                <button
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="btn btn-light"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="mdi mdi-eye-outline"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div> */}

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Form>

                        {/* <Form action="dashboard">
                          <div className="mt-4 text-center">
                            <h5 className="font-size-14 mb-3">
                              Sign in with
                            </h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary me-1"
                                >
                                  <i className="mdi mdi-facebook"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-info text-white border-info me-1"
                                >
                                  <i className="mdi mdi-twitter"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                >
                                  <i className="mdi mdi-google"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Form> */}
                        {/* <div className="mt-5 text-center">
                          <p>
                            Don&apos;t have an account ?
                            <Link
                              to="pages-register-2"
                              className="fw-medium text-primary"
                            >
                              Signup now
                            </Link>
                          </p>
                        </div> */}
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Vinder Property Pvt Ltd.{" "}
                        <br />
                        {/* <i className="mdi mdi-heart text-danger"></i>  */}
                        Powered by Woodapples Software Solutions Pvt. Ltd
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
