import React, { useEffect, useState } from "react"
import SimpleBar from "simplebar-react"
import ReactApexChart from "react-apexcharts"
import * as Yup from "yup"
import Swal from "sweetalert2"
import {
  Card,
  Col,
  CardBody,
  Table,
  Row,
  Label,
  FormFeedback,
  Badge,
} from "reactstrap"
import { useFormik } from "formik"
import { getUserVendorWithMostTicket } from "store/actions"
import { useDispatch, useSelector } from "react-redux"

const category = () => {
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed

    initialValues: {
      filter: "This Year",
    },

    validationSchema: Yup.object({}),
  })

  useEffect(() => {
    dispatch(getUserVendorWithMostTicket())
  }, [validation.values.filter])

  const countState = state => state.ticketsDashboard.mostTickets.tickets
  const count = useSelector(countState) || []

  const formatNumberWithCommas = number => {
    // If number is null or empty, return it as it is
    if (!number) {
      return number
    }

    // Convert number to string
    let numStr = String(number)

    // Split the string into integer and decimal parts
    let parts = numStr.split(".")

    // Format the integer part with commas
    let integerPart = parts[0]
    let lastThreeDigits = integerPart.slice(-3) // Get the last three digits
    let remainingDigits = integerPart.slice(0, -3) // Get the remaining digits
    let formattedInteger =
      remainingDigits !== ""
        ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          lastThreeDigits
        : lastThreeDigits

    // Combine integer and decimal parts with a dot
    // let formattedNumber = formattedInteger + (parts[1] ? "." + parts[1] : "")

    return formattedInteger
  }

  const getChartOptions = index => {
    var options = {
      chart: { sparkline: { enabled: !0 } },
      dataLabels: { enabled: !1 },
      colors: ["#556ee6"],
      plotOptions: {
        radialBar: {
          hollow: { margin: 0, size: "60%" },
          track: { margin: 0 },
          dataLabels: { show: !1 },
        },
      },
    }
    switch (index) {
      case 1:
        options["colors"][0] = "#556ee6"
        break
      case 2:
        options["colors"][0] = "#FFBF00"
        break
      case 3:
        options["colors"][0] = "#f46a6a"
        break
      case 4:
        options["colors"][0] = "#34c38f"
        break
      default:
        break
    }

    return options
  }

  return (
    <React.Fragment>
      <Col>
        <Card style={{ height: "476px" }}>
          <CardBody>
            <Row>
              <Col className="d-flex align-items-center">
                <h4 className="card-title mb-4">
                  User / Vendor With Most Tickets
                </h4>
              </Col>
            </Row>
            <div style={{ height: "400px" }}>
              <SimpleBar style={{ maxHeight: "100%" }}>
                <Table className="table align-middle mb-2">
                  <tbody>
                    {count &&
                      count.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <h5 className="font-size-16 mb-1">{item.name}</h5>
                            <p className="text-muted mb-0">
                              Last Updated On {item.last_updated_at}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-1">Ticket Count</p>
                            <h5 className="mb-0 text-primary">
                              {item.ticket_count}
                            </h5>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default category
