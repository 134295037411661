/* FACILITIES */
export const GET_FACILITIES = "GET_FACILITIES"
export const GET_FACILITIES_SUCCESS = "GET_FACILITIES_SUCCESS"
export const GET_FACILITIES_FAIL = "GET_FACILITIES_FAIL"

/* FACILITIES DETAIL*/
export const GET_FACILITY_DETAIL = "GET_FACILITY_DETAIL"
export const GET_FACILITY_DETAIL_SUCCESS = "GET_FACILITY_DETAIL_SUCCESS"
export const GET_FACILITY_DETAIL_FAIL = "GET_FACILITY_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_FACILITY = "ADD_NEW_FACILITY"
 export const ADD_FACILITY_SUCCESS = "ADD_FACILITY_SUCCESS"
 export const ADD_FACILITY_FAIL = "ADD_FACILITY_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_FACILITY = "UPDATE_FACILITY"
 export const UPDATE_FACILITY_SUCCESS = "UPDATE_FACILITY_SUCCESS"
 export const UPDATE_FACILITY_FAIL = "UPDATE_FACILITY_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_FACILITY = "DELETE_FACILITY"
 export const DELETE_FACILITY_SUCCESS = "DELETE_FACILITY_SUCCESS"
 export const DELETE_FACILITY_FAIL = "DELETE_FACILITY_FAIL"
