import {
  GET_ALL_ENGAGEMENT_LIST,
  GET_ALL_ENGAGEMENT_LIST_SUCCESS,
  GET_ALL_ENGAGEMENT_LIST_FAIL,
  GET_APPROVAL_PENDING_LIST,
  GET_APPROVAL_PENDING_LIST_SUCCESS,
  GET_APPROVAL_PENDING_LIST_FAIL,
  GET_BILLING_SETUP_PENDING_LIST,
  GET_BILLING_SETUP_PENDING_LIST_SUCCESS,
  GET_BILLING_SETUP_PENDING_LIST_FAIL,
} from "./actionTypes"

export const getAllEngagementList = () => ({
  type: GET_ALL_ENGAGEMENT_LIST,
})

export const getAllEngagementListSuccess = engagementList => ({
  type: GET_ALL_ENGAGEMENT_LIST_SUCCESS,
  payload: engagementList,
})

export const getAllEngagementListFail = error => ({
  type: GET_ALL_ENGAGEMENT_LIST_FAIL,
  payload: error,
})

export const getApprovalPendingList = () => ({
  type: GET_APPROVAL_PENDING_LIST,
})

export const getApprovalPendingListSuccess = approvalPendingList => ({
  type: GET_APPROVAL_PENDING_LIST_SUCCESS,
  payload: approvalPendingList,
})

export const getApprovalPendingListFail = error => ({
  type: GET_APPROVAL_PENDING_LIST_FAIL,
  payload: error,
})

export const getBillingSetupPendingList = () => ({
  type: GET_BILLING_SETUP_PENDING_LIST,
})

export const getBillingSetupPendingListSuccess = billingSetupPendingList => ({
  type: GET_BILLING_SETUP_PENDING_LIST_SUCCESS,
  payload: billingSetupPendingList,
})

export const getBillingSetupPendingListFail = error => ({
  type: GET_BILLING_SETUP_PENDING_LIST_FAIL,
  payload: error,
})
