import {
  GET_DEBIT_NOTE_LIST_SUCCESS,
  GET_DEBIT_NOTE_LIST_FAIL,
  GET_DEBIT_NOTE_DETAIL_SUCCESS,
  GET_DEBIT_NOTE_DETAIL_FAIL,
  ADD_NEW_DEBIT_NOTE_SUCCESS,
  ADD_NEW_DEBIT_NOTE_FAIL,
  UPDATE_DEBIT_NOTE_SUCCESS,
  UPDATE_DEBIT_NOTE_FAIL,
  DELETE_DEBIT_NOTE_SUCCESS,
  DELETE_DEBIT_NOTE_FAIL,
  GET_VENDOR_INVOICE_DETAIL_SUCCESS,
  GET_VENDOR_INVOICE_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  debitNoteList: [],
  addDebitNote: [],
  editDebitNote: [],
  debitNoteDetail: {},
  vendorInvoiceDetail: {},
  error: {},
  loading: true,
}

const debitNote = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEBIT_NOTE_LIST_SUCCESS:
      return {
        ...state,
        debitNoteList: action.payload,
        loading: false,
      }

    case GET_DEBIT_NOTE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DEBIT_NOTE_DETAIL_SUCCESS:
      return {
        ...state,
        debitNoteDetail: action.payload,
        loading: false,
      }

    case GET_DEBIT_NOTE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        addDebitNote: [...state.addDebitNote, action.payload],
      }

    case ADD_NEW_DEBIT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        editDebitNote: state.editDebitNote.map(editDebitNote =>
          editDebitNote.id === action.payload.id
            ? { ...editDebitNote, ...action.payload }
            : editDebitNote
        ),
      }

    case UPDATE_DEBIT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        debitNoteList: state.debitNoteList.data.filter(
          debitNoteList =>
            debitNoteList.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_DEBIT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_VENDOR_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        vendorInvoiceDetail: action.payload,
        loading: false,
      }

    case GET_VENDOR_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default debitNote
