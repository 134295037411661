export const GET_MATERIAL_MASTER_LIST = "GET_MATERIAL_MASTER_LIST"
export const GET_MATERIAL_MASTER_LIST_SUCCESS = "GET_MATERIAL_MASTER_LIST_SUCCESS"
export const GET_MATERIAL_MASTER_LIST_FAIL = "GET_MATERIAL_MASTER_LIST_FAIL"

export const GET_MATERIAL_MASTER_DETAIL = "GET_MATERIAL_MASTER_DETAIL"
export const GET_MATERIAL_MASTER_DETAIL_SUCCESS = "GET_MATERIAL_MASTER_DETAIL_SUCCESS"
export const GET_MATERIAL_MASTER_DETAIL_FAIL = "GET_MATERIAL_MASTER_DETAIL_FAIL"

 export const ADD_MATERIAL_MASTER = "ADD_MATERIAL_MASTER"
 export const ADD_MATERIAL_MASTER_SUCCESS = "ADD_MATERIAL_MASTER_SUCCESS"
 export const ADD_MATERIAL_MASTER_FAIL = "ADD_MATERIAL_MASTER_FAIL"

 export const UPDATE_MATERIAL_MASTER = "UPDATE_MATERIAL_MASTER"
 export const UPDATE_MATERIAL_MASTER_SUCCESS = "UPDATE_MATERIAL_MASTER_SUCCESS"
 export const UPDATE_MATERIAL_MASTER_FAIL = "UPDATE_MATERIAL_MASTER_FAIL"

 export const DELETE_MATERIAL_MASTER = "DELETE_MATERIAL_MASTER"
 export const DELETE_MATERIAL_MASTER_SUCCESS = "DELETE_MATERIAL_MASTER_SUCCESS"
 export const DELETE_MATERIAL_MASTER_FAIL = "DELETE_MATERIAL_MASTER_FAIL"

 export const GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER = "GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER"
 export const GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_SUCCESS = "GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_SUCCESS"
 export const GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_FAIL = "GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_FAIL"

 export const ADD_NEW_SACHSNCODE_MATERIAL_MASTER = "ADD_NEW_SACHSNCODE_MATERIAL_MASTER"
 export const ADD_NEW_SACHSNCODE_MATERIAL_MASTER_SUCCESS = "ADD_NEW_SACHSNCODE_MATERIAL_MASTER_SUCCESS"
 export const ADD_NEW_SACHSNCODE_MATERIAL_MASTER_FAIL = "ADD_NEW_SACHSNCODE_MATERIAL_MASTER_FAIL"
