import React from "react"

import { Col, Container, Row, CardTitle, Label } from "reactstrap"

const CompanyDetail = props => {
  const { companyProfile } = props
  return (
    <React.Fragment>
      <Container fluid={true}>
        {companyProfile && (
          <Row>
            <Col>
              <CardTitle className="mb-3">Company Profile</CardTitle>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Customer Name </Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {companyProfile.name}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Address Line 1</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {companyProfile.address_line_1}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Address Line 2</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {companyProfile.address_line_2}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Label className="form-label">Country</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {companyProfile.country_name}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">State</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {companyProfile.state_name}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">City</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {companyProfile.city_name}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Label className="form-label">Pincode</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {companyProfile.pin_code}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Business Pan No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {companyProfile.pan_no}
                  </p>
                </Col>

                <Col lg={4}>
                  <Label className="form-label">CIN No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {companyProfile.cin_no}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  )
}

export default CompanyDetail
