import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"

// import { recentfile } from "../../../common/data";
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  deleteAssignedProjectUnit,
  deleteClientUser,
  getAssignedProjectUnitList,
  getClientUserList,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"
import Swal from "sweetalert2"
import { reinviteClientUser } from "helpers/fakebackend_helper"

const AllClientUser = () => {
  const [clientId, setClientId] = useState()

  const dispatch = useDispatch()
  const clientUserState = state => state.clientUser.user.client_users
  const data = useSelector(clientUserState)

  const fetchData = () => {
    const url = new URL(window.location.href)
    const clientId = url.pathname.split("/view-client/")[1]
    setClientId(clientId)
    dispatch(getClientUserList(clientId))
  }
  useEffect(() => {
    fetchData()
  }, [dispatch])

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const handleResendInvitation = async (id) => {
    try {
      const response = await reinviteClientUser(id);
      if (response.status) {
        Swal.fire({
          title: "Invitation Resent!",
          text: "The invitation has been successfully resent to the user.",
          icon: "success",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "There was an issue resending the invitation. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error resending invitation:", error);
    }
  };
  

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteClientUser(deleteId.id))
    }
    setDeleteModal(false)
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Unit Access</th>
              <th scope="col">Mobile</th>
              <th scope="col">Designation</th>
              <th scope="col"> UserName</th>

              <th scope="col" colSpan={2}>
                Action
              </th>
            </tr>
          </thead>
          {Array.isArray(data) && data?.length > 0 ? (
            <tbody>
              {data?.map((item, key) => (
                <tr key={key}>
                  <td>
                    {item.first_name} {item.middle_name} {item.last_name}
                  </td>
                  <td>{item.email_id}</td>
                  <td>{item.unit_codes}</td>
                  <td>{item.mobile_no}</td>
                  <td>{item.designation}</td>
                  <td>{item.login_username}</td>

                  <td>
                    <div className="d-flex gap-3">
                      {permission
                        .filter(
                          perm =>
                            perm.page === "/manage-user/edit-client-user/:id" &&
                            perm.access === 1
                        )
                        .map(index => (
                          <Link
                            key={index}
                            to={`/clients/manage-user/edit-client-user/${item.id}`}
                            className="text-success"
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="edittooltip"
                            >
                              Edit
                            </UncontrolledTooltip>
                          </Link>
                        ))}
                      {permission
                        .filter(
                          perm =>
                            perm.page === "delete-client-user" &&
                            perm.access === 1
                        )
                        .map(index => (
                          <Link
                            key={index}
                            to="#"
                            className="text-danger"
                            onClick={() => {
                              onClickDelete(item.id)
                            }}
                          >
                            <i
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="deletetooltip"
                            >
                              Delete
                            </UncontrolledTooltip>
                          </Link>
                        ))}
                      {permission
                        .filter(
                          perm =>
                            perm.page === "/manage-user/reinvite-client-user" &&
                            perm.access === 1
                        )
                        .map(index => (
                          <Link
                            key={index}
                            to="#"
                            className="text-danger"
                            onClick={() => {
                              handleResendInvitation(item.id)
                            }}
                          >
                            <i
                              className="mdi mdi-email-sync font-size-18"
                              id="deletetooltip1"
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="deletetooltip1"
                            >
                              Resend Invitation
                            </UncontrolledTooltip>
                          </Link>
                        ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="5" className="text-center">
                  No Records Found
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
      <div className="mt-3 text-end">
        {permission
          .filter(
            perm =>
              perm.page === "/view-client/add-new-assigned-project-unit/:id" &&
              perm.access === 1
          )
          .map(index => (
            <Link
              key={index}
              to={`/clients/manage-user/add-new-client-user/${clientId}`}
            >
              <button type="submit" className="btn btn-primary w-md">
                Add New Client User
              </button>
            </Link>
          ))}
      </div>

      {/* </div> */}
    </React.Fragment>
  )
}

export default AllClientUser
