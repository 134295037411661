import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import SacHsnCodeModal from "./sacHsnCodeModal"
import {
  addNewMaterialMaster,
  getMaterialMasterDetail,
  getSacHsnCodeForMaterialMaster,
  updateMaterialMaster,
} from "store/actions"

const NewMaterialMaster = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const materialMasterState = state => state.materialMaster.materialDetail.data
  const sacHsnCodeState = state => state.materialMaster.sachsnCode.data
  const loadingState = state => state.materialMaster.loading
  const [isEditMode, setIsEditMode] = useState(false)
  const materialMasterProperties = createSelector(
    materialMasterState,
    sacHsnCodeState,
    loadingState,
    (material, sacHsn, loading) => {
      return {
        material: material,
        sacHsn: sacHsn,
        loading: loading,
      }
    }
  )
  const { material, sacHsn, loading } = useSelector(materialMasterProperties)
  const [codeId, setCodeId] = useState()
  const [isLoading, setLoading] = useState(loading)

  const [modal_large, setmodal_large] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }

  const vendorData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const codeId = url.pathname.split("/settings/edit-material-master/")[1]
      setCodeId(codeId)

      dispatch(getMaterialMasterDetail(codeId))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      vendorData()
    }
  }, [dispatch])

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    }
  }, [location.pathname])

  useEffect(() => {
    dispatch(getSacHsnCodeForMaterialMaster())
  }, [dispatch])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode
      ? {
          sac_hsm_id: (material && material.sac_hsm_id) || "",
          materials: (material && material.materials) || "",
          is_active: Boolean(material && material.is_active),
        }
      : {
          sac_hsm_id: "",
          materials: "",
          is_active: false,
        },
    validationSchema: Yup.object({
      sac_hsm_id: Yup.number().required("Please Select SAC / HSM Code"),
      materials: Yup.string().required("Please Enter Material Name"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      if (isEditMode === true) {
        dispatch(updateMaterialMaster(codeId, values, props.router.navigate))
      } else if (isEditMode === false) {
        dispatch(addNewMaterialMaster(values, props.router.navigate))
      }
    },
  })

  return (
    <React.Fragment>
      <SacHsnCodeModal isOpen={modalLarge} toggle={toggleModalLarge} />
      <div className="page-content">
        {isLoading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <>
            <Row>
              <Col>
                <div className="d-flex gap-2 mb-3">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey", marginRight: "15px" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>

                  <span
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    {isEditMode
                      ? "Edit Material Master"
                      : "New Material Master"}{" "}
                  </span>
                </div>
              </Col>
              <Col>
                <Breadcrumbs title="Settings / Master configuration / Material Master" />
              </Col>
            </Row>
            <div className="mt-3"></div>
            <Card>
              <CardBody>
                <Form
                  autoComplete="off"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col lg="6">
                      <Label for="sac_hsm_id">Applicable SAC / HSN Code*</Label>
                      <select
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        // defaultValue="IN"
                        value={validation.values.sac_hsm_id}
                        name="sac_hsm_id"
                        className={`form-select ${
                          validation.touched.sac_hsm_id &&
                          validation.errors.sac_hsm_id
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        {!validation.values.sac_hsm_id && (
                          <option>Select SAC HSN Code</option>
                        )}

                        {(sacHsn || []).map(item => (
                          <option key={item.id} value={item.id}>
                            {item.sac_hsm_code}
                          </option>
                        ))}
                      </select>
                      {validation.touched.sac_hsm_id &&
                        validation.errors.sac_hsm_id && (
                          <FormFeedback type="invalid">
                            {validation.errors.sac_hsm_id}
                          </FormFeedback>
                        )}
                      <div className="mt-2 ms-1 mb-3">
                        <Button
                          style={{
                            type: "button",
                            background: "none",
                            border: "none",
                            boxShadow:"none",
                            padding: "2px",
                            color: "#333",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            toggleModalLarge()
                          }}
                        >
                          Add More?
                        </Button>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="formrow-materials-Input">
                          Material Name*
                        </Label>
                        <Input
                          type="text"
                          name="materials"
                          className="form-control"
                          id="formrow-materials-Input"
                          placeholder="Enter Material Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.materials || ""}
                          invalid={
                            validation.touched.materials &&
                            validation.errors.materials
                              ? true
                              : false
                          }
                        />
                        {validation.touched.materials &&
                        validation.errors.materials ? (
                          <FormFeedback type="invalid">
                            {validation.errors.materials}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-2"></div>
                      <div>
                        <Label htmlFor="formrow-ispaid-Input">Is Active</Label>
                      </div>

                      <Col lg="12">
                        <div className="form-check form-check-inline">
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            id="is_active"
                            name="is_active"
                            onChange={e => {
                              validation.setFieldValue(
                                "is_active",
                                e.target.checked
                              )
                            }}
                            checked={Boolean(validation?.values?.is_active)}
                          />
                          <Label
                            className="form-check-Label"
                            htmlFor="is-Active"
                          >
                            <h6>Yes</h6>
                          </Label>
                        </div>
                        {validation.touched.is_active &&
                          validation.errors.is_active && (
                            <FormFeedback type="invalid">
                              {validation.errors.is_active}
                            </FormFeedback>
                          )}
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3 text-end">
                        <button type="submit" className="btn btn-primary w-md">
                          {isEditMode ? "Edit" : "Submit"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(NewMaterialMaster)

NewMaterialMaster.propTypes = {
  history: PropTypes.object,
}
