import {
  downloadProjectUnitDataExcel,
  statusWiseTicketListExcel,
  vendorAssignedticketExcel,
} from "helpers/fakebackend_helper"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal, Table } from "reactstrap"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import {
  newUnitAssignedList,
  statusWiseTicketList,
  vendorTicketList,
} from "store/actions"

const StatusWiseTickets = ({ isOpen, toggle, filterResponse, statusValue }) => {
  const dispatch = useDispatch()

  const dataState = state => state.Dashboard?.statusWiseTicketList?.data
  const data = useSelector(dataState)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  useEffect(() => {
    if (isOpen === true && user) {
      dispatch(
        statusWiseTicketList({
          filter: filterResponse,
          status: statusValue,
          user_id: user.user_id,
        })
      )
    }
  }, [filterResponse, dispatch, isOpen, statusValue])

  const handleExport = async () => {
    // Construct the URL for download
    const value = {
      filter: filterResponse,
      type: "tickets",
      user_id: user.user_id,
      status: statusValue,

    }
    const downloadUrl = await statusWiseTicketListExcel(value)
  }
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Ticket List
        </h5>
        <button
          onClick={handleExport}
          type="button"
          className="btn btn-primary btn-sm me-4"
        >
          Export
        </button>
        <button
          onClick={toggle}
          type="button"
          className="close mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <SimpleBar style={{ maxHeight: "60vh", overflowX: "auto" }}>
          <div style={{ minWidth: "100%" }}>
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Ticket No</th>
                  <th>Name</th>
                  <th>Priority</th>
                  <th>Ticket Status</th>
                </tr>
              </thead>
              <tbody>
                {(data || []).map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{row.ticket_no}</td>
                    <td>{row.name}</td>
                    <td>{row.priority_id}</td>
                    <td>{row.status_name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </SimpleBar>
      </div>
    </Modal>
  )
}

export default StatusWiseTickets
