import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Label,
  Input,
  navigate,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import VLogo from "../../../assets/images/VIP/VLogo.png"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import { getTicketDetail } from "store/actions"
import Swal from "sweetalert2"
import { addNewTicket, updateTicket } from "helpers/fakebackend_helper"

const NewCustomerTicket = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const dispatch = useDispatch()
  const customerState = state => state.tickets.ticketDetail.Data
  // const loadingState = state => state.facilities.loading
  const data = useSelector(customerState)
  // const loadingData = useSelector(loadingState)

 

  const customerProperties = createSelector(
    customerState,
    // loadingState,
    (customers, loading) => {
      return {
        customers: customers,
        loading: loading,
      }
    }
  )

  const { customers, loading } = useSelector(customerProperties)

  const [specificUser, setSpecificUser] = useState(null)

  const [customerId, setCustomerId] = useState()

  const customerData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const customerId = url.pathname.split("/edit-customer-ticket/")[1]
      setCustomerId(customerId)
  
      dispatch(getTicketDetail(customerId))
     

    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      customerData()
    }
  }, [dispatch])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          ticket_topic_name: (customers && customers.ticket_topic_name) || "",
          is_active: (customers && customers.is_active) || false,
          // is_active: false,
        }
      : {
          ticket_topic_name: "",
          is_active: false,
        },
    validationSchema: Yup.object({
      ticket_topic_name: Yup.string().required(
        "Please Enter Your Customer Support title"
      ),
      // is_active: Yup.boolean().required("Please Select Valid Option"),
      is_active: Yup.boolean(),
    }),
   
    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        if (isEditMode === true) {
          // const updatedResponse = await dispatch(onUpdateVendor(vendorId,values))
          const response = await updateTicket(customerId, values)
          // response2 = onUpdateVendor(vendorId,values)

          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Customer Support Ticket Topic has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/customer-ticket-master")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has not been saved.",
            })
          }
        } else if (isEditMode === false) {
          const response = await addNewTicket(values)
          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Customer Support Ticket Topic has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/customer-ticket-master")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Customer Support Ticket Topic has not been saved.",
            })
          }


        }
      } catch (error) {
        loadingSwal.close()

        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const navigate = useNavigate()
  const toggleToast = () => {
    setToast(!toast)
  }
  const toggleToast2 = () => {
    setToast2(!toast2)
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  useEffect(() => {
    //check if the current path includes 'edit-customer'
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)
    setToast2(true)
  }
  const [selectedOption, setSelectedOption] = useState(customers?.is_active)

  const handleChange1 = () => {
    const newValue = !selectedOption
    setSelectedOption(newValue)
    validation.setFieldValue("is_active", newValue)
  }

  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {" "}
              {isEditMode
                ? "Edit Customer Support ticket topic"
                : "New Customer Support ticket topic"}
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Settings / Master configuration / Customer Support ticket topic Master" />
        </Col>
      </Row>
      <div className="mt-3"></div>

      {/* <CardTitle className="mb-4">New Customer Support ticket topic</CardTitle> */}
      <Card>
        <CardBody>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-customersupport-Input">
                    Customer support ticket*
                  </Label>
                  <Input
                    type="text"
                    name="ticket_topic_name"
                    className="form-control"
                    id="formrow-customersupport-Input"
                    placeholder="Enter Customer support title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.ticket_topic_name || ""}
                    invalid={
                      validation.touched.ticket_topic_name &&
                      validation.errors.ticket_topic_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.ticket_topic_name &&
                  validation.errors.ticket_topic_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.ticket_topic_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div>
                  <div className="mb-3"></div>
                  <Label htmlFor="formrow-isactive-Input">Is Active</Label>
                </div>

                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="is_active"
                      name="is_active"
                      onChange={e => {
                        validation.setFieldValue("is_active", e.target.checked)
                      }}
                      checked={Boolean(validation?.values?.is_active)}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      Yes
                    </Label>
                  </div>
                  {validation.touched.is_active &&
                    validation.errors.is_active && (
                      <FormFeedback type="invalid">
                        {validation.errors.is_active}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-md">
                    {isEditMode ? "Edit" : "Submit"}{" "}
                  </button>
                </div>
                {toast2 && (
                  <div
                    className="position-fixed top-0 end-0 p-3"
                    style={{ zIndex: "1005" }}
                  >
                    <Toast isOpen={toast2} role="aleart">
                      <ToastHeader toggle={() => setToast2(!toast2)}>
                        <img src={VLogo} alt="" className="me-2" height="18" />
                        <strong className="me-auto">VIP Mall</strong>
                      </ToastHeader>
                      <ToastBody>
                        {!isEditMode
                          ? "Submitted Successfully"
                          : "Edited Successfully"}
                      </ToastBody>
                    </Toast>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default NewCustomerTicket
