import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  getFacilityDetail,
  addNewFacility,
  updateFacility,
} from "store/masters/facility/actions"
import { createSelector } from "reselect"

const NewFacilityMaster = props => {
  const [isEditMode, setIsEditMode] = useState(false)

  const dispatch = useDispatch()
  const facilityState = state => state.facilities.facilityDetail.Data
  const loadingState = state => state.facilities.loading

  const facilitiesProperties = createSelector(
    facilityState,
    loadingState,
    (facilities, loading) => {
      return {
        facilities: facilities,
        loading: loading,
      }
    }
  )

  const { facilities, loading } = useSelector(facilitiesProperties)
  const [facilityId, setFacilityId] = useState()

  const facilityData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const facilityId = url.pathname.split("/edit-facility-master/")[1]
      setFacilityId(facilityId)

      dispatch(getFacilityDetail(facilityId))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      facilityData()
    }
  }, [dispatch])

  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    }
  }, [location.pathname])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          facility_code: (facilities && facilities.facility_code) || "",
          facility_name: (facilities && facilities.facility_name) || "",
          facility_fees: (facilities && facilities.facility_fees) || "",
          is_paid: (facilities && facilities.is_paid) || false,
          is_active: (facilities && facilities.is_active) || false,
        }
      : {
          facility_code: "",
          facility_name: "",
          facility_fees: "",
          is_paid: false,
          is_active: false,
        },
    validationSchema: Yup.object({
      facility_code: Yup.string().required("Please Enter Facility Code"),
      facility_name: Yup.string().required("Please Enter Facility Name"),
      facility_fees: Yup.number(),
      is_paid: Yup.boolean(),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      if (isEditMode === true) {
        dispatch(updateFacility(facilityId, values, props.router.navigate))
      } else if (isEditMode === false) {
        dispatch(addNewFacility(values, props.router.navigate))
      }
    },
  })
  const [selectedOption, setSelectedOption] = useState(facilities?.is_active)
  const [selectedOptionNew, setSelectedOptionNew] = useState(
    Boolean(facilities?.is_paid)
  )

  const handleChange1 = () => {
    const newValue = !selectedOption
    setSelectedOption(newValue)
    validation.setFieldValue("is_active", newValue)
  }
  const handleChange2 = () => {
    const isPaidValue = !selectedOptionNew
    setSelectedOptionNew(isPaidValue)
    validation.setFieldValue("is_paid", isPaidValue)
  }

  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {" "}
              {isEditMode ? "Edit Facility Master" : "New Facility Master"}
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Settings / Master configuration / Facility Master" />
        </Col>
      </Row>
      <div className="mt-3"></div>

      <Card>
        <CardBody>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-facility_code-Input">
                    Facility Code*
                  </Label>
                  <Input
                    type="text"
                    name="facility_code"
                    className="form-control"
                    id="formrow-facility_code-Input"
                    placeholder="Enter Facility Code "
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.facility_code || ""}
                    invalid={
                      validation.touched.facility_code &&
                      validation.errors.facility_code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.facility_code &&
                  validation.errors.facility_code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.facility_code}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-facility_name-Input">
                    Facility Name*
                  </Label>
                  <Input
                    type="text"
                    name="facility_name"
                    className="form-control"
                    id="formrow-facility_name-Input"
                    placeholder="Enter Facility name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.facility_name || ""}
                    invalid={
                      validation.touched.facility_name &&
                      validation.errors.facility_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.facility_name &&
                  validation.errors.facility_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.facility_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div>
                  <Label htmlFor="formrow-ispaid-Input">Is Paid</Label>
                </div>
                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="paid"
                      name="is_paid"
                      onChange={e => {
                        validation.setFieldValue("is_paid", e.target.checked)
                      }}
                      checked={Boolean(validation?.values?.is_paid)}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      Yes
                    </Label>
                  </div>
                  {validation.touched.is_paid && validation.errors.is_paid && (
                    <FormFeedback type="invalid">
                      {validation.errors.is_paid}
                    </FormFeedback>
                  )}
                </Col>
              </Col>
              <Col>
                <div>
                  <Label htmlFor="formrow-ispaid-Input">Is Active</Label>
                </div>
                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="is_active"
                      name="is_active"
                      onChange={e => {
                        validation.setFieldValue("is_active", e.target.checked)
                      }}
                      checked={Boolean(validation?.values?.is_active)}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      Yes
                    </Label>
                  </div>
                  {validation.touched.is_active &&
                    validation.errors.is_active && (
                      <FormFeedback type="invalid">
                        {validation.errors.is_active}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
            </Row>
            <Row>
              {validation.values.is_paid && (
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-facility_fees-Input">
                      Facility Fees
                    </Label>
                    <Input
                      type="text"
                      name="facility_fees"
                      className="form-control"
                      id="formrow-facility_fees-Input"
                      placeholder="Enter Facility Code"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.facility_fees || ""}
                      invalid={
                        validation.touched.facility_fees &&
                        validation.errors.facility_fees
                          ? true
                          : false
                      }
                    />
                    {validation.touched.facility_fees &&
                    validation.errors.facility_fees ? (
                      <FormFeedback type="invalid">
                        {validation.errors.facility_fees}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-md">
                    {isEditMode ? "Edit" : "Submit"}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default withRouter(NewFacilityMaster)

NewFacilityMaster.propTypes = {
  history: PropTypes.object,
}
