import {
  GET_BILLING_CLIENT_DETAIL_SUCCESS,
  GET_BILLING_CLIENT_DETAIL_FAIL,
  GET_SERVICE_ENGAGEMENT_SUCCESS,
  GET_SERVICE_ENGAGEMENT_FAIL,
  GET_BILLING_COMPANY_LIST_SUCCESS,
  GET_BILLING_COMPANY_LIST_FAIL,
  GET_BILLING_COMPANY_DETAIL_SUCCESS,
  GET_BILLING_COMPANY_DETAIL_FAIL,
  GET_SUPPLY_LIST_SUCCESS,
  GET_SUPPLY_LIST_FAIL,
  GET_PLACE_OF_SUPPLY_SUCCESS,
  GET_PLACE_OF_SUPPLY_FAIL,
  GET_APPLICABLE_RATE_LIST_SUCCESS,
  GET_APPLICABLE_RATE_LIST_FAIL,
  GET_INVOICE_TYPE_MASTER_LIST_SUCCESS,
  GET_INVOICE_TYPE_MASTER_LIST_FAIL,
  ADD_BILLING_SETUP_SUCCESS,
  ADD_BILLING_SETUP_FAIL,
  GET_BILLING_CLIENT_LIST_FAIL,
  GET_BILLING_CLIENT_LIST_SUCCESS,
  GET_INVOICE_NUMBER_SUCCESS,
  GET_INVOICE_NUMBER_FAIL,
  ADD_NEW_BILL_SUCCESS,
  ADD_NEW_BILL_FAIL,
  GET_BILLING_REGISTER_SUCCESS,
  GET_BILLING_REGISTER_FAIL,
  GET_BILLING_DATA_SUCCESS,
  GET_BILLING_DATA_FAIL,
  EDIT_BILL_SUCCESS,
  EDIT_BILL_FAIL,
  GET_INVOICE_PREVIEW_DETAIL_SUCCESS,
  GET_INVOICE_PREVIEW_DETAIL_FAIL,
  GET_PAYMENT_MODE_SUCCESS,
  GET_PAYMENT_MODE_FAIL,
  ADD_RECORD_PAYMENT_SUCCESS,
  ADD_RECORD_PAYMENT_FAIL,
  DELETE_BILLING_REGISTER_SUCCESS,
  DELETE_BILLING_REGISTER_FAIL,
  ADD_BILLING_RECEIPT_SUCCESS,
  ADD_BILLING_RECEIPT_FAIL,
  EDIT_BILLING_RECEIPT_SUCCESS,
  EDIT_BILLING_RECEIPT_FAIL,
  GET_BILLING_RECEIPT_LIST_SUCCESS,
  GET_BILLING_RECEIPT_LIST_FAIL,
  GET_BILL_INVOICE_NO_LIST_SUCCESS,
  GET_BILL_INVOICE_NO_LIST_FAIL,
  GET_RECEIPT_NUMBER_SUCCESS,
  GET_RECEIPT_NUMBER_FAIL,
  SEND_MAIL_TO_CUSTOMER_SUCCESS,
  SEND_MAIL_TO_CUSTOMER_FAIL,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_FAIL,
  GET_BILLING_RECEIPT_DETAIL_SUCCESS,
  GET_BILLING_RECEIPT_DETAIL_FAIL,
  GET_RECORD_PAYMENT_DETAIL_SUCCESS,
  GET_RECORD_PAYMENT_DETAIL_FAIL,
  DELETE_BILLING_RECORD_SUCCESS,
  DELETE_BILLING_RECORD_FAIL,
  GET_BILLING_SETUP_DETAIL_SUCCESS,
  GET_BILLING_SETUP_DETAIL_FAIL,
  EDIT_BILLING_SETUP_SUCCESS,
  EDIT_BILLING_SETUP_FAIL,
  DELETE_BILLING_RECORD_PAYMENT_SUCCESS,
  DELETE_BILLING_RECORD_PAYMENT_FAIL,
  GET_SACHSN_CODE_BILLING_SETUP_SUCCESS,
  GET_SACHSN_CODE_BILLING_SETUP_FAIL,
  GET_SACHSN_CODE_BILLING_SUCCESS,
  GET_SACHSN_CODE_BILLING_FAIL,
  GET_RECURRING_BILL_SUCCESS,
  GET_RECURRING_BILL_FAIL,
  GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_SUCCESS,
  GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_FAIL,
  GET_PROJECT_LIST_IN_BILLING_SUCCESS,
  GET_PROJECT_LIST_IN_BILLING_FAIL,
  GET_PROJECT_DETAIL_IN_BILLING_SUCCESS,
  GET_PROJECT_DETAIL_IN_BILLING_FAIL,
  GET_PROJECT_UNIT_DETAIL_IN_BILLING_SUCCESS,
  GET_PROJECT_UNIT_DETAIL_IN_BILLING_FAIL,
  GET_BILLING_CLIENT_LIST_IN_RECEIPT_SUCCESS,
  GET_BILLING_CLIENT_LIST_IN_RECEIPT_FAIL,
  GET_BILLING_COMPANY_LIST_IN_RECEIPT_SUCCESS,
  GET_BILLING_COMPANY_LIST_IN_RECEIPT_FAIL,
  GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_SUCCESS,
  GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_FAIL,
  GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_SUCCESS,
  GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_FAIL,
  GET_GST_LOCATION_LIST_SUCCESS,
  GET_GST_LOCATION_LIST_FAIL,
  GET_GST_LOCATION_DETAIL_SUCCESS,
  GET_GST_LOCATION_DETAIL_FAIL,
  GET_COMPANY_FILTER_SUCCESS,
  GET_COMPANY_FILTER_FAIL,
  GET_COMPANY_LOCATION_FILTER_SUCCESS,
  GET_COMPANY_LOCATION_FILTER_FAIL,
  GET_CUSTOMER_FILTER_SUCCESS,
  GET_CUSTOMER_FILTER_FAIL,
  GET_PROJECT_FILTER_SUCCESS,
  GET_PROJECT_FILTER_FAIL,
  GET_FINANCIAL_YEAR_FILTER_SUCCESS,
  GET_FINANCIAL_YEAR_FILTER_FAIL,
  GET_COMPANY_FILTER_FOR_RECEIPT_SUCCESS,
  GET_COMPANY_FILTER_FOR_RECEIPT_FAIL,
  GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_SUCCESS,
  GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_FAIL,
  GET_CUSTOMER_FILTER_FOR_RECEIPT_SUCCESS,
  GET_CUSTOMER_FILTER_FOR_RECEIPT_FAIL,
  GET_PROJECT_FILTER_FOR_RECEIPT_SUCCESS,
  GET_PROJECT_FILTER_FOR_RECEIPT_FAIL,
  GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_SUCCESS,
  GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_FAIL,
  GET_RECEIPT_PREVIEW_SUCCESS,
  GET_RECEIPT_PREVIEW_FAIL,
  SEND_RECEIPT_MAIL_TO_CUSTOMER_SUCCESS,
  SEND_RECEIPT_MAIL_TO_CUSTOMER_FAIL,
  DELETE_CONSUMPTION_REGISTER_SUCCESS,
  DELETE_CONSUMPTION_REGISTER_FAIL,
  GET_CONSUMPTION_REGISTER_SUCCESS,
  GET_CONSUMPTION_REGISTER_FAIL,
  ADD_NEW_CONSUMPTION_SUCCESS,
  ADD_NEW_CONSUMPTION_FAIL,
  EDIT_CONSUMPTION_SUCCESS,
  EDIT_CONSUMPTION_FAIL,
  GET_CONSUMPTION_REGISTER_LIST_SUCCESS,
  GET_CONSUMPTION_REGISTER_LIST_FAIL,
  GET_CONSUMPTION_DATA_SUCCESS,
  GET_CONSUMPTION_DATA_FAIL,
  GET_BILLING_CREDIT_NOTE_LIST_SUCCESS,
  GET_BILLING_CREDIT_NOTE_LIST_FAIL,
  GET_CREDIT_NUMBER_SUCCESS,
  GET_CREDIT_NUMBER_FAIL,
  GET_COMPONENTS_FOR_CREDIT_NOTE_FAIL,
  GET_COMPONENTS_FOR_CREDIT_NOTE_SUCCESS,
  ADD_CREDIT_NOTE_SUCCESS,
  ADD_CREDIT_NOTE_FAIL,
  GET_CREDIT_NOTE_REASONS_SUCCESS,
  GET_CREDIT_NOTE_REASONS_FAIL,
  GET_CREDIT_NOTE_INVOICE_LIST_SUCCESS,
  GET_CREDIT_NOTE_INVOICE_LIST_FAIL,
  GET_CREDIT_DATA_SUCCESS,
  GET_CREDIT_DATA_FAIL,
  EDIT_CREDIT_NOTE_FAIL,
  EDIT_CREDIT_NOTE_SUCCESS,
  DELETE_CREDIT_NOTE_SUCCESS,
  DELETE_CREDIT_NOTE_FAIL,
  GET_CREDIT_INVOICE_PREVIEW_DETAIL_SUCCESS,
  GET_CREDIT_INVOICE_PREVIEW_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  billingData: {},
  creditData: {},
  recordPayment: {},
  clientList: [],
  clientListInReceipt: [],
  companyListInReceipt: [],
  clientDetail: {},
  receiptDetail: {},
  invoiceNumber: {},
  receiptNumber: {},
  serviceEngagement: {},
  companyList: [],
  companyDetail: {},
  companyDetailInReceipt: {},
  supplyList: [],
  placeOfSupply: [],
  applicableRateList: [],
  invoiceTypeMasterList: [],
  billInvoiceNo: [],
  addBillingSetup: [],
  editBillingSetup: [],
  billSetupDetail: {},
  addNewBill: [],
  editBill: [],
  billingRegister: [],
  recurringBills: [],
  invoicePreviewDetail: {},
  creditInvoicePreviewDetail: {},
  addRecordPayment: [],
  paymentMode: [],
  billingReceipt: [],
  //
  addNewReceipt: [],
  editReceipt: [],
  receiptList: [],
  sendMail: [],
  sendReceiptMail: [],
  codesBilling: [],
  codesBillingSetup: [],
  //
  assignedProjectUnit: [],
  projectList: [],
  projectDetail: {},
  projectUnitDetail: {},
  amountDetailInReceipt: {},
  //
  gstLocationList: [],
  gstLocationDetail: {},
  downloadInvoice: {},

  //
  companyFilter: [],
  locationFilter: [],
  customerFilter: [],
  projectFilter: [],
  financialYearFilter: [],
  //
  companyFilterForReceipt: [],
  locationFilterForReceipt: [],
  customerFilterForReceipt: [],
  projectFilterForReceipt: [],
  financialYearFilterForReceipt: [],

  //
  receiptPreview: [],
  //consumption
  consumptionData: {},
  addNewConsumption: [],
  editConsumption: [],
  //creditNote
  creditNoteList: [],
  addCreditNote: [],
  editCreditNote: [],
  creditNoteComponents: [],
  creditNoteReasons: [],
  creditNoteInvoiceList: [],
  creditNumber: {},
  error: {},
  loading: true,
}

const billing = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BILLING_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clientList: action.payload,
        loading: true,
      }

    case GET_BILLING_CLIENT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BILLING_CREDIT_NOTE_LIST_SUCCESS:
      return {
        ...state,
        creditNoteList: action.payload,
        loading: true,
      }

    case GET_BILLING_CREDIT_NOTE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_GST_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        gstLocationList: action.payload,
        loading: true,
      }

    case GET_GST_LOCATION_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILLING_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        clientDetail: action.payload,
        loading: true,
      }

    case GET_BILLING_CLIENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERVICE_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        serviceEngagement: action.payload,
        loading: true,
      }

    case GET_SERVICE_ENGAGEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BILLING_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        companyList: action.payload,
        loading: true,
      }

    case GET_BILLING_COMPANY_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BILLING_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        companyDetail: action.payload,
        loading: true,
      }

    case GET_BILLING_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SUPPLY_LIST_SUCCESS:
      return {
        ...state,
        supplyList: action.payload,
        loading: true,
      }

    case GET_SUPPLY_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PLACE_OF_SUPPLY_SUCCESS:
      return {
        ...state,
        placeOfSupply: action.payload,
        loading: true,
      }

    case GET_PLACE_OF_SUPPLY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_APPLICABLE_RATE_LIST_SUCCESS:
      return {
        ...state,
        applicableRateList: action.payload,
        loading: true,
      }

    case GET_APPLICABLE_RATE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_INVOICE_TYPE_MASTER_LIST_SUCCESS:
      return {
        ...state,
        invoiceTypeMasterList: action.payload,
        loading: true,
      }

    case GET_INVOICE_TYPE_MASTER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BILLING_SETUP_SUCCESS:
      return {
        ...state,
        addBillingSetup: [...state.addBillingSetup, action.payload],
      }

    case ADD_BILLING_SETUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //edit billing setup
    case EDIT_BILLING_SETUP_SUCCESS:
      return {
        ...state,
        editBillingSetup: [...state.editBillingSetup, action.payload],
      }

    case EDIT_BILLING_SETUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //add new bill
    case ADD_NEW_BILL_SUCCESS:
      return {
        ...state,
        addNewBill: [...state.addNewBill, action.payload],
      }

    case ADD_NEW_BILL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case EDIT_BILL_SUCCESS:
      return {
        ...state,
        editBill: [...state.editBill, action.payload],
      }

    case EDIT_BILL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_INVOICE_NUMBER_SUCCESS:
      return {
        ...state,
        invoiceNumber: action.payload,
        loading: true,
      }

    case GET_INVOICE_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILLING_REGISTER_SUCCESS:
      return {
        ...state,
        billingRegister: action.payload,
        loading: true,
      }

    case GET_BILLING_REGISTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILLING_DATA_SUCCESS:
      return {
        ...state,
        billingData: action.payload,
        loading: true,
      }

    case GET_BILLING_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_INVOICE_PREVIEW_DETAIL_SUCCESS:
      return {
        ...state,
        invoicePreviewDetail: action.payload,
        loading: true,
      }

    case GET_INVOICE_PREVIEW_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        paymentMode: action.payload,
        loading: true,
      }

    case GET_PAYMENT_MODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_RECORD_PAYMENT_SUCCESS:
      return {
        ...state,
        addRecordPayment: [...state.addRecordPayment, action.payload],
      }

    case ADD_RECORD_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BILLING_REGISTER_SUCCESS:
      return {
        ...state,
        billingRegister: state.billingRegister.data.filter(
          bill => bill.billing_id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_BILLING_REGISTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BILLING_RECEIPT_SUCCESS:
      return {
        ...state,
        addNewReceipt: [...state.addRecordPayment, action.payload],
      }

    case ADD_BILLING_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case EDIT_BILLING_RECEIPT_SUCCESS:
      return {
        ...state,
        editReceipt: [...state.editBill, action.payload],
      }

    case EDIT_BILLING_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILLING_RECEIPT_LIST_SUCCESS:
      return {
        ...state,
        receiptList: action.payload,
        loading: true,
      }

    case GET_BILLING_RECEIPT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILL_INVOICE_NO_LIST_SUCCESS:
      return {
        ...state,
        billInvoiceNo: action.payload,
        loading: true,
      }

    case GET_BILL_INVOICE_NO_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_RECEIPT_NUMBER_SUCCESS:
      return {
        ...state,
        receiptNumber: action.payload,
        loading: true,
      }

    case GET_RECEIPT_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SEND_MAIL_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        sendMail: [...state.sendMail, action.payload],
      }

    case SEND_MAIL_TO_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SEND_RECEIPT_MAIL_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        sendReceiptMail: [...state.sendReceiptMail, action.payload],
      }

    case SEND_RECEIPT_MAIL_TO_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DOWNLOAD_INVOICE_SUCCESS:
      return {
        ...state,
        downloadInvoice: action.payload,
        loading: true,
      }

    case DOWNLOAD_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILLING_RECEIPT_DETAIL_SUCCESS:
      return {
        ...state,
        receiptDetail: action.payload,
        loading: true,
      }

    case GET_BILLING_RECEIPT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_RECORD_PAYMENT_DETAIL_SUCCESS:
      return {
        ...state,
        recordPayment: action.payload,
        loading: true,
      }

    case GET_RECORD_PAYMENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BILLING_RECORD_SUCCESS:
      return {
        ...state,
        billingReceipt: state.billingReceipt.filter(
          bill => bill.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_BILLING_RECORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //billing setup get
    case GET_BILLING_SETUP_DETAIL_SUCCESS:
      return {
        ...state,
        billSetupDetail: action.payload,
        loading: true,
      }

    case GET_BILLING_SETUP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BILLING_RECORD_PAYMENT_SUCCESS:
      return {
        ...state,
        billingRecordPayment: state.billingRecordPayment.filter(
          bill => bill.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_BILLING_RECORD_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SACHSN_CODE_BILLING_SETUP_SUCCESS:
      return {
        ...state,
        codesBillingSetup: action.payload,
        loading: true,
      }

    case GET_SACHSN_CODE_BILLING_SETUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SACHSN_CODE_BILLING_SUCCESS:
      return {
        ...state,
        codesBilling: action.payload,
        loading: true,
      }

    case GET_SACHSN_CODE_BILLING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_RECURRING_BILL_SUCCESS:
      return {
        ...state,
        recurringBills: action.payload,
        loading: true,
      }

    case GET_RECURRING_BILL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_SUCCESS:
      return {
        ...state,
        assignedProjectUnit: action.payload,
        loading: true,
      }

    case GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_LIST_IN_BILLING_SUCCESS:
      return {
        ...state,
        projectList: action.payload,
        loading: true,
      }

    case GET_PROJECT_LIST_IN_BILLING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_DETAIL_IN_BILLING_SUCCESS:
      return {
        ...state,
        projectDetail: action.payload,
        loading: true,
      }

    case GET_PROJECT_DETAIL_IN_BILLING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_UNIT_DETAIL_IN_BILLING_SUCCESS:
      return {
        ...state,
        projectUnitDetail: action.payload,
        loading: true,
      }

    case GET_PROJECT_UNIT_DETAIL_IN_BILLING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILLING_CLIENT_LIST_IN_RECEIPT_SUCCESS:
      return {
        ...state,
        clientListInReceipt: action.payload,
        loading: true,
      }

    case GET_BILLING_CLIENT_LIST_IN_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BILLING_COMPANY_LIST_IN_RECEIPT_SUCCESS:
      return {
        ...state,
        companyListInReceipt: action.payload,
        loading: true,
      }

    case GET_BILLING_COMPANY_LIST_IN_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_SUCCESS:
      return {
        ...state,
        companyDetailInReceipt: action.payload,
        loading: true,
      }

    case GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_SUCCESS:
      return {
        ...state,
        amountDetailInReceipt: action.payload,
        loading: true,
      }

    case GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GST_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        gstLocationList: action.payload,
        loading: true,
      }

    case GET_GST_LOCATION_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GST_LOCATION_DETAIL_SUCCESS:
      return {
        ...state,
        gstLocationDetail: action.payload,
        loading: true,
      }

    case GET_GST_LOCATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //

    case GET_GST_LOCATION_DETAIL_SUCCESS:
      return {
        ...state,
        gstLocationDetail: action.payload,
        loading: true,
      }

    case GET_GST_LOCATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //
    //Register
    case GET_COMPANY_FILTER_SUCCESS:
      return {
        ...state,
        companyFilter: action.payload,
        loading: true,
      }

    case GET_COMPANY_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPANY_LOCATION_FILTER_SUCCESS:
      return {
        ...state,
        locationFilter: action.payload,
        loading: true,
      }

    case GET_COMPANY_LOCATION_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CUSTOMER_FILTER_SUCCESS:
      return {
        ...state,
        customerFilter: action.payload,
        loading: true,
      }

    case GET_CUSTOMER_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_FILTER_SUCCESS:
      return {
        ...state,
        projectFilter: action.payload,
        loading: true,
      }

    case GET_PROJECT_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_FINANCIAL_YEAR_FILTER_SUCCESS:
      return {
        ...state,
        financialYearFilter: action.payload,
        loading: true,
      }

    case GET_FINANCIAL_YEAR_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //receipt
    case GET_COMPANY_FILTER_FOR_RECEIPT_SUCCESS:
      return {
        ...state,
        companyFilterForReceipt: action.payload,
        loading: true,
      }

    case GET_COMPANY_FILTER_FOR_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_SUCCESS:
      return {
        ...state,
        locationFilterForReceipt: action.payload,
        loading: true,
      }

    case GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CUSTOMER_FILTER_FOR_RECEIPT_SUCCESS:
      return {
        ...state,
        customerFilterForReceipt: action.payload,
        loading: true,
      }

    case GET_CUSTOMER_FILTER_FOR_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_FILTER_FOR_RECEIPT_SUCCESS:
      return {
        ...state,
        projectFilterForReceipt: action.payload,
        loading: true,
      }

    case GET_PROJECT_FILTER_FOR_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_SUCCESS:
      return {
        ...state,
        financialYearFilterForReceipt: action.payload,
        loading: true,
      }

    case GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_RECEIPT_PREVIEW_SUCCESS:
      return {
        ...state,
        receiptPreview: action.payload,
        loading: true,
      }

    case GET_RECEIPT_PREVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    ///CONSUMPTION
    case GET_CONSUMPTION_REGISTER_LIST_SUCCESS:
      return {
        ...state,
        consumptionRegister: action.payload,
        loading: true,
      }

    case GET_CONSUMPTION_REGISTER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CONSUMPTION_DATA_SUCCESS:
      return {
        ...state,
        consumptionData: action.payload,
        loading: true,
      }

    case GET_CONSUMPTION_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //add new bill
    case ADD_NEW_CONSUMPTION_SUCCESS:
      return {
        ...state,
        addNewConsumption: [...state.addNewConsumption, action.payload],
      }

    case ADD_NEW_CONSUMPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case EDIT_CONSUMPTION_SUCCESS:
      return {
        ...state,
        editConsumption: [...state.editConsumption, action.payload],
      }

    case EDIT_CONSUMPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CONSUMPTION_REGISTER_SUCCESS:
      return {
        ...state,
        consumptionRegister: state.consumptionRegister.data.filter(
          consumption =>
            consumption.compsumption_id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_CONSUMPTION_REGISTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CREDIT_NUMBER_SUCCESS:
      return {
        ...state,
        creditNumber: action.payload,
        loading: true,
      }

    case GET_CREDIT_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPONENTS_FOR_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        creditNoteComponents: action.payload,
        loading: true,
      }

    case GET_COMPONENTS_FOR_CREDIT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CREDIT_NOTE_REASONS_SUCCESS:
      return {
        ...state,
        creditNoteReasons: action.payload,
        loading: true,
      }

    case GET_CREDIT_NOTE_REASONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        addCreditNote: [...state.addCreditNote, action.payload],
      }

    case ADD_CREDIT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case EDIT_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        editCreditNote: [...state.editCreditNote, action.payload],
      }

    case EDIT_CREDIT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CREDIT_NOTE_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        creditNoteInvoiceList: action.payload,
        loading: true,
      }

    case GET_CREDIT_NOTE_INVOICE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CREDIT_DATA_SUCCESS:
      return {
        ...state,
        creditData: action.payload,
        loading: true,
      }

    case GET_CREDIT_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        creditNoteList: state.creditNoteList.data.filter(
          bill => bill.billing_id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_CREDIT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CREDIT_INVOICE_PREVIEW_DETAIL_SUCCESS:
      return {
        ...state,
        creditInvoicePreviewDetail: action.payload,
        loading: true,
      }

    case GET_CREDIT_INVOICE_PREVIEW_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default billing
