import {
  GET_CLIENT_USER_LIST,
  GET_CLIENT_USER_LIST_SUCCESS,
  GET_CLIENT_USER_LIST_FAIL,
  GET_CLIENT_USER_DETAIL,
  GET_CLIENT_USER_DETAIL_SUCCESS,
  GET_CLIENT_USER_DETAIL_FAIL,
  ADD_NEW_CLIENT_USER,
  ADD_NEW_CLIENT_USER_SUCCESS,
  ADD_NEW_CLIENT_USER_FAIL,
  UPDATE_CLIENT_USER,
  UPDATE_CLIENT_USER_SUCCESS,
  UPDATE_CLIENT_USER_FAIL,
  DELETE_CLIENT_USER,
  DELETE_CLIENT_USER_SUCCESS,
  DELETE_CLIENT_USER_FAIL,
} from "./actionTypes"

export const getClientUserList = (value) => ({
  type: GET_CLIENT_USER_LIST,
  value,
})

export const getClientUserListSuccess = users => ({
  type: GET_CLIENT_USER_LIST_SUCCESS,
  payload: users,
})
export const getClientUserListFail = error => ({
  type: GET_CLIENT_USER_LIST_FAIL,
  payload: error,
})

export const getClientUserDetail = userId => ({
  type: GET_CLIENT_USER_DETAIL,
  userId,
})

export const getClientUserDetailSuccess = userDetail => ({
  type: GET_CLIENT_USER_DETAIL_SUCCESS,
  payload: userDetail,
})

export const getClientUserDetailFail = error => ({
  type: GET_CLIENT_USER_DETAIL_FAIL,
  payload: error,
})

export const addNewClientUser = (user, history) => ({
  type: ADD_NEW_CLIENT_USER,
  payload: { user, history },
  status: true,
})

export const addNewClientUserSuccess = user => ({
  type: ADD_NEW_CLIENT_USER_SUCCESS,
  payload: user,
})

export const addNewClientUserFail = error => ({
  type: ADD_NEW_CLIENT_USER_FAIL,
  payload: error,
})

export const updateClientUser = (id, user, history) => ({
  type: UPDATE_CLIENT_USER,
  payload: { id, user, history },
})

export const updateClientUserSuccess = user => ({
  type: UPDATE_CLIENT_USER_SUCCESS,
  payload: user,
})

export const updateClientUserFail = error => ({
  type: UPDATE_CLIENT_USER_FAIL,
  payload: error,
})

export const deleteClientUser = user => ({
  type: DELETE_CLIENT_USER,
  payload: user,
})

export const deleteClientUserSuccess = user => ({
  type: DELETE_CLIENT_USER_SUCCESS,
  payload: user,
})

export const deleteClientUserFail = error => ({
  type: DELETE_CLIENT_USER_FAIL,
  payload: error,
})
