import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Label,
  Input,
  navigate,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import Swal from "sweetalert2"

import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import VLogo from "../../../assets/images/VIP/VLogo.png"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import { getExpenseDetail } from "store/masters/expense/actions"
import { addNewExpense, updateExpense } from "helpers/fakebackend_helper"
// import { updateExpense } from 'helpers/fakebackend_helper';

const NewExpenseService = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const dispatch = useDispatch()
  const expenseState = state => state.expenses.expenseDetail.Data
  // const loadingState = state => state.facilities.loading
  const data = useSelector(expenseState)
  // const loadingData = useSelector(loadingState)


  const expensesProperties = createSelector(
    expenseState,
    // loadingState,
    (expenses, loading) => {
      return {
        expenses: expenses,
        loading: loading,
      }
    }
  )

  const { expenses, loading } = useSelector(expensesProperties)

  const [specificUser, setSpecificUser] = useState(null)

  const [expenseId, setExpenseId] = useState()

  const expenseData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const expenseId = url.pathname.split("/edit-expense-master-service/")[1]
      setExpenseId(expenseId)
      
      dispatch(getExpenseDetail(expenseId))
      

      // setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      expenseData()
    }
  }, [dispatch])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          expense_title: (expenses && expenses.expense_title) || "",
          expense_type: (expenses && expenses.expense_type) || "Internal",
          is_active: (expenses && expenses.is_active) || false,
          // is_active:true,
        }
      : {
          expense_title: "",
          expense_type: "Internal",
          is_active: false,
        },
    validationSchema: Yup.object({
      expense_title: Yup.string().required("Please Enter Expense title"),
      expense_type: Yup.string().required("Please Select Expense Type"),
      // is_active: Yup.boolean().required("Please Select Valid Option"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      try {
        // Assuming addNewVendor returns a Promise
        // const response =  dispatch(addNewVendor(values));
        // let response
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        if (isEditMode === true) {
          // const updatedResponse = await dispatch(onUpdateVendor(vendorId,values))
          const response = await updateExpense(expenseId, values)
          // response2 = onUpdateVendor(vendorId,values)

          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Expense Type has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/expense-master")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has been saved.",
            })
          }
        } else if (isEditMode === false) {
          // const response = await dispatch(addNewVendor(values));
          const response = await addNewExpense(values)
          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Expense Type has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/expense-master")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Expense Type has not been saved.",
            })
          }
          // response2 = addNewVendor(values)

          // alert('hello')

        }
      } catch (error) {
        // Handle the error if needed
        loadingSwal.close()

        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },

   
  })

  const navigate = useNavigate()
  const toggleToast = () => {
    setToast(!toast)
  }
  const toggleToast2 = () => {
    setToast2(!toast2)
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  useEffect(() => {
    //check if the current path includes 'edit-expense'
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)
    setToast2(true)
  }

  const [selectedOption, setSelectedOption] = useState(expenses?.is_active)

  const handleChange1 = () => {
    const newValue = !selectedOption
    setSelectedOption(newValue)
    validation.setFieldValue("is_active", newValue)
  }

  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {" "}
              {isEditMode ? "Edit Expense" : "New Expense"}{" "}
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Settings / Master configuration / Expense Master" />
        </Col>
      </Row>
      <div className="mt-3"></div>

      <Card>
        <CardBody>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-newexpense-Input">
                    Expense Title*
                  </Label>
                  <Input
                    type="text"
                    name="expense_title"
                    className="form-control"
                    id="formrow-newexpense-Input"
                    placeholder="Enter Expense title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.expense_title || ""}
                    invalid={
                      validation.touched.expense_title &&
                      validation.errors.expense_title
                        ? true
                        : false
                    }
                  />
                  {validation.touched.expense_title &&
                  validation.errors.expense_title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.expense_title}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                    <div className="mb-3">
                      <Label htmlFor="expense_type">
                        Expense type*
                      </Label>
                      <select
                        name="expense_type"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.expense_type}
                        className={`form-select ${
                          validation.touched.expense_type &&
                          validation.errors.expense_type
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        {!validation.values.expense_type && (
                          <option>Select Expense type</option>
                        )}
                        <option value="Internal">Internal</option>
                        <option value="External">External</option>
                     
                      </select>
                      {validation.touched.expense_type &&
                      validation.errors.expense_type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.expense_type}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
              <Col>
                <div>
                  <div className="mb-3"></div>
                  <Label htmlFor="formrow-isactive-Input">Is Active</Label>
                </div>

                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="is_active"
                      name="is_active"
                      onChange={e => {
                        validation.setFieldValue("is_active", e.target.checked)
                      }}
                      checked={Boolean(validation?.values?.is_active)}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      Yes
                    </Label>
                  </div>
                  {validation.touched.is_active &&
                    validation.errors.is_active && (
                      <FormFeedback type="invalid">
                        {validation.errors.is_active}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-md">
                    {isEditMode ? "Edit" : "Submit"}
                  </button>
                </div>
                {toast2 && (
                  <div
                    className="position-fixed top-0 end-0 p-3"
                    style={{ zIndex: "1005" }}
                  >
                    <Toast isOpen={toast2} role="aleart">
                      <ToastHeader toggle={() => setToast2(!toast2)}>
                        <img src={VLogo} alt="" className="me-2" height="18" />
                        <strong className="me-auto">VIP Mall</strong>
                      </ToastHeader>
                      <ToastBody>
                        {!isEditMode
                          ? "Submitted Successfully"
                          : "Edited Successfully"}
                      </ToastBody>
                    </Toast>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default NewExpenseService
