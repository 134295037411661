

//CUSTOMER FILTER

export const GET_CUSTOMER_FILTER_FOR_CONSUMPTION = "GET_CUSTOMER_FILTER_FOR_CONSUMPTION"
export const GET_CUSTOMER_FILTER_FOR_CONSUMPTION_SUCCESS = "GET_CUSTOMER_FILTER_FOR_CONSUMPTION_SUCCESS"
export const GET_CUSTOMER_FILTER_FOR_CONSUMPTION_FAIL = "GET_CUSTOMER_FILTER_FOR_CONSUMPTION_FAIL"

//PROJECT FILTER

export const GET_PROJECT_FILTER_FOR_CONSUMPTION="GET_PROJECT_FILTER_FOR_CONSUMPTION"
export const GET_PROJECT_FILTER_FOR_CONSUMPTION_SUCCESS="GET_PROJECT_FILTER_FOR_CONSUMPTION_SUCCESS"
export const GET_PROJECT_FILTER_FOR_CONSUMPTION_FAIL="GET_PROJECT_FILTER_FOR_CONSUMPTION_FAIL"

//FINANCIAL YEAR FILTER

export const GET_UNIT_FOR_CONSUMPTION="GET_UNIT_FOR_CONSUMPTION"
export const GET_UNIT_FOR_CONSUMPTION_SUCCESS="GET_UNIT_FOR_CONSUMPTION_SUCCESS"
export const GET_UNIT_FOR_CONSUMPTION_FAIL="GET_UNIT_FOR_CONSUMPTION_FAIL"

export const GET_CONSUMPTION_FILTER="GET_CONSUMPTION_FILTER"
export const GET_CONSUMPTION_FILTER_SUCCESS="GET_CONSUMPTION_FILTER_SUCCESS"
export const GET_CONSUMPTION_FILTER_FAIL="GET_CONSUMPTION_FILTER_FAIL"