import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_COMPLIANCES, GET_ACTIVE_COMPLIANCES, GET_COMPLIANCE_DETAIL, ADD_NEW_COMPLIANCE, DELETE_COMPLIANCE, UPDATE_COMPLIANCE ,GET_PROJECT_COMPLIANCES } from "./actionTypes"
import {
  getCompliancesSuccess,
  getCompliancesFail,
  getComplianceDetailSuccess,
  getComplianceDetailFail,
  addComplianceFail,
  addComplianceSuccess,
  updateComplianceSuccess,
  updateComplianceFail,
  deleteComplianceSuccess,
  deleteComplianceFail,
  getProjectComplianceSuccess,
  getProjectComplianceFail,
 
  getActiveCompliancesFail,
  getActiveCompliancesSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getCompliances, getComplianceDetails, addNewCompliance, updateCompliance, deleteCompliance , getProjectCompliances, getActiveCompliances} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

function* fetchCompliances() {
  try {
    const response = yield call(getCompliances)
    yield put(getCompliancesSuccess(response))
  } catch (error) {
    yield put(getCompliancesFail(error))
  } 
}

function* fetchActiveCompliances() {
  try {
    const response = yield call(getActiveCompliances)
    yield put(getActiveCompliancesSuccess(response))
  } catch (error) {
    yield put(getActiveCompliancesFail(error))
  } 
}

function* fetchComplianceDetail({ complianceId }) {
  try {
    const response = yield call(getComplianceDetails, complianceId)

    yield put(getComplianceDetailSuccess(response))
  } catch (error) {
    yield put(getComplianceDetailFail(error))
  }
}

function* onUpdateCompliance({ payload: { id, compliance } }) {
  try {
    const response = yield call(updateCompliance,id, compliance)
    yield put(updateComplianceSuccess(response))
    // toast.success("Compliance Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateComplianceFail(error))
    // toast.error("Compliance Update Failded", { autoClose: 2000 })
  }
  const response2 = yield put(updateFacilitySuccess(response))

}

function* onDeleteCompliance({ payload: compliance }) {
  try {
    const response = yield call(deleteCompliance, compliance)
    yield put(deleteComplianceSuccess(response))
    // toast.success("Compliance Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteComplianceFail(error))
    // toast.error("Compliance Delete Failded", { autoClose: 2000 })
  }
}

function* onAddNewCompliance({ payload: compliance }) {
  try {
    const response = yield call(addNewCompliance, compliance)
    yield put(addComplianceSuccess(response))
    toast.success("Compliance Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addComplianceFail(error))
    toast.error("Compliance Add Failded", { autoClose: 2000 })
  }
}
function* onAddProjectCompliances({ payload: compliance }) {
  try {
    const response = yield call(getProjectCompliances, compliance)
    yield put(getProjectComplianceSuccess(response))
  } catch (error) {
    yield put(getProjectComplianceFail(error))
  }
}

function* compliancesSaga() {
  yield takeEvery(GET_COMPLIANCES, fetchCompliances)
  yield takeEvery(GET_COMPLIANCE_DETAIL, fetchComplianceDetail)
  yield takeEvery(ADD_NEW_COMPLIANCE, onAddNewCompliance)
  yield takeEvery(UPDATE_COMPLIANCE, onUpdateCompliance)
  yield takeEvery(DELETE_COMPLIANCE, onDeleteCompliance)
  yield takeEvery(GET_PROJECT_COMPLIANCES, onAddProjectCompliances)
  yield takeEvery(GET_ACTIVE_COMPLIANCES, fetchActiveCompliances)

}

export default compliancesSaga
