import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"

// Crypto Redux States
import {
  GET_DEBIT_NOTE_LIST,
  GET_DEBIT_NOTE_DETAIL,
  ADD_NEW_DEBIT_NOTE,
  UPDATE_DEBIT_NOTE,
  DELETE_DEBIT_NOTE,
  GET_VENDOR_INVOICE_DETAIL,
} from "./actionTypes"
import {
  getDebitNoteListSuccess,
  getDebitNoteListFail,
  getDebitNoteDetailSuccess,
  getDebitNoteDetailFail,
  addNewDebitNoteSuccess,
  addNewDebitNoteFail,
  updateDebitNoteSuccess,
  updateDebitNoteFail,
  deleteDebitNoteSuccess,
  deleteDebitNoteFail,
  getVendorInvoiceDetailSuccess,
  getVendorInvoiceDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDebitNoteList,
  getDebitNoteDetail,
  addNewDebitNote,
  updateDebitNote,
  deleteDebitNote,
  getVendorInvoiceDetail,
} from "helpers/fakebackend_helper"

function* fetchDebitNoteList({value}) {
  try {
    const response = yield call(getDebitNoteList,value)
    yield put(getDebitNoteListSuccess(response))
  } catch (error) {
    yield put(getDebitNoteListFail(error))
  }
}

function* fetchDebitNoteDetail({ debitId }) {
  try {
    const response = yield call(getDebitNoteDetail, debitId)
    yield put(getDebitNoteDetailSuccess(response))
  } catch (error) {
    yield put(getDebitNoteDetailFail(error))
  }
}

function* onAddNewDebitNote({ payload: { debit, history } }) {
  try {
    const response = yield call(addNewDebitNote, debit)
    if (response.status === true) {
      yield put(addNewDebitNoteSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Debit Note Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/vendor/debit-note-list")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewDebitNoteFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onUpdateDebitNote({ payload: { id, debit, history } }) {
  try {
    const response = yield call(updateDebitNote, id, debit)
    if (response && response.status === true) {
      yield put(updateDebitNoteSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Debit Note Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/vendor/debit-note-list")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateDebitNoteFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteDebitNote({ payload: debit }) {
  try {
    const response = yield call(deleteDebitNote, debit)
    console.log(response)
    const response2 = yield put(deleteDebitNoteSuccess(response))
    console.log(response2)
    // yield put(deleteSACHSNCodeSuccess(response))
    if (response2.payload.status) {
      const debitNoteListResponse = yield call(getDebitNoteList)
      yield put(getDebitNoteListSuccess(debitNoteListResponse))
    } else {
      console.error("Error in delete Debit Note:", response.error)
    }
  } catch (error) {
    yield put(deleteDebitNoteFail(error))
  }
}

function* fetchVendorInvoiceDetail({ debitId }) {
  try {
    const response = yield call(getVendorInvoiceDetail, debitId)
    yield put(getVendorInvoiceDetailSuccess(response))
  } catch (error) {
    yield put(getVendorInvoiceDetailFail(error))
  }
}

function* debitNoteSaga() {
  yield takeEvery(GET_DEBIT_NOTE_LIST, fetchDebitNoteList)
  yield takeEvery(GET_DEBIT_NOTE_DETAIL, fetchDebitNoteDetail)
  yield takeEvery(ADD_NEW_DEBIT_NOTE, onAddNewDebitNote)
  yield takeEvery(UPDATE_DEBIT_NOTE, onUpdateDebitNote)
  yield takeEvery(DELETE_DEBIT_NOTE, onDeleteDebitNote)
  yield takeEvery(GET_VENDOR_INVOICE_DETAIL, fetchVendorInvoiceDetail)
}

export default debitNoteSaga
