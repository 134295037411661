import React, { useState, useEffect } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  CardImg,
  CardText,
  UncontrolledTooltip,
  Alert,
} from "reactstrap"
import { isEmpty } from "lodash"

import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { deleteGallery, getGallery, getVideo } from "store/actions"
import DeleteModal from "components/Common/DeleteModal"

const ProjectGalleryView = ({ galleryMode, galleryId }) => {
  const dispatch = useDispatch()
  const getGalleryState = state => state.projects.galleries.data
  const Gallery = useSelector(getGalleryState) || []

  const getVideoState = state => state.projects.videos.data
  const Video = useSelector(getVideoState) || []

  const [userId, setUserId] = useState()
  const fetchData = () => {
    const url = new URL(window.location.href)
    const userId = url.pathname.split("/project-details/")[1]

    setUserId(userId)

    const response = dispatch(getGallery(userId))
    dispatch(getVideo(userId))
  }
  useEffect(() => {
    fetchData()
  }, [dispatch])

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    // if (contact && contact.id) {
    dispatch(deleteGallery(deleteId.id))
    // }
    // onPaginationPageChange(1);
    setDeleteModal(false)
    // navigate('/settings/vendor-master')

    dispatch(getGallery(userId))
    dispatch(getGallery(userId))
  }
  const embedYouTubeUrl = url => {
    const videoId = url?.split("v=")[1]
    return `https://www.youtube.com/embed/${videoId}`
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        // delete ='vendor'
      />
      <Container fluid={true}>
        <Row>
          <Col>
            <CardTitle
              className="text-center mb-4"
              style={{ fontSize: "24px" }}
            >
              PROJECT PHOTOS
            </CardTitle>
          </Col>
        </Row>
        {!isEmpty(Gallery) ? (
          <Row>
            {Gallery.map(item => (
              <Col key={item.id} lg={4}>
                <Card>
                  <CardBody>
                    <CardImg
                      top
                      className="rounded-3 img-fluid"
                      src={item.image_paths}
                      alt={`Image ${item.id}`}
                      style={{ height: "250px" }}
                    />
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Alert color="warning" role="alert">
            The Project Gallery Photos is currently empty. Please add photos to
            populate the gallery.
          </Alert>
        )}

        <Row>
          <Col>
            <CardTitle
              className="text-center mb-4"
              style={{ fontSize: "24px" }}
            >
              PROJECT VIDEOS
            </CardTitle>
          </Col>
        </Row>
        {!isEmpty(Video) ? (
          <Row>
            {Video.map((video, id) => (
              <Col lg={4} key={id}>
                <Card>
                  <CardBody>
                    {/* <div >
                    {video.video_url}
                  </div> */}
                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                      <iframe
                        title={`video-${id}`}
                        className="embed-responsive-item"
                        src={embedYouTubeUrl(video.video_url)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Alert color="warning" role="alert">
            The Project Gallery Videos is currently empty. Please add videos to
            populate the gallery.
          </Alert>
        )}
        {!galleryMode && (
          <Row>
            <Col>
              <div className="mb-3 text-end">
                {permission
                  .filter(
                    item =>
                      item.page ===
                        "project-gallery/edit-project-gallery/:id" &&
                      item.access === 1
                  )
                  .map((item, index) => (
                    <Link
                      key={index}
                      to={`/project-gallery/edit-project-gallery/${userId}`}
                    >
                      <button className="btn btn-primary ">
                        {" "}
                        Edit Gallery
                      </button>
                    </Link>
                  ))}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  )
}

export default ProjectGalleryView
