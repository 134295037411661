import {
  GET_PROPOSAL_TYPE_MASTER_LIST,
  GET_PROPOSAL_TYPE_MASTER_LIST_SUCCESS,
  GET_PROPOSAL_TYPE_MASTER_LIST_FAIL,
  GET_PROPOSAL_TYPE_MASTER_DETAIL,
  GET_PROPOSAL_TYPE_MASTER_DETAIL_SUCCESS,
  GET_PROPOSAL_TYPE_MASTER_DETAIL_FAIL,
  ADD_NEW_PROPOSAL_TYPE_MASTER,
  ADD_NEW_PROPOSAL_TYPE_MASTER_SUCCESS,
  ADD_NEW_PROPOSAL_TYPE_MASTER_FAIL,
  UPDATE_PROPOSAL_TYPE_MASTER,
  UPDATE_PROPOSAL_TYPE_MASTER_SUCCESS,
  UPDATE_PROPOSAL_TYPE_MASTER_FAIL,
  DELETE_PROPOSAL_TYPE_MASTER,
  DELETE_PROPOSAL_TYPE_MASTER_SUCCESS,
  DELETE_PROPOSAL_TYPE_MASTER_FAIL,
} from "./actionTypes"

export const getProposalMasterList = () => ({
  type: GET_PROPOSAL_TYPE_MASTER_LIST,
})

export const getProposalMasterListSuccess = proposals => ({
  type: GET_PROPOSAL_TYPE_MASTER_LIST_SUCCESS,
  payload: proposals,
})
export const getProposalMasterListFail = error => ({
  type: GET_PROPOSAL_TYPE_MASTER_LIST_FAIL,
  payload: error,
})

export const getProposalMasterDetail = proposalId => ({
  type: GET_PROPOSAL_TYPE_MASTER_DETAIL,
  proposalId,
})

export const getProposalMasterDetailSuccess = proposalDetail => ({
  type: GET_PROPOSAL_TYPE_MASTER_DETAIL_SUCCESS,
  payload: proposalDetail,
})

export const getProposalMasterDetailFail = error => ({
  type: GET_PROPOSAL_TYPE_MASTER_DETAIL_FAIL,
  payload: error,
})

export const addNewProposalMaster = (proposal, history) => ({
  type: ADD_NEW_PROPOSAL_TYPE_MASTER,
  payload: { proposal, history },
  status: true,
})

export const addNewProposalMasterSuccess = proposal => ({
  type: ADD_NEW_PROPOSAL_TYPE_MASTER_SUCCESS,
  payload: proposal,
})

export const addNewProposalMasterFail = error => ({
  type: ADD_NEW_PROPOSAL_TYPE_MASTER_FAIL,
  payload: error,
})

export const updateProposalMaster = (id, proposal, history) => ({
  type: UPDATE_PROPOSAL_TYPE_MASTER,
  payload: { id, proposal, history },
})

export const updateProposalMasterSuccess = proposal => ({
  type: UPDATE_PROPOSAL_TYPE_MASTER_SUCCESS,
  payload: proposal,
})

export const updateProposalMasterFail = error => ({
  type: UPDATE_PROPOSAL_TYPE_MASTER_FAIL,
  payload: error,
})

export const deleteProposalMaster = proposal => ({
  type: DELETE_PROPOSAL_TYPE_MASTER,
  payload: proposal,
})

export const deleteProposalMasterSuccess = proposal => ({
  type: DELETE_PROPOSAL_TYPE_MASTER_SUCCESS,
  payload: proposal,
})

export const deleteProposalMasterFail = error => ({
  type: DELETE_PROPOSAL_TYPE_MASTER_FAIL,
  payload: error,
})
