import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import Swal from "sweetalert2"
import { useFormik } from "formik"
import {
  FormFeedback,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate, useLocation } from "react-router-dom"
import VLogo from "../../assets/images/VIP/VLogo.png"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { getModules, getRoleDetail, getRoles } from "store/actions"
import { addNewRole, updateRole } from "helpers/fakebackend_helper"

const NewUser = () => {
  const [isEdit, setIsEdit] = useState(false)
  const location = useLocation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const [selectedRadio, setSelectedRadio] = useState({})

  const handleModuleRadioChange = (moduleName, selectedValue) => {
    setSelectedRadio(prevState => ({
      ...prevState,
      [moduleName]: selectedValue,
    }))
  }

  const dispatch = useDispatch()

  const modulesState = state => state.roles.userRoleModules.data
  const loadingState = state => state.roles.loading
  const Modules = useSelector(modulesState) || []
  const loadingData = useSelector(loadingState)


  const rolesProperties = createSelector(
    modulesState,
    loadingState,
    (roles, loading) => {
      return {
        roles: roles,
        loading: loading,
      }
    }
  )

  const { roles, loading } = useSelector(rolesProperties)
  const [specificUser, setSpecificUser] = useState(null)
  const [roleId, setRoleId] = useState()

  const userRoledata = state => state.roles.roleDetail.Data
  const role = useSelector(userRoledata)


  const roleData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const roleId = url.pathname.split("/edit-role/")[1]
      setRoleId(roleId)
      dispatch(getRoleDetail(roleId))
      dispatch(getModules())
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    roleData()
  }, [dispatch])

  const navigate = useNavigate()

  useEffect(() => {
    // Check if the current path includes 'edit-project'
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  document.title = "Role Management"

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: isEditMode
      ? {
          role: (role && role.role) || "",
          restrict_delete: 0,
        }
      : {
          role: "",
          restrict_delete: 0,
        },
    validationSchema: Yup.object({
      role: Yup.string(),
      restrict_delete: Yup.number(),
    }),
    onSubmit: async values => {
      try {
        if (isEditMode === true) {
          const response = await updateRole(roleId, values)

          if (response && response.status === true) {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your User role has been updated.",
            }).then(navigate("/settings/user-role-management"))
          } else {
            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has not been saved.",
            })
          }
        } else if (isEditMode === false) {
          const response = await addNewRole(values)

          if (response && response.status === true) {
            await Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your User Role has been created.",
            }).then(navigate("/settings/user-role-management"))
          } else {
            Swal.fire({
              title: "failure",
              icon: "error",
              text:
                error.response.data.message ||
                "Your User Role has not been saved.",
            })
          }
        }
      } catch (error) {
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your Role.",
        })
      }
    },
  })

  return (
    <>
      <div className="page-content">
        <Row>
          <Col>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey", marginRight: "15px" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>

              <span
                style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
              >
                {" "}
                {isEditMode ? "Edit Role" : "Create New Role"}{" "}
              </span>
            </div>
          </Col>
          <Col>
            {isEditMode ? (
              <Breadcrumbs title="Settings / User Role Management / Edit User Role" />
            ) : (
              <Breadcrumbs title="Settings / User Role Management / Create New Role" />
            )}
          </Col>
        </Row>
        <Card>
          <CardBody>
            {/* <CardTitle className="mb-4">Time / Region</CardTitle> */}
            <Form autoComplete="off">
              {/* Your form elements go here */}
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-firstname-Input">Role Name</Label>
                    <Input
                      type="text"
                      name="role"
                      id="formrow-firstname-Input"
                      placeholder="Name of the role"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.role || ""}
                      className={`form-control ${
                        validation.touched.role && validation.errors.role
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {validation.touched.role && validation.errors.role ? (
                      <FormFeedback type="invalid">
                        {validation.errors.role}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary "
                    onClick={validation.handleSubmit}
                  >
                    {!isEditMode ? "Create Role" : "Edit Role"}
                  </button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default NewUser
