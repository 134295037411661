import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"

// import { recentfile } from "../../../common/data";
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getPastEmployment } from "store/actions"

const PastEmploymentView = () => {
  const [pastEmpId, setPastEmpId] = useState()

  const dispatch = useDispatch()
  const pastEmploymentState = state => state.users.pastEmp.data
  const data = useSelector(pastEmploymentState)
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const fetchData = () => {
    const url = new URL(window.location.href)
    const pastEmpId = url.pathname.split("/user-details/")[1]
    setPastEmpId(pastEmpId)
    // setIsEditMode(true)
    dispatch(getPastEmployment(pastEmpId))
  }
  useEffect(() => {
    fetchData()
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th scope="col">Company Name</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Designation</th>
            </tr>
          </thead>
          {Array.isArray(data) && data.length > 0 ? (
            <tbody>
              {data.map((item, key) => (
                <tr key={key}>
                  <td>
                    <Link to="#" className="text-dark fw-medium">
                      {item.name_of_company}
                    </Link>
                  </td>
                  <td>{item.start_date}</td>
                  <td>{item.end_date}</td>
                  <td>{item.designation}</td>
                  {permission
                    .filter(
                      item =>
                        item.page === "/view-pastemployment/:id" &&
                        item.access === 1
                    )
                    .map(( index) => (
                      <td key={index}>
                        <Link
                          to={`/users/view-pastemployment/${item.id}`}
                          className="text-success"
                        >
                          <i
                            className="mdi mdi-chevron-right font-size-18"
                            id={`edittooltip-${key}`}
                            style={{ color: "#3366cc" }}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`edittooltip-${key}`}
                          >
                            View
                          </UncontrolledTooltip>
                        </Link>
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="5" className="text-center">
                  No Records Found
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
      <div className="mt-3 text-end">
        {permission
          .filter(
            item =>
              item.page === "/add-new-pastemployment/:id" && item.access === 1
          )
          .map((item, index) => (
            <Link key={index} to={`/users/add-new-pastemployment/${pastEmpId}`}>
              <button type="submit" className="btn btn-primary w-md">
                Add Past Employment
              </button>
            </Link>
          ))}
      </div>

      {/* </div> */}
    </React.Fragment>
  )
}

export default PastEmploymentView
