import React, { useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"

const VendorDetail = props => {
  const { vendorProfile } = props
  return (
    <React.Fragment>
      <Container fluid={true}>
        {vendorProfile && (
          <Row>
            <Col>
              <CardTitle className="mb-3">Vendor Identities</CardTitle>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Customer Type </Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {vendorProfile?.vendor_details?.entity}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Vendor Name </Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {vendorProfile?.vendor_details?.vendor_name}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Vendor Code</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {vendorProfile?.vendor_details?.vendor_code}
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Service Offering</Label>
                  {vendorProfile?.vendor_details?.service_offering_name ? (
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {vendorProfile?.vendor_details?.service_offering_name?.join(", ")}
                    </p>
                  ) : (
                    <p className="text-dark">No service offerings available</p>
                  )}
                </Col>
              </Row>
            </Col>
            <hr />
            <Col>
              <CardTitle className="mb-3">Contact Details</CardTitle>

              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label"> Email Address </Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.email_address}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Mobile No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {vendorProfile?.vendor_details?.mobile_no}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Address</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {vendorProfile?.vendor_details?.address}
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Pincode</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {vendorProfile?.vendor_details?.pincode}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Country</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {vendorProfile?.vendor_details?.country}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">State</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {vendorProfile?.vendor_details?.state}
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">City</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {vendorProfile?.vendor_details?.city}
                  </p>
                </Col>
              </Row>
            </Col>
            <hr />
            <Col>
              <CardTitle className="mb-3">Document Identities</CardTitle>

              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">PAN No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.pan_no}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Aadhar No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.aadhar_no}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">TAN No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.tan_no}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Label className="form-label">GST No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.gst_no}
                  </p>
                </Col>
              </Row>
            </Col>
            <hr />
            <Col>
              <CardTitle className="mb-3">Vendor Bank Details</CardTitle>

              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Bank Name</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.bank_name}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Account No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.account_no}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">IFSC Code</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.ifsc_code}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Label className="form-label">Account Holder Name</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.account_holder_name}
                  </p>
                </Col>
              </Row>
            </Col>
            <hr />
            <Col>
              <CardTitle className="mb-3">Sign In details</CardTitle>

              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Login Username</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.login_username}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">is Active</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {vendorProfile?.vendor_details?.is_active === 1
                      ? "Yes"
                      : "No"}
                  </p>
                </Col>
              </Row>
            </Col>
            <hr />
          </Row>
        )}
      </Container>
    </React.Fragment>
  )
}

export default VendorDetail
