import { ADD_USER_PERMISSION, DELETE_DOCUMENT } from "helpers/url_helper"
import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_DETAIL,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_DOCUMENTATION,
  GET_DOCUMENTATION_SUCCESS,
  GET_DOCUMENTATION_FAIL,
  ADD_DOCUMENTATION,
  ADD_DOCUMENTATION_SUCCESS,
  ADD_DOCUMENTATION_FAIL,
  UPDATE_DOCUMENTATION,
  UPDATE_DOCUMENTATION_SUCCESS,
  UPDATE_DOCUMENTATION_FAIL,
  DELETE_DOCUMENTATION,
  DELETE_DOCUMENTATION_SUCCESS,
  DELETE_DOCUMENTATION_FAIL,
  GET_PAST_EMPLOYMENT,
  GET_PAST_EMPLOYMENT_SUCCESS,
  GET_PAST_EMPLOYMENT_FAIL,
  ADD_PAST_EMPLOYMENT,
  ADD_PAST_EMPLOYMENT_FAIL,
  ADD_PAST_EMPLOYMENT_SUCCESS,
  UPDATE_PAST_EMPLOYMENT,
  UPDATE_PAST_EMPLOYMENT_SUCCESS,
  UPDATE_PAST_EMPLOYMENT_FAIL,
  DELETE_PAST_EMPLOYMENT,
  DELETE_PAST_EMPLOYMENT_SUCCESS,
  DELETE_PAST_EMPLOYMENT_FAIL,
  GET_ASSIGNED_CUSTOMER,
  GET_ASSIGNED_CUSTOMER_FAIL,
  GET_ASSIGNED_CUSTOMER_SUCCESS,
  GET_PAST_EMPLOYMENT_DETAIL,
  GET_PAST_EMPLOYMENT_DETAIL_SUCCESS,
  GET_PAST_EMPLOYMENT_DETAIL_FAIL,
  REINVITE,
  REINVITE_SUCCESS,
  REINVITE_FAIL,
  GET_USER_MODULES,
  GET_USER_MODULES_SUCCESS,
  GET_USER_MODULES_FAIL,
  GET_USER_PERMISSION,
  GET_USER_PERMISSION_SUCCESS,
  GET_USER_PERMISSION_FAIL,
  ADD_USER_PERMISSION_SUCCESS,
  ADD_USER_PERMISSION_FAIL,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAIL,
  GET_EMPLOYEE_CODE,
  GET_EMPLOYEE_CODE_SUCCESS,
  GET_EMPLOYEE_CODE_FAIL,
  GET_PROJECT_NAME_FOR_USER,
  GET_PROJECT_NAME_FOR_USER_SUCCESS,
  GET_PROJECT_NAME_FOR_USER_FAIL,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const updateUser = (id, user) => ({
  type: UPDATE_USER,
  payload: { id, user },
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserDetail = userId => ({
  type: GET_USER_DETAIL,
  userId,
})

export const getUserDetailSuccess = userDetails => ({
  type: GET_USER_DETAIL_SUCCESS,
  payload: userDetails,
})

export const getUserDetailFail = error => ({
  type: GET_USER_DETAIL_FAIL,
  payload: error,
})

export const getDocumentation = userId => ({
  type: GET_DOCUMENTATION,
  userId,
})

export const getDocumentationSuccess = docs => ({
  type: GET_DOCUMENTATION_SUCCESS,
  payload: docs,
})

export const getDocumentationFail = error => ({
  type: GET_DOCUMENTATION_FAIL,
  payload: error,
})

export const addDocumentation = docs => ({
  type: ADD_DOCUMENTATION,
  payload: docs,
})

export const addDocumentationSuccess = docs => ({
  type: ADD_DOCUMENTATION_SUCCESS,
  payload: docs,
})

export const addDocumentationFail = error => ({
  type: ADD_DOCUMENTATION_FAIL,
  payload: error,
})

export const updateDocumentation = docs => ({
  type: UPDATE_DOCUMENTATION,
  payload: docs,
})

export const updateDocumentationSuccess = docs => ({
  type: UPDATE_DOCUMENTATION_SUCCESS,
  payload: docs,
})

export const updateDocumentationFail = error => ({
  type: UPDATE_DOCUMENTATION_FAIL,
  payload: error,
})

export const deleteDocumentation = docs => ({
  type: DELETE_DOCUMENTATION,
  docs,
})

export const deleteDocumentationSuccess = docs => ({
  type: DELETE_DOCUMENTATION_SUCCESS,
  payload: docs,
})

export const deleteDocumentationFail = error => ({
  type: DELETE_DOCUMENTATION_FAIL,
  payload: error,
})

export const getPastEmployment = emp => ({
  type: GET_PAST_EMPLOYMENT,
  emp,
})

export const getPastEmploymentSuccess = emp => ({
  type: GET_PAST_EMPLOYMENT_SUCCESS,
  payload: emp,
})

export const getPastEmploymentFail = error => ({
  type: GET_PAST_EMPLOYMENT_FAIL,
  payload: error,
})

export const getPastEmploymentDetail = emp => ({
  type: GET_PAST_EMPLOYMENT_DETAIL,
  emp,
})

export const getPastEmploymentDetailSuccess = emp => ({
  type: GET_PAST_EMPLOYMENT_DETAIL_SUCCESS,
  payload: emp,
})

export const getPastEmploymentDetailFail = error => ({
  type: GET_PAST_EMPLOYMENT_DETAIL_FAIL,
  payload: error,
})

export const addPastEmployment = (id, emp) => ({
  type: ADD_PAST_EMPLOYMENT,
  payload: { id, emp },
})

export const addPastEmploymentSuccess = emp => ({
  type: ADD_PAST_EMPLOYMENT_SUCCESS,
  payload: emp,
})

export const addPastEmploymentFail = error => ({
  type: ADD_PAST_EMPLOYMENT_FAIL,
  payload: error,
})

export const updatePastEmployment = emp => ({
  type: UPDATE_PAST_EMPLOYMENT,
  payload: emp,
})

export const updatePastEmploymentSuccess = emp => ({
  type: UPDATE_PAST_EMPLOYMENT_SUCCESS,
  payload: emp,
})

export const updatePastEmploymentFail = error => ({
  type: UPDATE_PAST_EMPLOYMENT_FAIL,
  payload: error,
})

export const deletePastEmployment = emp => ({
  type: DELETE_PAST_EMPLOYMENT,
  payload: emp,
})

export const deletePastEmploymentSuccess = emp => ({
  type: DELETE_PAST_EMPLOYMENT_SUCCESS,
  payload: emp,
})

export const deletePastEmploymentFail = error => ({
  type: DELETE_PAST_EMPLOYMENT_FAIL,
  payload: error,
})

export const getAssignedCustomer = customer => ({
  type: GET_ASSIGNED_CUSTOMER,
  payload: customer,
})

export const getAssignedCustomerSuccess = customer => ({
  type: GET_ASSIGNED_CUSTOMER_SUCCESS,
  payload: customer,
})

export const getAssignedCustomerFail = error => ({
  type: GET_ASSIGNED_CUSTOMER_FAIL,
  payload: error,
})

export const reinvite = emp => ({
  type: REINVITE,
  payload: emp,
})

export const reinviteSuccess = emp => ({
  type: REINVITE_SUCCESS,
  payload: emp,
})

export const reinviteFail = error => ({
  type: REINVITE_FAIL,
  payload: error,
})

export const getUserPermission = userId => ({
  type: GET_USER_PERMISSION,
  userId,
})

export const getUserPermissionSuccess = roles => ({
  type: GET_USER_PERMISSION_SUCCESS,
  payload: roles,
})
export const getUserPermissionFail = error => ({
  type: GET_USER_PERMISSION_FAIL,
  payload: error,
})

export const addNewUserRole = role => ({
  type: ADD_USER_PERMISSION,
  payload: role,
})

export const addNewUserRoleSuccess = role => ({
  type: ADD_USER_PERMISSION_SUCCESS,
  payload: role,
})

export const addNewUserRoleFail = error => ({
  type: ADD_USER_PERMISSION_FAIL,
  payload: error,
})

export const resetUserPassword = (user, history) => {
  return {
    type: RESET_USER_PASSWORD,
    payload: { user, history },
  }
}

export const resetUserPasswordSuccess = message => {
  return {
    type: RESET_USER_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const resetUserPasswordFail = message => {
  return {
    type: RESET_USER_PASSWORD_FAIL,
    payload: message,
  }
}

export const getEmployeeCode = () => ({
  type: GET_EMPLOYEE_CODE,
})

export const getEmployeeCodeSuccess = users => ({
  type: GET_EMPLOYEE_CODE_SUCCESS,
  payload: users,
})

export const getEmployeeCodeFail = error => ({
  type: GET_EMPLOYEE_CODE_FAIL,
  payload: error,
})

// GET project names

export const getProjectNamesForUser = () => ({
  type: GET_PROJECT_NAME_FOR_USER,
})

export const getProjectNamesForUserSuccess = projectNames => ({
  type: GET_PROJECT_NAME_FOR_USER_SUCCESS,
  payload: projectNames,
})

export const getProjectNamesForUserFail = error => ({
  type: GET_PROJECT_NAME_FOR_USER_FAIL,
  payload: error,
})