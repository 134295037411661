import React, { useEffect, useState } from "react"
import SimpleBar from "simplebar-react"
// import { activityData } from "common/data"
import Proptypes from "prop-types"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  FormFeedback,
  Table,
  Alert,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  createServiceRequest,
  getMaterialMasterForServiceRequest,
  getProjectManagerInServiceRequest,
  getServiceRequestDetail,
  getUnitMasterForServiceRequest,
  getUsersInServiceRequest,
  getVendorsInServiceRequest,
} from "store/actions"
import Spinners from "components/Common/Spinner"
import { useFormik } from "formik"
import * as Yup from "yup"
import withRouter from "components/Common/withRouter"
import { acceptServiceRequest } from "helpers/fakebackend_helper"
import TicketView from "./TicketView/ticketView"
import Swal from "sweetalert2"

const ReassignViewServiceRequest = props => {
  const path = useLocation()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [reassign, setReassign] = useState(1)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const serviceRequestState = state =>
    state.serviceRequests.serviceRequestDetail.service_request
  const data = useSelector(serviceRequestState) || []
  const ticketDataState = state =>
    state.serviceRequests.serviceRequestDetail.ServiceRequestTicket
  const ticketData = useSelector(ticketDataState) || []
  const trackingDataState = state =>
    state.serviceRequests.serviceRequestDetail.service_request_log
  const trackingData = useSelector(trackingDataState) || []
  const loadingState = state => state.serviceRequests.loading
  const loading = useSelector(loadingState) || []
  const [isLoading, setLoading] = useState(loading)
  const [serviceId, setServiceId] = useState()

  const projectManagersListState = state => state.serviceRequests.managers.users
  const managers = useSelector(projectManagersListState) || []

  const usersListState = state => state.serviceRequests.users.users
  const users = useSelector(usersListState) || []

  const vendorsListState = state =>
    state.serviceRequests.vendors.vendor_management
  const vendors = useSelector(vendorsListState) || []

  const materialMasterState = state => state.serviceRequests.materials.data
  const materialMaster = useSelector(materialMasterState) || []

  const unitMasterState = state => state.serviceRequests.units.data
  const unitMaster = useSelector(unitMasterState) || []
  console.log("ID", ticketData.ticket_id)
  const [rows, setRows] = useState([{ id: 1 }])

  const addRow = () => {
    const newRow = { id: rows.length + 1 }
    setRows([...rows, newRow])
  }

  const removeRow = index => {
    const updatedRows = rows.filter((_, idx) => idx !== index)
    setRows(updatedRows)

    const updatedValidationRows = validation.values.material.filter(
      (_, idx) => idx !== index
    )
    validation.setFieldValue("rows", updatedValidationRows)
  }

  const handleChange = (id, e) => {
    const { name, value } = e.target
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value }
      }
      return row
    })
    setRows(updatedRows)
  }

  const [labors, setLabors] = useState([
    { id: 1, labor_type: "", quantity: "", amount_per_unit: "" },
  ])

  const addLaborRow = () => {
    setLabors([
      ...labors,
      {
        id: labors.length + 1,
        labor_type: "",
        quantity: "",
        amount_per_unit: "",
      },
    ])
  }

  const removeLaborRow = index => {
    const updatedLabors = labors.filter((_, idx) => idx !== index)
    setLabors(updatedLabors)

    const updatedValidationLabors = validation.values.manpower.filter(
      (_, idx) => idx !== index
    )
    validation.setFieldValue("labors", updatedValidationLabors)
  }

  const handleLaborChange = (id, e) => {
    const { name, value } = e.target
    const updatedLabors = labors.map(labor => {
      if (labor.id === id) {
        return { ...labor, [name]: value }
      }
      return labor
    })
    setLabors(updatedLabors)
  }

  const [otherCosts, setOtherCosts] = useState([
    { id: 1, cost_type: "", price: "" },
  ])

  const addOtherCostRow = () => {
    setOtherCosts([
      ...otherCosts,
      { id: otherCosts.length + 1, cost_type: "", price: "" },
    ])
  }

  const removeOtherCostRow = index => {
    const updatedOtherCosts = otherCosts.filter((_, idx) => idx !== index)
    setOtherCosts(updatedOtherCosts)

    const updatedValidationCosts = validation.values.other_cost.filter(
      (_, idx) => idx !== index
    )
    validation.setFieldValue("other_cost", updatedValidationCosts)
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    // initialValues: window.location.href.includes('edit')
    initialValues: {
      declined_reason: "",
      ticket_supervisor_id:
        (ticketData && ticketData.ticket_supervisor_id) || "",
      mode_of_operation: (ticketData && ticketData.mode_of_operation) || "",
      assigned_internal_user_id:
        (ticketData && ticketData.assigned_internal_user_id) || "",
      vendor_id: (ticketData && ticketData.vendor_id) || "",
      work_type: (ticketData && ticketData.work_type) || "",
      is_additional_material_required:
        (ticketData && ticketData.is_additional_material_required) || "",
      is_manpower_required:
        (ticketData && ticketData.is_manpower_required) || "",
      is_other_cost_required:
        (ticketData && ticketData.is_other_cost_required) || "2",
      priority_id: (ticketData && ticketData.priority_id) || "",
      instructions: (ticketData && ticketData.instructions) || "",
      material: "",
      manpower: "",
      other_cost: "",
      is_rejected_status: 0,
    },

    validationSchema: Yup.object({
      ticket_supervisor_id: Yup.number().required(
        "Please Select Ticket Supervisor"
      ),
    }),

    onSubmit: async values => {
      dispatch(
        createServiceRequest(
          ticketData?.ticket_id,
          values,
          props.router.navigate
        )
      )
      dispatch(getServiceRequestDetail(serviceId))
    },
  })
  console.log("Values", validation.values.material)
  const fetchData = () => {
    const serviceId = path.pathname.split("/reassign-view-service-requests/")[1]
    setServiceId(serviceId)
    dispatch(getServiceRequestDetail(serviceId))
  }

  useEffect(() => {
    if (validation && validation.values && validation.values.material) {
      // Update rows state based on the length of billing_components array
      setRows(
        [...Array(validation.values.material.length)].map((_, index) => ({
          id: index,
        }))
      )
    }
  }, [validation.values.material])

  useEffect(() => {
    if (validation && validation.values && validation.values.manpower) {
      // Update rows state based on the length of billing_components array
      setRows(
        [...Array(validation.values.manpower.length)].map((_, index) => ({
          id: index,
        }))
      )
    }
  }, [validation.values.manpower])

  useEffect(() => {
    if (validation && validation.values && validation.values.other_cost) {
      // Update rows state based on the length of billing_components array
      setRows(
        [...Array(validation.values.other_cost.length)].map((_, index) => ({
          id: index,
        }))
      )
    }
  }, [validation.values.other_cost])

  useEffect(() => {
    const materialData = ticketData?.materials?.map(row => ({
      id: row.id,
      material_id: row.material_id || "",
      quantity: row.quantity || "",
      unit: row.unit || "",
      unit_price: row.unit_price || "",
      file_path_show: row.file_path || "",

    }))
    validation.setFieldValue("material", materialData)
  }, [ticketData.materials])

  useEffect(() => {
    const manpowerData = ticketData?.manpower?.map(row => ({
      id: row.id,
      label_required: row.label_required || "",
      quantity: row.quantity || "",
      cost_per_unit: row.cost_per_unit || "",
      file_path_show: row.file_path || "",

    }))
    validation.setFieldValue("manpower", manpowerData)
  }, [ticketData.manpower])

  useEffect(() => {
    const otherData = ticketData?.other_cost?.map(row => ({
      id: row.id,
      requirement: row.requirement || "",
      other_cost: row.other_cost || "",
      file_path_show: row.file_path || "",
    }))
    validation.setFieldValue("other_cost", otherData)
  }, [ticketData.other_cost])

  useEffect(() => {
    fetchData()
    dispatch(getProjectManagerInServiceRequest())
    // dispatch(getUsersInServiceRequest())
    dispatch(getVendorsInServiceRequest())
    dispatch(getMaterialMasterForServiceRequest())
    dispatch(getUnitMasterForServiceRequest())
  }, [dispatch, useLocation])

  useEffect(() => {
    if (data) {
      const projectId = data.project_id || data.prefered_project_id
      if (projectId) {
        dispatch(getUsersInServiceRequest(projectId))
      }
    }
  }, [data, dispatch])

  const handleAccept = async val => {
    const values = {
      status: val,
      declined_reason: validation.values.declined_reason,
    }
    const response = await acceptServiceRequest(serviceId, values)
    if (response.success === true && response.service_request.status === 5) {
      Swal.fire({
        title: "Rejected",
        icon: "error",
        text: "Your Service Request has been Rejected.",
      })
    } else if (
      response.success === true &&
      response.service_request.status === 2
    ) {
      Swal.fire({
        title: "Accepted",
        icon: "success",
        text: "Your Service Request has been Accepted.",
      }).then(() => {
        dispatch(getServiceRequestDetail(serviceId))
      })
    }
  }

  console.log("Validation",validation.values)
  const getStatusColor = status => {
    switch (status) {
      case "Open":
        return "text-secondary" // Primary color
      case "Ticket Created":
        return "text-primary" // Success color
      case "In Progress":
        return "text-warning" // Warning color
      case "In Progress (reassigned)":
        return "text-warning" // Warning color
      case "Closed":
        return "text-success" // Danger color
      case "Declined(with reason)":
        return "text-danger" // Danger color
      default:
        return ""
    }
  }

  useEffect(() => {
    if (validation && validation.values && validation.values.material) {
      // Update rows state based on the length of material array
      setRows(
        [...Array(validation.values.material.length)].map((_, index) => ({
          id: index,
        }))
      )
    }
  }, [validation.values.material])
  useEffect(() => {
    if (validation && validation.values && validation.values.manpower) {
      // Update rows state based on the length of manpower array
      setLabors(
        [...Array(validation.values.manpower.length)].map((_, index) => ({
          id: index,
        }))
      )
    }
  }, [validation.values.manpower])
  useEffect(() => {
    if (validation && validation.values && validation.values.other_cost) {
      // Update rows state based on the length of other_cost array
      setOtherCosts(
        [...Array(validation.values.other_cost.length)].map((_, index) => ({
          id: index,
        }))
      )
    }
  }, [validation.values.other_cost])

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <div className="page-content ">
          <Container fluid={true}>
            <Row className="justify-content-center">
              <Col lg={12}>
                <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                  <div className="d-flex gap-2 ">
                    <i
                      className="mdi mdi-arrow-left font-size-20"
                      style={{ color: "grey" }}
                      onClick={() => {
                        navigate(-1)
                      }}
                    ></i>
                    Service Request Details
                  </div>
                </CardTitle>
                {data && data.status === 5 && (
                  <Col lg={12}>
                    <Label></Label>
                    <Alert color="success" role="alert" className="text-center">
                      Service Request Has Been Declined
                    </Alert>
                  </Col>
                )}
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="me-2">
                        <h5 className="card-title mb-4">
                          Track Request ({data.srq_code})
                        </h5>
                      </div>
                    </div>
                    <SimpleBar className="mt-2" style={{ maxHeight: "280px" }}>
                      {trackingData &&
                        trackingData.map((track, index) => (
                          <ul
                            key={index}
                            className="verti-timeline list-unstyled"
                          >
                            <li
                              className={`event-list ${
                                track.status_name ? "active" : ""
                              }`}
                              style={{ marginBottom: "20px" }} // Add margin bottom for spacing
                            >
                              <div className="event-timeline-dot">
                                <i
                                  className={`bx bxs-right-arrow-circle font-size-18 ${
                                    track.status_name && "bx-fade-right"
                                  }`}
                                ></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                  <h5 className="font-size-14">
                                    {track.log_message}
                                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                  </h5>
                                </div>
                                <div
                                  className={`flex-grow-1 ${getStatusColor(
                                    track.status_name
                                  )}`}
                                >
                                  {track.status_name} ({track.created_at})
                                </div>
                              </div>
                            </li>
                          </ul>
                        ))}
                    </SimpleBar>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <h6>Type of Service</h6>
                      </Col>
                      <Col lg={8}>{data && data.service_type}</Col>
                    </Row>
                    <hr></hr>
                    {data && data.category_title !== null && (
                      <>
                        {" "}
                        <Row>
                          <Col lg={4}>
                            <h6>Project Unit Category</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.category_title === null
                              ? "NA"
                              : data.category_title}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {(data && data.maintenance_issue_type !== null) ||
                      ("" && (
                        <>
                          <Row>
                            <Col lg={4}>
                              <h6>Maintainence Issue</h6>
                            </Col>
                            <Col lg={8}>
                              {data && data.maintenance_issue_type === null
                                ? "NA"
                                : data.maintenance_issue_type}
                            </Col>
                          </Row>
                          <hr />
                        </>
                      ))}
                    {data && data.project_name !== null && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h6>Project In Which You Are Facing Issue</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.project_name === null
                              ? "NA"
                              : data.project_name}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data && data.unit_code !== null && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h6>Project Unit In Which You Are Facing Issue</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.unit_code === null
                              ? "NA"
                              : data.unit_code}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data &&
                      data.prefered_duration_for_user_engagement !== null && (
                        <>
                          <Row>
                            <Col lg={4}>
                              <h6>
                                How soon you want to start new engagement?
                              </h6>
                            </Col>
                            <Col lg={8}>
                              {data &&
                              data.prefered_duration_for_user_engagement ===
                                null
                                ? "NA"
                                : data.prefered_duration_for_user_engagement}
                            </Col>
                          </Row>
                          <hr />
                        </>
                      )}
                    {(data && data.support_ticket_topic !== null) ||
                      ("" && (
                        <>
                          {" "}
                          <Row>
                            <Col lg={4}>
                              <h6>Category</h6>
                            </Col>
                            <Col lg={8}>
                              {data && data.support_ticket_topic === null
                                ? "NA"
                                : data.support_ticket_topic}
                            </Col>
                          </Row>
                          <hr />
                        </>
                      ))}
                    {data && data.budget_prefered !== null && (
                      <>
                        {" "}
                        <Row>
                          <Col lg={4}>
                            <h6>Prefered Budget</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.budget_prefered === null
                              ? "NA"
                              : data.budget_prefered}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {(data && data.preferred_project_name !== null) ||
                      ("" && (
                        <>
                          {" "}
                          <Row>
                            <Col lg={4}>
                              <h6>Preferred Project</h6>
                            </Col>
                            <Col lg={8}>
                              {data && data.preferred_project_name === null
                                ? "NA"
                                : data.preferred_project_name}
                            </Col>
                          </Row>
                          <hr />
                        </>
                      ))}
                    <Row>
                      <Col lg={4}>
                        <h6>Task Description</h6>
                      </Col>
                      <Col lg={8}>{data && data.description}</Col>
                    </Row>
                    <hr></hr>
                    {data && data?.files?.length > 0 && (
                      <Row>
                        <Col lg={4}>
                          <h6>Supporting Pics / Videos / Document</h6>
                        </Col>
                        <Col lg={8}>
                          {data && data?.files?.length > 0 ? (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {data.files.map((file, index) => (
                                <div
                                  key={index}
                                  style={{
                                    width: "200px",
                                    height: "150px",
                                    margin: "5px",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                  }}
                                >
                                  <img
                                    src={file.file_path}
                                    alt={`File ${index}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            "NA"
                          )}
                        </Col>
                      </Row>
                    )}
                    {data && data.status === 1 && (
                      <Row>
                        <Col className="mb-3 mt-2" lg={6}>
                          <Label>Reason for Rejection:</Label>
                          <Input
                            type="textarea"
                            placeholder="Enter Reason for Rejection (IF REJECTED)"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.declined_reason}
                            className={`form-control ${
                              validation.touched.declined_reason &&
                              validation.errors.declined_reason
                                ? "is-invalid"
                                : ""
                            }`}
                            name="declined_reason"
                          />
                          {validation.touched.declined_reason &&
                          validation.errors.declined_reason ? (
                            <FormFeedback type="invalid">
                              {validation.errors.declined_reason}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    )}
                    {data && data.status === 1 && (
                      <div className="actions clearfix text-end">
                        <button
                          style={{ width: "100px" }}
                          className="btn btn-primary me-2 mt-2"
                          onClick={() => {
                            handleAccept(2)
                          }}
                        >
                          Accept
                        </button>
                        <button
                          style={{ width: "100px" }}
                          className="btn btn-danger me-2 mt-2"
                          onClick={() => {
                            handleAccept(5)
                          }}
                        >
                          Reject
                        </button>
                      </div>
                    )}
                  </CardBody>
                </Card>

                {data && data.status !== 1 && data.status !== 5 ? (
                  <>
                    {reassign === 2 ? (
                      <>
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-3">
                              Ticket No: {data.ticket_no}
                            </CardTitle>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-ticket_supervisor_id-Input">
                                    Ticket Supervisor*
                                  </Label>
                                  <select
                                    name="ticket_supervisor_id"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.ticket_supervisor_id ||
                                      ""
                                    }
                                    className={`form-select ${
                                      validation.touched.ticket_supervisor_id &&
                                      validation.errors.ticket_supervisor_id
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  >
                                    {!validation.values
                                      .ticket_supervisor_id && (
                                      <option>Select Ticket Supervisor</option>
                                    )}
                                    {(users || []).map(item => (
                                      <option key={item.id} value={item.id}>
                                        {item.full_name}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.touched.ticket_supervisor_id &&
                                  validation.errors.ticket_supervisor_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.ticket_supervisor_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="mode_of_operation">
                                    Mode of Operation:
                                  </Label>
                                  <div className="form-check">
                                    <Input
                                      type="radio"
                                      id="mode_of_operation"
                                      name="mode_of_operation"
                                      className={`form-check-input `}
                                      value={1}
                                      checked={
                                        String(
                                          validation.values.mode_of_operation
                                        ) === "1"
                                      }
                                      onChange={validation.handleChange}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="assignToInternalUser"
                                    >
                                      Internal
                                    </Label>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <div className="form-check">
                                    <Input
                                      type="radio"
                                      id="mode_of_operation"
                                      name="mode_of_operation"
                                      className={`form-check-input `}
                                      value={2}
                                      onChange={validation.handleChange}
                                      checked={
                                        String(
                                          validation.values.mode_of_operation
                                        ) === "2"
                                      }
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="assignToVendor"
                                    >
                                      External
                                    </Label>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        {String(validation.values.mode_of_operation) === "1" ? (
                          <>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Row>
                                    <Col lg={3}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-userRole-Input">
                                          Assign To
                                        </Label>
                                        <select
                                          name="assigned_internal_user_id"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values
                                              .assigned_internal_user_id || ""
                                          }
                                          className={`form-select ${
                                            validation.touched
                                              .assigned_internal_user_id &&
                                            validation.errors
                                              .assigned_internal_user_id
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        >
                                          {!validation.values
                                            .assigned_internal_user_id && (
                                            <option>Select User</option>
                                          )}
                                          {(users || []).map(item => (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.full_name}
                                            </option>
                                          ))}
                                        </select>
                                        {validation.touched
                                          .assigned_internal_user_id &&
                                        validation.errors
                                          .assigned_internal_user_id ? (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors
                                                .assigned_internal_user_id
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>

                                    <Col lg={3}>
                                      <div className="mb-3">
                                        <Label htmlFor="is_additional_material_required">
                                          Material Required:
                                        </Label>
                                        <div className="form-check">
                                          <Input
                                            type="radio"
                                            id="is_additional_material_required"
                                            name="is_additional_material_required"
                                            className={`form-check-input `}
                                            value={1}
                                            checked={
                                              String(
                                                validation.values
                                                  .is_additional_material_required
                                              ) === "1"
                                            }
                                            onChange={validation.handleChange}
                                          />
                                          <Label
                                            className="form-check-label"
                                            htmlFor="is_additional_material_required"
                                          >
                                            Yes
                                          </Label>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="form-check">
                                          <Input
                                            type="radio"
                                            id="assignTo2"
                                            name="is_additional_material_required"
                                            className={`form-check-input `}
                                            value={2}
                                            onChange={validation.handleChange}
                                            checked={
                                              String(
                                                validation.values
                                                  .is_additional_material_required
                                              ) === "2"
                                            }
                                          />
                                          <Label
                                            className="form-check-label"
                                            htmlFor="is_additional_material_required"
                                          >
                                            No
                                          </Label>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={3}>
                                      <div className="mb-3">
                                        <Label htmlFor="is_manpower_required">
                                          Manpower Required:
                                        </Label>
                                        <div className="form-check">
                                          <Input
                                            type="radio"
                                            id="is_manpower_required"
                                            name="is_manpower_required"
                                            className={`form-check-input `}
                                            value={1}
                                            checked={
                                              String(
                                                validation.values
                                                  .is_manpower_required
                                              ) === "1"
                                            }
                                            onChange={validation.handleChange}
                                          />
                                          <Label
                                            className="form-check-label"
                                            htmlFor="is_manpower_required"
                                          >
                                            Yes
                                          </Label>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="form-check">
                                          <Input
                                            type="radio"
                                            id="assignTo2"
                                            name="is_manpower_required"
                                            className={`form-check-input `}
                                            value={2}
                                            onChange={validation.handleChange}
                                            checked={
                                              String(
                                                validation.values
                                                  .is_manpower_required
                                              ) === "2"
                                            }
                                          />
                                          <Label
                                            className="form-check-label"
                                            htmlFor="is_manpower_required"
                                          >
                                            No
                                          </Label>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={3}>
                                      <div className="mb-3">
                                        <Label htmlFor="is_other_cost_required">
                                          Other Cost Required:
                                        </Label>
                                        <div className="form-check">
                                          <Input
                                            type="radio"
                                            id="is_other_cost_required"
                                            name="is_other_cost_required"
                                            className={`form-check-input `}
                                            value={1}
                                            checked={
                                              String(
                                                validation.values
                                                  .is_other_cost_required
                                              ) === "1"
                                            }
                                            onChange={validation.handleChange}
                                          />
                                          <Label
                                            className="form-check-label"
                                            htmlFor="is_other_cost_required"
                                          >
                                            Yes
                                          </Label>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <div className="form-check">
                                          <Input
                                            type="radio"
                                            id="assignTo2"
                                            name="is_other_cost_required"
                                            className={`form-check-input `}
                                            value={2}
                                            onChange={validation.handleChange}
                                            checked={
                                              String(
                                                validation.values
                                                  .is_other_cost_required
                                              ) === "2"
                                            }
                                          />
                                          <Label
                                            className="form-check-label"
                                            htmlFor="is_other_cost_required"
                                          >
                                            No
                                          </Label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  {String(
                                    validation.values
                                      .is_additional_material_required
                                  ) === "1" ? (
                                    <Row>
                                      <Card>
                                        <CardBody>
                                          <Label> Material</Label>

                                          <div>
                                            <table className="table">
                                              <thead>
                                                <tr>
                                                  <th>Material</th>
                                                  <th>Quantity</th>
                                                  <th>Unit</th>
                                                  <th>Amount Per Unit</th>
                                                  <th>Total</th>
                                                  <th>Quotation File</th>

                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {rows.map((row, key) => (
                                                  <tr key={row.id}>
                                                    <td>
                                                      <select
                                                        className="form-select"
                                                        name={`material[${key}].material_id`}
                                                        value={
                                                          validation?.values
                                                            ?.material[key]
                                                            ?.material_id
                                                        }
                                                        onChange={
                                                          validation.handleChange
                                                        }
                                                      >
                                                        <option value="">
                                                          Select Material
                                                        </option>
                                                        {(
                                                          materialMaster || []
                                                        ).map(item => (
                                                          <option
                                                            key={item.id}
                                                            value={item.id}
                                                          >
                                                            {item.materials}
                                                          </option>
                                                        ))}
                                                      </select>
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        name={`material[${key}].quantity`}
                                                        value={
                                                          validation?.values
                                                            ?.material[key]
                                                            ?.quantity
                                                        }
                                                        onChange={
                                                          validation.handleChange
                                                        }
                                                        placeholder="Enter Quantity"
                                                      />
                                                    </td>
                                                    <td>
                                                      <select
                                                        className="form-select"
                                                        name={`material[${key}].unit`}
                                                        value={
                                                          validation?.values
                                                            ?.material[key]
                                                            ?.unit
                                                        }
                                                        onChange={
                                                          validation.handleChange
                                                        }
                                                      >
                                                        <option value="">
                                                          Select Unit
                                                        </option>
                                                        {(unitMaster || []).map(
                                                          item => (
                                                            <option
                                                              key={item.id}
                                                              value={item.id}
                                                            >
                                                              {item.unit}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        name={`material[${key}].unit_price`}
                                                        value={
                                                          validation?.values
                                                            ?.material[key]
                                                            ?.unit_price
                                                        }
                                                        onChange={
                                                          validation.handleChange
                                                        }
                                                        placeholder="Enter Amount"
                                                      />
                                                    </td>
                                                    <td>
                                                      <Label>
                                                        {(validation?.values
                                                          ?.material[key]
                                                          ?.quantity || 0) *
                                                          (validation?.values
                                                            ?.material[key]
                                                            ?.unit_price || 0)}
                                                      </Label>
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="file"
                                                        id={`material[${key}].file_path`}
                                                        name={`material[${key}].file_path`}
                                                        onChange={e => {
                                                          const selectedFile =
                                                            e.target.files[0]
                                                          validation.setFieldValue(
                                                            `material[${key}].file_path`,
                                                            selectedFile
                                                          )
                                                        }}
                                                        onBlur={
                                                          validation.handleBlur
                                                        }
                                                        className={`form-control ${
                                                          validation.touched[
                                                            `material[${key}].file_path`
                                                          ] &&
                                                          validation.errors[
                                                            `material[${key}].file_path`
                                                          ]
                                                            ? "is-invalid"
                                                            : ""
                                                        }`}
                                                      />
                                                      <div>
                                                        {validation?.values
                                                          ?.material[key]
                                                          ?.file_path_show ? (
                                                          <a
                                                            href={
                                                              validation.values
                                                                .material[key]
                                                                .file_path_show
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                          >
                                                            Previous File
                                                          </a>
                                                        ) : (
                                                          <span></span>
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <Button
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                          removeRow(row.id)
                                                        }
                                                      >
                                                        Remove
                                                      </Button>
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                            <button
                                              className="btn btn-primary ms-2 mb-3"
                                              onClick={addRow}
                                            >
                                              Add Row
                                            </button>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Row>
                                  ) : null}
                                  {String(
                                    validation.values.is_manpower_required
                                  ) === "1" ? (
                                    <Row>
                                      <Card>
                                        <CardBody>
                                          <Label> Manpower</Label>
                                          <div>
                                            <table className="table">
                                              <thead>
                                                <tr>
                                                  <th>Manpower</th>
                                                  <th>Quantity</th>
                                                  <th>Cost Per Unit</th>
                                                  <th>Total</th>
                                                  <th>Quotation File</th>

                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {labors.map((row, key) => (
                                                  <tr key={row.id}>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        name={`manpower[${key}].label_required`}
                                                        value={
                                                          validation?.values
                                                            ?.manpower[key]
                                                            ?.label_required
                                                        }
                                                        onChange={
                                                          validation.handleChange
                                                        }
                                                        placeholder="Enter Manpower"
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        name={`manpower[${key}].quantity`}
                                                        value={
                                                          validation?.values
                                                            ?.manpower[key]
                                                            ?.quantity
                                                        }
                                                        onChange={
                                                          validation.handleChange
                                                        }
                                                        placeholder="Enter Quantity"
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        name={`manpower[${key}].cost_per_unit`}
                                                        value={
                                                          validation?.values
                                                            .manpower[key]
                                                            ?.cost_per_unit
                                                        }
                                                        onChange={
                                                          validation.handleChange
                                                        }
                                                        placeholder="Enter Cost/Unit"
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        name={`manpower[0].total`}
                                                        disabled
                                                        value={
                                                          (validation?.values
                                                            ?.manpower?.[key]
                                                            ?.quantity || 0) *
                                                          (validation?.values
                                                            ?.manpower?.[key]
                                                            ?.cost_per_unit ||
                                                            0)
                                                        }
                                                        onChange={
                                                          validation.handleChange
                                                        }
                                                        placeholder="Enter Total"
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="file"
                                                        id={`manpower[${key}].file_path`}
                                                        name={`manpower[${key}].file_path`}
                                                        onChange={e => {
                                                          const selectedFile =
                                                            e.target.files[0]
                                                          validation.setFieldValue(
                                                            `manpower[${key}].file_path`,
                                                            selectedFile
                                                          )
                                                        }}
                                                        onBlur={
                                                          validation.handleBlur
                                                        }
                                                        className={`form-control ${
                                                          validation.touched[
                                                            `manpower[${key}].file_path`
                                                          ] &&
                                                          validation.errors[
                                                            `manpower[${key}].file_path`
                                                          ]
                                                            ? "is-invalid"
                                                            : ""
                                                        }`}
                                                      />
                                                      <div>
                                                        {validation?.values
                                                          ?.manpower[key]
                                                          ?.file_path_show ? (
                                                          <a
                                                            href={
                                                              validation.values
                                                                .manpower[key]
                                                                .file_path_show
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                          >
                                                            Previous File
                                                          </a>
                                                        ) : (
                                                          <span></span>
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <Button
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                          removeLaborRow(row.id)
                                                        }
                                                      >
                                                        Remove
                                                      </Button>
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                            <button
                                              className="btn btn-primary ms-2 mb-3"
                                              onClick={addLaborRow}
                                            >
                                              Add Row
                                            </button>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Row>
                                  ) : null}

                                  {String(
                                    validation.values.is_other_cost_required
                                  ) === "1" ? (
                                    <Row>
                                      <Card>
                                        <CardBody>
                                          <Label> Other Cost</Label>

                                          <div>
                                            <table className="table">
                                              <thead>
                                                <tr>
                                                  <th>Item</th>
                                                  <th>Amount</th>
                                                  <th>Quotation File</th>

                                                  <th>Action</th>
                                                  <th></th>
                                                  <th></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {otherCosts.map((row, key) => (
                                                  <tr key={row.id}>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        name={`other_cost[${key}].requirement`}
                                                        value={
                                                          validation?.values
                                                            ?.other_cost[key]
                                                            ?.requirement
                                                        }
                                                        onChange={
                                                          validation.handleChange
                                                        }
                                                        placeholder="Enter Requirement"
                                                      />
                                                    </td>

                                                    <td>
                                                      <Input
                                                        type="text"
                                                        name={`other_cost[${key}].other_cost`}
                                                        value={
                                                          validation?.values
                                                            ?.other_cost[key]
                                                            ?.other_cost
                                                        }
                                                        onChange={
                                                          validation.handleChange
                                                        }
                                                        placeholder="Enter Amount"
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="file"
                                                        id={`other_cost[${key}].file_path`}
                                                        name={`other_cost[${key}].file_path`}
                                                        onChange={e => {
                                                          const selectedFile =
                                                            e.target.files[0]
                                                          validation.setFieldValue(
                                                            `other_cost[${key}].file_path`,
                                                            selectedFile
                                                          )
                                                        }}
                                                        onBlur={
                                                          validation.handleBlur
                                                        }
                                                        className={`form-control ${
                                                          validation.touched[
                                                            `other_cost[${key}].file_path`
                                                          ] &&
                                                          validation.errors[
                                                            `other_cost[${key}].file_path`
                                                          ]
                                                            ? "is-invalid"
                                                            : ""
                                                        }`}
                                                      />
                                                      <div>
                                                        {validation?.values
                                                          ?.other_cost[key]
                                                          ?.file_path_show ? (
                                                          <a
                                                            href={
                                                              validation.values
                                                                .other_cost[key]
                                                                .file_path_show
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                          >
                                                            Previous File
                                                          </a>
                                                        ) : (
                                                          
                                                          <span></span>
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <Button
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                          removeOtherCostRow(
                                                            row.id
                                                          )
                                                        }
                                                      >
                                                        Remove
                                                      </Button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                            <button
                                              className="btn btn-primary ms-2 mb-3"
                                              onClick={addOtherCostRow}
                                            >
                                              Add Row
                                            </button>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Row>
                                  ) : null}
                                </Row>
                              </CardBody>
                            </Card>
                          </>
                        ) : null}
                        {String(validation.values.mode_of_operation) === "2" ? (
                          <>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Row>
                                    <Col lg={6}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-userRole-Input">
                                          Select Vendor
                                        </Label>
                                        <select
                                          name="vendor_id"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.vendor_id || ""
                                          }
                                          className={`form-select ${
                                            validation.touched.vendor_id &&
                                            validation.errors.vendor_id
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        >
                                          {!validation.values.vendor_id && (
                                            <option>Select Vendor</option>
                                          )}
                                          {(vendors || []).map(item => (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.vendor_name}
                                            </option>
                                          ))}
                                        </select>
                                        {validation.touched.vendor_id &&
                                        validation.errors.vendor_id ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.vendor_id}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-userRole-Input">
                                          Work Type
                                        </Label>
                                        <select
                                          name="work_type"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.work_type || ""
                                          }
                                          className={`form-select ${
                                            validation.touched.work_type &&
                                            validation.errors.work_type
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        >
                                          {!validation.values.work_type && (
                                            <option>Select User</option>
                                          )}
                                          <option value="1">Under AMC</option>
                                          <option value="2">New Work</option>
                                        </select>
                                        {validation.touched.work_type &&
                                        validation.errors.work_type ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.work_type}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                </Row>
                              </CardBody>
                            </Card>
                          </>
                        ) : null}

                        <Card>
                          <CardBody>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-userRole-Input">
                                    Priority
                                  </Label>
                                  <select
                                    name="priority_id"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priority_id || ""}
                                    className={`form-select ${
                                      validation.touched.priority_id &&
                                      validation.errors.priority_id
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  >
                                    {!validation.values.priority_id && (
                                      <option>Select Priority</option>
                                    )}
                                    <option value="1">Critical</option>
                                    <option value="2">High</option>
                                    <option value="3">Medium</option>
                                    <option value="4">Low</option>
                                  </select>
                                  {validation.touched.priority_id &&
                                  validation.errors.priority_id ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.priority_id}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3 mt-3">
                                  <Label htmlFor="instructions">
                                    Instruction
                                  </Label>
                                  <Input
                                    type="textarea"
                                    name="instructions"
                                    className="form-control"
                                    id="instructions"
                                    placeholder="Enter Instruction"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.instructions || ""}
                                    invalid={
                                      validation.touched.instructions &&
                                      validation.errors.instructions
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.instructions &&
                                  validation.errors.instructions ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.instructions}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            {data && data.status === 2 ? (
                              <div className="actions clearfix">
                                <button
                                  className="btn btn-primary me-2"
                                  onClick={validation.handleSubmit}
                                >
                                  Create Ticket
                                </button>
                              </div>
                            ) : null}
                            {data && data.status === 3 ? (
                              <div className="actions clearfix">
                                <button
                                  className="btn btn-primary me-2"
                                  onClick={validation.handleSubmit}
                                >
                                  Reassign Ticket
                                </button>
                              </div>
                            ) : null}
                          </CardBody>
                        </Card>
                      </>
                    ) : (
                      <>
                        <Row>
                          <TicketView
                            ticketid={ticketData && ticketData.ticket_id}
                          />
                        </Row>
                        <Row>
                          {" "}
                          {data && data.status !== 1 && data.status !== 4 ? (
                            <div className="actions clearfix text-end">
                              <button
                                className="btn btn-primary me-2 "
                                onClick={() => {
                                  setReassign(2)
                                }}
                              >
                                Reassign
                              </button>
                            </div>
                          ) : null}
                        </Row>
                      </>
                    )}
                  </>
                ) : null}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default withRouter(ReassignViewServiceRequest)

ReassignViewServiceRequest.propTypes = {
  history: Proptypes.object,
}
