import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_LANDLORDS,
  GET_LANDLORD_DETAIL,
  ADD_NEW_LANDLORD,
  DELETE_LANDLORD,
  UPDATE_LANDLORD,
  ACTIVE_LANDLORD,
  GET_DOCUMENT_LIST_LANDLORD,
  ADD_DOCUMENT_LANDLORD,
  DELETE_DOCUMENT_LANDLORD,
} from "./actionTypes"
import {
  getLandlordsSuccess,
  getLandlordsFail,
  getLandlordDetailSuccess,
  getLandlordDetailFail,
  addLandlordFail,
  addLandlordSuccess,
  updateLandlordSuccess,
  updateLandlordFail,
  deleteLandlordSuccess,
  deleteLandlordFail,
  activeLandlordSuccess,
  activeLandlordFail,
  getLandlordDocumentsSuccess,
  getLandlordDocumentsFail,
  addLandlordDocumentSuccess,
  addLandlordDocumentFail,
  deleteLandlordDocumentSuccess,
  deleteLandlordDocumentFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getLandlords,
  getLandlordDetails,
  addNewLandlord,
  updateLandlord,
  deleteLandlord,
  activeLandlord,
  getLandlordDocuments,
  addLandlordDocument,
  deleteLandlordDocument,
} from "helpers/fakebackend_helper"

function* fetchLandlords() {
  try {
    const response = yield call(getLandlords)
    yield put(getLandlordsSuccess(response))
  } catch (error) {
    yield put(getLandlordsFail(error))
  }
}

function* fetchLandlordDetail({ landlordId }) {
  try {
    const response = yield call(getLandlordDetails, landlordId)
    yield put(getLandlordDetailSuccess(response))
  } catch (error) {
    yield put(getLandlordDetailFail(error))
  }
}

function* onUpdateLandlord({ payload: { id, landlord } }) {
  try {
    const response = yield call(updateLandlord, id, landlord)
    yield put(updateLandlordSuccess(response))
  } catch (error) {
    yield put(updateLandlordFail(error))
  }
}

function* onDeleteLandlord({ payload: landlord }) {
  try {
    const response = yield call(deleteLandlord, landlord)
    yield put(deleteLandlordSuccess(response))
  } catch (error) {
    yield put(deleteLandlordFail(error))
  }
}

function* onAddNewLandlord({ payload: landlord }) {
  try {
    const response = yield call(addNewLandlord, landlord)
    yield put(addLandlordSuccess(response))
  } catch (error) {
    yield put(addLandlordFail(error))
  }
}

function* fetchActiveLandlords() {
  try {
    const response = yield call(activeLandlord)
    yield put(activeLandlordSuccess(response))
  } catch (error) {
    yield put(activeLandlordFail(error))
  }
}

function* fetchLandlordDocuments({ landlordId }) {
  try {
    const response = yield call(getLandlordDocuments, landlordId)
    yield put(getLandlordDocumentsSuccess(response))
  } catch (error) {
    yield put(getLandlordDocumentsFail(error))
  }
}

function* onAddLandlordDocument({ payload: landlord }) {
  try {
    const response = yield call(addLandlordDocument, landlord)
    yield put(addLandlordDocumentSuccess(response))
  } catch (error) {
    yield put(addLandlordDocumentFail(error))
  }
}

function* onDeleteLandlordDocument({ docs }) {
  try {
    const response = yield call(deleteLandlordDocument, docs)
    yield put(deleteLandlordDocumentSuccess(response))
  } catch (error) {
    yield put(deleteLandlordDocumentFail(error))
  }
}

function* landlordsSaga() {
  yield takeEvery(GET_LANDLORDS, fetchLandlords)
  yield takeEvery(ACTIVE_LANDLORD, fetchActiveLandlords)
  yield takeEvery(GET_LANDLORD_DETAIL, fetchLandlordDetail)
  yield takeEvery(ADD_NEW_LANDLORD, onAddNewLandlord)
  yield takeEvery(UPDATE_LANDLORD, onUpdateLandlord)
  yield takeEvery(DELETE_LANDLORD, onDeleteLandlord)
  yield takeEvery(GET_DOCUMENT_LIST_LANDLORD, fetchLandlordDocuments)
  yield takeEvery(ADD_DOCUMENT_LANDLORD, onAddLandlordDocument)
  yield takeEvery(DELETE_DOCUMENT_LANDLORD, onDeleteLandlordDocument)
}

export default landlordsSaga
