import {
  GET_COMPANY_FILTER_FAIL,
  GET_COMPANY_FILTER_SUCCESS,
  GET_COMPANY_LOCATION_FILTER_FAIL,
  GET_COMPANY_LOCATION_FILTER_SUCCESS,
  GET_CONSUMPTION_FILTER_FAIL,
  GET_CONSUMPTION_FILTER_SUCCESS,
  GET_CUSTOMER_FILTER_FAIL,
  GET_CUSTOMER_FILTER_FOR_CONSUMPTION_FAIL,
  GET_CUSTOMER_FILTER_FOR_CONSUMPTION_SUCCESS,
  GET_CUSTOMER_FILTER_SUCCESS,
  GET_FINANCIAL_YEAR_FILTER_FAIL,
  GET_FINANCIAL_YEAR_FILTER_SUCCESS,
  GET_PROJECT_FILTER_FAIL,
  GET_PROJECT_FILTER_FOR_CONSUMPTION_FAIL,
  GET_PROJECT_FILTER_FOR_CONSUMPTION_SUCCESS,
  GET_PROJECT_FILTER_SUCCESS,
  GET_UNIT_FOR_CONSUMPTION_FAIL,
  GET_UNIT_FOR_CONSUMPTION_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  
  customerFilter: [],
  projectFilter: [],
  unit: [],
  Consumption: [],
  //

  error: {},
  loading: true,
}

const consumptionReport = (state = INIT_STATE, action) => {
  switch (action.type) {
   
    case GET_CUSTOMER_FILTER_FOR_CONSUMPTION_SUCCESS:
      return {
        ...state,
        customerFilter: action.payload,
        loading: true,
      }

    case GET_CUSTOMER_FILTER_FOR_CONSUMPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_FILTER_FOR_CONSUMPTION_SUCCESS:
      return {
        ...state,
        projectFilter: action.payload,
        loading: true,
      }

    case GET_PROJECT_FILTER_FOR_CONSUMPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_UNIT_FOR_CONSUMPTION_SUCCESS:
      return {
        ...state,
        unit: action.payload,
        loading: true,
      }

    case GET_UNIT_FOR_CONSUMPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CONSUMPTION_FILTER_SUCCESS:
      return {
        ...state,
        Consumption: action.payload,
        loading: true,
      }

    case GET_CONSUMPTION_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default consumptionReport
