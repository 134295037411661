import React, { useEffect, useState } from "react"
import SimpleBar from "simplebar-react"
import ReactApexChart from "react-apexcharts"
import * as Yup from "yup"
import Swal from "sweetalert2"
import {
  Card,
  Col,
  CardBody,
  Table,
  Row,
  Label,
  FormFeedback,
  Badge,
} from "reactstrap"
import { useFormik } from "formik"
import NewCustomerModal from "../Modal/newCustomerModal"
import NewUnitsAssignedModal from "../Modal/newUnitsAssignedModal"
import NewBillsModal from "../Modal/newBillsModal"
import PaymentsModal from "../Modal/paymentsModal"
import { ActivityFilter, getTicketStatusPieDigram } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import StatusWiseTickets from "../Modal/statusWiseTickets"

const TicketActivity = () => {
  const dispatch = useDispatch()
  const [modal_large, setmodal_large] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)
  const [statusValue, setStatusValue] = useState()

  const toggleModalLarge = () => setModalLarge(!modalLarge)

  const dataState = state => state.ticketsDashboard?.ticketStatusPieDigram
  const data = useSelector(dataState) || []

  const handleModal = value => {
    toggleModalLarge()
    setStatusValue(value)
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed

    initialValues: {
      filter: "This Year",
    },

    validationSchema: Yup.object({}),
  })

  // useEffect(() => {
  //   dispatch(ActivityFilter({ filter: validation.values.filter }))
  // }, [validation.values.filter])

  useEffect(() => {
    dispatch(getTicketStatusPieDigram({ filter: validation.values.filter }))
  }, [validation.values.filter])

  const formatNumberWithCommas = number => {
    // If number is null or empty, return it as it is
    if (!number) {
      return number
    }

    // Convert number to string
    let numStr = String(number)

    // Split the string into integer and decimal parts
    let parts = numStr.split(".")

    // Format the integer part with commas
    let integerPart = parts[0]
    let lastThreeDigits = integerPart.slice(-3) // Get the last three digits
    let remainingDigits = integerPart.slice(0, -3) // Get the remaining digits
    let formattedInteger =
      remainingDigits !== ""
        ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          lastThreeDigits
        : lastThreeDigits

    // Combine integer and decimal parts with a dot
    // let formattedNumber = formattedInteger + (parts[1] ? "." + parts[1] : "")

    return formattedInteger
  }

  const getChartOptions = index => {
    var options = {
      chart: { sparkline: { enabled: !0 } },
      dataLabels: { enabled: !1 },
      colors: ["#556ee6"],
      plotOptions: {
        radialBar: {
          hollow: { margin: 0, size: "60%" },
          track: { margin: 0 },
          dataLabels: { show: !1 },
        },
      },
    }
    switch (index) {
      case 1:
        options["colors"][0] = "#556ee6"
        break
      case 2:
        options["colors"][0] = "#FFBF00"
        break
      case 3:
        options["colors"][0] = "#f46a6a"
        break
      case 4:
        options["colors"][0] = "#34c38f"
        break
      default:
        break
    }

    return options
  }

  return (
    <React.Fragment>
      <StatusWiseTickets
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        filterResponse={validation.values.filter}
        statusValue={statusValue}
      />

      <Col>
        <Card style={{ height: "476px" }}>
          <CardBody>
            <Row>
              <Col className="d-flex align-items-center">
                <h4 className="card-title mb-4">Ticket Analytics</h4>
              </Col>
              <Col lg={3}>
                <div className="mb-3">
                  <select
                    name="filter"
                    className={`form-select ${
                      validation.touched.filter && validation.errors.filter
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.filter}
                  >
                    <option value="This Week">This Week</option>
                    <option value="This Month">This Month</option>
                    <option value="This Year">This Year</option>
                    <option value="Previous Week">Previous Week</option>
                    <option value="Previous Month">Previous Month</option>
                    <option value="Previous Year">Previous Year</option>
                  </select>
                  {validation.touched.filter && validation.errors.filter ? (
                    <FormFeedback type="invalid">
                      {validation.errors.filter}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <div className="table-responsive mt-4">
              <Table className="table align-middle mb-1">
                <tbody>
                  <tr>
                    <td>
                      <h5 className="font-size-16 mb-1">New </h5>
                    </td>
                    <td></td>
                    <td
                      style={{ cursor: "pointer", textAlign: "end" }}
                      onClick={()=>{handleModal("1")}}
                    >
                      <h5 className="mb-0 text-primary">
                        {data && data.new_status}
                      </h5>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h5 className="font-size-16  mb-1">Assigned</h5>
                    </td>
                    <td></td>
                    <td
                      style={{ cursor: "pointer", textAlign: "end" }}
                      onClick={()=>{handleModal("2")}}
                    >
                      <h5 className="mb-0 mb-0 text-warning">
                        {data && data.assigned_status}
                      </h5>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h5 className="font-size-16  mb-1">
                        Submitted for approval
                      </h5>
                    </td>
                    <td></td>
                    <td
                      style={{ cursor: "pointer", textAlign: "end" }}
                      onClick={()=>{handleModal("3")}}
                    >
                      <h5 className="mb-0 mb-0 text-danger">
                        {data && data.submit_for_approval_status}
                      </h5>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h5 className="font-size-16 mb-1">Work in Progress</h5>
                    </td>
                    <td></td>
                    <td
                      style={{ cursor: "pointer", textAlign: "end" }}
                      onClick={()=>{handleModal("4")}}
                    >
                      <h5 className="mb-0 mb-0 text-success">
                        {data && data.work_in_progress_status}
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="font-size-16 mb-1">Under Review</h5>
                    </td>
                    <td></td>
                    <td
                      style={{ cursor: "pointer", textAlign: "end" }}
                      onClick={()=>{handleModal("5")}}
                    >
                      <h5 className="mb-0 mb-0 text-primary">
                        {data && data.under_review_status}
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="font-size-16 mb-1">Rework</h5>
                    </td>
                    <td></td>
                    <td
                      style={{ cursor: "pointer", textAlign: "end" }}
                      onClick={()=>{handleModal("6")}}
                    >
                      <h5 className="mb-0 mb-0 text-warning">
                        {data && data.rework_status}
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="font-size-16 mb-1">Closed</h5>
                    </td>
                    <td></td>
                    <td
                      style={{ cursor: "pointer", textAlign: "end" }}
                      onClick={()=>{handleModal("7")}}
                    >
                      <h5 className="mb-0 mb-0 text-success">
                        {data && data.close_status}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default TicketActivity
