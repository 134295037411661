import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  getPastEmploymentDetail,
  getCountries,
  getStates,
  getCities,
  getProjectListForClient,
  getProjectUnitListForClient,
  getInvoiceTypeMasterClientList,
  getSacHsnCodeClient,
  getApplicableRateClientList,
} from "store/actions"
import { addAssignedProjectUnit } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"

const AddNewAssignedProjectUnit = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [assignedProjectId, setAssignedProjectId] = useState()
  const [pastEmploymentData, setPastEmploymentData] = useState()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pastEmploymentState = state => state.users.pastEmp.data
  const pastEmployment = useSelector(pastEmploymentState) || []
  const pastEmploymentDataState = state => state.users.pastEmp.data
  const Empdata = useSelector(pastEmploymentDataState) || []
  const projectListState = state => state.clients.projectList.project_units
  const projectListData = useSelector(projectListState) || []
  const projectUnitListState = state =>
    state.clients.projectUnitList.project_unit
  const projectUnitListData = useSelector(projectUnitListState) || []
  const InvoiceMasterState = state => state.clients.invoiceTypeMasterList.data
  const invoiceTypeMasterList = useSelector(InvoiceMasterState) || []
  const HSNMasterState = state => state.clients.codesClient.data
  const sacHsnCode = useSelector(HSNMasterState) || []
  const applicableRateListState = state => state.clients.applicableRateList.data
  const applicableRateList = useSelector(applicableRateListState) || []

  console.log(sacHsnCode, invoiceTypeMasterList)
  const newData = () => {
    const url = new URL(window.location.href)
    const assignedProjectId = url.pathname.split(
      "/add-new-assigned-project-unit/"
    )[1]
    setAssignedProjectId(assignedProjectId)
  }

  useEffect(() => {
    newData()
  }, [])

  useEffect(() => {
    dispatch(getInvoiceTypeMasterClientList())
    dispatch(getSacHsnCodeClient())
    dispatch(getApplicableRateClientList())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCountries())
  }, [dispatch])

  useEffect(() => {
    dispatch(getProjectListForClient(user.user_id))
  }, [dispatch])

  const [selectedOption, setSelectedOption] = useState(
    Boolean(Empdata?.is_active)
  )
  const [rows, setRows] = useState([{ id: 1 }])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      project: assignedProjectId || "",
      project_unit: "",
      minimum_monthly_rent: "",
      revenue_share: "",
      revenue_share_percentage: "",
      rent_payment_schedule: "",
      customer_lock_in_period: "",
      notice_period: "",
      agreement_start_date: "",
      agreement_end_date: "",
      rent_escalation_percentage: "",
      rent_escalation_period: "",
      registration_cost_for_agreement_on_company: "",
      brand_name: "",
      security_deposit_for_rent: "",
      security_deposit_for_electricity: "",
      security_deposit_for_maintenance: "",
      document: "",
      billing_components: rows.map(row => ({
        // Add invoice type here, replace 'invoice_type' with your actual field name
        invoice_type_id: invoiceTypeMasterList[row.id - 1]?.id,
        description: invoiceTypeMasterList[row.id - 1]?.invoice_type,
        amount: "",
        gst_percentage: invoiceTypeMasterList[row.id - 1]?.gst_percentage,
        sac_hsm_id: invoiceTypeMasterList[row.id - 1]?.sac_hsm_id,
        sac_hsn_code: invoiceTypeMasterList[row.id - 1]?.sac_hsm_code,
        check: 0,
      })),
    },

    validationSchema: Yup.object({
      project: Yup.string().required("Please Select Project"),
      brand_name: Yup.string().required("Please Enter Brand Name"),
      security_deposit_for_rent: Yup.string().required(
        "Please Enter Security Deposit for Rent "
      ),
      security_deposit_for_electricity: Yup.string().required(
        "Please Enter Security Deposit for Electricity"
      ),
      security_deposit_for_maintenance: Yup.string().required(
        "Please Enter Security Deposit for Maintenance"
      ),
      project_unit: Yup.string().required("Please Select Project Unit"),
      minimum_monthly_rent: Yup.string(),
      revenue_share: Yup.string().required("Please Select Revenue Share"),
      revenue_share_percentage: Yup.string()
        .required("Please Enter Revenue Share Percentage(%)")
        .matches(
          /^[0-9]+(\.[0-9]{1,2})?$/,
          "Revenue Share Percentage(%) must be in percentage format"
        )
        .test(
          "max-value",
          "Revenue Share Percentage(%) must be less than or equal to 100",
          value => {
            if (value) {
              const floatValue = parseFloat(value)
              return floatValue <= 100
            }
            return true
          }
        ),

      rent_payment_schedule: Yup.string().required(
        "Please Select Rent Payment Schedule"
      ),
      customer_lock_in_period: Yup.string()
        .required("Please Enter Customer Lock in Period in Months")
        .matches(/^\d+$/, "Customer Lock in Period must be a number"),

      notice_period: Yup.string()
        .required("Please Enter Notice Period in Months")
        .matches(/^\d+$/, "Notice Period must be a number"),

      agreement_start_date: Yup.string().required(
        "Agreement Start date is required"
      ),
      agreement_end_date: Yup.string().when(
        "agreement_start_date",
        (agreement_start_date, schema) => {
          return agreement_start_date
            ? schema
                .test(
                  "is-after-start",
                  "Agreement End Date must be after Agreement Start date",
                  function (value) {
                    const { agreement_start_date } = this.parent
                    if (!agreement_start_date || !value) return true // Allow null values
                    return new Date(value) > new Date(agreement_start_date)
                  }
                )
                .required(
                  "Agreement End Date is required when Agreement Start date is provided"
                )
            : schema
        }
      ),
      rent_escalation_percentage: Yup.string()
        .matches(
          /^[0-9]+(\.[0-9]{1,2})?$/,
          "Rent Escalation Percentage(%) must be in percentage format"
        )
        .test(
          "max-value",
          "Rent Escalation Percentage(%) must be less than or equal to 100",
          value => {
            if (value) {
              const floatValue = parseFloat(value)
              return floatValue <= 100
            }
            return true
          }
        ),
      rent_escalation_period: Yup.string(),
      registration_cost_for_agreement_on_company: Yup.string(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        const response = await addAssignedProjectUnit(assignedProjectId, values)

        if (response && response.status === true) {
          loadingSwal.close()
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Assigned Project has been Created.",
          }).then(result => {
            if (result.isConfirmed) {
              navigate(-2)
            }
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "failure",
            icon: "error",
            text: "Your Assigned Project has not been saved.",
          })
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })
  console.log(validation.values)
  const handleChange = event => {
    setSelectedOption(event.target.value)

    validation.setFieldValue("approval_status", event.target.value)
  }
  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState(validation.values.state)

  useEffect(() => {
    const fetchData = async () => {
      // Fetch countries
      await dispatch(getCountries())

      // If country is selected (edit mode), fetch states and cities
      if (validation.values.country) {
        await dispatch(getStates(validation.values.country))
        await dispatch(getCities(validation.values.state))
      }

      // Set initial values
      setCountryName(validation.values.country)
      setStateId(validation.values.state)
    }

    fetchData()
  }, [dispatch, validation.values.country, validation.values.state])

  useEffect(() => {
    if (validation.values.project !== "") {
      dispatch(getProjectUnitListForClient(validation.values.project))
    }
  }, [dispatch, validation.values.project])

  const addRow = () => {
    const newRow = { id: rows.length + 1 }
    setRows([...rows, newRow])
  }

  useEffect(() => {
    if (invoiceTypeMasterList) {
      // Update rows state based on the length of items array
      setRows(
        [...Array(invoiceTypeMasterList.length)].map((_, index) => ({
          id: index + 1,
        }))
      )
    }
  }, [invoiceTypeMasterList])

  const removeRow = id => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete this record?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      cancelButtonColor: "#6c757d", // Change this to your desired color
      confirmButtonColor: "#dc3545", // Change this to your desired color
    }).then(result => {
      if (result.isConfirmed) {
        const updatedRows = rows.filter(row => row.id !== id)
        setRows(updatedRows)
      }
    })
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <CardTitle className="h4" style={{ fontSize: "20px" }}>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              Assign Project Unit
            </div>
          </CardTitle>
          <Row>
            <Col>
              <Form
                autoComplete="off"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="project">Project*</Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.project}
                            name="project"
                            className={`form-select ${
                              validation.touched.project &&
                              validation.errors.project
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Project</option>
                            {projectListData.map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project &&
                            validation.errors.project && (
                              <div className="invalid-feedback">
                                {validation.errors.project}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="brand_name">Brand*</Label>
                          <Input
                            type="text"
                            name="brand_name"
                            className="form-control"
                            id="brand_name"
                            placeholder="Enter Brand Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.brand_name || ""}
                            invalid={
                              validation.touched.brand_name &&
                              validation.errors.brand_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.brand_name &&
                          validation.errors.brand_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.brand_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="project_unit">Project Unit*</Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.project_unit}
                            name="project_unit"
                            className={`form-select ${
                              validation.touched.project_unit &&
                              validation.errors.project_unit
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Project Unit</option>
                            {projectUnitListData?.map(projectUnit => (
                              <option
                                key={projectUnit.id}
                                value={projectUnit.id}
                              >
                                {projectUnit.unit_code}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_unit &&
                            validation.errors.project_unit && (
                              <div className="invalid-feedback">
                                {validation.errors.project_unit}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="minimum_monthly_rent">
                            Minimum Monthly Rent
                          </Label>
                          <Input
                            type="text"
                            name="minimum_monthly_rent"
                            className="form-control"
                            id="minimum_monthly_rent"
                            placeholder="Enter Minimum monthly rent for the unit"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.minimum_monthly_rent || ""}
                            invalid={
                              validation.touched.minimum_monthly_rent &&
                              validation.errors.minimum_monthly_rent
                                ? true
                                : false
                            }
                          />
                          {validation.touched.minimum_monthly_rent &&
                          validation.errors.minimum_monthly_rent ? (
                            <FormFeedback type="invalid">
                              {validation.errors.minimum_monthly_rent}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="revenue_share">Revenue Share*</Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.revenue_share}
                            name="revenue_share"
                            className={`form-select ${
                              validation.touched.revenue_share &&
                              validation.errors.revenue_share
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Duration</option>
                            <option value="1">Monthly</option>
                            <option value="2">Annually</option>
                          </select>
                          {validation.touched.revenue_share &&
                          validation.errors.revenue_share ? (
                            <FormFeedback type="invalid">
                              {validation.errors.revenue_share}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="revenue_share_percentage">
                            Revenue Share (%)*
                          </Label>
                          <Input
                            type="text"
                            name="revenue_share_percentage"
                            className="form-control"
                            id="revenue_share_percentage"
                            placeholder="Enter Share in Percentage"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.revenue_share_percentage || ""
                            }
                            invalid={
                              validation.touched.revenue_share_percentage &&
                              validation.errors.revenue_share_percentage
                                ? true
                                : false
                            }
                          />
                          {validation.touched.revenue_share_percentage &&
                          validation.errors.revenue_share_percentage ? (
                            <FormFeedback type="invalid">
                              {validation.errors.revenue_share_percentage}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="rent_payment_schedule">
                            Rent Payment Schedule*
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            defaultValue="IN"
                            value={validation.values.rent_payment_schedule}
                            name="rent_payment_schedule"
                            className={`form-select ${
                              validation.touched.rent_payment_schedule &&
                              validation.errors.rent_payment_schedule
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Duration</option>
                            <option value="1">Monthly</option>
                            <option value="2">Quaterly</option>{" "}
                            <option value="3">Half Yearly</option>{" "}
                            <option value="4">Yearly</option>{" "}
                          </select>
                          {validation.touched.rent_payment_schedule &&
                          validation.errors.rent_payment_schedule ? (
                            <FormFeedback type="invalid">
                              {validation.errors.rent_payment_schedule}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="customer_lock_in_period">
                            Customer Lock-in period* (Months)
                          </Label>
                          <Input
                            type="text"
                            name="customer_lock_in_period"
                            className="form-control"
                            id="customer_lock_in_period"
                            placeholder="Number of Months"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.customer_lock_in_period || ""
                            }
                            invalid={
                              validation.touched.customer_lock_in_period &&
                              validation.errors.customer_lock_in_period
                                ? true
                                : false
                            }
                          />
                          {validation.touched.customer_lock_in_period &&
                          validation.errors.customer_lock_in_period ? (
                            <FormFeedback type="invalid">
                              {validation.errors.customer_lock_in_period}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="notice_period">
                            Notice period* (Months)
                          </Label>
                          <Input
                            type="text"
                            name="notice_period"
                            className="form-control"
                            id="notice_period"
                            placeholder="Number of Months"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.notice_period || ""}
                            invalid={
                              validation.touched.notice_period &&
                              validation.errors.notice_period
                                ? true
                                : false
                            }
                          />
                          {validation.touched.notice_period &&
                          validation.errors.notice_period ? (
                            <FormFeedback type="invalid">
                              {validation.errors.notice_period}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="security_deposit_for_rent">
                            Security Deposit for Rent*
                          </Label>
                          <Input
                            type="text"
                            name="security_deposit_for_rent"
                            className="form-control"
                            id="security_deposit_for_rent"
                            placeholder="Enter Security Deposit for Rent"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.security_deposit_for_rent || ""
                            }
                            invalid={
                              validation.touched.security_deposit_for_rent &&
                              validation.errors.security_deposit_for_rent
                                ? true
                                : false
                            }
                          />
                          {validation.touched.security_deposit_for_rent &&
                          validation.errors.security_deposit_for_rent ? (
                            <FormFeedback type="invalid">
                              {validation.errors.security_deposit_for_rent}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="security_deposit_for_electricity">
                            Security Deposit for Electricity*
                          </Label>
                          <Input
                            type="text"
                            name="security_deposit_for_electricity"
                            className="form-control"
                            id="security_deposit_for_electricity"
                            placeholder="Enter Security Deposit Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values
                                .security_deposit_for_electricity || ""
                            }
                            invalid={
                              validation.touched
                                .security_deposit_for_electricity &&
                              validation.errors.security_deposit_for_electricity
                                ? true
                                : false
                            }
                          />
                          {validation.touched
                            .security_deposit_for_electricity &&
                          validation.errors.security_deposit_for_electricity ? (
                            <FormFeedback type="invalid">
                              {
                                validation.errors
                                  .security_deposit_for_electricity
                              }
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="security_deposit_for_maintenance">
                            Security Deposit of Maintenance*
                          </Label>
                          <Input
                            type="text"
                            name="security_deposit_for_maintenance"
                            className="form-control"
                            id="security_deposit_for_maintenance"
                            placeholder="Enter Security Deposit for Maintenance"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values
                                .security_deposit_for_maintenance || ""
                            }
                            invalid={
                              validation.touched
                                .security_deposit_for_maintenance &&
                              validation.errors.security_deposit_for_maintenance
                                ? true
                                : false
                            }
                          />
                          {validation.touched
                            .security_deposit_for_maintenance &&
                          validation.errors.security_deposit_for_maintenance ? (
                            <FormFeedback type="invalid">
                              {
                                validation.errors
                                  .security_deposit_for_maintenance
                              }
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="rent_escalation_percentage">
                            Rent Escalation(%)
                          </Label>
                          <Input
                            type="text"
                            name="rent_escalation_percentage"
                            className="form-control"
                            id="rent_escalation_percentage"
                            placeholder="Enter Rent Escalation in Percentage"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.rent_escalation_percentage || ""
                            }
                            invalid={
                              validation.touched.rent_escalation_percentage &&
                              validation.errors.rent_escalation_percentage
                                ? true
                                : false
                            }
                          />
                          {validation.touched.rent_escalation_percentage &&
                          validation.errors.rent_escalation_percentage ? (
                            <FormFeedback type="invalid">
                              {validation.errors.rent_escalation_percentage}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="rent_escalation_period">
                            Rent Escalation Period (Months)
                          </Label>
                          <Input
                            type="text"
                            name="rent_escalation_period"
                            className="form-control"
                            id="rent_escalation_period"
                            placeholder="No. of months to be entered"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.rent_escalation_period || ""
                            }
                            invalid={
                              validation.touched.rent_escalation_period &&
                              validation.errors.rent_escalation_period
                                ? true
                                : false
                            }
                          />
                          {validation.touched.rent_escalation_period &&
                          validation.errors.rent_escalation_period ? (
                            <FormFeedback type="invalid">
                              {validation.errors.rent_escalation_period}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="registration_cost_for_agreement_on_company">
                            Registration cost for agreement on company
                          </Label>
                          <Input
                            type="text"
                            name="registration_cost_for_agreement_on_company"
                            className="form-control"
                            id="registration_cost_for_agreement_on_company"
                            placeholder="Enter Registration cost for agreement on company"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values
                                .registration_cost_for_agreement_on_company ||
                              ""
                            }
                            invalid={
                              validation.touched
                                .registration_cost_for_agreement_on_company &&
                              validation.errors
                                .registration_cost_for_agreement_on_company
                                ? true
                                : false
                            }
                          />
                          {validation.touched
                            .registration_cost_for_agreement_on_company &&
                          validation.errors
                            .registration_cost_for_agreement_on_company ? (
                            <FormFeedback type="invalid">
                              {
                                validation.errors
                                  .registration_cost_for_agreement_on_company
                              }
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="agreement_start_date">
                            Lease Agreement Start Date*
                          </Label>
                          <Input
                            name="agreement_start_date"
                            type="date"
                            className={`form-control ${
                              validation.touched.agreement_start_date &&
                              validation.errors.agreement_start_date
                                ? "is-invalid"
                                : ""
                            }`}
                            id="agreement_start_date"
                            placeholder="Enter Start Date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.agreement_start_date || ""}
                          />
                          {validation.touched.agreement_start_date &&
                          validation.errors.agreement_start_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.agreement_start_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="agreement_end_date">
                            Lease Agreement End Date*
                          </Label>
                          <Input
                            name="agreement_end_date"
                            type="date"
                            className={`form-control ${
                              validation.touched.agreement_end_date &&
                              validation.errors.agreement_end_date
                                ? "is-invalid"
                                : ""
                            }`}
                            id="agreement_end_date"
                            placeholder="Enter Start Date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.agreement_end_date || ""}
                          />
                          {validation.touched.agreement_end_date &&
                          validation.errors.agreement_end_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.agreement_end_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <Label htmlFor="document">
                          Upload Lease Agreement Document
                          <i
                            className="mdi mdi-email"
                            onClick={() =>
                              handlePreview(validation.values.document)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {validation.values.document
                              ? "PREVIEW DOCUMENT"
                              : ""}
                          </i>
                        </Label>
                        <input
                          type="file"
                          id="document"
                          name="document"
                          onChange={e => {
                            const selectedFile = e.target.files[0]
                            validation.setFieldValue(`document`, selectedFile)
                          }}
                          onBlur={validation.handleBlur}
                          className={`form-control ${
                            validation.touched.document &&
                            validation.errors.document
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {validation.touched.document &&
                          validation.errors.document && (
                            <div className="invalid-feedback">
                              {validation.errors.document}
                            </div>
                          )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-3">
                          Rent and Maintenance Setup
                        </CardTitle>
                        <Row>
                          <Col lg={1} style={{ width: "50px" }}>
                            <div className="mb-3">
                              <Label> </Label>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Invoice Type</Label>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Amount</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>SAC / HSN Code</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>GST Rate</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>GST Amount</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>Total</Label>
                            </div>
                          </Col>
                        </Row>
                        {rows.map((row, key) => (
                          <Row key={key}>
                            <Col
                              lg={1}
                              className="d-flex align-items-center justify-content-end"
                              style={{ width: "50px" }}
                            >
                              <div className="mb-4">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`record_${key}`}
                                  name={`billing_components[${key}].check`}
                                  onChange={e => {
                                    const { name, checked } = e.target
                                    validation.setFieldValue(
                                      name,
                                      checked ? 1 : 0
                                    ) // Update form field value
                                  }}
                                  onBlur={validation.handleBlur}
                                  checked={
                                    Number(
                                      validation.values.billing_components[key]
                                        ?.check
                                    ) === 1
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg={2} className="d-flex align-items-center">
                              <div className="mb-3">
                                <p
                                  htmlFor={`invoice_type_${key}`}
                                  className="form-label"
                                >
                                  {
                                    invoiceTypeMasterList[row.id - 1]
                                      ?.invoice_type
                                  }
                                </p>
                              </div>
                            </Col>

                            <Col lg={2}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  id={`amount_${key}`}
                                  name={`billing_components[${key}].amount`}
                                  placeholder="Enter Amount"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.billing_components[key]
                                      ?.amount || ""
                                  }
                                />
                                {validation.touched.billing_components?.[key]
                                  ?.amount &&
                                  validation.errors.billing_components?.[key]
                                    ?.amount && (
                                    <FormFeedback type="invalid">
                                      {
                                        validation.errors.billing_components[
                                          key
                                        ]?.amount
                                      }
                                    </FormFeedback>
                                  )}
                              </div>
                            </Col>

                            <Col lg={1}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  id={`sac_hsn_code_${key}`}
                                  name={`billing_components[${key}].sac_hsn_code`}
                                  placeholder="NA"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  disabled
                                  value={
                                    validation.values.billing_components[key]
                                      ?.sac_hsn_code || ""
                                  }
                                />
                                {validation.touched.billing_components?.[key]
                                  ?.sac_hsn_code &&
                                  validation.errors.billing_components?.[key]
                                    ?.sac_hsn_code && (
                                    <FormFeedback type="invalid">
                                      {
                                        validation.errors.billing_components[
                                          key
                                        ]?.sac_hsn_code
                                      }
                                    </FormFeedback>
                                  )}
                              </div>
                            </Col>
                            <Col lg={1}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  disabled
                                  id={`gst_percentage${key}`}
                                  name={`billing_components[${key}].gst_percentage`}
                                  placeholder="NA"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.billing_components[key]
                                      ?.gst_percentage || ""
                                  }
                                />
                                {validation.touched.billing_components?.[key]
                                  ?.gst_percentage &&
                                  validation.errors.billing_components?.[key]
                                    ?.gst_percentage && (
                                    <FormFeedback type="invalid">
                                      {
                                        validation.errors.billing_components[
                                          key
                                        ]?.gst_percentage
                                      }
                                    </FormFeedback>
                                  )}
                              </div>
                            </Col>
                            <Col lg={1}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  disabled
                                  value={
                                    validation.values.billing_components[key]
                                      ?.amount *
                                    (validation.values.billing_components[key]
                                      ?.gst_percentage /
                                      100)
                                  }
                                  id={`gst_amount${key}`}
                                  name={`billing_components[${key}].gst_amount`}
                                  placeholder="GST Amount"
                                />
                                {validation.touched.billing_components?.[key]
                                  ?.gst_amount &&
                                  validation.errors.billing_components?.[key]
                                    ?.gst_amount && (
                                    <FormFeedback type="invalid">
                                      {
                                        validation.errors.billing_components[
                                          key
                                        ]?.gst_amount
                                      }
                                    </FormFeedback>
                                  )}
                              </div>
                            </Col>
                            <Col lg={1}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  name={`billing_components[${key}].total`}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    (parseFloat(
                                      validation.values.billing_components[key]
                                        ?.amount
                                    ) || 0) +
                                    (parseFloat(
                                      validation.values.billing_components[key]
                                        ?.amount || 0
                                    ) *
                                      (parseFloat(
                                        validation.values.billing_components[
                                          key
                                        ]?.gst_percentage
                                      ) /
                                        100) || 0)
                                  }
                                  id={`total_${key}`}
                                  placeholder="Total"
                                  disabled
                                />
                              </div>
                            </Col>

                            {/* <Col>
                              <div className="mb-3">
                                <button
                                  type="button"
                                  // className="btn btn-danger "
                                  style={{
                                    border: "none",
                                    background: "none",
                                    padding: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeRow(row.id)}
                                >
                                  <i
                                    className="mdi mdi-delete"
                                    style={{
                                      color: "red",
                                      fontSize: "20px",
                                    }}
                                  />
                                </button>
                              </div>
                            </Col> */}
                          </Row>
                        ))}
                        {/* <Row>
                          <Col>
                            <div className="mb-3 text-start">
                              <button
                                type="button"
                                className="btn btn-primary w-md me-2"
                                style={{ width: "10%" }}
                                onClick={addRow}
                              >
                                Add
                              </button>
                            </div>
                          </Col>
                        </Row> */}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3 text-end">
                      <button type="submit" className="btn btn-primary l-md">
                        Send for Billing
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AddNewAssignedProjectUnit
