import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_UNITS, GET_UNIT_DETAIL, ADD_NEW_UNIT, DELETE_UNIT, UPDATE_UNIT, GET_ACTIVE_UNITS } from "./actionTypes"
import {
  getUnitsSuccess,
  getUnitsFail,
  getUnitDetailSuccess,
  getUnitDetailFail,
  addUnitFail,
  addUnitSuccess,
  updateUnitSuccess,
  updateUnitFail,
  deleteUnitSuccess,
  deleteUnitFail,
  getActiveUnitsSuccess,
  getActiveUnitsFail
} from "./actions"

//Include Both Helper File with needed methods
import { getUnits, getUnitDetails, addNewUnit, updateUnit, deleteUnit, getActiveUnits } from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

function* fetchUnits() {
  try {
    const response = yield call(getUnits)

    yield put(getUnitsSuccess(response))
  } catch (error) {
    yield put(getUnitsFail(error))
  } 
}


function* fetchUnitDetail({ unitId }) {
  try {
    const response = yield call(getUnitDetails, unitId)
   
    yield put(getUnitDetailSuccess(response))
  } catch (error) {
    yield put(getUnitDetailFail(error))
  }
}

function* onUpdateUnit({ payload: { id, unit } }) {
  try {
    const response = yield call(updateUnit,id, unit)

  } catch (error) {
    yield put(updateUnitFail(error))
    
  }
  const response2 = yield put(updateFacilitySuccess(response))

}

function* onDeleteUnit({ payload: unit }) {
  try {
    const response = yield call(deleteUnit, unit)
    yield put(deleteUnitSuccess(response))
    // toast.success("Unit Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteUnitFail(error))
    // toast.error("Unit Delete Failded", { autoClose: 2000 })
  }
}

function* onAddNewUnit({ payload: unit }) {
  try {
    const response = yield call(addNewUnit, unit)
    yield put(addUnitSuccess(response))
    toast.success("Unit Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addUnitFail(error))
    toast.error("Unit Add Failded", { autoClose: 2000 })
  }
}

function* fetchActiveUnits() {
  try {
    const response = yield call(getActiveUnits)
    yield put(getActiveUnitsSuccess(response))
  } catch (error) {
    yield put(getActiveUnitsFail(error))
  } 
}

function* unitsSaga() {
  yield takeEvery(GET_UNITS, fetchUnits)
  yield takeEvery(GET_UNIT_DETAIL, fetchUnitDetail)
  yield takeEvery(ADD_NEW_UNIT, onAddNewUnit)
  yield takeEvery(UPDATE_UNIT, onUpdateUnit)
  yield takeEvery(DELETE_UNIT, onDeleteUnit)
  yield takeEvery(GET_ACTIVE_UNITS, fetchActiveUnits)
}

export default unitsSaga
