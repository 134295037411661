import {
  GET_CITIES_FAIL,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_SUCCESS,
  GET_PROJECT_LIST_BY_CUSTOMER_ID_FAIL,
  GET_PROJECT_LIST_BY_CUSTOMER_ID_SUCCESS,
  GET_PROJECT_LIST_BY_VENDOR_ID_FAIL,
  GET_PROJECT_LIST_BY_VENDOR_ID_SUCCESS,
  GET_PROJECT_UNIT_DATA_FAIL,
  GET_PROJECT_UNIT_DATA_SUCCESS,
  GET_STATES_FAIL,
  GET_STATES_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  cities: [],
  states: [],
  countries: [],
  projectList: [],
  projectListVendor: [],
  projectUnit: {},
  error: {},
  loading: true,
}

const common = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload,
        loading: false, // Set loading to false on success
      }

    case GET_CITIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false, // Set loading to false on failure
      }

    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        loading: false, // Set loading to false on success
      }

    case GET_STATES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false, // Set loading to false on failure
      }

    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loading: false,
      }

    case GET_COUNTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PROJECT_LIST_BY_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        projectList: action.payload,
        loading: false,
      }

    case GET_PROJECT_LIST_BY_CUSTOMER_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_PROJECT_UNIT_DATA_SUCCESS:
      return {
        ...state,
        projectUnit: action.payload,
        loading: false,
      }

    case GET_PROJECT_UNIT_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PROJECT_LIST_BY_VENDOR_ID_SUCCESS:
      return {
        ...state,
        projectListVendor: action.payload,
        loading: false,
      }

    case GET_PROJECT_LIST_BY_VENDOR_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default common
