import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CLIENTS,
  GET_CLIENTS_LIST,
  ADD_NEW_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  GET_CLIENT_DOCUMENTS,
  ADD_NEW_CLIENT_DOCUMENT,
  DELETE_CLIENT_DOCUMENT,
  GET_ASSIGNED_PROJECT,
  GET_ASSIGNED_PROJECT_LIST,
  ADD_ASSIGNED_PROJECT,
  UPDATE_ASSIGNED_PROJECT,
  DELETE_ASSIGNED_PROJECT,
  REINVITE_CLIENT,
  GET_ALL_ASSIGNED_PROJECT_UNITS,
  GET_PROJECT_UNIT_LIST_FOR_CLIENT,
  GET_PROJECT_LIST_FOR_CLIENT,
  GET_CLIENT_CODE,
  GET_SACHSN_CODE_CLIENT,
  GET_INVOICE_TYPE_MASTER_LIST,
  GET_INVOICE_TYPE_MASTER_CLIENT_LIST,
  GET_APPLICABLE_RATE_CLIENT_LIST,
} from "./actionTypes"
import {
  getClientsSuccess,
  getClientsFail,
  getClientsListSuccess,
  getClientsListFail,
  addNewClientSuccess,
  addNewClientFail,
  updateClientSuccess,
  updateClientFail,
  deleteClientSuccess,
  deleteClientFail,
  getClientDocumentsSuccess,
  getClientDocumentsFail,
  addClientDocumentsSuccess,
  addClientDocumentsFail,
  deleteClientDocumentSuccess,
  deleteClientDocumentFail,
  getAssignedProjectUnitSuccess,
  getAssignedProjectUnitFail,
  getAssignedProjectUnitListSuccess,
  getAssignedProjectUnitListFail,
  addAssignedProjectUnitSuccess,
  addAssignedProjectUnitFail,
  updateAssignedProjectUnitSuccess,
  updateAssignedProjectUnitFail,
  deleteAssignedProjectUnitSuccess,
  deleteAssignedProjectUnitFail,
  reinviteClientSuccess,
  reinviteClientFail,
  getAllAssignedProjectsSuccess,
  getAllAssignedProjectsFail,
  getProjectUnitListForClientSuccess,
  getProjectUnitListForClientFail,
  getProjectListForClientSuccess,
  getProjectListForClientFail,
  getClientCodeSuccess,
  getClientCodeFail,
  getInvoiceTypeMasterListSuccess,
  getInvoiceTypeMasterListFail,
  getSacHsnCodeBillingFail,
  getSacHsnCodeBillingSuccess,
  getInvoiceTypeMasterClientListSuccess,
  getInvoiceTypeMasterClientListFail,
  getSacHsnCodeClientSuccess,
  getSacHsnCodeClientFail,
  getApplicableRateClientListSuccess,
  getApplicableRateClientListFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getClients,
  getClientsList,
  addNewClient,
  updateClient,
  deleteClient,
  getClientDocuments,
  addClientDocuments,
  deleteClientDocument,
  getAssignedProjectUnit,
  getAssignedProjectUnitList,
  addAssignedProjectUnit,
  updateAssignedProjectUnit,
  deleteAssignedProjectUnit,
  reinviteClient,
  getAllAssignedProjects,
  getProjectListForClients,
  getProjectUnitListForClients,
  getClientCode,
  getInvoiceTypeMasterList,
  getSacHsnCodeClient,
  getInvoiceTypeMasterClientList,
  getApplicableRateClientList,
} from "helpers/fakebackend_helper"

function* fetchClients({ clientId }) {
  try {
    const response = yield call(getClients, clientId)
    yield put(getClientsSuccess(response))
  } catch (error) {
    yield put(getClientsFail(error))
  }
}

function* fetchClientsList({ id }) {
  try {
    const response = yield call(getClientsList, id)
    yield put(getClientsListSuccess(response))
  } catch (error) {
    yield put(getClientsListFail(error))
  }
}

function* onAddNewClient({ payload: client }) {
  try {
    const response = yield call(addNewClient, client)
    yield put(addNewClientSuccess(response))
  } catch (error) {
    yield put(addNewClientFail(error))
  }
}

function* onUpdateClient({ payload: id, client }) {
  try {
    const response = yield call(updateClient, id, client)
    yield put(updateClientSuccess(response))
  } catch (error) {
    yield put(updateClientFail(error))
  }
}

// function* onDeleteClient({ payload: client }) {
//   try {
//     const response = yield call(deleteClient, client)
//     yield put(deleteClientSuccess(response))
//   } catch (error) {
//     yield put(deleteClientFail(error))
//   }
// }

function* onDeleteClient({ payload: client }) {
  try {
    const response = yield call(deleteClient, client)
    const response3 = yield put(deleteClientSuccess(response))
    // yield take('DELETE_CLIENT_SUCCESS');
    if (response3.payload.status) {
      const clientListResponse = yield call(getClientsList)
      yield put(getClientsListSuccess(clientListResponse))
    }
  } catch (error) {
    yield put(deleteClientFail(error))
  }
}

function* fetchClientDocuments({ clientId }) {
  try {
    const response = yield call(getClientDocuments, clientId)
    yield put(getClientDocumentsSuccess(response))
  } catch (error) {
    yield put(getClientDocumentsFail(error))
  }
}

function* onAddClientDocument({ payload: client }) {
  try {
    const response = yield call(addClientDocuments, client)
    yield put(addClientDocumentsSuccess(response))
  } catch (error) {
    yield put(addClientDocumentsFail(error))
  }
}

function* onDeleteClientDocument({ docs }) {
  try {
    const response = yield call(deleteClientDocument, docs)
    yield put(deleteClientDocumentSuccess(response))
  } catch (error) {
    yield put(deleteClientDocumentFail(error))
  }
}

function* fetchAssignedProject({ emp }) {
  try {
    const response = yield call(getAssignedProjectUnit, emp)
    yield put(getAssignedProjectUnitSuccess(response))
  } catch (error) {
    yield put(getAssignedProjectUnitFail(error))
  }
}
function* fetchAssignedProjectList({ emp }) {
  try {
    const response = yield call(getAssignedProjectUnitList, emp)
    yield put(getAssignedProjectUnitListSuccess(response))
  } catch (error) {
    yield put(getAssignedProjectUnitListFail(error))
  }
}

function* onAddAssignedProject({ payload: { id, user } }) {
  try {
    const response = yield call(addAssignedProjectUnit, id, user)
    yield put(addAssignedProjectUnitSuccess(response))
    // toast.success("User Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addAssignedProjectUnitFail(error))
    // toast.error("User Add Failded", { autoClose: 2000 })
  }
}

function* onUpdateAssignedProject({ payload: { id, user } }) {
  try {
    const response = yield call(updateAssignedProjectUnit, id, user)
    yield put(updateAssignedProjectUnitSuccess(response))
    // toast.success("User Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateAssignedProjectUnitFail(error))
    // toast.error("User Update Failded", { autoClose: 2000 })
  }
}

function* onDeleteAssignedProject({ payload: user }) {
  try {
    const response = yield call(deleteAssignedProjectUnit, user)
    yield put(deleteAssignedProjectUnitSuccess(response))
    // toast.success("User Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteAssignedProjectUnitFail(error))
    // toast.error("User Delete Failded", { autoClose: 2000 })
  }
}

function* reinviteClientApi({ payload: user }) {
  try {
    const response = yield call(reinviteClient, user)
    yield put(reinviteClientSuccess(response))
  } catch (error) {
    yield put(reinviteClientFail(error))
  }
}

function* fetchAllProjectUnits() {
  try {
    const response = yield call(getAllAssignedProjects)
    yield put(getAllAssignedProjectsSuccess(response))
  } catch (error) {
    yield put(getAllAssignedProjectsFail(error))
  }
}

function* fetchProjectListForClients({ id }) {
  try {
    const response = yield call(getProjectListForClients, id)
    yield put(getProjectListForClientSuccess(response))
  } catch (error) {
    yield put(getProjectListForClientFail(error))
  }
}

function* fetchProjectUnitListForClients({ clientId }) {
  try {
    const response = yield call(getProjectUnitListForClients, clientId)
    yield put(getProjectUnitListForClientSuccess(response))
  } catch (error) {
    yield put(getProjectUnitListForClientFail(error))
  }
}

function* fetchClientCode() {
  try {
    const response = yield call(getClientCode)
    yield put(getClientCodeSuccess(response))
  } catch (error) {
    yield put(getClientCodeFail(error))
  }
}

function* fetchSacHsnCodeClient() {
  try {
    const response = yield call(getSacHsnCodeClient)
    yield put(getSacHsnCodeClientSuccess(response))
  } catch (error) {
    yield put(getSacHsnCodeClientFail(error))
  }
}
function* fetchInvoiceTypeMasterList() {
  try {
    const response = yield call(getInvoiceTypeMasterClientList)
    yield put(getInvoiceTypeMasterClientListSuccess(response))
  } catch (error) {
    yield put(getInvoiceTypeMasterClientListFail(error))
  }
}
function* fetchApplicableRateClientList() {
  try {
    const response = yield call(getApplicableRateClientList)
    yield put(getApplicableRateClientListSuccess(response))
  } catch (error) {
    yield put(getApplicableRateClientListFail(error))
  }
}

function* clientsSaga() {
  yield takeEvery(GET_CLIENTS, fetchClients)
  yield takeEvery(GET_CLIENTS_LIST, fetchClientsList)
  yield takeEvery(ADD_NEW_CLIENT, onAddNewClient)
  yield takeEvery(UPDATE_CLIENT, onUpdateClient)
  yield takeEvery(DELETE_CLIENT, onDeleteClient)
  yield takeEvery(GET_CLIENT_DOCUMENTS, fetchClientDocuments)
  yield takeEvery(ADD_NEW_CLIENT_DOCUMENT, onAddClientDocument)
  yield takeEvery(DELETE_CLIENT_DOCUMENT, onDeleteClientDocument)
  yield takeEvery(GET_ASSIGNED_PROJECT, fetchAssignedProject)
  yield takeEvery(GET_ASSIGNED_PROJECT_LIST, fetchAssignedProjectList)
  yield takeEvery(ADD_ASSIGNED_PROJECT, onAddAssignedProject)
  yield takeEvery(UPDATE_ASSIGNED_PROJECT, onUpdateAssignedProject)
  yield takeEvery(DELETE_ASSIGNED_PROJECT, onDeleteAssignedProject)
  yield takeEvery(REINVITE_CLIENT, reinviteClientApi)
  yield takeEvery(GET_ALL_ASSIGNED_PROJECT_UNITS, fetchAllProjectUnits)
  yield takeEvery(GET_PROJECT_LIST_FOR_CLIENT, fetchProjectListForClients)
  yield takeEvery(
    GET_PROJECT_UNIT_LIST_FOR_CLIENT,
    fetchProjectUnitListForClients
  )
  yield takeEvery(GET_CLIENT_CODE, fetchClientCode)
  yield takeEvery(GET_SACHSN_CODE_CLIENT, fetchSacHsnCodeClient)
  yield takeEvery(
    GET_INVOICE_TYPE_MASTER_CLIENT_LIST,
    fetchInvoiceTypeMasterList
  )
  yield takeEvery(
    GET_APPLICABLE_RATE_CLIENT_LIST,
    fetchApplicableRateClientList
  )
}

export default clientsSaga
