import { call, put, takeEvery } from "redux-saga/effects"

//  Redux States
import {
  GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD,
  GET_FACING_MAINTENANCE_ISSUE_LIST,
  GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST,
  GET_RECENT_SERVICE_REQUEST_LIST,
  GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH,
  GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM,
  GET_SUPPORT_TICKET_LIST,
  GET_TYPES_OF_REQUESTS_GRAPH,
} from "./actionTypes"
import {
  getCountForServiceRequestDashboardFail,
  getCountForServiceRequestDashboardSuccess,
  getFacingMaintenanceIssueListFail,
  getFacingMaintenanceIssueListSuccess,
  getLookingForadditionalProjectUnitListFail,
  getLookingForadditionalProjectUnitListSuccess,
  getRecentServiceRequestListFail,
  getRecentServiceRequestListSuccess,
  getServiceRequestStatusPieDigramFail,
  getServiceRequestStatusPieDigramSuccess,
  getServiceRequestsAssignmentGraphFail,
  getServiceRequestsAssignmentGraphSuccess,
  getSupportTicketListFail,
  getSupportTicketListSuccess,
  getTypesOfRequestsGraphFail,
  getTypesOfRequestsGraphSuccess,
} from "./actions"

import {
  getCountForServiceRequestDashboard,
  getFacingMaintenanceIssueList,
  getLookingForadditionalProjectUnitList,
  getRecentServiceRequestList,
  getServiceRequestStatusPieDigram,
  getServiceRequestsAssignmentGraph,
  getSupportTicketList,
  getTypesOfRequestsGraph,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

function* fetchCountForServiceRequestDashboard() {
  try {
    const response = yield call(getCountForServiceRequestDashboard)
    yield put(getCountForServiceRequestDashboardSuccess(response))
  } catch (error) {
    yield put(getCountForServiceRequestDashboardFail(error))
  }
}
function* fetchLookingForAdditionalProjectUnitList() {
  try {
    const response = yield call(getLookingForadditionalProjectUnitList)
    yield put(getLookingForadditionalProjectUnitListSuccess(response))
  } catch (error) {
    yield put(getLookingForadditionalProjectUnitListFail(error))
  }
}
function* fetchFacingMaintenanceIssueList() {
  try {
    const response = yield call(getFacingMaintenanceIssueList)
    yield put(getFacingMaintenanceIssueListSuccess(response))
  } catch (error) {
    yield put(getFacingMaintenanceIssueListFail(error))
  }
}
function* fetchSupportTicketList() {
  try {
    const response = yield call(getSupportTicketList)
    yield put(getSupportTicketListSuccess(response))
  } catch (error) {
    yield put(getSupportTicketListFail(error))
  }
}
function* fetchRecentServiceRequestList() {
  try {
    const response = yield call(getRecentServiceRequestList)
    yield put(getRecentServiceRequestListSuccess(response))
  } catch (error) {
    yield put(getRecentServiceRequestListFail(error))
  }
}

function* fetchTypesOfRequestsGraph({ payload: typesOfRequestsGraph }) {
  try {
    const response = yield call(getTypesOfRequestsGraph, typesOfRequestsGraph)
    yield put(getTypesOfRequestsGraphSuccess(response))
  } catch (error) {
    yield put(getTypesOfRequestsGraphFail(error))
  }
}
function* fetchServiceRequestsAssignmentGraph({
  payload: requestsAssignmentGraph,
}) {
  try {
    const response = yield call(
      getServiceRequestsAssignmentGraph,
      requestsAssignmentGraph
    )
    yield put(getServiceRequestsAssignmentGraphSuccess(response))
  } catch (error) {
    yield put(getServiceRequestsAssignmentGraphFail(error))
  }
}
function* fetchServiceRequestStatusPieDigram({ payload: requestsPieDigram }) {
  try {
    const response = yield call(
      getServiceRequestStatusPieDigram,
      requestsPieDigram
    )
    yield put(getServiceRequestStatusPieDigramSuccess(response))
  } catch (error) {
    yield put(getServiceRequestStatusPieDigramFail(error))
  }
}

function* serviceRequestDashboardSaga() {
  yield takeEvery(
    GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD,
    fetchCountForServiceRequestDashboard
  )
  yield takeEvery(
    GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST,
    fetchLookingForAdditionalProjectUnitList
  )
  yield takeEvery(
    GET_FACING_MAINTENANCE_ISSUE_LIST,
    fetchFacingMaintenanceIssueList
  )
  yield takeEvery(GET_SUPPORT_TICKET_LIST, fetchSupportTicketList)
  yield takeEvery(
    GET_RECENT_SERVICE_REQUEST_LIST,
    fetchRecentServiceRequestList
  )
  yield takeEvery(GET_TYPES_OF_REQUESTS_GRAPH, fetchTypesOfRequestsGraph)
  yield takeEvery(
    GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH,
    fetchServiceRequestsAssignmentGraph
  )
  yield takeEvery(
    GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM,
    fetchServiceRequestStatusPieDigram
  )
}

export default serviceRequestDashboardSaga
