import { downloadBillingDataExcel, vendorOutstandingListExcel } from "helpers/fakebackend_helper";
import { API_URL } from "helpers/url_helper";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Table } from "reactstrap";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { billAmountList, vendorOutstandingList } from "store/actions";

const VendorOutstandingModal = ({ isOpen, toggle, filterResponse }) => {
  const dispatch = useDispatch();
  const billAmountListState = state =>
    state.Dashboard?.vendorOutstandingList?.data;
  const billAmount = useSelector(billAmountListState);

  useEffect(() => {
    if(isOpen===true){

    dispatch(vendorOutstandingList({ filter: filterResponse }));
    }
  }, [filterResponse, dispatch,isOpen]);

  const handleExport = async() => {
    // Construct the URL for download
    const value = {
      filter:filterResponse,
      type: 'NewBill'
    } 
    const downloadUrl = await vendorOutstandingListExcel(value)
   
  }
  const handleDownloadInvoice = billingId => {
    // Construct the URL for download
    const downloadUrl = `${API_URL}/api/download-invoice-pdf/${billingId}`

    // Create an anchor element
    const anchorElement = document.createElement("a")
    anchorElement.href = downloadUrl
    anchorElement.target = "_blank" // Open in a new tab
    anchorElement.download = "invoice.pdf" // Optional: specify a filename for the downloaded file

    // Append the anchor element to the document body (required in some browsers)
    document.body.appendChild(anchorElement)

    // Trigger a click event on the anchor element
    anchorElement.click()

    // Clean up: remove the anchor element from the document body
    document.body.removeChild(anchorElement)
  }
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
         Vendor Oustanding List
        </h5>
        <button
          onClick={handleExport}
          type="button"
          className="btn btn-primary btn-sm me-4"
        >
          Export
        </button>
        <button
          onClick={toggle}
          type="button"
          className="close mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <SimpleBar style={{ maxHeight: "60vh", overflowX: "auto" }}>
          <div style={{ minWidth: "100%" }}>
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Vendor name</th>
                  <th>Vendor Code</th>
                  <th>Invoice Date</th>
                  <th>Invoice no</th>
                  <th>Invoice Amount</th>
                  <th>Oustanding Amount</th>
                  
                </tr>
              </thead>
              <tbody>
                {(billAmount || []).map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td> {row.vendor_name}</td>
                    <td>{row.vendor_code}</td>
                    <td>{row.invoice_date}</td>
                    <td>{row.invoice_no}</td>
                    <td>{row.invoice_amount}</td>
                    <td>{row.outstanding_amount}</td>
                    
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </SimpleBar>
      </div>
    </Modal>
  );
};

export default VendorOutstandingModal;
