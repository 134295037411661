import {
  GET_ALL_COMPANIES_FAIL,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_PROJECTS_FAIL,
  GET_ALL_PROJECTS_SUCCESS,
  GET_GST_R1_REPORT_FAIL,
  GET_GST_R1_REPORT_SUCCESS,
  GET_PROJECT_OCCUPANCY_DETAIL_FAIL,
  GET_PROJECT_OCCUPANCY_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  allProjects: [],
  allCompanies: [],
  projectOccupancyDetail: {},
  gstReport: {},
  error: {},
  loading: true,
}

const Reports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        allProjects: action.payload,
        loading: false,
        // loading: true
      }

    case GET_ALL_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_OCCUPANCY_DETAIL_SUCCESS:
      return {
        ...state,
        projectOccupancyDetail: action.payload,
      }

    case GET_PROJECT_OCCUPANCY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        allCompanies: action.payload,
        loading: false,
        // loading: true
      }

    case GET_ALL_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GST_R1_REPORT_SUCCESS:
      return {
        ...state,
        gstReport: action.payload,
      }

    case GET_GST_R1_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Reports
