/* INVOICES */
export const GET_INVOICES = "GET_INVOICES"
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS"
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL"

/* INVOICES DETAIL*/
export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL"
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS"
export const GET_INVOICE_DETAIL_FAIL = "GET_INVOICE_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_INVOICE = "ADD_NEW_INVOICE"
 export const ADD_INVOICE_SUCCESS = "ADD_INVOICE_SUCCESS"
 export const ADD_INVOICE_FAIL = "ADD_INVOICE_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_INVOICE = "UPDATE_INVOICE"
 export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS"
 export const UPDATE_INVOICE_FAIL = "UPDATE_INVOICE_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_INVOICE = "DELETE_INVOICE"
 export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS"
 export const DELETE_INVOICE_FAIL = "DELETE_INVOICE_FAIL"

 // GET GST APPLICABLE RATE

 export const GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT = "GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT"
export const GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_SUCCESS = "GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_SUCCESS"
export const GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_FAIL = "GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_FAIL"

// SAC HSN CODE

export const GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT ="GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT"
export const GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_SUCCESS ="GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_SUCCESS"
export const GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_FAIL ="GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_FAIL"
