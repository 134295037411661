import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  Label,
  Input,
  navigate,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import VLogo from "../../../assets/images/VIP/VLogo.png"
import { getLandlordDetail } from "store/masters/landlord/actions"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import Swal from "sweetalert2"
import { updateLandlord, addNewLandlord } from "helpers/fakebackend_helper"
import { getCities, getCountries, getStates } from "store/actions"

const NewLandlordService = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const dispatch = useDispatch()
  const landlordState = state => state.landlords.landlordDetail.data
  // const loadingState = state => state.facilities.loading
  const data = useSelector(landlordState)
  // const loadingData = useSelector(loadingState)


  const landlordsProperties = createSelector(
    landlordState,
    // loadingState,
    (landlords, loading) => {
      return {
        landlords: landlords,
        loading: loading,
      }
    }
  )

  const { landlords, loading } = useSelector(landlordsProperties)

  const [specificUser, setSpecificUser] = useState(null)

  const [landlordId, setLandlordId] = useState()

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const landlordData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const landlordId = url.pathname.split("/edit-landlord-master-service/")[1]
      setLandlordId(landlordId)
      // const response =  dispatch(getCompanyDetail(facilityId))
      // if(isEditMode === true){
      dispatch(getLandlordDetail(landlordId))
      // } else {
      //   //don't dispatch or reset form
      //   setIsEditMode(false)
      // }

      // setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      landlordData()
    }
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          entity: (landlords && landlords.entity) || "",
          landlord_name: (landlords && landlords.landlord_name) || "",
          email_id: (landlords && landlords.email_id) || "",
          mobile_no: (landlords && landlords.mobile_no) || "",
          address_line_1: (landlords && landlords.address_line_1) || "",
          address_line_2: (landlords && landlords.address_line_2) || "",
          pincode: (landlords && landlords.pincode) || "",
          country: (landlords && landlords.country) || "",
          state: (landlords && landlords.state) || "",
          city: (landlords && landlords.city) || "",
          pan_no: (landlords && landlords.pan_no) || "",
          adhar_card_no: (landlords && landlords.adhar_card_no) || "",
          gst_no: (landlords && landlords.gst_no) || "",
          bank_name: (landlords && landlords.bank_name) || "",
          bank_account_no: (landlords && landlords.bank_account_no) || "",
          bank_ifsc_code: (landlords && landlords.bank_ifsc_code) || "",
          account_holder_name:
            (landlords && landlords.account_holder_name) || "",
          is_active: (landlords && landlords.is_active) || false,
          // is_active: landlords && landlords.is_active || "",
        }
      : {
          entity: "",
          landlord_name: "",
          email_id: "",
          mobile_no: "",
          address_line_1: "",
          address_line_2: "",
          pincode: "",
          country: "",
          state: "",
          city: "",
          pan_no: "",
          adhar_card_no: "",
          gst_no: "",
          bank_name: "",
          bank_account_no: "",
          bank_ifsc_code: "",
          account_holder_name: "",
          is_active: false,
        },
    validationSchema: Yup.object({
      entity: Yup.string().required("Please Select Entity"),
      landlord_name: Yup.string().required("Please Enter Landlord Full Name"),
      email_id: Yup.string().email().required("Please Enter Valid Email"),
      mobile_no: Yup.number().required("Please Enter Mobile no."),
      address_line_1: Yup.string().required("Please Enter Address Line 1"),
      address_line_2: Yup.string(),
      pincode: Yup.number().required("Please Enter Pincode"),
      country: Yup.string().required("Please Select Country"),
      state: Yup.string().required("Please Select State"),
      city: Yup.string().required("Please Select City"),
      pan_no: Yup.string(),
      adhar_card_no: Yup.string(),
      gst_no: Yup.string(),
      bank_name: Yup.string().required("Please Enter Bank Name"),
      bank_account_no: Yup.string().required("Please Enter Bank Account no"),
      bank_ifsc_code: Yup.string().required("Please Enter Bank IFSC Code"),
      account_holder_name: Yup.string().required(
        "Please Enter Account Holder's Name"
      ),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        // Assuming addNewVendor returns a Promise
        // const response =  dispatch(addNewVendor(values));
        // let response
        if (isEditMode === true) {
          // const updatedResponse = await dispatch(onUpdateVendor(vendorId,values))
          const response = await updateLandlord(landlordId, values)
          // response2 = onUpdateVendor(vendorId,values)

          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Landlord has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/landlord-service")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has been saved.",
            })
          }
        } else if (isEditMode === false) {
          // const response = await dispatch(addNewVendor(values));
          const response = await addNewLandlord(values)
          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Landlord has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/landlord-service")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Landlord has not been saved.",
            })
          }
          // response2 = addNewVendor(values)

          // alert('hello')

        }
      } catch (error) {
        // Handle the error if needed
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },

  })

  const navigate = useNavigate()
  const toggleToast = () => {
    setToast(!toast)
  }
  const toggleToast2 = () => {
    setToast2(!toast2)
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  // useEffect(() => {
  //   // Check if the current path includes 'edit-landlord-service'
  //   if (location.pathname.includes('edit')) {
  //     setIsEditMode(true);
  //   } else {
  //     setIsEditMode(false);
  //   }
  // }, [location.pathname]);

  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState(validation.values.state)

  useEffect(() => {
    const fetchData = async () => {
      // Fetch countries
      await dispatch(getCountries())

      // If country is selected (edit mode), fetch states and cities
      if (validation.values.country) {
        await dispatch(getStates(validation.values.country))
        await dispatch(getCities(validation.values.state))
      }

      // Set initial values
      setCountryName(validation.values.country)
      setStateId(validation.values.state)
    }

    fetchData()
  }, [dispatch, validation.values.country, validation.values.state])

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)
    setToast2(true)
  }

  const [selectedOption, setSelectedOption] = useState(landlords?.is_active)

  const handleChange1 = () => {
    const newValue = !selectedOption
    setSelectedOption(newValue)
    validation.setFieldValue("is_active", newValue)
  }

  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {" "}
              {isEditMode ? "Edit Landlord" : "New Landlord"}
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Settings / Master configuration / Landlord Master" />
        </Col>
      </Row>
      <div className="mt-3"></div>

      {/* <Breadcrumbs title="Settings / Master configuration / Landlord Master" breadcrumbItem="New Landlord" /> */}
      {/* <CardTitle className="mb-4">New Landlord</CardTitle> */}

      <Card>
        <CardBody>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-entity-Input">Entity*</Label>
                  <select
                    name="entity"
                    defaultValue="0"
                    // className="form-select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.entity || ""}
                    className={`form-select ${
                      validation.touched.entity && validation.errors.entity
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.entity && (
                      <option>Select Entity</option>
                    )}
                    {/* <option value="0">Select Entity</option> */}
                    <option value="Pvt Ltd/LTD/LLP">Pvt Ltd / LTD / LLP </option>
                    <option value="Government">Government</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Proprietorship">Proprietorship</option>
                    <option value="Individual">Individual</option>
                  </select>
                  {validation.touched.entity && validation.errors.entity ? (
                    <FormFeedback type="invalid">
                      {validation.errors.entity}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-landlordName-Input">
                    Landlord Name*
                  </Label>
                  <Input
                    type="text"
                    name="landlord_name"
                    className="form-control"
                    id="formrow-landlordName-Input"
                    placeholder="Enter Landlord Full Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.landlord_name || ""}
                    invalid={
                      validation.touched.landlord_name &&
                      validation.errors.landlord_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.landlord_name &&
                  validation.errors.landlord_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.landlord_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-emailid-Input">Email Id*</Label>
                  <Input
                    type="email"
                    name="email_id"
                    className="form-control"
                    id="formrow-emailid-Input"
                    placeholder="landlord@mail.com"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email_id || ""}
                    invalid={
                      validation.touched.email_id && validation.errors.email_id
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email_id && validation.errors.email_id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email_id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-mobileno-Input">Mobile no.*</Label>
                  <Input
                    type="text"
                    name="mobile_no"
                    className="form-control"
                    id="formrow-mobileno-Input"
                    placeholder="+91..."
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.mobile_no || ""}
                    invalid={
                      validation.touched.mobile_no &&
                      validation.errors.mobile_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.mobile_no &&
                  validation.errors.mobile_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.mobile_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-landlordName1-Input">
                    Address line 1*
                  </Label>
                  <Input
                    type="text"
                    name="address_line_1"
                    className="form-control"
                    id="formrow-landlordName1-Input"
                    placeholder="Plot no / Flat no, Building no,. or Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.address_line_1 || ""}
                    invalid={
                      validation.touched.address_line_1 &&
                      validation.errors.address_line_1
                        ? true
                        : false
                    }
                  />
                  {validation.touched.address_line_1 &&
                  validation.errors.address_line_1 ? (
                    <FormFeedback type="invalid">
                      {validation.errors.address_line_1}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-landlordName2-Input">
                    Address line 2
                  </Label>
                  <Input
                    type="text"
                    name="address_line_2"
                    className="form-control"
                    id="formrow-landlordName2-Input"
                    placeholder="Street, Locality, Area"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.address_line_2 || ""}
                    invalid={
                      validation.touched.address_line_2 &&
                      validation.errors.address_line_2
                        ? true
                        : false
                    }
                  />
                  {validation.touched.address_line_2 &&
                  validation.errors.address_line_2 ? (
                    <FormFeedback type="invalid">
                      {validation.errors.address_line_2}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-pincode-Input">Pincode*</Label>
                  <Input
                    type="text"
                    name="pincode"
                    className="form-control"
                    id="formrow-pincode-Input"
                    placeholder="Enter Address Pincode"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.pincode || ""}
                    invalid={
                      validation.touched.pincode && validation.errors.pincode
                        ? true
                        : false
                    }
                  />
                  {validation.touched.pincode && validation.errors.pincode ? (
                    <FormFeedback type="invalid">
                      {validation.errors.pincode}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="country">Country*</Label>
                  <select
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    // defaultValue="IN"
                    value={validation.values.country}
                    name="country"
                    className={`form-select ${
                      validation.touched.country && validation.errors.country
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.country && (
                      <option>Enter Your Country</option>
                    )}

                    {(countries || []).map(item => (
                      <option key={item.id} value={item.id}>
                        {item.country_name}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-state-Input">State*</Label>
                  <select
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="state"
                    value={validation.values.state}
                    className={`form-select ${
                      validation.touched.state && validation.errors.state
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.state && (
                      <option>Enter Your States</option>
                    )}

                    {(states || []).map(item => (
                      <option key={item.id} value={item.id}>
                        {item.state_name}
                      </option>
                    ))}
                  </select>
                  {validation.touched.state && validation.errors.state ? (
                    <FormFeedback type="invalid">
                      {validation.errors.state}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-city-Input">City*</Label>
                  <select
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="city"
                    value={validation.values.city}
                    className={`form-select ${
                      validation.touched.city && validation.errors.city
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.city && (
                      <option>Enter Your City</option>
                    )}

                    {(cities || []).map(item => (
                      <option key={item.id} value={item.id}>
                        {item.city_name}
                      </option>
                    ))}
                  </select>
                  {validation.touched.city && validation.errors.city ? (
                    <FormFeedback type="invalid">
                      {validation.errors.city}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-pincode-Input">PAN No</Label>
                  <Input
                    type="text"
                    name="pan_no"
                    className="form-control"
                    id="formrow-pincode-Input"
                    placeholder="Enter Pan Card No"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.pan_no || ""}
                    invalid={
                      validation.touched.pan_no && validation.errors.pan_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.pan_no && validation.errors.pan_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.pan_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-pincode-Input">
                    Aadhar Card Number.
                  </Label>
                  <Input
                    type="text"
                    name="adhar_card_no"
                    className="form-control"
                    id="formrow-pincode-Input"
                    placeholder="Enter Adhar card no."
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.adhar_card_no || ""}
                    invalid={
                      validation.touched.adhar_card_no &&
                      validation.errors.adhar_card_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.adhar_card_no &&
                  validation.errors.adhar_card_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.adhar_card_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-gstno-Input">GST No.</Label>
                  <Input
                    type="text"
                    name="gst_no"
                    className="form-control"
                    id="formrow-gstno-Input"
                    placeholder="Enter GST no."
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.gst_no || ""}
                    invalid={
                      validation.touched.gst_no && validation.errors.gst_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.gst_no && validation.errors.gst_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.gst_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-bankname-Input">Bank Name*</Label>
                  <Input
                    type="text"
                    name="bank_name"
                    className="form-control"
                    id="formrow-bankname-Input"
                    placeholder="Enter Bank's Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.bank_name || ""}
                    invalid={
                      validation.touched.bank_name &&
                      validation.errors.bank_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.bank_name &&
                  validation.errors.bank_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.bank_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-account-Input">
                    Bank Account No.*
                  </Label>
                  <Input
                    type="text"
                    name="bank_account_no"
                    className="form-control"
                    id="formrow-account-Input"
                    placeholder="Enter Account No"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.bank_account_no || ""}
                    invalid={
                      validation.touched.bank_account_no &&
                      validation.errors.bank_account_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.bank_account_no &&
                  validation.errors.bank_account_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.bank_account_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-ifsc-Input">Bank IFSC Code*</Label>
                  <Input
                    type="text"
                    name="bank_ifsc_code"
                    className="form-control"
                    id="formrow-ifsc-Input"
                    placeholder="Enter Bank's IFSC Code"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.bank_ifsc_code || ""}
                    invalid={
                      validation.touched.bank_ifsc_code &&
                      validation.errors.bank_ifsc_code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.bank_ifsc_code &&
                  validation.errors.bank_ifsc_code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.bank_ifsc_code}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-accountholder-Input">
                    Account Holder Name*
                  </Label>
                  <Input
                    type="text"
                    name="account_holder_name"
                    className="form-control"
                    id="formrow-accountholder-Input"
                    placeholder="Enter Account Holder's Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.account_holder_name || ""}
                    invalid={
                      validation.touched.account_holder_name &&
                      validation.errors.account_holder_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.account_holder_name &&
                  validation.errors.account_holder_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.account_holder_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-2"></div>
                <div>
                  <Label htmlFor="formrow-ispaid-Input">Is Active</Label>
                </div>

                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="is_active"
                      name="is_active"
                      onChange={e => {
                        validation.setFieldValue("is_active", e.target.checked)
                      }}
                      checked={Boolean(validation?.values?.is_active)}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      <h6>Yes</h6>
                    </Label>
                  </div>
                  {validation.touched.is_active &&
                    validation.errors.is_active && (
                      <FormFeedback type="invalid">
                        {validation.errors.is_active}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-md">
                    {isEditMode ? "Edit" : "Submit"}
                  </button>
                </div>
                {toast2 && (
                  <div
                    className="position-fixed top-0 end-0 p-3"
                    style={{ zIndex: "1005" }}
                  >
                    <Toast isOpen={toast2} role="aleart">
                      <ToastHeader toggle={() => setToast2(!toast2)}>
                        <img src={VLogo} alt="" className="me-2" height="18" />
                        <strong className="me-auto">VIP Mall</strong>
                      </ToastHeader>
                      <ToastBody>
                        {!isEditMode
                          ? "Submitted Successfully"
                          : "Edited Successfully"}
                      </ToastBody>
                    </Toast>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default NewLandlordService
