import {
// TIME REGION

  ADD_TIMEREGION_SUCCESS,
  ADD_TIMEREGION_FAIL,
  GET_TIMEREGION_SUCCESS,
  GET_TIMEREGION_FAIL,

// FILE CONFIGURATION

  ADD_FILECONFIGURATION_SUCCESS,
  ADD_FILECONFIGURATION_FAIL,
  GET_FILECONFIGURATION_SUCCESS,
  GET_FILECONFIGURATION_FAIL,

// RECAPTCHA CONFIGURATION

  ADD_RECAPTCHACONFIGURATION_SUCCESS,
  ADD_RECAPTCHACONFIGURATION_FAIL,
  GET_RECAPTCHACONFIGURATION_SUCCESS,
  GET_RECAPTCHACONFIGURATION_FAIL,

// EMAIL CONFIGURATION

  ADD_EMAILCONFIGURATION_SUCCESS,
  ADD_EMAILCONFIGURATION_FAIL,
  GET_EMAILCONFIGURATION_SUCCESS,
  GET_EMAILCONFIGURATION_FAIL,

// USER PASSWORD SETTINGS

  ADD_USERPASSWORDSETTINGS_SUCCESS,
  ADD_USERPASSWORDSETTINGS_FAIL,
  GET_USERPASSWORDSETTINGS_SUCCESS,
  GET_USERPASSWORDSETTINGS_FAIL,

  //DATE TIME 
  GET_DATETIMEFORMAT_SUCCESS,
  GET_DATETIMEFORMAT_FAIL,

  //TIME ZONE
  GET_TIMEZONE_SUCCESS,
  GET_TIMEZONE_FAIL,

  //CURRENCY
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY_FAIL,

} from "./actionTypes";

const INIT_STATE = {
  regions: [],
  dateAndTime:[],
  timeZone:[],
  currency:[],
  regionDetail: {},
  error: {},
  loading: true
};

const regions = (state = INIT_STATE, action) => {
  switch (action.type) {

    //TIME REGION
    case ADD_TIMEREGION_SUCCESS:
      return {
        ...state,
        timeregions: [...state.timeregions, action.payload],
      };

    case ADD_TIMEREGION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

      case GET_TIMEREGION_SUCCESS:
      return {
        ...state,
        timeregions: action.payload,
        loading: false
      };

    case GET_TIMEREGION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    //FILE CONFIGURATION

    case ADD_FILECONFIGURATION_SUCCESS:
      return {
        ...state,
        fileconfigurations: [...state.fileconfigurations, action.payload],
      };

    case ADD_FILECONFIGURATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

      case GET_FILECONFIGURATION_SUCCESS:
      return {
        ...state,
        fileconfigurations: action.payload,
        loading: true
      };

    case GET_FILECONFIGURATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    //RECAPTCHA CONFIGURATION

    case ADD_RECAPTCHACONFIGURATION_SUCCESS:
      return {
        ...state,
        recaptchaconfigurations: [...state.recaptchaconfigurations, action.payload],
      };

    case ADD_RECAPTCHACONFIGURATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

      case GET_RECAPTCHACONFIGURATION_SUCCESS:
      return {
        ...state,
        recaptchaconfigurations: action.payload,
        loading: true
      };

    case GET_RECAPTCHACONFIGURATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    //EMAIL CONFIGURATION

    case ADD_EMAILCONFIGURATION_SUCCESS:
      return {
        ...state,
        emailconfigurations: [...state.emailconfigurations, action.payload],
      };

    case ADD_EMAILCONFIGURATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      
      case GET_EMAILCONFIGURATION_SUCCESS:
      return {
        ...state,
        emailconfigurations: action.payload,
        loading: true
      };

    case GET_EMAILCONFIGURATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    //USER PASSWORD SETTINGS

    case ADD_USERPASSWORDSETTINGS_SUCCESS:
      return {
        ...state,
        userpasswordsettings: [...state.userpasswordsettings, action.payload],
      };

    case ADD_USERPASSWORDSETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      case GET_USERPASSWORDSETTINGS_SUCCESS:
        return {
          ...state,
          userpasswordsettings: action.payload,
          loading: true
        };
  
      case GET_USERPASSWORDSETTINGS_FAIL:
        return {
          ...state,
          error: action.payload,
        };

        // DATE TIME FORMAT 

        case GET_DATETIMEFORMAT_SUCCESS:
      return {
        ...state,
        dateAndTime: action.payload,
        loading: true
      };

    case GET_DATETIMEFORMAT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

        //TIMEZONE SELECTION

        case GET_TIMEZONE_SUCCESS:
      return {
        ...state,
        timeZone: action.payload,
        loading: true
      };

    case GET_TIMEZONE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

        //CURRENCY SELECTION

        case GET_CURRENCY_SUCCESS:
      return {
        ...state,
        currency: action.payload,
        loading: true
      };

    case GET_CURRENCY_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default regions;
