import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "../ExpenseMaster/expenseContainer"
import { put, call } from "redux-saga/effects"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

// import {
//   getUsers as onGetUsers,
//   addNewUser as onAddNewUser,
//   updateUser as onUpdateUser,
//   deleteUser as onDeleteUser,
// } from "store/contacts/actions";
import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { getExpenses, deleteExpense } from "store/masters/expense/actions"
// import { deleteExpense } from "helpers/fakebackend_helper"

const ExpenseService = props => {
  const navigate = useNavigate()

 
  //meta title
  document.title = "Expense Master "

  const dispatch = useDispatch()
  const [expense, setExpense] = useState()
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (expense && expense.name) || "",
      employeeCode: (expense && expense.employeeCode) || "",
      role: (expense && expense.role) || "",
      email: (expense && expense.email) || "",
      phoneNumber: (expense && expense.phoneNumber) || "",
      username: (expense && expense.username) || "",
      propertyType: (expense && expense.propertyType) || "",
      propertyName: (expense && expense.propertyName) || "",
      projectType: (expense && expense.projectType) || "",
      projectArea: (expense && expense.projectArea) || "",
      userDocument: (expense && expense.userDocument) || "",
      facilityMaster: (expense && expense.facilityMaster) || "",
      facilityFees: (expense && expense.facilityFees) || "",
      isActive: (expense && expense.isActive) || "",
      documentTitle: (expense && expense.documentTitle) || "",
      applicableFor: (expense && expense.applicableFor) || "",
      isMandatory: (expense && expense.isMandatory) || "",
      expenseTitle: (expense && expense.expenseTitle) || "",
      maintenanceIssue: (expense && expense.maintenanceIssue) || "",
      ticketTopic: (expense && expense.ticketTopic) || "",
      invoiceType: (expense && expense.invoiceType) || "",
      projectCategoryTitle: (expense && expense.projectCategoryTitle) || "",
      isPromotionalUnit: (expense && expense.isPromotionalUnit) || "",
      complianceTitle: (expense && expense.complianceTitle) || "",
      dependentCompliance: (expense && expense.dependentCompliance) || "",
      entity: (expense && expense.entity) || "",
      landlordName: (expense && expense.landlordName) || "",
      img: (expense && expense.img) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      designation: Yup.string().required("Please Enter Your Designation"),
      tags: Yup.array().required("Please Enter Tag"),
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
        .required("Please Enter Your Email"),
      projects: Yup.string().required("Please Enter Your Project"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: contact.id,
          name: values.name,
          designation: values.designation,
          tags: values.tags,
          email: values.email,
          projects: values.projects,
        }
        // update user
        dispatch(onUpdateUser(updateUser))
        setIsEdit(false)
        validation.resetForm()
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          designation: values["designation"],
          email: values["email"],
          tags: values["tags"],
          projects: values["projects"],
        }
        // save new user
        dispatch(onAddNewUser(newUser))
        validation.resetForm()
      }
      toggle()
    },
  })

  const [userList, setUserList] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const loadingState = state => state.facilities.loading
  const loadingData = useSelector(loadingState)
  const expenseState = state => state.expenses.expenses.expenseTypes
  const data = useSelector(expenseState) || []
  const ExpenseProperties = createSelector(
    expenseState,
    loadingState,
    (expenses, loading) => ({
      expenses: expenses,
      loading: loading,
    })
  )

  const { expenses, loading } = useSelector(ExpenseProperties)

  useEffect(() => {
    const response = dispatch(getExpenses())
    setIsEdit(false)
  }, [dispatch])

  useEffect(() => {
    setExpense(expenses)
    setIsEdit(false)
  }, [expenses])

  useEffect(() => {
    if (!isEmpty(expenses) && !!isEdit) {
      setExpense(expenses)
      setIsEdit(false)
    }
  }, [expenses])

  const [isLoading, setLoading] = useState(loading)

  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Expense Title",
        accessor: "expense_title",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.expense_title}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Expense Type",
        accessor: "expense_type",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.expense_type}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },

      {
        Header: "Is Active",
        accessor: "isActive",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.is_active ? "Yes" : "No"}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {permission
                .filter(
                  item =>
                    item.page === "/edit-expense-master-service/:id" &&
                    item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to={`/settings/edit-expense-master-service/${cellProps.row.original.id}`}
                    className="text-success"
                    onClick={() => {
                      const userData = cellProps.row.original
                      handleUserClick(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                ))}
              {permission
                .filter(
                  item =>
                    item.page === "delete-expense-master-service" &&
                    item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      const userData = cellProps.row.original.id
                      onClickDelete(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                ))}
            </div>
          )
        },
      },
    ],
    []
  )

  // useEffect(() => {
  //   if (users && !users.length) {
  //     dispatch(onGetUsers());
  //     setIsEdit(false);
  //   }
  // }, [dispatch, users]);

  // useEffect(() => {
  //   setExpense(users);
  //   setIsEdit(false);
  // }, [users]);

  // useEffect(() => {
  //   if (!isEmpty(users) && !!isEdit) {
  //     setExpense(users);
  //     setIsEdit(false);
  //   }
  // }, [users]);

  const toggle = () => {
    setModal(!modal)
  }

  const handleUserClick = arg => {
    const user = arg

    // setContact({
    //   id: user.id,
    //   name: user.name,
    //   designation: user.designation,
    //   email: user.email,
    //   tags: user.tags,
    //   projects: user.projects,
    // });
    // setIsEdit(true);

    // toggle();
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = userData => {
    setDeleteId({ id: userData })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteExpense(deleteId.id))

    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/settings/expense-master")
    dispatch(getExpenses())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Expense Master"
            breadcrumbItem="Expense Type Master"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      // isAddUserList={permission && permission[19].access}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}

            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit User" : "Add User"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Insert Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Designation</Label>
                        <Input
                          name="designation"
                          label="Designation"
                          placeholder="Insert Designation"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.designation || ""}
                          invalid={
                            validation.touched.designation &&
                            validation.errors.designation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.designation &&
                        validation.errors.designation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.designation}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          placeholder="Insert Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Option</Label>
                        <Input
                          type="select"
                          name="tags"
                          className="form-select"
                          multiple={true}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.tags || []}
                          invalid={
                            validation.touched.tags && validation.errors.tags
                              ? true
                              : false
                          }
                        >
                          <option>Photoshop</option>
                          <option>illustrator</option>
                          <option>Html</option>
                          <option>Php</option>
                          <option>Java</option>
                          <option>Python</option>
                          <option>UI/UX Designer</option>
                          <option>Ruby</option>
                          <option>Css</option>
                        </Input>
                        {validation.touched.tags && validation.errors.tags ? (
                          <FormFeedback type="invalid">
                            {validation.errors.tags}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Projects</Label>
                        <Input
                          name="projects"
                          label="Projects"
                          type="text"
                          placeholder="Insert Projects"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.projects || ""}
                          invalid={
                            validation.touched.projects &&
                            validation.errors.projects
                              ? true
                              : false
                          }
                        />
                        {validation.touched.projects &&
                        validation.errors.projects ? (
                          <FormFeedback type="invalid">
                            {validation.errors.projects}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default ExpenseService
