import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import {
  Input,
  Label,
  Modal,
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  FormFeedback,
  Button,
} from "reactstrap"

// Form Editor
import { Editor, EditorState } from "react-draft-wysiwyg"
import { convertToRaw } from "draft-js"
import { ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import { TextEditor } from "./TextEditor"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { sendEmailToCustomer } from "store/actions"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { sendReminderMailToCustomer } from "helpers/fakebackend_helper"

const LargeModal = ({ isOpen, toggle, data }) => {
  const [selectedFiles, setselectedFiles] = useState([])

  console.log(data)
  const dispatch = useDispatch()
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      to: "",
      cc: "",
      subject: "",
      message: "<p></p>",
      // send_pdf_attachment: false,
      files: [],
      customerId: '',
    },
    validationSchema: Yup.object().shape({}),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        const response = await sendReminderMailToCustomer(
          values.customerId,
          values
        )
        if (response && response.status === true) {
          loadingSwal.close()
          validation.setFieldValue('cc','')
          validation.setFieldValue('subject','')
          validation.setFieldValue('message','<p></p>')
          validation.setFieldValue('files',[])
          setselectedFiles([])
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Mail has been sent.",
          }).then(result => {
            if (result.isConfirmed) {
              toggle()
            }
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "failure",
            icon: "error",
            text: "Your Mail has not been sent.",
          })
        }
      } catch (error) {
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })


  useEffect(()=>{
    if(selectedFiles){
      validation.setFieldValue('files',selectedFiles)
    }
  },[selectedFiles])
  useEffect(()=>{
    if(data){
      validation.setFieldValue('customerId',data.client_id)
      validation.setFieldValue('to',data.email_address)
    }
  },[data])
  console.log(validation.values)

  function handleAcceptedFiles(files) {
    const updatedFile = files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(prevFiles => [...prevFiles, ...updatedFile])
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleDeletedFiles(fileToDelete) {
    setselectedFiles(prevFiles =>
      prevFiles.filter(file => file !== fileToDelete)
    )
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Draft Mail
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <div className="mb-3">
            <Col>
              <Label>To*</Label>
              <Input
                type="text"
                name="to"
                className="form-control mb-2"
                id="to"
                placeholder="Enter To"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.to || ""}
                invalid={
                  validation.touched.to && validation.errors.to ? true : false
                }
              />
              {validation.touched.to && validation.errors.to ? (
                <FormFeedback type="invalid">
                  {validation.errors.to}
                </FormFeedback>
              ) : null}
              For multiple receipients use comma (,). Eg.
              abcd@gmail.com,xyz@gmail.com
            </Col>
          </div>
        </Row>
        <Row>
          <div className="mb-3">
            <Col>
              <Label>CC</Label>
              <Input
                type="text"
                name="cc"
                className="form-control mb-2"
                id="cc"
                placeholder="Enter CC"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.cc || ""}
                invalid={
                  validation.touched.cc && validation.errors.cc ? true : false
                }
              />
              {validation.touched.cc && validation.errors.cc ? (
                <FormFeedback type="invalid">
                  {validation.errors.cc}
                </FormFeedback>
              ) : null}
              For multiple receipients use comma (,). Eg.
              abcd@gmail.com,xyz@gmail.com
            </Col>
          </div>
        </Row>
        <Row>
          <div className="mb-3">
            <Col>
              <Label>Subject*</Label>
              <Input
                type="text"
                name="subject"
                className="form-control mb-2"
                id="subject"
                placeholder="Enter Subject"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.subject || ""}
                invalid={
                  validation.touched.subject && validation.errors.subject
                    ? true
                    : false
                }
              ></Input>
            </Col>
          </div>
        </Row>

        <Row>
          <Col>
            <Label>Message*</Label>
            <Form method="post">
              <TextEditor
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                setFieldValue={val => validation.setFieldValue("message", val)}
                value={validation.values.message}
              />
            </Form>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="12">
            <Dropzone
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                        <Col className="text-end">
                          <Button
                            className="btn btn-danger"
                            onClick={() => {
                              handleDeletedFiles(f)
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <div className="mb-2">
            <Col className="text-center">
              <button
                onClick={validation.handleSubmit}
                className="btn btn-primary mt-3 "
              >
                {" "}
                Submit{" "}
              </button>
            </Col>
          </div>
        </Row>
      </div>
    </Modal>
  )
}

export default LargeModal
