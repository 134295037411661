import { call, put, takeEvery } from "redux-saga/effects"

import {
  getLedgerDetailSuccess,
  getLedgerDetailFail,
  getAllProjectsForCustomerOustandingSuccess,
  getAllProjectsForCustomerOustandingFail,
  getAllCompaniesForCustomerOustandingSuccess,
  getAllCompaniesForCustomerOustandingFail,
  getReportForCustomerOustandingSuccess,
  getReportForCustomerOustandingFail,
  getFinancialYearForOutstandingFail,
  getFinancialYearForOutstandingSuccess,
} from "./actions"
import {
  getAllCompaniesForCustomerOustanding,
  getAllProjectsForCustomerOustanding,
  getFinancialYearForOutstanding,
  getLedgerDetails,
  getReportForCustomerOustanding,
} from "helpers/fakebackend_helper"
import {
  GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING,
  GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING,
  GET_REPORT_FOR_CUSTOMER_OUTSTANDING,
  GET_LEDGER_DETAIL,
  GET_FINANCIAL_YEAR_FOR_OUTSTANDING,
} from "./actionTypes"

function* fetchAllProjects({ id }) {
  try {
    const response = yield call(getAllProjectsForCustomerOustanding, id)
    yield put(getAllProjectsForCustomerOustandingSuccess(response))
  } catch (error) {
    yield put(getAllProjectsForCustomerOustandingFail(error))
  }
}
function* fetchFinancialYear({ id }) {
  try {
    const response = yield call(getFinancialYearForOutstanding, id)
    yield put(getFinancialYearForOutstandingSuccess(response))
  } catch (error) {
    yield put(getFinancialYearForOutstandingFail(error))
  }
}

function* fetchLedgerDetail({ value }) {
  try {
    const response = yield call(getLedgerDetails, value)
    yield put(getLedgerDetailSuccess(response))
  } catch (error) {
    yield put(getLedgerDetailFail(error))
  }
}

function* fetchAllCompanies() {
  try {
    const response = yield call(getAllCompaniesForCustomerOustanding)
    yield put(getAllCompaniesForCustomerOustandingSuccess(response))
  } catch (error) {
    yield put(getAllCompaniesForCustomerOustandingFail(error))
  }
}

function* fetchReportForCustomerOustanding({ companyData }) {
  try {
    const response = yield call(getReportForCustomerOustanding, companyData)
    yield put(getReportForCustomerOustandingSuccess(response))
  } catch (error) {
    yield put(getReportForCustomerOustandingFail(error))
  }
}

function* oustandingReports() {
  yield takeEvery(GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING, fetchAllProjects)
  yield takeEvery(GET_FINANCIAL_YEAR_FOR_OUTSTANDING, fetchFinancialYear)
  yield takeEvery(GET_LEDGER_DETAIL, fetchLedgerDetail)
  yield takeEvery(GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING, fetchAllCompanies)
  yield takeEvery(
    GET_REPORT_FOR_CUSTOMER_OUTSTANDING,
    fetchReportForCustomerOustanding
  )
}

export default oustandingReports
