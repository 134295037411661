import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Label,
  Input,
  navigate,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import VLogo from "../../../assets/images/VIP/VLogo.png"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import {
  getConsumptionRegisterList,
  getGstApplicableRateForInvoiceComponent,
  getInvoiceDetail,
  getSACHSNCodeForInvoiceComponent,
} from "store/actions"
import { addNewInvoice, updateInvoice } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

const NewInvoiceService = ({ defaultChecked = false }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const dispatch = useDispatch()

  const invoiceState = state => state.invoices.invoiceDetail.Data
  const data = useSelector(invoiceState)

  const applicableRateListState = state => state.invoices.rate.data
  const applicableRateList = useSelector(applicableRateListState)

  const SACHSMState = state => state.invoices.sachsn.data
  const SACHSMList = useSelector(SACHSMState)
  const consumptionList = state =>
    state?.billing?.consumptionRegister?.consumption_list_data
  const ConsumptionList = useSelector(consumptionList)

  useEffect(() => {
    dispatch(getConsumptionRegisterList())
  }, [dispatch])

  const invoicesProperties = createSelector(
    invoiceState,
    (invoices, loading) => {
      return {
        invoices: invoices,
        loading: loading,
      }
    }
  )

  const [checked, setChecked] = useState(false)
  const { invoices, loading } = useSelector(invoicesProperties)

  const [invoiceId, setInvoiceId] = useState()

  const invoiceData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const invoiceId = url.pathname.split("/edit-invoice-service-master/")[1]
      setInvoiceId(invoiceId)
      // const response =  dispatch(getCompanyDetail(facilityId))
      // if(isEditMode === true){
      dispatch(getInvoiceDetail(invoiceId))
      // } else {
      //   //don't dispatch or reset form
      //   setIsEditMode(false)
      // }

      // setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    dispatch(getGstApplicableRateForInvoiceComponent())
    dispatch(getSACHSNCodeForInvoiceComponent())
    if (location.pathname.includes("edit")) {
      invoiceData()
    }
  }, [dispatch])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          invoice_type: (invoices && invoices.invoice_type) || "",
          is_active: (invoices && invoices.is_active) || "",
          gst_applicable: (invoices && invoices.gst_applicable) || "",
          sac_hsm_id: (invoices && invoices.sac_hsm_id) || "",
          consumption_type: (invoices && invoices.consumption_type) || "",
          gst_rate:
            (invoices && invoices.gst_applicable) === true ||
            (invoices && invoices.gst_applicable) === 1
              ? (invoices && invoices.gst_rate) || ""
              : "",
        }
      : {
          invoice_type: "",
          is_active: false,
          gst_applicable: true,
          gst_rate: "",
          sac_hsm_id: "",
          consumption_type: "None",
        },
    validationSchema: Yup.object({
      invoice_type: Yup.string().required("Please Enter Your Project Name"),
      // is_active: Yup.boolean().required("Please Select Valid Option"),
      is_active: Yup.boolean(),
      gst_applicable: Yup.boolean(),
      gst_rate: Yup.number().when("gst_applicable", {
        is: true,
        then: () => Yup.number().required("Applicable Rate is Required"),
      }),
      sac_hsm_id: Yup.number().when("gst_applicable", {
        is: true,
        then: () => Yup.number().required("SAC/HSM Code is Required"),
      }),
    }),
    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        // Assuming addNewVendor returns a Promise
        // const response =  dispatch(addNewVendor(values));
        // let response
        if (isEditMode === true) {
          const response = await updateInvoice(invoiceId, values)

          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Invoice Component has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/invoice-master")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has been saved.",
            })
          }
        } else if (isEditMode === false) {
          // const response = await dispatch(addNewVendor(values));
          const response = await addNewInvoice(values)
          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Invoice Component has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/invoice-master")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Maintainence Issue has not been saved.",
            })
          }
        }
      } catch (error) {
        // Handle the error if needed
        // loadingSwal.close()

        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const navigate = useNavigate()

  useEffect(() => {
    // Check if the current path includes 'edit-invoice'
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)
    setToast2(true)
  }

  const [selectedOption, setSelectedOption] = useState(invoices?.is_active)

  const handleChange1 = () => {
    const newValue = !selectedOption
    setSelectedOption(newValue)
    validation.setFieldValue("is_active", newValue)
  }

  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {" "}
              {isEditMode ? "Edit Invoice Component" : "New Invoice Component"}
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Settings / Master configuration / Invoice Component" />
        </Col>
      </Row>
      <div className="mt-3"></div>
      <Card>
        <CardBody>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-invoicetype-Input">
                    Invoice Component title*
                  </Label>
                  <Input
                    type="text"
                    name="invoice_type"
                    className="form-control"
                    id="formrow-invoicetype-Input"
                    placeholder="Enter Invoice Component title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.invoice_type || ""}
                    invalid={
                      validation.touched.invoice_type &&
                      validation.errors.invoice_type
                        ? true
                        : false
                    }
                  />
                  {validation.touched.invoice_type &&
                  validation.errors.invoice_type ? (
                    <FormFeedback type="invalid">
                      {validation.errors.invoice_type}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div>
                  <div className="mb-3"></div>
                  <Label htmlFor="formrow-isactive-Input">Is Active</Label>
                </div>

                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="is_active"
                      name="is_active"
                      onChange={e => {
                        validation.setFieldValue("is_active", e.target.checked)
                      }}
                      checked={Boolean(validation?.values?.is_active)}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      Yes
                    </Label>
                  </div>
                  {validation.touched.is_active &&
                    validation.errors.is_active && (
                      <FormFeedback type="invalid">
                        {validation.errors.is_active}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <Label htmlFor="formrow-isactive-Input" className="mt-2">
                    GST Aplicable
                  </Label>
                </div>
                <Col lg={6}>
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="gst_applicable"
                      name="gst_applicable"
                      onChange={e => {
                        validation.setFieldValue(
                          "gst_applicable",
                          e.target.checked
                        )
                      }}
                      checked={Boolean(validation?.values?.gst_applicable)}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      Yes
                    </Label>
                  </div>
                  {validation.touched.gst_applicable &&
                    validation.errors.gst_applicable && (
                      <FormFeedback type="invalid">
                        {validation.errors.gst_applicable}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
            </Row>
            <Row>
              {validation.values.gst_applicable === true ||
              validation.values.gst_applicable === 1 ? (
                <>
                  <Col lg="6">
                    <div className="mb-3 mt-3">
                      <Label for="sac_hsm_id">SAC HSN Code</Label>
                      <select
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        // defaultValue="IN"
                        value={validation.values.sac_hsm_id}
                        name="sac_hsm_id"
                        className={`form-select ${
                          validation.touched.sac_hsm_id &&
                          validation.errors.sac_hsm_id
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        {!validation.values.sac_hsm_id && (
                          <option>Select SAC HSN Code</option>
                        )}

                        {(SACHSMList || []).map(item => (
                          <option key={item.id} value={item.id}>
                            {item.sac_hsm_code}
                          </option>
                        ))}
                      </select>
                      {validation.touched.sac_hsm_id &&
                        validation.errors.sac_hsm_id && (
                          <FormFeedback type="invalid">
                            {validation.errors.sac_hsm_id}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3 mt-3">
                      <Label htmlFor="gst_rate">Applicable Rate</Label>
                      <select
                        className={`form-select ${
                          validation.touched.gst_rate &&
                          validation.errors.gst_rate
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gst_rate}
                        name="gst_rate"
                      >
                        <option>Select Applicable Rate</option>
                        {(applicableRateList || []).map(rate => (
                          <option key={rate.id} value={rate.id}>
                            {rate.applicable_rate}
                          </option>
                        ))}
                      </select>
                      {validation.touched.gst_rate &&
                        validation.errors.gst_rate && (
                          <FormFeedback type="invalid">
                            {validation.errors.gst_rate}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </>
              ) : null}
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-4">
                  <Label className="d-block mb-3" htmlFor="consumption_type">
                    Consumption Type
                  </Label>
                  <div className="form-check form-check mb-2">
                    <Input
                      type="radio"
                      id="None"
                      name="consumption_type"
                      className="form-check-input"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value="None"
                     
                      checked={validation.values.consumption_type === "None"}

                    />
                    <Label
                      className="form-check-label"
                      htmlFor="None"
                    >
                      None
                    </Label>
                  </div>
                  <div className="form-check form-check mb-2">
                    <Input
                      type="radio"
                      id="Water"
                      name="consumption_type"
                      className="form-check-input"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value="Water"
                     
                      checked={validation.values.consumption_type === "Water"}

                    />
                    <Label
                      className="form-check-label"
                      htmlFor="Water"
                    >
                      Water
                    </Label>
                  </div>
                  <div className="form-check form-check mb-2">
                    <Input
                      type="radio"
                      id="Gas"
                      name="consumption_type"
                      className="form-check-input"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value="Gas"
                     
                      checked={validation.values.consumption_type === "Gas"}

                    />
                    <Label
                      className="form-check-label"
                      htmlFor="Gas"
                    >
                      Gas
                    </Label>
                  </div>
                  <div className="form-check form-check mb-2">
                    <Input
                      type="radio"
                      id="Electricity"
                      name="consumption_type"
                      className="form-check-input"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value="Electricity"
                     
                      checked={validation.values.consumption_type === "Electricity"}

                    />
                    <Label
                      className="form-check-label"
                      htmlFor="Electricity"
                    >
                      Electricity
                    </Label>
                  </div>
                  <div className="form-check form-check mb-2">
                    <Input
                      type="radio"
                      id="Electricity"
                      name="consumption_type"
                      className="form-check-input"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value="DG"
                     
                      checked={validation.values.consumption_type === "DG"}

                    />
                    <Label
                      className="form-check-label"
                      htmlFor="DG"
                    >
                      DG
                    </Label>
                  </div>
                    
                  
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-md">
                    {isEditMode ? "Edit" : "Submit"}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default NewInvoiceService
