import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  UncontrolledCollapse,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb

import classnames from "classnames"
import ProjectDetailView from "./components/ProjectDetail/projectDetailView"
import ProjectGalleryView from "./components/ProjectGallery/projectGalleryView"
import ProjectUnitView from "./components/ProjectUnit/projectUnitView"
import { getProjectDetail } from "store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"

const ProjectProfile = () => {
  const dispatch = useDispatch()
  document.title = "Project Profile"
  const [customActiveTab, setcustomActiveTab] = useState("1")
  // const [customActiveTab, setcustomActiveTab] = useState();
  const navigate = useNavigate()

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  // useEffect(() => {
  //   setcustomActiveTab(
  //     permission && permission[67]?.access === 0
  //       ? "2"
  //       : "1" || permission[77]?.access === 0
  //       ? "1" || "3"
  //       : "1"
  //   )
  // }, [permission])

  const projectDetailState = state => state.projects.projectDetail.data
  const projectProfile = useSelector(projectDetailState)

  const [projectId, setProjectId] = useState()

  const projectDetails = async () => {
    try {
      const url = new URL(window.location.href)
      const projectId = url.pathname.split("/project-details/")[1]
      setProjectId(projectId)
      const response = await dispatch(getProjectDetail(projectId))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    projectDetails()
  }, [dispatch])

  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteBillingRegister(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    dispatch(getBillingRegisterList())
    dispatch(getBillingRegisterList())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <CardTitle className="h4" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 mb-3">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  {projectProfile
                    ? projectProfile.project_name
                    : "Project Type"}
                </div>
              </CardTitle>
            </Col>
            <Col>
              <div className="mb-3 text-end ">
                {permission
                  .filter(
                    item =>
                      item.page === "edit-project/:id" && item.access === 1
                  )
                  .map((item, index) => (
                    <button
                      key={index}
                      className="btn btn-primary  me-2"
                      onClick={() => navigate(`/edit-project/${projectId}`)}
                    >
                      <i
                        className="mdi mdi-pencil me-1"
                        style={{ color: "#40baff" }}
                      ></i>
                      Edit project
                    </button>
                  ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom ">
                    {/* <Nav tabs className="nav-tabs-custom nav-justified"> */}
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Project Details
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      {permission
                        .filter(
                          item =>
                            item.page === "list-project-gallery" &&
                            item.access === 1
                        )
                        .map((item, index) => (
                          <NavLink
                            key={index}
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleCustom("2")
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Project Gallery
                            </span>
                          </NavLink>
                        ))}
                    </NavItem>
                    <NavItem>
                      {permission
                        .filter(
                          item =>
                            item.page === "project-unit-details" &&
                            item.access === 1
                        )
                        .map((item, index) => (
                          <NavLink
                            key={index}
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "3",
                            })}
                            onClick={() => {
                              toggleCustom("3")
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Project Unit
                            </span>
                          </NavLink>
                        ))}
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <ProjectDetailView projectProfile={projectProfile} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <ProjectGalleryView />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <ProjectUnitView />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectProfile
