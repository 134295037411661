import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Toast,
  ToastHeader,
  ToastBody,
  navigate,
  FormFeedback,
} from "reactstrap"
import classnames from "classnames"
import { error } from "toastr"

import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
// import { addNewClient } from "store/actions"
import Swal from "sweetalert2"
import { addNewClient } from "helpers/fakebackend_helper"
import { getCities, getCountries, getStates } from "store/actions"

const VendorsDocuments = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const dispatch = useDispatch()
  const customerState = state => state.tickets.ticketDetail.Data
  // const loadingState = state => state.facilities.loading
  const data = useSelector(customerState)
  // const loadingData = useSelector(loadingState)


  const customerProperties = createSelector(
    customerState,
    // loadingState,
    (customers, loading) => {
      return {
        customers: customers,
        loading: loading,
      }
    }
  )

  const { customers, loading } = useSelector(customerProperties)

  const [specificUser, setSpecificUser] = useState(null)

  const [customerId, setCustomerId] = useState()

  const [selectedOption, setSelectedOption] = useState("")

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      customer_type: "",
      customer_name: "",
      customer_code: "",
      email_address: "",
      mobile_no: "",
      address: "",
      pincode: "",
      city: "",
      state: "",
      country: "IN",
      pan_no: "",
      aadhar_no: "",
      tan_no: "",
      gst_no: "",
      login_username: "",
      is_active: "",
    },
    validationSchema: Yup.object().shape({
      customer_type: Yup.string().required("Please Select Customer Type"),
      customer_name: Yup.string().required("Please Enter Your Customer's Name"),
      customer_code: Yup.number()
        .typeError("Customer code must be a number")
        .required("Please Enter Customer code"),
      email_address: Yup.string()
        .email("Invalid Email Format")
        .required("Please Enter Email Address"),
      mobile_no: Yup.string()
        .matches(
          /^[0-9]{10,12}$/,
          "Mobile Number must be between 10 to 12 digits"
        )
        .required("Please Enter Mobile Number "),
      address: Yup.string().required("Please Enter Address"),
      pincode: Yup.string()
        .matches(/^\d{6}$/, "Pincode must be a 6-digit number")
        .required("Please Enter Pincode"),
      city: "",
      state: "",
      country: "",
      pan_no: Yup.string()
        .matches(
          /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
          "PAN Number must be in the format: ABCDE1234F"
        )
        .required("Please Enter PAN Number"),
      aadhar_no: Yup.string()
        .matches(/^\d{12}$/, "Aadhar Number must be a 12-digit number")
        .required("Please Enter Aadhar Number"),
      tan_no: Yup.string()
        .matches(
          /^[A-Z]{4}\d{5}[A-Z]$/,
          "TAN Number must be in the format: XXXX12345X"
        )
        .required("Please Enter TAN Number"),
      gst_no: Yup.string()
        .matches(
          /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}\d{1}$/,
          "GST Number must be in the format: 12ABCDE1234F1Z1"
        )
        .required("Please Enter GST Number"),
      login_username: Yup.string().required("Please enter Login Username"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        const response = await addNewClient(values)
        if (response && response.status === true) {
          loadingSwal.close()

          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Client has been Added.",
          }).then(result => {
            if (result.isConfirmed) {
              navigate("/clients")
            }
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "failure",
            icon: "error",
            text: "Your Client has not been saved.",
          })
        }
      } catch (error) {
        // Handle the error if needed
        loadingSwal.close()

        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const navigate = useNavigate()

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  useEffect(() => {
    //check if the current path includes 'edit-customer'
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)
    setToast2(true)
  }

  const handleChange = event => {
    setSelectedOption(event.target.value)

    validation.setFieldValue("is_active", event.target.value)
  }

  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState(validation.values.state)

  const [rows1, setrows1] = useState([{ id: 1 }])
  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows1(modifiedRows)
  }
  function handleRemoveRow(id) {
    if (rows1.length !== 1) {
      var modifiedRows = [...rows1]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows1(modifiedRows) 
    } else(
      Swal.fire({
        title: "failure",
        icon: "error",
        text: "Cannot delete the last Document.",
      })
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      // Fetch countries
      await dispatch(getCountries())

      // If country is selected (edit mode), fetch states and cities
      if (validation.values.country) {
        await dispatch(getStates(validation.values.country))
        await dispatch(getCities(validation.values.state))
      }

      // Set initial values
      setCountryName(validation.values.country)
      setStateId(validation.values.state)
    }

    fetchData()
  }, [dispatch, validation.values.country, validation.values.state])

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 6) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }
  const handleNextTab = () => {
    validation.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        toggleTab(activeTab + 1)
      } else {
      }
    })
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="inner-repeater mb-4">
            <Label>Documents* </Label>
            <table style={{ width: "100%" }}>
              <tbody>
                {(rows1 || []).map((formRow, key) => (
                  <tr key={key}>
                    <td>
                      <Row className="mb-2">
                        <Col md="3">
                          <select
                            type="text"
                            onChange={e =>
                              validation.handleChange({
                                target: {
                                  name: `compliance[${key}].certificate`,
                                  value: e.target.value,
                                },
                              })
                            }
                            onBlur={validation.handleBlur}
                            name={`compliance[${key}].certificate`}
                            className={`inner form-select ${
                              validation.touched.compliance?.[key]
                                ?.certificate &&
                              validation.errors.compliance?.[key]?.certificate
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.compliance?.[key]
                              ?.compliance_title && (
                              <option>Select Document</option>
                            )}
                            <option>Registration Form</option>
                            <option>Miscellaneous Document</option>
                          </select>
                          {validation.touched.compliance?.[key]
                            ?.compliance_title &&
                            validation.errors.compliance?.[key]
                              ?.compliance_title && (
                              <div className="invalid-feedback">
                                {
                                  validation.errors.compliance?.[key]
                                    ?.compliance_title
                                }
                              </div>
                            )}
                        </Col>
                        <Col md="3">
                          <Input
                            type="file"
                            onChange={e =>
                              validation.handleChange({
                                target: {
                                  name: `compliance[${key}].validity_end_date`,
                                  value: e.target.value,
                                },
                              })
                            }
                            onBlur={validation.handleBlur}
                            name={`compliance[${key}].validity_end_date`}
                            className={`form-control ${
                              validation.touched.compliance?.[key]
                                ?.validity_end_date &&
                              validation.errors.compliance?.[key]
                                ?.validity_end_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.compliance?.[key]
                            ?.validity_end_date &&
                            validation.errors.compliance?.[key]
                              ?.validity_end_date && (
                              <div className="invalid-feedback">
                                {
                                  validation.errors.compliance?.[key]
                                    ?.validity_end_date
                                }
                              </div>
                            )}
                        </Col>
                        <Col md="1">
                          <Button
                            color="primary"
                            className="btn-block inner "
                            id="unknown-btn"
                            style={{ width: "100%" }}
                            onClick={e => {
                              handleRemoveRow(formRow.id)
                            }}
                          >
                            {" "}
                            Delete{" "}
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Button
              onClick={() => {
                handleAddRowNested()
              }}
              color="success"
              className="mt-1"
            >
              Add Documents
            </Button>
          </div>
          <Row>
            <Col>
              <div className="mb-3 text-end">
                <button type="submit" className="btn btn-primary w-md">
                  Save{" "}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default VendorsDocuments
