/* DOCUMENTS */
export const GET_DOCUMENTS = "GET_DOCUMENTS"
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS"
export const GET_DOCUMENTS_FAIL = "GET_DOCUMENTS_FAIL"

/* ACTIVE DOCUMENTS */
export const GET_ACTIVE_DOCUMENTS = "GET_ACTIVE_DOCUMENTS"
export const GET_ACTIVE_DOCUMENTS_SUCCESS = "GET_ACTIVE_DOCUMENTS_SUCCESS"
export const GET_ACTIVE_DOCUMENTS_FAIL = "GET_ACTIVE_DOCUMENTS_FAIL"

/* DOCUMENTS DETAIL*/
export const GET_DOCUMENT_DETAIL = "GET_DOCUMENT_DETAIL"
export const GET_DOCUMENT_DETAIL_SUCCESS = "GET_DOCUMENT_DETAIL_SUCCESS"
export const GET_DOCUMENT_DETAIL_FAIL = "GET_DOCUMENT_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_DOCUMENT = "ADD_NEW_DOCUMENT"
 export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS"
 export const ADD_DOCUMENT_FAIL = "ADD_DOCUMENT_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT"
 export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS"
 export const UPDATE_DOCUMENT_FAIL = "UPDATE_DOCUMENT_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_DOCUMENT = "DELETE_DOCUMENT"
 export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS"
 export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL"
