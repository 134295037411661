import { GET_CITIES, GET_COUNTRIES, GET_PROJECT_LIST_BY_CUSTOMER_ID, GET_PROJECT_LIST_BY_VENDOR_ID, GET_PROJECT_UNIT_DATA, GET_STATES } from "./actionTypes"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  getCitiesFail,
  getCitiesSuccess,
  getCountriesFail,
  getCountriesSuccess,
  getProjectListByCustomerIdFail,
  getProjectListByCustomerIdSuccess,
  getProjectListByvendorIdFail,
  getProjectListByvendorIdSuccess,
  getProjectUnitDataFail,
  getProjectUnitDataSuccess,
  getStatesFail,
  getStatesSuccess,
} from "./actions"
import { getCities, getCountries, getStates,getProjectListByCustomerId, getProjectUnitData, getProjectListByVendorId } from "helpers/fakebackend_helper"

function* fetchCities({ stateId }) {
  try {
    const response = yield call(getCities, stateId)

    yield put(getCitiesSuccess(response))
  } catch (error) {
    yield put(getCitiesFail(error))
  }
}

function* fetchStates({ states }) {
  try {
    const response = yield call(getStates, states)

    yield put(getStatesSuccess(response))
  } catch (error) {
    yield put(getStatesFail(error))
  }
}

function* fetchCountries() {
  try {
    const response = yield call(getCountries)

    yield put(getCountriesSuccess(response))
  } catch (error) {
    yield put(getCountriesFail(error))
  }
}
function* fetchProjectListByCustomerId({id}) {
  try {
    const response = yield call(getProjectListByCustomerId,id)

    yield put(getProjectListByCustomerIdSuccess(response))
  } catch (error) {
    yield put(getProjectListByCustomerIdFail(error))
  }
}
function* fetchProjectUnitData({id}) {
  try {
    const response = yield call(getProjectUnitData,id)

    yield put(getProjectUnitDataSuccess(response))
  } catch (error) {
    yield put(getProjectUnitDataFail(error))
  }
}
function* fetchProjectListByVendorId({id}) {
  try {
    const response = yield call(getProjectListByVendorId,id)

    yield put(getProjectListByvendorIdSuccess(response))
  } catch (error) {
    yield put(getProjectListByvendorIdFail(error))
  }
}

function* commonSaga() {
  yield takeEvery(GET_CITIES, fetchCities)
  yield takeEvery(GET_STATES, fetchStates)
  yield takeEvery(GET_COUNTRIES, fetchCountries)
  yield takeEvery(GET_PROJECT_LIST_BY_CUSTOMER_ID, fetchProjectListByCustomerId)
  yield takeEvery(GET_PROJECT_LIST_BY_VENDOR_ID, fetchProjectListByVendorId)
  yield takeEvery(GET_PROJECT_UNIT_DATA, fetchProjectUnitData)
}

export default commonSaga
