export const GET_ALL_ENGAGEMENT_LIST = "GET_ALL_ENGAGEMENT_LIST"
export const GET_ALL_ENGAGEMENT_LIST_SUCCESS = "GET_ALL_ENGAGEMENT_LIST_SUCCESS"
export const GET_ALL_ENGAGEMENT_LIST_FAIL = "GET_ALL_ENGAGEMENT_LIST_FAIL"

export const GET_APPROVAL_PENDING_LIST = "GET_APPROVAL_PENDING_LIST"
export const GET_APPROVAL_PENDING_LIST_SUCCESS = "GET_APPROVAL_PENDING_LIST_SUCCESS"
export const GET_APPROVAL_PENDING_LIST_FAIL = "GET_APPROVAL_PENDING_LIST_FAIL"

export const GET_BILLING_SETUP_PENDING_LIST ="GET_BILLING_SETUP_PENDING_LIST"
export const GET_BILLING_SETUP_PENDING_LIST_SUCCESS ="GET_BILLING_SETUP_PENDING_LIST_SUCCESS"
export const GET_BILLING_SETUP_PENDING_LIST_FAIL ="GET_BILLING_SETUP_PENDING_LIST_FAIL"



