import {
  GET_TICKETS_FAIL,
  GET_TICKETS_SUCCESS,
  GET_TICKET_DETAIL_FAIL,
  GET_TICKET_DETAIL_SUCCESS,
  ADD_TICKET_SUCCESS,
  ADD_TICKET_FAIL,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAIL,
  DELETE_TICKET_SUCCESS,
  DELETE_TICKET_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  tickets: [],
  ticketDetail: {},
  error: {},
  loading: true
};

const tickets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: action.payload,
        loading: true
        // loading: true
      };

    case GET_TICKETS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_TICKET_SUCCESS:
      return {
        ...state,
        tickets: [...state.tickets, action.payload],
      };

    case ADD_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_TICKET_DETAIL_SUCCESS:
      return {
        ...state,
        ticketDetail: action.payload,
      };

    case UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        tickets: state.tickets.map(ticket =>
          ticket.id.toString() === action.payload.id.toString()
            ? { ticket, ...action.payload }
            : ticket
        ),
      };

    case UPDATE_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_TICKET_SUCCESS:
      return {
        ...state,
        tickets: state.tickets.filter(
          ticket => ticket.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    case GET_TICKET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default tickets;
