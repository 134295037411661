import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_TICKETS, GET_TICKET_DETAIL, ADD_NEW_TICKET, DELETE_TICKET, UPDATE_TICKET } from "./actionTypes"
import {
  getTicketsSuccess,
  getTicketsFail,
  getTicketDetailSuccess,
  getTicketDetailFail,
  addTicketFail,
  addTicketSuccess,
  updateTicketSuccess,
  updateTicketFail,
  deleteTicketSuccess,
  deleteTicketFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getTickets, getTicketDetails, addNewTicket, updateTicket, deleteTicket } from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

function* fetchTickets() {
  try {
    const response = yield call(getTickets)
    yield put(getTicketsSuccess(response))
  } catch (error) {
    yield put(getTicketsFail(error))
  } 
}


function* fetchTicketDetail({ ticketId }) {
  try {
    const response = yield call(getTicketDetails, ticketId)
    yield put(getTicketDetailSuccess(response))
  } catch (error) {
    yield put(getTicketDetailFail(error))
  }
}

function* onUpdateTicket({ payload: { id, ticket } }) {
  try {
    const response = yield call(updateTicket,id, ticket)
    // yield put(updateTicketSuccess(response))
    // toast.success("Ticket Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateTicketFail(error))
    // toast.error("Ticket Update Failded", { autoClose: 2000 })
  }
  const response2 = yield put(updateFacilitySuccess(response))

}

function* onDeleteTicket({ payload: ticket }) {
  try {
    const response = yield call(deleteTicket, ticket)
    yield put(deleteTicketSuccess(response))
    // toast.success("Ticket Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteTicketFail(error))
    // toast.error("Ticket Delete Failded", { autoClose: 2000 })
  }
}

function* onAddNewTicket({ payload: ticket }) {
  try {
    const response = yield call(addNewTicket, ticket)
    yield put(addTicketSuccess(response))
    toast.success("Ticket Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addTicketFail(error))
    toast.error("Ticket Add Failded", { autoClose: 2000 })
  }
}

function* ticketsSaga() {
  yield takeEvery(GET_TICKETS, fetchTickets)
  yield takeEvery(GET_TICKET_DETAIL, fetchTicketDetail)
  yield takeEvery(ADD_NEW_TICKET, onAddNewTicket)
  yield takeEvery(UPDATE_TICKET, onUpdateTicket)
  yield takeEvery(DELETE_TICKET, onDeleteTicket)
}

export default ticketsSaga
