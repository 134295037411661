/* CITIES */
export const GET_CITIES = "GET_CITIES"
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS"
export const GET_CITIES_FAIL = "GET_CITIES_FAIL"

/* STATES */
export const GET_STATES = "GET_STATES"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"
export const GET_STATES_FAIL = "GET_STATES_FAIL"

/* COUNTRIES */
export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL"

/* GET_PROJECT_LIST_BY_CUSTOMER_ID */
export const GET_PROJECT_LIST_BY_CUSTOMER_ID = "GET_PROJECT_LIST_BY_CUSTOMER_ID"
export const GET_PROJECT_LIST_BY_CUSTOMER_ID_SUCCESS = "GET_PROJECT_LIST_BY_CUSTOMER_ID_SUCCESS"
export const GET_PROJECT_LIST_BY_CUSTOMER_ID_FAIL = "GET_PROJECT_LIST_BY_CUSTOMER_ID_FAIL"

/* GET_PROJECT_LIST_BY_VENDOR_ID */
export const GET_PROJECT_LIST_BY_VENDOR_ID = "GET_PROJECT_LIST_BY_VENDOR_ID"
export const GET_PROJECT_LIST_BY_VENDOR_ID_SUCCESS = "GET_PROJECT_LIST_BY_VENDOR_ID_SUCCESS"
export const GET_PROJECT_LIST_BY_VENDOR_ID_FAIL = "GET_PROJECT_LIST_BY_VENDOR_ID_FAIL"

/* GET_PROJECT_UNIT_DATA */
export const GET_PROJECT_UNIT_DATA = "GET_PROJECT_UNIT_DATA"
export const GET_PROJECT_UNIT_DATA_SUCCESS = "GET_PROJECT_UNIT_DATA_SUCCESS"
export const GET_PROJECT_UNIT_DATA_FAIL = "GET_PROJECT_UNIT_DATA_FAIL"