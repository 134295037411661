import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Col, Label, Modal, Row, Form, Input, FormFeedback } from "reactstrap"
import { createSelector } from "reselect"
import { addNewSACHSNCodeMaterialMaster } from "store/actions"
import * as Yup from "yup"

const sacHsnCodeModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch()
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      sac_hsm_code: "",
      description: "",
      is_active: true,
    },
    validationSchema: Yup.object({
      sac_hsm_code: Yup.string().required("Please Enter Your SAC / HSM Code"),
      description: Yup.string().required("Please Enter Description"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      const response = dispatch(addNewSACHSNCodeMaterialMaster(values))
      toggle()
    },
  })

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Add SAC / HSN Code
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-sac_hsm_code-Input">
                  SAC / HSN Code*
                </Label>
                <Input
                  type="text"
                  name="sac_hsm_code"
                  className="form-control"
                  id="formrow-sac_hsm_code-Input"
                  placeholder="Enter SAC / HSN Code"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.sac_hsm_code || ""}
                  invalid={
                    validation.touched.sac_hsm_code &&
                    validation.errors.sac_hsm_code
                      ? true
                      : false
                  }
                />
                {validation.touched.sac_hsm_code &&
                validation.errors.sac_hsm_code ? (
                  <FormFeedback type="invalid">
                    {validation.errors.sac_hsm_code}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-description-Input">Description*</Label>
                <Input
                  type="text"
                  name="description"
                  className="form-control"
                  id="formrow-description-Input"
                  placeholder="Enter Description"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-2"></div>
              <div>
                <Label htmlFor="formrow-ispaid-Input">Is Active</Label>
              </div>

              <Col lg="12">
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    className="form-check-Input"
                    id="is_active"
                    name="is_active"
                    onChange={e => {
                      validation.setFieldValue("is_active", e.target.checked)
                    }}
                    checked={Boolean(validation?.values?.is_active)}
                  />
                  <Label className="form-check-Label" htmlFor="is-Active">
                    <h6>Yes</h6>
                  </Label>
                </div>
                {validation.touched.is_active &&
                  validation.errors.is_active && (
                    <FormFeedback type="invalid">
                      {validation.errors.is_active}
                    </FormFeedback>
                  )}
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3 text-end">
                <button type="submit" className="btn btn-primary w-md">
                  Add
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}

export default sacHsnCodeModal
