import React, { useEffect, useState } from "react"
import  {useNavigate, useLocation } from "react-router-dom"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip,
  Row,
  Col,
  CardTitle,
  Label,
  Container,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap"

import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Breadcrumb from "components/Common/Breadcrumb"
import { getAssignedProjectUnit, getBillingSetupDetail } from "store/actions"

const BillingSetupPreview = () => {
  const path = useLocation()

  const [unitId, setUnitId] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const billingSetupPreviewState = state => state.billing.billSetupDetail.data
  const billingSetupPreview = useSelector(billingSetupPreviewState)

  useEffect(() => {
    const pathName = path.pathname
    const unitId = pathName.split("/billing-setup-preview/")[1]
    setUnitId(unitId)
    // dispatch(dispatch(getEngagementService(unitId)))
    dispatch(getBillingSetupDetail(unitId))

    // validation.setFieldValue("client_service_engagement_id", unitId)
  }, [dispatch, path])

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey", marginRight: "15px" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              <span
                style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
              >
                {" "}
                Billing Setup Preview
              </span>
            </div>
          </Col>
          <Col>
            <Breadcrumb title="Billing Setup Preview " />
          </Col>
        </Row>
        <Container fluid={true}>
          <Card>
            <CardBody>
            <Row>
                <Col className="text-end">
                    <button className="btn btn-primary" onClick={()=>{navigate(`/billing/billing-setup-preview/${unitId}`)}}>
                      Edit Billing Setup
                    </button>
                  
                </Col>
              </Row>
              {billingSetupPreview && (
                <Row>
                  <Col>
                    <CardTitle className="mb-3">
                      {" "}
                      <div className="d-flex gap-2 mb-3">
                        <i
                          className="bx bx-receipt font-size-20"
                          style={{ color: "#3366cc", marginTop: "5px" }}
                        ></i>
                        <span
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          {" "}
                          Billing Setup Preview
                        </span>
                      </div>
                    </CardTitle>
                    <CardSubtitle className="mb-3">
                      {" "}
                      <span
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "13px",
                        }}
                      >
                        {/* Status: {billingSetupPreview && billingSetupPreview.project_unit?.approval_status} */}
                      </span>
                    </CardSubtitle>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={4}>
                        <Label className="form-label">Billing Company</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {billingSetupPreview &&
                            billingSetupPreview.company_name}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Billing Mode </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {billingSetupPreview &&
                          billingSetupPreview.billing_mode === 1
                            ? "Automated Invoicing"
                            : "Manual Invoicing"}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Commencement date</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {billingSetupPreview &&
                            billingSetupPreview.commencement_date}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">Billing Frequency </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {billingSetupPreview &&
                            (billingSetupPreview.billing_frequency === 1
                              ? "Monthly"
                              : billingSetupPreview.billing_frequency === 2
                              ? "Quarterly"
                              : billingSetupPreview.billing_frequency === 3
                              ? "Half_yearly"
                              : billingSetupPreview.billing_frequency === 4
                              ? "Yearly"
                              : "None")}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Type of Supply </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {billingSetupPreview &&
                            billingSetupPreview.gst_type_of_supply}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Place of Supply</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {billingSetupPreview &&
                            billingSetupPreview.place_of_supply_state}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">Gst Applicable</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {billingSetupPreview &&
                          billingSetupPreview.gst_applicable === 1
                            ? "Yes"
                            : "No"}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Gst rate(%)</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {billingSetupPreview &&
                            billingSetupPreview.gst_applicable_rate}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          {/* Notice Period* (DAYS) */}
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {/* {billingSetupPreview && billingSetupPreview.project_unit?.notice_period} */}
                        </p>
                      </Col>
                    </Row>
                    <Table striped bordered hover className="mt-4">
                      <thead>
                        <tr>
                          <th>Invoice type</th>
                          <th>Description(%)</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {billingSetupPreview.billing_item_details.map(
                          (item, index) => (
                            <tr key={index}>
                              <td className="col-lg-4">{item.invoice_type}</td>
                              <td className="col-lg-4">{item.description}</td>
                              <td className="col-lg-4">{item.amount}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
              
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BillingSetupPreview
