import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//flatpickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Table,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import { useFormik } from "formik"
import {
  getProjectListByvendorId,
  getUnitForConsumption,
  getVendorLedger,
} from "store/actions"
import { downloadLedger, downloadVendorLedger } from "helpers/fakebackend_helper"
import formatNumberWithCommas from "components/Common/CommaSeparator"

const VendorLedger = props => {
  document.title = "Vendor Ledger"
  const dispatch = useDispatch()
  const ledgerState = state => state.vendorSummaryReport.vendorLedger.ledger
  const LedgerData = useSelector(ledgerState) || []
  const ledger = state => state.vendorSummaryReport.vendorLedger
  const ledgerOtherData = useSelector(ledger) || []
  const companyState = state =>
    state.vendorSummaryReport?.vendorLedger?.company
  const companyData = useSelector(companyState) || []
  const locationState = state =>
    state.vendorSummaryReport?.vendorLedger?.location
  const locationData = useSelector(locationState) || []
  const [vendor, setVendor] = useState("")
  console.log(companyData)
  const location = useLocation()

  const [type, setType] = useState()

  const navigate = useNavigate()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [project, setProject] = useState("")
  const projectstate = state => state.common?.projectListVendor?.projects
  const projectList = useSelector(projectstate) || []

  const handleExport = async () => {
    const value = {
      type: "vendorLedger",
      project_id: project,
      vendor_id: vendor,
      from_date: validation.values.from_date,
      to_date: validation.values.to_date,
      user_id: user.user_id,

    }

    const downloadUrl = await downloadVendorLedger(value)
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  const validation = useFormik({
    initialValues: {
      from_date: "",
      to_date: "",
    },
    validate: values => {
      const errors = {}
      if (values.from_date && !values.to_date) {
        errors.to_date = "To date is required if from date is filled."
      }
      return errors
    },
  })
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      const value = {
        vendor_id: id,
      }
      dispatch(getVendorLedger(value))
      dispatch(getProjectListByvendorId(id))
      setVendor(id)
    }
  }, [id])

  const handleSubmit = async () => {
    if (
      project ||
      vendor ||
      validation.values.from_date ||
      validation.values.to_date
    ) {
      const value = {
        vendor_id: vendor,
        project_id: project,
        from_date: validation.values.from_date,
        to_date: validation.values.to_date,
      }
      dispatch(getVendorLedger(value))
    }
  }



  const handleSelectProject = ele => {
    const value = ele.value
    setProject(value)
  }

  const sumValues = () => {

    let x1, x2;
    if (ledgerOtherData && ledgerOtherData.outstanding_amount > 0) {
      x2 = ledgerOtherData.outstanding_amount;
      x1 = 0;
    } else {
      x2 = 0;
      x1 = Math.abs(ledgerOtherData.outstanding_amount);
    }
  
    const value1 = ledgerOtherData.total_debit + x1;
    const value2 = ledgerOtherData.total_credit + x2;
    console.log(value1, value2);
    return { value1, value2 };
  };
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={10}>
              <Card className="overflow-hidden">
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr>
                          <td style={{ borderBottomWidth: 0 }}>
                            {" "}
                            <div className="d-flex gap-2 mb-3">
                              <i
                                className="mdi mdi-arrow-left font-size-20"
                                style={{ color: "grey", marginRight: "15px" }}
                                onClick={() => {
                                  navigate("/reports/vendor-summary-report")
                                }}
                              ></i>

                              <span
                                style={{
                                  color: "black",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                }}
                              ></span>
                            </div>
                          </td>
                          <td style={{ borderBottomWidth: 0 }}>
                            <select
                              className="form-select select2 mb-3 mb-xxl-0"
                              defaultValue="Select Project"
                              onChange={e => handleSelectProject(e.target)}
                            >
                              <option value="">Select Project</option>
                              {(projectList || []).map(item => (
                                <option key={item.project_id} value={item.project_id}>
                                  {item.project_name}
                                </option>
                              ))}
                            </select>
                          </td>
                          
                          <td style={{ borderBottomWidth: 0 }}>
                            <div className="mb-3">
                              <Input
                                type="date"
                                className={`form-control mb-3 mb-xxl-0 ${
                                  validation.touched.from_date &&
                                  validation.errors.from_date
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="from_date"
                                placeholder="Select From date"
                                value={validation.values.from_date}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.from_date &&
                              validation.errors.from_date ? (
                                <div className="invalid-feedback">
                                  {validation.errors.from_date}
                                </div>
                              ) : null}
                            </div>
                          </td>
                          <td style={{ borderBottomWidth: 0 }}>
                            <div className="mb-3">
                              <Input
                                dateFormat="dd/mm/yyyy"
                                type="date"
                                className={`form-control mb-3 mb-xxl-0 ${
                                  validation.touched.to_date &&
                                  validation.errors.to_date
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="to_date"
                                placeholder="Select to date"
                                value={validation.values.to_date}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.to_date &&
                              validation.errors.to_date ? (
                                <div className="invalid-feedback">
                                  {validation.errors.to_date}
                                </div>
                              ) : null}
                            </div>
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              borderBottomWidth: 0,
                            }}
                          >
                            <button
                              className="btn btn-success btn-sm"
                              onClick={handleSubmit}
                              type="button"
                            >
                              Submit
                            </button>
                          </td>
                          {permission
                            .filter(
                              item =>
                                item.page === "/export-client-ledger" &&
                                item.access === 1
                            )
                            .map(index => (
                              <td
                                key={index}
                                style={{
                                  textAlign: "end",
                                  borderBottomWidth: 0,
                                }}
                              >
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={handleExport}
                                  type="button"
                                >
                                  Export
                                </button>
                              </td>
                            ))}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={10}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="table-responsive">
                    {companyData && companyData.name && companyData && project !== "" && (
                      <Row className="justify-content-center  mt-3 me-0 ms-0">
                        <h4 className="text-center text-dark">
                          {companyData && companyData?.name}
                        </h4>
                        <h6 className="text-center ">
                          {locationData && locationData?.location_title}
                        </h6>
                        <h6 className="text-center ">
                          <b> CIN : </b>{" "}
                          {companyData && companyData?.cin_no} ||{" "}
                          <b> PAN : </b>{" "}
                          {companyData && companyData?.pan_no}
                        </h6>

                        <h6 className="text-center ">
                          <b> Email :</b>{" "}
                          {locationData && locationData?.account_email} ||{" "}
                          <b> Phone no : </b>{" "}
                          {locationData && locationData?.contact_phone}
                        </h6>
                      </Row>
                    )}
                    <hr></hr>
                    <h4 className="text-center mt-4 text-dark">
                      Ledger Account
                    </h4>
                    {validation.values.from_date &&
                      validation.values.to_date && (
                        <p className="text-center text-dark mb-4">
                          From{" "}
                          {validation.values.from_date
                            ? new Date(
                                validation.values.from_date
                              ).toLocaleDateString("en-GB")
                            : "N/A"}{" "}
                          to{" "}
                          {validation.values.to_date
                            ? new Date(
                                validation.values.to_date
                              ).toLocaleDateString("en-GB")
                            : "N/A"}
                        </p>
                      )}

                    <Table className="table mb-0 mt-4">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingRight: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            <strong>Vendor Name</strong>
                          </td>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingBottom: "15px",
                            }}
                          >
                            :
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              borderBottomWidth: 0,
                              paddingBottom: "15px",
                            }}
                          >
                            {(ledgerOtherData &&
                              ledgerOtherData.vendor_name) ||
                              "NA"}
                          </td>

                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingRight: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            <strong>Vendor Code</strong>
                          </td>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingBottom: "15px",
                            }}
                          >
                            :
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              borderBottomWidth: 0,
                              paddingLeft: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            {(ledgerOtherData &&
                              ledgerOtherData.vendor_code) ||
                              "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingRight: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            <strong>Address</strong>
                          </td>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingBottom: "15px",
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingBottom: "15px",
                            }}
                          >
                            {(ledgerOtherData && ledgerOtherData.address) ||
                              "NA"}
                          </td>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingLeft: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            {" "}
                          </td>

                          {type === "project" || type === "projects" ? (
                            <>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingRight: "15px",
                                  paddingBottom: "15px",
                                }}
                              >
                                <strong>Project</strong>
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingBottom: "15px",
                                }}
                              >
                                :
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingBottom: "15px",
                                }}
                              >
                                {type === "project"
                                  ? ledgerOtherData?.project_names
                                  : "All"}
                              </td>
                            </>
                          ) : null}

                          {type === "company" || type === "companies" ? (
                            <>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingRight: "15px",
                                  paddingBottom: "15px",
                                }}
                              >
                                <strong>Company</strong>
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingBottom: "15px",
                                }}
                              >
                                :
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingBottom: "15px",
                                }}
                              >
                                {type === "company"
                                  ? ledgerOtherData?.company_names
                                  : "All"}
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingLeft: "15px",
                                  paddingBottom: "15px",
                                }}
                              >
                                {" "}
                              </td>
                            </>
                          ) : null}
                        </tr>
                      </tbody>
                    </Table>
                    <Table
                      style={{
                        border: "1px solid #EEE",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Voucher Date
                        </th>
                        <th
                          colSpan={2}
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Particulars{" "}
                        </th>

                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Voucher Type{" "}
                        </th>
                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Voucher No.{" "}
                        </th>
                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Debit
                        </th>
                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Credit
                        </th>
                      </tr>
                      {LedgerData &&
                        LedgerData.map((d, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                                textWrap: "nowrap",
                              }}
                            >
                              {d.date}
                            </td>
                            <td
                              colSpan={2}
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                              }}
                            >
                              {d.description}
                            </td>
                            <td
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                              }}
                            >
                              {d.transaction_type}
                            </td>
                            <td
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                              }}
                            >
                              {d.bill_no}
                            </td>

                            <td
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                                textAlign: "end",
                              }}
                            >
                              {Number(d.amount_debit) === 0
                                ? ""
                                : formatNumberWithCommas(d.amount_debit)}
                            </td>
                            <td
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                                textAlign: "end",
                              }}
                            >
                              {Number(d.amount_credit) === 0
                                ? ""
                                : formatNumberWithCommas(d.amount_credit)}{" "}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderTop: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        ></td>

                        <th
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderTop: "2px solid black",
                            borderCollapse: "collapse",
                          }}
                        >
                          {/* Total */}
                        </th>
                        <td
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderTop: "2px solid black",
                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          {formatNumberWithCommas(
                            (parseFloat(ledgerOtherData?.total_debit).toFixed(2))
                          ) || "0.00"}
                        </td>
                        <td
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderTop: "2px solid black",
                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          {formatNumberWithCommas(
                            parseFloat(ledgerOtherData?.total_credit).toFixed(2)
                          ) || "0.00"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderBottom: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        ></td>

                        <th
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderBottom: "2px solid black",
                            borderCollapse: "collapse",
                          }}
                        >
                          Closing Balance
                        </th>
                        <td
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderBottom: "2px solid black",
                            
                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          {isNaN(
                            parseFloat(ledgerOtherData.outstanding_amount)
                          )
                            ? "0.00"
                            : parseFloat(
                                ledgerOtherData.outstanding_amount
                              ) < 0
                            ? formatNumberWithCommas(
                                Math.abs(
                                  parseFloat(
                                    ledgerOtherData.outstanding_amount
                                  )
                                ).toFixed(2)
                              )
                            : "0.00"}
                        </td>
                        <td
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderBottom: "2px solid black",
                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          {parseFloat(
                            ledgerOtherData.outstanding_amount
                          ) > 0
                            ? formatNumberWithCommas(
                                ledgerOtherData.outstanding_amount
                              )
                            : "0.00"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        ></td>

                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderBottom: "2px solid black",
                            textAlign: "center",
                            borderCollapse: "collapse",
                          }}
                        >Total</th>
                        <td
                          style={{
                            padding: "15px",
                            
                            border: "1px solid #EEE",
                            borderBottom: "2px solid black",
                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          <strong className="p-0">
                            {formatNumberWithCommas((sumValues()?.value1).toFixed(2))}
                          </strong>
                        </td>
                        <td
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderBottom: "2px solid black",

                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          <strong className="p-0">
                            {formatNumberWithCommas((sumValues()?.value2).toFixed(2))}
                          </strong>
                        </td>
                      </tr>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

VendorLedger.propTypes = {
  history: PropTypes.object,
}

export default withRouter(VendorLedger)
