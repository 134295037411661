import {
  GET_VENDORS_FAIL,
  GET_VENDORS_SUCCESS,
  GET_VENDOR_DETAIL_FAIL,
  GET_VENDOR_DETAIL_SUCCESS,
  ADD_VENDOR_SUCCESS,
  ADD_VENDOR_FAIL,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_FAIL,
  DELETE_VENDOR_SUCCESS,
  DELETE_VENDOR_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  vendors: [],
  addVendor: [],
  vendorDetail: {
    data: {}, 
    loading: true, 
  },
  error: {},
  loading: true,
}

const vendors = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: action.payload,
        loading: false,
      }

    case GET_VENDORS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_VENDOR_SUCCESS:
      return {
        ...state,
        addVendor: [...state.addVendor, action.payload],
      }

    case ADD_VENDOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_VENDOR_DETAIL_SUCCESS:
      return {
        ...state,
        vendorDetail: action.payload,
        loading: false,
      }

    case UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        addVendor: state.addVendor.map(vendor =>
          vendor.id === action.payload.id
            ? { ...vendor, ...action.payload }
            : vendor
        ),
      }

    case UPDATE_VENDOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_VENDOR_SUCCESS:
      return {
        ...state,
        vendors: state.vendors.filter(
          vendor => vendor.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_VENDOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_VENDOR_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default vendors
