/* ALL_PROJECTS */
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS"
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS"
export const GET_ALL_PROJECTS_FAIL = "GET_ALL_PROJECTS_FAIL"

/* PROJECT OCCUPANCY DETAIL*/
export const GET_PROJECT_OCCUPANCY_DETAIL = "GET_PROJECT_OCCUPANCY_DETAIL"
export const GET_PROJECT_OCCUPANCY_DETAIL_SUCCESS = "GET_PROJECT_OCCUPANCY_DETAIL_SUCCESS"
export const GET_PROJECT_OCCUPANCY_DETAIL_FAIL = "GET_PROJECT_OCCUPANCY_DETAIL_FAIL"

/* ALL_COMPANIES */
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES"
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS"
export const GET_ALL_COMPANIES_FAIL = "GET_ALL_COMPANIES_FAIL"

/* PROJECT OCCUPANCY DETAIL*/
export const GET_GST_R1_REPORT = "GET_GST_R1_REPORT"
export const GET_GST_R1_REPORT_SUCCESS = "GET_GST_R1_REPORT_SUCCESS"
export const GET_GST_R1_REPORT_FAIL = "GET_GST_R1_REPORT_FAIL"