/* RENEWALS */
export const GET_RENEWALS = "GET_RENEWALS"
export const GET_RENEWALS_SUCCESS = "GET_RENEWALS_SUCCESS"
export const GET_RENEWALS_FAIL = "GET_RENEWALS_FAIL"

/* RENEWALS DETAIL*/
export const GET_RENEWAL_DETAIL = "GET_RENEWAL_DETAIL"
export const GET_RENEWAL_DETAIL_SUCCESS = "GET_RENEWAL_DETAIL_SUCCESS"
export const GET_RENEWAL_DETAIL_FAIL = "GET_RENEWAL_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_RENEWAL = "ADD_NEW_RENEWAL"
 export const ADD_RENEWAL_SUCCESS = "ADD_RENEWAL_SUCCESS"
 export const ADD_RENEWAL_FAIL = "ADD_RENEWAL_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_RENEWAL = "UPDATE_RENEWAL"
 export const UPDATE_RENEWAL_SUCCESS = "UPDATE_RENEWAL_SUCCESS"
 export const UPDATE_RENEWAL_FAIL = "UPDATE_RENEWAL_FAIL"
 
 /**
  * Delete user
  */
 export const DECLINE_RENEWAL = "DECLINE_RENEWAL"
 export const DECLINE_RENEWAL_SUCCESS = "DECLINE_RENEWAL_SUCCESS"
 export const DECLINE_RENEWAL_FAIL = "DECLINE_RENEWAL_FAIL"
