import {
  GET_DEBIT_NOTE_LIST,
  GET_DEBIT_NOTE_LIST_SUCCESS,
  GET_DEBIT_NOTE_LIST_FAIL,
  GET_DEBIT_NOTE_DETAIL,
  GET_DEBIT_NOTE_DETAIL_SUCCESS,
  GET_DEBIT_NOTE_DETAIL_FAIL,
  ADD_NEW_DEBIT_NOTE,
  ADD_NEW_DEBIT_NOTE_SUCCESS,
  ADD_NEW_DEBIT_NOTE_FAIL,
  UPDATE_DEBIT_NOTE,
  UPDATE_DEBIT_NOTE_SUCCESS,
  UPDATE_DEBIT_NOTE_FAIL,
  DELETE_DEBIT_NOTE,
  DELETE_DEBIT_NOTE_SUCCESS,
  DELETE_DEBIT_NOTE_FAIL,
  GET_VENDOR_INVOICE_DETAIL,
  GET_VENDOR_INVOICE_DETAIL_SUCCESS,
  GET_VENDOR_INVOICE_DETAIL_FAIL,
} from "./actionTypes"

export const getDebitNoteList = (value) => ({
  type: GET_DEBIT_NOTE_LIST,
  value
})

export const getDebitNoteListSuccess = debits => ({
  type: GET_DEBIT_NOTE_LIST_SUCCESS,
  payload: debits,
})
export const getDebitNoteListFail = error => ({
  type: GET_DEBIT_NOTE_LIST_FAIL,
  payload: error,
})

export const getDebitNoteDetail = debitId => ({
  type: GET_DEBIT_NOTE_DETAIL,
  debitId,
})

export const getDebitNoteDetailSuccess = debitDetail => ({
  type: GET_DEBIT_NOTE_DETAIL_SUCCESS,
  payload: debitDetail,
})

export const getDebitNoteDetailFail = error => ({
  type: GET_DEBIT_NOTE_DETAIL_FAIL,
  payload: error,
})

export const addNewDebitNote = (debit, history) => ({
  type: ADD_NEW_DEBIT_NOTE,
  payload: { debit, history },
  status: true,
})

export const addNewDebitNoteSuccess = debit => ({
  type: ADD_NEW_DEBIT_NOTE_SUCCESS,
  payload: debit,
})

export const addNewDebitNoteFail = error => ({
  type: ADD_NEW_DEBIT_NOTE_FAIL,
  payload: error,
})

export const updateDebitNote = (id, debit, history) => ({
  type: UPDATE_DEBIT_NOTE,
  payload: { id, debit, history },
})

export const updateDebitNoteSuccess = debit => ({
  type: UPDATE_DEBIT_NOTE_SUCCESS,
  payload: debit,
})

export const updateDebitNoteFail = error => ({
  type: UPDATE_DEBIT_NOTE_FAIL,
  payload: error,
})

export const deleteDebitNote = debit => ({
  type: DELETE_DEBIT_NOTE,
  payload: debit,
})

export const deleteDebitNoteSuccess = debit => ({
  type: DELETE_DEBIT_NOTE_SUCCESS,
  payload: debit,
})

export const deleteDebitNoteFail = error => ({
  type: DELETE_DEBIT_NOTE_FAIL,
  payload: error,
})

export const getVendorInvoiceDetail = debitId => ({
  type: GET_VENDOR_INVOICE_DETAIL,
  debitId,
})

export const getVendorInvoiceDetailSuccess = debitDetail => ({
  type: GET_VENDOR_INVOICE_DETAIL_SUCCESS,
  payload: debitDetail,
})

export const getVendorInvoiceDetailFail = error => ({
  type: GET_VENDOR_INVOICE_DETAIL_FAIL,
  payload: error,
})