import {
  GET_CITY_FOR_CUSTOMER_INDEX,
  GET_CITY_FOR_CUSTOMER_INDEX_FAIL,
  GET_CITY_FOR_CUSTOMER_INDEX_SUCCESS,
  GET_CUSTOMER_INDEX,
  GET_CUSTOMER_INDEX_FAIL,
  GET_CUSTOMER_INDEX_SUCCESS,
  GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX,
  GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_FAIL,
  GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_SUCCESS,
  GET_STATE_FOR_CUSTOMER_INDEX,
  GET_STATE_FOR_CUSTOMER_INDEX_FAIL,
  GET_STATE_FOR_CUSTOMER_INDEX_SUCCESS,
} from "./actionTypes"

export const getCustomerIndex = userId => ({
  type: GET_CUSTOMER_INDEX,
  userId,
})

export const getCustomerIndexSuccess = customerIndex => ({
  type: GET_CUSTOMER_INDEX_SUCCESS,
  payload: customerIndex,
})

export const getCustomerIndexFail = error => ({
  type: GET_CUSTOMER_INDEX_FAIL,
  payload: error,
})

export const getProjectFilterForCustomerIndex = userId => ({
  type: GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX,
  userId,
})

export const getProjectFilterForCustomerIndexSuccess = projectFilter => ({
  type: GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_SUCCESS,
  payload: projectFilter,
})

export const getProjectFilterForCustomerIndexFail = error => ({
  type: GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_FAIL,
  payload: error,
})

export const getStateForCustomerIndex = data => ({
  type: GET_STATE_FOR_CUSTOMER_INDEX,
  data,
})

export const getStateForCustomerIndexSuccess = unit => ({
  type: GET_STATE_FOR_CUSTOMER_INDEX_SUCCESS,
  payload: unit,
})

export const getStateForCustomerIndexFail = error => ({
  type: GET_STATE_FOR_CUSTOMER_INDEX_FAIL,
  payload: error,
})

export const getCityForCustomerIndex = data => ({
  type: GET_CITY_FOR_CUSTOMER_INDEX,
  data,
})

export const getCityForCustomerIndexSuccess = unit => ({
  type: GET_CITY_FOR_CUSTOMER_INDEX_SUCCESS,
  payload: unit,
})

export const getCityForCustomerIndexFail = error => ({
  type: GET_CITY_FOR_CUSTOMER_INDEX_FAIL,
  payload: error,
})
