import {
  GET_MATERIAL_MASTER_LIST_SUCCESS,
  GET_MATERIAL_MASTER_LIST_FAIL,
  GET_MATERIAL_MASTER_DETAIL_SUCCESS,
  GET_MATERIAL_MASTER_DETAIL_FAIL,
  ADD_MATERIAL_MASTER_SUCCESS,
  ADD_MATERIAL_MASTER_FAIL,
  UPDATE_MATERIAL_MASTER_SUCCESS,
  UPDATE_MATERIAL_MASTER_FAIL,
  DELETE_MATERIAL_MASTER_SUCCESS,
  DELETE_MATERIAL_MASTER_FAIL,
  GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_SUCCESS,
  GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_FAIL,
  ADD_NEW_SACHSNCODE_MATERIAL_MASTER_SUCCESS,
  ADD_NEW_SACHSNCODE_MATERIAL_MASTER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  material: [],
  addMaterial: [],
  materialDetail: {},
  sachsnCode: [],
  addCode: [],
  error: {},
  loading: true,
}

const materialMaster = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MATERIAL_MASTER_LIST_SUCCESS:
      return {
        ...state,
        material: action.payload,
        loading: false,
      }

    case GET_MATERIAL_MASTER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MATERIAL_MASTER_DETAIL_SUCCESS:
      return {
        ...state,
        materialDetail: action.payload,
        loading: false,
      }

    case GET_MATERIAL_MASTER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_MATERIAL_MASTER_SUCCESS:
      return {
        ...state,
        addMaterial: [...state.addMaterial, action.payload],
      }

    case ADD_MATERIAL_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_MATERIAL_MASTER_SUCCESS:
      return {
        ...state,
        addMaterial: state.addMaterial.map(material =>
          material.id === action.payload.id
            ? { ...material, ...action.payload }
            : material
        ),
      }

    case UPDATE_MATERIAL_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_MATERIAL_MASTER_SUCCESS:
      return {
        ...state,
        material: state.material.data.filter(
          material => material.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_MATERIAL_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_SUCCESS:
      return {
        ...state,
        sachsnCode: action.payload,
        loading: false,
      }

    case GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_SACHSNCODE_MATERIAL_MASTER_SUCCESS:
      return {
        ...state,
        addCode: [...state.addCode, action.payload],
      }

    case ADD_NEW_SACHSNCODE_MATERIAL_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default materialMaster
