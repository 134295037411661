import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  // TIME REGION

  ADD_NEW_TIMEREGION,
  GET_TIMEREGION,

  // FILE CONFIGURATION

  ADD_NEW_FILECONFIGURATION,
  GET_FILECONFIGURATION,

  // RECAPTCHA CONFIGURATION

  ADD_NEW_RECAPTCHACONFIGURATION,
  GET_RECAPTCHACONFIGURATION,

  // EMAIL CONFIGURATION

  ADD_NEW_EMAILCONFIGURATION,
  GET_EMAILCONFIGURATION,

  //USER PASSWORD SETTINGS

  ADD_NEW_USERPASSWORDSETTINGS,
  GET_USERPASSWORDSETTINGS,

  // DATE AND TIME 

  GET_DATETIMEFORMAT,

  //TIME ZONE

  GET_TIMEZONE,

  //CURRENCY

  GET_CURRENCY,

} from "./actionTypes"
import {

  // TIME REGION

  addTimeregionSuccess,
  addTimeregionFail,
  getTimeregionSuccess,
  getTimeregionFail,

  // FILE CONFIGURATION

  addFileconfigurationSuccess,
  addFileconfigurationFail,
  getFileconfigurationSuccess,
  getFileconfigurationFail,

  // RECAPTCHA CONFIGURATION

  addRecaptchaconfigurationSuccess,
  addRecaptchaconfigurationFail,
  getRecaptchaconfigurationSuccess,
  getRecaptchaconfigurationFail,

  //EMAIL CONFIGURATION

  addEmailconfigurationSuccess,
  addEmailconfigurationFail,
  getEmailconfigurationSuccess,
  getEmailconfigurationFail,

  //USER PASSWORD SETTINGS

  addUserpasswordsettingsSuccess,
  addUserpasswordsettingsFail,
  getUserpasswordsettingsSuccess,
  getUserpasswordsettingsFail,

  // DATE AND TIME 

getDateTimeFormatSuccess,
getDateTimeFormatFail,

  //TIME ZONE

getTimeZoneSuccess,
getTimeZoneFail,
  //CURRENCY

  getCurrencySuccess,
  getCurrencyFail

} from "./actions"

//Include Both Helper File with needed methods
import { addNewTimeregion, getTimeregion, addNewFileConfiguration, getFileConfiguration, addNewRecaptchaConfiguration, getRecaptchaConfiguration, addNewEmailConfiguration, getEmailConfiguration, addNewUserPasswordSettings, getUserPasswordSettings, getDateTimeFormat,getTimeZone,getCurrency } from "helpers/fakebackend_helper"
import { toast } from "react-toastify"


// TIME REGION

function* onAddNewTimeregion({ payload: timeregions }) {
  try {
    const response = yield call(addNewTimeregion, timeregions)
    yield put(addTimeregionSuccess(response))
    toast.success("Time / Region Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addTimeregionFail(error))
    toast.error("Time / Region Add Failded", { autoClose: 2000 })
  }
}

function* fetchTimeregion() {
  try {
    const response = yield call(getTimeregion)
    yield put(getTimeregionSuccess(response))
  } catch (error) {
    yield put(getTimeregionFail(error))
  } 
}

// FILE CONFIGURATION

function* onAddNewFileconfiguration({ payload: fileconfigurations }) {
  try {
    const response = yield call(addNewFileConfiguration, fileconfigurations)
    yield put(addFileconfigurationSuccess(response))
    toast.success("File Configuration Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addFileconfigurationFail(error))
    toast.error("File Configuration Add Failded", { autoClose: 2000 })
  }
}

function* fetchFileconfiguration() {
  try {
    const response = yield call(getFileConfiguration)
    yield put(getFileconfigurationSuccess(response))
  } catch (error) {
    yield put(getFileconfigurationFail(error))
  } 
}

// RECAPTCHA CONFIGURATION

function* onAddNewRecaptchaconfiguration({ payload: recaptchaconfigurations }) {
  try {
    const response = yield call(addNewRecaptchaConfiguration, recaptchaconfigurations)
    yield put(addRecaptchaconfigurationSuccess(response))
    toast.success("Recaptcha Configuration Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addRecaptchaconfigurationFail(error))
    toast.error("Recaptcha Configuration Add Failded", { autoClose: 2000 })
  }
}

function* fetchRecaptchaconfiguration() {
  try {
    const response = yield call(getRecaptchaConfiguration)
    yield put(getRecaptchaconfigurationSuccess(response))
  } catch (error) {
    yield put(getRecaptchaconfigurationFail(error))
  } 
}

// EMAIL CONFIGURATION

function* onAddNewEmailconfiguration({ payload: emailconfigurations }) {
  try {
    const response = yield call(addNewEmailConfiguration, emailconfigurations)
    yield put(addEmailconfigurationSuccess(response))
    toast.success("Email Configuration Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addEmailconfigurationFail(error))
    toast.error("Email Configuration Add Failded", { autoClose: 2000 })
  }
}

function* fetchEmailconfiguration() {
  try {
    const response = yield call(getEmailConfiguration)
    yield put(getEmailconfigurationSuccess(response))
  } catch (error) {
    yield put(getEmailconfigurationFail(error))
  } 
}

// USER PASSWORD SETTINGS 

function* onAddNewUserpasswordsettings({ payload: userpasswordsettings }) {
  try {
    const response = yield call(addNewUserPasswordSettings, userpasswordsettings)
    yield put(addUserpasswordsettingsSuccess(response))
    toast.success("User Password Settings Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addUserpasswordsettingsFail(error))
    toast.error("User Password Seettings Add Failded", { autoClose: 2000 })
  }

}

function* fetchUserpasswordsettings() {
  try {
    const response = yield call(getUserPasswordSettings)
    yield put(getUserpasswordsettingsSuccess(response))
  } catch (error) {
    yield put(getUserpasswordsettingsFail(error))
  } 
}

// DATA AND TIME

function* fetchDateAndTime() {
  try {
    const response = yield call(getDateTimeFormat)
    yield put(getDateTimeFormatSuccess(response))
  } catch (error) {
    yield put(getDateTimeFormatFail(error))
  } 
}
// TIME ZONE

function* fetchTimeZone() {
  try {
    const response = yield call(getTimeZone)
    yield put(getTimeZoneSuccess(response))
  } catch (error) {
    yield put(getTimeZoneFail(error))
  } 
}
// Currency

function* fetchCurrency() {
  try {
    const response = yield call(getCurrency)
    yield put(getCurrencySuccess(response))
  } catch (error) {
    yield put(getCurrencyFail(error))
  } 
}
function* regionsSaga() {

  // Time Region
  yield takeEvery(ADD_NEW_TIMEREGION, onAddNewTimeregion)
  yield takeEvery(GET_TIMEREGION, fetchTimeregion)
  
  // File Configuration
  yield takeEvery(ADD_NEW_FILECONFIGURATION, onAddNewFileconfiguration)
  yield takeEvery(GET_FILECONFIGURATION, fetchFileconfiguration)

  //Recaptcha Configuration
  yield takeEvery(ADD_NEW_RECAPTCHACONFIGURATION, onAddNewRecaptchaconfiguration)
  yield takeEvery(GET_RECAPTCHACONFIGURATION, fetchRecaptchaconfiguration)

  //Email Configuration
  yield takeEvery(ADD_NEW_EMAILCONFIGURATION, onAddNewEmailconfiguration)
  yield takeEvery(GET_EMAILCONFIGURATION, fetchEmailconfiguration)

  //User Password Settings
  yield takeEvery(ADD_NEW_USERPASSWORDSETTINGS, onAddNewUserpasswordsettings)
  yield takeEvery(GET_USERPASSWORDSETTINGS, fetchUserpasswordsettings)

  //DATE AND TIME 
  yield takeEvery(GET_DATETIMEFORMAT, fetchDateAndTime)

  //TIME ZONE 
  yield takeEvery(GET_TIMEZONE, fetchTimeZone)

  //CURRENCY
  yield takeEvery(GET_CURRENCY, fetchCurrency)


  

}

export default regionsSaga
