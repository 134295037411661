import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"

const LargeModal = ({
  isOpen,
  toggle,
  data,
  applicableRateList,
  billingCompanyDetail,
  placeOfSupplyList,
  invoiceTypeMasterList,
}) => {
  const companyState = billingCompanyDetail && billingCompanyDetail?.state
  const placeOfSupply =
    placeOfSupplyList &&
    placeOfSupplyList.filter(item => {
      return item.id === Number(data.place_of_supply)
    })[0]?.id
  const rateApplicable =
    applicableRateList && applicableRateList[data.gst_rate - 1]?.applicable_rate

  const [CGST, setCGST] = useState(0)
  const [SGST, setSGST] = useState(0)
  const [IGST, setIGST] = useState(0)

  useEffect(() => {
    const { cgst, sgst, igst } = calculateGST(
      data.billing_components,
      companyState,
      placeOfSupply,
      rateApplicable
    )

    if (data.gst_applicable === "yes") {
      setCGST(cgst)
      setSGST(sgst)
      setIGST(igst)
    } else {
      setCGST(0)
      setSGST(0)
      setIGST(0)
    }
  }, [data.billing_components, companyState, placeOfSupply, rateApplicable])

  const calculateSubtotal = (items, discount) => {
    const total = items.reduce(
      (total, item) =>
        total + (parseFloat(item && item.unit * item.amt_per_unit) || 0),
      0
    )
    const subtotal = total - parseFloat(discount || 0)
    return { subtotal, total }
  }

  const calculateGST = (items, companyState, placeOfSupply, rateApplicable) => {
    const subtotal = calculateSubtotal(items, data.discount).subtotal

    let cgst, sgst, igst
    if (companyState && placeOfSupply && companyState !== placeOfSupply) {
      igst = (subtotal * rateApplicable) / 100 // Assuming rateApplicable is the IGST rate
    } else {
      cgst = (subtotal * (rateApplicable / 2)) / 100 // Assuming rateApplicable is the CGST rate
      sgst = (subtotal * (rateApplicable / 2)) / 100 // Assuming rateApplicable is the SGST rate
    }
    return { cgst, sgst, igst }
  }

  const calculateNetPayable = (items, discount) => {
    const subtotal = calculateSubtotal(items, discount).subtotal
    const { igst, cgst, sgst } = calculateGST(
      items,
      companyState,
      placeOfSupply,
      rateApplicable
    )
    let netPayable;
    if (data.gst_applicable === "yes") {
      netPayable = subtotal + (igst || cgst + sgst)
    } else {
      netPayable = subtotal 
    }
    
    return netPayable
  }

  const formatNumberWithCommas = number => {
    // If number is null or empty, return it as it is
    if (!number) {
      return number
    }

    // Convert number to string
    let numStr = String(number)

    // Split the string into integer and decimal parts
    let parts = numStr.split(".")

    // Format the integer part with commas
    let integerPart = parts[0]
    let lastThreeDigits = integerPart.slice(-3) // Get the last three digits
    let remainingDigits = integerPart.slice(0, -3) // Get the remaining digits
    let formattedInteger =
      remainingDigits !== ""
        ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          lastThreeDigits
        : lastThreeDigits

    // Combine integer and decimal parts with a dot
    let formattedNumber = formattedInteger + (parts[1] ? "." + parts[1] : "")

    return formattedNumber
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Detailed Summary
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <table className="table">
          <thead>
            <tr>
              <th>Invoice Type</th>
              <th>Description</th>
              <th>Unit</th>
              <th>Price/Unit</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.billing_components.map((item, index) => {
                // Adjust the comparison here based on your data structure
                const invoiceTypeObject = invoiceTypeMasterList?.find(
                  invoiceType =>
                    String(invoiceType.id) === String(item?.invoice_type_id)
                )

                // Add more debugging information
                if (!invoiceTypeObject) {
                  console.log(
                    `No matching invoice type found for item id: ${item?.invoice_type}`
                  )
                }

                const invoiceTypeName =
                  invoiceTypeObject?.invoice_type || "Unknown Invoice Type"

                return (
                  <tr key={index}>
                    <td>{invoiceTypeName}</td>
                    <td>{item?.description}</td>
                    <td>{item?.unit}</td>
                    <td>{formatNumberWithCommas(item?.amt_per_unit)}</td>
                    <td>
                      {item?.unit && item?.amt_per_unit
                        ? formatNumberWithCommas(item.unit * item.amt_per_unit)
                        : "NA"}
                    </td>
                  </tr>
                )
              })}
            <tr>
              <td colSpan="4" className="text-end">
                <b>Total</b>
              </td>
              <td>
                {formatNumberWithCommas(
                  calculateSubtotal(data.billing_components, data.discount)
                    .total
                )}{" "}
                /-
              </td>
            </tr>
            <tr>
              <td colSpan="4" className="text-end">
                <b>Discount</b>
              </td>
              <td>{formatNumberWithCommas(data.discount)} /-</td>
            </tr>
            <tr>
              <td colSpan="4" className="text-end">
                <b>Subtotal</b>
              </td>
              <td>
                {formatNumberWithCommas(
                  calculateSubtotal(data.billing_components, data.discount)
                    .subtotal
                )}
                /-
              </td>
            </tr>
            {data.gst_applicable === "yes" && (
              <>
                {companyState &&
                placeOfSupply &&
                companyState === placeOfSupply ? (
                  <>
                    <tr>
                      <td colSpan="4" className="text-end">
                        <b>{`CGST (${rateApplicable}%)`} </b>
                      </td>
                      <td>{formatNumberWithCommas(CGST)} /-</td>
                    </tr>
                    <tr>
                      <td colSpan="4" className="text-end">
                        <b>{`SGST (${rateApplicable}%)`}</b>
                      </td>
                      <td>{formatNumberWithCommas(SGST)} /-</td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan="4" className="text-end">
                      <b>{`IGST (${rateApplicable}%)`} </b>
                    </td>
                    <td>{formatNumberWithCommas(IGST)} /-</td>
                  </tr>
                )}
              </>
            )}
            <tr>
              <td colSpan="4" className="text-end">
                <b>Net Payable</b>
              </td>
              <td>
                {formatNumberWithCommas(
                  calculateNetPayable(data.billing_components, data.discount)
                )}{" "}
                /-
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  )
}

export default LargeModal
