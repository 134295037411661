import { call, put, takeEvery } from "redux-saga/effects"

import {
  getCountForDashboardSuccess,
  getCountForDashboardFail,
  getVacantProjectUnitListSuccess,
  getVacantProjectUnitListFail,
  ActivityFilterSuccess,
  ActivityFilterFail,
  newCustomerListSuccess,
  newCustomerListFail,
  billAmountListSuccess,
  billAmountListFail,
  paymentAmountListSuccess,
  paymentAmountListFail,
  newUnitAssignedListSuccess,
  newUnitAssignedListFail,
  newCustomerGraphSuccess,
  newCustomerGraphFail,
  newUnitAssignedGraphSuccess,
  newUnitAssignedGraphFail,
  billAndPaymentGraphSuccess,
  billAndPaymentGraphFail,
  customerOutstandingListSuccess,
  customerOutstandingListFail,
  newVendorListSuccess,
  newVendorListFail,
  vendorOutstandingListSuccess,
  vendorOutstandingListFail,
  vendorPaymentListSuccess,
  vendorPaymentListFail,
  vendorInvoiceListSuccess,
  vendorInvoiceListFail,
  vendorTicketListSuccess,
  vendorTicketListFail,
  statusWiseTicketListSuccess,
  statusWiseTicketListFail,
} from "./actions"
import {
  ActivityFilter,
  billAmountList,
  billAndPaymentGraph,
  getCountForDashboard,
  getVacantProjectUnitList,
  newCustomerGraph,
  newCustomerListAPI,
  newUnitAssignedGraph,
  newUnitAssignedList,
  paymentAmountList,
  newVendorList,
  vendorTicketList,
  vendorInvoiceList,
  vendorPaymentList,
  vendorOutstandingList,
  customerOutstandingList,
  statusWiseTicketList,
} from "helpers/fakebackend_helper"
import {
  ACTIVITY_FILTER,
  BILL_AMOUNT_LIST,
  BILL_AND_PAYMENT_GRAPH,
  CUSTOMER_OUTSTANTDING_LIST,
  GET_COUNT_FOR_DASHBOARD,
  GET_VACANT_PROJECT_UNIT_LIST,
  NEW_CUSTOMER_GRAPH,
  NEW_CUSTOMER_LIST,
  NEW_UNITS_ASSIGNED_GRAPH,
  NEW_UNIT_ASSIGNED_LIST,
  NEW_VENDOR_LIST,
  PAYMENT_AMOUNT_LIST,
  STATUS_WISE_TICKET_LIST,
  VENDOR_INVOICE_LIST,
  VENDOR_OUTSTANDING_LIST,
  VENDOR_PAYMENT_LIST,
  VENDOR_TICKET_LIST,
} from "./actionTypes"

function* fetchCountForDashboard() {
  try {
    const response = yield call(getCountForDashboard)
    yield put(getCountForDashboardSuccess(response))
  } catch (error) {
    yield put(getCountForDashboardFail(error))
  }
}
function* fetchVacantProjectUnitList() {
  try {
    const response = yield call(getVacantProjectUnitList)
    yield put(getVacantProjectUnitListSuccess(response))
  } catch (error) {
    yield put(getVacantProjectUnitListFail(error))
  }
}

function* activityFilter({ payload: activity }) {
  try {
    const response = yield call(ActivityFilter, activity)
    yield put(ActivityFilterSuccess(response))
  } catch (error) {
    yield put(ActivityFilterFail(error))
  }
}

function* newCustomerList({ payload: newCustomerList }) {
  try {
    const response = yield call(newCustomerListAPI, newCustomerList)
    yield put(newCustomerListSuccess(response))
  } catch (error) {
    yield put(newCustomerListFail(error))
  }
}

function* getNewUnitAssignedList({ payload: unitList }) {
  try {
    const response = yield call(newUnitAssignedList, unitList)
    yield put(newUnitAssignedListSuccess(response))
  } catch (error) {
    yield put(newUnitAssignedListFail(error))
  }
}

function* getBillAmountList({ payload: billList }) {
  try {
    const response = yield call(billAmountList, billList)
    yield put(billAmountListSuccess(response))
  } catch (error) {
    yield put(billAmountListFail(error))
  }
}
function* getPaymentAmountList({ payload: paymentList }) {
  try {
    const response = yield call(paymentAmountList, paymentList)
    yield put(paymentAmountListSuccess(response))
  } catch (error) {
    yield put(paymentAmountListFail(error))
  }
}

function* getNewCustomerGraph({ payload: customerGraph }) {
  try {
    const response = yield call(newCustomerGraph, customerGraph)
    yield put(newCustomerGraphSuccess(response))
  } catch (error) {
    yield put(newCustomerGraphFail(error))
  }
}
function* getNewUnitAssignedGraph({ payload: unitAssignedGraph }) {
  try {
    const response = yield call(newUnitAssignedGraph, unitAssignedGraph)
    yield put(newUnitAssignedGraphSuccess(response))
  } catch (error) {
    yield put(newUnitAssignedGraphFail(error))
  }
}

function* getBillAndPaymentGraph({ payload: billPaymentGraph }) {
  try {
    const response = yield call(billAndPaymentGraph, billPaymentGraph)
    yield put(billAndPaymentGraphSuccess(response))
  } catch (error) {
    yield put(billAndPaymentGraphFail(error))
  }
}
/////////
function* fetchCustomerOutstandingList({ payload: data }) {
  try {
    const response = yield call(customerOutstandingList, data)
    yield put(customerOutstandingListSuccess(response))
  } catch (error) {
    yield put(customerOutstandingListFail(error))
  }
}
function* fetchNewVendor({ payload: data }) {
  try {
    const response = yield call(newVendorList,data)
    yield put(newVendorListSuccess(response))
  } catch (error) {
    yield put(newVendorListFail(error))
  }
}

function* fetchVendorOutstandingList({ payload: data }) {
  try {
    const response = yield call(vendorOutstandingList, data)
    yield put(vendorOutstandingListSuccess(response))
  } catch (error) {
    yield put(vendorOutstandingListFail(error))
  }
}

function* fetchVendorPaymentList({ payload: data }) {
  try {
    const response = yield call(vendorPaymentList, data)
    yield put(vendorPaymentListSuccess(response))
  } catch (error) {
    yield put(vendorPaymentListFail(error))
  }
}
function* fetchVendorInvoiceList({ payload: data }) {
  try {
    const response = yield call(vendorInvoiceList, data)
    yield put(vendorInvoiceListSuccess(response))
  } catch (error) {
    yield put(vendorInvoiceListFail(error))
  }
}
function* fetchVendorTicketList({ payload: data }) {
  try {
    const response = yield call(vendorTicketList, data)
    yield put(vendorTicketListSuccess(response))
  } catch (error) {
    yield put(vendorTicketListFail(error))
  }
}
function* fetchStatusWiseTicketList({ payload: data }) {
  try {
    const response = yield call(statusWiseTicketList, data)
    yield put(statusWiseTicketListSuccess(response))
  } catch (error) {
    yield put(statusWiseTicketListFail(error))
  }
}
function* dashboardSaga() {
  yield takeEvery(GET_COUNT_FOR_DASHBOARD, fetchCountForDashboard)
  yield takeEvery(GET_VACANT_PROJECT_UNIT_LIST, fetchVacantProjectUnitList)
  yield takeEvery(ACTIVITY_FILTER, activityFilter)
  yield takeEvery(NEW_CUSTOMER_LIST, newCustomerList)
  yield takeEvery(NEW_UNIT_ASSIGNED_LIST, getNewUnitAssignedList)
  yield takeEvery(BILL_AMOUNT_LIST, getBillAmountList)
  yield takeEvery(PAYMENT_AMOUNT_LIST, getPaymentAmountList)
  yield takeEvery(NEW_CUSTOMER_GRAPH, getNewCustomerGraph)
  yield takeEvery(NEW_UNITS_ASSIGNED_GRAPH, getNewUnitAssignedGraph)
  yield takeEvery(BILL_AND_PAYMENT_GRAPH, getBillAndPaymentGraph)
  //vendor and customer activity on dashboard
  yield takeEvery(CUSTOMER_OUTSTANTDING_LIST, fetchCustomerOutstandingList)
  yield takeEvery(NEW_VENDOR_LIST, fetchNewVendor)
  yield takeEvery(VENDOR_OUTSTANDING_LIST, fetchVendorOutstandingList)
  yield takeEvery(VENDOR_PAYMENT_LIST, fetchVendorPaymentList)
  yield takeEvery(VENDOR_INVOICE_LIST, fetchVendorInvoiceList)
  yield takeEvery(VENDOR_TICKET_LIST, fetchVendorTicketList)
  yield takeEvery(STATUS_WISE_TICKET_LIST, fetchStatusWiseTicketList)
}

export default dashboardSaga
