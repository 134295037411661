import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  FormFeedback,
  Alert,
} from "reactstrap"
import {
  addNewBillingReceipt,
  getBillingCompanyListInReceipt,
  getPaymentMode,
  getVendor,
  getVendorListInPayment,
  getVendorPaymentDetail,
  getVendors,
} from "store/actions"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { createSelector } from "reselect"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"
// import LargeModal from "./detailedSummaryModal"

const AddNewPayment = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [clientId, setClientId] = useState()
  // const [billingDetails, setBillingDetails] = useState([])
  const billingDetails = [
    {
      amount: "100.00",
      dueDate: "2024-07-01",
      outstandingAmount: "50.00",
    },
    {
      amount: "200.00",
      dueDate: "2024-07-10",
      outstandingAmount: "75.00",
    },
    {
      amount: "150.00",
      dueDate: "2024-07-15",
      outstandingAmount: "60.00",
    },
    {
      amount: "250.00",
      dueDate: "2024-07-20",
      outstandingAmount: "100.00",
    },
  ]

  const [Index, setIndex] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const vendorPaymentState = state => state.vendors
  const BillingState = state => state.billing
  const path = useLocation()

  const AllBillingProperties = createSelector(
    vendorPaymentState,
    BillingState,

    (vendor,billing) => ({
      vendorList: vendor.vendorListInPayment?.data,
      vendorPaymentDetail: vendor.vendorPaymentDetail?.data,
      paymentMode: billing.paymentMode.data,
    })
  )

  const { vendorList, vendorPaymentDetail,paymentMode } = useSelector(AllBillingProperties)

  console.log(vendorList)
  useEffect(() => {
    dispatch(getVendorListInPayment())
    dispatch(getPaymentMode())

  }, [dispatch])

  useEffect(() => {
    const newPaymentData =
      vendorPaymentDetail &&
      vendorPaymentDetail.map(data => ({
        vendor_invoice_id: data.vendor_invoice_id,
        invoice_no: data.invoice_no,
        invoice_amount: data.invoice_amount,
        outstanding_amount: data.outstanding_amount,
      }))
    validation.setFieldValue("paymentData", newPaymentData)
  }, [vendorPaymentDetail])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      vendor_id: "",
      payment_date: "",
      paymentData: "",
      payment_type: "",
      remarks: "",
    },
    validationSchema: Yup.object().shape({
      vendor_id: Yup.number().required("Please select Vendor"),
    }),

    onSubmit: async values => {
      dispatch(addNewBillingReceipt(props.router.navigate, values))
    },
  })

  useEffect(() => {
    const newPaymentData =
      vendorPaymentDetail &&
      vendorPaymentDetail.map(data => ({
        vendor_invoice_id: data.vendor_invoice_id,
        invoice_no: data.invoice_no,
        invoice_amount: data.invoice_amount,
        outstanding_amount: data.outstanding_amount,
        amount_paid: data.amount_paid || '0',
      }))
    validation.setFieldValue("paymentData", newPaymentData)
  }, [vendorPaymentDetail])

  useEffect(() => {
    if (validation.values.vendor_id) {
      dispatch(getVendorPaymentDetail(validation.values.vendor_id))
    }
  }, [validation.values.vendor_id])

  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
                Add Payment
              </div>
            </CardTitle>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="vendor_id">Vendor*</Label>

                          <select
                            className={`form-select ${
                              validation.touched.vendor_id &&
                              validation.errors.vendor_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="vendor_id"
                            value={validation.values.vendor_id}
                          >
                            <option>Select Vendor</option>
                            {(vendorList || []).map(vendor => (
                              <option key={vendor.id} value={vendor.id}>
                                {vendor.vendor_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.vendor_id &&
                            validation.errors.vendor_id && (
                              <div className="invalid-feedback">
                                {validation.errors.vendor_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="payment_date">Payment Date*</Label>
                          <Input
                            type="date"
                            id="payment_date"
                            name="payment_date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.payment_date}
                            className={`form-control ${
                              validation.touched.payment_date &&
                              validation.errors.payment_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.payment_date &&
                          validation.errors.payment_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.payment_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row >
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                    <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="payment_type">Payment Type*</Label>
                          <select
                            className={`form-select ${
                              validation.touched.payment_type &&
                              validation.errors.payment_type
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="payment_type"
                            value={validation.values.payment_type || ""}
                          >
                            {!validation.values.payment_type && (
                              <option>Select Payment Mode</option>
                            )}
                            {paymentMode &&
                              paymentMode.map((mode, index) => (
                                <option key={index} value={mode.id}>
                                  {mode.payment_mode}
                                </option>
                              ))}
                          </select>
                          {validation.touched.payment_type &&
                          validation.errors.payment_type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.payment_type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="remarks">Remarks</Label>

                          <Input
                            type="text"
                            name="remarks"
                            className="form-control"
                            id="remarks"
                            placeholder="Enter Remarks"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.remarks || ""}
                            invalid={
                              validation.touched.remarks &&
                              validation.errors.remarks
                                ? true
                                : false
                            }
                          />
                          {validation.touched.remarks &&
                          validation.errors.remarks ? (
                            <FormFeedback type="invalid">
                              {validation.errors.remarks}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      
                    </Row>
                    {(validation.values.payment_type === "1" ||
                      validation.values.payment_type === "2") && (
                      <Row>
                        <Col lg={4}>
                          <div className=" mb-2">
                            <Label htmlFor="ins_no" className="mt-2">
                              Ins No.
                            </Label>

                            <input
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              className={`form-control w-50 ${
                                validation.touched.ins_no &&
                                validation.errors.ins_no
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="ins_no"
                              value={validation.values.ins_no || ""}
                            />
                            {validation.touched.ins_no &&
                            validation.errors.ins_no ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ins_no}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    )}
                    {validation.values.payment_type === "3" && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <div className=" mb-2">
                              <Label
                                htmlFor="bank_account_number"
                                className="mt-2"
                              >
                                Bank Account Number
                              </Label>

                              <input
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                className={`form-control  ${
                                  validation.touched.bank_account_number &&
                                  validation.errors.bank_account_number
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="bank_account_number"
                                value={
                                  validation.values.bank_account_number || ""
                                }
                              />
                              {validation.touched.bank_account_number &&
                              validation.errors.bank_account_number ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.bank_account_number}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className=" mb-2">
                              <Label htmlFor="cheque_no" className="mt-2">
                                Cheque No.
                              </Label>

                              <input
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                className={`form-control  ${
                                  validation.touched.cheque_no &&
                                  validation.errors.cheque_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="cheque_no"
                                value={validation.values.cheque_no || ""}
                              />
                              {validation.touched.cheque_no &&
                              validation.errors.cheque_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.cheque_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <div className=" mb-2">
                              <Label htmlFor="date" className="mt-2">
                                Date
                              </Label>

                              <input
                                type="date"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                className={`form-control ${
                                  validation.touched.date &&
                                  validation.errors.date
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="date"
                                value={validation.values.date || ""}
                              />
                              {validation.touched.date &&
                              validation.errors.date ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.date}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {/* {billingDetails.length === 0 ? ( */}
                    {billingDetails.length === 1 ? (
                      <p className="text-center">No Record found</p>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Invoice</th>
                            <th>Invoice Amount</th>

                            <th>Oustanding Amount</th>

                            <th>Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(validation?.values?.paymentData || []).map(
                            (payment, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{payment.invoice_no}</td>
                                <td>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`invoice_amount_${index}`}
                                      name={`paymentData.${index}.invoice_amount`}
                                      disabled
                                      value={
                                        validation.values.paymentData[index]
                                          ?.invoice_amount || ""
                                      }
                                      className={`form-control ${
                                        validation.touched.paymentData?.[index]
                                          ?.invoice_amount &&
                                        validation.errors.paymentData?.[index]
                                          ?.invoice_amount
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                   
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`outstanding_amount_${index}`}
                                      name={`paymentData.${index}.outstanding_amount`}
                                      disabled
                                      value={
                                        parseFloat(validation.values.paymentData?.[index]?.outstanding_amount || 0) -
                                        parseFloat(validation.values.paymentData?.[index]?.amount_paid || 0)
                                      }
                                      
                                      className={`form-control ${
                                        validation.touched.paymentData?.[index]
                                          ?.outstanding_amount &&
                                        validation.errors.paymentData?.[index]
                                          ?.outstanding_amount
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                   
                                  </div>
                                </td>
                                
                                <td>
                                  <div className="mb-3">
                                  <Input
                                      type="text"
                                      id={`amount_${index}`}
                                      name={`paymentData.${index}.amount_paid`}
                                      onChange={validation.handleChange}
                                      onBlur = {validation.handleBlur}
                                      value={
                                        validation.values.paymentData[index]
                                          ?.amount_paid || ""
                                      }
                                      className={`form-control ${
                                        validation.touched.paymentData?.[index]
                                          ?.amount_paid &&
                                        validation.errors.paymentData?.[index]
                                          ?.amount_paid
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    {validation.touched.paymentData?.[index]
                                          ?.amount_paid &&
                                          validation.errors.paymentData?.[index]
                                          ?.amount_paid ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.paymentData?.[index].amount_paid}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button
                    type="submit"
                    className="btn btn-primary w-md me-2"
                    style={{ width: "10%" }}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddNewPayment)

AddNewPayment.propTypes = {
  history: Proptypes.object,
}
