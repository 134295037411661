import React, { useEffect } from "react"
import getChartColorsArray from "components/Common/ChartsDynamicColor"
import ReactEcharts from "echarts-for-react"
import * as Yup from "yup"

import { Col, CardBody, FormFeedback, Row } from "reactstrap"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { getServiceRequestStatusPieDigram } from "store/actions"

const pieChart = () => {
  const dispatch = useDispatch()

  const dataColors =
    '["--bs-secondary","--bs-primary", "--bs-warning","--bs-success","--bs-danger"]'
  const PieEChartColors = getChartColorsArray(dataColors)

  const dataState = state => state.serviceRequestDashboard?.requestsPieDigram
  const data = useSelector(dataState) || []

  const validation = useFormik({
    initialValues: {
      filter: "This Year",
    },
    validationSchema: Yup.object({}),
  })

  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["Open", "Ticket Created", "In Progress", "Closed", "Declined"],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: PieEChartColors,
    series: [
      {
        name: "Service Requests",
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        data: [
          {
            value: data && data.open_status !== 0 ? data.open_status : null,
            name: "Open",
          },
          {
            value:
              data && data.ticket_created_status !== 0
                ? data.ticket_created_status
                : null,
            name: "Ticket Created",
          },
          {
            value:
              data && data.in_progess_ticket !== 0
                ? data.in_progess_ticket
                : null,
            name: "In Progress",
          },
          {
            value:
              data && data.ticket_close_status !== 0
                ? data.ticket_close_status
                : null,
            name: "Closed",
          },
          {
            value:
              data && data.declined_status !== 0 ? data.declined_status : null,
            name: "Declined",
          },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  useEffect(() => {
    dispatch(
      getServiceRequestStatusPieDigram({ filter: validation.values.filter })
    )
  }, [validation.values.filter])
  return (
    <CardBody>
      <Row>
        <Col className="d-flex align-items-center">
          <h4 className="card-title mb-4">
            Statistic Analysis of Service Request By Status
          </h4>
        </Col>
        <Col lg={3}>
          <div className="mb-3">
            <select
              name="filter"
              className={`form-select ${
                validation.touched.filter && validation.errors.filter
                  ? "is-invalid"
                  : ""
              }`}
              onChange={validation.handleChange} // Ensure onChange is linked to handleChange
              onBlur={validation.handleBlur}
              value={validation.values.filter} // Ensure value is bound to Formik's values.filter
            >
              <option value="This Week">This Week</option>
              <option value="This Month">This Month</option>
              <option value="This Year">This Year</option>
              <option value="Previous Week">Previous Week</option>
              <option value="Previous Month">Previous Month</option>
              <option value="Previous Year">Previous Year</option>
            </select>
            {validation.touched.filter && validation.errors.filter ? (
              <FormFeedback type="invalid">
                {validation.errors.filter}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <Col>
        <ReactEcharts style={{ height: "350px" }} option={options} />
      </Col>
      <Col className="text-center">
        <b style={{color:"gray", fontSize:"12px"}}>{validation.values.filter}</b>
      </Col>
    </CardBody>
  )
}

export default pieChart
