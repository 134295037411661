import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
  getVendorDetail,
  updateVendor,
  addNewVendor,
} from "store/masters/vendor/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"

const NewVendorService = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const vendorState = state => state.vendor.vendorDetail.data
  const loadingState = state => state.vendor.vendorDetail.loading
  const [isEditMode, setIsEditMode] = useState(false)
  const vendorsProperties = createSelector(
    vendorState,
    loadingState,
    (vendors, loading) => {
      return {
        vendors: vendors,
        loading: loading,
      }
    }
  )
  const { vendors, loading } = useSelector(vendorsProperties)
  const [vendorId, setFacilityId] = useState()
  const [isLoading, setLoading] = useState(loading)

  const vendorData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const vendorId = url.pathname.split("/edit-vendor/")[1]
      setFacilityId(vendorId)

      dispatch(getVendorDetail(vendorId))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      vendorData()
    }
  }, [dispatch])

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    }
  }, [location.pathname])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode
      ? {
          service_code: (vendors && vendors.service_code) || "",
          vendor_type: (vendors && vendors.vendor_type) || "",
          service_name: (vendors && vendors.service_name) || "",
          is_active: Boolean(vendors && vendors.is_active),
        }
      : {
          service_code: "",
          vendor_type: "",
          service_name: "",
          is_active: false,
        },
    validationSchema: Yup.object({
      service_code: Yup.string().required("Please Enter Your Service Code"),
      vendor_type: Yup.string().required("Please Select Vendor type"),
      service_name: Yup.string().required("Please Enter Service Name"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      if (isEditMode === true) {
        dispatch(updateVendor(vendorId, values, props.router.navigate))
      } else if (isEditMode === false) {
        dispatch(addNewVendor(values, props.router.navigate))
      }
    },
  })

  return (
    <div className="page-content">
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <>
          <Row>
            <Col>
              <div className="d-flex gap-2 mb-3">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey", marginRight: "15px" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>

                <span
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  {" "}
                  {isEditMode
                    ? "Edit Vendor Service"
                    : "New Vendor Service"}{" "}
                </span>
              </div>
            </Col>
            <Col>
              <Breadcrumbs title="Settings / Master configuration / Vendor Master" />
            </Col>
          </Row>
          <div className="mt-3"></div>
          <Card>
            <CardBody>
              <Form
                autoComplete="off"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-service_code-Input">
                        Service Code*
                      </Label>
                      <Input
                        type="text"
                        name="service_code"
                        className="form-control"
                        id="formrow-service_code-Input"
                        placeholder="Enter Service Code"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.service_code || ""}
                        invalid={
                          validation.touched.service_code &&
                          validation.errors.service_code
                            ? true
                            : false
                        }
                      />
                      {validation.touched.service_code &&
                      validation.errors.service_code ? (
                        <FormFeedback type="invalid">
                          {validation.errors.service_code}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-vendor_type-Input">
                        Vendor type*
                      </Label>
                      <select
                        name="vendor_type"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.vendor_type || ""}
                        className={`form-select ${
                          validation.touched.vendor_type &&
                          validation.errors.vendor_type
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        {!validation.values.vendor_type && (
                          <option>Select Vendor type</option>
                        )}
                        <option value="OEM Vendor">OEM Vendor</option>
                        <option value="Manpower Vendor">Manpower Vendor</option>
                        <option value="Service Vendor">Service Vendor</option>
                        <option value="Product Vendor">Product Vendor</option>
                      </select>
                      {validation.touched.vendor_type &&
                      validation.errors.vendor_type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.vendor_type}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-service_name-Input">
                        Service Name*
                      </Label>
                      <Input
                        type="text"
                        name="service_name"
                        className="form-control"
                        id="formrow-service_name-Input"
                        placeholder="Enter Service Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.service_name || ""}
                        invalid={
                          validation.touched.service_name &&
                          validation.errors.service_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.service_name &&
                      validation.errors.service_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.service_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-2"></div>
                    <div>
                      <Label htmlFor="formrow-ispaid-Input">Is Active</Label>
                    </div>

                    <Col lg="12">
                      <div className="form-check form-check-inline">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="is_active"
                          name="is_active"
                          onChange={e => {
                            validation.setFieldValue(
                              "is_active",
                              e.target.checked
                            )
                          }}
                          checked={Boolean(validation?.values?.is_active)}
                        />
                        <Label className="form-check-Label" htmlFor="is-Active">
                          <h6>Yes</h6>
                        </Label>
                      </div>
                      {validation.touched.is_active &&
                        validation.errors.is_active && (
                          <FormFeedback type="invalid">
                            {validation.errors.is_active}
                          </FormFeedback>
                        )}
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3 text-end">
                      <button type="submit" className="btn btn-primary w-md">
                        {isEditMode ? "Edit" : "Submit"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  )
}

export default withRouter(NewVendorService)

NewVendorService.propTypes = {
  history: PropTypes.object,
}
