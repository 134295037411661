import React, { useEffect, useState } from "react"
import { Row, Col, Label, FormFeedback, Alert, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getAllProjects, getProjectOccupancyDetail } from "store/actions"
import { useFormik } from "formik"
import {
  downloadGstR1Excell,
  downloadOccupancyExcell,
} from "helpers/fakebackend_helper"
import * as XLSX from "xlsx"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"
import IsOccupiedModal from "./projectOccupancyModal/isOccupiedModal"
import IsNotOccupiedModal from "./projectOccupancyModal/isNotOccupiedModal"

const ProjectOccupancyReports = props => {
  document.title = "Project Occupancy Report"
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dispatch = useDispatch()
  const allProjectState = state => state.Reports.allProjects.project
  const allProjects = useSelector(allProjectState)
  const projectOccupancyDetailState = state =>
    state.Reports.projectOccupancyDetail.data

  const projectOccupancyDetail = useSelector(projectOccupancyDetailState)
  console.log(allProjects)
  console.log(projectOccupancyDetail)

  const validation = useFormik({
    initialValues: {
      project_id: "",
    },
  })
  useEffect(() => {
    dispatch(getAllProjects(user.user_id))
  }, [dispatch])

  useEffect(() => {
    if (validation.values.project_id !== "") {
      dispatch(getProjectOccupancyDetail(validation.values.project_id))
    }
  }, [dispatch, validation.values.project_id])

  const floors = projectOccupancyDetail?.floors
  const highestUnitCount = projectOccupancyDetail?.highest_unit_count

  const [modalLarge, setModalLarge] = useState(false)
  const [unitId, setUnitId] = useState()
  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const [modalLarge2, setModalLarge2] = useState(false)
  const toggleModalLarge2 = () => setModalLarge2(!modalLarge2)

  const handleIsOccupiedModal = value => {
    setUnitId(value)
    toggleModalLarge()
  }

  const handleIsNotOccupiedModal = value => {
    setUnitId(value)
    toggleModalLarge2()
  }

  return (
    <React.Fragment>
      <IsOccupiedModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        unitId={unitId}
      />
      <IsNotOccupiedModal
        isOpen={modalLarge2}
        toggle={toggleModalLarge2}
        unitId={unitId}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row className="mb-3 justify-content-end">
            <Col className="mt-2">
              <h4 className="card-title mb-4">Floor-wise Occupancy</h4>
            </Col>
            <Col className="text-end mt-2">
              <Label className="form-label font-size-14">
                Select Project :
              </Label>
            </Col>
            <Col lg={2}>
              <select
                name="project_id"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.project_id}
                className={`form-select ${
                  validation.touched.project_id && validation.errors.project_id
                    ? "is-invalid"
                    : ""
                }`}
              >
                {!validation.values.project_id && (
                  <option>Select Project</option>
                )}
                {(allProjects || []).map((data, index) => (
                  <option key={index} value={data.id}>
                    {data.project_name}
                  </option>
                ))}
              </select>
              {validation.touched.project_id && validation.errors.project_id ? (
                <FormFeedback type="invalid">
                  {validation.errors.project_id}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {floors && floors.length > 0 ? (
                    <div className="table-responsive">
                      <div>
                        <h5>Total Occupied : {projectOccupancyDetail?.total_occupied_count} &nbsp; &nbsp; Free Counter : {projectOccupancyDetail?.total_unoccupied_count}</h5>
                      </div>
                      <SimpleBar
                        style={{ maxHeight: "60vh", overflowX: "auto" }}
                      >
                        <div style={{ minWidth: "100%" }}>
                          <table
                            className="table table-bordered mb-0"
                            style={{ backgroundColor: "#CCCCCC" }}
                          >
                            <thead>
                              <tr
                                style={{
                                  border: "1px solid #BEBEBE",
                                }}
                              >
                                <th
                                  colSpan={1}
                                  // style={{ backgroundColor: "#333" }} TO TABLE HEADING BACKGROUND COLOR
                                >
                                  Floor No
                                </th>
                                <th
                                  colSpan={highestUnitCount}
                                  // style={{ backgroundColor: "#333" }} TO CHANGE TABLE HEADING BACKGROUND COLOR
                                >
                                  Units
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {floors.map(floor => (
                                <tr
                                  key={floor.floor_no}
                                  style={{
                                    border: "1px solid #BEBEBE",
                                  }}
                                >
                                  <td
                                    style={{
                                      border: "1px solid #BEBEBE",
                                      padding: "8px",
                                      // backgroundColor: "#666", TO CHANGE SERIAL NUMBER BACKGROUND COLOR
                                    }}
                                  >
                                    {floor.floor_no}
                                  </td>
                                  {floor.units.map(unit => (
                                    <td 
                                    onClick={() => {
                                      if (unit.is_occupied === 1) {
                                        handleIsOccupiedModal(unit.cid)
                                      } else {
                                        handleIsNotOccupiedModal(unit.pid)
                                      }
                                    }}

                                      key={unit.unit_code}
                                      style={{
                                        cursor:'pointer',
                                        border: "1px solid #BEBEBE",
                                        backgroundColor:
                                          unit.is_occupied === 1
                                            ? "#FF7F7F"
                                            : unit.is_occupied === 0
                                            ? "#57FF86"
                                            : "Gray",
                                        color:
                                          unit.is_occupied === 1
                                            ? "white"
                                            : unit.is_occupied === 0
                                            ? "black"
                                            : "Gray",
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundColor:
                                            unit.is_occupied === 1
                                              ? "#FF7F7F"
                                              : unit.is_occupied === 0
                                              ? "#57FF86"
                                              : "Gray",
                                          color:
                                            unit.is_occupied === 1
                                              ? "white"
                                              : unit.is_occupied === 0
                                              ? "black"
                                              : "Gray",
                                          padding: "8px",
                                          margin: "2px",
                                          borderRadius: "5px",
                                          display: "block",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Link
                                          className="text-dark"
                                          
                                        >
                                          {unit.unit_code}
                                        </Link>
                                      </span>
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </SimpleBar>
                    </div>
                  ) : (
                    <Alert color="warning" role="alert">
                      Error: Insufficient data available for this project.
                      Please ensure that all necessary data sources are properly
                      configured and accessible.
                    </Alert>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProjectOccupancyReports
