import {
    GET_EXPENSES,
    GET_EXPENSES_FAIL,
    GET_EXPENSES_SUCCESS,
    GET_EXPENSE_DETAIL,
    ADD_NEW_EXPENSE,
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAIL,
    UPDATE_EXPENSE,
    UPDATE_EXPENSE_SUCCESS,
    UPDATE_EXPENSE_FAIL,
    DELETE_EXPENSE,
    DELETE_EXPENSE_SUCCESS,
    DELETE_EXPENSE_FAIL,
    GET_EXPENSE_DETAIL_FAIL,
    GET_EXPENSE_DETAIL_SUCCESS,
  } from "./actionTypes"
  
  export const getExpenses = () => ({
    type: GET_EXPENSES,
  })
  
  export const getExpensesSuccess = expenses => ({
    type: GET_EXPENSES_SUCCESS,
    payload: expenses,
  })
  
  export const addNewExpense = expense => ({
    type: ADD_NEW_EXPENSE,
    payload: expense,
  })
  
  export const addExpenseSuccess = expense => ({
    type: ADD_EXPENSE_SUCCESS,
    payload: expense,
  })
  
  export const addExpenseFail = error => ({
    type: ADD_EXPENSE_FAIL,
    payload: error,
  })
  
  export const updateExpense = (id,expense) => ({
    type: UPDATE_EXPENSE,
    payload: {id,expense},
  })
  
  export const updateExpenseSuccess = expense => ({
    type: UPDATE_EXPENSE_SUCCESS,
    payload: expense,
  })
  
  export const updateExpenseFail = error => ({
    type: UPDATE_EXPENSE_FAIL,
    payload: error,
  })
  
  export const deleteExpense = expense => ({
    type: DELETE_EXPENSE,
    payload: expense,
  })
  
  export const deleteExpenseSuccess = expense => ({
    type: DELETE_EXPENSE_SUCCESS,
    payload: expense,
  })
  
  export const deleteExpenseFail = error => ({
    type: DELETE_EXPENSE_FAIL,
    payload: error,
  })
  
  export const getExpensesFail = error => ({
    type: GET_EXPENSES_FAIL,
    payload: error,
  })
  
  export const getExpenseDetail = expenseId => ({
    type: GET_EXPENSE_DETAIL,
    expenseId,
  })
  
  export const getExpenseDetailSuccess = expenseDetails => ({
    type: GET_EXPENSE_DETAIL_SUCCESS,
    payload: expenseDetails,
  })
  
  export const getExpenseDetailFail = error => ({
    type: GET_EXPENSE_DETAIL_FAIL,
    payload: error,
  })
  