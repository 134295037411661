import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledCollapse,
  Label,
  Input,
  Toast,
  ToastBody,
  ToastHeader,
  FormFeedback,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import FileList from "VipPages/UserManagement/components/documentView"

import classnames from "classnames"
import PastEmploymentView from "./components/pastEmploymentView"
import ProfileView from "./components/profileView"
import AssignedCustomers from "./components/assignedCustomers"
import { deleteUser, getUserDetail, getUsers } from "store/user/actions"
// import { getUserProfile } from "api"
import { useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { reinvite, resetUserPassword } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"
import DeleteModal from "components/Common/DeleteModal"
import * as Yup from "yup"
import { useFormik } from "formik"

const UserProfile = () => {
  document.title = "User Profile"

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [modal_center, setmodal_center] = useState(false)
  const [modal_center2, setmodal_center2] = useState(false)
  const [toast2, setToast2] = useState(false)
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [loading, setLoading] = useState(true)
  const [userId, setCompanyId] = useState()

  const userDetail = state => state.users.userDetail.user
  const userProfile = useSelector(userDetail)

  const userProperties = createSelector(userDetail, getUserDetail => ({
    error: getUserDetail.error,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter New Password"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please Re-Enter Password"),
    }),
  })
  useEffect(() => {
    const fetchData = async () => {
      const url = new URL(window.location.href)
      const userId = url.pathname.split("/users/user-details/")[1]

      try {
        dispatch(getUserDetail(userId))
        setCompanyId(userId)

        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  const handleResetPassword = async () => {
    try {
      const loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })

      const response = await resetUserPassword(userId, validation.values)

      // Close loading spinner
      loadingSwal.close()

      if (response && response.status === true) {
        setmodal_center(!modal_center)
        validation.resetForm()
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "User Password has been Updated.",
        })
      } else {
        Swal.fire({
          title: "Failure",
          icon: "error",
          text: "There was a problem while reinviting the user.",
        })
      }
    } catch (error) {
      console.error("Error:", error)
      Swal.fire({
        title: "Error",
        icon: "error",
        text:
          error.response.data.message ||
          "An error occurred while reinviting the user.",
      })
    }
  }

  const handleEmail = async () => {
    try {
      const loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })

      const response = await reinvite(userId)

      // Close loading spinner
      loadingSwal.close()

      if (response && response.status === true) {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "User Has Been Reinvited.",
        })
      } else {
        Swal.fire({
          title: "Failure",
          icon: "error",
          text: "There was a problem while reinviting the user.",
        })
      }
    } catch (error) {
      console.error("Error:", error)
      Swal.fire({
        title: "Error",
        icon: "error",
        text:
          error.response.data.message ||
          "An error occurred while reinviting the user.",
      })
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [contact, setContact] = useState()

  const onClickDelete = users => {
    setContact(users)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteUser(userId))
    setDeleteModal(false)
    navigate("/users")
    dispatch(getUsers())
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          <CardTitle className="h4" style={{ fontSize: "20px" }}>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              User Profile
            </div>
          </CardTitle>
          <Row>
            <Col lg={12}>
              <Card>
                {userProfile ? (
                  <CardBody>
                    <Row>
                      <div className="d-flex gap-2 ">
                        <div className="mb-3  ">
                          <img
                            src={userProfile.image}
                            // src={userProfile ? userProfile.image : avatar2}
                            alt=""
                            style={{ height: "140px", width: "140px" }}
                            className="avatar-xl rounded-4 img-fluid "
                          />
                        </div>
                        <div className="mb-3  " style={{ marginLeft: "10px" }}>
                          <b>
                            {userProfile?.first_name} {userProfile?.last_name}
                          </b>
                          <h6 className="mb-4">{userProfile.login_username}</h6>

                          <h6 className="text-primary mb-0">
                            {userProfile.contact_no}
                          </h6>
                          <h6 className="text-primary mb-2">
                            {userProfile.email}
                          </h6>

                          <Row className="d-flex  ">
                            <Col className="mt-1">
                              {permission
                                .filter(
                                  item =>
                                    item.page === "/edit-user/:id" &&
                                    item.access === 1
                                )
                                .map((item, index) => (
                                  <Link
                                    key={index}
                                    to={`/users/edit-user/${userProfile.id}`}
                                  >
                                    <button
                                      className="btn btn-soft-light btn-md"
                                      style={{
                                        color: "black",
                                        backgroundColor: "#F6F6F6",
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-pencil"
                                        style={{
                                          color: "#3366cc",
                                          marginRight: "10px",
                                        }}
                                      ></i>{" "}
                                      Edit Profile
                                    </button>
                                  </Link>
                                ))}

                              {permission
                                .filter(
                                  item =>
                                    item.page === "reset-other-user-password" &&
                                    item.access === 1
                                )
                                .map((item, index) => (
                                  <button
                                    key={index}
                                    className="btn btn-soft-light btn-md"
                                    onClick={() => {
                                      tog_center()
                                    }}
                                    style={{
                                      marginLeft: "10px",
                                      color: "black",
                                      fontweight: "bold",
                                      backgroundColor: "#F6F6F6",
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-lock-reset"
                                      style={{
                                        color: "#3366cc",
                                        marginRight: "10px",
                                      }}
                                    ></i>{" "}
                                    Reset User Password
                                  </button>
                                ))}
                              <Modal
                                isOpen={modal_center}
                                toggle={() => {
                                  tog_center()
                                }}
                                centered
                              >
                                <div className="modal-header">
                                  <h5
                                    className="modal-title mt-0"
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Reset Password
                                  </h5>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setmodal_center(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <Row>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        Enter Password
                                      </Label>
                                      <Input
                                        name="password"
                                        className="form-control"
                                        placeholder="Enter New password"
                                        type="password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.password || ""}
                                        invalid={
                                          validation.touched.password &&
                                          validation.errors.password
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.password &&
                                      validation.errors.password ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.password}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Row>
                                  <Row>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        Re-Enter Password
                                      </Label>
                                      <Input
                                        name="confirm_password"
                                        className="form-control"
                                        placeholder="Re-Enter password"
                                        type="password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.confirm_password ||
                                          ""
                                        }
                                        invalid={
                                          validation.touched.confirm_password &&
                                          validation.errors.confirm_password
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.confirm_password &&
                                      validation.errors.confirm_password ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.confirm_password}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="mb-3 text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-primary l-md"
                                          onClick={() => {
                                            handleResetPassword()
                                          }}
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Modal>

                              {/* <button
                                className="btn btn-soft-light btn-md"
                                onClick={() => {
                                  tog_center2()
                                }}
                                style={{
                                  marginLeft: "10px",
                                  color: "black",
                                  fontweight: "bold",
                                  backgroundColor: "#F6F6F6",
                                }}
                              >
                                <i
                                  className="mdi mdi-transfer"
                                  style={{
                                    color: "#3366cc",
                                    marginRight: "10px",
                                  }}
                                ></i>
                                Transfer Data
                              </button> */}

                              {permission
                                .filter(
                                  item =>
                                    item.page === "delete-user" &&
                                    item.access === 1
                                )
                                .map((item, index) => (
                                  <button
                                    key={index}
                                    className="btn btn-soft-light btn-md"
                                    onClick={onClickDelete}
                                    style={{
                                      marginLeft: "10px",
                                      color: "black",
                                      fontweight: "bold",
                                      backgroundColor: "#F6F6F6",
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-delete"
                                      style={{
                                        color: "#3366cc",
                                        marginRight: "10px",
                                      }}
                                    ></i>
                                    Delete User
                                  </button>
                                ))}

                              {permission
                                .filter(
                                  item =>
                                    item.page === "reinvite-link" &&
                                    item.access === 1
                                )
                                .map((item, index) => (
                                  <button
                                    key={index}
                                    className="btn btn-soft-light btn-md"
                                    onClick={handleEmail}
                                    style={{
                                      marginLeft: "10px",
                                      color: "black",
                                      fontweight: "bold",
                                      backgroundColor: "#F6F6F6",
                                    }}
                                  >
                                    <i
                                      className="bx bx-mail-send"
                                      style={{
                                        color: "#3366cc",
                                        marginRight: "10px",
                                      }}
                                    ></i>
                                    Reinvite to join portal
                                  </button>
                                ))}
                              {permission
                                .filter(
                                  item =>
                                    item.page ===
                                      "/user-details/manage-user-permission/:id" &&
                                    item.access === 1
                                )
                                .map((item, index) => (
                                  <Link
                                    key={index}
                                    to={`/users/user-details/manage-user-permission/${userProfile.id}`}
                                  >
                                    <button
                                      className="btn btn-soft-light btn-md"
                                      style={{
                                        marginLeft: "10px",
                                        color: "black",
                                        fontweight: "bold",
                                        backgroundColor: "#F6F6F6",
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-account-group"
                                        style={{
                                          color: "#3366cc",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                      Manage Permission
                                    </button>
                                  </Link>
                                ))}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Row>

                    <Nav tabs className="nav-tabs-custom ">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Profile Details
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        {permission
                          .filter(
                            item =>
                              item.page === "List-documentation" &&
                              item.access === 1
                          )
                          .map((item, index) => (
                            <NavLink
                              key={index}
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2")
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-user"></i>
                              </span>
                              <span className="d-none d-sm-block">
                                Documentation
                              </span>
                            </NavLink>
                          ))}
                      </NavItem>
                      <NavItem>
                        {permission
                          .filter(
                            item =>
                              item.page === "List-pastemployment" &&
                              item.access === 1
                          )
                          .map((item, index) => (
                            <NavLink
                              key={index}
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "3",
                              })}
                              onClick={() => {
                                toggleCustom("3")
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-envelope"></i>
                              </span>
                              <span className="d-none d-sm-block">
                                Past Employment
                              </span>
                            </NavLink>
                          ))}
                      </NavItem>
                    </Nav>

                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <ProfileView userProfile={userProfile} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <FileList />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <PastEmploymentView />
                          </Col>
                        </Row>
                      </TabPane>
                      {/* <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <AssignedCustomers />
                          </Col>
                        </Row>
                      </TabPane> */}
                    </TabContent>
                  </CardBody>
                ) : (
                  <Spinners setLoading={setLoading} />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserProfile
