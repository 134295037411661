export const GET_DEBIT_NOTE_LIST = "GET_DEBIT_NOTE_LIST"
export const GET_DEBIT_NOTE_LIST_SUCCESS = "GET_DEBIT_NOTE_LIST_SUCCESS"
export const GET_DEBIT_NOTE_LIST_FAIL = "GET_DEBIT_NOTE_LIST_FAIL"

export const GET_DEBIT_NOTE_DETAIL = "GET_DEBIT_NOTE_DETAIL"
export const GET_DEBIT_NOTE_DETAIL_SUCCESS = "GET_DEBIT_NOTE_DETAIL_SUCCESS"
export const GET_DEBIT_NOTE_DETAIL_FAIL = "GET_DEBIT_NOTE_DETAIL_FAIL"

export const ADD_NEW_DEBIT_NOTE = "ADD_NEW_DEBIT_NOTE"
export const ADD_NEW_DEBIT_NOTE_SUCCESS = "ADD_NEW_DEBIT_NOTE_SUCCESS"
export const ADD_NEW_DEBIT_NOTE_FAIL = "ADD_NEW_DEBIT_NOTE_FAIL"

export const UPDATE_DEBIT_NOTE = "UPDATE_DEBIT_NOTE"
export const UPDATE_DEBIT_NOTE_SUCCESS = "UPDATE_DEBIT_NOTE_SUCCESS"
export const UPDATE_DEBIT_NOTE_FAIL = "UPDATE_DEBIT_NOTE_FAIL"

export const DELETE_DEBIT_NOTE = "DELETE_DEBIT_NOTE"
export const DELETE_DEBIT_NOTE_SUCCESS = "DELETE_DEBIT_NOTE_SUCCESS"
export const DELETE_DEBIT_NOTE_FAIL = "DELETE_DEBIT_NOTE_FAIL"

export const GET_VENDOR_INVOICE_DETAIL = "GET_VENDOR_INVOICE_DETAIL"
export const GET_VENDOR_INVOICE_DETAIL_SUCCESS = "GET_VENDOR_INVOICE_DETAIL_SUCCESS"
export const GET_VENDOR_INVOICE_DETAIL_FAIL = "GET_VENDOR_INVOICE_DETAIL_FAIL"
