import React, { useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"

const ProfileView = props => {
  const { userProfile } = props
  return (
    <React.Fragment>
      <Container fluid={true}>
        {userProfile && (
          <Row>
            <Col>
              <CardTitle className="mb-3">Office Details</CardTitle>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Employee code </Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {userProfile.employee_code}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">User Role </Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {userProfile.role}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Login Username</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {userProfile.login_username}
                  </p>
                </Col>
              </Row>
            </Col>
            <hr />
            <Col>
              <CardTitle className="mb-3">Personal Details</CardTitle>

              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">First name </Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {userProfile.first_name}{" "}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Middle Name</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {userProfile.middle_name}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Last name</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {userProfile.last_name}
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">Address</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {userProfile.address}
                  </p>
                </Col>
                {/* <Col lg={4}>
                <Label className="form-label">Address Line 2</Label>
                    <p className="text-dark" style={{fontWeight:600}}> Richmond Road, Bangalore</p>
                </Col> */}
                <Col lg={4}>
                  <Label className="form-label">Country</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {userProfile.country_name}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">State</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {userProfile.state_name}
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">City</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {userProfile.city_name}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Pincode</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {userProfile.pincode}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Project Access</Label>
                  <div>
                    <span className="text-dark" style={{ fontWeight: 600 }}>
                      {userProfile.project_names.join(", ")}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
            <hr />
            <Col>
              <CardTitle className="mb-3">Social Details</CardTitle>

              <Row className="mb-3">
                <Col lg={4}>
                  <Label className="form-label">PAN No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {userProfile.pan_no}
                  </p>
                </Col>
                <Col lg={4}>
                  <Label className="form-label">Aadhar No.</Label>
                  <p className="text-dark" style={{ fontWeight: 600 }}>
                    {" "}
                    {userProfile.aadhar_no}
                  </p>
                </Col>
              </Row>
            </Col>
            <hr />
          </Row>
        )}
      </Container>
    </React.Fragment>
  )
}

export default ProfileView
