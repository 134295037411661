export const GET_PROPOSAL_TYPE_MASTER_LIST = "GET_PROPOSAL_TYPE_MASTER_LIST"
export const GET_PROPOSAL_TYPE_MASTER_LIST_SUCCESS = "GET_PROPOSAL_TYPE_MASTER_LIST_SUCCESS"
export const GET_PROPOSAL_TYPE_MASTER_LIST_FAIL = "GET_PROPOSAL_TYPE_MASTER_LIST_FAIL"

export const GET_PROPOSAL_TYPE_MASTER_DETAIL = "GET_PROPOSAL_TYPE_MASTER_DETAIL"
export const GET_PROPOSAL_TYPE_MASTER_DETAIL_SUCCESS = "GET_PROPOSAL_TYPE_MASTER_DETAIL_SUCCESS"
export const GET_PROPOSAL_TYPE_MASTER_DETAIL_FAIL = "GET_PROPOSAL_TYPE_MASTER_DETAIL_FAIL"

export const ADD_NEW_PROPOSAL_TYPE_MASTER = "ADD_NEW_PROPOSAL_TYPE_MASTER"
export const ADD_NEW_PROPOSAL_TYPE_MASTER_SUCCESS = "ADD_NEW_PROPOSAL_TYPE_MASTER_SUCCESS"
export const ADD_NEW_PROPOSAL_TYPE_MASTER_FAIL = "ADD_NEW_PROPOSAL_TYPE_MASTER_FAIL"

export const UPDATE_PROPOSAL_TYPE_MASTER = "UPDATE_PROPOSAL_TYPE_MASTER"
export const UPDATE_PROPOSAL_TYPE_MASTER_SUCCESS = "UPDATE_PROPOSAL_TYPE_MASTER_SUCCESS"
export const UPDATE_PROPOSAL_TYPE_MASTER_FAIL = "UPDATE_PROPOSAL_TYPE_MASTER_FAIL"

export const DELETE_PROPOSAL_TYPE_MASTER = "DELETE_PROPOSAL_TYPE_MASTER"
export const DELETE_PROPOSAL_TYPE_MASTER_SUCCESS = "DELETE_PROPOSAL_TYPE_MASTER_SUCCESS"
export const DELETE_PROPOSAL_TYPE_MASTER_FAIL = "DELETE_PROPOSAL_TYPE_MASTER_FAIL"
