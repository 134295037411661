import {
  GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_FAIL,
  GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS,
  GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_FAIL,
  GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_SUCCESS,
  GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_FAIL,
  GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_FAIL,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_SUCCESS,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_FAIL,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  companyFilter: [],

  vendorFilter: [],
  projectFilter: [],
  vendorFilterList: [],
  projectFilterList: [],
  billHistory: [],
  paymentHistory: [],

  error: {},
  loading: true,
}

const VendorPaymentList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_SUCCESS:
      return {
        ...state,
        companyFilter: action.payload,
        loading: true,
      }

    case GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_SUCCESS:
      return {
        ...state,
        projectFilter: action.payload,
        loading: true,
      }

    case GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_SUCCESS:
      return {
        ...state,
        vendorFilter: action.payload,
        loading: true,
      }

    case GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS:
      return {
        ...state,
        billHistory: action.payload,
        loading: true,
      }

    case GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentHistory: action.payload,
        loading: true,
      }

    case GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        projectFilterList: action.payload,
        loading: true,
      }

    case GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        vendorFilterList: action.payload,
        loading: true,
      }

    case GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default VendorPaymentList
