/* UNITS */
export const GET_UNITS = "GET_UNITS"
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS"
export const GET_UNITS_FAIL = "GET_UNITS_FAIL"
/* ACTIVE UNITS */
export const GET_ACTIVE_UNITS = "GET_ACTIVE_UNITS"
export const GET_ACTIVE_UNITS_SUCCESS = "GET_ACTIVE_UNITS_SUCCESS"
export const GET_ACTIVE_UNITS_FAIL = "GET_ACTIVE_UNITS_FAIL"

/* UNITS DETAIL*/
export const GET_UNIT_DETAIL = "GET_UNIT_DETAIL"
export const GET_UNIT_DETAIL_SUCCESS = "GET_UNIT_DETAIL_SUCCESS"
export const GET_UNIT_DETAIL_FAIL = "GET_UNIT_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_UNIT = "ADD_NEW_UNIT"
 export const ADD_UNIT_SUCCESS = "ADD_UNIT_SUCCESS"
 export const ADD_UNIT_FAIL = "ADD_UNIT_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_UNIT = "UPDATE_UNIT"
 export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS"
 export const UPDATE_UNIT_FAIL = "UPDATE_UNIT_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_UNIT = "DELETE_UNIT"
 export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS"
 export const DELETE_UNIT_FAIL = "DELETE_UNIT_FAIL"
