/* TICKETS */
export const GET_TICKETS = "GET_TICKETS"
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS"
export const GET_TICKETS_FAIL = "GET_TICKETS_FAIL"

/* TICKETS DETAIL*/
export const GET_TICKET_DETAIL = "GET_TICKET_DETAIL"
export const GET_TICKET_DETAIL_SUCCESS = "GET_TICKET_DETAIL_SUCCESS"
export const GET_TICKET_DETAIL_FAIL = "GET_TICKET_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_TICKET = "ADD_NEW_TICKET"
 export const ADD_TICKET_SUCCESS = "ADD_TICKET_SUCCESS"
 export const ADD_TICKET_FAIL = "ADD_TICKET_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_TICKET = "UPDATE_TICKET"
 export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS"
 export const UPDATE_TICKET_FAIL = "UPDATE_TICKET_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_TICKET = "DELETE_TICKET"
 export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS"
 export const DELETE_TICKET_FAIL = "DELETE_TICKET_FAIL"
