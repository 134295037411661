import {
    GET_UNITS,
    GET_UNITS_FAIL,
    GET_UNITS_SUCCESS,
    GET_UNIT_DETAIL,
    ADD_NEW_UNIT,
    ADD_UNIT_SUCCESS,
    ADD_UNIT_FAIL,
    UPDATE_UNIT,
    UPDATE_UNIT_SUCCESS,
    UPDATE_UNIT_FAIL,
    DELETE_UNIT,
    DELETE_UNIT_SUCCESS,
    DELETE_UNIT_FAIL,
    GET_UNIT_DETAIL_FAIL,
    GET_UNIT_DETAIL_SUCCESS,
    GET_ACTIVE_UNITS,
    GET_ACTIVE_UNITS_SUCCESS,
    GET_ACTIVE_UNITS_FAIL,
  } from "./actionTypes"
  
  export const getUnits = () => ({
    type: GET_UNITS,
  })
  
  export const getUnitsSuccess = units => ({
    type: GET_UNITS_SUCCESS,
    payload: units,
  })
  export const getActiveUnits = () => ({
    type: GET_ACTIVE_UNITS,
  })
  
  export const getActiveUnitsSuccess = units => ({
    type: GET_ACTIVE_UNITS_SUCCESS,
    payload: units,
  })
  export const getActiveUnitsFail = error => ({
    type: GET_ACTIVE_UNITS_FAIL,
    payload: error,
  })
  
  export const addNewUnit = unit => ({
    type: ADD_NEW_UNIT,
    payload: unit,
  })
  
  export const addUnitSuccess = unit => ({
    type: ADD_UNIT_SUCCESS,
    payload: unit,
  })
  
  export const addUnitFail = error => ({
    type: ADD_UNIT_FAIL,
    payload: error,
  })
  
  export const updateUnit = (id,unit) => ({
    type: UPDATE_UNIT,
    payload: {id,unit},
  })
  
  export const updateUnitSuccess = unit => ({
    type: UPDATE_UNIT_SUCCESS,
    payload: unit,
  })
  
  export const updateUnitFail = error => ({
    type: UPDATE_UNIT_FAIL,
    payload: error,
  })
  
  export const deleteUnit = unit => ({
    type: DELETE_UNIT,
    payload: unit,
  })
  
  export const deleteUnitSuccess = unit => ({
    type: DELETE_UNIT_SUCCESS,
    payload: unit,
  })
  
  export const deleteUnitFail = error => ({
    type: DELETE_UNIT_FAIL,
    payload: error,
  })
  
  export const getUnitsFail = error => ({
    type: GET_UNITS_FAIL,
    payload: error,
  })
  
  export const getUnitDetail = unitId => ({
    type: GET_UNIT_DETAIL,
    unitId,
  })
  
  export const getUnitDetailSuccess = unitDetails => ({
    type: GET_UNIT_DETAIL_SUCCESS,
    payload: unitDetails,
  })
  
  export const getUnitDetailFail = error => ({
    type: GET_UNIT_DETAIL_FAIL,
    payload: error,
  })
  