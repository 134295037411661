import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { Card, Col, CardBody } from "reactstrap"
import { getCountForDashboard } from "store/actions"
import { getCountForServiceRequestDashboard } from "store/serviceRequestDashboard/actions"

const typeOfRequest = () => {


  const dispatch = useDispatch()
  const countState = state => state.Dashboard.count
  const count = useSelector(countState) || []

  
  useEffect(() => {
    dispatch(getCountForDashboard())
  }, [dispatch])
  return (
    <React.Fragment>
      {/* {(blodStatsData || []).map((stat, index) => ( */}
      {/* <Col lg={4} key={index}> */}
      <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/service-requests/all-service-requests">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">All Service Requests</p>
                      <h5 className="mb-0 text-primary">
                        {count && count.all_count_service_request_status}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/service-requests/pending-service-requests">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Pending Service Requests
                      </p>
                      <h5 className="mb-0 text-warning">
                        {count && count.Pending_Status_Count}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-warning font-size-20">
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/service-requests/inprogress-service-requests">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Accepted Service Request
                      </p>
                      <h5 className="mb-0 text-danger">
                        {count && count.Accept_Status_Count}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-danger font-size-20">
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/service-requests/closed-service-request">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Rejected Service Request
                      </p>
                      <h5 className="mb-0 text-success">
                        {count && count.Reject_Status_Count}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
      {/* ))} */}
    </React.Fragment>
  )
}

export default typeOfRequest
