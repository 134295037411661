import {
    GET_VENDORS,
    GET_VENDORS_FAIL,
    GET_VENDORS_SUCCESS,
    GET_VENDOR_DETAIL,
    ADD_NEW_VENDOR,
    ADD_VENDOR_SUCCESS,
    ADD_VENDOR_FAIL,
    UPDATE_VENDOR,
    UPDATE_VENDOR_SUCCESS,
    UPDATE_VENDOR_FAIL,
    DELETE_VENDOR,
    DELETE_VENDOR_SUCCESS,
    DELETE_VENDOR_FAIL,
    GET_VENDOR_DETAIL_FAIL,
    GET_VENDOR_DETAIL_SUCCESS,
  } from "./actionTypes"
  
 

  export const getVendors = () => ({
    type: GET_VENDORS,
  })
  
  export const getVendorsSuccess = vendors => ({
    type: GET_VENDORS_SUCCESS,
    payload: vendors,
  })
  
  export const addNewVendor = (vendor,history) => ({
    type: ADD_NEW_VENDOR,
    payload:{vendor,history},
    status: true
  })
  
  export const addVendorSuccess = vendor => ({
    type: ADD_VENDOR_SUCCESS,
    payload: vendor 
  })
  
  export const addVendorFail = error => ({
    type: ADD_VENDOR_FAIL,
    payload: error,
  })
  
  export const updateVendor = (id,vendor,history) => ({
    type: UPDATE_VENDOR,
    payload: {id,vendor,history},
  })
  
  export const updateVendorSuccess = vendor => ({
    type: UPDATE_VENDOR_SUCCESS,
    payload: vendor,
  })
  
  export const updateVendorFail = error => ({
    type: UPDATE_VENDOR_FAIL,
    payload: error,
  })
  
  export const deleteVendor = vendor => ({
    type: DELETE_VENDOR,
    payload: vendor,
  })
  
  export const deleteVendorSuccess = vendor => ({
    type: DELETE_VENDOR_SUCCESS,
    payload: vendor,
  })
  
  export const deleteVendorFail = error => ({
    type: DELETE_VENDOR_FAIL,
    payload: error,
  })
  
  export const getVendorsFail = error => ({
    type: GET_VENDORS_FAIL,
    payload: error,
  })
  
  export const getVendorDetail = vendorId => ({
    type: GET_VENDOR_DETAIL,
    vendorId,
  })
  
  export const getVendorDetailSuccess = vendorDetails => ({
    type: GET_VENDOR_DETAIL_SUCCESS,
    payload: vendorDetails,
  })
  
  export const getVendorDetailFail = error => ({
    type: GET_VENDOR_DETAIL_FAIL,
    payload: error,
  })
  