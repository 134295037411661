
const obj = JSON.parse(localStorage.getItem("authUser"))
let accessToken = null;

if (obj && obj.access_token) {
  const Authorization = obj.access_token;
  accessToken = `Bearer ${Authorization}`;
}

export default accessToken;






