import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Toast,
  ToastHeader,
  ToastBody,
  navigate,
  FormFeedback,
} from "reactstrap"
import classnames from "classnames"
import { error } from "toastr"

import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
// import { addNewClient } from "store/actions"
import Swal from "sweetalert2"
import { addNewClient } from "helpers/fakebackend_helper"
import { getCities, getCountries, getStates } from "store/actions"

const VendorsContacts = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const dispatch = useDispatch()
  const customerState = state => state.tickets.ticketDetail.Data
  // const loadingState = state => state.facilities.loading
  const data = useSelector(customerState)
  // const loadingData = useSelector(loadingState)


  const customerProperties = createSelector(
    customerState,
    // loadingState,
    (customers, loading) => {
      return {
        customers: customers,
        loading: loading,
      }
    }
  )

  const { customers, loading } = useSelector(customerProperties)

  const [specificUser, setSpecificUser] = useState(null)

  const [customerId, setCustomerId] = useState()

  const [selectedOption, setSelectedOption] = useState("")

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      customer_type: "",
      customer_name: "",
      customer_code: "",
      email_address: "",
      mobile_no: "",
      address: "",
      pincode: "",
      city: "",
      state: "",
      country: "IN",
      pan_no: "",
      aadhar_no: "",
      tan_no: "",
      gst_no: "",
      login_username: "",
      is_active: "",
    },
    validationSchema: Yup.object().shape({
      customer_type: Yup.string().required("Please Select Customer Type"),
      customer_name: Yup.string().required("Please Enter Your Customer's Name"),
      customer_code: Yup.number()
        .typeError("Customer code must be a number")
        .required("Please Enter Customer code"),
      email_address: Yup.string()
        .email("Invalid Email Format")
        .required("Please Enter Email Address"),
      mobile_no: Yup.string()
        .matches(
          /^[0-9]{10,12}$/,
          "Mobile Number must be between 10 to 12 digits"
        )
        .required("Please Enter Mobile Number "),
      address: Yup.string().required("Please Enter Address"),
      pincode: Yup.string()
        .matches(/^\d{6}$/, "Pincode must be a 6-digit number")
        .required("Please Enter Pincode"),
      city: "",
      state: "",
      country: "",
      pan_no: Yup.string()
        .matches(
          /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
          "PAN Number must be in the format: ABCDE1234F"
        )
        .required("Please Enter PAN Number"),
      aadhar_no: Yup.string()
        .matches(/^\d{12}$/, "Aadhar Number must be a 12-digit number")
        .required("Please Enter Aadhar Number"),
      tan_no: Yup.string()
        .matches(
          /^[A-Z]{4}\d{5}[A-Z]$/,
          "TAN Number must be in the format: XXXX12345X"
        )
        .required("Please Enter TAN Number"),
      gst_no: Yup.string()
        .matches(
          /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}\d{1}$/,
          "GST Number must be in the format: 12ABCDE1234F1Z1"
        )
        .required("Please Enter GST Number"),
      login_username: Yup.string().required("Please enter Login Username"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        const response = await addNewClient(values)
        if (response && response.status === true) {
          loadingSwal.close()

          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Client has been Added.",
          }).then(result => {
            if (result.isConfirmed) {
              navigate("/clients")
            }
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "failure",
            icon: "error",
            text: "Your Client has not been saved.",
          })
        }
      } catch (error) {
        // Handle the error if needed
        loadingSwal.close()

        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const navigate = useNavigate()

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  useEffect(() => {
    //check if the current path includes 'edit-customer'
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)
    setToast2(true)
  }

  const handleChange = event => {
    setSelectedOption(event.target.value)

    validation.setFieldValue("is_active", event.target.value)
  }

  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState(validation.values.state)

  const [rows1, setrows1] = useState([{ id: 1 }])
  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows1(modifiedRows)
  }
  function handleRemoveRow(id) {
    if (id !== 1) {
      var modifiedRows = [...rows1]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows1(modifiedRows)
    }
  }
  const [vendors, setVendors] = useState([
    {
      id: 1,
      name: "",
      phone_no: "",
      email_address2: "",
      role: "",
      address2: "",
      pincode: "",
      country: "",
      is_active: 1,
    },
  ])

  const handleChangeVendor = (e, index) => {
    const { name, value } = e.target
    const updatedVendors = [...vendors]
    updatedVendors[index][name] = value
    setVendors(updatedVendors)
  }

  const handleAdd = () => {
    setVendors([
      ...vendors,
      {
        id: vendors.length + 1,
        name: "",
        phone_no: "",
        email_address2: "",
        role: "",
        address2: "",
        pincode: "",
        country: "",
        is_active: 1,
      },
    ])
  }

  const handleDelete = index => {
    if (vendors.length > 1) {
      const updatedVendors = vendors.filter(vendor => vendor.id !== index)
      setVendors(updatedVendors)
    } else {
      // Optionally, you can display a message or handle the situation accordingly
      Swal.fire({
        title: "failure",
        icon: "error",
        text: "Cannot delete the last vendor.",
      })
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      // Fetch countries
      await dispatch(getCountries())

      // If country is selected (edit mode), fetch states and cities
      if (validation.values.country) {
        await dispatch(getStates(validation.values.country))
        await dispatch(getCities(validation.values.state))
      }

      // Set initial values
      setCountryName(validation.values.country)
      setStateId(validation.values.state)
    }

    fetchData()
  }, [dispatch, validation.values.country, validation.values.state])

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 6) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }
  const handleNextTab = () => {
    validation.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        toggleTab(activeTab + 1)
      } else {
      }
    })
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Form>
          {vendors.map((vendor, index) => (
            <Card key={vendor.id}>
              <CardBody>
                <CardTitle>Vendor {index + 1}</CardTitle>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label for={`name${index}`}>Name*</Label>
                      <Input
                        type="text"
                        name={`name${index}`}
                        className="form-control"
                        id={`name${index}`}
                        placeholder="Enter Vendor Employee Name"
                        onChange={e => handleChangeVendor(e, index)}
                        value={vendor.name}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label for="phone_no">Phone No.*</Label>
                      <Input
                        type="text"
                        name="phone_no"
                        className="form-control"
                        id="phone_no"
                        placeholder="Enter Vendor Employee Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phone_no || ""}
                        invalid={
                          validation.touched.phone_no &&
                          validation.errors.phone_no
                            ? true
                            : false
                        }
                      />
                      {validation.touched.phone_no &&
                      validation.errors.phone_no ? (
                        <FormFeedback type="invalid">
                          {validation.errors.phone_no}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label for="email_address">Email Address*</Label>
                      <Input
                        type="email"
                        name="email_address2"
                        className="form-control"
                        id="email_address2"
                        placeholder="Enter Vendor Email Address"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email_address2 || ""}
                        invalid={
                          validation.touched.email_address2 &&
                          validation.errors.email_address2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.vendor_name &&
                      validation.errors.vendor_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.vendor_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label for="role">Role*</Label>
                      <Input
                        type="text"
                        name="role"
                        className="form-control"
                        id="role"
                        placeholder="Enter Role of Vendor"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.role || ""}
                        invalid={
                          validation.touched.role && validation.errors.role
                            ? true
                            : false
                        }
                      />
                      {validation.touched.vendor_name &&
                      validation.errors.vendor_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.vendor_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="address">Address*</Label>
                      <Input
                        type="text"
                        name="address"
                        className="form-control"
                        id="address2"
                        placeholder="Enter Address"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address2 || ""}
                        invalid={
                          validation.touched.address2 &&
                          validation.errors.address2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.address2 &&
                      validation.errors.address2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.address2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="pincode">Pincode*</Label>
                      <Input
                        type="text"
                        name="pincode2"
                        className="form-control"
                        id="pincode2"
                        placeholder="Enter Pincode"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pincode || ""}
                        invalid={
                          validation.touched.pincode &&
                          validation.errors.pincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.pincode &&
                      validation.errors.pincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <Label for="country">Country*</Label>
                      <select
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        // defaultValue="IN"
                        value={validation.values.country}
                        name="country2"
                        className={`form-select ${
                          validation.touched.country &&
                          validation.errors.country
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        {!validation.values.country && (
                          <option>Enter Your Country</option>
                        )}

                        {(countries || []).map(item => (
                          <option key={item.id} value={item.id}>
                            {item.country_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <Label htmlFor="is_active">Is Active*</Label>
                    <Col>
                      <div className="form-check">
                        <Input
                          type="radio"
                          id="isActiveYes"
                          name="is_active"
                          value={1}
                          onClick={e => {
                            handleChange(e)
                          }}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="isActiveYes"
                          style={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                        >
                          <h6>Yes</h6>
                        </Label>
                      </div>
                      <div className="form-check">
                        <Input
                          type="radio"
                          id="isActiveNo"
                          name="is_active"
                          value={0}
                          onClick={e => {
                            handleChange(e)
                          }}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="isActiveNo"
                          style={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                        >
                          <h6>No</h6>
                        </Label>
                      </div>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <Button
                      style={{ width: "100%" }}
                      onClick={() => {
                        handleAdd()
                      }}
                      color="success"
                    >
                      Add
                    </Button>
                  </Col>
                  <Col md="2">
                    <Button
                      color="primary"
                      className="btn-block inner "
                      id="unknown-btn"
                      style={{ width: "100%" }}
                      onClick={e => {
                        handleDelete(vendor.id)
                      }}
                    >
                      {" "}
                      Delete{" "}
                    </Button>
                  </Col>
                </Row>
                <Row>
                <Col>
                  <div className="mb-3 text-end">
                    <button type="submit" className="btn btn-primary w-md">
                      Save{" "}
                    </button>
                  </div>
                </Col>
              </Row>
              </CardBody>
            </Card>
          ))}
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default VendorsContacts
