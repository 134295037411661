import {
  GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT,
  GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_SUCCESS,
  GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_FAIL,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_SUCCESS,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_FAIL,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_SUCCESS,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_FAIL,
  GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS,
  GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_FAIL,
  GET_BILL_HISTORY_FOR_VENDOR_PAYMENT,
  GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT,
  GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS,
  GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_FAIL,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL,
} from "./actionTypes"

//GET COMPANY FILTER

export const getCompanyFilterForVendorPayment = userId => ({
  type: GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT,
  userId,
})

export const getCompanyFilterForVendorPaymentSuccess = companyFilter => ({
  type: GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_SUCCESS,
  payload: companyFilter,
})

export const getCompanyFilterForVendorPaymentFail = error => ({
  type: GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_FAIL,
  payload: error,
})

export const getProjectFilterForVendorPayment = userId => ({
  type: GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT,
  userId,
})

export const getProjectFilterForVendorPaymentSuccess = projectFilter => ({
  type: GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_SUCCESS,
  payload: projectFilter,
})

export const getProjectFilterForVendorPaymentFail = error => ({
  type: GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_FAIL,
  payload: error,
})

export const getVendorFilterForVendorPayment = userId => ({
  type: GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT,
  userId,
})

export const getVendorFilterForVendorPaymentSuccess = vendorFilter => ({
  type: GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_SUCCESS,
  payload: vendorFilter,
})

export const getVendorFilterForVendorPaymentFail = error => ({
  type: GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_FAIL,
  payload: error,
})

export const getBillHistoryForVendorPayment = userId => ({
  type: GET_BILL_HISTORY_FOR_VENDOR_PAYMENT,
  userId,
})

export const getBillHistoryForVendorPaymentSuccess = vendorFilter => ({
  type: GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS,
  payload: vendorFilter,
})

export const getBillHistoryForVendorPaymentFail = error => ({
  type: GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_FAIL,
  payload: error,
})

export const getPaymentHistoryForVendorPayment = userId => ({
  type: GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT,
  userId,
})

export const getPaymentHistoryForVendorPaymentSuccess = vendorFilter => ({
  type: GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS,
  payload: vendorFilter,
})

export const getPaymentHistoryForVendorPaymentFail = error => ({
  type: GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_FAIL,
  payload: error,
})

////for payment
export const getProjectFilterForVendorPaymentList = userId => ({
  type: GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST,
  userId,
})

export const getProjectFilterForVendorPaymentListSuccess = projectFilter => ({
  type: GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS,
  payload: projectFilter,
})

export const getProjectFilterForVendorPaymentListFail = error => ({
  type: GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL,
  payload: error,
})

export const getVendorFilterForVendorPaymentList = userId => ({
  type: GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST,
  userId,
})

export const getVendorFilterForVendorPaymentListSuccess = vendorFilter => ({
  type: GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS,
  payload: vendorFilter,
})

export const getVendorFilterForVendorPaymentListFail = error => ({
  type: GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL,
  payload: error,
})