import {
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,
  GET_MODULES_FAIL,
  GET_MODULES_SUCCESS,
  GET_ROLE_DETAIL_FAIL,
  GET_ROLE_DETAIL_SUCCESS,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
 
  GET_MANAGE_PERMISSION_FAIL,
  GET_MANAGE_PERMISSION_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  roles: [],
  manageRoles: [],
  userRoleModules: [],
  roleDetail: {},
  error: {},
  loading: true,
}

const roles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        loading: false,
        // loading: true
      }

    case GET_ROLES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_MANAGE_PERMISSION_SUCCESS:
      return {
        ...state,
        manageRoles: action.payload,
        loading: false,
        // loading: true
      }

    case  GET_MANAGE_PERMISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MODULES_SUCCESS:
      return {
        ...state,
        userRoleModules: action.payload,
        loading: false,
        // loading: true
      }

    case GET_MODULES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        roles: [...state.roles, action.payload],
      }

    case ADD_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ROLE_DETAIL_SUCCESS:
      return {
        ...state,
        roleDetail: action.payload,
      }

    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.map(role =>
          role.id.toString() === action.payload.id.toString()
            ? { role, ...action.payload }
            : role
        ),
      }

    case UPDATE_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.filter(
          role => role.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ROLE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    default:
      return state
  }
}

export default roles
