import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"

// Crypto Redux States
import {
  GET_REASON_MASTER_LIST,
  GET_REASON_MASTER_DETAIL,
  ADD_NEW_REASON_MASTER,
  UPDATE_REASON_MASTER,
  DELETE_REASON_MASTER,
} from "./actionTypes"
import {
  getReasonMasterListSuccess,
  getReasonMasterListFail,
  getReasonMasterDetailSuccess,
  getReasonMasterDetailFail,
  addNewReasonMasterSuccess,
  addNewReasonMasterFail,
  updateReasonMasterSuccess,
  updateReasonMasterFail,
  deleteReasonMasterSuccess,
  deleteReasonMasterFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getReasonMasterList,
  getReasonMasterDetail,
  addNewReasonMaster,
  updateReasonMaster,
  deleteReasonMaster,
} from "helpers/fakebackend_helper"

function* fetchReasonMasterList() {
  try {
    const response = yield call(getReasonMasterList)
    yield put(getReasonMasterListSuccess(response))
  } catch (error) {
    yield put(getReasonMasterListFail(error))
  }
}

function* fetchReasonMasterDetail({ reasonId }) {
  try {
    const response = yield call(getReasonMasterDetail, reasonId)
    yield put(getReasonMasterDetailSuccess(response))
  } catch (error) {
    yield put(getReasonMasterDetailFail(error))
  }
}

function* onAddNewReasonMaster({ payload: { reason, history } }) {
  try {
    const response = yield call(addNewReasonMaster, reason)
    if (response.status === true) {
      yield put(addNewReasonMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Reason Master Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/reason-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewReasonMasterFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onUpdateReasonMaster({ payload: { id, reason, history } }) {
  try {
    const response = yield call(updateReasonMaster, id, reason)
    if (response && response.status === true) {
      yield put(updateReasonMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Reason Master Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/reason-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateReasonMasterFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteReasonMaster({ payload: reason }) {
  try {
    const response = yield call(deleteReasonMaster, reason)
    console.log(response)
    const response2 = yield put(deleteReasonMasterSuccess(response))
    console.log(response2)
    // yield put(deleteSACHSNCodeSuccess(response))
    if (response2.payload.status) {
      const reasonMasterListResponse = yield call(getReasonMasterList)
      yield put(getReasonMasterListSuccess(reasonMasterListResponse))
    } else {
      console.error("Error in delete Reason Master:", response.error)
    }
  } catch (error) {
    yield put(deleteReasonMasterFail(error))
  }
}

function* reasonMasterSaga() {
  yield takeEvery(GET_REASON_MASTER_LIST, fetchReasonMasterList)
  yield takeEvery(GET_REASON_MASTER_DETAIL, fetchReasonMasterDetail)
  yield takeEvery(ADD_NEW_REASON_MASTER, onAddNewReasonMaster)
  yield takeEvery(UPDATE_REASON_MASTER, onUpdateReasonMaster)
  yield takeEvery(DELETE_REASON_MASTER, onDeleteReasonMaster)
}

export default reasonMasterSaga
