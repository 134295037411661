import {
  GET_VENDOR,
  GET_VENDOR_SUCCESS,
  GET_VENDOR_FAIL,
  GET_VENDORS_LIST,
  GET_VENDORS_LIST_SUCCESS,
  GET_VENDORS_LIST_FAIL,
  ADD_NEW_VENDORS,
  ADD_NEW_VENDORS_SUCCESS,
  ADD_NEW_VENDORS_FAIL,
  UPDATE_VENDORS,
  UPDATE_VENDORS_SUCCESS,
  UPDATE_VENDORS_FAIL,
  DELETE_VENDORS,
  DELETE_VENDORS_SUCCESS,
  DELETE_VENDORS_FAIL,
  ADD_NEW_VENDORS_DOCUMENTS,
  ADD_NEW_VENDORS_DOCUMENTS_SUCCESS,
  ADD_NEW_VENDORS_DOCUMENTS_FAIL,
  ADD_NEW_VENDORS_CONTACTS,
  ADD_NEW_VENDORS_CONTACTS_SUCCESS,
  ADD_NEW_VENDORS_CONTACTS_FAIL,
  DELETE_VENDOR_CONTACT_SUCCESS,
  DELETE_VENDOR_CONTACT_FAIL,
  DELETE_VENDOR_DOCUMENT_SUCCESS,
  DELETE_VENDOR_DOCUMENT_FAIL,
  DELETE_VENDOR_DOCUMENT,
  DELETE_VENDOR_CONTACT,
  GET_VENDOR_SERVICE_MASTER_LIST_SUCCESS,
  GET_VENDOR_SERVICE_MASTER_LIST,
  GET_VENDOR_SERVICE_MASTER_LIST_FAIL,
  GET_VENDOR_CONTACT,
  GET_VENDOR_CONTACT_SUCCESS,
  GET_VENDOR_CONTACT_FAIL,
  GET_VENDOR_CODE,
  GET_VENDOR_CODE_SUCCESS,
  GET_VENDOR_CODE_FAIL,
  ADD_VENDOR_PAYMENT,
  ADD_VENDOR_PAYMENT_SUCCESS,
  ADD_VENDOR_PAYMENT_FAIL,
  GET_VENDOR_LIST_IN_PAYMENT,
  GET_VENDOR_LIST_IN_PAYMENT_SUCCESS,
  GET_VENDOR_LIST_IN_PAYMENT_FAIL,
  GET_VENDOR_PAYMENT_DETAIL,
  GET_VENDOR_PAYMENT_DETAIL_SUCCESS,
  GET_VENDOR_PAYMENT_DETAIL_FAIL,
  GET_VENDOR_PAYMENT_DATA,
  GET_VENDOR_PAYMENT_DATA_SUCCESS,
  GET_VENDOR_PAYMENT_DATA_FAIL,
} from "./actionTypes"

export const getVendor = vendorId => ({
  type: GET_VENDOR,
  vendorId,
})

export const getVendorSuccess = vendor => ({
  type: GET_VENDOR_SUCCESS,
  payload: vendor,
})

export const getVendorFail = error => ({
  type: GET_VENDOR_FAIL,
  payload: error,
})
export const getVendorContact = vendorId => ({
  type: GET_VENDOR_CONTACT,
  vendorId,
})

export const getVendorContactSuccess = vendor => ({
  type: GET_VENDOR_CONTACT_SUCCESS,
  payload: vendor,
})

export const getVendorContactFail = error => ({
  type: GET_VENDOR_CONTACT_FAIL,
  payload: error,
})

export const getVendorsList = () => ({
  type: GET_VENDORS_LIST,
})

export const getVendorsListSuccess = vendors => ({
  type: GET_VENDORS_LIST_SUCCESS,
  payload: vendors,
})

export const getVendorsListFail = error => ({
  type: GET_VENDORS_LIST_FAIL,
  payload: error,
})

export const getVendorServiceMasterList = () => ({
  type: GET_VENDOR_SERVICE_MASTER_LIST,
})

export const getVendorServiceMasterListSuccess = vendors => ({
  type: GET_VENDOR_SERVICE_MASTER_LIST_SUCCESS,
  payload: vendors,
})

export const getVendorServiceMasterListFail = error => ({
  type: GET_VENDOR_SERVICE_MASTER_LIST_FAIL,
  payload: error,
})
//add
export const addNewVendors = vendor => ({
  type: ADD_NEW_VENDORS,
  payload: vendor,
})

export const addNewVendorsSuccess = vendor => ({
  type: ADD_NEW_VENDORS_SUCCESS,
  payload: vendor,
})

export const addNewVendorsFail = error => ({
  type: ADD_NEW_VENDORS_FAIL,
  payload: error,
})
//add docs
export const addNewVendorsDocuments = (id, docs) => ({
  type: ADD_NEW_VENDORS_DOCUMENTS,
  payload: { id, docs },
})

export const addNewVendorsDocumentsSuccess = vendor => ({
  type: ADD_NEW_VENDORS_DOCUMENTS_SUCCESS,
  payload: vendor,
})

export const addNewVendorsDocumentsFail = error => ({
  type: ADD_NEW_VENDORS_DOCUMENTS_FAIL,
  payload: error,
})
//vendor contacts
export const addNewVendorsContacts = (id, contacts) => ({
  type: ADD_NEW_VENDORS_CONTACTS,
  payload: { id, contacts },
})

export const addNewVendorsContactsSuccess = vendor => ({
  type: ADD_NEW_VENDORS_CONTACTS_SUCCESS,
  payload: vendor,
})

export const addNewVendorsContactsFail = error => ({
  type: ADD_NEW_VENDORS_CONTACTS_FAIL,
  payload: error,
})

///update
export const updateVendors = vendor => ({
  type: UPDATE_VENDORS,
  payload: vendor,
})

export const updateVendorsSuccess = vendor => ({
  type: UPDATE_VENDORS_SUCCESS,
  payload: vendor,
})

export const updateVendorsFail = error => ({
  type: UPDATE_VENDORS_FAIL,
  payload: error,
})

export const deleteVendors = vendor => ({
  type: DELETE_VENDORS,
  payload: vendor,
})

export const deleteVendorsSuccess = vendor => ({
  type: DELETE_VENDORS_SUCCESS,
  payload: vendor,
})

export const deleteVendorsFail = error => ({
  type: DELETE_VENDORS_FAIL,
  payload: error,
})

//DELETE DOCUMENTS
export const deleteVendorsDocument = vendor => ({
  type: DELETE_VENDOR_DOCUMENT,
  payload: vendor,
})

export const deleteVendorsDocumentSuccess = vendor => ({
  type: DELETE_VENDOR_DOCUMENT_SUCCESS,
  payload: vendor,
})

export const deleteVendorsDocumentFail = error => ({
  type: DELETE_VENDOR_DOCUMENT_FAIL,
  payload: error,
})
export const deleteVendorsContact = vendor => ({
  type: DELETE_VENDOR_CONTACT,
  payload: vendor,
})

export const deleteVendorsContactSuccess = vendor => ({
  type: DELETE_VENDOR_CONTACT_SUCCESS,
  payload: vendor,
})

export const deleteVendorsContactFail = error => ({
  type: DELETE_VENDOR_CONTACT_FAIL,
  payload: error,
})

export const getVendorCode = () => ({
  type: GET_VENDOR_CODE,
})

export const getVendorCodeSuccess = vendors => ({
  type: GET_VENDOR_CODE_SUCCESS,
  payload: vendors,
})

export const getVendorCodeFail = error => ({
  type: GET_VENDOR_CODE_FAIL,
  payload: error,
})

//add new Payment
export const addVendorPayment = (history, payment) => ({
  type: ADD_VENDOR_PAYMENT,
  payload: { history, payment },
})

export const addVendorPaymentSuccess = payment => ({
  type: ADD_VENDOR_PAYMENT_SUCCESS,
  payload: payment,
})

export const addVendorPaymentFail = error => ({
  type: ADD_VENDOR_PAYMENT_FAIL,
  payload: error,
})

export const getVendorListInPayment = vendorId => ({
  type: GET_VENDOR_LIST_IN_PAYMENT,
  vendorId,
})

export const getVendorListInPaymentSuccess = vendor => ({
  type: GET_VENDOR_LIST_IN_PAYMENT_SUCCESS,
  payload: vendor,
})

export const getVendorListInPaymentFail = error => ({
  type: GET_VENDOR_LIST_IN_PAYMENT_FAIL,
  payload: error,
})

export const getVendorPaymentDetail = vendorId => ({
  type: GET_VENDOR_PAYMENT_DETAIL,
  vendorId,
})

export const getVendorPaymentDetailSuccess = vendor => ({
  type: GET_VENDOR_PAYMENT_DETAIL_SUCCESS,
  payload: vendor,
})

export const getVendorPaymentDetailFail = error => ({
  type: GET_VENDOR_PAYMENT_DETAIL_FAIL,
  payload: error,
})

export const getVendorPaymentData = paymentId => ({
  type: GET_VENDOR_PAYMENT_DATA,
  paymentId,
})

export const getVendorPaymentDataSuccess = vendor => ({
  type: GET_VENDOR_PAYMENT_DATA_SUCCESS,
  payload: vendor,
})

export const getVendorPaymentDataFail = error => ({
  type: GET_VENDOR_PAYMENT_DATA_FAIL,
  payload: error,
})


