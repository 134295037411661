/* COMPANIES */
export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

/* COMPANIES DETAIL*/
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL"
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS"
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
 export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
 export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_COMPANY = "UPDATE_COMPANY"
 export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
 export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_COMPANY = "DELETE_COMPANY"
 export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
 export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"

 // Document 

 export const GET_DOCUMENT_LIST_COMPANY = "GET_DOCUMENT_LIST_COMPANY"
 export const GET_DOCUMENT_LIST_COMPANY_SUCCESS = "GET_DOCUMENT_LIST_COMPANY_SUCCESS"
 export const GET_DOCUMENT_LIST_COMPANY_FAIL = "GET_DOCUMENT_LIST_COMPANY_FAIL"

 export const ADD_DOCUMENT_COMPANY= "ADD_DOCUMENT_COMPANY"
 export const ADD_DOCUMENT_COMPANY_SUCCESS= "ADD_DOCUMENT_COMPANY_SUCCESS"
 export const ADD_DOCUMENT_COMPANY_FAIL= "ADD_DOCUMENT_COMPANY_FAIL"

 export const DELETE_DOCUMENT_COMPANY="DELETE_DOCUMENT_COMPANY"
 export const DELETE_DOCUMENT_COMPANY_SUCCESS="DELETE_DOCUMENT_COMPANY_SUCCESS"
 export const DELETE_DOCUMENT_COMPANY_FAIL="DELETE_DOCUMENT_COMPANY_FAIL"

 //LOCATION

 export const GET_COMPANY_LOCATION_LIST = "GET_COMPANY_LOCATION_LIST"
 export const GET_COMPANY_LOCATION_LIST_SUCCESS = "GET_COMPANY_LOCATION_LIST_SUCCESS"
 export const GET_COMPANY_LOCATION_LIST_FAIL = "GET_COMPANY_LOCATION_LIST_FAIL"

 export const GET_COMPANY_LOCATION_DETAIL = "GET_COMPANY_LOCATION_DETAIL"
 export const GET_COMPANY_LOCATION_DETAIL_SUCCESS = "GET_COMPANY_LOCATION_DETAIL_SUCCESS"
 export const GET_COMPANY_LOCATION_DETAIL_FAIL = "GET_COMPANY_LOCATION_DETAIL_FAIL"

 export const ADD_COMPANY_LOCATION ="ADD_COMPANY_LOCATION"
 export const ADD_COMPANY_LOCATION_SUCCESS ="ADD_COMPANY_LOCATION_SUCCESS"
 export const ADD_COMPANY_LOCATION_FAIL ="ADD_COMPANY_LOCATION_FAIL"

 export const UPDATE_COMPANY_LOCATION ="UPDATE_COMPANY_LOCATION"
 export const UPDATE_COMPANY_LOCATION_SUCCESS ="UPDATE_COMPANY_LOCATION_SUCCESS"
 export const UPDATE_COMPANY_LOCATION_FAIL ="UPDATE_COMPANY_LOCATION_FAIL"
 
 export const DELETE_COMPANY_LOCATION ="DELETE_COMPANY_LOCATION"
 export const DELETE_COMPANY_LOCATION_SUCCESS ="DELETE_COMPANY_LOCATION_SUCCESS"
 export const DELETE_COMPANY_LOCATION_FAIL ="DELETE_COMPANY_LOCATION_FAIL"

 