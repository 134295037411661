import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import { Button, Card, CardBody, Col } from "reactstrap"
import { getRecentServiceRequestList } from "store/actions"
import TableContainer from "../../../../components/Common/TableContainer"
import { useDispatch, useSelector } from "react-redux"
import getStatusColor from "components/Common/getStatusColor"

const LatestTranaction = () => {
  const dispatch = useDispatch()
  const serviceRequestState = state =>
    state.serviceRequestDashboard.recentRequests.latest_services_request_list

  const latestTransaction = useSelector(serviceRequestState) || []

  useEffect(() => {
    dispatch(getRecentServiceRequestList())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">{cellProps.row.index + 1}</h5>
            </>
          )
        },
      },
      {
        Header: "SRQ Code",
        accessor: "srq_code",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.srq_code}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.customer_name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Type of Request",
        accessor: "type_of_request",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.service_type}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Request Made On",
        accessor: "request_made_on",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.created_on}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* {cellProps.row.original.status} */}
                <span>{cellProps.row.original.status}</span>{" "}
                {/* <span style={{ color: getStatusColor(cellProps.row.original.status) }}>{cellProps.row.original.status}</span>{" "} */}

              </h5>
            </>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardBody>
            <div className="mb-4 h4 card-title">Recent Service Requests</div>
            <TableContainer
              columns={columns}
              data={latestTransaction}
              isGlobalFilter={false}
              isAddOptions={false}
              // isPagination={true}
              iscustomPageSizeOptions={false}
              customPageSize={5}
              pagination="pagination pagination-rounded justify-content-end mb-2"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

LatestTranaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(LatestTranaction)
