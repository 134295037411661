/* PROPOSALS */
export const GET_PROPOSALS = "GET_PROPOSALS"
export const GET_PROPOSALS_SUCCESS = "GET_PROPOSALS_SUCCESS"
export const GET_PROPOSALS_FAIL = "GET_PROPOSALS_FAIL"

/* PROPOSALS DETAIL*/
export const GET_PROPOSAL_DETAIL = "GET_PROPOSAL_DETAIL"
export const GET_PROPOSAL_DETAIL_SUCCESS = "GET_PROPOSAL_DETAIL_SUCCESS"
export const GET_PROPOSAL_DETAIL_FAIL = "GET_PROPOSAL_DETAIL_FAIL"

/**
 * add proposal
 */
 export const ADD_NEW_PROPOSAL = "ADD_NEW_PROPOSAL"
 export const ADD_PROPOSAL_SUCCESS = "ADD_PROPOSAL_SUCCESS"
 export const ADD_PROPOSAL_FAIL = "ADD_PROPOSAL_FAIL"
 
 /**
  * Edit proposal
  */
 export const UPDATE_PROPOSAL = "UPDATE_PROPOSAL"
 export const UPDATE_PROPOSAL_SUCCESS = "UPDATE_PROPOSAL_SUCCESS"
 export const UPDATE_PROPOSAL_FAIL = "UPDATE_PROPOSAL_FAIL"
 
 /**
  * Delete proposal
  */
 export const DELETE_PROPOSAL = "DELETE_PROPOSAL"
 export const DELETE_PROPOSAL_SUCCESS = "DELETE_PROPOSAL_SUCCESS"
 export const DELETE_PROPOSAL_FAIL = "DELETE_PROPOSAL_FAIL"
