import {
    GET_LANDLORDS,
    GET_LANDLORDS_FAIL,
    GET_LANDLORDS_SUCCESS,
    GET_LANDLORD_DETAIL,
    ADD_NEW_LANDLORD,
    ADD_LANDLORD_SUCCESS,
    ADD_LANDLORD_FAIL,
    UPDATE_LANDLORD,
    UPDATE_LANDLORD_SUCCESS,
    UPDATE_LANDLORD_FAIL,
    DELETE_LANDLORD,
    DELETE_LANDLORD_SUCCESS,
    DELETE_LANDLORD_FAIL,
    GET_LANDLORD_DETAIL_FAIL,
    GET_LANDLORD_DETAIL_SUCCESS,
    ACTIVE_LANDLORD,
    ACTIVE_LANDLORD_SUCCESS,
    ACTIVE_LANDLORD_FAIL,
    GET_DOCUMENT_LIST_LANDLORD,
    GET_DOCUMENT_LIST_LANDLORD_SUCCESS,
    GET_DOCUMENT_LIST_LANDLORD_FAIL,
    DELETE_DOCUMENT_LANDLORD,
    DELETE_DOCUMENT_LANDLORD_SUCCESS,
    DELETE_DOCUMENT_LANDLORD_FAIL,
  } from "./actionTypes"
  
  export const getLandlords = () => ({
    type: GET_LANDLORDS,
  })
  
  export const getLandlordsSuccess = landlords => ({
    type: GET_LANDLORDS_SUCCESS,
    payload: landlords,
  })
  export const getLandlordsFail = error => ({
    type: GET_LANDLORDS_FAIL,
    payload: error,
  })
  
  export const addNewLandlord = landlord => ({
    type: ADD_NEW_LANDLORD,
    payload: landlord,
  })
  
  export const addLandlordSuccess = landlord => ({
    type: ADD_LANDLORD_SUCCESS,
    payload: landlord,
  })
  
  export const addLandlordFail = error => ({
    type: ADD_LANDLORD_FAIL,
    payload: error,
  })
  
  export const updateLandlord = (id,landlord) => ({
    type: UPDATE_LANDLORD,
    payload: {id,landlord},
  })
  
  export const updateLandlordSuccess = landlord => ({
    type: UPDATE_LANDLORD_SUCCESS,
    payload: landlord,
  })
  
  export const updateLandlordFail = error => ({
    type: UPDATE_LANDLORD_FAIL,
    payload: error,
  })
  
  export const deleteLandlord = (landlord) => ({
    type: DELETE_LANDLORD,
    payload: landlord
  })
  
  export const deleteLandlordSuccess = landlord => ({
    type: DELETE_LANDLORD_SUCCESS,
    payload: landlord,
  })
  
  export const deleteLandlordFail = error => ({
    type: DELETE_LANDLORD_FAIL,
    payload: error,
  })
  
  
  export const getLandlordDetail = landlordId => ({
    type: GET_LANDLORD_DETAIL,
    landlordId,
  })
  
  export const getLandlordDetailSuccess = landlordDetails => ({
    type: GET_LANDLORD_DETAIL_SUCCESS,
    payload: landlordDetails,
  })
  
  export const getLandlordDetailFail = error => ({
    type: GET_LANDLORD_DETAIL_FAIL,
    payload: error,
  })
  
  export const activeLandlord = () => ({
    type: ACTIVE_LANDLORD,
  })
  
  export const activeLandlordSuccess = landlords => ({
    type: ACTIVE_LANDLORD_SUCCESS,
    payload: landlords,
  })
  export const activeLandlordFail = error => ({
    type: ACTIVE_LANDLORD_FAIL,
    payload: error,
  })

  export const getLandlordDocuments = (landlordId) => ({
    type: GET_DOCUMENT_LIST_LANDLORD,
    landlordId
  })
  
  export const getLandlordDocumentsSuccess = docs => ({
    type: GET_DOCUMENT_LIST_LANDLORD_SUCCESS,
    payload: docs,
  })
  
  export const getLandlordDocumentsFail = error => ({
    type: GET_DOCUMENT_LIST_LANDLORD_FAIL,
    payload: error,
  })
  
  export const addLandlordDocument = docs => ({
    type: ADD_DOCUMENT_LANDLORD,
    payload: docs,
  })
  
  export const addLandlordDocumentSuccess = docs => ({
    type: ADD_DOCUMENT_LANDLORD_SUCCESS,
    payload: docs,
  })
  
  export const addLandlordDocumentFail = error => ({
    type: ADD_DOCUMENT_LANDLORD_FAIL,
    payload: error,
  })
  
  export const deleteLandlordDocument = docs => ({
    type: DELETE_DOCUMENT_LANDLORD,
    docs,
  })
  
  export const deleteLandlordDocumentSuccess = docs => ({
    type: DELETE_DOCUMENT_LANDLORD_SUCCESS,
    payload: docs,
  })
  
  export const deleteLandlordDocumentFail = error => ({
    type: DELETE_DOCUMENT_LANDLORD_FAIL,
    payload: error,
  })
  