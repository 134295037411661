import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  FormFeedback,
  Col,
  Row,
  Label,
  Form,
  Input,
  Card,
  CardBody,
  Modal,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import Swal from "sweetalert2"

import {
  getMaterialMasterForServiceRequest,
  getServiceRequestTicketDetail,
  getUnitMasterForServiceRequest,
  getVendorPaymentData,
} from "store/actions"
import {
  addOtherCostInTicket,
  approveVendorInvoice,
  sendForReviewInTicket,
  updateStatusInTicket,
} from "helpers/fakebackend_helper"
import Dropzone from "react-dropzone"

const LargeModal = ({ isOpen, toggle, data }) => {
  const dispatch = useDispatch()
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: 0,
      approval_remark: "",
    },

    validationSchema: Yup.object({}),
  })

  const handleReject = async value1 => {
    
    let loadingSwal
    try {
      loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })
      const value = {
        approval_status: value1,
        approval_remark: validation.values.approval_remark,
      }

      const response = await approveVendorInvoice(value, data)

      if (response && response.status === true) {
        loadingSwal.close()

        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Rejected Successfully",
        }).then(result => {
          if (result.isConfirmed) {
            dispatch(getVendorPaymentData(data))
            toggle()
          }
        })
      } else {
        loadingSwal.close()

        Swal.fire({
          title: "Failure",
          icon: "error",
          text: "Your Request has not been Submitted.",
        })
      }
    } catch (error) {
      console.error("Error:", error)
      if (loadingSwal) {
        loadingSwal.close()
      }

      Swal.fire({
        title: "Error",
        icon: "error",
        text:
          error.response?.data?.message ||
          "An error occurred while Submitting.",
      })
    }
  }
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Approval
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Card>
          <CardBody>
            <Form
              autoComplete="off"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Input
                            type="textarea"
                            name={`approval_remark`}
                            onChange={validation.handleChange}
                            placeholder="Enter Remark"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Row>
              <Row>
                <div className="mb-3 mt-4 text-center">
                
                  <button
                    className="btn btn-danger "
                    onClick={() => {
                      handleReject("2")
                    }}
                  >
                    Reject
                  </button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  )
}

export default LargeModal
