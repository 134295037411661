import {
    GET_DOCUMENTS,
    GET_DOCUMENTS_FAIL,
    GET_DOCUMENTS_SUCCESS,
    GET_ACTIVE_DOCUMENTS,
    GET_ACTIVE_DOCUMENTS_FAIL,
    GET_ACTIVE_DOCUMENTS_SUCCESS,
    GET_DOCUMENT_DETAIL,
    ADD_NEW_DOCUMENT,
    ADD_DOCUMENT_SUCCESS,
    ADD_DOCUMENT_FAIL,
    UPDATE_DOCUMENT,
    UPDATE_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_FAIL,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAIL,
    GET_DOCUMENT_DETAIL_FAIL,
    GET_DOCUMENT_DETAIL_SUCCESS,
  } from "./actionTypes"
  
  export const getDocuments = () => ({
    type: GET_DOCUMENTS,
  })
  
  export const getDocumentsSuccess = documents => ({
    type: GET_DOCUMENTS_SUCCESS,
    payload: documents,
  })

  export const getActiveDocuments = (userType) => ({
    type: GET_ACTIVE_DOCUMENTS,
    userType,
  })
  
  export const getActiveDocumentsSuccess = documents => ({
    type: GET_ACTIVE_DOCUMENTS_SUCCESS,
    payload: documents,
  })
  
  export const addNewDocument = document => ({
    type: ADD_NEW_DOCUMENT,
    payload: document,
  })
  
  export const addDocumentSuccess = document => ({
    type: ADD_DOCUMENT_SUCCESS,
    payload: document,
  })
  
  export const addDocumentFail = error => ({
    type: ADD_DOCUMENT_FAIL,
    payload: error,
  })
  
  export const updateDocument = (id,document) => ({
    type: UPDATE_DOCUMENT,
    payload: {id,document},
  })
  
  export const updateDocumentSuccess = document => ({
    type: UPDATE_DOCUMENT_SUCCESS,
    payload: document,
  })
  
  export const updateDocumentFail = error => ({
    type: UPDATE_DOCUMENT_FAIL,
    payload: error,
  })
  
  export const deleteDocument = document => ({
    type: DELETE_DOCUMENT,
    payload: document,
  })
  
  export const deleteDocumentSuccess = document => ({
    type: DELETE_DOCUMENT_SUCCESS,
    payload: document,
  })
  
  export const deleteDocumentFail = error => ({
    type: DELETE_DOCUMENT_FAIL,
    payload: error,
  })
  
  export const getDocumentsFail = error => ({
    type: GET_DOCUMENTS_FAIL,
    payload: error,
  })
  
  export const getActiveDocumentsFail = error => ({
    type: GET_ACTIVE_DOCUMENTS_FAIL,
    payload: error,
  })
  export const getDocumentDetail = documentId => ({
    type: GET_DOCUMENT_DETAIL,
    documentId,
  })
  
  export const getDocumentDetailSuccess = documentDetails => ({
    type: GET_DOCUMENT_DETAIL_SUCCESS,
    payload: documentDetails,
  })
  
  export const getDocumentDetailFail = error => ({
    type: GET_DOCUMENT_DETAIL_FAIL,
    payload: error,
  })
  