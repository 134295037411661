export const GET_CLIENT_USER_LIST = "GET_CLIENT_USER_LIST"
export const GET_CLIENT_USER_LIST_SUCCESS = "GET_CLIENT_USER_LIST_SUCCESS"
export const GET_CLIENT_USER_LIST_FAIL = "GET_CLIENT_USER_LIST_FAIL"

export const GET_CLIENT_USER_DETAIL = "GET_CLIENT_USER_DETAIL"
export const GET_CLIENT_USER_DETAIL_SUCCESS = "GET_CLIENT_USER_DETAIL_SUCCESS"
export const GET_CLIENT_USER_DETAIL_FAIL = "GET_CLIENT_USER_DETAIL_FAIL"

export const ADD_NEW_CLIENT_USER = "ADD_NEW_CLIENT_USER"
export const ADD_NEW_CLIENT_USER_SUCCESS = "ADD_NEW_CLIENT_USER_SUCCESS"
export const ADD_NEW_CLIENT_USER_FAIL = "ADD_NEW_CLIENT_USER_FAIL"

export const UPDATE_CLIENT_USER = "UPDATE_CLIENT_USER"
export const UPDATE_CLIENT_USER_SUCCESS = "UPDATE_CLIENT_USER_SUCCESS"
export const UPDATE_CLIENT_USER_FAIL = "UPDATE_CLIENT_USER_FAIL"

export const DELETE_CLIENT_USER = "DELETE_CLIENT_USER"
export const DELETE_CLIENT_USER_SUCCESS = "DELETE_CLIENT_USER_SUCCESS"
export const DELETE_CLIENT_USER_FAIL = "DELETE_CLIENT_USER_FAIL"
