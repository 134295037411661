import {
  GET_COMPLIANCES_FAIL,
  GET_COMPLIANCES_SUCCESS,
  GET_PROJECT_COMPLIANCE_SUCCESS,
  GET_PROJECT_COMPLIANCE_FAIL,
  GET_ACTIVE_COMPLIANCES_FAIL,
  GET_ACTIVE_COMPLIANCES_SUCCESS,
  GET_COMPLIANCE_DETAIL_FAIL,
  GET_COMPLIANCE_DETAIL_SUCCESS,
  ADD_COMPLIANCE_SUCCESS,
  ADD_COMPLIANCE_FAIL,
  UPDATE_COMPLIANCE_SUCCESS,
  UPDATE_COMPLIANCE_FAIL,
  DELETE_COMPLIANCE_SUCCESS,
  DELETE_COMPLIANCE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  compliances: [],
  activecompliances: [],
  complianceDetail: {},
  error: {},
  loading: true,
}

const compliances = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPLIANCES_SUCCESS:
      return {
        ...state,
        compliances: action.payload,
        loading: true,
        // loading: true
      }

    case GET_COMPLIANCES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PROJECT_COMPLIANCE_SUCCESS:
      return {
        ...state,
        compliances: action.payload,
        loading: true
        // loading: true
      };

    case GET_PROJECT_COMPLIANCE_FAIL:
      return {
        ...state,
        error: action.payload,
      };      

    case GET_ACTIVE_COMPLIANCES_SUCCESS:
      return {
        ...state,
        activecompliances: action.payload,
        loading: true,
        // loading: true
      }

    case GET_ACTIVE_COMPLIANCES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_COMPLIANCE_SUCCESS:
      return {
        ...state,
        compliances: [...state.compliances, action.payload],
      }

    case ADD_COMPLIANCE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPLIANCE_DETAIL_SUCCESS:
      return {
        ...state,
        complianceDetail: action.payload,
      }

    case UPDATE_COMPLIANCE_SUCCESS:
      return {
        ...state,
        compliances: state.compliances.map(compliance =>
          compliance.id.toString() === action.payload.id.toString()
            ? { compliance, ...action.payload }
            : compliance
        ),
      }

    case UPDATE_COMPLIANCE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COMPLIANCE_SUCCESS:
      return {
        ...state,
        compliances: state.compliances.data.filter(
          compliance => compliance.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_COMPLIANCE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPLIANCE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default compliances
