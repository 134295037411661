import React, { useEffect, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getProjectDetail } from "store/actions"

const ProjectDetailView = props => {
  const dispatch = useDispatch()
  const projectDetailState = state => state.projects.projectDetail.data
  const projectProfile = useSelector(projectDetailState)

  const projectDetails = async () => {
    try {
      const url = new URL(window.location.href)
      const projectId = url.pathname.split("/project-details/")[1]
      const response = await dispatch(getProjectDetail(projectId))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    projectDetails()
  }, [dispatch])
  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col>
            <CardTitle className="mb-3">Project Property</CardTitle>

            <Row className="mb-3">
              <Col lg={4}>
                <Label className="form-label">Project Name</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.project_name}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Property Type</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.property_type}
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Label className="form-label">Project Use</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.project_use}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Project Area(sq.ft)</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.project_area_sqft}
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Label className="form-label">Project Type</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.project_type}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Total Floors</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.total_floors}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Label className="form-label">Location</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.location}
                </p>
              </Col>
            </Row>
          </Col>
          <hr />
          <Col>
            <CardTitle className="mb-3">
              {projectProfile?.property_type}
            </CardTitle>

            <Row className="mb-3">
              <Col lg={4}>
                <Label className="form-label">Contract Terms Start Date</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.contract_terms_start_date}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Contract Terms End Date</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.contract_terms_end_date}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Project Manager</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.first_name} {projectProfile?.middle_name}{" "}
                  {projectProfile?.last_name}
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Label className="form-label">Parking Available</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.parking_available === 1 ? "Yes" : "No"}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Parking type</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.parking_type}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Operated by</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.operated_by}
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Label className="form-label">Car Capability</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.car_parking_capability}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Bike Capability</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.bike_parking_capability}
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <Label className="form-label"> Branding Space Area(Sqft)</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.branding_space_area}
                </p>
              </Col>

              <Col lg={4}>
                <Label className="form-label"> Event Space Area(Sqft)</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.event_space_area}
                </p>
              </Col>
            </Row>
          </Col>
          <hr />
          <Col>
            <CardTitle className="mb-3">Compliance </CardTitle>

            <Row>
              <Col>
                <Label className="form-label">Compliance Name</Label>
                {projectProfile?.compliances?.map((item, index) => (
                  <p
                    key={index}
                    className="text-dark"
                    style={{ fontWeight: 600 }}
                  >
                    {item.compliance_title}
                  </p>
                ))}
              </Col>

              <Col>
                <Label className="form-label">From Date</Label>
                {projectProfile?.compliances?.map((item, index) => (
                  <p
                    key={index}
                    className="text-dark"
                    style={{ fontWeight: 600 }}
                  >
                    {item.from_date}
                  </p>
                ))}
              </Col>

              <Col>
                <Label className="form-label">To Date</Label>
                {projectProfile?.compliances?.map((item, index) => (
                  <p
                    key={index}
                    className="text-dark"
                    style={{ fontWeight: 600 }}
                  >
                    {item.to_date}
                  </p>
                ))}
              </Col>

              <Col>
                <Label className="form-label">Documents</Label>
                {projectProfile?.compliances?.map((item, index) => (
                  <p
                    key={index}
                    className="text-dark"
                    style={{ fontWeight: 600 }}
                  >
                    <Link to={item.document_url} target="_blank">
                      <i className="mdi mdi-eye me-2"></i>View
                    </Link>
                  </p>
                ))}
              </Col>
            </Row>
          </Col>
          <hr />
          <Col>
            <CardTitle className="mb-3">Billing Company Details </CardTitle>
            <Row>
              <Col lg={4}>
                <Label className="form-label">Billing Company</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.company_name}
                </p>
              </Col>
              <Col lg={4}>
                <Label className="form-label">Billing Company Location</Label>
                <p className="text-dark" style={{ fontWeight: 600 }}>
                  {projectProfile?.location_title}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ProjectDetailView
