import {
  GET_EXPENSES_FAIL,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSE_DETAIL_FAIL,
  GET_EXPENSE_DETAIL_SUCCESS,
  ADD_EXPENSE_SUCCESS,
  ADD_EXPENSE_FAIL,
  UPDATE_EXPENSE_SUCCESS,
  UPDATE_EXPENSE_FAIL,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  expenses: [],
  expenseDetail: {},
  error: {},
  loading: true
};

const expenses = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EXPENSES_SUCCESS:
      return {
        ...state,
        expenses: action.payload,
        loading: true
        // loading: true
      };

    case GET_EXPENSES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_EXPENSE_SUCCESS:
      return {
        ...state,
        expenses: [...state.expenses, action.payload],
      };

    case ADD_EXPENSE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_EXPENSE_DETAIL_SUCCESS:
      return {
        ...state,
        expenseDetail: action.payload,
      };

    case UPDATE_EXPENSE_SUCCESS:
      return {
        ...state,
        expenses: state.expenses.map(expense =>
          expense.id.toString() === action.payload.id.toString()
            ? { expense, ...action.payload }
            : expense
        ),
      };

    case UPDATE_EXPENSE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_EXPENSE_SUCCESS:
      return {
        ...state,
        expenses: state.expenses.expenseTypes.filter(
          expense => expense.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_EXPENSE_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    case GET_EXPENSE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default expenses;
