import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import Swal from "sweetalert2"

import VLogo from "../../assets/images/VIP/VLogo.png"
// import { addNewUserpasswordsettings, getUserpasswordsettings } from "store/actions";
import { useDispatch } from "react-redux"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  FormGroup,
  InputGroup,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import { addNewUserPasswordSettings } from "helpers/fakebackend_helper"

const UserPasswordSettings = props => {
  const { data } = props
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast1, setToast1] = useState(false)
  const [toast2, setToast2] = useState(false)
  const [toast3, setToast3] = useState(false)
  const [toast4, setToast4] = useState(false)
  const [toast5, setToast5] = useState(false)
  const [FormSubmitted, setFormSubmitted] = useState("0")
  const dispatch = useDispatch()

  const [selectedOption, setSelectedOption] = useState(
    data?.min_password_length?.toString()
  )
  const [selectedOptionNew, setSelectedOptionNew] = useState(
    data?.require_regular_password_changes?.toString()
  )

  const validation1 = useFormik({
    initialValues: {
      password_strength: (data && data.password_strength) || "",
      min_password_length: selectedOption,
      require_change_on_first_login:
        (data && data.require_change_on_first_login) || "",
      require_regular_password_changes:
      selectedOptionNew,
    },

    validation1Schema: {
      password_strength: Yup.string().required(
        "Please Select Password Strength"
      ),
      min_password_length: Yup.number().required(
        "Please Select Minimum Password Length"
      ),
      require_change_on_first_login: Yup.string().required(
        "Please Select Required Password change on first login"
      ),
      require_regular_password_changes: Yup.number().required(
        "Please Select Required Regular password changes"
      ),
    },

    onSubmit: async values => {

      try {
        const response = await addNewUserPasswordSettings(values)
        if (response && response.status === true) {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Password Settings has been Submitted.",
          })
        } else {
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Network Error:", error.message)
      }
    },
  })
  const handleChange = event => {
    setSelectedOption(event.target.value)
    
    validation1.setFieldValue("min_password_length", event.target.value)
  }

  const handleChange1 = event => {
    setSelectedOptionNew(event.target.value)
    validation1.setFieldValue("require_regular_password_changes", event.target.value)
  }
  

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">User Password Settings</CardTitle>
        <Form
        autoComplete = 'off'>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="password_strength">Password Strength</Label>
                <div className="form-check">
                  <Input
                    type="radio"
                    id="PasswordStrengthRadio1"
                    name="password_strength"
                    className={`form-check-input ${
                      validation1.touched.password_strength &&
                      validation1.errors.password_strength
                        ? "is-invalid"
                        : ""
                    }`}
                    value="ag"
                    onChange={validation1.handleChange}
                    onBlur={validation1.handleBlur}
                    checked={validation1.values.password_strength === "ag"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="PasswordStrengthRadio1"
                  >
                    Anything goes
                  </Label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Input
                    type="radio"
                    id="PasswordStrengthRadio2"
                    name="password_strength"
                    className={`form-check-input ${
                      validation1.touched.password_strength &&
                      validation1.errors.password_strength
                        ? "is-invalid"
                        : ""
                    }`}
                    value="lan"
                    onChange={validation1.handleChange}
                    onBlur={validation1.handleBlur}
                    checked={validation1.values.password_strength === "lan"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="PasswordStrengthRadio2"
                  >
                    Letters and numbers required
                  </Label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Input
                    type="radio"
                    id="PasswordStrengthRadio3"
                    name="password_strength"
                    className={`form-check-input ${
                      validation1.touched.password_strength &&
                      validation1.errors.password_strength
                        ? "is-invalid"
                        : ""
                    }`}
                    value="ulnp"
                    onChange={validation1.handleChange}
                    onBlur={validation1.handleBlur}
                    checked={validation1.values.password_strength === "ulnp"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="PasswordStrengthRadio3"
                  >
                    Uppercase/lowercase letters, numbers, and punctuations
                    required
                  </Label>
                </div>
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="minPasswordLength">
                  Minimum Password Length
                </Label>
                <div className="form-check">
                  <Input
                    type="radio"
                    id="minPasswordLengthRadio1"
                    name="min_password_length"
                    className={`form-check-input `}
                    value={4}
                    onChange={e => {
                      handleChange(e)
                    }}
                    checked={selectedOption === "4"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="minPasswordLengthRadio1"
                  >
                    4
                  </Label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Input
                    type="radio"
                    id="minPasswordLengthRadio2"
                    name="min_password_length"
                    className={`form-check-input `}
                    value={6}
                    onChange={e => {
                      handleChange(e)
                    }}
                    checked={selectedOption === "6"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="minPasswordLengthRadio2"
                  >
                    6
                  </Label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Input
                    type="radio"
                    id="minPasswordLengthRadio3"
                    name="min_password_length"
                    className={`form-check-input `}
                    value={8}
                    onChange={e => {
                      handleChange(e)
                    }}
                    checked={selectedOption === "8"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="minPasswordLengthRadio3"
                  >
                    8
                  </Label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Input
                    type="radio"
                    id="minPasswordLengthRadio4"
                    name="min_password_length"
                    className={`form-check-input `}
                    value={10}
                    onChange={e => {
                      handleChange(e)
                    }}
                    checked={selectedOption === "10"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="minPasswordLengthRadio4"
                  >
                    10
                  </Label>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="passwordStrength">
                  Required Password change on first login
                </Label>
                <div className="form-check">
                  <Input
                    type="radio"
                    id="requiredPasswordRadio1"
                    name="require_change_on_first_login"
                    className={`form-check-input ${
                      validation1.touched.require_change_on_first_login &&
                      validation1.errors.require_change_on_first_login
                        ? "is-invalid"
                        : ""
                    }`}
                    value="yes"
                    onChange={validation1.handleChange}
                    onBlur={validation1.handleBlur}
                    checked={
                      validation1.values.require_change_on_first_login === "yes"
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="requiredPasswordRadio1"
                  >
                    Yes
                  </Label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Input
                    type="radio"
                    id="requiredPasswordRadio2"
                    name="require_change_on_first_login"
                    className={`form-check-input ${
                      validation1.touched.require_change_on_first_login &&
                      validation1.errors.require_change_on_first_login
                        ? "is-invalid"
                        : ""
                    }`}
                    value="no"
                    onChange={validation1.handleChange}
                    onBlur={validation1.handleBlur}
                    checked={
                      validation1.values.require_change_on_first_login === "no"
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="requiredPasswordRadio2"
                  >
                    No
                  </Label>
                </div>
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="require_regular_password_changes">
                  Required Regular password changes
                </Label>
                <div className="form-check">
                  <Input
                    type="radio"
                    id="requiredRegularPass1"
                    name="require_regular_password_changes"
                    className={`form-check-input ${
                      validation1.touched.require_regular_password_changes &&
                      validation1.errors.require_regular_password_changes
                        ? "is-invalid"
                        : ""
                    }`}
                    value={0}
                    onChange={e => {
                      handleChange1(e)
                    }}
                    checked={selectedOptionNew === "0"}
                    onBlur={validation1.handleBlur}
                    // checked={validation1.values.require_regular_password_changes}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="requiredRegularPasswordRadio1"
                  >
                    No
                  </Label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Input
                    type="radio"
                    id="requiredRegularPass2"
                    name="require_regular_password_changes"
                    className={`form-check-input ${
                      validation1.touched.require_regular_password_changes &&
                      validation1.errors.require_regular_password_changes
                        ? "is-invalid"
                        : ""
                    }`}
                    value={30}
                    onChange={e => {
                      handleChange1(e)
                    }}
                    checked={selectedOptionNew === "30"}
                    onBlur={validation1.handleBlur}
                    // checked={validation1.values.require_regular_password_changes}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="requiredRegularPasswordRadio2"
                  >
                    30 Days
                  </Label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Input
                    type="radio"
                    id="requiredRegularPasswordRadio4"
                    name="require_regular_password_changes"
                    className={`form-check-input ${
                      validation1.touched.require_regular_password_changes &&
                      validation1.errors.require_regular_password_changes
                        ? "is-invalid"
                        : ""
                    }`}
                    value={60}
                    onChange={e => {
                      handleChange1(e)
                    }}
                    checked={selectedOptionNew === "60"}
                    onBlur={validation1.handleBlur}
                    // checked={validation1.values.require_regular_password_changes}

                  />
                  <Label
                    className="form-check-label"
                    htmlFor="requiredRegularPasswordRadio4"
                  >
                    60 Days
                  </Label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Input
                    type="radio"
                    id="requiredRegularPasswordRadio5"
                    name="require_regular_password_changes"
                    className={`form-check-input ${
                      validation1.touched.require_regular_password_changes &&
                      validation1.errors.require_regular_password_changes
                        ? "is-invalid"
                        : ""
                    }`}
                    value={90}
                    onChange={e => {
                      handleChange1(e)
                    }}
                    checked={selectedOptionNew === "90"}
                    onBlur={validation1.handleBlur}
                    // checked={validation1.values.require_regular_password_changes}

                  />
                  <Label
                    className="form-check-label"
                    htmlFor="requiredRegularPasswordRadio5"
                  >
                    90 Days
                  </Label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Input
                    type="radio"
                    id="requiredRegularPasswordRadio6"
                    name="require_regular_password_changes"
                    className={`form-check-input ${
                      validation1.touched.require_regular_password_changes &&
                      validation1.errors.require_regular_password_changes
                        ? "is-invalid"
                        : ""
                    }`}
                    value={180}
                    onChange={e => {
                      handleChange1(e)
                    }}
                    checked={selectedOptionNew === "180"}
                    onBlur={validation1.handleBlur}
                    // checked={validation1.values.require_regular_password_changes}

                  />
                  <Label
                    className="form-check-label"
                    htmlFor="requiredRegularPasswordRadio6"
                  >
                    180 Days
                  </Label>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="text-end">
              <button
                type="submit"
                className="btn btn-primary w-md"
                onClick={validation1.handleSubmit}
              >
                Save Changes
              </button>
            </Col>
          </Row>
          {toast5 && (
            <div
              className="position-fixed top-0 end-0 p-3"
              style={{ zIndex: "1005" }}
            >
              <Toast isOpen={toast5} role="aleart">
                <ToastHeader toggle={() => setToast5(!toast5)}>
                  <img src={VLogo} alt="" className="me-2" height="18" />
                  <strong className="me-auto">VIP Mall</strong>
                </ToastHeader>
                <ToastBody>
                  {!isEditMode
                    ? "Submitted Successfully"
                    : "Edited Successfully"}
                </ToastBody>
              </Toast>
            </div>
          )}
        </Form>
      </CardBody>
    </Card>
  )
}
export default UserPasswordSettings
