import {
  GET_PROPOSAL_TYPE_MASTER_LIST_SUCCESS,
  GET_PROPOSAL_TYPE_MASTER_LIST_FAIL,
  GET_PROPOSAL_TYPE_MASTER_DETAIL_SUCCESS,
  GET_PROPOSAL_TYPE_MASTER_DETAIL_FAIL,
  ADD_NEW_PROPOSAL_TYPE_MASTER_SUCCESS,
  ADD_NEW_PROPOSAL_TYPE_MASTER_FAIL,
  UPDATE_PROPOSAL_TYPE_MASTER_SUCCESS,
  UPDATE_PROPOSAL_TYPE_MASTER_FAIL,
  DELETE_PROPOSAL_TYPE_MASTER_SUCCESS,
  DELETE_PROPOSAL_TYPE_MASTER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  proposal: [],
  addProposal: [],
  proposalDetail: {},
  error: {},
  loading: true,
}

const proposalMaster = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROPOSAL_TYPE_MASTER_LIST_SUCCESS:
      return {
        ...state,
        proposal: action.payload,
        loading: false,
      }

    case GET_PROPOSAL_TYPE_MASTER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROPOSAL_TYPE_MASTER_DETAIL_SUCCESS:
      return {
        ...state,
        proposalDetail: action.payload,
        loading: false,
      }

    case GET_PROPOSAL_TYPE_MASTER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_PROPOSAL_TYPE_MASTER_SUCCESS:
      return {
        ...state,
        addProposal: [...state.addProposal, action.payload],
      }

    case ADD_NEW_PROPOSAL_TYPE_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PROPOSAL_TYPE_MASTER_SUCCESS:
      return {
        ...state,
        addProposal: state.addProposal.map(proposal =>
          proposal.id === action.payload.id ? { ...proposal, ...action.payload } : proposal
        ),
      }

    case UPDATE_PROPOSAL_TYPE_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROPOSAL_TYPE_MASTER_SUCCESS:
      return {
        ...state,
        proposal: state.proposal.data.filter(
          proposal => proposal.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_PROPOSAL_TYPE_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default proposalMaster
