import {
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_NAME_FAIL,
  GET_PROJECT_NAME_SUCCESS,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  ADD_PROJECT_UNIT_FAIL,
  ADD_PROJECT_UNIT_SUCCESS,
  ADD_PROJECT_NAME_FAIL,
  ADD_PROJECT_NAME_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_FAIL,
  ADD_GALLERY_SUCCESS,
  ADD_GALLERY_FAIL,
  UPDATE_GALLERY_SUCCESS,
  UPDATE_GALLERY_FAIL,
  DELETE_GALLERY_SUCCESS,
  DELETE_GALLERY_FAIL,
  GET_PROJECT_UNIT_SUCCESS,
  GET_PROJECT_UNIT_FAIL,
  UPDATE_PROJECT_UNIT_SUCCESS,
  UPDATE_PROJECT_UNIT_FAIL,
  DELETE_PROJECT_UNIT_SUCCESS,
  DELETE_PROJECT_UNIT_FAIL,
  GET_PROJECT_UNIT_DETAIL_SUCCESS,
  GET_PROJECT_UNIT_DETAIL_FAIL,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_FAIL,
  GET_VIDEO_DETAIL_SUCCESS,
  GET_VIDEO_DETAIL_FAIL,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_FAIL,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAIL,
  GET_PROJECT_MANAGER_SUCCESS,
  GET_PROJECT_MANAGER_FAIL,
  GET_FACILITIES_AVAILABLE_SUCCESS,
  GET_FACILITIES_AVAILABLE_FAIL,
  GET_COMPANY_FOR_PROJECT_SUCCESS,
  GET_COMPANY_FOR_PROJECT_FAIL,
  GET_COMPANY_DETAIL_FOR_PROJECT_SUCCESS,
  GET_COMPANY_DETAIL_FOR_PROJECT_FAIL,
  GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_SUCCESS,
  GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_FAIL,
  GET_COMPANY_LOCATION_FOR_PROJECT_SUCCESS,
  GET_COMPANY_LOCATION_FOR_PROJECT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  projects: [],
  galleries: [],
  videos: [],
  units: [],
  manager: [],
  company: [],
  location: [],
  facilities: [],
  projectDetail: {},
  companyDetail: {},
  locationDetail: {},
  projectUnitDetail: {},
  error: {},
  loading: true,
}

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        loading: true,
        // loading: true
      }

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_NAME_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        loading: true,
        // loading: true
      }

    case GET_PROJECT_NAME_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_PROJECT_NAME_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      }
    case ADD_PROJECT_UNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_PROJECT_UNIT_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      }
    case GET_PROJECT_NAME_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_PROJECT_NAME_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      }

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projectDetail: action.payload,
      }
    case ADD_PROJECT_NAME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_NAME_SUCCESS:
      return {
        ...state,
        projectDetail: action.payload,
      }

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.map(project =>
          project.id.toString() === action.payload.id.toString()
            ? { project, ...action.payload }
            : project
        ),
      }

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter(
          project => project.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // GALLERY

    case GET_GALLERY_SUCCESS:
      return {
        ...state,
        galleries: action.payload,
        loading: true,
        // loading: true
      }

    case GET_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_GALLERY_SUCCESS:
      return {
        ...state,
        galleries: [...state.gallery, action.payload],
      }

    case ADD_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_GALLERY_SUCCESS:
      return {
        ...state,
        galleries: state.galleries.map(gallery =>
          gallery.id.toString() === action.payload.id.toString()
            ? { gallery, ...action.payload }
            : gallery
        ),
      }

    case UPDATE_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        galleries: state.galleries.filter(
          gallery => gallery.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //PROJECT UNIT

    case GET_PROJECT_UNIT_SUCCESS:
      return {
        ...state,
        units: action.payload,
        loading: true,
        // loading: true
      }

    case GET_PROJECT_UNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_UNIT_DETAIL_SUCCESS:
      return {
        ...state,
        projectUnitDetail: action.payload,
        loading: true,
        // loading: true
      }

    case GET_PROJECT_UNIT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PROJECT_UNIT_SUCCESS:
      return {
        ...state,
        projectUnitDetail: state.projectUnitDetail.map(unit =>
          unit.id.toString() === action.payload.id.toString()
            ? { unit, ...action.payload }
            : unit
        ),
      }

    case UPDATE_PROJECT_UNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROJECT_UNIT_SUCCESS:
      return {
        ...state,
        projectUnitDetail: state.projectUnitDetail.filter(
          unit => unit.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_PROJECT_UNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //PROJECT GALLERY VIDEO

    case GET_VIDEO_SUCCESS:
      return {
        ...state,
        videos: action.payload,
        loading: true,
        // loading: true
      }

    case GET_VIDEO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_VIDEO_DETAIL_SUCCESS:
      return {
        ...state,
        videos: action.payload,
        loading: true,
        // loading: true
      }

    case GET_VIDEO_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_VIDEO_SUCCESS:
      return {
        ...state,
        videos: [...state.videos, action.payload],
      }

    case ADD_VIDEO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        videos: state.videos.filter(
          videos => videos.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_VIDEO_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_MANAGER_SUCCESS:
      return {
        ...state,
        manager: action.payload,
        loading: true,
        // loading: true
      }

    case GET_PROJECT_MANAGER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_FACILITIES_AVAILABLE_SUCCESS:
      return {
        ...state,
        facilities: action.payload,
        loading: true,
        // loading: true
      }

    case GET_FACILITIES_AVAILABLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANY_FOR_PROJECT_SUCCESS:
      return {
        ...state,
        company: action.payload,
        loading: true,
        // loading: true
      }

    case GET_COMPANY_FOR_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANY_DETAIL_FOR_PROJECT_SUCCESS:
      return {
        ...state,
        companyDetail: action.payload,
        loading: true,
        // loading: true
      }

    case GET_COMPANY_DETAIL_FOR_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_COMPANY_LOCATION_FOR_PROJECT_SUCCESS:
        return {
          ...state,
          location: action.payload,
          loading: true,
          // loading: true
        }
  
      case GET_COMPANY_LOCATION_FOR_PROJECT_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_SUCCESS:
        return {
          ...state,
          locationDetail: action.payload,
          loading: true,
          // loading: true
        }
  
      case GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_FAIL:
        return {
          ...state,
          error: action.payload,
        }
     
    default:
      return state
  }
}

export default projects
