import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_BILLING_CLIENT_DETAIL,
  GET_SERVICE_ENGAGEMENT,
  GET_BILLING_COMPANY_LIST,
  GET_BILLING_COMPANY_DETAIL,
  GET_SUPPLY_LIST,
  GET_PLACE_OF_SUPPLY,
  GET_APPLICABLE_RATE_LIST,
  GET_INVOICE_TYPE_MASTER_LIST,
  ADD_BILLING_SETUP,
  GET_BILLING_CLIENT_LIST,
  GET_INVOICE_NUMBER,
  ADD_NEW_BILL,
  GET_BILLING_REGISTER,
  GET_BILLING_DATA,
  EDIT_BILL,
  GET_INVOICE_PREVIEW_DETAIL,
  ADD_RECORD_PAYMENT,
  GET_PAYMENT_MODE,
  DELETE_BILLING_REGISTER,
  GET_RECORD_PAYMENT_DETAIL,
  GET_BILL_INVOICE_NO_LIST,
  ADD_BILLING_RECEIPT,
  GET_BILLING_RECEIPT_LIST,
  EDIT_BILLING_RECEIPT,
  GET_RECEIPT_NUMBER,
  SEND_MAIL_TO_CUSTOMER,
  DOWNLOAD_INVOICE,
  GET_BILLING_RECEIPT_DETAIL,
  DELETE_BILLING_RECORD,
  GET_BILLING_SETUP_DETAIL,
  EDIT_BILLING_SETUP,
  GET_SACHSN_CODE_BILLING_SETUP,
  GET_SACHSN_CODE_BILLING,
  GET_RECURRING_BILL,
  GET_ASSIGNED_PROJECT_UNIT_IN_FOR_BILLING,
  GET_ASSIGNED_PROJECT_UNIT_IN_BILLING,
  GET_PROJECT_LIST_IN_BILLING,
  GET_PROJECT_DETAIL_IN_BILLING,
  GET_PROJECT_UNIT_DETAIL_IN_BILLING,
  GET_BILLING_CLIENT_LIST_IN_RECEIPT,
  GET_BILLING_COMPANY_LIST_IN_RECEIPT,
  GET_BILLING_COMPANY_DETAIL_IN_RECEIPT,
  GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT,
  GET_GST_LOCATION_LIST,
  GET_GST_LOCATION_DETAIL,
  GET_COMPANY_FILTER,
  GET_COMPANY_LOCATION_FILTER,
  GET_CUSTOMER_FILTER,
  GET_PROJECT_FILTER,
  GET_FINANCIAL_YEAR_FILTER,
  GET_COMPANY_FILTER_FOR_RECEIPT,
  GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT,
  GET_PROJECT_FILTER_FOR_RECEIPT,
  GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT,
  GET_CUSTOMER_FILTER_FOR_RECEIPT,
  GET_RECEIPT_PREVIEW,
  SEND_RECEIPT_MAIL_TO_CUSTOMER,
  DELETE_CONSUMPTION_REGISTER,
  GET_CONSUMPTION_REGISTER_LIST,
  GET_CONSUMPTION_DATA,
  EDIT_CONSUMPTION,
  ADD_NEW_CONSUMPTION,
  GET_BILLING_CREDIT_NOTE_LIST,
  GET_CREDIT_NUMBER,
  GET_COMPONENTS_FOR_CREDIT_NOTE,
  ADD_CREDIT_NOTE,
  GET_CREDIT_NOTE_REASONS,
  GET_CREDIT_NOTE_INVOICE_LIST,
  GET_CREDIT_DATA,
  EDIT_CREDIT_NOTE,
  DELETE_CREDIT_NOTE,
  GET_CREDIT_INVOICE_PREVIEW_DETAIL,
} from "./actionTypes"
import {
  getBillingClientDetailSuccess,
  getBillingClientDetailFail,
  getEngagementServiceSuccess,
  getEngagementServiceFail,
  getBillingCompanyListSuccess,
  getBillingCompanyListFail,
  getBillingCompanyDetailSuccess,
  getBillingCompanyDetailFail,
  getSupplyListSuccess,
  getSupplyListFail,
  getPlaceOfSupplySuccess,
  getPlaceOfSupplyFail,
  getApplicableRateListSuccess,
  getApplicableRateListFail,
  getInvoiceTypeMasterListSuccess,
  getInvoiceTypeMasterListFail,
  addBillingSetupSuccess,
  addBillingSetupFail,
  getBillingClientListSuccess,
  getBillingClientListFail,
  getInvoiceNumberSuccess,
  getInvoiceNumberFail,
  addNewBillFail,
  getBillingRegisterListSuccess,
  getBillingRegisterListFail,
  addNewBillSuccess,
  getBillingDataSuccess,
  getBillingDataFail,
  editBillFail,
  editBillSuccess,
  getInvoicePreviewDetailSuccess,
  getInvoicePreviewDetailFail,
  getPaymentModeSuccess,
  getPaymentModeFail,
  addRecordPaymentFail,
  addRecordPaymentSuccess,
  deleteBillingRegisterSuccess,
  deleteBillingRegisterFail,
  getRecordPaymentDetailSuccess,
  getRecordPaymentDetailFail,
  addNewBillingReceiptFail,
  addNewBillingReceiptSuccess,
  getBillingReceiptListSuccess,
  getBillingReceiptListFail,
  getBillInvoiceNoListSuccess,
  getBillInvoiceNoListFail,
  getReceiptNumberSuccess,
  getReceiptNumberFail,
  sendEmailToCustomerSuccess,
  sendEmailToCustomerFail,
  downloadInvoiceSuccess,
  downloadInvoiceFail,
  getBillingReceiptDetailSuccess,
  getBillingReceiptDetailFail,
  editBillingReceiptSuccess,
  editBillingReceiptFail,
  deleteBillingRecordSuccess,
  deleteBillingRecordFail,
  getBillingSetupDetailSuccess,
  getBillingSetupDetailFail,
  editBillingSetupFail,
  editBillingSetupSuccess,
  getSacHsnCodeBillingSuccess,
  getSacHsnCodeBillingFail,
  getSacHsnCodeBillingSetupSuccess,
  getSacHsnCodeBillingSetupFail,
  getRecurringBillListFail,
  getRecurringBillListSuccess,
  getAssignedProjectUnitListFail,
  getAssignedProjectUnitListInBillingSuccess,
  getAssignedProjectUnitListInBillingFail,
  getProjectListInBillingSuccess,
  getProjectListInBillingFail,
  getProjectDetailInBillingSuccess,
  getProjectDetailInBillingFail,
  getProjectUnitDetailInBillingSuccess,
  getProjectUnitDetailInBillingFail,
  getBillingClientListInReceiptSuccess,
  getBillingClientListInReceiptFail,
  getBillingCompanyListInReceiptSuccess,
  getBillingCompanyListInReceiptFail,
  getBillingCompanyDetailInReceiptSuccess,
  getBillingCompanyDetailInReceiptFail,
  getOutstandingAmountDetailInBillingReceiptSuccess,
  getOutstandingAmountDetailInBillingReceiptFail,
  getGstLocationListSuccess,
  getGstLocationListFail,
  getGstLocationDetailSuccess,
  getGstLocationDetailFail,
  getCompanyFilterSuccess,
  getCompanyFilterFail,
  getCompanyLocationFilterSuccess,
  getCompanyLocationFilterFail,
  getCustomerFilterSuccess,
  getCustomerFilterFail,
  getProjectFilterSuccess,
  getProjectFilterFail,
  getFinancialYearFilterSuccess,
  getFinancialYearFilterFail,
  getCompanyFilterForReceiptSuccess,
  getCompanyFilterForReceiptFail,
  getCompanyLocationFilterForReceiptSuccess,
  getCompanyLocationFilterForReceiptFail,
  getCustomerFilterForReceiptSuccess,
  getCustomerFilterForReceiptFail,
  getProjectFilterForReceiptSuccess,
  getProjectFilterForReceiptFail,
  getFinancialYearFilterForReceiptSuccess,
  getFinancialYearFilterForReceiptFail,
  getReceiptPreviewSuccess,
  getReceiptPreviewFail,
  sendReceiptEmailToCustomerSuccess,
  sendReceiptEmailToCustomerFail,
  getConsumptionRegisterListSuccess,
  getConsumptionRegisterListFail,
  getConsumptionDataSuccess,
  getConsumptionDataFail,
  editConsumptionFail,
  editConsumptionSuccess,
  addNewConsumptionFail,
  addNewConsumptionSuccess,
  getBillingCreditNoteListSuccess,
  getBillingCreditNoteListFail,
  getCreditNumberSuccess,
  getCreditNumberFail,
  deleteConsumptionRegisterSuccess,
  deleteConsumptionRegisterFail,
  getComponentsForCreditNoteSuccess,
  getComponentsForCreditNoteFail,
  AddCreditNoteFail,
  AddCreditNoteSuccess,
  getCreditNoteReasonsSuccess,
  getCreditNoteReasonsFail,
  getCreditNoteInvoiceListSuccess,
  getCreditNoteInvoiceListFail,
  addCreditNoteSuccess,
  addCreditNoteFail,
  getCreditDataSuccess,
  getCreditDataFail,
  editCreditNoteSuccess,
  editCreditNoteFail,
  deleteCreditNoteSuccess,
  deleteCreditNoteFail,
  getCreditInvoicePreviewDetailSuccess,
  getCreditInvoicePreviewDetailFail,
} from "./actions"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods
import {
  getBillingClientDetail,
  getEngagementService,
  getBillingCompanyList,
  getBillingCompanyDetail,
  getSupplyList,
  getPlaceOfSupply,
  getApplicableRateList,
  getInvoiceTypeMasterList,
  addBillingSetup,
  getBillingClientList,
  getInvoiceNumber,
  addNewBill,
  getBillingRegisterList,
  getBillingData,
  editBill,
  getInvoicePreviewDetail,
  addRecordPayment,
  getPaymentmode,
  deleteBillingRegister,
  getRecordPaymentDetail,
  addNewBillingReceipt,
  editBillingReceipt,
  getBillingReceiptList,
  getBillInvoiceNoList,
  getReceiptNumber,
  sendMailToCustomer,
  downloadInvoice,
  getBillingReceiptDetail,
  deleteBillingRecord,
  getBillingSetupDetail,
  editBillingSetup,
  getSacHsnCodeBillingSetup,
  getSacHsnCodeBilling,
  getRecurringBillList,
  getAssignedProjectUnitListInBilling,
  getProjectListInBilling,
  getProjectDetailInBilling,
  getProjectUnitDetailInBilling,
  getBillingClientListInReceipt,
  getBillingCompanyListInReceipt,
  getBillingCompanyDetailInReceipt,
  getOutstandingAmountDetailInBillingReceipt,
  getGstLocationList,
  getGstLocationDetail,
  getCompanyFilter,
  getCompanyLocationFilter,
  getCustomerFilter,
  getProjectFilter,
  getFinancialYearFilter,
  getCompanyFilterForReceipt,
  getCustomerFilterForReceipt,
  getProjectFilterForReceipt,
  getFinancialYearFilterForReceipt,
  getCompanyLocationFilterForReceipt,
  getReceiptPreview,
  sendReceiptEmailToCustomer,
  editConsumption,
  getConsumptionRegisterList,
  getConsumptionData,
  deleteConsumptionRegister,
  addNewConsumption,
  getBillingCreditNoteList,
  getCreditNumber,
  getComponentsForCreditNote,
  addCreditNote,
  getCreditNoteReasons,
  getCreditNoteInvoiceList,
  getCreditData,
  editCreditNote,
  deleteCreditNote,
  getCreditInvoicePreviewDetail,
} from "helpers/fakebackend_helper"

function* fetchBillingClientList({ id }) {
  try {
    const response = yield call(getBillingClientList, id)
    yield put(getBillingClientListSuccess(response))
  } catch (error) {
    yield put(getBillingClientListFail(error))
  }
}

function* fetchBillingClientListInReceipt({ id }) {
  try {
    const response = yield call(getBillingClientListInReceipt, id)
    yield put(getBillingClientListInReceiptSuccess(response))
  } catch (error) {
    yield put(getBillingClientListInReceiptFail(error))
  }
}
function* fetchBillingCompanyListInReceipt({ id }) {
  try {
    const response = yield call(getBillingCompanyListInReceipt, id)
    yield put(getBillingCompanyListInReceiptSuccess(response))
  } catch (error) {
    yield put(getBillingCompanyListInReceiptFail(error))
  }
}

function* fetchBillingClientDetail({ clientId }) {
  try {
    const response = yield call(getBillingClientDetail, clientId)
    yield put(getBillingClientDetailSuccess(response))
  } catch (error) {
    yield put(getBillingClientDetailFail(error))
  }
}
function* fetchEngagementService({ projectId }) {
  try {
    const response = yield call(getEngagementService, projectId)
    yield put(getEngagementServiceSuccess(response))
  } catch (error) {
    yield put(getEngagementServiceFail(error))
  }
}

function* fetchBillingCompanyList({ unit_id }) {
  try {
    const response = yield call(getBillingCompanyList, unit_id)
    yield put(getBillingCompanyListSuccess(response))
  } catch (error) {
    yield put(getBillingCompanyListFail(error))
  }
}

function* fetchBillingCompanyDetail({ companyId }) {
  try {
    const response = yield call(getBillingCompanyDetail, companyId)
    yield put(getBillingCompanyDetailSuccess(response))
  } catch (error) {
    yield put(getBillingCompanyDetailFail(error))
  }
}

function* fetchSupplyList() {
  try {
    const response = yield call(getSupplyList)
    yield put(getSupplyListSuccess(response))
  } catch (error) {
    yield put(getSupplyListFail(error))
  }
}

function* fetchPlaceOfSupply() {
  try {
    const response = yield call(getPlaceOfSupply)
    yield put(getPlaceOfSupplySuccess(response))
  } catch (error) {
    yield put(getPlaceOfSupplyFail(error))
  }
}
function* fetchApplicableRateList() {
  try {
    const response = yield call(getApplicableRateList)
    yield put(getApplicableRateListSuccess(response))
  } catch (error) {
    yield put(getApplicableRateListFail(error))
  }
}
function* fetchInvoiceTypeMasterList() {
  try {
    const response = yield call(getInvoiceTypeMasterList)
    yield put(getInvoiceTypeMasterListSuccess(response))
  } catch (error) {
    yield put(getInvoiceTypeMasterListFail(error))
  }
}

function* onAddNewBillingSetup({ payload: { history, bill } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(addBillingSetup, bill)
    if (response.status === true) {
      loadingSwal.close()
      yield put(addBillingSetupSuccess(response))

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Billing Setup has been Saved.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/clients/service-engagement/all-engagement-list")
        }
      })
    } else {
      loadingSwal.close()

      Swal.fire({
        title: "failure",
        icon: "error",
        text: "Your Billing Setup has not been saved.",
      })
    }
  } catch (error) {
    yield put(addBillingSetupFail(error))
    console.error("Error:", error)
    Swal.fire({
      title: "Error",
      icon: "error",
      text:
        error.response.data.message ||
        "An error occurred while saving your Billing Setup.",
    })
  }
}
function* onEditNewBillingSetup({ payload: { history, billId, bill } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(editBillingSetup, billId, bill)
    if (response.status === true) {
      loadingSwal.close()
      yield put(editBillingSetupSuccess(response))

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Billing Setup has been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/clients/service-engagement/all-engagement-list")
        }
      })
    } else {
      loadingSwal.close()

      Swal.fire({
        title: "failure",
        icon: "error",
        text: "Your Billing Setup has not been Updated.",
      })
    }
  } catch (error) {
    yield put(editBillingSetupFail(error))
    console.error("Error:", error)
    Swal.fire({
      title: "Error",
      icon: "error",
      text:
        error.response.data.message ||
        "An error occurred while saving your Billing Setup.",
    })
  }
}

function* onAddNewBill({ payload: { history, bill } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(addNewBill, bill)
    if (response.status === true) {
      yield put(addNewBillSuccess(response))
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Bill has been Saved.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/billing/billing-register")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewBillFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}

function* onEditBill({ payload: { billingId, history, bill } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(editBill, billingId, bill)
    if (response.status === true) {
      yield put(editBillSuccess(response))
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Bill has been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/billing/billing-register")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(editBillFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}

function* fetchInvoiceNumber({ companyId }) {
  try {
    const response = yield call(getInvoiceNumber, companyId)
    yield put(getInvoiceNumberSuccess(response))
  } catch (error) {
    yield put(getInvoiceNumberFail(error))
  }
}

function* fetchBillingRegisterList({ id }) {
  try {
    const response = yield call(getBillingRegisterList, id)
    yield put(getBillingRegisterListSuccess(response))
  } catch (error) {
    yield put(getBillingRegisterListFail(error))
  }
}

function* fetchBillingData({ billingId }) {
  try {
    const response = yield call(getBillingData, billingId)
    yield put(getBillingDataSuccess(response))
  } catch (error) {
    yield put(getBillingDataFail(error))
  }
}

function* fetchCreditData({ billingId }) {
  try {
    const response = yield call(getCreditData, billingId)
    yield put(getCreditDataSuccess(response))
  } catch (error) {
    yield put(getCreditDataFail(error))
  }
}

function* fetchInvoicePreviewDetail({ billId }) {
  try {
    const response = yield call(getInvoicePreviewDetail, billId)
    yield put(getInvoicePreviewDetailSuccess(response))
  } catch (error) {
    yield put(getInvoicePreviewDetailFail(error))
  }
}
function* fetchCreditInvoicePreviewDetail({ billId }) {
  try {
    const response = yield call(getCreditInvoicePreviewDetail, billId)
    yield put(getCreditInvoicePreviewDetailSuccess(response))
  } catch (error) {
    yield put(getCreditInvoicePreviewDetailFail(error))
  }
}

function* fetchPaymentmode() {
  try {
    const response = yield call(getPaymentmode)
    yield put(getPaymentModeSuccess(response))
  } catch (error) {
    yield put(getPaymentModeFail(error))
  }
}

function* onAddRecordPayment({ payload: { history, recordPayment } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(addRecordPayment, recordPayment)
    if (response.status === true) {
      yield put(addRecordPaymentSuccess(response))
      const response2 = yield call(getReceiptNumber, {
        location_id: recordPayment.location_id,
        receipt_date: recordPayment.receipt_date,
      })
      console.log("response2", response2)
      if (response2.status === true) {
        yield put(getReceiptNumberSuccess(response))
      }
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Payment Record has been Saved.",
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addRecordPaymentFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}

function* onBillingRegisterDelete({ billId }) {
  try {
    const response = yield call(deleteBillingRegister, billId)
    yield put(deleteBillingRegisterSuccess(response))
  } catch (error) {
    yield put(deleteBillingRegisterFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}

function* fetchRecordPaymentDetail({ billId }) {
  try {
    const response = yield call(getRecordPaymentDetail, billId)
    yield put(getRecordPaymentDetailSuccess(response))
  } catch (error) {
    yield put(getRecordPaymentDetailFail(error))
  }
}

function* fetchBillingReceiptList({ payload: { values } }) {
  try {
    const response = yield call(getBillingReceiptList, values)
    yield put(getBillingReceiptListSuccess(response))
  } catch (error) {
    yield put(getBillingReceiptListFail(error))
  }
}

function* onAddNewReceipt({ payload: { history, receipt } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(addNewBillingReceipt, receipt)
    if (response.status === true) {
      yield put(addNewBillingReceiptSuccess(response))
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Bill Receipt has been Saved.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/billing/receipt")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewBillingReceiptFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}

function* onEditBillingReceipt({ payload: { paymentId, history, bill } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(editBillingReceipt, paymentId, bill)
    if (response.status === true) {
      yield put(editBillingReceiptSuccess(response))
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Bill has been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/billing/receipt")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(editBillingReceiptFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}
function* fetchBillInvoiceNoList({ companyId }) {
  try {
    const response = yield call(getBillInvoiceNoList, companyId)
    yield put(getBillInvoiceNoListSuccess(response))
  } catch (error) {
    yield put(getBillInvoiceNoListFail(error))
  }
}

function* fetchReceiptNumber({ companyId }) {
  try {
    const response = yield call(getReceiptNumber, companyId)
    yield put(getReceiptNumberSuccess(response))
  } catch (error) {
    yield put(getReceiptNumberFail(error))
  }
}

function* onSendMailToCustomer({ payload: { customerId, billId } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(sendMailToCustomer, customerId, billId)
    if (response.status === true) {
      yield put(sendEmailToCustomerSuccess(response))
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Email has been Sent to Customer.",
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(sendEmailToCustomerFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Your Email has not been Sent",
    })
  }
}

function* fetchdownloadInvoice({ billId }) {
  try {
    const response = yield call(downloadInvoice, billId)
    yield put(downloadInvoiceSuccess(response))
  } catch (error) {
    yield put(downloadInvoiceFail(error))
  }
}

function* fetchBillingReceiptDetail({ receiptId }) {
  try {
    const response = yield call(getBillingReceiptDetail, receiptId)
    yield put(getBillingReceiptDetailSuccess(response))
  } catch (error) {
    yield put(getBillingReceiptDetailFail(error))
  }
}

function* onBillingRecordDelete({ billId }) {
  try {
    const response = yield call(deleteBillingRecord, billId)
    yield put(deleteBillingRecordSuccess(response))
  } catch (error) {
    yield put(deleteBillingRecordFail(error))
  }
}

function* fetchBillingSetupDetail({ billId }) {
  try {
    const response = yield call(getBillingSetupDetail, billId)
    yield put(getBillingSetupDetailSuccess(response))
  } catch (error) {
    yield put(getBillingSetupDetailFail(error))
  }
}

function* fetchSacHsnCodeBillingSetup() {
  try {
    const response = yield call(getSacHsnCodeBillingSetup)
    yield put(getSacHsnCodeBillingSetupSuccess(response))
  } catch (error) {
    yield put(getSacHsnCodeBillingSetupFail(error))
  }
}

function* fetchSacHsnCodeBilling() {
  try {
    const response = yield call(getSacHsnCodeBilling)
    yield put(getSacHsnCodeBillingSuccess(response))
  } catch (error) {
    yield put(getSacHsnCodeBillingFail(error))
  }
}

//Recurring bill
function* fetchRecurringBillList() {
  try {
    const response = yield call(getRecurringBillList)
    yield put(getRecurringBillListSuccess(response))
  } catch (error) {
    yield put(getRecurringBillListFail(error))
  }
}

// assignedProjectUnit
function* fetchAssignedProjectUnitList({ value }) {
  try {
    console.log(value)
    const response = yield call(getAssignedProjectUnitListInBilling, value)
    yield put(getAssignedProjectUnitListInBillingSuccess(response))
  } catch (error) {
    yield put(getAssignedProjectUnitListInBillingFail(error))
  }
}
//projectList
function* fetchProjectListInBilling({ id }) {
  try {
    const response = yield call(getProjectListInBilling, id)
    yield put(getProjectListInBillingSuccess(response))
  } catch (error) {
    yield put(getProjectListInBillingFail(error))
  }
}
//project Details
function* fetchProjectDetailInBilling({ projectId }) {
  try {
    const response = yield call(getProjectDetailInBilling, projectId)
    yield put(getProjectDetailInBillingSuccess(response))
  } catch (error) {
    yield put(getProjectDetailInBillingFail(error))
  }
}
//project Unit Details
function* fetchProjectUnitDetailInBilling({ projectUnitId }) {
  try {
    const response = yield call(getProjectUnitDetailInBilling, projectUnitId)
    yield put(getProjectUnitDetailInBillingSuccess(response))
  } catch (error) {
    yield put(getProjectUnitDetailInBillingFail(error))
  }
}

function* fetchBillingCompanyDetailInReceipt({ companyId }) {
  try {
    const response = yield call(getBillingCompanyDetailInReceipt, companyId)
    yield put(getBillingCompanyDetailInReceiptSuccess(response))
  } catch (error) {
    yield put(getBillingCompanyDetailInReceiptFail(error))
  }
}
//oustanding Amount in receipt
function* fetchOutstandingAmountDetailInBillingReceipt({ invoiceId }) {
  try {
    const response = yield call(
      getOutstandingAmountDetailInBillingReceipt,
      invoiceId
    )
    yield put(getOutstandingAmountDetailInBillingReceiptSuccess(response))
  } catch (error) {
    yield put(getOutstandingAmountDetailInBillingReceiptFail(error))
  }
}

function* fetchGstLocationList({ id }) {
  try {
    const response = yield call(getGstLocationList, id)
    yield put(getGstLocationListSuccess(response))
  } catch (error) {
    yield put(getGstLocationListFail(error))
  }
}

function* fetchGstLocationDetail({ locationId }) {
  try {
    const response = yield call(getGstLocationDetail, locationId)
    yield put(getGstLocationDetailSuccess(response))
  } catch (error) {
    yield put(getGstLocationDetailFail(error))
  }
}
//Billing register filter
function* fetchCompanyFilter({ userId }) {
  try {
    const response = yield call(getCompanyFilter, userId)
    yield put(getCompanyFilterSuccess(response))
  } catch (error) {
    yield put(getCompanyFilterFail(error))
  }
}
function* fetchCompanyLocationFilter({ userId }) {
  try {
    const response = yield call(getCompanyLocationFilter, userId)
    yield put(getCompanyLocationFilterSuccess(response))
  } catch (error) {
    yield put(getCompanyLocationFilterFail(error))
  }
}
function* fetchCustomerFilter({ userId }) {
  try {
    const response = yield call(getCustomerFilter, userId)
    yield put(getCustomerFilterSuccess(response))
  } catch (error) {
    yield put(getCustomerFilterFail(error))
  }
}
function* fetchProjectFilter({ userId }) {
  try {
    const response = yield call(getProjectFilter, userId)
    yield put(getProjectFilterSuccess(response))
  } catch (error) {
    yield put(getProjectFilterFail(error))
  }
}
function* fetchFinancialYearFilter({ userId }) {
  try {
    const response = yield call(getFinancialYearFilter, userId)
    yield put(getFinancialYearFilterSuccess(response))
  } catch (error) {
    yield put(getFinancialYearFilterFail(error))
  }
}

//billing payment filter
function* fetchCompanyFilterForReceipt({ userId }) {
  try {
    const response = yield call(getCompanyFilterForReceipt, userId)
    yield put(getCompanyFilterForReceiptSuccess(response))
  } catch (error) {
    yield put(getCompanyFilterForReceiptFail(error))
  }
}
function* fetchCompanyLocationFilterForReceipt({ userId }) {
  try {
    const response = yield call(getCompanyLocationFilterForReceipt, userId)
    yield put(getCompanyLocationFilterForReceiptSuccess(response))
  } catch (error) {
    yield put(getCompanyLocationFilterForReceiptFail(error))
  }
}
function* fetchCustomerFilterForReceipt({ userId }) {
  try {
    const response = yield call(getCustomerFilterForReceipt, userId)
    yield put(getCustomerFilterForReceiptSuccess(response))
  } catch (error) {
    yield put(getCustomerFilterForReceiptFail(error))
  }
}
function* fetchProjectFilterForReceipt({ userId }) {
  try {
    const response = yield call(getProjectFilterForReceipt, userId)
    yield put(getProjectFilterForReceiptSuccess(response))
  } catch (error) {
    yield put(getProjectFilterForReceiptFail(error))
  }
}
function* fetchFinancialYearFilterForReceipt({ userId }) {
  try {
    const response = yield call(getFinancialYearFilterForReceipt, userId)
    yield put(getFinancialYearFilterForReceiptSuccess(response))
  } catch (error) {
    yield put(getFinancialYearFilterForReceiptFail(error))
  }
}

function* fetchReceiptPreview({ id }) {
  try {
    const response = yield call(getReceiptPreview, id)
    yield put(getReceiptPreviewSuccess(response))
  } catch (error) {
    yield put(getReceiptPreviewFail(error))
  }
}

function* onSendReceiptMailToCustomer({ payload: { customerId, receiptId } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(
      sendReceiptEmailToCustomer,
      customerId,
      receiptId
    )
    if (response.status === true) {
      yield put(sendReceiptEmailToCustomerSuccess(response))
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Email has been Sent to Customer.",
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(sendReceiptEmailToCustomerFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Your Email has not been Sent",
    })
  }
}
//consumption api
function* onAddNewConsumption({ payload: { history, consumption } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(addNewConsumption, consumption)
    if (response.status === true) {
      yield put(addNewConsumptionSuccess(response))
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Consumption has been Saved.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/billing/all-consumption")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewConsumptionFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}

function* onEditConsumption({
  payload: { consumptionId, history, consumption },
}) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(editConsumption, consumptionId, consumption)
    if (response.status === true) {
      yield put(editConsumptionSuccess(response))
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Consumption has been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/billing/billing-register")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(editConsumptionFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}

function* fetchConsumptionRegisterList({ consumption }) {
  try {
    const response = yield call(getConsumptionRegisterList, consumption)
    yield put(getConsumptionRegisterListSuccess(response))
  } catch (error) {
    yield put(getConsumptionRegisterListFail(error))
  }
}
function* fetchConsumptionData({ consumptionId }) {
  try {
    const response = yield call(getConsumptionData, consumptionId)
    yield put(getConsumptionDataSuccess(response))
  } catch (error) {
    yield put(getConsumptionDataFail(error))
  }
}

function* onConsumptionRegisterDelete({ consumptionId }) {
  try {
    const response = yield call(deleteConsumptionRegister, consumptionId)
    yield put(deleteConsumptionRegisterSuccess(response))
  } catch (error) {
    yield put(deleteConsumptionRegisterFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}
//credit Note
function* fetchBillingCreditNoteList({ id }) {
  try {
    const response = yield call(getBillingCreditNoteList, id)
    yield put(getBillingCreditNoteListSuccess(response))
  } catch (error) {
    yield put(getBillingCreditNoteListFail(error))
  }
}

function* fetchCreditNumber({ companyId }) {
  try {
    const response = yield call(getCreditNumber, companyId)
    yield put(getCreditNumberSuccess(response))
  } catch (error) {
    yield put(getCreditNumberFail(error))
  }
}

  function* fetchComponentsForCreditNote({ companyId }) {
    try {
      const response = yield call(getComponentsForCreditNote, companyId)
      yield put(getComponentsForCreditNoteSuccess(response))
    } catch (error) {
      yield put(getComponentsForCreditNoteFail(error))
    }
  }
  function* fetchCreditNoteReasons({ companyId }) {
    try {
      const response = yield call(getCreditNoteReasons, companyId)
      yield put(getCreditNoteReasonsSuccess(response))
    } catch (error) {
      yield put(getCreditNoteReasonsFail(error))
    }
  }

  function* onAddCreditNote({ payload: { history, data } }) {
    try {
      const loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })
      const response = yield call(addCreditNote, data)
      if (response.status === true) {
        yield put(addCreditNoteSuccess(response))
        loadingSwal.close()

        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Your Credit Note has been Submitted.",
        }).then(result => {
          if (result.isConfirmed) {
            history("/billing/credit-note-list")
          }
        })
      } else {
        throw new Error(
          response && response.message ? response.message : "Unknown error"
        )
      }
    } catch (error) {
      yield put(addCreditNoteFail(error))
      Swal.fire({
        title: "Failure",
        icon: "error",
        text: error.response.data.message || "Unknown error",
      })
    }
  }
  function* onEditCreditNote({ payload: {billingId, history, data } }) {
    try {
      const loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })
      const response = yield call(editCreditNote, billingId,data)
      if (response.status === true) {
        yield put(editCreditNoteSuccess(response))
        loadingSwal.close()

        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Your Credit Note has been Submitted.",
        }).then(result => {
          if (result.isConfirmed) {
            history("/billing/credit-note-list")
          }
        })
      } else {
        throw new Error(
          response && response.message ? response.message : "Unknown error"
        )
      }
    } catch (error) {
      yield put(editCreditNoteFail(error))
      Swal.fire({
        title: "Failure",
        icon: "error",
        text: error.response.data.message || "Unknown error",
      })
    }
  }

  function* fetchCreditNoteInvoiceList({ companyId }) {
    try {
      const response = yield call(getCreditNoteInvoiceList, companyId)
      yield put(getCreditNoteInvoiceListSuccess(response))
    } catch (error) {
      yield put(getCreditNoteInvoiceListFail(error))
    }
  }
  
  
function* onDeleteCreditNote({ billId }) {
  try {
    const response = yield call(deleteCreditNote, billId)
    yield put(deleteCreditNoteSuccess(response))
  } catch (error) {
    yield put(deleteCreditNoteFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}


  function* billingSaga() {
    yield takeEvery(GET_BILLING_CLIENT_LIST, fetchBillingClientList)
    yield takeEvery(
      GET_BILLING_CLIENT_LIST_IN_RECEIPT,
      fetchBillingClientListInReceipt
    )
    yield takeEvery(
      GET_BILLING_COMPANY_LIST_IN_RECEIPT,
      fetchBillingCompanyListInReceipt
    )
    yield takeEvery(GET_BILLING_CLIENT_DETAIL, fetchBillingClientDetail)
    yield takeEvery(GET_SERVICE_ENGAGEMENT, fetchEngagementService)
    yield takeEvery(GET_BILLING_COMPANY_LIST, fetchBillingCompanyList)
    yield takeEvery(GET_BILLING_COMPANY_DETAIL, fetchBillingCompanyDetail)
    yield takeEvery(
      GET_BILLING_COMPANY_DETAIL_IN_RECEIPT,
      fetchBillingCompanyDetailInReceipt
    )
    yield takeEvery(GET_SUPPLY_LIST, fetchSupplyList)
    yield takeEvery(GET_PLACE_OF_SUPPLY, fetchPlaceOfSupply)
    yield takeEvery(GET_APPLICABLE_RATE_LIST, fetchApplicableRateList)
    yield takeEvery(GET_INVOICE_TYPE_MASTER_LIST, fetchInvoiceTypeMasterList)
    yield takeEvery(ADD_BILLING_SETUP, onAddNewBillingSetup)
    yield takeEvery(EDIT_BILLING_SETUP, onEditNewBillingSetup)
    yield takeEvery(ADD_NEW_BILL, onAddNewBill)
    yield takeEvery(GET_BILLING_REGISTER, fetchBillingRegisterList)
    yield takeEvery(GET_BILLING_CREDIT_NOTE_LIST, fetchBillingCreditNoteList)
    yield takeEvery(GET_BILLING_DATA, fetchBillingData)
    yield takeEvery(GET_CREDIT_DATA, fetchCreditData)
    yield takeEvery(EDIT_BILL, onEditBill)
    yield takeEvery(DELETE_BILLING_REGISTER, onBillingRegisterDelete)
    yield takeEvery(GET_INVOICE_NUMBER, fetchInvoiceNumber)
    yield takeEvery(GET_INVOICE_PREVIEW_DETAIL, fetchInvoicePreviewDetail)
    yield takeEvery(GET_CREDIT_INVOICE_PREVIEW_DETAIL, fetchCreditInvoicePreviewDetail)
    yield takeEvery(ADD_RECORD_PAYMENT, onAddRecordPayment)
    yield takeEvery(ADD_BILLING_RECEIPT, onAddNewReceipt)
    yield takeEvery(GET_PAYMENT_MODE, fetchPaymentmode)
    yield takeEvery(GET_RECORD_PAYMENT_DETAIL, fetchRecordPaymentDetail)
    yield takeEvery(GET_BILL_INVOICE_NO_LIST, fetchBillInvoiceNoList)
    yield takeEvery(GET_BILLING_RECEIPT_LIST, fetchBillingReceiptList)
    yield takeEvery(EDIT_BILLING_RECEIPT, onEditBillingReceipt)
    yield takeEvery(GET_RECEIPT_NUMBER, fetchReceiptNumber)
    yield takeEvery(SEND_MAIL_TO_CUSTOMER, onSendMailToCustomer)
    yield takeEvery(DOWNLOAD_INVOICE, fetchdownloadInvoice)
    yield takeEvery(GET_BILLING_RECEIPT_DETAIL, fetchBillingReceiptDetail)
    yield takeEvery(DELETE_BILLING_RECORD, onBillingRecordDelete)
    yield takeEvery(GET_BILLING_SETUP_DETAIL, fetchBillingSetupDetail)
    yield takeEvery(GET_SACHSN_CODE_BILLING_SETUP, fetchSacHsnCodeBillingSetup)
    yield takeEvery(GET_SACHSN_CODE_BILLING, fetchSacHsnCodeBilling)
    yield takeEvery(GET_RECURRING_BILL, fetchRecurringBillList)
    yield takeEvery(
      GET_ASSIGNED_PROJECT_UNIT_IN_BILLING,
      fetchAssignedProjectUnitList
    )
    yield takeEvery(GET_PROJECT_LIST_IN_BILLING, fetchProjectListInBilling)
    yield takeEvery(GET_PROJECT_DETAIL_IN_BILLING, fetchProjectDetailInBilling)
    yield takeEvery(
      GET_PROJECT_UNIT_DETAIL_IN_BILLING,
      fetchProjectUnitDetailInBilling
    )
    yield takeEvery(
      GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT,
      fetchOutstandingAmountDetailInBillingReceipt
    )
    yield takeEvery(GET_GST_LOCATION_LIST, fetchGstLocationList)
    yield takeEvery(GET_GST_LOCATION_DETAIL, fetchGstLocationDetail)
    //Register filter
    yield takeEvery(GET_COMPANY_FILTER, fetchCompanyFilter)
    yield takeEvery(GET_COMPANY_LOCATION_FILTER, fetchCompanyLocationFilter)
    yield takeEvery(GET_CUSTOMER_FILTER, fetchCustomerFilter)
    yield takeEvery(GET_PROJECT_FILTER, fetchProjectFilter)
    yield takeEvery(GET_FINANCIAL_YEAR_FILTER, fetchFinancialYearFilter)
    //receipt filter
    yield takeEvery(
      GET_COMPANY_FILTER_FOR_RECEIPT,
      fetchCompanyFilterForReceipt
    )
    yield takeEvery(
      GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT,
      fetchCompanyLocationFilterForReceipt
    )
    yield takeEvery(
      GET_CUSTOMER_FILTER_FOR_RECEIPT,
      fetchCustomerFilterForReceipt
    )
    yield takeEvery(
      GET_PROJECT_FILTER_FOR_RECEIPT,
      fetchProjectFilterForReceipt
    )
    yield takeEvery(
      GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT,
      fetchFinancialYearFilterForReceipt
    )
    yield takeEvery(GET_RECEIPT_PREVIEW, fetchReceiptPreview)
    yield takeEvery(SEND_RECEIPT_MAIL_TO_CUSTOMER, onSendReceiptMailToCustomer)
    //
    yield takeEvery(ADD_NEW_CONSUMPTION, onAddNewConsumption)
    yield takeEvery(EDIT_CONSUMPTION, onEditConsumption)
    yield takeEvery(GET_CONSUMPTION_REGISTER_LIST, fetchConsumptionRegisterList)
    yield takeEvery(GET_CONSUMPTION_DATA, fetchConsumptionData)
    yield takeEvery(DELETE_CONSUMPTION_REGISTER, onConsumptionRegisterDelete)
    //credit note
    yield takeEvery(
      GET_COMPONENTS_FOR_CREDIT_NOTE,
      fetchComponentsForCreditNote
    )
    yield takeEvery(GET_CREDIT_NOTE_REASONS, fetchCreditNoteReasons)
    yield takeEvery(GET_CREDIT_NUMBER, fetchCreditNumber)
    yield takeEvery(ADD_CREDIT_NOTE, onAddCreditNote)
    yield takeEvery(EDIT_CREDIT_NOTE, onEditCreditNote)
    yield takeEvery(GET_CREDIT_NOTE_INVOICE_LIST, fetchCreditNoteInvoiceList)
    yield takeEvery(DELETE_CREDIT_NOTE, onDeleteCreditNote)

  }

export default billingSaga
