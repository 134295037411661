import {
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  GET_INVOICE_DETAIL_FAIL,
  GET_INVOICE_DETAIL_SUCCESS,
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_FAIL,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAIL,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAIL,
  GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_FAIL,
  GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_SUCCESS,
  GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_SUCCESS,
  GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  invoices: [],
  rate: [],
  sachsn: [],
  invoiceDetail: {},
  error: {},
  loading: true,
}

const invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        loading: true,
        // loading: true
      }

    case GET_INVOICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: [...state.invoices, action.payload],
      }

    case ADD_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: action.payload,
      }

    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: state.invoices.map(invoice =>
          invoice.id.toString() === action.payload.id.toString()
            ? { invoice, ...action.payload }
            : invoice
        ),
      }

    case UPDATE_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: state.invoices.data.filter(
          invoice => invoice.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_SUCCESS:
      return {
        ...state,
        rate: action.payload,
        loading: true,
        // loading: true
      }

    case GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_SUCCESS:
        return {
          ...state,
          sachsn: action.payload,
          loading: true,
          // loading: true
        }
  
      case GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_FAIL:
        return {
          ...state,
          error: action.payload,
        }
    default:
      return state
  }
}

export default invoices
