import {
  GET_RENEWALS_FAIL,
  GET_RENEWALS_SUCCESS,
  GET_RENEWAL_DETAIL_FAIL,
  GET_RENEWAL_DETAIL_SUCCESS,
  ADD_RENEWAL_SUCCESS,
  ADD_RENEWAL_FAIL,
  UPDATE_RENEWAL_SUCCESS,
  UPDATE_RENEWAL_FAIL,
  DECLINE_RENEWAL_SUCCESS,
  DECLINE_RENEWAL_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  renewals: [],
  addRenewal:[],
  renewalDetail: {},
  error: {},
  loading: true
};

const renewals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RENEWALS_SUCCESS:
      return {
        ...state,
        renewals: action.payload,
        loading: true
      };

    case GET_RENEWALS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_RENEWAL_SUCCESS:
      return {
        ...state,
        addRenewal: [...state.addRenewal, action.payload],
      };

    case ADD_RENEWAL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_RENEWAL_DETAIL_SUCCESS:
      return {
        ...state,
        renewalDetail: action.payload,
      };

    case UPDATE_RENEWAL_SUCCESS:
      return {
        ...state,
        addRenewal: state.addRenewal.map(renewal =>
          renewal.id === action.payload.id
            ? { renewal, ...action.payload }
            : renewal
        ),
      };

    case UPDATE_RENEWAL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DECLINE_RENEWAL_SUCCESS:
      return {
        ...state,
        renewals: state.renewals.renewals.filter(
          renewal => renewal.id.toString() !== action.payload.toString()
        ),
      };

    case DECLINE_RENEWAL_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    case GET_RENEWAL_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default renewals;
