import {
  GET_CITIES,
  GET_CITIES_FAIL,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_SUCCESS,
  GET_PROJECT_LIST_BY_CUSTOMER_ID,
  GET_PROJECT_LIST_BY_CUSTOMER_ID_FAIL,
  GET_PROJECT_LIST_BY_CUSTOMER_ID_SUCCESS,
  GET_PROJECT_LIST_BY_VENDOR_ID,
  GET_PROJECT_LIST_BY_VENDOR_ID_FAIL,
  GET_PROJECT_LIST_BY_VENDOR_ID_SUCCESS,
  GET_PROJECT_UNIT_DATA,
  GET_PROJECT_UNIT_DATA_FAIL,
  GET_PROJECT_UNIT_DATA_SUCCESS,
  GET_STATES,
  GET_STATES_FAIL,
  GET_STATES_SUCCESS,
} from "./actionTypes"

export const getCities = (stateId) => ({
  type: GET_CITIES,
  stateId,
})

export const getCitiesSuccess = companies => ({
  type: GET_CITIES_SUCCESS,
  payload: companies,
})

export const getCitiesFail = error => ({
  type: GET_CITIES_FAIL,
  payload: error,
})

export const getStates = (states) => ({
  type: GET_STATES,
  states
})

export const getStatesSuccess = companies => ({
  type: GET_STATES_SUCCESS,
  payload: companies,
})

export const getStatesFail = error => ({
  type: GET_STATES_FAIL,
  payload: error,
})

export const getCountries = () => ({
  type: GET_COUNTRIES,
})

export const getCountriesSuccess = companies => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: companies,
})

export const getCountriesFail = error => ({
  type: GET_COUNTRIES_FAIL,
  payload: error,
})

export const getProjectListByCustomerId = id => ({
  type: GET_PROJECT_LIST_BY_CUSTOMER_ID,
  id,
})

export const getProjectListByCustomerIdSuccess = projectList => ({
  type: GET_PROJECT_LIST_BY_CUSTOMER_ID_SUCCESS,
  payload: projectList,
})

export const getProjectListByCustomerIdFail = error => ({
  type: GET_PROJECT_LIST_BY_CUSTOMER_ID_FAIL,
  payload: error,
})
export const getProjectUnitData = id => ({
  type: GET_PROJECT_UNIT_DATA,
  id,
})

export const getProjectUnitDataSuccess = projectUnit => ({
  type: GET_PROJECT_UNIT_DATA_SUCCESS,
  payload: projectUnit,
})

export const getProjectUnitDataFail = error => ({
  type: GET_PROJECT_UNIT_DATA_FAIL,
  payload: error,
})
export const getProjectListByvendorId = id => ({
  type: GET_PROJECT_LIST_BY_VENDOR_ID,
  id,
})

export const getProjectListByvendorIdSuccess = projectList => ({
  type: GET_PROJECT_LIST_BY_VENDOR_ID_SUCCESS,
  payload: projectList,
})

export const getProjectListByvendorIdFail = error => ({
  type: GET_PROJECT_LIST_BY_VENDOR_ID_FAIL,
  payload: error,
})