import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  addNewUnitMaster,
  getUnitMasterDetail,
  updateUnitMaster,
} from "store/actions"

const NewUnitMaster = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const unitState = state => state.unitMaster.unitDetail.data
  const loadingState = state => state.unitMaster.loading
  const [isEditMode, setIsEditMode] = useState(false)
  const unitProperties = createSelector(
    unitState,
    loadingState,
    (unit, loading) => {
      return {
        unit: unit,
        loading: loading,
      }
    }
  )
  const { unit, loading } = useSelector(unitProperties)
  const [unitId, setUnitId] = useState()
  const [isLoading, setLoading] = useState(loading)

  const vendorData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const unitId = url.pathname.split("/settings/edit-unit-master/")[1]
      setUnitId(unitId)

      dispatch(getUnitMasterDetail(unitId))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      vendorData()
    }
  }, [dispatch])

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    }
  }, [location.pathname])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode
      ? {
          unit: (unit && unit.unit) || "",
          is_active: Boolean(unit && unit.is_active),
        }
      : {
          unit: "",
          is_active: false,
        },
    validationSchema: Yup.object({
      unit: Yup.string().required("Please Enter Unit"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      if (isEditMode === true) {
        dispatch(updateUnitMaster(unitId, values, props.router.navigate))
      } else if (isEditMode === false) {
        dispatch(addNewUnitMaster(values, props.router.navigate))
      }
    },
  })

  return (
    <div className="page-content">
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <>
          <Row>
            <Col>
              <div className="d-flex gap-2 mb-3">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey", marginRight: "15px" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>

                <span
                  style={{
                    color: "black",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  {" "}
                  {isEditMode ? "Edit Unit Master" : "New Unit Master"}{" "}
                </span>
              </div>
            </Col>
            <Col>
              <Breadcrumbs title="Settings / Master configuration / Unit Master" />
            </Col>
          </Row>
          <div className="mt-3"></div>
          <Card>
            <CardBody>
              <Form
                autoComplete="off"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label htmlFor="formrow-unit-Input">Unit*</Label>
                      <Input
                        type="text"
                        name="unit"
                        className="form-control"
                        id="formrow-unit-Input"
                        placeholder="Enter Unit"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.unit || ""}
                        invalid={
                          validation.touched.unit && validation.errors.unit
                            ? true
                            : false
                        }
                      />
                      {validation.touched.unit && validation.errors.unit ? (
                        <FormFeedback type="invalid">
                          {validation.errors.unit}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-2"></div>
                    <div>
                      <Label htmlFor="formrow-ispaid-Input">Is Active</Label>
                    </div>

                    <Col lg="12">
                      <div className="form-check form-check-inline">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="is_active"
                          name="is_active"
                          onChange={e => {
                            validation.setFieldValue(
                              "is_active",
                              e.target.checked
                            )
                          }}
                          checked={Boolean(validation?.values?.is_active)}
                        />
                        <Label className="form-check-Label" htmlFor="is-Active">
                          <h6>Yes</h6>
                        </Label>
                      </div>
                      {validation.touched.is_active &&
                        validation.errors.is_active && (
                          <FormFeedback type="invalid">
                            {validation.errors.is_active}
                          </FormFeedback>
                        )}
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3 text-end">
                      <button type="submit" className="btn btn-primary w-md">
                        {isEditMode ? "Edit" : "Submit"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  )
}

export default withRouter(NewUnitMaster)

NewUnitMaster.propTypes = {
  history: PropTypes.object,
}
