/* PROJECTS */
export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECT_NAME = "GET_PROJECT_NAME"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL"
export const GET_PROJECT_NAME_SUCCESS = "GET_PROJECT_NAME_SUCCESS"
export const GET_PROJECT_NAME_FAIL = "GET_PROJECT_NAME_FAIL"

/* PROJECTS DETAIL*/
export const GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL"
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS"
export const GET_PROJECT_DETAIL_FAIL = "GET_PROJECT_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT"
 export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS"
 export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL"

 export const ADD_NEW_PROJECT_NAME = "ADD_NEW_PROJECT_NAME"
 export const ADD_PROJECT_NAME_SUCCESS = "ADD_PROJECT_NAME_SUCCESS"
 export const ADD_PROJECT_NAME_FAIL = "ADD_PROJECT_NAME_FAIL"
 
 export const ADD_NEW_PROJECT_UNIT = "ADD_NEW_PROJECT_UNIT"
 export const ADD_PROJECT_UNIT_SUCCESS = "ADD_PROJECT_UNIT_SUCCESS"
 export const ADD_PROJECT_UNIT_FAIL = "ADD_PROJECT_UNIT_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_PROJECT = "UPDATE_PROJECT"
 export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
 export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_PROJECT = "DELETE_PROJECT"
 export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
 export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL"

 // Project Gallery

  export const GET_GALLERY = "GET_GALLERY"
  export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS"
  export const GET_GALLERY_FAIL = "GET_GALLERY_FAIL"

  export const ADD_GALLERY = "ADD_GALLERY"
  export const ADD_GALLERY_SUCCESS = "ADD_GALLERY_SUCCESS"
  export const ADD_GALLERY_FAIL = "ADD_GALLERY_FAIL"

  export const UPDATE_GALLERY = "UPDATE_GALLERY"
  export const UPDATE_GALLERY_SUCCESS = "UPDATE_GALLERY_SUCCESS"
  export const UPDATE_GALLERY_FAIL = "UPDATE_GALLERY_FAIL"

  export const DELETE_GALLERY = "DELETE_GALLERY"
  export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS"
  export const DELETE_GALLERY_FAIL = "DELETE_GALLERY_FAIL"

  //PROJECT UNIT

  export const GET_PROJECT_UNIT = "GET_PROJECT_UNIT"
  export const GET_PROJECT_UNIT_SUCCESS = "GET_PROJECT_UNIT_SUCCESS"
  export const GET_PROJECT_UNIT_FAIL = "GET_PROJECT_UNIT_FAIL"

  export const GET_PROJECT_UNIT_DETAIL = "GET_PROJECT_UNIT_DETAIL"
  export const GET_PROJECT_UNIT_DETAIL_SUCCESS = "GET_PROJECT_UNIT_DETAIL_SUCCESS"
  export const GET_PROJECT_UNIT_DETAIL_FAIL = "GET_PROJECT_UNIT_DETAIL_FAIL"


  export const UPDATE_PROJECT_UNIT = "UPDATE_PROJECT_UNIT"
  export const UPDATE_PROJECT_UNIT_SUCCESS = "UPDATE_PROJECT_UNIT_SUCCESS"
  export const UPDATE_PROJECT_UNIT_FAIL = "UPDATE_PROJECT_UNIT_FAIL"

  export const DELETE_PROJECT_UNIT = "DELETE_PROJECT_UNIT"
  export const DELETE_PROJECT_UNIT_SUCCESS = "DELETE_PROJECT_UNIT_SUCCESS"
  export const DELETE_PROJECT_UNIT_FAIL = "DELETE_PROJECT_UNIT_FAIL"

  // PROJECT GALLERY VIDEO PART 

  export const GET_VIDEO = "GET_VIDEO"
  export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS"
  export const GET_VIDEO_FAIL = "GET_VIDEO_FAIL"

  export const GET_VIDEO_DETAIL = "GET_VIDEO_DETAIL"
  export const GET_VIDEO_DETAIL_SUCCESS = "GET_VIDEO_DETAIL_SUCCESS"
  export const GET_VIDEO_DETAIL_FAIL = "GET_VIDEO_DETAIL_FAIL"

  export const ADD_VIDEO = "ADD_VIDEO"
  export const ADD_VIDEO_SUCCESS = "ADD_VIDEO_SUCCESS"
  export const ADD_VIDEO_FAIL = "ADD_VIDEO_FAIL"

  export const DELETE_VIDEO = "DELETE_VIDEO"
  export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS"
  export const DELETE_VIDEO_FAIL = "DELETE_VIDEO_FAIL"

  //GET PROJECT MANAGER 

  export const GET_PROJECT_MANAGER = "GET_PROJECT_MANAGER"
  export const GET_PROJECT_MANAGER_SUCCESS = "GET_PROJECT_MANAGER_SUCCESS"
  export const GET_PROJECT_MANAGER_FAIL = "GET_PROJECT_MANAGER_FAIL"

  // Facilities Available 

export const GET_FACILITIES_AVAILABLE = "GET_FACILITIES_AVAILABLE"
export const GET_FACILITIES_AVAILABLE_SUCCESS = "GET_FACILITIES_AVAILABLE_SUCCESS"
export const GET_FACILITIES_AVAILABLE_FAIL = "GET_FACILITIES_AVAILABLE_FAIL"

//GET COMPANY FOR PROJECT 
export const GET_COMPANY_FOR_PROJECT = "GET_COMPANY_FOR_PROJECT"
export const GET_COMPANY_FOR_PROJECT_SUCCESS = "GET_COMPANY_FOR_PROJECT_SUCCESS"
export const GET_COMPANY_FOR_PROJECT_FAIL = "GET_COMPANY_FOR_PROJECT_FAIL"

//GET COMPANY DETAIL FOR PROJECT 
export const GET_COMPANY_DETAIL_FOR_PROJECT = "GET_COMPANY_DETAIL_FOR_PROJECT"
export const GET_COMPANY_DETAIL_FOR_PROJECT_SUCCESS = "GET_COMPANY_DETAIL_FOR_PROJECT_SUCCESS"
export const GET_COMPANY_DETAIL_FOR_PROJECT_FAIL = "GET_COMPANY_DETAIL_FOR_PROJECT_FAIL"

// GET COMPANY LOCATION FOR PROJECT 
export const GET_COMPANY_LOCATION_FOR_PROJECT = "GET_COMPANY_LOCATION_FOR_PROJECT"
export const GET_COMPANY_LOCATION_FOR_PROJECT_SUCCESS = "GET_COMPANY_LOCATION_FOR_PROJECT_SUCCESS"
export const GET_COMPANY_LOCATION_FOR_PROJECT_FAIL = "GET_COMPANY_LOCATION_FOR_PROJECT_FAIL"

//GET COMPANY LOCATION DETAIL FOR PROJECT
export const GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT = "GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT"
export const GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_SUCCESS = "GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_SUCCESS"
export const GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_FAIL = "GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT_FAIL"