import {
  GET_PROPOSALS,
  GET_PROPOSALS_FAIL,
  GET_PROPOSALS_SUCCESS,
  GET_PROPOSAL_DETAIL,
  GET_PROPOSAL_DETAIL_FAIL,
  GET_PROPOSAL_DETAIL_SUCCESS,
  ADD_NEW_PROPOSAL,
  ADD_PROPOSAL_SUCCESS,
  ADD_PROPOSAL_FAIL,
  UPDATE_PROPOSAL,
  UPDATE_PROPOSAL_SUCCESS,
  UPDATE_PROPOSAL_FAIL,
  DELETE_PROPOSAL,
  DELETE_PROPOSAL_SUCCESS,
  DELETE_PROPOSAL_FAIL,
  
} from "./actionTypes"

export const getProposals = (value) => ({
  type: GET_PROPOSALS,
  value,
})

export const getProposalsSuccess = proposals => ({
  type: GET_PROPOSALS_SUCCESS,
  payload: proposals,
})

export const addNewProposal = (history,proposal) => ({
  type: ADD_NEW_PROPOSAL,
  payload: {history,proposal},
})

export const addProposalSuccess = proposal => ({
  type: ADD_PROPOSAL_SUCCESS,
  payload: proposal,
})

export const addProposalFail = error => ({
  type: ADD_PROPOSAL_FAIL,
  payload: error,
})

export const updateProposal = (id,proposal) => ({
  type: UPDATE_PROPOSAL,
  payload: {id,proposal},
})

export const updateProposalSuccess = proposal => ({
  type: UPDATE_PROPOSAL_SUCCESS,
  payload: proposal,
})

export const updateProposalFail = error => ({
  type: UPDATE_PROPOSAL_FAIL,
  payload: error,
})

export const deleteProposal = proposal => ({
  type: DELETE_PROPOSAL,
  proposal,
})

export const deleteProposalSuccess = proposal => ({
  type: DELETE_PROPOSAL_SUCCESS,
  payload: proposal,
})

export const deleteProposalFail = error => ({
  type: DELETE_PROPOSAL_FAIL,
  payload: error,
})

export const getProposalsFail = error => ({
  type: GET_PROPOSALS_FAIL,
  payload: error,
})

export const getProposalDetail = proposalId => ({
  type: GET_PROPOSAL_DETAIL,
  proposalId,
})

export const getProposalDetailSuccess = proposalDetails => ({
  type: GET_PROPOSAL_DETAIL_SUCCESS,
  payload: proposalDetails,
})

export const getProposalDetailFail = error => ({
  type: GET_PROPOSAL_DETAIL_FAIL,
  payload: error,
})
