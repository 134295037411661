import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import VLogo from "../../assets/images/VIP/VLogo.png"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap"
import { addNewEmailConfiguration } from "helpers/fakebackend_helper"

const EmailConfiguration = props => {
  const { data } = props

  const [isEditMode, setIsEditMode] = useState(false)
  const validation1 = useFormik({
    initialValues: {
      //Time/ Region

      smtp_host: (data && data.smtp_host) || "",
      smtp_port: (data && data.smtp_port) || "",
      smtp_username: (data && data.smtp_username) || "",
      smtp_password: (data && data.smtp_password) || "",
      encryption_method: (data && data.encryption_method) || "",
      from_name: (data && data.from_name) || "",
      from_email: (data && data.from_email) || "",
      reply_to_name: (data && data.reply_to_name) || "",
      reply_to_email: (data && data.reply_to_email) || "",
    },
    validation1Schema: {
      smtp_host: Yup.string().required("Please Enter SMTP Host"),
      smtp_port: Yup.string().required("Please Enter SMTP Port"),
      smtp_username: Yup.string().required("Please Enter SMTP Username"),
      smtp_password: Yup.string().required("Please Enter SMTP Password"),
      encryption_method: Yup.string("Please Select Encryption Type"),
      from_name: Yup.string().required("Please Enter From Name"),
      from_email: Yup.string().required("Please Enter From Email"),
      reply_to_name: Yup.string().required("Please Enter Reply to Name"),
      reply_to_email: Yup.string().required("Please Enter Reply to Email"),
    },

    onSubmit: async values => {
    
      try {
        const response = await addNewEmailConfiguration(values)
        if (response && response.status === true) {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Email Configuration has been created.",
          })
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Network Error:", error.response.data.message)
      }
    },
  })

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Email Configuration</CardTitle>
        <Form autoComplete="off">
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="smtp_host">SMTP Host</Label>
                <Input
                  type="text"
                  id="formrow-smtp-Input"
                  name="smtp_host"
                  placeholder="Enter SMTP Host"
                  onBlur={validation1.handleBlur}
                  onChange={validation1.handleChange}
                  value={validation1.values.smtp_host || ""}
                  className={`form-control ${
                    validation1.touched.smtp_host &&
                    validation1.errors.smtp_host
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="smtp_port">SMTP Port</Label>
                <Input
                  type="text"
                  id="formrow-smtpport-Input"
                  name="smtp_port"
                  placeholder="Enter SMTP Port"
                  onBlur={validation1.handleBlur}
                  onChange={validation1.handleChange}
                  value={validation1.values.smtp_port || ""}
                  className={`form-control ${
                    validation1.touched.smtp_port &&
                    validation1.errors.smtp_port
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="smtp_username">SMTP Username</Label>
                <Input
                  type="text"
                  id="formrow-smtpuser-Input"
                  name="smtp_username"
                  placeholder="Enter SMTP Username"
                  onBlur={validation1.handleBlur}
                  onChange={validation1.handleChange}
                  value={validation1.values.smtp_username || ""}
                  className={`form-control ${
                    validation1.touched.smtp_username &&
                    validation1.errors.smtp_username
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="smtp_password">SMTP Password</Label>
                <Input
                  type="password"
                  id="formrow-smtppass-Input"
                  name="smtp_password"
                  placeholder="Enter SMTP Password"
                  onBlur={validation1.handleBlur}
                  onChange={validation1.handleChange}
                  value={validation1.values.smtp_password || ""}
                  className={`form-control ${
                    validation1.touched.smtp_password &&
                    validation1.errors.smtp_password
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-encrypt-Input">Encryption type</Label>
              </div>

              <Col lg="12">
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="encryptionTypeRadio1"
                    name="encryption_method"
                    className={`form-check-input ${
                      validation1.touched.encryption_method &&
                      validation1.errors.encryption_method
                        ? "is-invalid"
                        : ""
                    }`}
                    value="tls"
                    onChange={validation1.handleChange}
                    onBlur={validation1.handleBlur}
                    checked={validation1.values.encryption_method === "tls"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="encryptionTypeRadio1"
                  >
                    TLS
                  </Label>
                </div>

                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="encryptionTypeRadio2"
                    name="encryption_method"
                    className={`form-check-input ${
                      validation1.touched.encryption_method &&
                      validation1.errors.encryption_method
                        ? "is-invalid"
                        : ""
                    }`}
                    value="ssl"
                    onChange={validation1.handleChange}
                    onBlur={validation1.handleBlur}
                    checked={validation1.values.encryption_method === "ssl"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="encryptionTypeRadio2"
                  >
                    SSL
                  </Label>
                </div>

                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="encryptionTypeRadio3"
                    name="encryption_method"
                    className={`form-check-input ${
                      validation1.touched.encryption_method &&
                      validation1.errors.encryption_method
                        ? "is-invalid"
                        : ""
                    }`}
                    value="none"
                    onChange={validation1.handleChange}
                    onBlur={validation1.handleBlur}
                    checked={validation1.values.encryption_method === "none"}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="encryptionTypeRadio3"
                  >
                    None
                  </Label>
                </div>
              </Col>
            </Col>

            <Col>
              <div className="mb-3">
                <Label htmlFor="from_name">From Name</Label>
                <Input
                  type="text"
                  id="formrow-firstname-Input"
                  name="from_name"
                  placeholder="Enter your Name"
                  onBlur={validation1.handleBlur}
                  onChange={validation1.handleChange}
                  value={validation1.values.from_name || ""}
                  className={`form-control ${
                    validation1.touched.from_name &&
                    validation1.errors.from_name
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="fromEmailInput">From Email</Label>
                    <Input
                      type="email"
                      id="fromEmailInput"
                      name="from_email"
                      placeholder="Enter Email"
                      onBlur={validation1.handleBlur}
                      onChange={validation1.handleChange}
                      value={validation1.values.from_email || ""}
                      className={`form-control ${
                        validation1.touched.from_email &&
                        validation1.errors.from_email
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="replyToNameInput">Reply to Name</Label>
                    <Input
                      type="text"
                      // className="form-control"
                      id="replyToNameInput"
                      name="reply_to_name"
                      placeholder="Enter Name"
                      onBlur={validation1.handleBlur}
                      onChange={validation1.handleChange}
                      value={validation1.values.reply_to_name || ""}
                      className={`form-control ${
                        validation1.touched.reply_to_name &&
                        validation1.errors.reply_to_name
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="replyToEmailInput">Reply to Email</Label>
                    <Input
                      type="email"
                      // className="form-control"
                      id="replyToEmailInput"
                      name="reply_to_email"
                      placeholder="Enter Email"
                      onBlur={validation1.handleBlur}
                      onChange={validation1.handleChange}
                      value={validation1.values.reply_to_email || ""}
                      className={`form-control ${
                        validation1.touched.reply_to_email &&
                        validation1.errors.reply_to_email
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary w-md"
                      onClick={validation1.handleSubmit}
                    >
                      Save Changes
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}
export default EmailConfiguration
