import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import VLogo from "../../assets/images/VIP/VLogo.png"
// import { addNewRecaptchaconfiguration, getRecaptchaconfiguration } from "store/actions";
import { useDispatch } from "react-redux"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  FormGroup,
  InputGroup,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import { addNewRecaptchaConfiguration } from "helpers/fakebackend_helper"

const ReCaptch = props => {
  const { data } = props
  const [selectedOption, setSelectedOption] = useState("0")
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast1, setToast1] = useState(false)
  const [toast2, setToast2] = useState(false)
  const [toast3, setToast3] = useState(false)
  const [toast4, setToast4] = useState(false)
  const [toast5, setToast5] = useState(false)
  const [FormSubmitted, setFormSubmitted] = useState("0")
  const dispatch = useDispatch()

  const validation1 = useFormik({
    initialValues: {
      //reCaptcha configuration
      recaptcha_site_key: (data && data.recaptcha_site_key) || "",
      recaptcha_secret_key: (data && data.recaptcha_secret_key) || "",
    },
    validation1Schema: {
      //reCaptcha configuration
      recaptcha_site_key: Yup.string().required("Please Enter Your Site Key"),
      recaptcha_secret_key: Yup.string().required(
        "Please Enter Your Secret Key"
      ),
    },

    onSubmit: async values => {
      try {
        const response = await addNewRecaptchaConfiguration(values)
        if (response && response.status === true) {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your ReCAPTCHA Configuration details has been Submitted.",
          })
        } else {
          // Handle error case
          console.error("Error:", response && response.message)
          Swal.fire({
            title: "Error",
            icon: "error",
            text:
              response && response.message
                ? response.message
                : "An error occurred.",
          })
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Network Error:", error.response.data.message)
      }
    },
  })

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">ReCAPTCHA Configuration</CardTitle>
        <Form autoComplete="off">
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="recaptcha_site_key">Site Key</Label>
                <Input
                  type="text"
                  id="formrow-recaptcha_site_key-Input"
                  name="recaptcha_site_key"
                  placeholder="Enter your Site key"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.recaptcha_site_key || ""}
                  className={`form-control ${
                    validation1.touched.recaptcha_site_key &&
                    validation1.errors.recaptcha_site_key
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="recaptcha_secret_key">Secret key</Label>
                <Input
                  type="text"
                  id="formrow-secretkey-Input"
                  name="recaptcha_secret_key"
                  placeholder="Enter your Secret key"
                  onBlur={validation1.handleBlur}
                  onChange={validation1.handleChange}
                  value={validation1.values.recaptcha_secret_key || ""}
                  className={`form-control ${
                    validation1.touched.recaptcha_secret_key &&
                    validation1.errors.recaptcha_secret_key
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary w-md"
                onClick={validation1.handleSubmit}
              >
                Save Changes
              </button>
            </div>
            {toast3 && (
              <div
                className="position-fixed top-0 end-0 p-3"
                style={{ zIndex: "1005" }}
              >
                <Toast isOpen={toast3} role="aleart">
                  <ToastHeader toggle={() => setToast3(!toast3)}>
                    <img src={VLogo} alt="" className="me-2" height="18" />
                    <strong className="me-auto">VIP Mall</strong>
                  </ToastHeader>
                  <ToastBody>
                    {!isEditMode
                      ? "Submitted Successfully"
                      : "Edited Successfully"}
                  </ToastBody>
                </Toast>
              </div>
            )}
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}
export default ReCaptch
