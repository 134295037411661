import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  FormFeedback,
  Col,
  Row,
  Label,
  Form,
  Input,
  Card,
  CardBody,
  Modal,
} from "reactstrap"
import * as Yup from "yup"
import Swal from "sweetalert2"
import {
  getCities,
  getCompanyLocationDetail,
  getCompanyLocationList,
  getCountries,
  getStates,
} from "store/actions"
import { createSelector } from "reselect"
import { updateCompanyLocation } from "helpers/fakebackend_helper"

const LargeModal = ({ isOpen, toggle, id }) => {
  const [companyId, setCompanyId] = useState()

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const companyLocationState = state => state.companies.locationDetail.data
  const loadingState = state => state.companies.loading
  const companyLocationProperties = createSelector(
    companyLocationState,
    loadingState,
    (location, loading) => ({
      location: location,
      loading: loading,
    })
  )
  const { location, loading } = useSelector(companyLocationProperties) || []
  const [isLoading, setLoading] = useState(loading)

  const dispatch = useDispatch()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
        company_id: companyId,
      location_title: (location && location.location_title) || "",
      gst_no: (location && location.gst_no) || "",
      state_id: (location && location.state_id) || "",
      city_id: (location && location.city_id) || "",
      country_id: (location && location.country_id) || "",
    },

    validationSchema: Yup.object({
        company_id: Yup.number(),
      location_title: Yup.string().required("Please Enter Address"),
      gst_no: Yup.string()
        .matches(
          /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/,
          "Invalid GST number"
        )
        .required("Please Enter GST Number"),
      state_id: Yup.number().required("Please Enter State"),
      city_id: Yup.number().required("Please Enter City"),
      country_id: Yup.string().required("Please Select Country"),
    }),
    onSubmit: async values => {
      let loadingSwal

      try {
        loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })

        const response = await updateCompanyLocation(id,values)

        if (response && response.status === true) {
          loadingSwal.close()

          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Company Location has been updated.",
          }).then(() => {
            dispatch(getCompanyLocationList(companyId))
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "Failure",
            icon: "error",
            text: "Your Company Location has not been updated.",
          })
        }
      } catch (error) {
        console.error("Error:", error)
        if (loadingSwal) {
          loadingSwal.close()
        }

        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response?.data?.message ||
            "An error occurred while updating your company location.",
        })
      }
    },
  })
  console.log(validation.values)

  const fetchData = () => {
    const url = new URL(window.location.href)
    const companyId = url.pathname.split("/view-company/")[1]
    setCompanyId(companyId)
  }
  useEffect(() => {
    fetchData()
    dispatch(getCountries())
  }, [dispatch])

  const [countryName, setCountryName] = useState(validation.values.country_id)
  const [stateId, setStateId] = useState("")
  useEffect(() => {
    dispatch(getStates(countryName))
  }, [countryName])
  useEffect(() => {
    if (validation.values.state_id) {
      dispatch(getCities(validation.values.state_id))
    }
  }, [validation.values.state_id])

  useEffect(() => {
    setCountryName(validation.values.country_id)
    setStateId(validation.values.state_id)
  }, [dispatch, validation.values.country_id, validation.values.state_id])

  useEffect(() => {
    if (id) {
      dispatch(getCompanyLocationDetail(id))
    }
  }, [id])
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Edit Location
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Card>
          <CardBody>
            <Form
              autoComplete="off"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-address">Address*</Label>
                    <Input
                      type="text"
                      id="location_title"
                      name="location_title"
                      className="form-control "
                      placeholder="Enter Your Address"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.location_title &&
                        validation.errors.location_title
                          ? true
                          : false
                      }
                      value={validation.values.location_title || ""}
                    />
                    {validation.touched.location_title &&
                    validation.errors.location_title ? (
                      <FormFeedback type="invalid">
                        {validation.errors.location_title}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="country_id">Country*</Label>
                    <select
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      // defaultValue="IN"
                      value={validation.values.country_id}
                      name="country_id"
                      className={`form-select ${
                        validation.touched.country_id &&
                        validation.errors.country_id
                          ? "is-invalid"
                          : ""
                      }`}
                    >
                      {!validation.values.country_id && (
                        <option>Select Your Country</option>
                      )}

                      {(countries || []).map(item => (
                        <option key={item.id} value={item.id}>
                          {item.country_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="stateno-input">State*</Label>
                    <select
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      name="state_id"
                      value={validation.values.state_id}
                      className={`form-select ${
                        validation.touched.state_id &&
                        validation.errors.state_id
                          ? "is-invalid"
                          : ""
                      }`}
                    >
                      {!validation.values.state_id && (
                        <option>Select Your States</option>
                      )}

                      {(states || []).map(item => (
                        <option key={item.id} value={item.id}>
                          {item.state_name}
                        </option>
                      ))}
                    </select>
                    {validation.touched.state_id &&
                    validation.errors.state_id ? (
                      <FormFeedback type="invalid">
                        {validation.errors.state_id}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-email-input4">City*</Label>
                    <select
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      name="city_id"
                      value={validation.values.city_id}
                      className={`form-select ${
                        validation.touched.city_id && validation.errors.city_id
                          ? "is-invalid"
                          : ""
                      }`}
                    >
                      {!validation.values.city_id && (
                        <option>Select Your City</option>
                      )}

                      {(cities || []).map(item => (
                        <option key={item.id} value={item.id}>
                          {item.city_name}
                        </option>
                      ))}
                    </select>
                    {validation.touched.city_id && validation.errors.city_id ? (
                      <FormFeedback type="invalid">
                        {validation.errors.city_id}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-pancard-input5">GST No.*</Label>
                    <Input
                      type="text"
                      name="gst_no"
                      className="form-control"
                      id="basicpill-pancard-input5"
                      placeholder="Enter Your GST No."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.gst_no || ""}
                      invalid={
                        validation.touched.gst_no && validation.errors.gst_no
                          ? true
                          : false
                      }
                    />
                    {validation.touched.gst_no && validation.errors.gst_no ? (
                      <FormFeedback type="invalid">
                        {validation.errors.gst_no}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="mb-3 mt-4 text-center">
                  <button type="submit" className="btn btn-primary ">
                    Update Location
                  </button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  )
}

export default LargeModal
