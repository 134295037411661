import { all, fork } from "redux-saga/effects"

//public

import AuthSaga from "./auth/login/saga"

import LayoutSaga from "./layout/saga"

// import projectsSaga from "./projects/saga"
import companiesSaga from "./company/saga"
import facilitiesSaga from "./masters/facility/saga"
import usersSaga from "./user/saga"

import ticketsSaga from "./masters/customer-ticket/saga"
import documentsSaga from "./masters/document/saga"
import expensesSaga from "./masters/expense/saga"
import invoicesSaga from "./masters/invoice-type/saga"
import landlordsSaga from "./masters/landlord/saga"
import maintainencesSaga from "./masters/maintainence/saga"
import compliancesSaga from "./masters/project-compliance/saga"
import unitsSaga from "./masters/project-unit/saga"
import vendorsSaga from "./masters/vendor/saga"
import sachsnSaga from "./masters/sac&hsncode/saga"
import unitMasterSaga from "./masters/unitMaster/saga"
import materialMasterSaga from "./masters/materialMaster/saga"
// import contactsSaga from "./contacts/saga"
import regionsSaga from "./region/saga"
//projectSaga
import projectsSaga from "./project/saga"
import rolesSaga from "./role/saga"
import commonSaga from "./common/saga"
//CLIENT
import clientsSaga from "./clients/saga"

//CLIENT ENGAGEMENT SERVICE

import serviceEngagementSaga from "./clients/ServiceEngagement/saga"
//VENDOR
import vendorSaga from "./vendors/saga"
import serviceRequestsSaga from "./serviceRequests/saga"

//Billing Setup
import billingSaga from "./billing/saga"
import forgetPasswordSaga from "./auth/forgetpwd/saga"
import gstReportSaga from "./gstReport/saga"

//Dashboard
import dashboardSaga from "./dashboard/saga"

//MY PROFILE

import myProfileSaga from "./myProfile/saga"
import serviceTicketSaga from "./tickets/saga"
import serviceRequestDashboardSaga from "./serviceRequestDashboard/saga"
import ticketsDashboardSaga from "./ticketsDashboard/saga"
import renewalsSaga from "./renewals/saga"
import ConsumptionReportSaga from "./consumptionReport/saga"
import VendorPaymentSaga from "./vendors/vendorFilter/saga"
import oustandingReports from "./customerOutstandingReport/saga"
import VendorSummaryReportSaga from "./VendorSummaryReport/saga"
import CustomerIndexSaga from "./customerIndex/saga"
import proposalsSaga from "./proposal/saga"
import proposalMasterSaga from "./masters/proposalTypeMaster/saga"
import reasonMasterSaga from "./masters/reasonMaster/saga"
import debitNoteSaga from "./debitNote/saga"
import clientUserSaga from "./clientUser/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(forgetPasswordSaga),
    fork(LayoutSaga),
    fork(companiesSaga),
    fork(usersSaga),

    //masters
    fork(facilitiesSaga),
    fork(ticketsSaga),
    fork(documentsSaga),
    fork(expensesSaga),
    fork(invoicesSaga),
    fork(landlordsSaga),
    fork(maintainencesSaga),
    fork(compliancesSaga),
    fork(unitsSaga),
    fork(vendorsSaga),
    fork(sachsnSaga),
    fork(unitMasterSaga),
    fork(materialMasterSaga),
    //region
    fork(regionsSaga),
    //project
    fork(projectsSaga),
    //roles
    fork(rolesSaga),
    //common
    fork(commonSaga),
    //Clients
    fork(clientsSaga),
    //Clients SERVICE ENGAGEMENT
    fork(serviceEngagementSaga),
    fork(ticketsDashboardSaga),
    //Vendors
    fork(vendorSaga),
    //service request
    fork(serviceRequestsSaga),
    fork(serviceRequestDashboardSaga),
    //Billing Setup

    fork(billingSaga),
    // Reports
    fork(gstReportSaga),
    fork(VendorSummaryReportSaga),

    //Dashboard
    fork(dashboardSaga),

    //MY PROFILE
    fork(myProfileSaga),
    //service Tickets
    fork(serviceTicketSaga),
    fork(renewalsSaga),
    fork(ConsumptionReportSaga),
    fork(VendorPaymentSaga),
    fork(oustandingReports),
    fork(CustomerIndexSaga),
    fork(proposalsSaga),
    fork(proposalMasterSaga),
    fork(reasonMasterSaga),
    fork(debitNoteSaga),
    fork(clientUserSaga),
  ])
}
