import React from 'react'
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
  } from "reactstrap";
  import * as Yup from "yup";
  import { useFormik } from "formik";
  
  // import { Email, Tags, Projects } from "./contactlistCol";
  
  //Import Breadcrumb
  import Breadcrumbs from "components/Common/Breadcrumb";
  import DeleteModal from "components/Common/DeleteModal";

const EmptyUsers = () => {
  return (
    <div className="page-content">
      <Breadcrumbs title="Users" breadcrumbItem="All Users" />
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col className="text-center">
          <Form
          autoComplete = 'off'>
            <div className="mx-auto" style={{borderRadius:'20px',maxHeight:'120px',maxWidth:'120px',backgroundColor:'#EEE'}}>
            <i className=" mdi mdi-account-multiple-outline" style={{ fontSize: "70px",color:'#888888' }}></i>
            </div>
            <Label className="form-label mt-auto" >No Users</Label>
        </Form>
        </Col>
      </Row>
    </div>
  )
}

export default EmptyUsers;
