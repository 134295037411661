export const API_URL = "https://vipmalldemobackend.erpca.shop/public"
// export const API_URL = `http://localhost:8000`
// export const API_URL = `https://api.v1malls.com`

//LOGIN
export const LOGIN = `${API_URL}/api/login`
export const FORGET_PASSWORD = `${API_URL}/api/forget-password`
//LOGOUT
export const LOGOUT_USER = `${API_URL}/api/logout`
//UPDATE PASSWORDS
export const UPDATE_PASS = `${API_URL}/api/update-password`

//Dashboard
export const GET_COUNT_FOR_DASHBOARD = `${API_URL}/api/all-count`
export const GET_VACANT_PROJECT_UNIT_LIST = `${API_URL}/api/vacant-project-unit-list`
export const ACTIVITY_FILTER = `${API_URL}/api/list-all-count`
export const NEW_CUSTOMER_LIST = `${API_URL}/api/list-client-data`
export const NEW_UNIT_ASSIGNED_LIST = `${API_URL}/api/list-project-Unit-data`
export const BILL_AMOUNT_LIST = `${API_URL}/api/all-billing-list`
export const PAYMENT_AMOUNT_LIST = `${API_URL}/api/all-bill-payment-list`
export const NEW_CUSTOMER_GRAPH = `${API_URL}/api/list-all-counts-for-customer-graph`
export const NEW_UNITS_ASSIGNED_GRAPH = `${API_URL}/api/list-all-counts-for-project-unit-graph`
export const BILL_AND_PAYMENT_GRAPH = `${API_URL}/api/list-all-counts-for-bill-payment-graph`
//
export const CUSTOMER_OUTSTANTDING_LIST = `${API_URL}/api/list-billing-data-outstanding-amount`
export const NEW_VENDOR_LIST = `${API_URL}/api/list-vendors-for-dashboard`
export const VENDOR_OUTSTANDING_LIST = `${API_URL}/api/list-vendor-invoices-outstanding-amounts`
export const VENDOR_PAYMENT_LIST = `${API_URL}/api/list-vendor-payments-for-dashboard`
export const VENDOR_INVOICE_LIST = `${API_URL}/api/list-vendor-invoices-for-dashboard`
export const VENDOR_TICKET_LIST = `${API_URL}/api/list-vendor-service-request-tickets`
export const STATUS_WISE_TICKET_LIST = `${API_URL}/api/list-status-ticket-dashboard`
//excell
export const NEW_CUSTOMER_LIST_EXCEL = `${API_URL}/api/download-customer-data-excel`
export const PROJECT_UNIT_DATA_EXCEL = `${API_URL}/api/download-project-unit-data-excel`
export const BILLING_DATA_EXCEL = `${API_URL}/api/download-billing-data-excel`
export const BILL_PAYMENT_EXCEL = `${API_URL}/api/download-bill-payment-data-excel`
//
export const CUSTOMER_OUTSTANDING_LIST_EXCEL = `${API_URL}/api/list-billing-data-outstanding-amount-excel`
export const NEW_VENDOR_DATA_EXCEL = `${API_URL}/api/download-vendor-data-excel`
export const VENDOR_ASSIGNED_TICKET_EXCEL = `${API_URL}/api/download-vendor-service-request-tickets-excel`
export const VENDOR_OUTSTANDING_LIST_EXCEL = `${API_URL}/api/list-vendor-invoices-outstanding-amount-excel`

export const VENDOR_INVOICE_LIST_EXCEL = `${API_URL}/api/download-vendor-invoices-excel`
export const VENDOR_PAYMENT_LIST_EXCEL = `${API_URL}/api/download-vendor-payments-excel`
//
export const STATUS_WISE_TICKET_LIST_EXCEL = `${API_URL}/api/download-status-dashboard-excel`

// MY PROFILE
export const GET_PROFILE_DETAIL = `${API_URL}/api/show-my-profile`

//PROJECTS
export const GET_PROJECTS = `${API_URL}/api/list-projects`
export const GET_PROJECT_NAME = `${API_URL}/api/list-project-Names`
export const GET_PROJECT_DETAIL = `${API_URL}/api/get-project`
export const GET_PROJECT_UNIT_DETAIL = `${API_URL}/api/get-project-units`
export const ADD_NEW_PROJECT = `${API_URL}/api/create-project`
export const ADD_NEW_PROJECT_NAME = `${API_URL}/api/create`
export const ADD_NEW_PROJECT_UNIT = `${API_URL}/api/create-project-units`
export const UPDATE_PROJECT = `${API_URL}/api/update-project`
export const DELETE_PROJECT = `${API_URL}/api/delete-project`
export const GET_COMPANY_FOR_PROJECT = `${API_URL}/api/list-company-names-for-project`
export const GET_COMPANY_DETAIL_FOR_PROJECT = `${API_URL}/api/get-company-details-for-project`
export const GET_COMPANY_LOCATION_FOR_PROJECT = `${API_URL}/api/list-company-gst-location-for-project`
export const GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT = `${API_URL}/api/get-company-gst-location-for-project`

//PROJECT MANAGER
export const GET_PROJECT_MANAGER = `${API_URL}/api/fetch-project-management/3`

// PROJECT GALLERY

export const GET_GALLERY = `${API_URL}/api/get-gallery-upload`
export const ADD_GALLERY = `${API_URL}/api/project-gallery-upload`
export const UPDATE_GALLERY = `${API_URL}/api/update`
export const DELETE_GALLERY = `${API_URL}/api/project-gallery-delete`

//VIDEO

export const GET_VIDEO = `${API_URL}/api/project-gallery/get-video`
export const GET_VIDEO_DETAIL = `${API_URL}/api/project-gallery/get-video`
export const ADD_VIDEO = `${API_URL}/api/project-gallery/add-video`
export const DELETE_VIDEO = `${API_URL}/api/project-gallery/delete-video`

//PROJECT UNIT
export const GET_PROJECT_UNIT = `${API_URL}/api/get-project-unit-id`
export const UPDATE_PROJECT_UNIT = `${API_URL}/api/update-project-units`
export const DELETE_PROJECT_UNIT = `${API_URL}/api/delete-project-units`
export const GET_FACILITIES_AVAILABLE = `${API_URL}/api/get-facilities`

// FORM 1 TIME REGION
export const ADD_NEW_TIMEREGION = `${API_URL}/api/update-time-and-region`
export const GET_TIMEREGION = `${API_URL}/api/get-regional-settings`

// FORM 2 FILE CONFIGURATION
export const ADD_NEW_FILECONFIGURATION = `${API_URL}/api/update-file-configuration`
export const GET_FILECONFIGURATION = `${API_URL}/api/get-file-configuration`

// FORM 3 ReCAPTCHA Configuration
export const ADD_NEW_RECAPTCHACONFIGURATION = `${API_URL}/api/update-recaptcha-configuration`
export const GET_RECAPTCHACONFIGURATION = `${API_URL}/api/get-recaptcha-configuration`

// FORM 4 EMAIL CONFIGURATION
export const ADD_NEW_EMAILCONFIGURATION = `${API_URL}/api/update-email-configuration`
export const GET_EMAILCONFIGURATION = `${API_URL}/api/get-email-configuration`

// FORM 5 USER PASSWORD SETTINGS
export const ADD_NEW_USERPASSWORDSETTINGS = `${API_URL}/api/update-user-password-setting`
export const GET_USERPASSWORDSETTINGS = `${API_URL}/api/get-user-password-setting`

//DATE AND TIME FORMAT

export const GET_DATETIMEFORMAT = `${API_URL}/api/date-time-Format`

//TIME ZONE

export const GET_TIMEZONE = `${API_URL}/api/time-zone`

//CURRENCY
export const GET_CURRENCY = `${API_URL}/api/get-currency-details`

//COMPANIES
export const GET_COMPANIES = `${API_URL}/api/list-companies`
export const GET_COMPANY_DETAIL = `${API_URL}/api/get-company-details`
export const ADD_NEW_COMPANY = `${API_URL}/api/create-company`
export const UPDATE_COMPANY = `${API_URL}/api/edit-company`
export const DELETE_COMPANY = `${API_URL}/api/delete-company`
export const GET_DOCUMENT_LIST_COMPANY = `${API_URL}/api/list-company-documents`
export const ADD_DOCUMENT_COMPANY = `${API_URL}/api/create-company-document`
export const DELETE_DOCUMENT_COMPANY = `${API_URL}/api/delete-company-document`
export const GET_COMPANY_LOCATION_LIST = `${API_URL}/api/list-gst-locations`
export const GET_COMPANY_LOCATION_DETAIL = `${API_URL}/api/get-gst-locations`
export const ADD_COMPANY_LOCATION = `${API_URL}/api/save-gst-locations`
export const UPDATE_COMPANY_LOCATION = `${API_URL}/api/alter-gst-locations`
export const DELETE_COMPANY_LOCATION = `${API_URL}/api/delete-gst-location`

//MASTERS
//FACILITIES
export const GET_FACILITIES = `${API_URL}/api/list-facility`
export const GET_FACILITY_DETAIL = `${API_URL}/api/get-facilitiy`
export const ADD_NEW_FACILITY = `${API_URL}/api/create-facility`
export const UPDATE_FACILITY = `${API_URL}/api/edit-facility`
export const DELETE_FACILITY = `${API_URL}/api/delete-facility`

//TICKETS
export const GET_TICKETS = `${API_URL}/api/list-tickets`
export const GET_TICKET_DETAIL = `${API_URL}/api/get-ticket`
export const ADD_NEW_TICKET = `${API_URL}/api/create-ticket`
export const UPDATE_TICKET = `${API_URL}/api/update-ticket`
export const DELETE_TICKET = `${API_URL}/api/delete-ticket`
export const GET_COUNT_FOR_TICKETS_DASHBOARD = `${API_URL}/api/all-priority-count`
export const GET_CRITICAL_PRIORITY_TICKET_LIST = `${API_URL}/api/critical-priority-list`
export const GET_HIGH_PRIORITY_TICKET_LIST = `${API_URL}/api/high-priority-list`
export const GET_MEDIUM_PRIORITY_TICKET_LIST = `${API_URL}/api/medium-priority-list`
export const GET_LOW_PRIORITY_TICKET_LIST = `${API_URL}/api/low-priority-list`
export const GET_RECENT_TICKETS_LIST = `${API_URL}/api/service-request-tickets-list`
export const GET_USER_VENDOR_WITH_MOST_TICKET = `${API_URL}/api/vendor-user-ticket-count-list`
export const GET_OPEN_CLOSE_TICKET_GRAPH = `${API_URL}/api/service-request-ticket-status-graph`
export const GET_TICKETS_STATUS_PIE_DIGRAM = `${API_URL}/api/service-request-ticket-status-count`

//DOCUMENTS
export const GET_DOCUMENTS = `${API_URL}/api/list-documents`
export const GET_ACTIVE_DOCUMENTS = `${API_URL}/api/get-active-documents`
export const GET_DOCUMENT_DETAIL = `${API_URL}/api/get-document`
export const ADD_NEW_DOCUMENT = `${API_URL}/api/create-documents`
export const UPDATE_DOCUMENT = `${API_URL}/api/update-documents`
export const DELETE_DOCUMENT = `${API_URL}/api/delete-documents`

//EXPENSES
export const GET_EXPENSES = `${API_URL}/api/list-expense`
export const GET_EXPENSE_DETAIL = `${API_URL}/api/get-expense`
export const ADD_NEW_EXPENSE = `${API_URL}/api/create-expense`
export const UPDATE_EXPENSE = `${API_URL}/api/update-expense`
export const DELETE_EXPENSE = `${API_URL}/api/delete-expense`

//INVOICES
export const GET_INVOICES = `${API_URL}/api/list-invoice-types`
export const GET_INVOICE_DETAIL = `${API_URL}/api/get-invoice-types`
export const ADD_NEW_INVOICE = `${API_URL}/api/create-invoice-type`
export const UPDATE_INVOICE = `${API_URL}/api/update-invoice-type`
export const DELETE_INVOICE = `${API_URL}/api/delete-invoice-type`
export const GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT = `${API_URL}/api/list-gst-applicable-rate`
export const GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT = `${API_URL}/api/list-sac-hsn-codes`

//LANDLORDS
export const GET_LANDLORDS = `${API_URL}/api/list-landlords`
export const ACTIVE_LANDLORD = `${API_URL}/api/active-landlords`
export const GET_LANDLORD_DETAIL = `${API_URL}/api/fetch-landlords`
export const ADD_NEW_LANDLORD = `${API_URL}/api/create-landlord`
export const UPDATE_LANDLORD = `${API_URL}/api/update-landlord`
export const DELETE_LANDLORD = `${API_URL}/api/delete-landlord`

export const GET_DOCUMENT_LIST_LANDLORD = `${API_URL}/api/list-landlords-documents`
export const ADD_DOCUMENT_LANDLORD = `${API_URL}/api/create-landlord-document`
export const DELETE_DOCUMENT_LANDLORD = `${API_URL}/api/delete-landlord-document`

//SAC / HSN CODE MASTER
export const GET_SACHSNCODE = `${API_URL}/api/list-all-codes`
export const GET_SACHSNCODE_DETAIL = `${API_URL}/api/get-codes`
export const ADD_NEW_SACHSNCODE = `${API_URL}/api/add-sac-hsm-code`
export const UPDATE_SACHSNCODE = `${API_URL}/api/update-sac-hsm-code`
export const DELETE_SACHSNCODE = `${API_URL}/api/delete-sac-hsm-code`

//UNIT MASTER
export const GET_UNIT_MASTER_LIST = `${API_URL}/api/list-units`
export const GET_UNIT_MASTER_DETAIL = `${API_URL}/api/view-units`
export const ADD_NEW_UNIT_MASTER = `${API_URL}/api/add-units`
export const UPDATE_UNIT_MASTER = `${API_URL}/api/edit-units`
export const DELETE_UNIT_MASTER = `${API_URL}/api/delete-units`

//MATERIAL MASTER
export const GET_MATERIAL_MASTER_LIST = `${API_URL}/api/list-material`
export const GET_MATERIAL_MASTER_DETAIL = `${API_URL}/api/view-material`
export const ADD_MATERIAL_MASTER = `${API_URL}/api/add-material`
export const UPDATE_MATERIAL_MASTER = `${API_URL}/api/edit-material`
export const DELETE_MATERIAL_MASTER = `${API_URL}/api/delete-material`
export const GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER = `${API_URL}/api/list-sac-hsm-code`
export const ADD_NEW_SACHSNCODE_MATERIAL_MASTER = `${API_URL}/api/add-sac-hsm-code`

//MAINTAINENCES
export const GET_MAINTAINENCES = `${API_URL}/api/list-maintenance-issues`
export const GET_MAINTAINENCE_DETAIL = `${API_URL}/api/get-maintenance-issues`
export const ADD_NEW_MAINTAINENCE = `${API_URL}/api/create-maintenance-issues`
export const UPDATE_MAINTAINENCE = `${API_URL}/api/update-maintenance-issues`
export const DELETE_MAINTAINENCE = `${API_URL}/api/delete-maintenance-issues`

//COMPLIANCES
export const GET_COMPLIANCES = `${API_URL}/api/list-project-compliances`
export const GET_PROJECT_COMPLIANCES = `${API_URL}/api/get-dependent-compliance`
export const GET_ACTIVE_COMPLIANCES = `${API_URL}/api/project-compliances`
export const GET_COMPLIANCE_DETAIL = `${API_URL}/api/get-project-compliances`
export const ADD_NEW_COMPLIANCE = `${API_URL}/api/create-project-compliance`
export const UPDATE_COMPLIANCE = `${API_URL}/api/update-project-compliance`
export const DELETE_COMPLIANCE = `${API_URL}/api/delete-project-compliance`

//UNITS
export const GET_UNITS = `${API_URL}/api/list-project-units`
export const GET_UNIT_DETAIL = `${API_URL}/api/get-project-unit`
export const ADD_NEW_UNIT = `${API_URL}/api/create-project-unit`
export const UPDATE_UNIT = `${API_URL}/api/update-project-unit`
export const DELETE_UNIT = `${API_URL}/api/delete-project-unit`
//PROPOSAL TYPE
export const GET_PROPOSAL_TYPE_MASTER_LIST = `${API_URL}/api/list-vendor-proposal-type-master`
export const GET_PROPOSAL_TYPE_MASTER_DETAIL = `${API_URL}/api/get-proposal-type`
export const ADD_PROPOSAL_TYPE_MASTER = `${API_URL}/api/add-proposal-type`
export const UPDATE_PROPOSAL_TYPE_MASTER = `${API_URL}/api/update-proposal-type`
export const DELETE_PROPOSAL_TYPE_MASTER = `${API_URL}/api/delete-proposal-type`
//Reason TYPE
export const GET_REASON_MASTER_LIST = `${API_URL}/api/reasons-list`
export const GET_REASON_MASTER_DETAIL = `${API_URL}/api/view-reasons`
export const ADD_NEW_REASON_MASTER = `${API_URL}/api/add-reasons`
export const UPDATE_REASON_MASTER = `${API_URL}/api/edit-reasons`
export const DELETE_REASON_MASTER = `${API_URL}/api/delete-reasons`

// ACTIVE UNITS

export const GET_ACTIVE_UNITS = `${API_URL}/api/list-unitcategory`

//VENDORS
export const GET_VENDORS = `${API_URL}/api/list-vendors`
export const GET_VENDOR_DETAIL = `${API_URL}/api/get-vendor`
export const ADD_NEW_VENDOR = `${API_URL}/api/create-vendor`
export const UPDATE_VENDOR = `${API_URL}/api/update-vendor-service`
export const DELETE_VENDOR = `${API_URL}/api/delete-vendor-service`

//USERS MODULE
export const GET_USERS = `${API_URL}/api/list-users`
export const GET_USER_PROFILE = `${API_URL}/api/show-profile`
export const GET_USER_DETAIL = `${API_URL}/api/show-profile`
export const ADD_NEW_USER = `${API_URL}/api/register`
export const UPDATE_USER = `${API_URL}/api/update`
export const DELETE_USER = `${API_URL}/api/delete`

export const GET_DOCUMENTATION = `${API_URL}/api/list-user-documents`
export const ADD_DOCUMENTATION = `${API_URL}/api/create-user-documents`
export const UPDATE_DOCUMENTATION = `${API_URL}/api/update`
export const DELETE_DOCUMENTATION = `${API_URL}/api/delete-user-document`

export const GET_PAST_EMPLOYMENT = `${API_URL}/api/get-past-employment`
export const GET_PAST_EMPLOYMENT_DETAIL = `${API_URL}/api/past-employment-details`
export const ADD_PAST_EMPLOYMENT = `${API_URL}/api/add-past-employment`
export const UPDATE_PAST_EMPLOYMENT = `${API_URL}/api/update-past-employment`
export const DELETE_PAST_EMPLOYMENT = `${API_URL}/api/past-employments`

export const GET_ASSIGNED_CUSTOMER = `${API_URL}/api/list-users`

export const REINVITE = `${API_URL}/api/reinvite-link`

export const GET_USER_PERMISSION = `${API_URL}/api/list-component-permissions-for-user`
export const ADD_USER_PERMISSION = `${API_URL}/api/user-component-access`

export const RESET_USER_PASSWORD = `${API_URL}/api/reset-password`

export const GET_EMPLOYEE_CODE = `${API_URL}/api/get-default-employee-code`

export const GET_PROJECT_NAME_FOR_USER = `${API_URL}/api/get-user-assigned-project`

//CLIENTS

export const GET_CLIENTS = `${API_URL}/api/get-clients`
export const GET_CLIENTS_LIST = `${API_URL}/api/list-clients`
export const ADD_NEW_CLIENT = `${API_URL}/api/create-client`
export const UPDATE_CLIENT = `${API_URL}/api/update-client`
export const DELETE_CLIENT = `${API_URL}/api/delete-clients`
export const GET_CLIENT_CODE = `${API_URL}/api/get-default-customer-code`

//CLIENTS DOCUMENTS

export const GET_CLIENT_DOCUMENTS = `${API_URL}/api/list-client-documents`
export const ADD_NEW_CLIENT_DOCUMENT = `${API_URL}/api/create-client-documents`
export const DELETE_CLIENT_DOCUMENT = `${API_URL}/api/delete-client-document`

//CLIENTS ASSIGNED PROJECT UNIT

export const GET_ASSIGNED_PROJECT = `${API_URL}/api/clients/get-client-project-unit`
export const GET_ASSIGNED_PROJECT_LIST = `${API_URL}/api/clients/list-project-unit`
export const ADD_ASSIGNED_PROJECT = `${API_URL}/api/clients/assign-project-unit`
export const UPDATE_ASSIGNED_PROJECT = `${API_URL}/api/clients/update-project-unit`
export const DELETE_ASSIGNED_PROJECT = `${API_URL}/api/clients/delete-project-unit`
export const GET_ALL_ASSIGNED_PROJECT_UNITS = `${API_URL}/api/clients/list-all-project-unit`
export const APPROVE_PROJECT = `${API_URL}/api/clients/update-project-unit-approval-status`
export const GET_PROJECT_LIST_FOR_CLIENT = `${API_URL}/api/clients/list-all-projects`
export const GET_PROJECT_UNIT_LIST_FOR_CLIENT = `${API_URL}/api/clients/get-project-unit-by-project-id`
export const GET_INVOICE_TYPE_MASTER_CLIENT_LIST = `${API_URL}/api/list-invoice-type-for-service-engagement`

//Clients REINVITE

export const REINVITE_CLIENT = `${API_URL}/api/reinvite-client-link`

//Clients Service Engagement

export const GET_ALL_ENGAGEMENT_LIST = `${API_URL}/api/clients/list-all-project-unit`
export const GET_APPROVAL_PENDING_LIST = `${API_URL}/api/clients/list-client-approved-projects`
export const GET_BILLING_SETUP_PENDING_LIST = `${API_URL}/api/clients/list-client-billing-setup-pending`
export const GET_APPLICABLE_RATE_CLIENT_LIST = `${API_URL}/api/gst-applicable-rate`
export const GET_SACHSN_CODE_BILLING_SETUP = `${API_URL}/api/list-all-codes-for-billing-setup`
export const GET_SACHSN_CODE_BILLING = `${API_URL}/api/list-all-codes-for-billings`

//VENDORS

export const GET_VENDOR = `${API_URL}/api/get-vendor-data`
export const GET_VENDOR_CONTACT = `${API_URL}/api/get-vendor-contact-data`
export const GET_VENDORS_LIST = `${API_URL}/api/list-all-vendor-data`
export const ADD_NEW_VENDORS = `${API_URL}/api/add-vendor-details`
export const UPDATE_NEW_VENDORS = `${API_URL}/api/update-vendor-details`
export const ADD_NEW_VENDORS_DOCUMENTS = `${API_URL}/api/add-vendor-documents`
export const ADD_NEW_VENDORS_CONTACTS = `${API_URL}/api/add-vendor-contacts`
export const UPDATE_VENDORS_CONTACTS = `${API_URL}/api/update-vendor-contacts`
export const DELETE_VENDORS = `${API_URL}/api/delete-vendor`
export const REINVITE_VENDORS = `${API_URL}/api/reinvite-vendor-link`
export const DELETE_VENDOR_DOCUMENT = `${API_URL}/api/delete-vendor-document`
export const DELETE_VENDOR_CONTACT = `${API_URL}/api/delete-vendor-contact`
export const GET_VENDOR_SERVICE_MASTER_LIST = `${API_URL}/api/list-vendors-for-vendor_mst`
export const GET_VENDOR_CODE = `${API_URL}/api/get-default-vendor-code`
export const ADD_VENDOR_PAYMENT = `${API_URL}/api/vendor-invoice-payment`
export const GET_VENDOR_LIST_IN_PAYMENT = `${API_URL}/api/list-vendors-for-invoice`
export const GET_VENDOR_PAYMENT_DETAIL = `${API_URL}/api/list-invoices-for-payment`
export const GET_VENDOR_PAYMENT_DATA = `${API_URL}/api/get-vendor-invoice-for-admin`
export const APPROVE_VENDOR_INVOICE = `${API_URL}/api/approve-vendor-invoice`

//USERS ROLES
export const GET_ROLES = `${API_URL}/api/roles-list`
export const GET_MODULES = `${API_URL}/api/list-user-component-permissions`
export const GET_MANAGE_PERMISSION = `${API_URL}/api/list-user-component-permissions`
export const GET_ROLE_DETAIL = `${API_URL}/api/get-role-list`
export const ADD_NEW_ROLE = `${API_URL}/api/create-roles`
export const UPDATE_ROLE = `${API_URL}/api/update-role`
export const DELETE_ROLE = `${API_URL}/api/delete-role`
export const ROLE_COMPONENT_ACCESS = `${API_URL}/api/role-component-access`

//MENU

//SERVICE REQUEST
export const GET_SERVICE_REQUESTS = `${API_URL}/api/list-client-for-service-request`
export const GET_SERVICE_REQUEST_TYPE = `${API_URL}/api/service-request-types`
export const GET_SERVICE_REQUEST_DETAIL = `${API_URL}/api/get-service-request-user`
export const ADD_NEW_SERVICE_REQUEST = `${API_URL}/api/create-service-request`
export const ASSIGN_SERVICE_REQUEST = `${API_URL}/api/assign-service-request`
export const DELETE_SERVICE_REQUEST = `${API_URL}`
export const UPDATE_SERVICE_REQUEST = `${API_URL}`
export const GET_PROJECT_UNIT_CATEGORY = `${API_URL}/api/list-project-unit-category`
export const GET_MAINTENANCE_ISSUES = `${API_URL}/api/list-maintenance-issues`
export const GET_CLIENT_INVOICES = `${API_URL}/api/list-client-invoices`
export const GET_SUPPORT_TICKET = `${API_URL}/api/list-support-ticket`
export const GET_ALL_SERVICE_REQUEST_LIST = `${API_URL}/api/list-service-request-user`
export const GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE = `${API_URL}/api/list-all-service-request-status-dashboard`
export const GET_PENDING_SERVICE_REQUEST_LIST = `${API_URL}/api/list-user-pending-service-status`
export const GET_PROJECT_MANAGER_IN_SERVICE_REQUEST = `${API_URL}/api/list-project-managers-in-service-request`
export const GET_USERS_IN_SERVICE_REQUEST = `${API_URL}/api/list-user-in-service-request`
export const GET_VENDORS_IN_SERVICE_REQUEST = `${API_URL}/api/list-vendor-in-service-request`
export const ACCEPT_SERVICE_REQUEST = `${API_URL}/api/update-status-for-service-request`
export const CREATE_SERVICE_REQUEST = `${API_URL}/api/edit-service-request-ticket`
export const GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST = `${API_URL}/api/list-material-mst-for-service-request`
export const GET_UNIT_MASTER_FOR_SERVICE_REQUEST = `${API_URL}/api/list-unit-mst-for-service-request`
export const GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD = `${API_URL}/api/all-service-request-count`
export const GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST = `${API_URL}/api/all-additional-project-list`
export const GET_FACING_MAINTENANCE_ISSUE_LIST = `${API_URL}/api/all-facing-maintenance-issue-list`
export const GET_SUPPORT_TICKET_LIST = `${API_URL}/api/all-raise-ticket-list`
export const GET_RECENT_SERVICE_REQUEST_LIST = `${API_URL}/api/latest-service-request-list`
export const GET_TYPES_OF_REQUESTS_GRAPH = `${API_URL}/api/service-request-type-count-list`
export const GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM = `${API_URL}/api/service-request-status-count-list`
export const GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH = `${API_URL}/api/service-request-internal-external-count`
export const APPROVE_ESTIMATION = `${API_URL}/api/accept-item`

export const GET_CITIES = `${API_URL}/api/cities`
export const GET_STATES = `${API_URL}/api/states`
export const GET_COUNTRIES = `${API_URL}/api/countries`
export const GET_PROJECT_LIST_BY_CUSTOMER_ID = `${API_URL}/api/list-projects-by-client-for-billing-ledger`
export const GET_PROJECT_LIST_BY_VENDOR_ID = `${API_URL}/api/get-project-info`
export const GET_PROJECT_UNIT_DATA = `${API_URL}/api/get-project-unit-for-project-occupancy-report`

//BILLING SETUP and billing

export const GET_BILLING_CLIENT_LIST = `${API_URL}/api/list-clients-by-project`
export const GET_BILLING_CLIENT_LIST_IN_RECEIPT = `${API_URL}/api/list-clients-for-payment`
export const GET_BILLING_COMPANY_LIST_IN_RECEIPT = `${API_URL}/api/list-companies-for-payment`
export const GET_BILLING_CLIENT_DETAIL = `${API_URL}/api/get-clients-for-bills-and-invoice`
export const GET_SERVICE_ENGAGEMENT = `${API_URL}/api/get-service-engagement-by-id`
export const GET_BILLING_COMPANY_LIST = `${API_URL}/api/list-companies-for-bill-Setup`
export const GET_BILLING_COMPANY_DETAIL = `${API_URL}/api/get-company-details-for-billing`
export const GET_SUPPLY_LIST = `${API_URL}/api/gst-type-of-supply`
export const GET_PLACE_OF_SUPPLY = `${API_URL}/api/gst-place-of-supply`
export const GET_APPLICABLE_RATE_LIST = `${API_URL}/api/gst-applicable-rate`
export const GET_INVOICE_TYPE_MASTER_LIST = `${API_URL}/api/list-invoice-type-for-bills`
//billing setup
export const ADD_BILLING_SETUP = `${API_URL}/api/create-bill-setup`
export const EDIT_BILLING_SETUP = `${API_URL}/api/update-bill-setup`
export const GET_BILLING_SETUP_DETAIL = `${API_URL}/api/get-billing-setup-by-id`
export const ADD_NEW_BILL = `${API_URL}/api/add-new-bills`
export const GET_INVOICE_NUMBER = `${API_URL}/api/generate-invoice-number`
export const GET_BILLING_REGISTER = `${API_URL}/api/billing-register-list`
export const GET_BILLING_DATA = `${API_URL}/api/get-billing-data`
export const EDIT_BILL = `${API_URL}/api/update-bills`
export const GET_INVOICE_PREVIEW_DETAIL = `${API_URL}/api/get-billing-data-for-invoice`
export const GET_PAYMENT_MODE = `${API_URL}/api/payment_mode`
export const ADD_RECORD_PAYMENT = `${API_URL}/api/add-record-payment`
export const DELETE_BILLING_REGISTER = `${API_URL}/api/delete-billing`
export const GET_RECORD_PAYMENT_DETAIL = `${API_URL}/api/get-record-payment`
//bill credit note
export const GET_BILLING_CREDIT_NOTE_LIST = `${API_URL}/api/get-credit-note-list`
export const GET_CREDIT_NUMBER = `${API_URL}/api/get-credit-note-number`
export const GET_COMPONENTS_FOR_CREDIT_NOTE = `${API_URL}/api/get-bill-component-list`
export const GET_CREDIT_NOTE_REASONS = `${API_URL}/api/get-reasons-list`
export const GET_CREDIT_NOTE_INVOICE_LIST = `${API_URL}/api/get-invoice-list`
export const ADD_CREDIT_NOTE = `${API_URL}/api/add-credit-note`
export const EDIT_CREDIT_NOTE = `${API_URL}/api/update-credit-data`
export const GET_CREDIT_DATA = `${API_URL}/api/get-credit-data`
export const DELETE_CREDIT_NOTE = `${API_URL}/api/delete-credit-note`
export const SEND_CREDIT_NOTE_MAIL_TO_CUSTOMER = `${API_URL}/api/send-credit-note-email`
export const GET_CREDIT_INVOICE_PREVIEW_DETAIL = `${API_URL}/api/get-credit-note-data`

//get list of project unit in billing
export const GET_ASSIGNED_PROJECT_UNIT_IN_BILLING = `${API_URL}/api/list-client-project-units-for-billing`
export const GET_PROJECT_LIST_IN_BILLING = `${API_URL}/api/list-project-management-for-billing`
export const GET_PROJECT_DETAIL_IN_BILLING = `${API_URL}/api/get-project-management-for-billing`
export const GET_PROJECT_UNIT_DETAIL_IN_BILLING = `${API_URL}/api/get-client-project-unit-in-billing`

//billing Receipt
export const ADD_BILLING_RECEIPT = `${API_URL}/api/add-bill-payment-receipt`
export const GET_BILLING_RECEIPT_DETAIL = `${API_URL}/api/get-bill-payment`
export const GET_BILLING_COMPANY_DETAIL_IN_RECEIPT = `${API_URL}/api/get-company-for-payment`
export const GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT = `${API_URL}/api/get-bill-outstanding-amount`
export const GET_GST_LOCATION_LIST = `${API_URL}/api/list-company-gst-locations`
export const GET_GST_LOCATION_DETAIL = `${API_URL}/api/get-company-gst-locations`

export const EDIT_BILLING_RECEIPT = `${API_URL}/api/edit-bill-payment-receipt`
export const GET_BILLING_RECEIPT_LIST = `${API_URL}/api/list-bill-payment`
//invoice bill no
export const GET_BILL_INVOICE_NO_LIST = `${API_URL}/api/list-all-bill-invoice-no`
// Receipt no
export const GET_RECEIPT_NUMBER = `${API_URL}/api/generate-receipt-number`
//send mail to customer
export const SEND_MAIL_TO_CUSTOMER = `${API_URL}/api/send-invoice-email`
export const SEND_RECEIPT_MAIL_TO_CUSTOMER = `${API_URL}/api/send-receipt-invoice-email`
//download invoice
export const DOWNLOAD_INVOICE = `${API_URL}/api/download-invoice-pdf`
//download invoice
export const DELETE_BILLING_RECORD = `${API_URL}/api/delete-bill-payment`
//recurring Bills
export const GET_RECURRING_BILL = `${API_URL}/api/list-all-recurring-bills`

//FILTERS

export const GET_COMPANY_FILTER = `${API_URL}/api/get-company-list`
export const GET_FINANCIAL_YEAR_FILTER = `${API_URL}/api/get-financial-year-list`
export const GET_COMPANY_LOCATION_FILTER = `${API_URL}/api/get-location-list`
export const GET_CUSTOMER_FILTER = `${API_URL}/api/get-customer-list`
export const GET_PROJECT_FILTER = `${API_URL}/api/get-project-list`

//FILTERS FOR RECEIPT

export const GET_COMPANY_FILTER_FOR_RECEIPT = `${API_URL}/api/get-company-list-for-bill-payment`
export const GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT = `${API_URL}/api/get-bill-payment-with-financial-year`
export const GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT = `${API_URL}/api/get-bill-payment-location-list`
export const GET_CUSTOMER_FILTER_FOR_RECEIPT = `${API_URL}/api/get-customer-list-for-bill-payment`
export const GET_PROJECT_FILTER_FOR_RECEIPT = `${API_URL}/api/get-project-list-for-receipt`

//RECEIPT

export const GET_RECEIPT_PREVIEW = `${API_URL}/api/get-receipt-preview`

//CONSUMPTION
export const ADD_NEW_CONSUMPTION = `${API_URL}/api/add-consumption-data`
export const EDIT_CONSUMPTION = `${API_URL}/api/`
export const GET_CONSUMPTION_REGISTER_LIST = `${API_URL}/api/get-consumption-data`
export const GET_CONSUMPTION_DATA = `${API_URL}/api/get-closing-unit`
export const DELETE_CONSUMPTION_REGISTER = `${API_URL}/api/`
export const GET_CONSUMPTION_IN_BILLING = `${API_URL}/api/total-consumption`

// REPORT

//PROJECT OCCUPANCY
export const GET_ALL_PROJECTS = `${API_URL}/api/list-all-projects-for-reports`
export const GET_PROJECT_OCCUPANCY_DETAIL = `${API_URL}/api/list-project-floors`
//GST R1 REPORT
export const GET_ALL_COMPANIES = `${API_URL}/api/list-companies-for-reports`
export const GET_GST_R1_REPORT = `${API_URL}/api/list-gst-r1`

//GST REPORT EXCELL
export const DOWNLOAD_GSTR1_EXCELL = `${API_URL}/api/download-gstr1-excel`
export const DOWNLOAD_PROJECT_OCCUPANCY_EXCELL = `${API_URL}/api/list-project-floors-for-excel-download`

//SERVICE REQUEST TICKETS
export const GET_SERVICE_TICKETS_LIST = `${API_URL}/api/list-service-request-tickets-for-project-manager-or-supervisor`
export const GET_SERVICE_TICKETS_DETAIL = `${API_URL}/api/get-service-request-ticket`

//DELETE SERVICE REQUEST TICKETS MANPOWER MATERIAL OTHER COST
export const DELETE_MANPOWER = `${API_URL}/api/delete-manpower-user`
export const DELETE_MATERIAL = `${API_URL}/api/delete-material-user`
export const DELETE_OTHER_COST = `${API_URL}/api/delete-other-cost-user`

//add proposal, material othercost and manpower

export const ADD_MATERIAL_IN_TICKET = `${API_URL}/api/update-user-material`
export const ADD_MANPOWER_IN_TICKET = `${API_URL}/api/update-user-manpower`
export const ADD_OTHERCOST_IN_TICKET = `${API_URL}/api/update-user-other-cost`
export const SEND_FOR_REVIEW_IN_TICKET = `${API_URL}/api/internal-user-resolution-file-upload`
export const UPDATE_STATUS_IN_TICKET = `${API_URL}/api/update-ticket-status`

export const GET_RENEWALS = `${API_URL}/api/get-client-project-unit-counts`
export const DECLINE_RENEWAL = `${API_URL}/api/decline-client-project-unit-renewal`
export const GET_RENEWAL_DETAIL = `${API_URL}/api/list-client-project-units-for-renewal`
export const ADD_NEW_RENEWAL = `${API_URL}/api/clients/assign-project-unit`
export const UPDATE_RENEWAL = `${API_URL}/`
export const SEND_REMINDER_MAIL_TO_CUSTOMER = `${API_URL}/api/send-renewal-email`

//VENDOR PAYMENT

export const GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT = `${API_URL}/api/list-company-data-for-vendor-payment`
export const GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT = `${API_URL}/api/list-projects-by-admin`
export const GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT = `${API_URL}/api/list-vendors-for-invoice`
export const GET_BILL_HISTORY_FOR_VENDOR_PAYMENT = `${API_URL}/api/list-vendor-invoices-in-admin`
export const GET_PAYMENT_INVOICE_NUMBER = `${API_URL}/api/generate-payment-invno`

export const GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT = `${API_URL}/api/list-vendor-invoice-payments`
export const DELETE_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT = `${API_URL}/api/delete-vendor-payment`
export const GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST = `${API_URL}/api/list-projects-by-admin`
export const GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST = `${API_URL}/api/list-vendors-for-invoice`

//consumption filter
export const GET_CUSTOMER_FILTER_FOR_CONSUMPTION = `${API_URL}/api/list-client-data-using-user-id`
export const GET_PROJECT_FILTER_FOR_CONSUMPTION = `${API_URL}/api/list-project-management-for-admin`
export const GET_UNIT_FOR_CONSUMPTION = `${API_URL}/api/list-client-project-unit-for_admin`
export const GET_CONSUMPTION_FILTER = `${API_URL}/api/get-customer-consumption-data-for-admin`

//oustanding report
export const GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING = `${API_URL}/api/list-all-projects-for-reports`
export const GET_FINANCIAL_YEAR_FOR_OUTSTANDING = `${API_URL}/api/list-financial-years`
export const GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING = `${API_URL}/api/list-companies-for-reports`
export const GET_REPORT_FOR_CUSTOMER_OUTSTANDING = `${API_URL}/api/get-outstanding-amounts`
export const GET_LEDGER_DETAIL = `${API_URL}/api/get-billing-ledger`
export const Download_LEDGER = `${API_URL}/api/download-billing-ledger-pdf`
export const Download_OUTSTANDING_REPORT_EXCEL = `${API_URL}/api/download-outstanding-amounts`

//vendor Summary report
export const GET_VENDOR_SUMMARY_REPORT = `${API_URL}/api/list-all-vendor-invoices-for-reports`
export const GET_VENDOR_INVOICE_DATA = `${API_URL}/api/list-vendor-invoices-by-vendorId`
export const GET_VENDOR_INVOICE_DATA_WITH_TDS = `${API_URL}/api/list-vendor-invoices-with-TDS`
export const GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT = `${API_URL}/api/list-vendor-invoices-with-outstanding-amount`
export const GET_VENDOR_INVOICES_PAYMENT_DATA = `${API_URL}/api/list-vendor-invoice-payments-by-vendorId`
export const GET_VENDOR_TICKET_DATA = `${API_URL}/api/list-service-request-tickets-by-vendorId`

export const GET_VENDOR_LEDGER = `${API_URL}/api/get-vendor-ledger`
export const Download_VENDOR_LEDGER = `${API_URL}/api/download-vendor-ledger-pdf`
//customer index

export const GET_CUSTOMER_INDEX = `${API_URL}/api/customer-index`
export const GET_CITY_FOR_CUSTOMER_INDEX = `${API_URL}/api/list-all-cities-for-reports`
export const GET_PROJECT_FOR_CUSTOMER_INDEX = `${API_URL}/api/list-all-projects-for-reports`
export const GET_STATE_FOR_CUSTOMER_INDEX = `${API_URL}/api/list-all-states-for-reports`
export const DOWNLOAD_CUSTOMER_INDEX = `${API_URL}/api/download-customer-data-excel`

//proposal
export const GET_PROPOSALS = `${API_URL}/api/vendor-proposals`
export const GET_PROPOSAL_DETAIL = `${API_URL}/api/view-proposal-for-admin`
export const ADD_NEW_PROPOSAL = `${API_URL}/api/add-vendor-proposal`
export const DELETE_PROPOSAL = `${API_URL}`
export const UPDATE_PROPOSAL = `${API_URL}`
export const ACCEPT_PROPOSAL = `${API_URL}/api/update-proposal-status`

//vendor debit note
export const GET_VENDOR_INVOICE_IN_DEBIT_NOTE = `${API_URL}/api/list-unpaid-vendor-invoices`

export const GET_DEBIT_NOTE_LIST = `${API_URL}/api/get-debit-note-list`
export const GET_DEBIT_NOTE_DETAIL = `${API_URL}/api/get-debit-notes`
export const ADD_NEW_DEBIT_NOTE = `${API_URL}/api/add-new-debit-note`
export const UPDATE_DEBIT_NOTE = `${API_URL}/api/edit-debit-note`
export const DELETE_DEBIT_NOTE = `${API_URL}/api/delete-debit-note`
export const GET_VENDOR_INVOICE_DETAIL = `${API_URL}/api/get-unpaid-vendor-invoices`

export const GET_GOODS_SERVICES_LIST = `${API_URL}/api/list-goods-and-service-types-for-vendor`


//manage client users
export const GET_CLIENT_USER_LIST = `${API_URL}/api/list-client-users-by-clientId`
export const GET_CLIENT_USER_DETAIL = `${API_URL}/api/get-client-user-by-id`
export const ADD_NEW_CLIENT_USER = `${API_URL}/api/add-client-user`
export const UPDATE_CLIENT_USER = `${API_URL}/api/update-client-user`
export const DELETE_CLIENT_USER = `${API_URL}/api/delete-client-user`
export const REINVITE_CLIENT_USER = `${API_URL}/api/reinvite-client-user`
export const GET_ACTIVE_CLIENT_PROJECT_UNIT = `${API_URL}/api/get-active-client-project-units`

//export
export const DOWNLOAD_BILLING_REGISTER_EXCEL = `${API_URL}/api/download-unpaid-billings-excel`
export const DOWNLOAD_RECEIPT_EXCEL = `${API_URL}/api/download-bill-payments-excel`
export const DOWNLOAD_DEBIT_NOTE_EXCEL = `${API_URL}/api/download-debit-note-excel`
export const DOWNLOAD_CREDIT_NOTE_EXCEL = `${API_URL}/api/download-credit-note-excel`