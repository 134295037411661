import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_RENEWALS,
  GET_RENEWAL_DETAIL,
  ADD_NEW_RENEWAL,
  DECLINE_RENEWAL,
  UPDATE_RENEWAL,
} from "./actionTypes"
import {
  getRenewalsSuccess,
  getRenewalsFail,
  getRenewalDetailSuccess,
  getRenewalDetailFail,
  addRenewalFail,
  addRenewalSuccess,
  updateRenewalSuccess,
  updateRenewalFail,
  deleteRenewalSuccess,
  deleteRenewalFail,
} from "./actions"

import {
  getRenewals,
  getRenewalDetails,
  addNewRenewal,
  updateRenewal,
  deleteRenewal,
  declineRenewal,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

function* fetchRenewals({id}) {
  try {
    const response = yield call(getRenewals,id)
    yield put(getRenewalsSuccess(response))
  } catch (error) {
    yield put(getRenewalsFail(error))
  }
}

function* fetchRenewalDetail({ renewalId }) {
  try {
    const response = yield call(getRenewalDetails, renewalId)
    yield put(getRenewalDetailSuccess(response))
  } catch (error) {
    yield put(getRenewalDetailFail(error))
  }
}

function* onUpdateRenewal({ payload: { id, renewal, history } }) {
  try {
    const response = yield call(updateRenewal, id, renewal)
    if (response && response.status === true) {
      yield put(updateRenewalSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Renewal Master Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/renewal-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateRenewalFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteRenewal({ payload: renewal }) {
  try {
    const response = yield call(declineRenewal, renewal)
    yield put(deleteRenewalSuccess(response))
  } catch (error) {
    yield put(deleteRenewalFail(error))
  }
}

function* onAddNewRenewal({ payload: { renewal, history } }) {
  try {
    const response = yield call(addNewRenewal, renewal)
    if (response.status === true) {
      yield put(addRenewalSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Renewal Master Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/renewal-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addRenewalFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* renewalsSaga() {
  yield takeEvery(GET_RENEWALS, fetchRenewals)
  yield takeEvery(GET_RENEWAL_DETAIL, fetchRenewalDetail)
  yield takeEvery(ADD_NEW_RENEWAL, onAddNewRenewal)
  yield takeEvery(UPDATE_RENEWAL, onUpdateRenewal)
  yield takeEvery(DECLINE_RENEWAL, onDeleteRenewal)
}

export default renewalsSaga
