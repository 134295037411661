import {
  downloadProjectUnitDataExcel,
  vendorAssignedticketExcel,
} from "helpers/fakebackend_helper"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal, Table } from "reactstrap"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { newUnitAssignedList, vendorTicketList } from "store/actions"

const VendorTicketAssigned = ({ isOpen, toggle, filterResponse }) => {
  const dispatch = useDispatch()

  const dataState = state => state.Dashboard?.vendorTicketList?.data
  const data = useSelector(dataState)

  useEffect(() => {
    if (isOpen === true) {
      dispatch(vendorTicketList({ filter: filterResponse }))
    }
  }, [filterResponse, dispatch, isOpen])

  const handleExport = async () => {
    // Construct the URL for download
    const value = {
      filter: filterResponse,
      type: "vendortickets",
    }
    const downloadUrl = await vendorAssignedticketExcel(value)
  }
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Vendor Ticket List
        </h5>
        <button
          onClick={handleExport}
          type="button"
          className="btn btn-primary btn-sm me-4"
        >
          Export
        </button>
        <button
          onClick={toggle}
          type="button"
          className="close mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <SimpleBar style={{ maxHeight: "60vh", overflowX: "auto" }}>
          <div style={{ minWidth: "100%" }}>
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Ticket No</th>
                  <th>Vendor Name</th>
                  <th>Priority</th>
                  <th>Ticket Status</th>
                </tr>
              </thead>
              <tbody>
                {(data || []).map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{row.ticket_no}</td>
                    <td>{row.vendor_name}</td>
                    <td>{row.priority_id}</td>
                    <td>{row.status_name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </SimpleBar>
      </div>
    </Modal>
  )
}

export default VendorTicketAssigned
