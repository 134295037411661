import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const SettingContent = props => {
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  useEffect(() => {
    const storedPermission = JSON.parse(localStorage.getItem("permission"))
    setPermission(storedPermission)
  }, [localStorage.getItem("permission")])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard">
                <i className="mdi mdi-arrow-left"></i>
                <span>
                  <b>{props.t("Settings")}</b>
                </span>
              </Link>
            </li>
            {permission
              .filter(
                item => item.page === "/regional-settings" && item.access === 1
              )
              .map((item, index) => (
                <li key={index}>
                  <Link to="/settings/regional-settings">
                    <i className=" mdi mdi-earth"></i>
                    <span>{props.t("Regional Settings")}</span>
                  </Link>
                </li>
              ))}
            {permission
              .filter(
                item =>
                  item.page === "/user-role-management" && item.access === 1
              )
              .map((item, index) => (
                <li key={index}>
                  <Link to="/settings/user-role-management">
                    <i className=" mdi mdi-account-group"></i>
                    <span>{props.t("User Role Management")}</span>
                  </Link>
                </li>
              ))}

            {permission.some(
              item =>
                (item.page === "/companies" ||
                  item.page === "/company-setup-page") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className=" mdi mdi-office-building"></i>
                  <span>{props.t("Company")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item => item.page === "/companies" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/settings/companies">
                          {props.t("All Companies")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/company-setup-page" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/settings/company-setup-page">
                          {props.t("Add Company")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            {permission.some(
              item =>
                (item.page === "/vendor-master" ||
                  item.page === "/facility-master" ||
                  item.page === "/document-master" ||
                  item.page === "/expense-master" ||
                  item.page === "/maintenence-service" ||
                  item.page === "/customer-ticket-master" ||
                  item.page === "/invoice-master" ||
                  item.page === "/project-unit-service" ||
                  item.page === "/project-service" ||
                  item.page === "/landlord-service" ||
                  item.page === "/material-master" ||
                  item.page === "/unit-master" ||
                  item.page === "/proposal-type-master" ||
                  item.page === "/sac-hsn-code-master") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className=" mdi mdi-cogs"></i>
                  <span>{props.t("Master Configuration")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item =>
                        item.page === "/vendor-master" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/settings/vendor-master">
                          {props.t("Vendor Service Master")}
                        </Link>
                      </li>
                    ))}

                  {permission
                    .filter(
                      item =>
                        item.page === "/facility-master" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/settings/facility-master">
                          {props.t("Facility Master")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/document-master" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/document-master">
                          {props.t("Document Master")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/expense-master" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/expense-master">
                          {props.t("Expense Type Master")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/maintenence-service" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/maintenence-service">
                          {props.t("Maintenance Issue Master")}
                        </Link>
                      </li>
                    ))}

                  {permission
                    .filter(
                      item =>
                        item.page === "/customer-ticket-master" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/customer-ticket-master">
                          {props.t("Customer Support Ticket topic Master")}
                        </Link>
                      </li>
                    ))}

                  {permission
                    .filter(
                      item =>
                        item.page === "/invoice-master" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/invoice-master">
                          {props.t("Invoice Component")}
                        </Link>
                      </li>
                    ))}

                  {permission
                    .filter(
                      item =>
                        item.page === "/project-unit-service" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/project-unit-service">
                          {props.t("Project Unit Category Master")}
                        </Link>
                      </li>
                    ))}

                  {permission
                    .filter(
                      item =>
                        item.page === "/project-service" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/project-service">
                          {props.t("Project compliance")}
                        </Link>
                      </li>
                    ))}

                  {permission
                    .filter(
                      item =>
                        item.page === "/landlord-service" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/landlord-service">
                          {props.t("Landlord Master")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/sac-hsn-code-master" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/sac-hsn-code-master">
                          {props.t("SAC / HSN Code Master")}
                        </Link>
                      </li>
                    ))}

                  {permission
                    .filter(
                      item =>
                        item.page === "/material-master" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/material-master">
                          {props.t("Material Master")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/unit-master" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/unit-master">
                          {props.t("Unit Master")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/proposal-type-master" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/proposal-type-master">
                          {props.t("Goods & Services Category Master")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/reason-master" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/settings/reason-master">
                          {props.t("Credit & Debit Note Reason Master")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SettingContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SettingContent))
