import {
    ADD_NEW_TIMEREGION,
    ADD_TIMEREGION_SUCCESS,
    ADD_TIMEREGION_FAIL,

    GET_TIMEREGION,
    GET_TIMEREGION_SUCCESS,
    GET_TIMEREGION_FAIL,

    ADD_NEW_FILECONFIGURATION,
    ADD_FILECONFIGURATION_SUCCESS,
    ADD_FILECONFIGURATION_FAIL,

    GET_FILECONFIGURATION,
    GET_FILECONFIGURATION_SUCCESS,
    GET_FILECONFIGURATION_FAIL,

    ADD_NEW_RECAPTCHACONFIGURATION,
    ADD_RECAPTCHACONFIGURATION_SUCCESS,
    ADD_RECAPTCHACONFIGURATION_FAIL,

    GET_RECAPTCHACONFIGURATION,
    GET_RECAPTCHACONFIGURATION_SUCCESS,
    GET_RECAPTCHACONFIGURATION_FAIL,

    ADD_NEW_EMAILCONFIGURATION,
    ADD_EMAILCONFIGURATION_SUCCESS,
    ADD_EMAILCONFIGURATION_FAIL,

    GET_EMAILCONFIGURATION,
    GET_EMAILCONFIGURATION_SUCCESS,
    GET_EMAILCONFIGURATION_FAIL,

    ADD_NEW_USERPASSWORDSETTINGS,
    ADD_USERPASSWORDSETTINGS_SUCCESS,
    ADD_USERPASSWORDSETTINGS_FAIL,

    GET_USERPASSWORDSETTINGS,
    GET_USERPASSWORDSETTINGS_SUCCESS,
    GET_USERPASSWORDSETTINGS_FAIL,

    GET_DATETIMEFORMAT,
    GET_DATETIMEFORMAT_SUCCESS,
    GET_DATETIMEFORMAT_FAIL,

    GET_TIMEZONE,
    GET_TIMEZONE_SUCCESS,
    GET_TIMEZONE_FAIL,

    GET_CURRENCY,
    GET_CURRENCY_SUCCESS,
    GET_CURRENCY_FAIL,

  } from "./actionTypes"
  
  //TIME REGION

  export const addNewTimeregion = timeregion => ({
    type: ADD_NEW_TIMEREGION,
    payload: timeregion,
  })
  
  export const addTimeregionSuccess = timeregion => ({
    type: ADD_TIMEREGION_SUCCESS,
    payload: timeregion,
  })
  
  export const addTimeregionFail = error => ({
    type: ADD_TIMEREGION_FAIL,
    payload: error,
  })

  export const getTimeregion = timeregion => ({
    type: GET_TIMEREGION,
    payload: timeregion,
  })
  
  export const getTimeregionSuccess = timeregion => ({
    type: GET_TIMEREGION_SUCCESS,
    payload: timeregion,
  })
  
  export const getTimeregionFail = error => ({
    type: GET_TIMEREGION_FAIL,
    payload: error,
  })

  // FILE CONFIGURATION

  export const addNewFileconfiguration = fileconfiguration => ({
    type: ADD_NEW_FILECONFIGURATION,
    payload: fileconfiguration,
  })
  
  export const addFileconfigurationSuccess = fileconfiguration => ({
    type: ADD_FILECONFIGURATION_SUCCESS,
    payload: fileconfiguration,
  })
  
  export const addFileconfigurationFail = error => ({
    type: ADD_FILECONFIGURATION_FAIL,
    payload: error,
  })

  export const getFileconfiguration = fileconfiguration => ({
    type: GET_FILECONFIGURATION,
    payload: fileconfiguration,
  })
  
  export const getFileconfigurationSuccess = fileconfiguration => ({
    type: GET_FILECONFIGURATION_SUCCESS,
    payload: fileconfiguration,
  })
  
  export const getFileconfigurationFail = error => ({
    type: GET_FILECONFIGURATION_FAIL,
    payload: error,
  })
  
    // RECAPTCHA CONFIGURATION

  export const addNewRecaptchaconfiguration = recaptchaconfiguration => ({
    type: ADD_NEW_RECAPTCHACONFIGURATION,
    payload: recaptchaconfiguration,
  })
  
  export const addRecaptchaconfigurationSuccess = recaptchaconfiguration => ({
    type: ADD_RECAPTCHACONFIGURATION_SUCCESS,
    payload: recaptchaconfiguration,
  })
  
  export const addRecaptchaconfigurationFail = error => ({
    type: ADD_RECAPTCHACONFIGURATION_FAIL,
    payload: error,
  })

  export const getRecaptchaconfiguration = recaptchaconfiguration => ({
    type: GET_RECAPTCHACONFIGURATION,
    payload: recaptchaconfiguration,
  })
  
  export const getRecaptchaconfigurationSuccess = recaptchaconfiguration => ({
    type: GET_RECAPTCHACONFIGURATION_SUCCESS,
    payload: recaptchaconfiguration,
  })
  
  export const getRecaptchaconfigurationFail = error => ({
    type: GET_RECAPTCHACONFIGURATION_FAIL,
    payload: error,
  })
    // EMAIL CONFIGURATION

  export const addNewEmailconfiguration = emailconfiguration => ({
    type: ADD_NEW_EMAILCONFIGURATION,
    payload: emailconfiguration,
  })
  
  export const addEmailconfigurationSuccess = emailconfiguration => ({
    type: ADD_EMAILCONFIGURATION_SUCCESS,
    payload: emailconfiguration,
  })
  
  export const addEmailconfigurationFail = error => ({
    type: ADD_EMAILCONFIGURATION_FAIL,
    payload: error,
  })

  export const getEmailconfiguration = emailconfiguration => ({
    type: GET_EMAILCONFIGURATION,
    payload: emailconfiguration,
  })
  
  export const getEmailconfigurationSuccess = emailconfiguration => ({
    type: GET_EMAILCONFIGURATION_SUCCESS,
    payload: emailconfiguration,
  })
  
  export const getEmailconfigurationFail = error => ({
    type: GET_EMAILCONFIGURATION_FAIL,
    payload: error,
  })
  
    // USER PASSWORD SETTINGS

  export const addNewUserpasswordsettings = userpasswordsetting => ({
    type: ADD_NEW_USERPASSWORDSETTINGS,
    payload: userpasswordsetting,
  })
  
  export const addUserpasswordsettingsSuccess = userpasswordsetting => ({
    type: ADD_USERPASSWORDSETTINGS_SUCCESS,
    payload: userpasswordsetting,
  })
  
  export const addUserpasswordsettingsFail = error => ({
    type: ADD_USERPASSWORDSETTINGS_FAIL,
    payload: error,
  })

  export const getUserpasswordsettings = userpasswordsetting => ({
    type: GET_USERPASSWORDSETTINGS,
    payload: userpasswordsetting,
  })
  
  export const getUserpasswordsettingsSuccess = userpasswordsetting => ({
    type: GET_USERPASSWORDSETTINGS_SUCCESS,
    payload: userpasswordsetting,
  })
  
  export const getUserpasswordsettingsFail = error => ({
    type: GET_USERPASSWORDSETTINGS_FAIL,
    payload: error,
  })
  
  // DATE AND TIME FORMAT 

  export const getDateTimeFormat = dateTime => ({
    type: GET_DATETIMEFORMAT,
    payload: dateTime,
  })
  
  export const getDateTimeFormatSuccess = dateTime => ({
    type: GET_DATETIMEFORMAT_SUCCESS,
    payload: dateTime,
  })
  
  export const getDateTimeFormatFail = error => ({
    type: GET_DATETIMEFORMAT_FAIL,
    payload: error,
  })

  // TIME ZONE SELECTION

  export const getTimeZone = dateTime => ({
    type: GET_TIMEZONE,
    payload: dateTime,
  })
  
  export const getTimeZoneSuccess = dateTime => ({
    type: GET_TIMEZONE_SUCCESS,
    payload: dateTime,
  })
  
  export const getTimeZoneFail = error => ({
    type: GET_TIMEZONE_FAIL,
    payload: error,
  })

  //CURRENCY

  export const getCurrency = dateTime => ({
    type: GET_CURRENCY,
    payload: dateTime,
  })
  
  export const getCurrencySuccess = dateTime => ({
    type: GET_CURRENCY_SUCCESS,
    payload: dateTime,
  })
  
  export const getCurrencyFail = error => ({
    type: GET_CURRENCY_FAIL,
    payload: error,
  })
