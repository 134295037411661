/* MAINTAINENCES */
export const GET_MAINTAINENCES = "GET_MAINTAINENCES"
export const GET_MAINTAINENCES_SUCCESS = "GET_MAINTAINENCES_SUCCESS"
export const GET_MAINTAINENCES_FAIL = "GET_MAINTAINENCES_FAIL"

/* MAINTAINENCES DETAIL*/
export const GET_MAINTAINENCE_DETAIL = "GET_MAINTAINENCE_DETAIL"
export const GET_MAINTAINENCE_DETAIL_SUCCESS = "GET_MAINTAINENCE_DETAIL_SUCCESS"
export const GET_MAINTAINENCE_DETAIL_FAIL = "GET_MAINTAINENCE_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_MAINTAINENCE = "ADD_NEW_MAINTAINENCE"
 export const ADD_MAINTAINENCE_SUCCESS = "ADD_MAINTAINENCE_SUCCESS"
 export const ADD_MAINTAINENCE_FAIL = "ADD_MAINTAINENCE_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_MAINTAINENCE = "UPDATE_MAINTAINENCE"
 export const UPDATE_MAINTAINENCE_SUCCESS = "UPDATE_MAINTAINENCE_SUCCESS"
 export const UPDATE_MAINTAINENCE_FAIL = "UPDATE_MAINTAINENCE_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_MAINTAINENCE = "DELETE_MAINTAINENCE"
 export const DELETE_MAINTAINENCE_SUCCESS = "DELETE_MAINTAINENCE_SUCCESS"
 export const DELETE_MAINTAINENCE_FAIL = "DELETE_MAINTAINENCE_FAIL"
