import React, { useEffect, useState } from "react"
import { Col, Button, Row, Input, Label } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"

import {
  getAllCompaniesForCustomerOustanding,
  getAllProjectsForCustomerOustanding,
  getClientsList,
  getFinancialYearForOutstanding,
  getReportForCustomerOustanding,
} from "store/actions"
import { useNavigate } from "react-router-dom"
import { downloadOutstandingReportExcel } from "helpers/fakebackend_helper"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [client, setClient] = useState("")
  const clientsState = state => state.clients.clientsList.clients
  const clientList = useSelector(clientsState) || []

  const handleSelectClient = ele => {
    const value = ele.value
    setClient(value)
  }

  const handleSearch = () => {
    const value = {
      client_id: client,
    }

    dispatch(getReportForCustomerOustanding(value))
  }

  useEffect(() => {
    if (user.user_id) {
      dispatch(getClientsList(user.user_id))
    }
  }, [dispatch])

  const exportExcel = async () => {
    const value = {
      type: "OutstandingReport",
      client_id: client,
      user_id: user.user_id,

    }
    const response = await downloadOutstandingReportExcel(value)
  }

  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  return (
    <React.Fragment>
      <Col xxl={2} lg={2}>
        <select
          className="form-select select2 mb-3 mb-xxl-0"
          defaultValue="Select Client"
          onChange={e => handleSelectClient(e.target)}
        >
          <option value="">Select Client</option>
          {(clientList || []).map(item => (
            <option key={item.id} value={item.id}>
              {item.customer_name}
            </option>
          ))}
        </select>
      </Col>

      <Col xxl={1} lg={1} className="mb-3">
        <div className="mb-3 mb-xxl-0">
          <button
            type="button"
            className="btn btn-soft-secondary w-100"
            onClick={handleSearch}
          >
            <i className="mdi mdi-filter-outline align-middle"></i> Submit
          </button>
        </div>
      </Col>
      {permission
        .filter(
          item =>
            item.page === "/export-outstanding-report" && item.access === 1
        )
        .map(index => (
          <Col key={index} xxl={1} lg={1} className="mb-3">
            <div className="mb-3 mb-xxl-0">
              <button
                type="button"
                className="btn btn-soft-secondary w-100"
                onClick={exportExcel}
              >
                <i className="mdi mdi-filter-outline align-middle"></i> Export
              </button>
            </div>
          </Col>
        ))}
    </React.Fragment>
  )
}

export default JobListGlobalFilter
