

//CUSTOMER FILTER

export const GET_CUSTOMER_INDEX = "GET_CUSTOMER_INDEX"
export const GET_CUSTOMER_INDEX_SUCCESS = "GET_CUSTOMER_INDEX_SUCCESS"
export const GET_CUSTOMER_INDEX_FAIL = "GET_CUSTOMER_INDEX_FAIL"

//PROJECT FILTER

export const GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX="GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX"
export const GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_SUCCESS="GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_SUCCESS"
export const GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_FAIL="GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_FAIL"

//STATE

export const GET_STATE_FOR_CUSTOMER_INDEX="GET_STATE_FOR_CUSTOMER_INDEX"
export const GET_STATE_FOR_CUSTOMER_INDEX_SUCCESS="GET_STATE_FOR_CUSTOMER_INDEX_SUCCESS"
export const GET_STATE_FOR_CUSTOMER_INDEX_FAIL="GET_STATE_FOR_CUSTOMER_INDEX_FAIL"

//CIty
export const GET_CITY_FOR_CUSTOMER_INDEX="GET_CITY_FOR_CUSTOMER_INDEX"
export const GET_CITY_FOR_CUSTOMER_INDEX_SUCCESS="GET_CITY_FOR_CUSTOMER_INDEX_SUCCESS"
export const GET_CITY_FOR_CUSTOMER_INDEX_FAIL="GET_CITY_FOR_CUSTOMER_INDEX_FAIL"