import {
    GET_INVOICES,
    GET_INVOICES_FAIL,
    GET_INVOICES_SUCCESS,
    GET_INVOICE_DETAIL,
    ADD_NEW_INVOICE,
    ADD_INVOICE_SUCCESS,
    ADD_INVOICE_FAIL,
    UPDATE_INVOICE,
    UPDATE_INVOICE_SUCCESS,
    UPDATE_INVOICE_FAIL,
    DELETE_INVOICE,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAIL,
    GET_INVOICE_DETAIL_FAIL,
    GET_INVOICE_DETAIL_SUCCESS,
    GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT,
    GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_SUCCESS,
    GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_FAIL,
    GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT,
    GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_SUCCESS,
    GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_FAIL,
  } from "./actionTypes"
  
  export const getInvoices = () => ({
    type: GET_INVOICES,
  })
  
  export const getInvoicesSuccess = invoices => ({
    type: GET_INVOICES_SUCCESS,
    payload: invoices,
  })
  
  export const addNewInvoice = invoice => ({
    type: ADD_NEW_INVOICE,
    payload: invoice,
  })
  
  export const addInvoiceSuccess = invoice => ({
    type: ADD_INVOICE_SUCCESS,
    payload: invoice,
  })
  
  export const addInvoiceFail = error => ({
    type: ADD_INVOICE_FAIL,
    payload: error,
  })
  
  export const updateInvoice = (id,invoice) => ({
    type: UPDATE_INVOICE,
    payload: {id,invoice},
  })
  
  export const updateInvoiceSuccess = invoice => ({
    type: UPDATE_INVOICE_SUCCESS,
    payload: invoice,
  })
  
  export const updateInvoiceFail = error => ({
    type: UPDATE_INVOICE_FAIL,
    payload: error,
  })
  
  export const deleteInvoice = invoice => ({
    type: DELETE_INVOICE,
    payload: invoice,
  })
  
  export const deleteInvoiceSuccess = invoice => ({
    type: DELETE_INVOICE_SUCCESS,
    payload: invoice,
  })
  
  export const deleteInvoiceFail = error => ({
    type: DELETE_INVOICE_FAIL,
    payload: error,
  })
  
  export const getInvoicesFail = error => ({
    type: GET_INVOICES_FAIL,
    payload: error,
  })
  
  export const getInvoiceDetail = invoiceId => ({
    type: GET_INVOICE_DETAIL,
    invoiceId,
  })
  
  export const getInvoiceDetailSuccess = invoiceDetails => ({
    type: GET_INVOICE_DETAIL_SUCCESS,
    payload: invoiceDetails,
  })
  
  export const getInvoiceDetailFail = error => ({
    type: GET_INVOICE_DETAIL_FAIL,
    payload: error,
  })
  
  export const getGstApplicableRateForInvoiceComponent = () => ({
    type: GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT,
  })
  
  export const getGstApplicableRateForInvoiceComponentSuccess = rate => ({
    type: GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_SUCCESS,
    payload: rate,
  })

  export const getGstApplicableRateForInvoiceComponentFail = error => ({
    type: GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT_FAIL,
    payload: error,
  })

  export const getSACHSNCodeForInvoiceComponent = () => ({
    type: GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT,
  })

  export const getSACHSNCodeForInvoiceComponentSuccess = rate => ({
    type: GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_SUCCESS,
    payload: rate,
  })

  export const getSACHSNCodeForInvoiceComponentFail = error => ({
    type: GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT_FAIL,
    payload: error,
  })