import React, { useEffect, useMemo, useState } from "react"
import FlatPickr from "react-flatpickr"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { getAllCompanies, getGstR1Report } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { API_URL } from "helpers/url_helper"
import { downloadGstR1Excell } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

const GstR1Report = () => {
  //meta title

  document.title = "GST R1 "
  const dispatch = useDispatch()

  const AllCompanyState = state => state.Reports.allCompanies.companies
  const GstR1ReportState = state => state.Reports.gstReport.data
  const allCompanies = useSelector(AllCompanyState)
  const Gstr1Report = useSelector(GstR1ReportState)
  console.log(allCompanies)
  console.log(Gstr1Report)
  const [companyLocation, setCompanyLoaction] = useState()

  const validation = useFormik({
    initialValues: {
      company_id: "",
      location_id: "",
      month: "",
      year: new Date().getFullYear() || "",
      status: "",
      type:'gstR1'
    },
  })

  console.log(validation.values)
  useEffect(() => {
    dispatch(getAllCompanies())
  }, [dispatch])

  const handleFilter = () => {
    if (validation.values.company_id && validation.values.location_id) {
      dispatch(getGstR1Report(validation.values));
    } else {
      Swal.fire({
        title: "Company Location Required",
        text: "Please select company location",
        icon: "warning"
      });
    }
  };
  

  const [selectedGroup, setSelectedGroup] = useState(null)

  const handleSelectGroup = event => {
    const selectedValue = event.target.value
    setSelectedGroup(selectedValue) // Now just the value of the selected option
  }

  // Memoize optionGroup to avoid unnecessary re-renders
  const optionGroup = useMemo(
    () =>
      allCompanies &&
      allCompanies.map(company => ({
        label: company?.name,
        options: company?.locations?.map(loc => ({
          label: loc?.location_title,
          value: loc?.id,
          company_id: loc?.company_id,
        })),
      })),
    [allCompanies]
  )

  useEffect(() => {
    if (selectedGroup && optionGroup.length > 0) {
      // Find the selected location option from the optionGroup

      const selectedOption = optionGroup
        .flatMap(group => group.options)
        .find(option => Number(option.value) === Number(selectedGroup))

      if (selectedOption) {
        // Set the Formik field values for both company_id and location_id
        validation.setFieldValue("company_id", selectedOption.company_id)
        validation.setFieldValue("location_id", selectedOption.value)
      }
    }
  }, [selectedGroup, optionGroup])

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: "#f8f9fa", // Customize the control background color if needed
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: "#e9ecef", // Customize the menu background color
      maxHeight: 200, // Set the max height for the dropdown menu
      overflowY: "auto", // Enable vertical scrolling
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#007bff"
        : state.isFocused
        ? "#f8f9fa"
        : "#fff",
      color: state.isSelected ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#f8f9fa",
        color: "#000",
      },
    }),
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const years = []
  const startYear = 2022
  const endYear = new Date().getFullYear()

  // Create an array of years from startYear to endYear
  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }

  // Sort the array in descending order
  years.sort((a, b) => b - a)

  // Move the current year to the top of the sorted array
  const currentYearIndex = years.indexOf(new Date().getFullYear())
  if (currentYearIndex !== -1) {
    const currentYear = years.splice(currentYearIndex, 1)[0]
    years.unshift(currentYear)
  }

  const handleExport = async () => {
    if (validation.values.company_id && validation.values.location_id) {
      try {
        // Construct the URL for download
        const downloadUrl = await downloadGstR1Excell(validation.values);
  
      } catch (error) {
        Swal.fire({
          title: "Export Failed",
          text: "There was an issue generating the export. Please try again later.",
          icon: "error"
        });
      }
    } else {
      Swal.fire({
        title: "Company location Required",
        text: "Please select company location",
        icon: "warning"
      });
    }
  };
  

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="GST R1 Report" breadcrumbItem="GST R1 Report" />

          <Row>
            <Col xxl={4} lg={6}>
              <div className="mb-3">
                <Label>Select Company Location</Label>
                <select
                  value={selectedGroup || ""}
                  onChange={handleSelectGroup}
                  className="form-select"
                >
                  <option value="">Select Company Location</option>
                  {optionGroup &&
                    optionGroup.map((group, index) => (
                      <optgroup key={index} label={group.label}>
                        {group.options.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                </select>
                {!selectedGroup && (
                  <div className="text-danger">
                    Please select company location
                  </div>
                )}
                {validation.touched.location_id && validation.errors.location_id ? (
                  <FormFeedback type="invalid">
                    {validation.errors.location_id}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col xxl={2} lg={4}>
              <Label htmlFor="month">Select Month</Label>
              <div className="mb-2" id="datepicker1">
                <Input
                  type="select"
                  id="month"
                  name="month"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.month}
                  className={`form-control ${
                    validation.touched.month && validation.errors.month
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Month</option>
                  {months.map((month, index) => (
                    <option key={index + 1} value={index + 1}>
                      {month}
                    </option>
                  ))}
                </Input>
                {validation.touched.month && validation.errors.month ? (
                  <FormFeedback type="invalid">
                    {validation.errors.month}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col xxl={2} lg={4}>
              <Label htmlFor="year">Select Year</Label>
              <div className="mb-2" id="datepicker2">
                <Input
                  type="select"
                  id="year"
                  name="year"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.year}
                  className={`form-control ${
                    validation.touched.year && validation.errors.year
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Year</option>
                  {years.map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
                {validation.touched.year && validation.errors.year ? (
                  <FormFeedback type="invalid">
                    {validation.errors.year}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xxl={2} lg={4}>
              <Label htmlFor="month">Select Status</Label>
              <div className="mb-2" id="datepicker1">
                <Input
                  type="select"
                  // id="month"
                  name="status"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.status}
                  className={`form-control ${
                    validation.touched.status && validation.errors.status
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Status</option>
                  <option value="1">Paid</option>
                  <option value="0">Unpaid</option>
                </Input>
                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.status}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col xxl={2} lg={4}>
              <div className="mb-2 mb-xxl-0">
                <button
                  type="button"
                  className="btn btn-soft-primary w-100 mt-4"
                  onClick={() => {
                    handleFilter()
                  }}
                >
                  <i className="mdi mdi-filter-outline align-middle"></i> Filter
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="h4">GST R1 Report </CardTitle>
                    </Col>
                    
                    <Col className="text-end">
                      <button
                        onClick={handleExport}
                        type="button"
                        className="btn btn-primary btn-sm me-4"
                      >
                        Export
                      </button>
                    </Col>
                  </Row>
                  {Gstr1Report && Gstr1Report.length > 0 ? (
                    <div className="table-responsive">
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Customer Name</th>
                            <th>Customer GST No</th>
                            <th>Inv No</th>
                            <th>Inv Date</th>
                            <th>Company Gst No</th>
                            <th>Inv Amount</th>
                            <th>Reverse Charge</th>
                            <th>Inv Type</th>
                            <th>Taxable Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(Gstr1Report || []).map((row, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{row?.billing?.customer_name}</td>
                              <td>{row?.client?.gst_no}</td>
                              <td>{row?.billing?.invoice_no}</td>
                              <td>{row?.billing?.invoice_date}</td>
                              <td>{row?.location?.gst_no}</td>
                              <td>{row?.billing?.invoice_value}</td>
                              <td>N</td>
                              <td>{row?.billing?.invoice_type}</td>
                              <td>{row?.billing?.discounted_subtotal}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <Alert color="warning" role="alert">
                      Please select a valid company and date range.{" "}
                    </Alert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GstR1Report
