import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { Card, Col, CardBody } from "reactstrap"
import { getCountForDashboard, getCountForTicketsDashboard } from "store/actions"

const typeOfRequest = () => {
  const dispatch = useDispatch()
  const countState = state => state.Dashboard.count
  const count = useSelector(countState) || []

  
  useEffect(() => {
    dispatch(getCountForDashboard())
  }, [dispatch])

  return (
    <React.Fragment>
      {/* {(blodStatsData || []).map((stat, index) => ( */}
      {/* <Col lg={4} key={index}> */}
      
      <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/tickets/tickets-list">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">All Tickets</p>
                      <h5 className="mb-0 text-primary">
                        {count && count.total_tickets}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/tickets/open-tickets-list">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Pending/Open Tickets</p>
                      <h5 className="mb-0 text-warning">
                        {count && count.open_ticket_status}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-warning font-size-20">
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/tickets/wip-tickets-list">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">WIP Tickets</p>
                      <h5 className="mb-0 text-danger">
                        {count && count.WIP_tickets}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-danger font-size-20">
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/tickets/closed-tickets-list">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Closed Tickets</p>
                      <h5 className="mb-0 text-success">
                        {count && count.closed_ticket_status}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
      {/* ))} */}
    </React.Fragment>
  )
}

export default typeOfRequest
