import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_MAINTAINENCES, GET_MAINTAINENCE_DETAIL, ADD_NEW_MAINTAINENCE, DELETE_MAINTAINENCE, UPDATE_MAINTAINENCE } from "./actionTypes"
import {
  getMaintainencesSuccess,
  getMaintainencesFail,
  getMaintainenceDetailSuccess,
  getMaintainenceDetailFail,
  addMaintainenceFail,
  addMaintainenceSuccess,
  updateMaintainenceSuccess,
  updateMaintainenceFail,
  deleteMaintainenceSuccess,
  deleteMaintainenceFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getMaintainences, getMaintainenceDetails, addNewMaintainence, updateMaintainence, deleteMaintainence } from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

function* fetchMaintainences() {
  try {
    const response = yield call(getMaintainences)
    yield put(getMaintainencesSuccess(response))
  } catch (error) {
    yield put(getMaintainencesFail(error))
  } 
}


function* fetchMaintainenceDetail({ maintainenceId }) {
  try {
    const response = yield call(getMaintainenceDetails, maintainenceId)
    yield put(getMaintainenceDetailSuccess(response))
  } catch (error) {
    yield put(getMaintainenceDetailFail(error))
  }
}

function* onUpdateMaintainence({ payload: { id, maintainence } }) {
  try {
    const response = yield call(updateMaintainence,id, maintainence)
    // yield put(updateMaintainenceSuccess(response))
    // toast.success("Maintainence Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateMaintainenceFail(error))
    // toast.error("Maintainence Update Failded", { autoClose: 2000 })
  }
  const response2 = yield put(updateFacilitySuccess(response))
}

function* onDeleteMaintainence({ payload: maintainence }) {
  try {
    const response = yield call(deleteMaintainence, maintainence)
    yield put(deleteMaintainenceSuccess(response))
    // toast.success("Maintainence Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteMaintainenceFail(error))
    // toast.error("Maintainence Delete Failded", { autoClose: 2000 })
  }
}

function* onAddNewMaintainence({ payload: maintainence }) {
  try {
    const response = yield call(addNewMaintainence, maintainence)
    yield put(addMaintainenceSuccess(response))
    toast.success("Maintainence Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addMaintainenceFail(error))
    toast.error("Maintainence Add Failded", { autoClose: 2000 })
  }
}

function* maintainencesSaga() {
  yield takeEvery(GET_MAINTAINENCES, fetchMaintainences)
  yield takeEvery(GET_MAINTAINENCE_DETAIL, fetchMaintainenceDetail)
  yield takeEvery(ADD_NEW_MAINTAINENCE, onAddNewMaintainence)
  yield takeEvery(UPDATE_MAINTAINENCE, onUpdateMaintainence)
  yield takeEvery(DELETE_MAINTAINENCE, onDeleteMaintainence)
}

export default maintainencesSaga
