import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_BILL_HISTORY_FOR_VENDOR_PAYMENT,
  GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT,
  GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT,
  GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT,
  GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST,
} from "./actionTypes"
import {
  getCompanyFilterForVendorPaymentSuccess,
  getCompanyFilterForVendorPaymentFail,
  getProjectFilterForVendorPaymentSuccess,
  getProjectFilterForVendorPaymentFail,
  getVendorFilterForVendorPaymentSuccess,
  getVendorFilterForVendorPaymentFail,
  getBillHistoryForVendorPaymentSuccess,
  getBillHistoryForVendorPaymentFail,
  getPaymentHistoryForVendorPaymentSuccess,
  getPaymentHistoryForVendorPaymentFail,
  getProjectFilterForVendorPaymentListSuccess,
  getProjectFilterForVendorPaymentListFail,
  getVendorFilterForVendorPaymentListSuccess,
  getVendorFilterForVendorPaymentListFail,
} from "./actions"

import {
  getBillHistoryForVendorPayment,
  getCompanyFilterForVendorPayment,
  getPaymentHistoryForVendorPayment,
  getProjectFilterForVendorPayment,
  getVendorFilterForVendorPayment,
} from "helpers/fakebackend_helper"

function* fetchCompanyFilter({ userId }) {
  try {
    const response = yield call(getCompanyFilterForVendorPayment, userId)
    yield put(getCompanyFilterForVendorPaymentSuccess(response))
  } catch (error) {
    yield put(getCompanyFilterForVendorPaymentFail(error))
  }
}

function* fetchProjectFilter({ userId }) {
  try {
    const response = yield call(getProjectFilterForVendorPayment, userId)
    yield put(getProjectFilterForVendorPaymentSuccess(response))
  } catch (error) {
    yield put(getProjectFilterForVendorPaymentFail(error))
  }
}
function* fetchVendorFilter({ userId }) {
  try {
    const response = yield call(getVendorFilterForVendorPayment, userId)
    yield put(getVendorFilterForVendorPaymentSuccess(response))
  } catch (error) {
    yield put(getVendorFilterForVendorPaymentFail(error))
  }
}
function* fetchBillHistory({ userId }) {
  try {
    const response = yield call(getBillHistoryForVendorPayment, userId)
    yield put(getBillHistoryForVendorPaymentSuccess(response))
  } catch (error) {
    yield put(getBillHistoryForVendorPaymentFail(error))
  }
}
function* fetchPaymentHistory({ userId }) {
  try {
    const response = yield call(getPaymentHistoryForVendorPayment,userId)
    yield put(getPaymentHistoryForVendorPaymentSuccess(response))
  } catch (error) {
    yield put(getPaymentHistoryForVendorPaymentFail(error))
  }
}

function* fetchProjectFilterList({ userId }) {
  try {
    const response = yield call(getProjectFilterForVendorPaymentList, userId)
    yield put(getProjectFilterForVendorPaymentListSuccess(response))
  } catch (error) {
    yield put(getProjectFilterForVendorPaymentListFail(error))
  }
}
function* fetchVendorFilterList({ userId }) {
  try {
    const response = yield call(getVendorFilterForVendorPaymentList, userId)
    yield put(getVendorFilterForVendorPaymentListSuccess(response))
  } catch (error) {
    yield put(getVendorFilterForVendorPaymentListFail(error))
  }
}
function* VendorPaymentSaga() {
  yield takeEvery(GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT, fetchCompanyFilter)
  yield takeEvery(GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT, fetchProjectFilter)
  yield takeEvery(GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT, fetchVendorFilter)
  yield takeEvery(GET_BILL_HISTORY_FOR_VENDOR_PAYMENT, fetchBillHistory)
  yield takeEvery(GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT, fetchPaymentHistory)
  yield takeEvery(GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST, fetchProjectFilterList)
  yield takeEvery(GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST, fetchVendorFilterList)
}

export default VendorPaymentSaga
