import { data } from "autoprefixer"
import {
  GET_VENDOR_INVOICES,
  GET_VENDOR_INVOICES_DATA,
  GET_VENDOR_INVOICES_DATA_FAIL,
  GET_VENDOR_INVOICES_DATA_SUCCESS,
  GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT,
  GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_FAIL,
  GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_SUCCESS,
  GET_VENDOR_INVOICES_DATA_WITH_TDS,
  GET_VENDOR_INVOICES_DATA_WITH_TDS_FAIL,
  GET_VENDOR_INVOICES_DATA_WITH_TDS_SUCCESS,
  GET_VENDOR_INVOICES_FAIL,
  GET_VENDOR_INVOICES_PAYMENT_DATA,
  GET_VENDOR_INVOICES_PAYMENT_DATA_FAIL,
  GET_VENDOR_INVOICES_PAYMENT_DATA_SUCCESS,
  GET_VENDOR_INVOICES_SUCCESS,
  GET_VENDOR_LEDGER,
  GET_VENDOR_LEDGER_FAIL,
  GET_VENDOR_LEDGER_SUCCESS,
  GET_VENDOR_TICKET_DATA,
  GET_VENDOR_TICKET_DATA_FAIL,
  GET_VENDOR_TICKET_DATA_SUCCESS,
} from "./actionTypes"

export const getVendorInvoices = id => ({
  type: GET_VENDOR_INVOICES,
  id,
})

export const getVendorInvoicesSuccess = invoices => ({
  type: GET_VENDOR_INVOICES_SUCCESS,
  payload: invoices,
})

export const getVendorInvoicesFail = error => ({
  type: GET_VENDOR_INVOICES_FAIL,
  payload: error,
})

export const getVendorInvoicesData = id => ({
  type: GET_VENDOR_INVOICES_DATA,
  id,
})

export const getVendorInvoicesDataSuccess = invoices => ({
  type: GET_VENDOR_INVOICES_DATA_SUCCESS,
  payload: invoices,
})

export const getVendorInvoicesDataFail = error => ({
  type: GET_VENDOR_INVOICES_DATA_FAIL,
  payload: error,
})
export const getVendorInvoicesDataWithTds = id => ({
  type: GET_VENDOR_INVOICES_DATA_WITH_TDS,
  id,
})

export const getVendorInvoicesDataWithTdsSuccess = invoices => ({
  type: GET_VENDOR_INVOICES_DATA_WITH_TDS_SUCCESS,
  payload: invoices,
})

export const getVendorInvoicesDataWithTdsFail = error => ({
  type: GET_VENDOR_INVOICES_DATA_WITH_TDS_FAIL,
  payload: error,
})

//
export const getVendorInvoicesDataWithOutstandingAmount = id => ({
  type: GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT,
  id,
})

export const getVendorInvoicesDataWithOutstandingAmountSuccess = invoices => ({
  type: GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_SUCCESS,
  payload: invoices,
})

export const getVendorInvoicesDataWithOutstandingAmountFail = error => ({
  type: GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_FAIL,
  payload: error,
})
//vendor payment
export const getVendorInvoicesPaymentData = id => ({
  type: GET_VENDOR_INVOICES_PAYMENT_DATA,
  id,
})

export const getVendorInvoicesPaymentDataSuccess = invoices => ({
  type: GET_VENDOR_INVOICES_PAYMENT_DATA_SUCCESS,
  payload: invoices,
})

export const getVendorInvoicesPaymentDataFail = error => ({
  type: GET_VENDOR_INVOICES_PAYMENT_DATA_FAIL,
  payload: error,
})
//vendor payment
export const getVendorTicketData = id => ({
  type: GET_VENDOR_TICKET_DATA,
  id,
})

export const getVendorTicketDataSuccess = invoices => ({
  type: GET_VENDOR_TICKET_DATA_SUCCESS,
  payload: invoices,
})

export const getVendorTicketDataFail = error => ({
  type: GET_VENDOR_TICKET_DATA_FAIL,
  payload: error,
})

export const getVendorLedger = data => ({
  type: GET_VENDOR_LEDGER,
  data,
})

export const getVendorLedgerSuccess = invoices => ({
  type: GET_VENDOR_LEDGER_SUCCESS,
  payload: invoices,
})

export const getVendorLedgerFail = error => ({
  type: GET_VENDOR_LEDGER_FAIL,
  payload: error,
})
