import {
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_SUCCESS,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,

  //DOCUMENTATION
  GET_DOCUMENTATION_SUCCESS,
  GET_DOCUMENTATION_FAIL,
  ADD_DOCUMENTATION_SUCCESS,
  ADD_DOCUMENTATION_FAIL,
  UPDATE_DOCUMENTATION_SUCCESS,
  UPDATE_DOCUMENTATION_FAIL,
  DELETE_DOCUMENTATION_SUCCESS,
  DELETE_DOCUMENTATION_FAIL,

  // PAST EMPLOYMENT
  GET_PAST_EMPLOYMENT_SUCCESS,
  GET_PAST_EMPLOYMENT_FAIL,
  ADD_PAST_EMPLOYMENT_SUCCESS,
  ADD_PAST_EMPLOYMENT_FAIL,
  UPDATE_PAST_EMPLOYMENT_SUCCESS,
  UPDATE_PAST_EMPLOYMENT_FAIL,
  DELETE_PAST_EMPLOYMENT_SUCCESS,
  DELETE_PAST_EMPLOYMENT_FAIL,

  // ASSIGNED CUSTOMER
  GET_ASSIGNED_CUSTOMER_SUCCESS,
  GET_ASSIGNED_CUSTOMER_FAIL,
  GET_PAST_EMPLOYMENT_DETAIL_SUCCESS,
  GET_PAST_EMPLOYMENT_DETAIL_FAIL,
  REINVITE_SUCCESS,
  REINVITE_FAIL,

  //PERMISSION
  GET_USER_MODULES_SUCCESS,
  GET_USER_MODULES_FAIL,
  GET_USER_PERMISSION_SUCCESS,
  GET_USER_PERMISSION_FAIL,
  ADD_USER_PERMISSION_SUCCESS,
  ADD_USER_PERMISSION_FAIL,
  GET_EMPLOYEE_CODE_SUCCESS,
  GET_EMPLOYEE_CODE_FAIL,
  GET_PROJECT_NAME_FOR_USER_SUCCESS,
  GET_PROJECT_NAME_FOR_USER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  docs: [],
  userPermission: [],
  userModules: [],
  addUserPermission: [],
  pastEmp: [],
  customers: [],
  reinvite: [],
  emp_codes: [],
  projectNames:[],
  userDetail: {},
  error: {},
  loading: true,
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        // loading: true
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        loading: false,
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
        loading: false,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        users: state.users.users.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // DOCUMENTATIOn

    case GET_DOCUMENTATION_SUCCESS:
      return {
        ...state,
        docs: action.payload,
        loading: false,
        // loading: true
      }

    case GET_DOCUMENTATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DOCUMENTATION_SUCCESS:
      return {
        ...state,
        docs: [...state.docs, action.payload],
      }

    case ADD_DOCUMENTATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_DOCUMENTATION_SUCCESS:
      return {
        ...state,
        docs: state.docs.map(doc =>
          doc.id.toString() === action.payload.id.toString()
            ? { doc, ...action.payload }
            : doc
        ),
      }

    case UPDATE_DOCUMENTATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_DOCUMENTATION_SUCCESS:
      return {
        ...state,
        docs: state.docs.filter(
          doc => doc.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_DOCUMENTATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // PAST EMPLOYMEMT

    case GET_PAST_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        pastEmp: action.payload,
        loading: false,
        // loading: true
      }

    case GET_PAST_EMPLOYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PAST_EMPLOYMENT_DETAIL_SUCCESS:
      return {
        ...state,
        pastEmp: action.payload,
        loading: false,
        // loading: true
      }

    case GET_PAST_EMPLOYMENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PAST_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        pastEmp: [...pastEmp.users, action.payload],
      }

    case ADD_PAST_EMPLOYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PAST_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        pastEmp: state.pastEmp.map(emp =>
          emp.id.toString() === action.payload.id.toString()
            ? { emp, ...action.payload }
            : emp
        ),
      }

    case UPDATE_PAST_EMPLOYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PAST_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        pastEmp: state.pastEmp.filter(
          emp => emp.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_PAST_EMPLOYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // ASSIGNED CUSTOMER

    case GET_ASSIGNED_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        loading: false,
        // loading: true
      }

    case GET_ASSIGNED_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //REINVITE

    case REINVITE_SUCCESS:
      return {
        ...state,
        reinvite: action.payload,
        loading: false,
        // loading: true
      }

    case REINVITE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //PERMISSION

    case GET_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        userPermission: action.payload,
        loading: false,
        // loading: true
      }

    case GET_USER_PERMISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        addUserPermission: [...state.addUserPermission, action.payload],
      }

    case ADD_USER_PERMISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYEE_CODE_SUCCESS:
      return {
        ...state,
        emp_codes: action.payload,
        loading: false,
        // loading: true
      }

    case GET_EMPLOYEE_CODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_NAME_FOR_USER_SUCCESS:
      return {
        ...state,
        projectNames: action.payload,
        loading: false,
        // loading: true
      }

    case GET_PROJECT_NAME_FOR_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default users
