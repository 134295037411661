import {
  GET_MAINTAINENCES_FAIL,
  GET_MAINTAINENCES_SUCCESS,
  GET_MAINTAINENCE_DETAIL_FAIL,
  GET_MAINTAINENCE_DETAIL_SUCCESS,
  ADD_MAINTAINENCE_SUCCESS,
  ADD_MAINTAINENCE_FAIL,
  UPDATE_MAINTAINENCE_SUCCESS,
  UPDATE_MAINTAINENCE_FAIL,
  DELETE_MAINTAINENCE_SUCCESS,
  DELETE_MAINTAINENCE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  maintainences: [],
  maintainenceDetail: {},
  error: {},
  loading: true
};

const maintainences = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAINTAINENCES_SUCCESS:
      return {
        ...state,
        maintainences: action.payload,
        loading: true
        // loading: true
      };

    case GET_MAINTAINENCES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_MAINTAINENCE_SUCCESS:
      return {
        ...state,
        maintainences: [...state.maintainences, action.payload],
      };

    case ADD_MAINTAINENCE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_MAINTAINENCE_DETAIL_SUCCESS:
      return {
        ...state,
        maintainenceDetail: action.payload,
      };

    case UPDATE_MAINTAINENCE_SUCCESS:
      return {
        ...state,
        maintainences: state.maintainences.map(maintainence =>
          maintainence.id.toString() === action.payload.id.toString()
            ? { maintainence, ...action.payload }
            : maintainence
        ),
      };

    case UPDATE_MAINTAINENCE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_MAINTAINENCE_SUCCESS:
      return {
        ...state,
        maintainences: state.maintainences.maintenance_issues.filter(
          maintainence => maintainence.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_MAINTAINENCE_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    case GET_MAINTAINENCE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default maintainences;
