import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  getPastEmploymentDetail,
  getCountries,
  getStates,
  getCities,
} from "store/actions"
import {
  updatePastEmployment,
  addPastEmployment,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"

const addnewpastemployment = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [pastEmploymentId, setPastEmploymentId] = useState()
  const [pastEmploymentData, setPastEmploymentData] = useState()

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pastEmploymentState = state => state.users.pastEmp.data
  const pastEmployment = useSelector(pastEmploymentState) || []
  const pastEmploymentDataState = state => state.users.pastEmp.data
  const Empdata = useSelector(pastEmploymentDataState) || []

  const editData = () => {
    const url = new URL(window.location.href)
    const pastEmploymentId = url.pathname.split("/users/edit-new-pastemployment/")[1]
    setPastEmploymentId(pastEmploymentId)
    setIsEditMode(true)
    const response = dispatch(getPastEmploymentDetail(pastEmploymentId))
    setPastEmploymentData(response)
  }
  const newData = () => {
    const url = new URL(window.location.href)
    const pastEmploymentId = url.pathname.split("/add-new-pastemployment/")[1]
    setPastEmploymentId(pastEmploymentId)
  }

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      editData()
    } else {
      newData()
    }
  }, [])

  useEffect(() => {
    dispatch(getCountries())
  }, [dispatch])

  const [selectedOption, setSelectedOption] = useState(
    Boolean(Empdata?.is_active)
  )

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          employee_id: (Empdata && Empdata.employee_id) || "",
          name_of_company: (Empdata && Empdata.name_of_company) || "",
          start_date: (Empdata && Empdata.start_date) || "",
          end_date: (Empdata && Empdata.end_date) || "",
          designation: (Empdata && Empdata.designation) || "",
          contact_person_name: (Empdata && Empdata.contact_person_name) || "",
          contact_person_email: (Empdata && Empdata.contact_person_email) || "",
          contact_person_contact_no:
            (Empdata && Empdata.contact_person_contact_no) || "",
          country: (Empdata && Empdata.country) || "IN",
          city: (Empdata && Empdata.city) || "",
          state: (Empdata && Empdata.state) || "",
          pincode: (Empdata && Empdata.pincode) || "",
        }
      : {
          employee_id: "",
          name_of_company: "",
          start_date: "",
          end_date: "",
          designation: "",
          contact_person_name: "",
          contact_person_email: "",
          contact_person_contact_no: "",
          country: "IN",
          city: "",
          state: "",
          pincode: "",
          user_role: "",
        },

    validationSchema: Yup.object({
      employee_id: Yup.string().required("Please Enter Employee Id (Employee UID)"),
      name_of_company: Yup.string().required("Please Enter Company Name"),
      start_date: Yup.string()
      .required('Start date is required'),
    end_date: Yup.string()
      .required('End date is required')
      .test('is-after-start', 'End date must be after start date', function(value) {
        const { start_date } = this.parent;
        if (!start_date || !value) return true; // Allow null values
        return new Date(value) > new Date(start_date);
      }),
      designation: Yup.string().required("Please Enter Designation"),
      contact_person_name: Yup.string().required("Please Enter Contact Person Name"),
      contact_person_email: Yup.string().required("Please Enter Contact Person Email"),
      contact_person_contact_no: Yup.string()
      .matches(
        /^[0-9]{10,12}$/,
        "Mobile Number should be between 10 and 12 digits and only contain numbers"
      )
      .required("Please Enter Contact Person's Contact Number"),
      country: Yup.string().required("Please Select Country"),
      city: Yup.number().required("Please Select City"),
      state: Yup.number().required("Please Select State"),
      pincode: Yup.string()
      .matches(/^[0-9]{6}$/, 'Pincode must be 6 digits and only contain numbers')
      .required('Please enter Pincode'),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        if (isEditMode === true) {
          const response = await updatePastEmployment(pastEmploymentId, values)
          
          if (response && response.status === true) {
            loadingSwal.close()
            
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Past Employment Detail has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate(-2)
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has not been saved.",
            })
          }
        } else if (isEditMode === false) {
          // const response = await dispatch(addNewVendor(values));

          const response = await addPastEmployment(pastEmploymentId, values)
          
          if (response && response.status === true) {
            loadingSwal.close()
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Past Employment Detail has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate(-2)
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Past Employment Detail has not been saved.",
            })
          }

        }
      } catch (error) {
        // Handle the error if needed
        console.error("Error:", error)
        loadingSwal.close()

        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const [countryName, setCountryName] = useState(validation.values.country);
  const [stateId, setStateId] = useState(validation.values.state);
  
  useEffect(() => {
    const fetchData = async () => {
      // Fetch countries
      await dispatch(getCountries());
  
      // If country is selected (edit mode), fetch states and cities
      if (validation.values.country) {
        await dispatch(getStates(validation.values.country));
        await dispatch(getCities(validation.values.state));
      }
  
      // Set initial values
      setCountryName(validation.values.country);
      setStateId(validation.values.state);
    };
  
    fetchData();
  }, [dispatch, validation.values.country, validation.values.state]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <CardTitle className="h4" style={{ fontSize: "20px" }}>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              {!isEditMode ? "Add New Past Employment" : "Edit Past Employment"}
            </div>
          </CardTitle>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="employee_id">
                            Employee Id (Employee UID)*
                          </Label>
                          <Input
                            type="text"
                            name="employee_id"
                            className="form-control"
                            id="employee_id"
                            placeholder="Enter Employee Id (Employee UID)"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.employee_id || ""}
                            invalid={
                              validation.touched.employee_id &&
                              validation.errors.employee_id
                                ? true
                                : false
                            }
                          />
                          {validation.touched.employee_id &&
                          validation.errors.employee_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.employee_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="name_of_company">
                            Name of Company*
                          </Label>
                          <Input
                            type="text"
                            name="name_of_company"
                            className="form-control"
                            id="name_of_company"
                            placeholder="Enter Name of Company"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name_of_company || ""}
                            invalid={
                              validation.touched.name_of_company &&
                              validation.errors.name_of_company
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name_of_company &&
                          validation.errors.name_of_company ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name_of_company}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="start_date">Start Date*</Label>
                          <Input
                            name="start_date"
                            type="date"
                            className={`form-control ${
                              validation.touched.start_date &&
                              validation.errors.start_date
                                ? "is-invalid"
                                : ""
                            }`}
                            id="start_date"
                            placeholder="Enter Start Date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.start_date || ""}
                          />
                          {validation.touched.start_date &&
                          validation.errors.start_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.start_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="end_date">End Date*</Label>
                          <Input
                            name="end_date"
                            type="date"
                            className={`form-control ${
                              validation.touched.end_date &&
                              validation.errors.end_date
                                ? "is-invalid"
                                : ""
                            }`}
                            id="end_date"
                            placeholder="Enter End Date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            disabled={!validation.values.start_date}
                            value={validation.values.end_date || ""}
                          />
                          {validation.touched.end_date &&
                          validation.errors.end_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.end_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="designation">Designation*</Label>
                          <Input
                            name="designation"
                            type="text"
                            className={`form-control ${
                              validation.touched.designation &&
                              validation.errors.designation
                                ? "is-invalid"
                                : ""
                            }`}
                            id="designation"
                            placeholder="Enter Designation"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.designation || ""}
                          />
                          {validation.touched.designation &&
                          validation.errors.designation ? (
                            <FormFeedback type="invalid">
                              {validation.errors.designation}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="contact_person_name">
                            Contact Person Name*
                          </Label>
                          <Input
                            name="contact_person_name"
                            type="text"
                            className={`form-control ${
                              validation.touched.contact_person_name &&
                              validation.errors.contact_person_name
                                ? "is-invalid"
                                : ""
                            }`}
                            id="contact_person_name"
                            placeholder="Enter Contact Person's Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contact_person_name || ""}
                          />
                          {validation.touched.contact_person_name &&
                          validation.errors.contact_person_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contact_person_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="contact_person_email">
                            Contact Person Email*
                          </Label>
                          <Input
                            name="contact_person_email"
                            type="text"
                            className={`form-control ${
                              validation.touched.contact_person_email &&
                              validation.errors.contact_person_email
                                ? "is-invalid"
                                : ""
                            }`}
                            id="contact_person_email"
                            placeholder="Enter Contact's Person Email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contact_person_email || ""}
                          />
                          {validation.touched.contact_person_email &&
                          validation.errors.contact_person_email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contact_person_email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="contact_person_contact_no">
                            Contact Person Contact Number*
                          </Label>
                          <Input
                            name="contact_person_contact_no"
                            type="text"
                            className={`form-control ${
                              validation.touched.contact_person_contact_no &&
                              validation.errors.contact_person_contact_no
                                ? "is-invalid"
                                : ""
                            }`}
                            id="contact_person_contact_no"
                            placeholder="Enter Contact Person's Contact Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.contact_person_contact_no || ""
                            }
                          />
                          {validation.touched.contact_person_contact_no &&
                          validation.errors.contact_person_contact_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contact_person_contact_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="country">Country*</Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            defaultValue="IN"
                            value={validation.values.country}
                            name="country"
                            className={`form-select ${
                              validation.touched.country &&
                              validation.errors.country
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.country && (
                              <option>Enter Your Country</option>
                            )}

                            {(countries || []).map(item => (
                              <option key={item.id} value={item.id}>
                                {item.country_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-state-Input">State*</Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="state"
                            value={validation.values.state}

                            className={`form-select ${
                              validation.touched.state &&
                              validation.errors.state
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.state && (
                              <option>Enter Your States</option>
                            )}

                            {(states || []).map(item => (
                              <option key={item.id} value={item.id}>
                                {item.state_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.state &&
                          validation.errors.state ? (
                            <FormFeedback type="invalid">
                              {validation.errors.state}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-city-Input">City*</Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="city"
                            value={validation.values.city}
                            className={`form-select ${
                              validation.touched.city && validation.errors.city
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.city && (
                              <option>Enter Your City</option>
                            )}

                            {(cities || []).map(item => (
                              <option key={item.id} value={item.id}>
                                {item.city_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.city && validation.errors.city ? (
                            <FormFeedback type="invalid">
                              {validation.errors.city}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="pincode">Pincode*</Label>
                          <Input
                            name="pincode"
                            type="text"
                            className={`form-control ${
                              validation.touched.pincode &&
                              validation.errors.pincode
                                ? "is-invalid"
                                : ""
                            }`}
                            id="pincode"
                            placeholder="Enter Pin Code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pincode || ""}
                          />
                          {validation.touched.pincode &&
                          validation.errors.pincode ? (
                            <FormFeedback type="invalid">
                              {validation.errors.pincode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3 text-end">
                          <button
                            type="submit"
                            className="btn btn-primary l-md"
                          >
                            Submit
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default addnewpastemployment
