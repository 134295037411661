import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation,useNavigate } from "react-router-dom"

import { Col, Container, Row, CardTitle, Label } from "reactstrap"
import { getCompanyLocationDetail } from "store/actions"

const CompanyDetail = props => {
  const [companyId, setCompanyId] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const companyDetail = state => state.companies.locationDetail.data
  const companyProfile = useSelector(companyDetail)

  const companyData = () => {
    try {
      const url = new URL(window.location.href)

      const companyId = url.pathname.split("/view-company-location/")[1]
      setCompanyId(companyId)
      dispatch(getCompanyLocationDetail(companyId))

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    companyData()
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <React.Fragment>
          <i
            className="mdi mdi-arrow-left font-size-14"
            style={{ color: "grey", marginRight: "5px" }}
            onClick={() => navigate(-1)}
          ></i>
          <span style={{ color: "black", fontWeight: "500", fontSize: "14px" }}>
            Company Location Details
          </span>
        </React.Fragment>
        <Container fluid={true}>
          {companyProfile && (
            <Row>
              <Col>
                {/* <CardTitle className="mb-3">Company Location Details</CardTitle> */}
                <Row className="mb-3 mt-3">
                  <Col lg={4}>
                    <Label className="form-label">Address</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.location_title}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Contact No.</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.contact_phone}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label"> Email Address </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {" "}
                      {companyProfile.account_email}{" "}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Label className="form-label">Country</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.country_name}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">State</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.state_name}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">City</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.city_name}
                    </p>
                  </Col>
                </Row>
              </Col>
              <hr />
              <Col>
                <CardTitle className="mb-3">
                  Company Financial Details
                </CardTitle>

                <Row className="mb-3">
                  <Col lg={4}>
                    <Label className="form-label">GST No.</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {" "}
                      {companyProfile.gst_no}
                    </p>
                  </Col>

                  <Col lg={4}>
                    <Label className="form-label">Bank Name</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.bank_name}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Bank Branch</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.bank_branch}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={4}>
                    <Label className="form-label">Bank Account No.</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.bank_account_no}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Bank IFSC</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.bank_ifsc}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-3"></Row>
              </Col>
              <hr />
              <Col>
                <CardTitle className="mb-3">Company Prefixes</CardTitle>

                <Row className="mb-3">
                  <Col lg={4}>
                    <Label className="form-label">Bill Prefix</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {" "}
                      {companyProfile.bill_prefix}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Bill Starting No.</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {" "}
                      {companyProfile.bill_starting_no}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Expense Voucher Prefix</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {" "}
                      {companyProfile.expense_voucher_prefix}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Label className="form-label">
                      Expense Voucher Starting no.
                    </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {" "}
                      {companyProfile.expense_voucher_starting_no}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Payment Voucher Prefix</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {" "}
                      {companyProfile.payment_voucher_prefix}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">
                      Payment Voucher Starting no.
                    </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {" "}
                      {companyProfile.payment_voucher_starting_no}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Label className="form-label">Receipt Prefix</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {" "}
                      {companyProfile.receipt_prefix}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Receipt Starting no.</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {" "}
                      {companyProfile.receipt_starting_no}
                    </p>
                  </Col>
                </Row>
              </Col>
              <hr />
              <Col>
                <CardTitle className="mb-3">E-Invoice Login Details</CardTitle>
                <Row className="mb-3">
                  <Col lg={4}>
                    <Label className="form-label">API Username</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.einvoice_api_username
                        ? companyProfile.einvoice_api_username
                        : "NA"}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">API Password</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.einvoice_api_password
                        ? companyProfile.einvoice_api_password
                        : "NA"}
                    </p>
                  </Col>
                </Row>
              </Col>
              <hr />
              <Col>
                <CardTitle className="mb-3">GST Filing Details</CardTitle>
                <Row className="mb-3">
                  <Col lg={4}>
                    <Label className="form-label">R1 Frequency</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.gst_filing_r1_frequency}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">3B Payment</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.gst_filing_3b_payment_frequency}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">3B Frequency</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {companyProfile.gst_filing_3b_frequency}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompanyDetail
