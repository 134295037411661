import React, { useState, useEffect } from "react"
import { Col, CardBody, Row, FormFeedback, Badge, Card } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import getChartColorsArray from "components/Common/ChartsDynamicColor"
import ReactApexChart from "react-apexcharts"
import { billAndPaymentGraph } from "store/actions"
import { useDispatch, useSelector } from "react-redux"

const LineGraph = () => {
  const dispatch = useDispatch()
  const [chartColors, setChartColors] = useState([])
  const dataColors =
    '["--bs-primary", "--bs-success", "--bs-warning", "--bs-danger"]'

  const categoryState = state => state.Dashboard?.billPaymentGraph?.category
  const category = useSelector(categoryState) || []

  const billAmountState = state => state.Dashboard?.billPaymentGraph?.data1
  const billAmount = useSelector(billAmountState) || []

  const billCountState = state => state.Dashboard?.billPaymentGraph?.dataCounts1
  const billCount = useSelector(billCountState) || []

  const paymentAmountState = state => state.Dashboard?.billPaymentGraph?.data2
  const paymentAmount = useSelector(paymentAmountState) || []

  const paymentCountState = state =>
    state.Dashboard?.billPaymentGraph?.dataCounts2
  const paymentCount = useSelector(paymentCountState) || []

  useEffect(() => {
    const colors = getChartColorsArray(dataColors)
    setChartColors(colors)
  }, [dataColors])

  const validation = useFormik({
    initialValues: {
      filter: "This Year",
    },
    validationSchema: Yup.object({}),
  })

  const getApexChartOptions = colors => {
    return {
      chart: {
        height: 380,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3, 3, 3],
        curve: "straight",
      },

      title: {
        text: "‎",
        align: "left",
        style: {
          fontWeight: "500",
        },
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.2,
        },
        borderColor: "#f1f1f1",
      },
      markers: {
        style: "inverted",
        size: 6,
      },
      xaxis: {
        categories: category && category,
        title: {
          text: validation.values.filter,
        },
      },
      yaxis: {
        title: {
          text: "Amount",
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              toolbar: {
                show: false,
              },
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    }
  }
  useEffect(() => {
    dispatch(billAndPaymentGraph({ filter: validation.values.filter }))
  }, [validation.values.filter])

  const getApexChartSeries = () => {
    return [
      { name: "Bill Amount", data: billAmount && billAmount },
      { name: "Payment Amount", data: paymentAmount && paymentAmount },
      // { name: "Bill Count", data: billCount && billCount },
      // { name: "Payment Count", data: paymentCount && paymentCount },
    ]
  }

  return (
    <Col lg={12}>
      <Card>
        <CardBody>
          <Row>
            <Col className="d-flex align-items-center">
              <h4 className="card-title mb-4">Bill / Payment Amount</h4>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <select
                  name="filter"
                  className={`form-select ${
                    validation.touched.filter && validation.errors.filter
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.filter}
                >
                  <option value="This Week">This Week</option>
                  <option value="This Month">This Month</option>
                  <option value="This Year">This Year</option>
                  <option value="Previous Week">Previous Week</option>
                  <option value="Previous Month">Previous Month</option>
                  <option value="Previous Year">Previous Year</option>
                </select>
                {validation.touched.filter && validation.errors.filter ? (
                  <FormFeedback type="invalid">
                    {validation.errors.filter}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <ReactApexChart
            options={getApexChartOptions(chartColors)}
            series={getApexChartSeries()}
            type="line"
            height="380"
            className="apex-charts"
          />
        </CardBody>
      </Card>
    </Col>
  )
}

export default LineGraph
