import {
  GET_FACILITIES,
  GET_FACILITIES_FAIL,
  GET_FACILITIES_SUCCESS,
  GET_FACILITY_DETAIL,
  ADD_NEW_FACILITY,
  ADD_FACILITY_SUCCESS,
  ADD_FACILITY_FAIL,
  UPDATE_FACILITY,
  UPDATE_FACILITY_SUCCESS,
  UPDATE_FACILITY_FAIL,
  DELETE_FACILITY,
  DELETE_FACILITY_SUCCESS,
  DELETE_FACILITY_FAIL,
  GET_FACILITY_DETAIL_FAIL,
  GET_FACILITY_DETAIL_SUCCESS,
} from "./actionTypes"

export const getFacilities = () => ({
  type: GET_FACILITIES,
})

export const getFacilitiesSuccess = facilities => ({
  type: GET_FACILITIES_SUCCESS,
  payload: facilities,
})

export const addNewFacility = (facility, history) => ({
  type: ADD_NEW_FACILITY,
  payload: { facility, history },
})

export const addFacilitySuccess = facility => ({
  type: ADD_FACILITY_SUCCESS,
  payload: facility,
})

export const addFacilityFail = error => ({
  type: ADD_FACILITY_FAIL,
  payload: error,
})

export const updateFacility = (id, facility, history) => ({
  type: UPDATE_FACILITY,
  payload: { id, facility, history },
})

export const updateFacilitySuccess = facility => ({
  type: UPDATE_FACILITY_SUCCESS,
  payload: facility,
})

export const updateFacilityFail = error => ({
  type: UPDATE_FACILITY_FAIL,
  payload: error,
})

export const deleteFacility = facility => ({
  type: DELETE_FACILITY,
  payload: facility,
})

export const deleteFacilitySuccess = facility => ({
  type: DELETE_FACILITY_SUCCESS,
  payload: facility,
})

export const deleteFacilityFail = error => ({
  type: DELETE_FACILITY_FAIL,
  payload: error,
})

export const getFacilitiesFail = error => ({
  type: GET_FACILITIES_FAIL,
  payload: error,
})

export const getFacilityDetail = facilityId => ({
  type: GET_FACILITY_DETAIL,
  facilityId,
})

export const getFacilityDetailSuccess = facilityDetails => ({
  type: GET_FACILITY_DETAIL_SUCCESS,
  payload: facilityDetails,
})

export const getFacilityDetailFail = error => ({
  type: GET_FACILITY_DETAIL_FAIL,
  payload: error,
})
