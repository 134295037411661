import { call, put, takeEvery } from "redux-saga/effects"

import {
  
  GET_CITY_FOR_CUSTOMER_INDEX,
  GET_CUSTOMER_INDEX,
  GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX,
  GET_STATE_FOR_CUSTOMER_INDEX,
} from "./actionTypes"
import {
 
  getStateForCustomerIndexSuccess,
  getStateForCustomerIndexFail,
  getProjectFilterForCustomerIndexFail,
  getProjectFilterForCustomerIndexSuccess,
  getCustomerIndexFail,
  getCustomerIndexSuccess,
  getCityForCustomerIndexSuccess,
  getCityForCustomerIndexFail,
} from "./actions"

import { getCityForCustomerIndex, getCustomerIndex, getProjectFilterForCustomerIndex, getStateForCustomerIndex } from "helpers/fakebackend_helper"


function* fetchCustomerIndex({ userId }) {
  try {
    const response = yield call(getCustomerIndex, userId)
    yield put(getCustomerIndexSuccess(response))
  } catch (error) {
    yield put(getCustomerIndexFail(error))
  }
}

function* fetchProjectFilterForCustomerIndex({ userId }) {
  try {
    const response = yield call(getProjectFilterForCustomerIndex, userId)
    yield put(getProjectFilterForCustomerIndexSuccess(response))
  } catch (error) {
    yield put(getProjectFilterForCustomerIndexFail(error))
  }
}

function* fetchStateForCustomerIndex({ data }) {
  try {
    const response = yield call(getStateForCustomerIndex, data)
    yield put(getStateForCustomerIndexSuccess(response))
  } catch (error) {
    yield put(getStateForCustomerIndexFail(error))
  }
}
function* fetchCityForCustomerIndex({ data }) {
  try {
    const response = yield call(getCityForCustomerIndex, data)
    yield put(getCityForCustomerIndexSuccess(response))
  } catch (error) {
    yield put(getCityForCustomerIndexFail(error))
  }
}

function* CustomerIndexSaga() {

  yield takeEvery(GET_CUSTOMER_INDEX, fetchCustomerIndex)
  yield takeEvery(GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX, fetchProjectFilterForCustomerIndex)
  yield takeEvery(GET_STATE_FOR_CUSTOMER_INDEX, fetchStateForCustomerIndex)
  yield takeEvery(GET_CITY_FOR_CUSTOMER_INDEX, fetchCityForCustomerIndex)
}

export default CustomerIndexSaga
