// TIME REGION
export const ADD_NEW_TIMEREGION = "ADD_NEW_TIMEREGION"
export const ADD_TIMEREGION_SUCCESS = "ADD_TIMEREGION_SUCCESS"
export const ADD_TIMEREGION_FAIL = "ADD_TIMEREGION_FAIL"

export const GET_TIMEREGION = "GET_TIMEREGION"
export const GET_TIMEREGION_SUCCESS = "GET_TIMEREGION_SUCCESS"
export const GET_TIMEREGION_FAIL = "GET_TIMEREGION_FAIL"


// FILE CONFIGURATION
export const ADD_NEW_FILECONFIGURATION = "ADD_NEW_FILECONFIGURATION"
export const ADD_FILECONFIGURATION_SUCCESS = "ADD_FILECONFIGURATION_SUCCESS"
export const ADD_FILECONFIGURATION_FAIL = "ADD_FILECONFIGURATION_FAIL"

export const GET_FILECONFIGURATION = "GET_FILECONFIGURATION"
export const GET_FILECONFIGURATION_SUCCESS = "GET_FILECONFIGURATION_SUCCESS"
export const GET_FILECONFIGURATION_FAIL = "GET_FILECONFIGURATION_FAIL"

// RECAPTCHA CONFIGURATION
export const ADD_NEW_RECAPTCHACONFIGURATION = "ADD_NEW_RECAPTCHACONFIGURATION"
export const ADD_RECAPTCHACONFIGURATION_SUCCESS = "ADD_RECAPTCHACONFIGURATION_SUCCESS"
export const ADD_RECAPTCHACONFIGURATION_FAIL = "ADD_RECAPTCHACONFIGURATION_FAIL"

export const GET_RECAPTCHACONFIGURATION = "GET_RECAPTCHACONFIGURATION"
export const GET_RECAPTCHACONFIGURATION_SUCCESS = "GET_RECAPTCHACONFIGURATION_SUCCESS"
export const GET_RECAPTCHACONFIGURATION_FAIL = "GET_RECAPTCHACONFIGURATION_FAIL"

// EMAIL CONFIGURATION
export const ADD_NEW_EMAILCONFIGURATION = "ADD_NEW_EMAILCONFIGURATION"
export const ADD_EMAILCONFIGURATION_SUCCESS = "ADD_EMAILCONFIGURATION_SUCCESS"
export const ADD_EMAILCONFIGURATION_FAIL = "ADD_EMAILCONFIGURATION_FAIL"

export const GET_EMAILCONFIGURATION = "GET_EMAILCONFIGURATION"
export const GET_EMAILCONFIGURATION_SUCCESS = "GET_EMAILCONFIGURATION_SUCCESS"
export const GET_EMAILCONFIGURATION_FAIL = "GET_EMAILCONFIGURATION_FAIL"

// USER PASSWORD SETTINGS
export const ADD_NEW_USERPASSWORDSETTINGS = "ADD_NEW_USERPASSWORDSETTINGS"
export const ADD_USERPASSWORDSETTINGS_SUCCESS = "ADD_USERPASSWORDSETTINGS_SUCCESS"
export const ADD_USERPASSWORDSETTINGS_FAIL = "ADD_USERPASSWORDSETTINGS_FAIL"

export const GET_USERPASSWORDSETTINGS = "GET_USERPASSWORDSETTINGS"
export const GET_USERPASSWORDSETTINGS_SUCCESS = "GET_USERPASSWORDSETTINGS_SUCCESS"
export const GET_USERPASSWORDSETTINGS_FAIL = "GET_USERPASSWORDSETTINGS_FAIL"

// DATE TIME FORMAT 

export const GET_DATETIMEFORMAT = "GET_DATETIMEFORMAT"
export const GET_DATETIMEFORMAT_SUCCESS = "GET_DATETIMEFORMAT_SUCCESS"
export const GET_DATETIMEFORMAT_FAIL = "GET_DATETIMEFORMAT_FAIL"

//TIMEZONE SELECTION

export const GET_TIMEZONE = "GET_TIMEZONE"
export const GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS"
export const GET_TIMEZONE_FAIL = "GET_TIMEZONE_FAIL"

//CURRENCY SELECTION

export const GET_CURRENCY = "GET_CURRENCY"
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS"
export const GET_CURRENCY_FAIL = "GET_CURRENCY_FAIL"