import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  UPDATE_PASS,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  updatePassSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  login,
  updatePass,
  logout,
  
} from "../../../helpers/fakebackend_helper"
import Swal from "sweetalert2"

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(login, {
        login_username: user.login_username,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      localStorage.setItem("permission", JSON.stringify(response.user_permissions))

      if (response.status === true) {
        if (response.is_password_change === 1) {
          yield put(loginSuccess(response))
          history("/dashboard")
        } else {
          history("/update-password")
        }
      }
    }
  } catch (error) {
    yield put(apiError(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Your work has been saved.",
    })
  }
}

function* onUpdatePassword({ payload: { user, history } }) {
  try {
    const response = yield call(updatePass, user, history)
    yield put(updatePassSuccess(response))
    history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(logout, history)
    if (response.status === true) {
      localStorage.removeItem("authUser")
      localStorage.removeItem("permission")
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      if (response) {
        history("/dashboard")
      } else {
        history("/login")
      }
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    if (response) history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(UPDATE_PASS, onUpdatePassword)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga

// if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//   const response = yield call(
//     fireBaseBackend.loginUser,
//     user.email,
//     user.password
//   );
//   yield put(loginSuccess(response));
// } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//   const response = yield call(postJwtLogin, {
//     email: user.email,
//     password: user.password,
//   });
//   localStorage.setItem("authUser", JSON.stringify(response));
//   yield put(loginSuccess(response));
// } else
