import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  getPastEmploymentDetail,
  getCountries,
  getStates,
  getCities,
} from "store/actions"
import {
  updatePastEmployment,
  addPastEmployment,
  addNewVendorsContacts,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"

const AddNewVendorContact = () => {
  const [vendorContactId, setVendorContactId] = useState()
  const [pastEmploymentData, setPastEmploymentData] = useState()

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pastEmploymentState = state => state.users.pastEmp.data
  const pastEmployment = useSelector(pastEmploymentState) || []
  const pastEmploymentDataState = state => state.users.pastEmp.data
  const Empdata = useSelector(pastEmploymentDataState) || []

  const editData = () => {
    const url = new URL(window.location.href)
    const vendorContactId = url.pathname.split("/add-new-vendor-contact/")[1]
    setVendorContactId(vendorContactId)
    setIsEditMode(true)
    const response = dispatch(getPastEmploymentDetail(vendorContactId))
    setPastEmploymentData(response)
  }
  const newData = () => {
    const url = new URL(window.location.href)
    const vendorContactId = url.pathname.split("/add-new-vendor-contact/")[1]
    setVendorContactId(vendorContactId)
  }

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      editData()
    } else {
      newData()
    }
  }, [])

  useEffect(() => {
    dispatch(getCountries())
  }, [dispatch])

  const [selectedOption, setSelectedOption] = useState(
    Boolean(Empdata?.is_active)
  )

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      phone_no: "",
      vendor_email_address: "",
      role: "",
      vendor_address: "",
      pincodes: '',
      countries: "IN",
      is_actives: 1,
    },

    validationSchema: Yup.object({
      
        name: Yup.string().required("Please Enter Employee Id (Employee UID)"),
        phone_no: Yup.string().required("Please Enter Contact Person Email"),
        vendor_email_address: Yup.string().required("Please Enter Vendor Email Address"),
        vendor_address: Yup.string().required("Please Enter Vendor Address"),
        pincodes: Yup.string()
          .matches(/^[0-9]{6}$/, "Pincode must be 6 digits and only contain numbers")
          .required("Please enter Pincode"),
        countries: Yup.string().required("Please Select Country"),
        // city: Yup.number().required("Please Select City"), // Commented out as there is no "city" field in the initialValues
        // state: Yup.number().required("Please Select State"), // Commented out as there is no "state" field in the initialValues
      }),
      

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
       
          const response = await addNewVendorsContacts(vendorContactId, values)
          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Vendor Contact has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate(-2)
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Vendor Contact has not been saved.",
            })
          }
      
       
      } catch (error) {
        console.error("Error:", error)
        loadingSwal.close()

        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your Vendor Contact.",
        })
      }
    },
  })

  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState(validation.values.state)

  useEffect(() => {
    const fetchData = async () => {
      // Fetch countries
      await dispatch(getCountries())

      // If country is selected (edit mode), fetch states and cities
      if (validation.values.country) {
        await dispatch(getStates(validation.values.country))
        await dispatch(getCities(validation.values.state))
      }

      // Set initial values
      setCountryName(validation.values.country)
      setStateId(validation.values.state)
    }

    fetchData()
  }, [dispatch, validation.values.country, validation.values.state])

  const handleChange = event => {
    setSelectedOption(event.target.value)

    validation.setFieldValue("is_active", event.target.value)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <CardTitle className="h4" style={{ fontSize: "20px" }}>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              Add Vendor Contact
            </div>
          </CardTitle>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Card>
                      <CardBody>
                        <CardTitle>Vendor </CardTitle>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label for="name">Name*</Label>
                              <Input
                                type="text"
                                name="name"
                                className="form-control"
                                id="name"
                                placeholder="Enter Vendor Employee Name"
                                onChange={validation.handleChange}
                                value={validation.values.name || ""}
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label for="phone_no">Phone No.*</Label>
                              <Input
                                type="text"
                                name="phone_no"
                                className="form-control"
                                id="phone_no"
                                placeholder="Enter Vendor Employee Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone_no || ""}
                                invalid={
                                  validation.touched.phone_no &&
                                  validation.errors.phone_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.phone_no &&
                              validation.errors.phone_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.phone_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label for="vendor_email_address">Email Address*</Label>
                              <Input
                                type="email"
                                name="vendor_email_address"
                                className="form-control"
                                id="vendor_email_address"
                                placeholder="Enter Vendor Email Address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.vendor_email_address || ""}
                                invalid={
                                  validation.touched.vendor_email_address &&
                                  validation.errors.vendor_email_address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.vendor_email_address &&
                              validation.errors.vendor_email_address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.vendor_email_address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label for="role">Role*</Label>
                              <Input
                                type="text"
                                name="role"
                                className="form-control"
                                id="role"
                                placeholder="Enter Role of Vendor"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.role || ""}
                                invalid={
                                  validation.touched.role &&
                                  validation.errors.role
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.vendor_name &&
                              validation.errors.vendor_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.vendor_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="address">Address*</Label>
                              <Input
                                type="text"
                                name="vendor_address"
                                className="form-control"
                                id="vendor_address"
                                placeholder="Enter Address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.vendor_address || ""}
                                invalid={
                                  validation.touched.vendor_address &&
                                  validation.errors.vendor_address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.vendor_address &&
                              validation.errors.vendor_address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.vendor_address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="pincodes">Pincode*</Label>
                              <Input
                                type="text"
                                name="pincodes"
                                className="form-control"
                                id="pincodes"
                                placeholder="Enter Pincode"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pincodes || ""}
                                invalid={
                                  validation.touched.pincodes &&
                                  validation.errors.pincodes
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.pincodes &&
                              validation.errors.pincodes ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pincodes}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label for="countries">Country*</Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                // defaultValue="IN"
                                value={validation.values.countries}
                                name="countries"
                                className={`form-select ${
                                  validation.touched.countries &&
                                  validation.errors.countries
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.countries && (
                                  <option>Enter Your Country</option>
                                )}

                                {(countries || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.country_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col>
                            <Label htmlFor="is_active">Is Active*</Label>
                            <Col>
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  id="isActiveYes"
                                  name="is_actives"
                                  value={1}
                                  onClick={e => {
                                    handleChange(e)
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="isActiveYes"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <h6>Yes</h6>
                                </Label>
                              </div>
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  id="isActiveNo"
                                  name="is_actives"
                                  value={0}
                                  onClick={e => {
                                    handleChange(e)
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="isActiveNo"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <h6>No</h6>
                                </Label>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <button
                      className="btn btn-primary "
                      type="submit"
                      onClick={validation.handleSubmit}
                    >
                      Add New Contact
                    </button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AddNewVendorContact
