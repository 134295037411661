import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_VENDOR,
  GET_VENDORS_LIST,
  ADD_NEW_VENDORS,
  UPDATE_VENDORS,
  DELETE_VENDORS,
  ADD_NEW_VENDORS_DOCUMENTS,
  ADD_NEW_VENDORS_CONTACTS,
  DELETE_VENDOR_CONTACT,
  DELETE_VENDOR_DOCUMENT,
  GET_VENDOR_SERVICE_MASTER_LIST,
  GET_VENDOR_CONTACT,
  GET_VENDOR_CODE,
  ADD_VENDOR_PAYMENT,
  GET_VENDOR_LIST_IN_PAYMENT,
  GET_VENDOR_PAYMENT_DETAIL,
  GET_VENDOR_PAYMENT_DATA,
} from "./actionTypes"
import {
  getVendorSuccess,
  getVendorFail,
  getVendorsListSuccess,
  getVendorsListFail,
  addNewVendorsSuccess,
  addNewVendorsFail,
  updateVendorsSuccess,
  updateVendorsFail,
  deleteVendorsSuccess,
  deleteVendorsFail,
  addNewVendorsDocumentsSuccess,
  addNewVendorsDocumentsFail,
  addNewVendorsContactsSuccess,
  addNewVendorsContactsFail,
  deleteVendorsContactSuccess,
  deleteVendorsContactFail,
  deleteVendorsDocumentSuccess,
  deleteVendorsDocumentFail,
  getVendorServiceMasterListSuccess,
  getVendorServiceMasterListFail,
  getVendorContactSuccess,
  getVendorContactFail,
  getVendorCodeSuccess,
  getVendorCodeFail,
  addVendorPaymentFail,
  addVendorPaymentSuccess,
  
  getVendorListInPaymentSuccess,
  getVendorListInPaymentFail,
  getVendorPaymentDetailSuccess,
  getVendorPaymentDetailFail,
  getVendorPaymentDataSuccess,
  getVendorPaymentDataFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getVendor,
  getVendorsList,
  addNewVendors,
  updateVendors,
  deleteVendors,
  addNewVendorsDocuments,
  addNewVendorsContacts,
  deleteVendorsDocument,
  deleteVendorsContact,
  getVendorServiceMasterList,
  getVendorContact,
  getVendorCode,
  addVendorPayment,
  getVendorListInPayment,
  getVendorPaymentDetail,
  getVendorPaymentData
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

function* fetchVendor({ vendorId }) {
  try {
    const response = yield call(getVendor, vendorId)
    yield put(getVendorSuccess(response))
  } catch (error) {
    yield put(getVendorFail(error))
  }
}
function* fetchVendorPaymentDetail({ vendorId }) {
  try {
    const response = yield call(getVendorPaymentDetail, vendorId)
    yield put(getVendorPaymentDetailSuccess(response))
  } catch (error) {
    yield put(getVendorPaymentDetailFail(error))
  }
}

function* fetchVendorListInPayment({ vendorId }) {
  try {
    const response = yield call(getVendorListInPayment, vendorId)
    yield put(getVendorListInPaymentSuccess(response))
  } catch (error) {
    yield put(getVendorListInPaymentFail(error))
  }
}
function* fetchVendorContact({ vendorId }) {
  try {
    const response = yield call(getVendorContact, vendorId)
    yield put(getVendorContactSuccess(response))
  } catch (error) {
    yield put(getVendorContactFail(error))
  }
}

function* fetchVendorsList() {
  try {
    const response = yield call(getVendorsList)
    yield put(getVendorsListSuccess(response))
  } catch (error) {
    yield put(getVendorsListFail(error))
  }
}

function* fetchVendorServiceMasterList() {
  try {
    const response = yield call(getVendorServiceMasterList)
    yield put(getVendorServiceMasterListSuccess(response))
  } catch (error) {
    yield put(getVendorServiceMasterListFail(error))
  }
}

function* onAddNewVendors({ payload: vendor }) {
  try {
    const response = yield call(addNewVendors, vendor)
    yield put(addNewVendorsSuccess(response))
  } catch (error) {
    yield put(addNewVendorsFail(error))
  }
}

function* onAddNewVendorsDocuments({ payload: id, docs }) {
  try {
    const response = yield call(addNewVendorsDocuments, id, docs)
    yield put(addNewVendorsDocumentsSuccess(response))
  } catch (error) {
    yield put(addNewVendorsDocumentsFail(error))
  }
}

function* onAddNewVendorsContacts({ payload: id, contacts }) {
  try {
    const response = yield call(addNewVendorsContacts, id, contacts)
    yield put(addNewVendorsContactsSuccess(response))
  } catch (error) {
    yield put(addNewVendorsContactsFail(error))
  }
}

function* onUpdateVendors({ payload: id, vendor }) {
  try {
    const response = yield call(updateVendors, id, vendor)
    yield put(updateVendorsSuccess(response))
  } catch (error) {
    yield put(updateVendorsFail(error))
  }
}

function* onDeleteVendors({ payload: vendor }) {
  try {
    const response = yield call(deleteVendors, vendor)
    yield put(deleteVendorsSuccess(response))
  } catch (error) {
    yield put(deleteVendorsFail(error))
  }
}
function* onDeleteVendorsDocument({ payload: vendor }) {
  try {
    const response = yield call(deleteVendorsDocument, vendor)
    yield put(deleteVendorsDocumentSuccess(response))
  } catch (error) {
    yield put(deleteVendorsDocumentFail(error))
  }
}
function* onDeleteVendorsContact({ payload: vendor }) {
  try {
    const response = yield call(deleteVendorsContact, vendor)
    yield put(deleteVendorsContactSuccess(response))
  } catch (error) {
    yield put(deleteVendorsContactFail(error))
  }
}

function* fetchVendorCode() {
  try {
    const response = yield call(getVendorCode)
    yield put(getVendorCodeSuccess(response))
  } catch (error) {
    yield put(getVendorCodeFail(error))
  }
}

function* onAddNewPayment({ payload: { history, payment } }) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(addVendorPayment, payment)
    if (response.status === true) {
      yield put(addVendorPaymentSuccess(response))
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Vendor Payment has been Saved.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/vendor-payment-history")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addVendorPaymentFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.response.data.message || "Unknown error",
    })
  }
}

function* fetchVendorPaymentData({ paymentId }) {
  try {
    const response = yield call(getVendorPaymentData, paymentId)
    yield put(getVendorPaymentDataSuccess(response))
  } catch (error) {
    yield put(getVendorPaymentDataFail(error))
  }
}

function* vendorsSaga() {
  yield takeEvery(GET_VENDOR, fetchVendor)
  yield takeEvery(GET_VENDOR_CONTACT, fetchVendorContact)
  yield takeEvery(GET_VENDORS_LIST, fetchVendorsList)
  yield takeEvery(GET_VENDOR_SERVICE_MASTER_LIST, fetchVendorServiceMasterList)
  yield takeEvery(ADD_NEW_VENDORS, onAddNewVendors)
  yield takeEvery(ADD_NEW_VENDORS_DOCUMENTS, onAddNewVendorsDocuments)
  yield takeEvery(ADD_NEW_VENDORS_CONTACTS, onAddNewVendorsContacts)
  yield takeEvery(UPDATE_VENDORS, onUpdateVendors)
  yield takeEvery(DELETE_VENDORS, onDeleteVendors)
  yield takeEvery(DELETE_VENDOR_DOCUMENT, onDeleteVendorsDocument)
  yield takeEvery(DELETE_VENDOR_CONTACT, onDeleteVendorsContact)
  yield takeEvery(GET_VENDOR_CODE, fetchVendorCode)
  yield takeEvery(ADD_VENDOR_PAYMENT, onAddNewPayment)
  yield takeEvery(GET_VENDOR_LIST_IN_PAYMENT, fetchVendorListInPayment)
  yield takeEvery(GET_VENDOR_PAYMENT_DETAIL, fetchVendorPaymentDetail)
  yield takeEvery(GET_VENDOR_PAYMENT_DATA, fetchVendorPaymentData)
}

export default vendorsSaga
