import {
  GET_SACHSNCODE,
  GET_SACHSNCODE_SUCCESS,
  GET_SACHSNCODE_FAIL,
  GET_SACHSNCODE_DETAIL,
  GET_SACHSNCODE_DETAIL_SUCCESS,
  GET_SACHSNCODE_DETAIL_FAIL,
  ADD_NEW_SACHSNCODE,
  ADD_NEW_SACHSNCODE_SUCCESS,
  ADD_NEW_SACHSNCODE_FAIL,
  UPDATE_SACHSNCODE,
  UPDATE_SACHSNCODE_SUCCESS,
  UPDATE_SACHSNCODE_FAIL,
  DELETE_SACHSNCODE,
  DELETE_SACHSNCODE_SUCCESS,
  DELETE_SACHSNCODE_FAIL,
} from "./actionTypes"

export const getSACHSNCode = () => ({
  type: GET_SACHSNCODE,
})

export const getSACHSNCodeSuccess = codes => ({
  type: GET_SACHSNCODE_SUCCESS,
  payload: codes,
})
export const getSACHSNCodeFail = error => ({
  type: GET_SACHSNCODE_FAIL,
  payload: error,
})

export const getSACHSNCodeDetail = codeId => ({
  type: GET_SACHSNCODE_DETAIL,
  codeId,
})

export const getSACHSNCodeDetailSuccess = codeDetail => ({
  type: GET_SACHSNCODE_DETAIL_SUCCESS,
  payload: codeDetail,
})

export const getSACHSNCodeDetailFail = error => ({
  type: GET_SACHSNCODE_DETAIL_FAIL,
  payload: error,
})

export const addNewSACHSNCode = (codes, history) => ({
  type: ADD_NEW_SACHSNCODE,
  payload: { codes, history },
  status: true,
})

export const addNewSACHSNCodeSuccess = codes => ({
  type: ADD_NEW_SACHSNCODE_SUCCESS,
  payload: codes,
})

export const addNewSACHSNCodeFail = error => ({
  type: ADD_NEW_SACHSNCODE_FAIL,
  payload: error,
})

export const updateSACHSNCode = (id, code, history) => ({
  type: UPDATE_SACHSNCODE,
  payload: { id, code, history },
})

export const updateSACHSNCodeSuccess = code => ({
  type: UPDATE_SACHSNCODE_SUCCESS,
  payload: code,
})

export const updateSACHSNCodeFail = error => ({
  type: UPDATE_SACHSNCODE_FAIL,
  payload: error,
})

export const deleteSACHSNCode = code => ({
  type: DELETE_SACHSNCODE,
  payload: code,
})

export const deleteSACHSNCodeSuccess = code => ({
  type: DELETE_SACHSNCODE_SUCCESS,
  payload: code,
})

export const deleteSACHSNCodeFail = error => ({
  type: DELETE_SACHSNCODE_FAIL,
  payload: error,
})
