import React, { useEffect, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getPastEmploymentDetail } from "store/actions"

const viewpastemployment = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const viewPastEmploymentState = state => state.users.pastEmp.data
  const data = useSelector(viewPastEmploymentState)
  const [employmentId, setEmploymentId] = useState()

  const fetchData = () => {
    const url = new URL(window.location.href)
    const pastEmploymentId = url.pathname.split("/view-pastemployment/")[1]
    setEmploymentId(pastEmploymentId)
    // viewPastEmploymentState(pastEmploymentId)
    dispatch(getPastEmploymentDetail(pastEmploymentId))
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <CardTitle className="h4" style={{ fontSize: "20px" }}>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              <CardTitle className="mb-3">Employment Details</CardTitle>
            </div>
          </CardTitle>
          <Row>
            <Col lg={12}>
              {data !== 0 ? (
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <Row className="mb-3">
                          <Col lg={4}>
                            <Label className="form-label">
                              Name of Company{" "}
                            </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.name_of_company}
                            </p>
                          </Col>
                          <Col lg={4}>
                            <Label className="form-label">Start Date </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.start_date}
                            </p>
                          </Col>
                          <Col lg={4}>
                            <Label className="form-label">End Date </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.end_date}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row className="mb-3">
                          <Col lg={4}>
                            <Label className="form-label">Designation </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.designation}
                            </p>
                          </Col>
                          <Col lg={4}>
                            <Label className="form-label">
                              Employee Id (Employee UID){" "}
                            </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.employee_id}
                            </p>
                          </Col>
                          <Col lg={4}>
                            <Label className="form-label">
                              Contact Person Name{" "}
                            </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.contact_person_name}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row className="mb-3">
                          <Col lg={4}>
                            <Label className="form-label">
                              Contact Person Email{" "}
                            </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.contact_person_email}
                            </p>
                          </Col>
                          <Col lg={4}>
                            <Label className="form-label">
                              Contact Person Contact Number{" "}
                            </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.contact_person_contact_no}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row className="mb-3">
                          <Col lg={4}>
                            <Label className="form-label">Country </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.country_name}
                            </p>
                          </Col>
                          <Col lg={4}>
                            <Label className="form-label">State </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.state_name}
                            </p>
                          </Col>
                          <Col lg={4}>
                            <Label className="form-label">City </Label>
                            <p
                              className="text-dark"
                              style={{ fontWeight: 600 }}
                            >
                              {data?.city_name}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">Pincode </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data?.pincode}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3 ">
                          {permission
                            .filter(
                              item =>
                                item.page === "/edit-new-pastemployment/:id" &&
                                item.access === 1
                            )
                            .map((item, index) => (
                              <Link
                                key={index}
                                to={`/users/edit-new-pastemployment/${employmentId}`}
                              >
                                <button className="btn btn-primary l-md">
                                  Edit
                                </button>
                              </Link>
                            ))}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ) : (
                <p style={{ fontSize: "25px", textAlign: "center" }}>
                  No data found...
                </p>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default viewpastemployment
