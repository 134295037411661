import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import Swal from "sweetalert2"

const token = accessToken

const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

let latestToken = accessToken

axiosApi.defaults.headers.common["Authorization"] = latestToken

axiosApi.interceptors.response.use(
  response => {
    const newToken = response.data && response.data.access_token
    if (newToken) {
      latestToken = `Bearer ${newToken}`
      axiosApi.defaults.headers.common["Authorization"] = latestToken
    }

    return response
  },
  async error => {
    console.log(error)
    if (
      error.response &&
      error.response.status === 401 &&
      localStorage.getItem("authUser")
    ) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Your Session has Timed Out.",
      }).then(() => {
        console.log("UNAUTH")
        localStorage.removeItem("authUser")
        window.location.href = "/login"
      })
    }
    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, {
      ...config,
      headers: {
        Authorization: latestToken,
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config, headers: { Authorization: latestToken } })
    .then(response => response.data)
}

export async function postWithFile(
  url,
  data,
  config = { Authorization: latestToken }
) {
  const headers = {
    ...config,
    "Content-Type": "multipart/form-data",
  }

  return axiosApi.post(url, data, { headers }).then(response => response.data)
}

export async function put(url, data, config = { Authorization: accessToken }) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = { Authorization: latestToken }) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function downloadExcel(url, data) {
  try {
    const response = await axiosApi.post(url, data, {
      headers: {
        Authorization: latestToken,
        "Content-Type": "application/json",
      },
      responseType: 'blob', 
    });

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const downloadUrl = window.URL.createObjectURL(blob);
    const anchorElement = document.createElement('a');
    anchorElement.href = downloadUrl;
    anchorElement.target = '_blank'; // Open in a new tab
    anchorElement.download = `${data.type}.xlsx` || 'gstreport.xlsx';

    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error during download:', error);
    
  }
}

export async function downloadPDF(url, data) {
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: 'Bearer ' + latestToken, // Adjust if necessary
        'Content-Type': 'application/json',
      },
      responseType: 'blob', 
    });

    const blob = new Blob([response.data], {
      type: 'application/pdf',
    });

    const downloadUrl = window.URL.createObjectURL(blob);
    const anchorElement = document.createElement('a');
    anchorElement.href = downloadUrl;
    anchorElement.target = '_blank'; // Open in a new tab
    anchorElement.download = `${data.type}.pdf` || 'report.pdf';

    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error during download:', error);
  }
}