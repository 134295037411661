import {
  ADD_MANPOWER_IN_TICKET,
  ADD_MANPOWER_IN_TICKET_FAIL,
  ADD_MANPOWER_IN_TICKET_SUCCESS,
  ADD_MATERIAL_IN_TICKET,
  ADD_MATERIAL_IN_TICKET_FAIL,
  ADD_MATERIAL_IN_TICKET_SUCCESS,
  ADD_OTHER_COST_IN_TICKET,
  ADD_OTHER_COST_IN_TICKET_FAIL,
  ADD_OTHER_COST_IN_TICKET_SUCCESS,
  SEND_FOR_REVIEW_IN_TICKET,
  SEND_FOR_REVIEW_IN_TICKET_FAIL,
  SEND_FOR_REVIEW_IN_TICKET_SUCCESS,
  GET_SERVICE_TICKETS_DETAIL,
  GET_SERVICE_TICKETS_DETAIL_FAIL,
  GET_SERVICE_TICKETS_DETAIL_SUCCESS,
  GET_SERVICE_TICKETS_LIST,
  GET_SERVICE_TICKETS_LIST_FAIL,
  GET_SERVICE_TICKETS_LIST_SUCCESS,
  UPDATE_STATUS_IN_TICKET,
  UPDATE_STATUS_IN_TICKET_FAIL,
  UPDATE_STATUS_IN_TICKET_SUCCESS,
} from "./actionTypes"

export const getServiceRequestTicketList = userId => ({
  type: GET_SERVICE_TICKETS_LIST,
  userId,
})

export const getServiceRequestTicketListSuccess = userId => ({
  type: GET_SERVICE_TICKETS_LIST_SUCCESS,
  payload: userId,
})

export const getServiceRequestTicketListFail = error => ({
  type: GET_SERVICE_TICKETS_LIST_FAIL,
  payload: error,
})

export const getServiceRequestTicketDetail = ticketId => ({
  type: GET_SERVICE_TICKETS_DETAIL,
  ticketId,
})

export const getServiceRequestTicketDetailSuccess = tickets => ({
  type: GET_SERVICE_TICKETS_DETAIL_SUCCESS,
  payload: tickets,
})

export const getServiceRequestTicketDetailFail = error => ({
  type: GET_SERVICE_TICKETS_DETAIL_FAIL,
  payload: error,
})
//add material,manpower
export const addMaterialInTicket = (ticketId, data) => ({
  type: ADD_MATERIAL_IN_TICKET,
  payload: { ticketId, data },
})

export const addMaterialInTicketSuccess = data => ({
  type: ADD_MATERIAL_IN_TICKET_SUCCESS,
  payload: data,
})

export const addMaterialInTicketFail = error => ({
  type: ADD_MATERIAL_IN_TICKET_FAIL,
  payload: error,
})
//add material,manpower
export const addManpowerInTicket = (ticketId, data) => ({
  type: ADD_MANPOWER_IN_TICKET,
  payload: { ticketId, data },
})

export const addManpowerInTicketSuccess = data => ({
  type: ADD_MANPOWER_IN_TICKET_SUCCESS,
  payload: data,
})

export const addManpowerInTicketFail = error => ({
  type: ADD_MANPOWER_IN_TICKET_FAIL,
  payload: error,
})
//add material,manpower
export const addOtherCostInTicket = (ticketId, data) => ({
  type: ADD_OTHER_COST_IN_TICKET,
  payload: { ticketId, data },
})

export const addOtherCostInTicketSuccess = data => ({
  type: ADD_OTHER_COST_IN_TICKET_SUCCESS,
  payload: data,
})

export const addOtherCostInTicketFail = error => ({
  type: ADD_OTHER_COST_IN_TICKET_FAIL,
  payload: error,
})
//add proposal
export const sendForReviewInTicket = (ticketId, data) => ({
  type: SEND_FOR_REVIEW_IN_TICKET,
  payload: { ticketId, data },
})

export const sendForReviewInTicketSuccess = data => ({
  type: SEND_FOR_REVIEW_IN_TICKET_SUCCESS,
  payload: data,
})

export const sendForReviewInTicketFail = error => ({
  type: SEND_FOR_REVIEW_IN_TICKET_FAIL,
  payload: error,
})
//add material,manpower
export const updateStatusInTicket = (ticketId, data) => ({
  type: UPDATE_STATUS_IN_TICKET,
  payload: { ticketId, data },
})

export const updateStatusInTicketSuccess = data => ({
  type: UPDATE_STATUS_IN_TICKET_SUCCESS,
  payload: data,
})

export const updateStatusInTicketFail = error => ({
  type: UPDATE_STATUS_IN_TICKET_FAIL,
  payload: error,
})
