import {
    GET_COMPANIES,
    GET_COMPANIES_FAIL,
    GET_COMPANIES_SUCCESS,
    GET_COMPANY_DETAIL,
    ADD_NEW_COMPANY,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_FAIL,
    UPDATE_COMPANY,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAIL,
    DELETE_COMPANY,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY_FAIL,
    GET_COMPANY_DETAIL_FAIL,
    GET_COMPANY_DETAIL_SUCCESS,
    GET_DOCUMENT_LIST_COMPANY,
    GET_DOCUMENT_LIST_COMPANY_SUCCESS,
    GET_DOCUMENT_LIST_COMPANY_FAIL,
    ADD_DOCUMENT_COMPANY,
    ADD_DOCUMENT_COMPANY_SUCCESS,
    ADD_DOCUMENT_COMPANY_FAIL,
    DELETE_DOCUMENT_COMPANY,
    DELETE_DOCUMENT_COMPANY_SUCCESS,
    DELETE_DOCUMENT_COMPANY_FAIL,
    GET_COMPANY_LOCATION_LIST,
    GET_COMPANY_LOCATION_LIST_SUCCESS,
    GET_COMPANY_LOCATION_LIST_FAIL,
    ADD_COMPANY_LOCATION,
    ADD_COMPANY_LOCATION_SUCCESS,
    ADD_COMPANY_LOCATION_FAIL,
    UPDATE_COMPANY_LOCATION,
    UPDATE_COMPANY_LOCATION_SUCCESS,
    UPDATE_COMPANY_LOCATION_FAIL,
    DELETE_COMPANY_LOCATION,
    DELETE_COMPANY_LOCATION_SUCCESS,
    DELETE_COMPANY_LOCATION_FAIL,
    GET_COMPANY_LOCATION_DETAIL,
    GET_COMPANY_LOCATION_DETAIL_SUCCESS,
    GET_COMPANY_LOCATION_DETAIL_FAIL,
  } from "./actionTypes"
  
  export const getCompanies = () => ({
    type: GET_COMPANIES,
  })
  
  export const getCompaniesSuccess = companies => ({
    type: GET_COMPANIES_SUCCESS,
    payload: companies,
  })
  
  export const addNewCompany = company => ({
    type: ADD_NEW_COMPANY,
    payload: company,
  })
  
  export const addCompanySuccess = company => ({
    type: ADD_COMPANY_SUCCESS,
    payload: company,
  })
  
  export const addCompanyFail = error => ({
    type: ADD_COMPANY_FAIL,
    payload: error,
  })
  
  export const updateCompany = (id,company) => ({
    type: UPDATE_COMPANY,
    payload: {id,company},
  })
  
  export const updateCompanySuccess = company => ({
    type: UPDATE_COMPANY_SUCCESS,
    payload: company,
  })
  
  export const updateCompanyFail = error => ({
    type: UPDATE_COMPANY_FAIL,
    payload: error,
  })
  
  export const deleteCompany = company => ({
    type: DELETE_COMPANY,
    payload: company,
  })
  
  export const deleteCompanySuccess = company => ({
    type: DELETE_COMPANY_SUCCESS,
    payload: company,
  })
  
  export const deleteCompanyFail = error => ({
    type: DELETE_COMPANY_FAIL,
    payload: error,
  })
  
  export const getCompaniesFail = error => ({
    type: GET_COMPANIES_FAIL,
    payload: error,
  })
  
  export const getCompanyDetail = companyId => ({
    type: GET_COMPANY_DETAIL,
    companyId,
  })
  
  export const getCompanyDetailSuccess = companyDetails => ({
    type: GET_COMPANY_DETAIL_SUCCESS,
    payload: companyDetails,
  })
  
  export const getCompanyDetailFail = error => ({
    type: GET_COMPANY_DETAIL_FAIL,
    payload: error,
  })

  export const getCompanyDocuments = (companyId) => ({
    type: GET_DOCUMENT_LIST_COMPANY,
    companyId
  })
  
  export const getCompanyDocumentsSuccess = docs => ({
    type: GET_DOCUMENT_LIST_COMPANY_SUCCESS,
    payload: docs,
  })
  
  export const getCompanyDocumentsFail = error => ({
    type: GET_DOCUMENT_LIST_COMPANY_FAIL,
    payload: error,
  })
  
  export const addCompanyDocument = docs => ({
    type: ADD_DOCUMENT_COMPANY,
    payload: docs,
  })
  
  export const addCompanyDocumentSuccess = docs => ({
    type: ADD_DOCUMENT_COMPANY_SUCCESS,
    payload: docs,
  })
  
  export const addCompanyDocumentFail = error => ({
    type: ADD_DOCUMENT_COMPANY_FAIL,
    payload: error,
  })
  
  export const deleteCompanyDocument = docs => ({
    type: DELETE_DOCUMENT_COMPANY,
    docs,
  })
  
  export const deleteCompanyDocumentSuccess = docs => ({
    type: DELETE_DOCUMENT_COMPANY_SUCCESS,
    payload: docs,
  })
  
  export const deleteCompanyDocumentFail = error => ({
    type: DELETE_DOCUMENT_COMPANY_FAIL,
    payload: error,
  })

  export const getCompanyLocationList = (companyId) => ({
    type: GET_COMPANY_LOCATION_LIST,
    companyId
  })
  
  export const getCompanyLocationListSuccess = location => ({
    type: GET_COMPANY_LOCATION_LIST_SUCCESS,
    payload: location,
  })
  
  export const getCompanyLocationListFail = error => ({
    type: GET_COMPANY_LOCATION_LIST_FAIL,
    payload: error,
  })

  export const getCompanyLocationDetail = (companyId) => ({
    type: GET_COMPANY_LOCATION_DETAIL,
    companyId
  })
  
  export const getCompanyLocationDetailSuccess = location => ({
    type: GET_COMPANY_LOCATION_DETAIL_SUCCESS,
    payload: location,
  })
  
  export const getCompanyLocationDetailFail = error => ({
    type: GET_COMPANY_LOCATION_DETAIL_FAIL,
    payload: error,
  })
  
  export const addCompanyLocation = location => ({
    type: ADD_COMPANY_LOCATION,
    payload: location,
  })
  
  export const addCompanyLocationSuccess = location => ({
    type: ADD_COMPANY_LOCATION_SUCCESS,
    payload: location,
  })
  
  export const addCompanyLocationFail = error => ({
    type: ADD_COMPANY_LOCATION_FAIL,
    payload: error,
  })

  export const updateCompanyLocation = (id,location) => ({
    type: UPDATE_COMPANY_LOCATION,
    payload: {id,location},
  })
  
  export const updateCompanyLocationSuccess = location => ({
    type: UPDATE_COMPANY_LOCATION_SUCCESS,
    payload: location,
  })
  
  export const updateCompanyLocationFail = error => ({
    type: UPDATE_COMPANY_LOCATION_FAIL,
    payload: error,
  })

  export const deleteCompanyLocation= location => ({
    type: DELETE_COMPANY_LOCATION,
    location,
  })
  
  export const deleteCompanyLocationSuccess = location => ({
    type: DELETE_COMPANY_LOCATION_SUCCESS,
    payload: location,
  })
  
  export const deleteCompanyLocationFail = error => ({
    type: DELETE_COMPANY_LOCATION_FAIL,
    payload: error,
  })