import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"

// import { recentfile } from "../../../common/data";
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  deleteVendorsContact,
  getPastEmployment,
  getVendor,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"

const VendorContacts = () => {
  const [vendorId, setVendorId] = useState()
  const dispatch = useDispatch()
  const vendorContactState = state => state.vendors.vendor.data
  const vendorContacts = useSelector(vendorContactState) || []

  const fetchData = () => {
    const url = new URL(window.location.href)
    const vendorId = url.pathname.split("/view-vendor/")[1]
    setVendorId(vendorId)
   
  }
  useEffect(() => {
    fetchData()
  }, [dispatch])

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = vendorId => {
    setDeleteId({ id: vendorId })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteVendorsContact(deleteId.id))
    setDeleteModal(false)
    dispatch(getVendor(vendorId))
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th scope="col">Name/Role</th>
              <th scope="col">Phone No.</th>
              <th scope="col">Email Address</th>
              <th scope="col" colSpan={2}>
                Address
              </th>
            </tr>
          </thead>
          {Array.isArray(vendorContacts.vendor_contacts) &&
          vendorContacts.vendor_contacts.length > 0 ? (
            <tbody>
              {vendorContacts.vendor_contacts.map((item, key) => (
                <tr key={key}>
                  <td>
                    <Link to="#" className="text-dark fw-medium">
                      {item.role}
                    </Link>
                  </td>
                  <td>{item.phone_no}</td>
                  <td>{item.vendor_email_address}</td>
                  <td>{item.vendor_address}</td>

                  <td>
                    <div className="d-flex gap-3">
                      {permission
                        .filter(
                          item =>
                            item.page === "delete-vendor-contact" &&
                            item.access === 1
                        )
                        .map(( index) => (
                          <Link
                            key={index}
                            to="#"
                            className="text-danger"
                            onClick={() => {
                              onClickDelete(item.id)
                            }}
                          >
                            <i
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="deletetooltip"
                            >
                              Delete
                            </UncontrolledTooltip>
                          </Link>
                        ))}
                      {permission
                        .filter(
                          item =>
                            item.page === "/edit-vendor-contact/:id" &&
                            item.access === 1
                        )
                        .map(( index) => (
                          <Link
                            key={index}
                            to={`/vendor/edit-vendor-contact/${item.id}`}
                            className="text-success"
                          >
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id={`edittooltip-${key}`}
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`edittooltip-${key}`}
                            >
                              Edit
                            </UncontrolledTooltip>
                          </Link>
                        ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="5" className="text-center">
                  No Records Found
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
      <div className="mt-3 text-end">
        {permission
          .filter(
            item =>
              item.page === "/add-new-vendor-contact/:id" && item.access === 1
          )
          .map((item, index) => (
            <Link key={index} to={`/vendor/add-new-vendor-contact/${vendorId}`}>
              <button type="submit" className="btn btn-primary w-md">
                Add Contacts
              </button>
            </Link>
          ))}
      </div>

      {/* </div> */}
    </React.Fragment>
  )
}

export default VendorContacts
