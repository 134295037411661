import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
// import { getClientDetail } from "store/actions"
import Spinners from "components/Common/Spinner"
import { getProfileDetail } from "store/actions"

const myProfile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const profileState = state => state?.myProfile?.detail?.user
  const loadingState = state => state?.myProfile?.loading
  const loading = useSelector(loadingState) || []
  const [isLoading, setLoading] = useState(loading)
  const profile = useSelector(profileState) || []
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [userId, setUserId] = useState(authUser ? authUser.user_id : "")

  useEffect(() => {
    dispatch(getProfileDetail(userId))
  }, [dispatch])

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <div className="page-content">
          <Container fluid={true}>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <Card className="overflow-hidden">
                  <div style={{ backgroundColor: "#F6F6F6" }}>
                    <Row className="mt-5">
                      <Col xs="5" className="align-self-end">
                        <img src="" alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody>
                    <Row>
                      <Col sm="4">
                        <div
                          className="avatar-lg profile-user-wid mb-3"
                          style={{ marginTop: "-50px" }}
                        >
                          <img
                            src={profile && profile.image}
                            alt="ALT"
                            className="img-thumbnail rounded-circle"
                            style={{ width: "120px", height: "100px" }}
                          />
                        </div>
                      </Col>
                      <Col sm="9">
                        <div className="pt-4">
                          <Row>
                            <Col xs="12">
                              <h5 className="font-size-15">
                                {profile && profile.first_name}{" "}
                                {profile && profile.middle_name}{" "}
                                {profile && profile.last_name}
                              </h5>
                              <p className="text-muted mb-0">
                                {profile && profile.email}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <div className="d-inline-flex">
                              {/* <Link to={`/users/edit-user/${profile.id}`}>
                                <Button
                                  className="mt-2 me-2"
                                  style={{
                                    backgroundColor: "#0D6EFD",
                                    color: "white",
                                    border: "none",
                                    fontSize: "10px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Edit Profile
                                </Button>
                              </Link> */}
                              <Link to={"/update-password"}>
                                <Button
                                  className="mt-2"
                                  style={{
                                    backgroundColor: "#E6E6E6",
                                    color: "black",
                                    border: "none",
                                    fontSize: "10px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Update Password
                                </Button>
                              </Link>
                            </div>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-5">Office Details</CardTitle>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Employee Code:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{profile && profile.employee_code}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">User Role:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div> {profile && profile.role}</div>
                      </div>
                    </div>

                    <hr></hr>
                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Login Username:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div> {profile && profile.login_username}</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-5">Personal Details</CardTitle>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">First Name:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div> {profile && profile.first_name}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Middle Name:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{profile && profile.middle_name}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Last Name:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{profile && profile.last_name}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Address:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{profile && profile.address}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Country:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{profile && profile.country_name}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">State:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{profile && profile.state_name}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">City:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{profile && profile.city_name}</div>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Pincode:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{profile && profile.pincode}</div>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Project Access:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>
                          {profile && profile?.project_names?.join(", ")}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-5">Social Details</CardTitle>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Pan No:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{profile && profile.pan_no}</div>
                      </div>
                    </div>

                    <hr></hr>

                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">Aadhar No:</h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{profile && profile.aadhar_no}</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default myProfile
