import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_DOCUMENTS,GET_ACTIVE_DOCUMENTS, GET_DOCUMENT_DETAIL, ADD_NEW_DOCUMENT, DELETE_DOCUMENT, UPDATE_DOCUMENT } from "./actionTypes"
import {
  getDocumentsSuccess,
  getDocumentsFail,
  getActiveDocumentsSuccess,
  getActiveDocumentsFail,
  getDocumentDetailSuccess,
  getDocumentDetailFail,
  addDocumentFail,
  addDocumentSuccess,
  updateDocumentSuccess,
  updateDocumentFail,
  deleteDocumentSuccess,
  deleteDocumentFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getDocuments, getActiveDocuments, getDocumentDetails, addNewDocument, updateDocument, deleteDocument } from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

function* fetchDocuments() {
  try {
    const response = yield call(getDocuments)
    yield put(getDocumentsSuccess(response))
  } catch (error) {
    yield put(getDocumentsFail(error))
  } 
}

function* fetchActiveDocuments({userType}) {
  try {
    const response = yield call(getActiveDocuments,userType)
    yield put(getActiveDocumentsSuccess(response))
  } catch (error) {
    yield put(getActiveDocumentsFail(error))
  } 
}

function* fetchDocumentDetail({ documentId }) {
  try {
    const response = yield call(getDocumentDetails, documentId)
    yield put(getDocumentDetailSuccess(response))
  } catch (error) {
    yield put(getDocumentDetailFail(error))
  }
}

function* onUpdateDocument({ payload: { id, document } }) {
  try {
    const response = yield call(updateDocument,id, document)
    // yield put(updateDocumentSuccess(response))
    toast.success("Document Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateDocumentFail(error))
    // toast.error("Document Update Failded", { autoClose: 2000 })
  }
  const response2 = yield put(updateFacilitySuccess(response))
}

function* onDeleteDocument({ payload: document }) {
  try {
    const response = yield call(deleteDocument, document)
    // yield put(deleteDocumentSuccess(response))
    // toast.success("Document Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteDocumentFail(error))
    // toast.error("Document Delete Failded", { autoClose: 2000 })
  }
}

function* onAddNewDocument({ payload: document }) {
  try {
    const response = yield call(addNewDocument, document)
    yield put(addDocumentSuccess(response))
    toast.success("Document Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addDocumentFail(error))
    toast.error("Document Add Failded", { autoClose: 2000 })
  }
}

function* documentsSaga() {
  yield takeEvery(GET_DOCUMENTS, fetchDocuments)
  yield takeEvery(GET_ACTIVE_DOCUMENTS, fetchActiveDocuments)
  yield takeEvery(GET_DOCUMENT_DETAIL, fetchDocumentDetail)
  yield takeEvery(ADD_NEW_DOCUMENT, onAddNewDocument)
  yield takeEvery(UPDATE_DOCUMENT, onUpdateDocument)
  yield takeEvery(DELETE_DOCUMENT, onDeleteDocument)
}

export default documentsSaga
