import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./billingSetupPendingListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { getBillingSetupPendingList } from "store/actions"

const BillingSetupPendingList = () => {
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  document.title = "Billing Setup Pending"

  const dispatch = useDispatch()
  const billingSetupPendingState = state =>
    state.serviceEngagement.billingSetupPendingList.project_units

  const billingSetupPending = useSelector(billingSetupPendingState) || []

  useEffect(() => {
    dispatch(getBillingSetupPendingList())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Customer",
        accessor: "customer_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={`/service-request/view-service/${cellProps.row.original.id}`}
                >
                  {cellProps.row.original.customer_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project",
        accessor: "project",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project Unit",
        accessor: "project_unit",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.unit_code}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Created By",
        accessor: "created_by",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                {cellProps.row.original.Created_by_first_name} {cellProps.row.original.Created_by_last_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project Manager",
        accessor: "project_manager",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                {cellProps.row.original.project_manager_first_name} {cellProps.row.original.project_manager_last_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      // {
      //   Header: "Approval Status",
      //   accessor: "approval_status",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return (
      //       <>
      //         <h5 className="font-size-14 mb-1">
      //           <Link className="text-dark">
      //             {cellProps.row.original.approval_status === 1
      //               ? "Completed"
      //               : cellProps.row.original.approval_status === 2
      //               ? "Pending"
      //               : "Rejected"}
      //           </Link>
      //         </h5>
      //       </>
      //     )
      //   },
      // },
      // {
      //   Header: "Billing Setup",
      //   accessor: "billing_setup",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return (
      //       <>
      //         <h5 className="font-size-14 mb-1">
      //           <Link className="text-dark">
      //             {cellProps.row.original.billing_setup === 1
      //               ? "Completed"
      //               : cellProps.row.original.billing_setup === 2
      //               ? "Pending"
      //               : "Rejected"}
      //           </Link>
      //         </h5>
      //       </>
      //     )
      //   },
      // },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
                {cellProps.row.original.billing_setup === 2 &&
                 (
              <Link to={`/billing/billing-setup/${cellProps.row.original.id}`}>
                <button className="btn btn-primary"  style={{ width: "140px" }}>
                  Billing Setup
                </button>
              </Link>)}
              
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Service Engagement"
            breadcrumbItem="Billing Setup Pending"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={billingSetupPending}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BillingSetupPendingList
