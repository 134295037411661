import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_EXPENSES,
  GET_EXPENSE_DETAIL,
  ADD_NEW_EXPENSE,
  DELETE_EXPENSE,
  UPDATE_EXPENSE,
} from "./actionTypes"
import {
  getExpensesSuccess,
  getExpensesFail,
  getExpenseDetailSuccess,
  getExpenseDetailFail,
  addExpenseFail,
  addExpenseSuccess,
  updateExpenseSuccess,
  updateExpenseFail,
  deleteExpenseSuccess,
  deleteExpenseFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getExpenses,
  getExpenseDetails,
  addNewExpense,
  updateExpense,
  deleteExpense,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

function* fetchExpenses() {
  try {
    const response = yield call(getExpenses)
    yield put(getExpensesSuccess(response))
  } catch (error) {
    yield put(getExpensesFail(error))
  }
}

function* fetchExpenseDetail({ expenseId }) {
  try {
    const response = yield call(getExpenseDetails, expenseId)
    yield put(getExpenseDetailSuccess(response))
  } catch (error) {
    yield put(getExpenseDetailFail(error))
  }
}

function* onUpdateExpense({ payload: { id, expense } }) {
  try {
    const response = yield call(updateExpense, id, expense)
    yield put(updateExpenseSuccess(response))
  } catch (error) {
    yield put(updateExpenseFail(error))
  }
}

function* onDeleteExpense({ payload: expense }) {
  try {
    const response = yield call(deleteExpense, expense)
    yield put(deleteExpenseSuccess(response))
  } catch (error) {
    yield put(deleteExpenseFail(error))
  }
}

function* onAddNewExpense({ payload: expense }) {
  try {
    const response = yield call(addNewExpense, expense)
    yield put(addExpenseSuccess(response))
    toast.success("Expense Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addExpenseFail(error))
    toast.error("Expense Add Failded", { autoClose: 2000 })
  }
}

function* expensesSaga() {
  yield takeEvery(GET_EXPENSES, fetchExpenses)
  yield takeEvery(GET_EXPENSE_DETAIL, fetchExpenseDetail)
  yield takeEvery(ADD_NEW_EXPENSE, onAddNewExpense)
  yield takeEvery(UPDATE_EXPENSE, onUpdateExpense)
  yield takeEvery(DELETE_EXPENSE, onDeleteExpense)
}

export default expensesSaga
