import React, { useEffect, useState } from "react"
import Swal from "sweetalert2"

import {
  Card,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Label,
  Input,
  navigate,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import VLogo from "../../../assets/images/VIP/VLogo.png"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import { getDocumentDetail } from "store/actions"
import { updateDocument, addNewDocument } from "helpers/fakebackend_helper"

const NewDocumentService = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const dispatch = useDispatch()
  const documentState = state => state.documents.documentDetail.Document
  const data = useSelector(documentState)




  const documentsProperties = createSelector(
    documentState,
    // loadingState,
    (documents, loading) => {
      return {
        documents: documents,
        loading: loading,
      }
    }
  )

  const { documents, loading } = useSelector(documentsProperties)

  const [specificUser, setSpecificUser] = useState(null)

  const [documentId, setDocumentId] = useState()

  const documentData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const documentId = url.pathname.split("/edit-document-master-service/")[1]
      setDocumentId(documentId)
    
      dispatch(getDocumentDetail(documentId))

    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      documentData()
    }
  }, [dispatch])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          document_title: (documents && documents.document_title) || "",
          applicable_for: (documents && documents.applicable_for) || "",
          is_mandatory: (documents && documents.is_mandatory) || false,
          is_active: (documents && documents.is_active) || false,
        }
      : {
          document_title: "",
          applicable_for: "",
          is_mandatory: false,
          is_active: false,
        },
    validationSchema: Yup.object({
      document_title: Yup.string().required("Please Enter Document Title"),
      applicable_for: Yup.string().required("Please Select Valid Option"),
      // is_mandatory: Yup.boolean().required("Please Select Valid Option"),
      is_mandatory: Yup.boolean(),
      // is_active: Yup.boolean().required("Please Select Valid Option"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        // Assuming addNewVendor returns a Promise
        // const response =  dispatch(addNewVendor(values));
        // let response
        if (isEditMode === true) {
          const response = await updateDocument(documentId, values)

          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Document has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/document-master")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has been saved.",
            })
          }
        } else if (isEditMode === false) {
          // const response = await dispatch(addNewVendor(values));
          const response = await addNewDocument(values)
          loadingSwal.close()
          if (response && response.status === true) {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Document has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/document-master")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Document has not been saved.",
            })
          }


        }
      } catch (error) {
        // Handle the error if needed
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },

   
  })

  const navigate = useNavigate()
  const toggleToast = () => {
    setToast(!toast)
  }
  const toggleToast2 = () => {
    setToast2(!toast2)
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  // Check if the current path includes 'edit-project'
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)
    setToast2(true)
  }
  const [selectedOption, setSelectedOption] = useState(documents?.is_active)
  const [selectedOptionNew, setSelectedOptionNew] = useState(
    documents?.is_mandatory
  )

  const handleChange1 = () => {
    const newValue = !selectedOption
    setSelectedOption(newValue)
    validation.setFieldValue("is_active", newValue)
  }
  const handleChange2 = () => {
    const isMandatoryValue = !selectedOptionNew
    setSelectedOptionNew(isMandatoryValue)
    validation.setFieldValue("is_mandatory", isMandatoryValue)
  }

  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {" "}
              {isEditMode ? "Edit Document Type" : "Add New Document Type"}
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Settings / Master configuration / Document Master" />
        </Col>
      </Row>
      <div className="mt-3"></div>
      <Card>
        <CardBody>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-documentTitle-Input">
                    Document Title*
                  </Label>
                  <Input
                    type="text"
                    name="document_title"
                    className="form-control"
                    id="formrow-documentTitle-Input"
                    placeholder="Enter Document Title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.document_title || ""}
                    invalid={
                      validation.touched.document_title &&
                      validation.errors.document_title
                        ? true
                        : false
                    }
                  />
                  {validation.touched.document_title &&
                  validation.errors.document_title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.document_title}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-firstname-Input">
                    Applicable For*
                  </Label>
                  <select
                    name="applicable_for"
                    defaultValue="0"
                    // className="form-select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.applicable_for || ""}
                    className={`form-select ${
                      validation.touched.applicable_for &&
                      validation.errors.applicable_for
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.applicable_for && (
                      <option>Select Applicable for</option>
                    )}
                    {/* <option value="0">Select Applicable for</option> */}
                    <option value="Customer">Customer</option>
                    <option value="Vendor">Vendor</option>
                    <option value="Service Request">Service Request</option>
                    <option value="User">User</option>
                    <option value="Landlord">Landlord</option>
                    <option value="Company">Company</option>
                  </select>
                  {validation.touched.applicable_for &&
                  validation.errors.applicable_for ? (
                    <FormFeedback type="invalid">
                      {validation.errors.applicable_for}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <Label htmlFor="is_mandatory">Is Mandatory</Label>
                </div>

                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="is_mandatory"
                      name="is_mandatory"
                      onChange={e => {
                        validation.setFieldValue(
                          "is_mandatory",
                          e.target.checked
                        )
                      }}
                      checked={Boolean(validation?.values?.is_mandatory)}
                    />
                    <Label className="form-check-Label" htmlFor="is_mandatory">
                      Yes
                    </Label>
                  </div>
                  {validation.touched.is_mandatory &&
                    validation.errors.is_mandatory && (
                      <FormFeedback type="invalid">
                        {validation.errors.is_mandatory}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
              <Col>
                <div>
                  <Label htmlFor="is_active">Is Active</Label>
                </div>

                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="is_active"
                      name="is_active"
                      onChange={e => {
                        validation.setFieldValue("is_active", e.target.checked)
                      }}
                      checked={Boolean(validation?.values?.is_active)}
                    />
                    <Label className="form-check-Label" htmlFor="is_active">
                      Yes
                    </Label>
                  </div>
                  {validation.touched.is_active &&
                    validation.errors.is_active && (
                      <FormFeedback type="invalid">
                        {validation.errors.is_active}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-md">
                    {isEditMode ? "Edit" : "Submit"}
                  </button>
                </div>
                {toast2 && (
                  <div
                    className="position-fixed top-0 end-0 p-3"
                    style={{ zIndex: "1005" }}
                  >
                    <Toast isOpen={toast2} role="aleart">
                      <ToastHeader toggle={() => setToast2(!toast2)}>
                        <img src={VLogo} alt="" className="me-2" height="18" />
                        <strong className="me-auto">VIP Mall</strong>
                      </ToastHeader>
                      <ToastBody>
                        {!isEditMode
                          ? "Submitted Successfully"
                          : "Edited Successfully"}
                      </ToastBody>
                    </Toast>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default NewDocumentService
