export const GET_REASON_MASTER_LIST = "GET_REASON_MASTER_LIST"
export const GET_REASON_MASTER_LIST_SUCCESS = "GET_REASON_MASTER_LIST_SUCCESS"
export const GET_REASON_MASTER_LIST_FAIL = "GET_REASON_MASTER_LIST_FAIL"

export const GET_REASON_MASTER_DETAIL = "GET_REASON_MASTER_DETAIL"
export const GET_REASON_MASTER_DETAIL_SUCCESS = "GET_REASON_MASTER_DETAIL_SUCCESS"
export const GET_REASON_MASTER_DETAIL_FAIL = "GET_REASON_MASTER_DETAIL_FAIL"

export const ADD_NEW_REASON_MASTER = "ADD_NEW_REASON_MASTER"
export const ADD_NEW_REASON_MASTER_SUCCESS = "ADD_NEW_REASON_MASTER_SUCCESS"
export const ADD_NEW_REASON_MASTER_FAIL = "ADD_NEW_REASON_MASTER_FAIL"

export const UPDATE_REASON_MASTER = "UPDATE_REASON_MASTER"
export const UPDATE_REASON_MASTER_SUCCESS = "UPDATE_REASON_MASTER_SUCCESS"
export const UPDATE_REASON_MASTER_FAIL = "UPDATE_REASON_MASTER_FAIL"

export const DELETE_REASON_MASTER = "DELETE_REASON_MASTER"
export const DELETE_REASON_MASTER_SUCCESS = "DELETE_REASON_MASTER_SUCCESS"
export const DELETE_REASON_MASTER_FAIL = "DELETE_REASON_MASTER_FAIL"
