import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Toast,
  ToastHeader,
  ToastBody,
  navigate,
  FormFeedback,
} from "reactstrap"
import classnames from "classnames"
import { error } from "toastr"

import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
// import { addNewClient } from "store/actions"
import Swal from "sweetalert2"
import {
  addNewVendor,
  addNewVendors,
  addNewVendorsDocuments,
} from "helpers/fakebackend_helper"
import {
  getCities,
  getCountries,
  getStates,
  getVendorCode,
  getVendorServiceMasterList,
} from "store/actions"

const AddNewVendor = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const dispatch = useDispatch()
  const customerState = state => state.tickets.ticketDetail.Data
  const vendorState = state => state.vendors.vendorServiceMasterList.vendors
  const data = useSelector(customerState)
  const vendorServiceList = useSelector(vendorState)

  const customerProperties = createSelector(
    customerState,
    // loadingState,
    (customers, loading) => {
      return {
        customers: customers,
        loading: loading,
      }
    }
  )

  useEffect(() => {
    dispatch(getVendorServiceMasterList())
  }, [dispatch])

  const { customers, loading } = useSelector(customerProperties)

  const [specificUser, setSpecificUser] = useState(null)

  const [customerId, setCustomerId] = useState()

  const [selectedOption, setSelectedOption] = useState("")

  const [selectedFile, setSelectedFile] = useState()
  const [imageURL, setImageURL] = useState(null)
  const [imageTouched, setImageTouched] = useState(false)

  const vendorCodeState = state => state.vendors.vendor_code.vendor_code

  const vendorCode = useSelector(vendorCodeState)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values need to be changed
    // enableReinitialize: true,

    initialValues: {
      image: "",
      entity: "",
      vendor_name: "",
      vendor_code: "",
      service_offering: "",
      email_address: "",
      mobile_no: "",
      address: "",
      pincode: "",
      country: "IN",
      state: "",
      city: "",
      pan_no: "",
      aadhar_no: "",
      tan_no: "",
      gst_no: "",
      bank_name: "",
      account_no: "",
      ifsc_code: "",
      account_holder_name: "",
      login_username: "",
      is_active: 0,
    },
    validationSchema: Yup.object().shape({
      image: Yup.string(),
      entity: Yup.string().required("Please Select Entity Type"),
      vendor_name: Yup.string().required("Please Enter Vendor Name"),
      vendor_code: Yup.string(),
      // service_offering: Yup.array().of(Yup.string()), // Assuming it's an array of strings
      email_address: Yup.string()
        .email("Invalid Email Format")
        .required("Please Enter Email Address"),
      mobile_no: Yup.string()
        .matches(/^\d{10,12}$/, "Mobile Number must be between 10 to 12 digits")
        .required("Please Enter Mobile Number "),
      address: Yup.string().required("Please Enter Address"),
      pincode: Yup.string()
        .matches(/^\d{6}$/, "Pincode must be a 6-digit number")
        .required("Please Enter Pincode"),
      city: Yup.string().required("Please Enter City"),
      state: Yup.string().required("Please Enter State"),
      country: Yup.string().required("Please Enter Country"),
      pan_no: Yup.string()
        .matches(
          /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
          "PAN Number must be in the format: ABCDE1234F"
        )
        .required("Please Enter PAN Number"),
      aadhar_no: Yup.string().matches(
        /^\d{12}$/,
        "Aadhar Number must be a 12-digit number"
      ),
      tan_no: Yup.string().matches(
        /^[A-Z]{4}\d{5}[A-Z]$/,
        "TAN Number must be in the format: XXXX12345X"
      ),
      gst_no: Yup.string().matches(
        /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[A-Z\d]{1}[A-Z\d]{1}$/,
        "GST Number must be in the format: 12ABCDE1234F1Z1"
      ),
      bank_name: Yup.string().required("Please Enter Bank Name"),
      account_no: Yup.string().required("Please Enter Account Number"),
      ifsc_code: Yup.string().required("Please Enter IFSC Code"),
      account_holder_name: Yup.string().required(
        "Please Enter Account Holder Name"
      ),
      login_username: Yup.string().required("Please Enter Login Username"),
      is_active: Yup.boolean(),
    }),
    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        const response = await addNewVendors(values)
        // const response = await addNewVendorsDocuments(1, values)
        if (response && response.status === true) {
          loadingSwal.close()

          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Vendor has been Added.",
          }).then(result => {
            if (result.isConfirmed) {
              navigate("/vendors")
            }
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "failure",
            icon: "error",
            text: "Your Vendor has not been saved.",
          })
        }
      } catch (error) {
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const navigate = useNavigate()

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const handleFileChange = event => {
    const file = event.target.files[0]
    setSelectedFile(file)
    if (typeof file === "object") {
      setImageURL(URL.createObjectURL(file))
    } else {
      setImageURL(file)
    }
  }

  useEffect(() => {
    if (selectedFile && typeof selectedFile === "object") {
      if (validation.touched.image && !imageTouched) {
        validation.setFieldValue("image", selectedFile)
        setImageTouched(true)
      }
    }
  }, [
    selectedFile,
    validation.touched.image,
    imageTouched,
    validation.setFieldValue,
  ])

  const handleRadio = event => {
    setSelectedOption(event.target.value)

    validation.setFieldValue("is_active", event.target.value)
  }

  const handleChange = e => {
    const { name, value, checked } = e.target
    if (name === "service_offering") {
      let updatedServiceOffering = [...validation.values.service_offering]
      if (checked) {
        updatedServiceOffering.push(value) // Add ID to the array if checked
      } else {
        updatedServiceOffering = updatedServiceOffering.filter(
          id => id !== value
        ) // Remove ID if unchecked
      }
      validation.setFieldValue("service_offering", updatedServiceOffering) // Update formik values directly
    } else {
      validation.handleChange(e) // For other fields, use default handleChange
    }
  }

  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState(validation.values.state)

  const [rows1, setrows1] = useState([{ id: 1 }])
  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows1(modifiedRows)
  }
  function handleRemoveRow(id) {
    if (rows1.length !== 1) {
      var modifiedRows = [...rows1]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows1(modifiedRows)
    } else
      Swal.fire({
        title: "failure",
        icon: "error",
        text: "Cannot delete the last Document.",
      })
  }

  const handleChangeVendor = (e, index) => {
    const { name, value } = e.target
    const updatedVendors = [...vendors]
    updatedVendors[index][name] = value
    setVendors(updatedVendors)
  }

  const handleDelete = index => {
    if (vendors.length > 1) {
      const updatedVendors = vendors.filter(vendor => vendor.id !== index)
      setVendors(updatedVendors)
    } else {
      // Optionally, you can display a message or handle the situation accordingly
      Swal.fire({
        title: "failure",
        icon: "error",
        text: "Cannot delete the last vendor.",
      })
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      // Fetch countries
      await dispatch(getCountries())

      // If country is selected (edit mode), fetch states and cities
      if (validation.values.country) {
        await dispatch(getStates(validation.values.country))
        if (validation.values.state) {
          await dispatch(getCities(validation.values.state))
        }
      }

      // Set initial values
      setCountryName(validation.values.country)
      setStateId(validation.values.state)
    }

    fetchData()
  }, [dispatch, validation.values.country, validation.values.state])

  const [activeTab, setactiveTab] = useState(1)

  useEffect(() => {
    dispatch(getVendorCode())
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <React.Fragment>
          <i
            className="mdi mdi-arrow-left font-size-20"
            style={{ color: "grey", marginRight: "20px" }}
            onClick={() => navigate(-1)}
          ></i>
          <span style={{ color: "black", fontWeight: "500", fontSize: "18px" }}>
            Add New Vendor
          </span>
        </React.Fragment>
        <div className="mt-3"></div>
        <Container fluid={true}>
          {/* <Breadcrumbs title="Setup" breadcrumbItem="Account Setup" /> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}

                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                            <label
                              className="rounded-2"
                              style={{
                                width: "150px",
                                display: "flex",
                                // padding: "30px",
                                alignItems: "center",
                                gap: "10px",
                                borderRadius: "10px",
                                border: "1px solid rgba(0, 0, 0, 0.10)",
                                flexDirection: "column",
                              }}
                            >
                              {!isEditMode ? (
                                <>
                                  {imageURL ? (
                                    <img
                                      className="object-fit-fill rounded-2"
                                      src={imageURL}
                                      alt=""
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        maxHeight: "150px",
                                        minHeight: "150px",
                                      }}
                                    />
                                  ) : (
                                    <i
                                      className="mdi mdi-image-outline"
                                      style={{
                                        fontSize: "60px",
                                        padding: "30px",
                                      }}
                                    ></i>
                                  )}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                </>
                              ) : (
                                <>
                                  {imageURL ? (
                                    <img
                                      className="object-fit-fill rounded-2"
                                      src={imageURL}
                                      alt=""
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        maxHeight: "150px",
                                        minHeight: "150px",
                                      }}
                                    />
                                  ) : (
                                    <i
                                      className="mdi mdi-image-outline"
                                      style={{
                                        fontSize: "60px",
                                        padding: "30px",
                                      }}
                                    ></i>
                                  )}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                </>
                              )}
                            </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="entity">Entity*</Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="entity"
                                value={validation.values.entity}
                                className={`form-select ${
                                  validation.touched.entity &&
                                  validation.errors.entity
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.entity && (
                                  <option>Enter Your Entity</option>
                                )}

                                <option value="Pvt Ltd/LTD/LLP">
                                  Pvt Ltd / LTD / LLP{" "}
                                </option>
                                <option value="Government">Government</option>
                                <option value="Partnership">Partnership</option>
                                <option value="Proprietorship">
                                  Proprietorship
                                </option>
                                <option value="Individual">Individual</option>
                              </select>
                              {validation.touched.city &&
                              validation.errors.city ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="vendor_name">Vendor Name*</Label>
                              <Input
                                type="text"
                                name="vendor_name"
                                className="form-control"
                                id="vendor_name"
                                placeholder="Enter Vendor Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.vendor_name || ""}
                                invalid={
                                  validation.touched.vendor_name &&
                                  validation.errors.vendor_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.vendor_name &&
                              validation.errors.vendor_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.vendor_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="vendor_code">Vendor Code*</Label>
                              <Input
                                type="text"
                                name="vendor_code"
                                className="form-control"
                                id="vendor_code"
                                placeholder="Enter Vendor Code"
                                // onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={vendorCode && vendorCode}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-2"></div>
                            <div>
                              <Label htmlFor="formrow-ispaid-Input">
                                Service Offering
                              </Label>
                            </div>

                            <Col lg="6">
                              {vendorServiceList?.map((option, index) => (
                                <div className="form-check" key={index}>
                                  <Input
                                    type="checkbox"
                                    className="form-check-Input"
                                    id={`service_offering_${index}`}
                                    name="service_offering"
                                    value={option.id}
                                    onClick={handleChange}
                                  />
                                  <Label
                                    className="form-check-Label"
                                    htmlFor={`service_offering_${index}`}
                                  >
                                    <h6>{option.service_name}</h6>
                                  </Label>
                                </div>
                              ))}
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="email_address">Email Address*</Label>
                              <Input
                                type="text"
                                name="email_address"
                                className="form-control"
                                id="email_address"
                                placeholder="Enter Vendor's Email Address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email_address || ""}
                                invalid={
                                  validation.touched.email_address &&
                                  validation.errors.email_address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email_address &&
                              validation.errors.email_address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email_address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="mobile">Mobile No.*</Label>
                              <Input
                                type="text"
                                name="mobile_no"
                                className="form-control"
                                id="mobile_no"
                                placeholder="Enter Vendor's Mobile Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobile_no || ""}
                                invalid={
                                  validation.touched.mobile_no &&
                                  validation.errors.mobile_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.mobile_no &&
                              validation.errors.mobile_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mobile_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="address">Address*</Label>
                              <Input
                                type="text"
                                name="address"
                                className="form-control"
                                id="address"
                                placeholder="Enter Vendor's Address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address &&
                              validation.errors.address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="pincode">Pincode*</Label>
                              <Input
                                type="text"
                                name="pincode"
                                className="form-control"
                                id="pincode"
                                placeholder="Enter Pincode"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pincode || ""}
                                invalid={
                                  validation.touched.pincode &&
                                  validation.errors.pincode
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.pincode &&
                              validation.errors.pincode ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pincode}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="country">Country*</Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                // defaultValue="IN"
                                value={validation.values.country}
                                name="country"
                                className={`form-select ${
                                  validation.touched.country &&
                                  validation.errors.country
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.country && (
                                  <option>Select Your Country</option>
                                )}

                                {(countries || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.country_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="formrow-state-Input">
                                State*
                              </Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="state"
                                value={validation.values.state}
                                className={`form-select ${
                                  validation.touched.state &&
                                  validation.errors.state
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.state && (
                                  <option>Enter Your States</option>
                                )}

                                {(states || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.state_name}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.state &&
                              validation.errors.state ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.state}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-city-Input">City*</Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="city"
                                value={validation.values.city}
                                className={`form-select ${
                                  validation.touched.city &&
                                  validation.errors.city
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.city && (
                                  <option>Enter Your City</option>
                                )}

                                {(cities || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.city_name}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.city &&
                              validation.errors.city ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Documents Identification
                        </CardTitle>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="pan_no">PAN no*</Label>
                              <Input
                                type="text"
                                name="pan_no"
                                className="form-control"
                                id="pan_no"
                                placeholder="Enter Customer's Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pan_no || ""}
                                invalid={
                                  validation.touched.pan_no &&
                                  validation.errors.pan_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.pan_no &&
                              validation.errors.pan_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pan_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="aadhar_no">Aadhar no</Label>
                              <Input
                                type="text"
                                name="aadhar_no"
                                className="form-control"
                                id="aadhar_no"
                                placeholder="Enter Customer's Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.aadhar_no || ""}
                                invalid={
                                  validation.touched.aadhar_no &&
                                  validation.errors.aadhar_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.aadhar_no &&
                              validation.errors.aadhar_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.aadhar_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="tan_no">TAN no</Label>
                              <Input
                                type="text"
                                name="tan_no"
                                className="form-control"
                                id="tan_no"
                                placeholder="Enter Customer's Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tan_no || ""}
                                invalid={
                                  validation.touched.tan_no &&
                                  validation.errors.tan_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.tan_no &&
                              validation.errors.tan_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tan_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="gst_no">GST no</Label>
                              <Input
                                type="text"
                                name="gst_no"
                                className="form-control"
                                id="gst_no"
                                placeholder="Enter Customer's Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gst_no || ""}
                                invalid={
                                  validation.touched.gst_no &&
                                  validation.errors.gst_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.gst_no &&
                              validation.errors.gst_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.gst_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Bank Details</CardTitle>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="bank_name">Bank Name*</Label>
                              <Input
                                type="text"
                                name="bank_name"
                                className="form-control"
                                id="bank_name"
                                placeholder="Enter Bank's Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.bank_name || ""}
                                invalid={
                                  validation.touched.bank_name &&
                                  validation.errors.bank_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.bank_name &&
                              validation.errors.bank_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.bank_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="ifsc_code">IFSC Code*</Label>
                              <Input
                                type="text"
                                name="ifsc_code"
                                className="form-control"
                                id="ifsc_code"
                                placeholder="Enter IFSC Code"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.ifsc_code || ""}
                                invalid={
                                  validation.touched.ifsc_code &&
                                  validation.errors.ifsc_code
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.ifsc_code &&
                              validation.errors.ifsc_code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.ifsc_code}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="account_no">Account No*</Label>
                              <Input
                                type="text"
                                name="account_no"
                                className="form-control"
                                id="account_no"
                                placeholder="Enter Account Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.account_no || ""}
                                invalid={
                                  validation.touched.account_no &&
                                  validation.errors.account_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.account_no &&
                              validation.errors.account_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.account_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="account_holder_name">
                                Account Holder Name*
                              </Label>
                              <Input
                                type="text"
                                name="account_holder_name"
                                className="form-control"
                                id="account_holder_name"
                                placeholder="Enter Account Holder's Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.account_holder_name || ""
                                }
                                invalid={
                                  validation.touched.account_holder_name &&
                                  validation.errors.account_holder_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.account_holder_name &&
                              validation.errors.account_holder_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.account_holder_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Sign in details</CardTitle>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="login_username">
                                Login Username*
                              </Label>
                              <Input
                                type="text"
                                name="login_username"
                                className="form-control"
                                id="login_username"
                                placeholder="Vendors Username"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.login_username || ""}
                                invalid={
                                  validation.touched.login_username &&
                                  validation.errors.login_username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.login_username &&
                              validation.errors.login_username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.login_username}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <Label htmlFor="is_active">Is Active*</Label>
                            <Col lg="6">
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  id="isActiveYes"
                                  name="is_active"
                                  value={1}
                                  onClick={e => {
                                    handleRadio(e)
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="isActiveYes"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <h6>Yes</h6>
                                </Label>
                              </div>
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  id="isActiveNo"
                                  name="is_active"
                                  value={0}
                                  defaultChecked={
                                    validation.values.is_active === 0
                                  }
                                  onClick={e => {
                                    handleRadio(e)
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="isActiveNo"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <h6>No</h6>
                                </Label>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Form>

                  <div className="actions clearfix">
                    <button
                      className="btn btn-primary"
                      onClick={validation.handleSubmit}
                    >
                      Save and Continue
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddNewVendor
