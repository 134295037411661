import {
  GET_CITY_FOR_CUSTOMER_INDEX_FAIL,
  GET_CITY_FOR_CUSTOMER_INDEX_SUCCESS,
  GET_CUSTOMER_INDEX_FAIL,
  GET_CUSTOMER_INDEX_SUCCESS,
  GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_FAIL,
  GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_SUCCESS,
  GET_STATE_FOR_CUSTOMER_INDEX_FAIL,
  GET_STATE_FOR_CUSTOMER_INDEX_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  customerIndex: [],
  projectFilter: [],
  state: [],
  city: [],
  //

  error: {},
  loading: true,
}

const CustomerIndex = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_INDEX_SUCCESS:
      return {
        ...state,
        customerIndex: action.payload,
        loading: true,
      }

    case GET_CUSTOMER_INDEX_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_SUCCESS:
      return {
        ...state,
        projectFilter: action.payload,
        loading: true,
      }

    case GET_PROJECT_FILTER_FOR_CUSTOMER_INDEX_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_STATE_FOR_CUSTOMER_INDEX_SUCCESS:
      return {
        ...state,
        state: action.payload,
        loading: true,
      }

    case GET_STATE_FOR_CUSTOMER_INDEX_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CITY_FOR_CUSTOMER_INDEX_SUCCESS:
      return {
        ...state,
        city: action.payload,
        loading: true,
      }

    case GET_CITY_FOR_CUSTOMER_INDEX_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default CustomerIndex
