/* LANDLORDS */
export const GET_LANDLORDS = "GET_LANDLORDS"
export const GET_LANDLORDS_SUCCESS = "GET_LANDLORDS_SUCCESS"
export const GET_LANDLORDS_FAIL = "GET_LANDLORDS_FAIL"

/* LANDLORDS DETAIL*/
export const GET_LANDLORD_DETAIL = "GET_LANDLORD_DETAIL"
export const GET_LANDLORD_DETAIL_SUCCESS = "GET_LANDLORD_DETAIL_SUCCESS"
export const GET_LANDLORD_DETAIL_FAIL = "GET_LANDLORD_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_LANDLORD = "ADD_NEW_LANDLORD"
 export const ADD_LANDLORD_SUCCESS = "ADD_LANDLORD_SUCCESS"
 export const ADD_LANDLORD_FAIL = "ADD_LANDLORD_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_LANDLORD = "UPDATE_LANDLORD"
 export const UPDATE_LANDLORD_SUCCESS = "UPDATE_LANDLORD_SUCCESS"
 export const UPDATE_LANDLORD_FAIL = "UPDATE_LANDLORD_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_LANDLORD = "DELETE_LANDLORD"
 export const DELETE_LANDLORD_SUCCESS = "DELETE_LANDLORD_SUCCESS"
 export const DELETE_LANDLORD_FAIL = "DELETE_LANDLORD_FAIL"

 //Active Landloard

 export const ACTIVE_LANDLORD = "ACTIVE_LANDLORD"
 export const ACTIVE_LANDLORD_SUCCESS = "ACTIVE_LANDLORD_SUCCESS"
 export const ACTIVE_LANDLORD_FAIL = "ACTIVE_LANDLORD_FAIL"

 //DOCUMENTS

 export const GET_DOCUMENT_LIST_LANDLORD = "GET_DOCUMENT_LIST_LANDLORD"
 export const GET_DOCUMENT_LIST_LANDLORD_SUCCESS = "GET_DOCUMENT_LIST_LANDLORD_SUCCESS"
 export const GET_DOCUMENT_LIST_LANDLORD_FAIL = "GET_DOCUMENT_LIST_LANDLORD_FAIL"

 export const ADD_DOCUMENT_LANDLORD= "ADD_DOCUMENT_LANDLORD"
 export const ADD_DOCUMENT_LANDLORD_SUCCESS= "ADD_DOCUMENT_LANDLORD_SUCCESS"
 export const ADD_DOCUMENT_LANDLORD_FAIL= "ADD_DOCUMENT_LANDLORD_FAIL"

 export const DELETE_DOCUMENT_LANDLORD="DELETE_DOCUMENT_LANDLORD"
 export const DELETE_DOCUMENT_LANDLORD_SUCCESS="DELETE_DOCUMENT_LANDLORD_SUCCESS"
 export const DELETE_DOCUMENT_LANDLORD_FAIL="DELETE_DOCUMENT_LANDLORD_FAIL"