import {
  GET_COUNT_FOR_DASHBOARD_FAIL,
  GET_COUNT_FOR_DASHBOARD_SUCCESS,
  GET_VACANT_PROJECT_UNIT_LIST_SUCCESS,
  GET_VACANT_PROJECT_UNIT_LIST_FAIL,
  ACTIVITY_FILTER_SUCCESS,
  ACTIVITY_FILTER_FAIL,
  NEW_CUSTOMER_LIST_SUCCESS,
  NEW_CUSTOMER_LIST_FAIL,
  BILL_AMOUNT_LIST_SUCCESS,
  BILL_AMOUNT_LIST_FAIL,
  PAYMENT_AMOUNT_LIST_SUCCESS,
  PAYMENT_AMOUNT_LIST_FAIL,
  NEW_UNIT_ASSIGNED_LIST_SUCCESS,
  NEW_UNIT_ASSIGNED_LIST_FAIL,
  NEW_CUSTOMER_GRAPH_SUCCESS,
  NEW_CUSTOMER_GRAPH_FAIL,
  NEW_UNITS_ASSIGNED_GRAPH_SUCCESS,
  NEW_UNITS_ASSIGNED_GRAPH_FAIL,
  BILL_AND_PAYMENT_GRAPH_SUCCESS,
  BILL_AND_PAYMENT_GRAPH_FAIL,
  CUSTOMER_OUTSTANTDING_LIST_SUCCESS,
  CUSTOMER_OUTSTANTDING_LIST_FAIL,
  VENDOR_OUTSTANDING_LIST_SUCCESS,
  VENDOR_OUTSTANDING_LIST_FAIL,
  VENDOR_PAYMENT_LIST_SUCCESS,
  VENDOR_PAYMENT_LIST_FAIL,
  VENDOR_TICKET_LIST_SUCCESS,
  VENDOR_TICKET_LIST_FAIL,
  NEW_VENDOR_LIST_SUCCESS,
  NEW_VENDOR_LIST_FAIL,
  VENDOR_INVOICE_LIST_FAIL,
  VENDOR_INVOICE_LIST_SUCCESS,
  STATUS_WISE_TICKET_LIST_SUCCESS,
  STATUS_WISE_TICKET_LIST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  count: [],
  vacantProjectUnitList: [],
  activityCount: [],
  newCustomerList: [],
  unitList: [],
  billAmountList: [],
  paymentAmountList: [],
  customerGraph: [],
  unitAssignedGraph: [],
  billPaymentGraph: [],
  //
  customerOutstandingList:[],
  vendorInvoiceList:[],
  vendorTicketList:[],
  vendorPaymentList:[],
  vendorOutstandingList:[],
  newVendorList:[],
  statusWiseTicketList:[],
  error: {},
  loading: true,
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNT_FOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        count: action.payload,
        loading: false,
        // loading: true
      }

    case GET_COUNT_FOR_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_VACANT_PROJECT_UNIT_LIST_SUCCESS:
      return {
        ...state,
        vacantProjectUnitList: action.payload,
        loading: false,
        // loading: true
      }

    case GET_VACANT_PROJECT_UNIT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ACTIVITY_FILTER_SUCCESS:
      return {
        ...state,
        activityCount: action.payload,
        loading: false,
        // loading: true
      }

    case ACTIVITY_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case NEW_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        newCustomerList: action.payload,
        loading: false,
        // loading: true
      }

    case NEW_CUSTOMER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case NEW_UNIT_ASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        unitList: action.payload,
        loading: false,
        // loading: true
      }

    case NEW_UNIT_ASSIGNED_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case BILL_AMOUNT_LIST_SUCCESS:
      return {
        ...state,
        billAmountList: action.payload,
        loading: false,
        // loading: true
      }

    case BILL_AMOUNT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case PAYMENT_AMOUNT_LIST_SUCCESS:
      return {
        ...state,
        paymentAmountList: action.payload,
        loading: false,
        // loading: true
      }

    case PAYMENT_AMOUNT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case PAYMENT_AMOUNT_LIST_SUCCESS:
      return {
        ...state,
        paymentAmountList: action.payload,
        loading: false,
        // loading: true
      }

    case PAYMENT_AMOUNT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case NEW_CUSTOMER_GRAPH_SUCCESS:
      return {
        ...state,
        customerGraph: action.payload,
        loading: false,
        // loading: true
      }

    case NEW_CUSTOMER_GRAPH_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case NEW_UNITS_ASSIGNED_GRAPH_SUCCESS:
      return {
        ...state,
        unitAssignedGraph: action.payload,
        loading: false,
        // loading: true
      }

    case NEW_UNITS_ASSIGNED_GRAPH_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case BILL_AND_PAYMENT_GRAPH_SUCCESS:
      return {
        ...state,
        billPaymentGraph: action.payload,
        loading: false,
        // loading: true
      }

    case BILL_AND_PAYMENT_GRAPH_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //dashboard
    case CUSTOMER_OUTSTANTDING_LIST_SUCCESS:
      return {
        ...state,
        customerOutstandingList: action.payload,
        loading: false,
      }

    case CUSTOMER_OUTSTANTDING_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case NEW_VENDOR_LIST_SUCCESS:
      return {
        ...state,
        newVendorList: action.payload,
        loading: false,
      }

    case NEW_VENDOR_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case VENDOR_OUTSTANDING_LIST_SUCCESS:
      return {
        ...state,
        vendorOutstandingList: action.payload,
        loading: false,
      }

    case VENDOR_OUTSTANDING_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case VENDOR_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        vendorPaymentList: action.payload,
        loading: false,
      }

    case VENDOR_INVOICE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case VENDOR_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        vendorInvoiceList: action.payload,
        loading: false,
      }

    case VENDOR_PAYMENT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case VENDOR_TICKET_LIST_SUCCESS:
      return {
        ...state,
        vendorTicketList: action.payload,
        loading: false,
      }

    case VENDOR_TICKET_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case STATUS_WISE_TICKET_LIST_SUCCESS:
      return {
        ...state,
        statusWiseTicketList: action.payload,
        loading: false,
      }

    case STATUS_WISE_TICKET_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Dashboard
