import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Label,
  Input,
  navigate,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import VLogo from "../../../assets/images/VIP/VLogo.png"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import { getMaintainenceDetail } from "store/masters/maintainence/actions"
import {
  addNewMaintainence,
  updateMaintainence,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

const NewMaintenenceService = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const dispatch = useDispatch()
  const maintainenceState = state => state.maintainences.maintainenceDetail.Data
  const data = useSelector(maintainenceState)


  const maintainenceProperties = createSelector(
    maintainenceState,
    // loadingState,
    (maintainences, loading) => {
      return {
        maintainences: maintainences,
        loading: loading,
      }
    }
  )

  const { maintainences, loading } = useSelector(maintainenceProperties)

  const [specificUser, setSpecificUser] = useState(null)

  const [maintainenceId, setMaintainenceId] = useState()

  const maintainenceData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const maintainenceId = url.pathname.split(
        "/edit-maintenence-master-service/"
      )[1]
      setMaintainenceId(maintainenceId)
      // const response =  dispatch(getCompanyDetail(facilityId))
      // if(isEditMode === true){
      dispatch(getMaintainenceDetail(maintainenceId))
      // } else {
      //   //don't dispatch or reset form
      //   setIsEditMode(false)
      // }

      // setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      maintainenceData()
    }
  }, [dispatch])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          issue_name: (maintainences && maintainences.issue_name) || "",
          is_active: (maintainences && maintainences.is_active) || false,
          // is_active: false,
        }
      : {
          issue_name: "",
          is_active: false,
        },
    validationSchema: Yup.object({
      issue_name: Yup.string().required("Please Enter Maintenance title"),
      // is_active: Yup.boolean().required("Please Select Valid Option"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        // Assuming addNewVendor returns a Promise
        // const response =  dispatch(addNewVendor(values));
        // let response
        if (isEditMode === true) {
          // const updatedResponse = await dispatch(onUpdateVendor(vendorId,values))
          const response = await updateMaintainence(maintainenceId, values)
          // response2 = onUpdateVendor(vendorId,values)

          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Maintainence Issue has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/maintenence-service")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has been saved.",
            })
          }
        } else if (isEditMode === false) {
          // const response = await dispatch(addNewVendor(values));
          const response = await addNewMaintainence(values)
          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Maintainence Issue has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/maintenence-service")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Maintainence Issue has not been saved.",
            })
          }


        }
      } catch (error) {
        // Handle the error if needed
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const navigate = useNavigate()
  const toggleToast = () => {
    setToast(!toast)
  }
  const toggleToast2 = () => {
    setToast2(!toast2)
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  useEffect(() => {
    //check if the current path includes 'edit-maintenence'
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)
    setToast2(true)
  }

  const [selectedOption, setSelectedOption] = useState(maintainences?.is_active)

  const handleChange1 = () => {
    const newValue = !selectedOption
    setSelectedOption(newValue)
    validation.setFieldValue("is_active", newValue)
  }

  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {" "}
              {isEditMode ? "Edit Maintenance Issue" : "New Maintenance Issue"}
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Settings / Master configuration / Maintenance Issue Master" />
        </Col>
      </Row>
      <div className="mt-3"></div>

      <Card>
        <CardBody>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-newmaintenence-Input">
                    Maintenance Issue Title*
                  </Label>
                  <Input
                    type="text"
                    name="issue_name"
                    className="form-control"
                    id="formrow-newmaintenence-Input"
                    placeholder="Enter Maintenance title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.issue_name || ""}
                    invalid={
                      validation.touched.issue_name &&
                      validation.errors.issue_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.issue_name &&
                  validation.errors.issue_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.issue_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div>
                  <div className="mb-3"></div>
                  <Label htmlFor="formrow-isactive-Input">Is Active</Label>
                </div>

                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="is_active"
                      name="is_active"
                      onChange={e => {
                        validation.setFieldValue("is_active", e.target.checked)
                      }}
                      checked={Boolean(validation?.values?.is_active)}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      Yes
                    </Label>
                  </div>
                  {validation.touched.is_active &&
                    validation.errors.is_active && (
                      <FormFeedback type="invalid">
                        {validation.errors.is_active}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-md">
                    {isEditMode ? "Edit" : "Submit"}
                  </button>
                </div>
                {toast2 && (
                  <div
                    className="position-fixed top-0 end-0 p-3"
                    style={{ zIndex: "1005" }}
                  >
                    <Toast isOpen={toast2} role="aleart">
                      <ToastHeader toggle={() => setToast2(!toast2)}>
                        <img src={VLogo} alt="" className="me-2" height="18" />
                        <strong className="me-auto">VIP Mall</strong>
                      </ToastHeader>
                      <ToastBody>
                        {!isEditMode
                          ? "Submitted Successfully"
                          : "Edited Successfully"}
                      </ToastBody>
                    </Toast>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default NewMaintenenceService
