import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Label,
  Input,
  navigate,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import VLogo from "../../../assets/images/VIP/VLogo.png"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import { getComplianceDetail, getProjectCompliance } from "store/actions"
import { addNewCompliance, updateCompliance } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"
const NewProjectService = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const dispatch = useDispatch()
  const complianceState = state => state.compliances.complianceDetail.data
  // const loadingState = state => state.facilities.loading
  const ProjectcomplianceState = state => state.compliances.compliances.data

  const data = useSelector(ProjectcomplianceState)
 
  const Projectcompliances = useSelector(complianceState) || []

  useEffect(() => {
    const response = dispatch(getProjectCompliance())
  }, [])
  const compliancesProperties = createSelector(
    complianceState,
    (compliances, loading) => {
      return {
        compliances: compliances,
        loading: loading,
      }
    }
  )

  const { compliances, loading } = useSelector(compliancesProperties)

  const [specificUser, setSpecificUser] = useState(null)

  const [complianceId, setComplianceId] = useState()

  const complianceData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const complianceId = url.pathname.split("/edit-project-service/")[1]
      setComplianceId(complianceId)
      // const response =  dispatch(getCompanyDetail(facilityId))
      // if(isEditMode === true){
      dispatch(getComplianceDetail(complianceId))
      // } else {
      //   //don't dispatch or reset form
      //   setIsEditMode(false)
      // }

      // setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      complianceData()
    }
  }, [dispatch])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          compliance_title: (compliances && compliances.compliance_title) || "",
          is_dependant: (compliances && compliances.is_dependant) || false,
          dependent_compliance:
            (compliances && compliances.dependent_compliance) || "",
          is_active: (compliances && compliances.is_active) || false,
        }
      : {
          compliance_title: "",
          is_dependant: false,
          dependent_compliance: "",
          is_active: false,
        },
    validationSchema: Yup.object({
      compliance_title: Yup.string().required("Please Enter Compliance title"),
      // dependent_compliance: Yup.boolean().required("Please Select Valid Option"),
      is_dependant: Yup.boolean(),
      dependent_compliance: Yup.string(),
      // dependent_compliance: Yup.string().required("Please Enter Dependent Compliance"),
      // is_active: Yup.boolean().required("Please Select Valid Option"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        // Assuming addNewVendor returns a Promise
        // const response =  dispatch(addNewVendor(values));
        // let response
        if (isEditMode === true) {
          const response = await updateCompliance(complianceId, values)

          if (response && response.status === true) {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Project Compliance Master has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/project-service")
              }
            })
          } else {
            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Project Compliance Master has not been saved.",
            })
          }
        } else if (isEditMode === false) {
          const response = await addNewCompliance(values)
          if (response && response.status === true) {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Project Compliance Master has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/project-service")
              }
            })
          } else {
            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Project Compliance Master has not been saved.",
            })
          }


        }
      } catch (error) {
        // Handle the error if needed
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const [isChecked, setChecked] = useState(false)
  const navigate = useNavigate()
  const toggleToast = () => {
    setToast(!toast)
  }
  const toggleToast2 = () => {
    setToast2(!toast2)
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const handleCheckboxChange = () => {
    setChecked(!isChecked)
  }
  useEffect(() => {
    // Check if the current path includes 'edit-project'
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)
    setToast2(true)
  }

  const [selectedOption, setSelectedOption] = useState(compliances?.is_active)
  const [selectedOptionNew, setSelectedOptionNew] = useState(
    compliances?.is_dependant
  )

  const handleChange1 = () => {
    const newValue = !selectedOption
    setSelectedOption(newValue)
    validation.setFieldValue("is_active", newValue)
  }
  const handleChange2 = () => {
    const isDependantValue = !selectedOptionNew
    setSelectedOptionNew(isDependantValue)
    validation.setFieldValue("is_dependant", isDependantValue)
  }
  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {isEditMode
                ? "Edit Project Compliance Master"
                : "New Project Compliance Master"}
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Settings / Master configuration / Project Compliance Master" />
        </Col>
      </Row>
      <div className="mt-3"></div>

      {/* <CardTitle className="mb-4">New Project Compliance</CardTitle> */}
      <Card>
        <CardBody>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-newexpense-Input">
                    Compliance Title*
                  </Label>
                  <Input
                    type="text"
                    name="compliance_title"
                    className="form-control"
                    id="formrow-newexpense-Input"
                    placeholder="Enter Compliance title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.compliance_title || ""}
                    invalid={
                      validation.touched.compliance_title &&
                      validation.errors.compliance_title
                        ? true
                        : false
                    }
                  />
                  {validation.touched.compliance_title &&
                  validation.errors.compliance_title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.compliance_title}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div>
                  <div className="mb-3"></div>
                  <Label htmlFor="formrow-isDependent-Input">
                    Is Dependent
                  </Label>
                </div>

                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      // className="form-check-Input"
                      id="is-dependent"
                      name="is_dependant"
                      onClick={handleChange2}
                      onBlur={validation.handleBlur}
                      // value={validation.values.is_dependant || false}
                      checked={validation.values.is_dependant}
                      className={`form-check-Input ${
                        validation.touched.is_dependant &&
                        validation.errors.is_dependant
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      Yes
                    </Label>
                  </div>
                  {validation.touched.is_dependant &&
                    validation.errors.is_dependant && (
                      <FormFeedback type="invalid">
                        {validation.errors.is_dependant}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
            </Row>
            <Row>
              {validation.values.is_dependant && (
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="formrow-newexpense-Input">
                      Dependent Compliance
                    </Label>
                    <select
                      name="dependent_compliance"
                      // defaultValue="0"
                      // className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.dependent_compliance || ""}
                      className={`form-select ${
                        validation.touched.dependent_compliance &&
                        validation.errors.dependent_compliance
                          ? "is-invalid"
                          : ""
                      }`}
                    >
                      {!validation.values.dependent_compliance && (
                        <option>Select Compliance</option>
                      )}
                      {/* <option value="0" disabled>Select Compliance </option> */}
                      {data.map(compliance => (
                        <option
                          key={compliance.id}
                          value={compliance.compliance_title}
                        >
                          {compliance.compliance_title}
                        </option>
                      ))}
                    </select>
                    {validation.touched.dependent_compliance &&
                    validation.errors.dependent_compliance ? (
                      <FormFeedback type="invalid">
                        {validation.errors.dependent_compliance}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              )}
              <Col>
                <div>
                  <div className="mb-3"></div>
                  <Label htmlFor="formrow-isactive-Input">Is Active</Label>
                </div>

                <Col lg="12">
                  <div className="form-check form-check-inline">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="is_active"
                      name="is_active"
                      onChange={e => {
                        validation.setFieldValue("is_active", e.target.checked)
                      }}
                      checked={Boolean(validation?.values?.is_active)}
                    />
                    <Label
                      className="form-check-Label"
                      htmlFor="formrow-customCheck"
                    >
                      Yes
                    </Label>
                  </div>
                  {validation.touched.is_active &&
                    validation.errors.is_active && (
                      <FormFeedback type="invalid">
                        {validation.errors.is_active}
                      </FormFeedback>
                    )}
                </Col>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-md">
                    {isEditMode ? "Edit" : "Submit"}
                  </button>
                </div>
                {toast2 && (
                  <div
                    className="position-fixed top-0 end-0 p-3"
                    style={{ zIndex: "1005" }}
                  >
                    <Toast isOpen={toast2} role="aleart">
                      <ToastHeader toggle={() => setToast2(!toast2)}>
                        <img src={VLogo} alt="" className="me-2" height="18" />
                        <strong className="me-auto">VIP Mall</strong>
                      </ToastHeader>
                      <ToastBody>
                        {!isEditMode
                          ? "Submitted Successfully"
                          : "Edited Successfully"}
                      </ToastBody>
                    </Toast>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default NewProjectService
