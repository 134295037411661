import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import Swal from "sweetalert2"
import { useFormik } from "formik"
// import altImg from "../../assets/images/VIP/add_photo_alternate.png"
// import VLogo from "../../assets/images/VIP/VLogo.png"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Toast,
  ToastHeader,
  ToastBody,
  navigate,
  FormFeedback,
} from "reactstrap"

import classnames from "classnames"
import { Link, useNavigate } from "react-router-dom"
//Import Breadcrumb
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
// import { addNewCompany, getCompanies, getCompanyDetails, updateCompany } from "helpers/fakebackend_helper"
import {
  getCompanies,
  getCompanyDetail,
  getCompanyLocationDetail,
} from "../../../store/company/actions"
import {
  addCompanyLocation,
  addNewCompany,
  updateCompany,
  updateCompanyLocation,
} from "helpers/fakebackend_helper"
import { getCities, getCountries, getStates } from "store/actions"
import { error } from "toastr"

const EditCompanyLocation = () => {
  const [toast2, setToast2] = useState(false)
  const [companyId, setCompanyId] = useState()
  const [isEditMode, setIsEditMode] = useState(false)

  const dispatch = useDispatch()
  const companyDetail = state => state.companies.locationDetail.data
  const company = useSelector(companyDetail)

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const CompanyProperties = createSelector(companyDetail, getCompanyDetail => ({
    error: getCompanyDetail.error,
  }))

  const companyData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const companyId = url.pathname.split("/edit-company-location/")[1]
      setCompanyId(companyId)
      // const response =  dispatch(getCompanyDetail(companyId))
      dispatch(getCompanyLocationDetail(companyId))

      // setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  console.log(companyId)
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      companyData()
    }
    dispatch(getCountries())
  }, [dispatch])

  const [logoFile, setLogoFile] = useState(company?.logo) // State to manage the selected logo file
  const [newUserImage, setNewUserImage] = useState(null)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          //TabPane 1

          company_id: (company && company.company_id) || "",
          location_title: (company && company.location_title) || "",
          country_id: (company && company.country_id) || "IN",
          state_id: (company && company.state_id) || "",
          city_id: (company && company.city_id) || "",
          contact_phone: (company && company.contact_phone) || "",
          account_email: (company && company.account_email) || "",
          //

          gst_no: (company && company.gst_no) || "",
       
          bank_name: (company && company.bank_name) || "",
          bank_branch: (company && company.bank_branch) || "",
          bank_account_no: (company && company.bank_account_no) || "",
          bank_ifsc: (company && company.bank_ifsc) || "",

          //TabPane 2
          bill_prefix: (company && company.bill_prefix) || "",
          bill_starting_no: (company && company.bill_starting_no) || "",
          receipt_starting_no: (company && company.receipt_starting_no) || "",
          expense_voucher_prefix:
            (company && company.expense_voucher_prefix) || "",
          expense_voucher_starting_no:
            (company && company.expense_voucher_starting_no) || "",
          payment_voucher_prefix:
            (company && company.payment_voucher_prefix) || "",
          payment_voucher_starting_no:
            (company && company.payment_voucher_starting_no) || "",
          receipt_prefix: (company && company.receipt_prefix) || "",
          receipt_starting_no: (company && company.receipt_starting_no) || "",
          credit_note_prefix: (company && company.credit_note_prefix) || "",
          credit_note_starting_no: (company && company.credit_note_starting_no) || "",

          //TabPane 3
          api_username: (company && company.api_username) || "",
          api_password: (company && company.api_password) || "",

          //TabPane 4
          gst_filing_r1_frequency: company && company.gst_filing_r1_frequency,
          gst_filing_3b_payment_frequency:
            company && company.gst_filing_3b_payment_frequency,
          gst_filing_3b_frequency: company && company.gst_filing_3b_frequency,
        }
      : {
          // TabPane 1
          company_id: companyId || "",
          location_title: "",
          country_id: "",
          state_id: "",
          city_id: "",

          gst_no: "",

          bank_name: "",
          bank_branch: "",
          bank_account_no: "",
          bank_ifsc: "",

          //TabPane 2
          bill_prefix: "",
          bill_starting_no: "",
          receipt_starting_no: "",
          expense_voucher_prefix: "",
          expense_voucher_starting_no: "",
          payment_voucher_prefix: "",
          payment_voucher_starting_no: "",
          receipt_prefix: "",
          receipt_starting_no: "",
          credit_note_prefix: "",
          credit_note_starting_no: "",

          //TabPane 3
          api_username: "",
          api_password: "",

          
        },

    validationSchema: Yup.object({
      location_title: Yup.string().required("Please Enter Address"),

      country_id: Yup.string().required("Please Select Country"),
      state_id: Yup.number().required("Please Select State"),
      city_id: Yup.number().required("Please Select City"),
      // pin_code: Yup.number().required("Please Enter Pin code").min(10000,).max(999999),
      contact_phone: Yup.string()
      .matches(
        /^[0-9]{10,12}$/,
        "Contact Number should be between 10 and 12 digits and only contain numbers"
      )
      .required("Please Enter Contact Number"),
    account_email: Yup.string()
      .email("Invalid email format")
      .required("Please Enter Email Id"),
      gst_no: Yup.string()
        .matches(
          /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/,
          "Invalid GST number"
        )
        .required("Please Enter Gst Number"),

      bank_name: Yup.string().required("Please Enter Bank Name"),
      bank_branch: Yup.string().required("Please Enter Bank Branch"),
      bank_account_no: Yup.number().required(
        "Please Enter Bank Account Number"
      ),
      bank_ifsc: Yup.string().required("Please Enter Bank IFSC Code"),
      //table 3
      bill_prefix: Yup.string(),
      bill_starting_no: Yup.number(),
      expense_voucher_prefix: Yup.string(),
      expense_voucher_starting_no: Yup.number(),
      payment_voucher_prefix: Yup.string(),
      payment_voucher_starting_no: Yup.number(),
      receipt_prefix: Yup.string(),
      receipt_starting_no: Yup.number(),
      //table 4
      api_username: Yup.string(),
      api_password: Yup.string(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        if (isEditMode === true) {
          const response = await updateCompanyLocation(companyId, values)

          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "You Have Succesfully Updated Location to Your Company.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/companies")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "There was an Issue While Updating Location to Company.",
            })
          }
        } else if (isEditMode === false) {
          const response = await addCompanyLocation(values)
          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "You Have Succesfully Updated Location to Your Company.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/companies")
              }
            })
          } else {
            Swal.fire({
              title: "failure",
              icon: "error",
              text: "There was an Issue While Updating Location to Company.",
            })
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your Location.",
        })
      }
    },
  })

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState("")
  useEffect(() => {
    dispatch(getStates(countryName))
  }, [countryName])
  useEffect(() => {
    if (validation.values.state_id) {
      dispatch(getCities(stateId))
    }
  }, [stateId])
  useEffect(() => {
    setLogoFile(company?.logo)
  }, [company])

  useEffect(() => {
    setCountryName(validation.values.country_id)
    setStateId(validation.values.state_id)
  }, [validation])

  const navigate = useNavigate()
  const toggleToast = () => {
    setToast(!toast)
  }
  const toggleToast2 = () => {
    setToast2(!toast2)
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  //meta title
  document.title = "Edit Company Location"

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const handleNewUserImageChange = event => {
    const file = event.target.files[0]

    if (!file) {
      setNewUserImage(null)
      return
    }

    setNewUserImage(file)

    validation.setFieldValue("logo", file)

    if (file) {
      const reader = new FileReader()

      reader.onloadend = () => {
        const fileData = reader.result
        setLogoFile(fileData)
      }

      reader.readAsDataURL(file)
    }
  }

  const handleLogoFileChange = event => {
    const file = event.target.files[0]

    if (!file) {
      setLogoFile(null)
      return
    }

    setLogoFile(file)

    validation.setFieldValue("logo", file)

    if (file) {
      const reader = new FileReader()

      reader.onloadend = () => {
        const fileData = reader.result
        setLogoFile(fileData)
      }

      reader.readAsDataURL(file)
    }
  }

  const [selectedOption1, setSelectedOption1] = useState(
    !isEditMode ? "Monthly" : company?.gst_filing_r1_frequency?.toString()
  )
  const [selectedOption2, setSelectedOption2] = useState(
    !isEditMode
      ? "Monthly"
      : company?.gst_filing_3b_payment_frequency?.toString()
  )
  const [selectedOption3, setSelectedOption3] = useState(
    !isEditMode ? "Monthly" : company?.gst_filing_3b_frequency?.toString()
  )

  const handleChange1 = event => {
    setSelectedOption1(event.target.value)

    validation.setFieldValue("gst_filing_r1_frequency", event.target.value)
  }

  const handleChange2 = event => {
    setSelectedOption2(event.target.value)
    validation.setFieldValue(
      "gst_filing_3b_payment_frequency",
      event.target.value
    )
  }

  const handleChange3 = event => {
    setSelectedOption3(event.target.value)
    validation.setFieldValue("gst_filing_3b_frequency", event.target.value)
  }

  const handleNextTab = () => {
    validation.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        toggleTab(activeTab + 1)
      } else {
        validation.setErrors(errors)
        const touchedFields = Object.keys(validation.values).reduce(
          (acc, fieldName) => {
            acc[fieldName] = true
            return acc
          },
          {}
        )
        validation.setTouched(touchedFields)
      }
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <React.Fragment>
          <i
            className="mdi mdi-arrow-left font-size-20"
            style={{ color: "grey", marginRight: "20px" }}
            onClick={() => navigate(-1)}
          ></i>
          <span style={{ color: "black", fontWeight: "500", fontSize: "18px" }}>
            Edit Company Location
          </span>
        </React.Fragment>
        <div className="mt-3"></div>
        <Container fluid={true}>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Company financial
                            Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Company Prefixes
                            Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> E-Invoice Login
                            Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">4.</span> GST Filing
                            Details
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <div>
                            <Form
                              onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                              }}
                            >
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-address">
                                      Address*
                                    </Label>
                                    <Input
                                      type="text"
                                      id="location_title"
                                      name="location_title"
                                      className="form-control "
                                      placeholder="Enter Your Address"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.location_title &&
                                        validation.errors.location_title
                                          ? true
                                          : false
                                      }
                                      value={
                                        validation.values.location_title || ""
                                      }
                                    />
                                    {validation.touched.location_title &&
                                    validation.errors.location_title ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.location_title}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="country_id">Country*</Label>
                                    <select
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      // defaultValue="IN"
                                      value={validation.values.country_id}
                                      name="country_id"
                                      className={`form-select ${
                                        validation.touched.country_id &&
                                        validation.errors.country_id
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    >
                                      {!validation.values.country_id && (
                                        <option>Select Your Country</option>
                                      )}

                                      {(countries || []).map(item => (
                                        <option key={item.id} value={item.id}>
                                          {item.country_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="stateno-input">State*</Label>
                                    <select
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      name="state_id"
                                      value={validation.values.state_id}
                                      className={`form-select ${
                                        validation.touched.state_id &&
                                        validation.errors.state_id
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    >
                                      {!validation.values.state_id && (
                                        <option>Select Your States</option>
                                      )}

                                      {(states || []).map(item => (
                                        <option key={item.id} value={item.id}>
                                          {item.state_name}
                                        </option>
                                      ))}
                                    </select>
                                    {validation.touched.state_id &&
                                    validation.errors.state_id ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.state_id}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-email-input4">
                                      City*
                                    </Label>
                                    <select
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      name="city_id"
                                      value={validation.values.city_id}
                                      className={`form-select ${
                                        validation.touched.city_id &&
                                        validation.errors.city_id
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    >
                                      {!validation.values.city_id && (
                                        <option>Select Your City</option>
                                      )}

                                      {(cities || []).map(item => (
                                        <option key={item.id} value={item.id}>
                                          {item.city_name}
                                        </option>
                                      ))}
                                    </select>
                                    {validation.touched.city_id &&
                                    validation.errors.city_id ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.city_id}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-pancard-input5">
                                      GST No.*
                                    </Label>
                                    <Input
                                      type="text"
                                      name="gst_no"
                                      className="form-control"
                                      id="basicpill-pancard-input5"
                                      placeholder="Enter Your GST No."
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.gst_no || ""}
                                      invalid={
                                        validation.touched.gst_no &&
                                        validation.errors.gst_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.gst_no &&
                                    validation.errors.gst_no ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.gst_no}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-companyuin-input11">
                                      Bank Name
                                    </Label>
                                    <Input
                                      type="text"
                                      name="bank_name"
                                      className="form-control"
                                      id="basicpill-companyuin-input11"
                                      placeholder="Your Business Bank Name"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.bank_name || ""}
                                      invalid={
                                        validation.touched.bank_name &&
                                        validation.errors.bank_name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.bank_name &&
                                    validation.errors.bank_name ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.bank_name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>

                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input1">
                                      Bank branch
                                    </Label>
                                    <Input
                                      type="text"
                                      name="bank_branch"
                                      className="form-control"
                                      id="basicpill-Declaration-input1"
                                      placeholder="Enter Bank Branch Name"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.bank_branch || ""
                                      }
                                      invalid={
                                        validation.touched.bank_branch &&
                                        validation.errors.bank_branch
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.bank_branch &&
                                    validation.errors.bank_branch ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.bank_branch}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-companyuin-input9">
                                      Bank Account No.
                                    </Label>
                                    <Input
                                      type="text"
                                      name="bank_account_no"
                                      className="form-control"
                                      id="basicpill-companyuin-input9"
                                      placeholder="Account No."
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.bank_account_no || ""
                                      }
                                      invalid={
                                        validation.touched.bank_account_no &&
                                        validation.errors.bank_account_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.bank_account_no &&
                                    validation.errors.bank_account_no ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.bank_account_no}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>

                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-declaration-input2">
                                      Bank IFSC
                                    </Label>
                                    <Input
                                      type="text"
                                      name="bank_ifsc"
                                      className="form-control"
                                      id="basicpill-Declaration-input2"
                                      placeholder="IFSC Code"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.bank_ifsc || ""}
                                      invalid={
                                        validation.touched.bank_ifsc &&
                                        validation.errors.bank_ifsc
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.bank_ifsc &&
                                    validation.errors.bank_ifsc ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.bank_ifsc}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="contact_phone-input3">
                                    Contact No.*
                                  </Label>
                                  <Input
                                    type="text"
                                    name="contact_phone"
                                    className="form-control"
                                    id="contact_phone-input3"
                                    placeholder="Enter Your Contact No."
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.contact_phone || ""
                                    }
                                    invalid={
                                      validation.touched.contact_phone &&
                                      validation.errors.contact_phone
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.contact_phone &&
                                  validation.errors.contact_phone ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.contact_phone}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="Email-input3">
                                    Account Email Id*
                                  </Label>
                                  <Input
                                    type="text"
                                    name="account_email"
                                    className="form-control"
                                    id="Email-input3"
                                    placeholder="example@mail.com"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.account_email || ""
                                    }
                                    invalid={
                                      validation.touched.account_email &&
                                      validation.errors.account_email
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.account_email &&
                                  validation.errors.account_email ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.account_email}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form
                              onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                              }}
                            >
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                      Bill Prefix
                                    </Label>
                                    <Input
                                      type="text"
                                      name="bill_prefix"
                                      className="form-control"
                                      id="bill-prefix"
                                      placeholder="Bill prefix"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.bill_prefix || ""
                                      }
                                      invalid={
                                        validation.touched.bill_prefix &&
                                        validation.errors.bill_prefix
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.bill_prefix &&
                                    validation.errors.bill_prefix ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.bill_prefix}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                      Bill Starting no
                                    </Label>
                                    <Input
                                      type="text"
                                      name="bill_starting_no"
                                      className="form-control"
                                      id="bill-starting-no"
                                      placeholder="Bill Starting no"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.bill_starting_no || ""
                                      }
                                      invalid={
                                        validation.touched.bill_starting_no &&
                                        validation.errors.bill_starting_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.bill_starting_no &&
                                    validation.errors.bill_starting_no ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.bill_starting_no}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                      Expense Voucher Prefix
                                    </Label>
                                    <Input
                                      type="text"
                                      name="expense_voucher_prefix"
                                      className="form-control"
                                      id="expense-voucher"
                                      placeholder="Expense Voucher Prefix"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .expense_voucher_prefix || ""
                                      }
                                      invalid={
                                        validation.touched
                                          .expense_voucher_prefix &&
                                        validation.errors.expense_voucher_prefix
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched
                                      .expense_voucher_prefix &&
                                    validation.errors.expense_voucher_prefix ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors
                                            .expense_voucher_prefix
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                      Expense Voucher Starting no.
                                    </Label>
                                    <Input
                                      type="text"
                                      name="expense_voucher_starting_no"
                                      className="form-control"
                                      id="expense-starting"
                                      placeholder="Expense Voucher Starting no "
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .expense_voucher_starting_no || ""
                                      }
                                      invalid={
                                        validation.touched
                                          .expense_voucher_starting_no &&
                                        validation.errors
                                          .expense_voucher_starting_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched
                                      .expense_voucher_starting_no &&
                                    validation.errors
                                      .expense_voucher_starting_no ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors
                                            .expense_voucher_starting_no
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-cardno-input12">
                                      Payment Voucher Prefix
                                    </Label>
                                    <Input
                                      type="text"
                                      name="payment_voucher_prefix"
                                      className="form-control"
                                      id="payment-voucher"
                                      placeholder="Payment Voucher Prefix"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .payment_voucher_prefix || ""
                                      }
                                      invalid={
                                        validation.touched
                                          .payment_voucher_prefix &&
                                        validation.errors.payment_voucher_prefix
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched
                                      .payment_voucher_prefix &&
                                    validation.errors.payment_voucher_prefix ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors
                                            .payment_voucher_prefix
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-cardno-input12">
                                      Payment Voucher Starting no.
                                    </Label>
                                    <Input
                                      type="text"
                                      name="payment_voucher_starting_no"
                                      className="form-control"
                                      id="payment-starting"
                                      placeholder="Payment Voucher Starting no"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .payment_voucher_starting_no || ""
                                      }
                                      invalid={
                                        validation.touched
                                          .payment_voucher_starting_no &&
                                        validation.errors
                                          .payment_voucher_starting_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched
                                      .payment_voucher_starting_no &&
                                    validation.errors
                                      .payment_voucher_starting_no ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validation.errors
                                            .payment_voucher_starting_no
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-card-verification-input0">
                                      Receipt Prefix
                                    </Label>
                                    <Input
                                      type="text"
                                      name="receipt_prefix"
                                      className="form-control"
                                      id="receipt-prefix"
                                      placeholder="Receipt Prefix"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.receipt_prefix || ""
                                      }
                                      invalid={
                                        validation.touched.receipt_prefix &&
                                        validation.errors.receipt_prefix
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.receipt_prefix &&
                                    validation.errors.receipt_prefix ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.receipt_prefix}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-card-verification-input0">
                                      Receipt Starting no.
                                    </Label>
                                    <Input
                                      type="text"
                                      name="receipt_starting_no"
                                      className="form-control"
                                      id="receipt-starting"
                                      placeholder="Receipt Prefix Starting no"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.receipt_starting_no ||
                                        ""
                                      }
                                      invalid={
                                        validation.touched
                                          .receipt_starting_no &&
                                        validation.errors.receipt_starting_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.receipt_starting_no &&
                                    validation.errors.receipt_starting_no ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.receipt_starting_no}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="credit_note_prefix">
                                      Credit Note Prefix
                                    </Label>
                                    <Input
                                      type="text"
                                      name="credit_note_prefix"
                                      className="form-control"
                                      id="credit_note_prefix"
                                      placeholder="Receipt Prefix"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.credit_note_prefix || ""
                                      }
                                      invalid={
                                        validation.touched.credit_note_prefix &&
                                        validation.errors.credit_note_prefix
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.credit_note_prefix &&
                                    validation.errors.credit_note_prefix ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.credit_note_prefix}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="credit_note_starting_no">
                                    Credit Note Starting no.
                                    </Label>
                                    <Input
                                      type="text"
                                      name="credit_note_starting_no"
                                      className="form-control"
                                      id="credit_note_starting_no"
                                      placeholder="Receipt Prefix Starting no"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.credit_note_starting_no ||
                                        ""
                                      }
                                      invalid={
                                        validation.touched
                                          .credit_note_starting_no &&
                                        validation.errors.credit_note_starting_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.credit_note_starting_no &&
                                    validation.errors.credit_note_starting_no ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.credit_note_starting_no}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-card-verification-input1">
                                      API Username
                                    </Label>
                                    <Input
                                      type="text"
                                      name="api_username"
                                      className="form-control"
                                      id="basicpill-card-verification-input1"
                                      placeholder="E-Invoice Username"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.api_username || ""
                                      }
                                      invalid={
                                        validation.touched.api_username &&
                                        validation.errors.api_username
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.api_username &&
                                    validation.errors.api_username ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.api_username}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-card-verification-input0">
                                      API Password
                                    </Label>
                                    <Input
                                      type="password"
                                      name="api_password"
                                      className="form-control"
                                      id="basicpill-card-verification-input0"
                                      placeholder="E-Invoice Password"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.api_password || ""
                                      }
                                      invalid={
                                        validation.touched.api_password &&
                                        validation.errors.api_password
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.api_password &&
                                    validation.errors.api_password ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.api_password}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <Row>
                            <div className="row justify-content-center">
                              <Col lg="6">
                                <div className="mb-4">
                                  <Label
                                    className="d-block mb-3"
                                    htmlFor="r1frequency"
                                  >
                                    R1 Frequency*
                                  </Label>
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="r1frequency_monthly"
                                      name="gst_filing_r1_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Monthly"
                                      onChange={e => {
                                        handleChange1(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_r1_frequency === "Monthly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_r1_frequency &&
                                        validation.errors
                                          .gst_filing_r1_frequency
                                          ? true
                                          : false
                                      }
                                      // checked={validation.values.gst_filing_r1_frequency === "Monthly"}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="r1frequency_monthly"
                                    >
                                      Monthly
                                    </Label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="r1frequency_quaterly"
                                      name="gst_filing_r1_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Quaterly"
                                      onChange={e => {
                                        handleChange1(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_r1_frequency ===
                                        "Quaterly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_r1_frequency &&
                                        validation.errors
                                          .gst_filing_r1_frequency
                                          ? true
                                          : false
                                      }
                                      // checked={validation.values.gst_filing_r1_frequency === "Quaterly" ? true : false}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="r1frequency_quaterly"
                                    >
                                      Quarterly
                                    </Label>
                                  </div>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-4">
                                  <Label
                                    className="d-block mb-3"
                                    htmlFor="3bpayment"
                                  >
                                    3B Payment*
                                  </Label>
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="3bpayment_monthly"
                                      name="gst_filing_3b_payment_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Monthly"
                                      onChange={e => {
                                        handleChange2(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_3b_payment_frequency ===
                                        "Monthly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_3b_payment_frequency &&
                                        validation.errors
                                          .gst_filing_3b_payment_frequency
                                          ? true
                                          : false
                                      }
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="3bpayment_monthly"
                                    >
                                      Monthly
                                    </Label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="3bpayment_quaterly"
                                      name="gst_filing_3b_payment_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Quaterly"
                                      onChange={e => {
                                        handleChange2(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_3b_payment_frequency ===
                                        "Quaterly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_3b_payment_frequency &&
                                        validation.errors
                                          .gst_filing_3b_payment_frequency
                                          ? true
                                          : false
                                      }
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="3bpayment_quaterly"
                                    >
                                      Quarterly
                                    </Label>
                                  </div>
                                  <div
                                    className="form-check form-check-inline"
                                    style={{ clear: "both" }}
                                  >
                                    {/* This div with "clear: both" will force the next content to start on a new line */}
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className="row justify-content">
                              <Col lg="6">
                                <div className="mb-4">
                                  <Label className="d-block mb-3">
                                    3B Frequency*
                                  </Label>
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="3bpayment_monthly"
                                      name="gst_filing_3b_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Monthly"
                                      onChange={e => {
                                        handleChange3(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_3b_frequency === "Monthly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_3b_frequency &&
                                        validation.errors
                                          .gst_filing_3b_frequency
                                          ? true
                                          : false
                                      }
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="3bpayment_monthly"
                                    >
                                      Monthly
                                    </Label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="3bpayment_quaterly"
                                      name="gst_filing_3b_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Quaterly"
                                      onChange={e => {
                                        handleChange3(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_3b_frequency ===
                                        "Quaterly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_3b_frequency &&
                                        validation.errors
                                          .gst_filing_3b_frequency
                                          ? true
                                          : false
                                      }
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="3bpayment_quaterly"
                                    >
                                      Quarterly
                                    </Label>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Row>
                        </TabPane>
                        {/* <TabPane tabId={6}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Confirm Detail</h5>
                                  <p className="text-muted">
                                    Please Check your Details.
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane> */}
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={activeTab === 5 ? "next disabled" : "next"}
                        >
                          {activeTab === 4 ? (
                            <Link to="#" onClick={validation.handleSubmit}>
                              Submit
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              // onClick={() => {
                              //   toggleTab(activeTab + 1)
                              // }}
                              onClick={handleNextTab}
                            >
                              Next
                            </Link>
                          )}
                        </li>
                        {/* {toast2 && (
                          <div
                            className="position-fixed top-0 end-0 p-3 "
                            style={{ zIndex: "1005" }}
                          >
                            <Toast isOpen={toast2} role="aleart">
                              <ToastHeader toggle={() => setToast2(!toast2)}>
                                <img
                                  src={VLogo}
                                  alt=""
                                  className="me-2"
                                  height="18"
                                />
                                <strong className="me-auto">VIP Mall</strong>
                              </ToastHeader>
                              <ToastBody>
                                {!isEditMode
                                  ? "Submitted Successfully"
                                  : "Edited Successfully"}
                              </ToastBody>
                            </Toast>
                          </div>
                        )} */}
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditCompanyLocation
