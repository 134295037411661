import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"

const LargeModal = ({
  isOpen,
  toggle,
  data,
  applicableRateList,
  billingCompanyDetail,
  placeOfSupplyList,
  invoiceTypeMasterList,
}) => {
  const companyState = billingCompanyDetail && billingCompanyDetail?.state
  const placeOfSupply = placeOfSupplyList && placeOfSupplyList.filter(item => {
    return item.id === Number(data.place_of_supply);
  })[0]?.id;
  const rateApplicable =
    applicableRateList &&
    applicableRateList[data.applicable_rate - 1]?.applicable_rate

  const [CGST, setCGST] = useState(0)
  const [SGST, setSGST] = useState(0)
  const [IGST, setIGST] = useState(0)

  useEffect(() => {
    const { cgst, sgst, igst } = calculateGST(
      data.items,
      companyState,
      placeOfSupply,
      rateApplicable
    )

    setCGST(cgst)
    setSGST(sgst)
    setIGST(igst)
  }, [data.items, companyState, placeOfSupply, rateApplicable])

  const calculateSubtotal = items => {
    const subtotal = items.reduce(
      (total, item) => total + (parseFloat(item && item.amount) || 0),
      0
    )
    return subtotal
  }

  const calculateGST = (items, companyState, placeOfSupply, rateApplicable) => {
    const subtotal = calculateSubtotal(items)
    let cgst
    if (companyState && placeOfSupply && companyState !== placeOfSupply) {
      const igst = (subtotal * rateApplicable) / 100 // Assuming rateApplicable is the IGST rate
      return { igst }
    } else {
      const cgst = (subtotal * (rateApplicable / 2)) / 100 // Assuming rateApplicable is the CGST rate
      const sgst = (subtotal * (rateApplicable / 2)) / 100 // Assuming rateApplicable is the SGST rate
      return { cgst, sgst }
    }
  }

  const calculateNetPayable = items => {
    const subtotal = calculateSubtotal(items)
    const { igst, cgst, sgst } = calculateGST(
      items,
      companyState,
      placeOfSupply,
      rateApplicable
    )
    const netPayable = subtotal + (igst || cgst + sgst)
    return netPayable
  }

  const formatNumberWithCommas = (number) => {
    // If number is null or empty, return it as it is
    if (!number) {
      return number;
    }
  
    // Convert number to string
    let numStr = String(number);
  
    // Split the string into integer and decimal parts
    let parts = numStr.split('.');
  
    // Format the integer part with commas
    let integerPart = parts[0];
    let lastThreeDigits = integerPart.slice(-3); // Get the last three digits
    let remainingDigits = integerPart.slice(0, -3); // Get the remaining digits
    let formattedInteger = remainingDigits !== '' ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastThreeDigits : lastThreeDigits;
  
    // Combine integer and decimal parts with a dot
    let formattedNumber = formattedInteger + (parts[1] ? '.' + parts[1] : '');
  
    return formattedNumber;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Detailed Summary
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <table className="table">
          <thead>
            <tr>
              <th>Invoice Type</th>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.items.map((item, index) => {

                const invoiceTypeObject = invoiceTypeMasterList?.find(
                  invoiceType => String(invoiceType.id) === String(item?.invoice_type)
                )


                if (!invoiceTypeObject) {
                  console.log(
                    `No matching invoice type found for item id: ${item?.invoice_type}`
                  )
                }

                const invoiceTypeName =
                  invoiceTypeObject?.invoice_type || "Unknown Invoice Type"

                return (
                  <tr key={index}>
                    <td>{invoiceTypeName}</td>
                    <td>{item?.description}</td>
                    <td>{formatNumberWithCommas(item?.amount)}</td>
                  </tr>
                )
              })}
            <tr>
              <td colSpan="2" className="text-end">
                <b>Subtotal</b>
              </td>
              <td>{formatNumberWithCommas(calculateSubtotal(data.items))} /-</td>
            </tr>
            {companyState && placeOfSupply && companyState === placeOfSupply ? (
              <>
                <tr>
                  <td colSpan="2" className="text-end">
                    <b>{`CGST (${rateApplicable}%)`} </b>
                  </td>
                  <td>{formatNumberWithCommas(CGST)} /-</td>
                </tr>
                <tr>
                  <td colSpan="2" className="text-end">
                    <b>{`SGST (${rateApplicable}%)`}</b>
                  </td>
                  <td>{formatNumberWithCommas(SGST)} /-</td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="2" className="text-end">
                  <b>{`IGST (${rateApplicable}%)`} </b>
                </td>
                <td>{formatNumberWithCommas(IGST)} /-</td>
              </tr>
            )}
            <tr>
              <td colSpan="2" className="text-end">
                <b>Net Payable</b>
              </td>
              <td>{formatNumberWithCommas(calculateNetPayable(data.items))} /-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  )
}

export default LargeModal
