import {
  GET_SERVICE_TICKETS_LIST_SUCCESS,
  GET_SERVICE_TICKETS_LIST_FAIL,
  GET_SERVICE_TICKETS_DETAIL_SUCCESS,
  GET_SERVICE_TICKETS_DETAIL_FAIL,
  ADD_MATERIAL_IN_TICKET_FAIL,
  ADD_MATERIAL_IN_TICKET_SUCCESS,
  ADD_MANPOWER_IN_TICKET_FAIL,
  ADD_MANPOWER_IN_TICKET_SUCCESS,
  ADD_OTHER_COST_IN_TICKET_SUCCESS,
  ADD_OTHER_COST_IN_TICKET_FAIL,
  SEND_FOR_REVIEW_IN_TICKET_SUCCESS,
  SEND_FOR_REVIEW_IN_TICKET_FAIL,
  UPDATE_STATUS_IN_TICKET_SUCCESS,
  UPDATE_STATUS_IN_TICKET_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  serviceTicket: [],
  serviceTicketDetail: {},
  material: [],
  manpower: [],
  othercost: [],
  updateTicketStatus: [],
  proposal: [],

  error: {},
  loading: true,
}

const ServiceTicket = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_TICKETS_LIST_SUCCESS:
      return {
        ...state,
        serviceTicket: action.payload,
        loading: true,
      }

    case GET_SERVICE_TICKETS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERVICE_TICKETS_DETAIL_SUCCESS:
      return {
        ...state,
        serviceTicketDetail: action.payload,
      }

    case GET_SERVICE_TICKETS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_MATERIAL_IN_TICKET_SUCCESS:
      return {
        ...state,
        material: [...state.material, action.payload],
      }

    case ADD_MATERIAL_IN_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_MANPOWER_IN_TICKET_SUCCESS:
      return {
        ...state,
        manpower: [...state.manpower, action.payload],
      }

    case ADD_MANPOWER_IN_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_OTHER_COST_IN_TICKET_SUCCESS:
      return {
        ...state,
        othercost: [...state.othercost, action.payload],
      }

    case ADD_OTHER_COST_IN_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SEND_FOR_REVIEW_IN_TICKET_SUCCESS:
      return {
        ...state,
        proposal: [...state.proposal, action.payload],
      }

    case SEND_FOR_REVIEW_IN_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_STATUS_IN_TICKET_SUCCESS:
      return {
        ...state,
        updateTicketStatus: [...state.updateTicketStatus, action.payload],
      }

    case UPDATE_STATUS_IN_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default ServiceTicket
