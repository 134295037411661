import React, { useEffect, useState } from "react";
import TimeRegion from "./timeRegion";
import FileConfiguration from "./fileConfiguration";
import ReCaptch from "./reCaptcha";
import EmailConfiguration from "./emailConfiguration";
import UserPasswordSettings from "./userPasswordSettings";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect"
import { getTimeregion } from "store/actions";
import Spinners from "components/Common/Spinner"

import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    FormGroup,
    InputGroup,
    Toast, ToastHeader, ToastBody,
    FormFeedback,
} from "reactstrap"


const RegionalSettings = () => {
        
        const dispatch = useDispatch()
        const regionState = (state) => state.regions.timeregions
        const loadingState = (state) => state.regions.loading
        const data = useSelector(regionState)
        const loading = useSelector(loadingState)
       
        const [isLoading, setLoading] = useState(loading)


  useEffect(() => {
        const fetchData =  async() => {
                await dispatch(getTimeregion());
              };
            
          fetchData();
        }, []);



        
    return (
            <div className="page-content">
            {isLoading ? (
                    <Spinners setLoading={setLoading} />
                  ) : (
            <Container fluid={true}>
            <Row>
                    <Col xl={12}>
            <TimeRegion data={data}></TimeRegion>
            </Col>
            </Row>
            <Row>
                    <Col xl={12}>
            <FileConfiguration data={data}/>
            </Col>
            </Row>
            <Row>
                    <Col xl={12}>
            <ReCaptch data={data}></ReCaptch>
            </Col>
            </Row>
            <Row>
                    <Col xl={12}>
            <EmailConfiguration data={data}></EmailConfiguration>
            </Col>
            </Row>
            <Row>
                    <Col xl={12}>
            <UserPasswordSettings data={data}></UserPasswordSettings>
            </Col>
            </Row>
            </Container>
              
              )}
        </div>
    );
};
export default RegionalSettings;