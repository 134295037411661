import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_VENDOR_INVOICES,
  GET_VENDOR_INVOICES_DATA,
  GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT,
  GET_VENDOR_INVOICES_DATA_WITH_TDS,
  GET_VENDOR_INVOICES_PAYMENT_DATA,
  GET_VENDOR_LEDGER,
  GET_VENDOR_TICKET_DATA,
} from "./actionTypes"
import {
  getVendorInvoicesDataFail,
  getVendorInvoicesDataSuccess,
  getVendorInvoicesDataWithOutstandingAmountFail,
  getVendorInvoicesDataWithOutstandingAmountSuccess,
  getVendorInvoicesDataWithTdsFail,
  getVendorInvoicesDataWithTdsSuccess,
  getVendorInvoicesFail,
  getVendorInvoicesPaymentDataFail,
  getVendorInvoicesPaymentDataSuccess,
  getVendorInvoicesSuccess,
  getVendorLedgerFail,
  getVendorLedgerSuccess,
  getVendorTicketDataFail,
  getVendorTicketDataSuccess,
} from "./actions"

import {
  getVendorInvoices,
  getVendorInvoicesData,
  getVendorInvoicesDataWithOutstandingAmount,
  getVendorInvoicesDataWithTds,
  getVendorInvoicesPaymentData,
  getVendorLedger,
  getVendorTicketData,
} from "helpers/fakebackend_helper"

function* fetchVendorInvoices() {
  try {
    const response = yield call(getVendorInvoices)
    yield put(getVendorInvoicesSuccess(response))
  } catch (error) {
    yield put(getVendorInvoicesFail(error))
  }
}
function* fetchVendorInvoicesData({id}) {
  try {
    const response = yield call(getVendorInvoicesData,id)
    yield put(getVendorInvoicesDataSuccess(response))
  } catch (error) {
    yield put(getVendorInvoicesDataFail(error))
  }
}
function* fetchVendorInvoicesDataWithTds({id}) {
  try {
    const response = yield call(getVendorInvoicesDataWithTds,id)
    yield put(getVendorInvoicesDataWithTdsSuccess(response))
  } catch (error) {
    yield put(getVendorInvoicesDataWithTdsFail(error))
  }
}
function* fetchVendorInvoicesDataWithOutstandingAmount({id}) {
  try {
    const response = yield call(getVendorInvoicesDataWithOutstandingAmount,id)
    yield put(getVendorInvoicesDataWithOutstandingAmountSuccess(response))
  } catch (error) {
    yield put(getVendorInvoicesDataWithOutstandingAmountFail(error))
  }
}
function* fetchVendorInvoicesPaymentData({id}) {
  try {
    const response = yield call(getVendorInvoicesPaymentData,id)
    yield put(getVendorInvoicesPaymentDataSuccess(response))
  } catch (error) {
    yield put(getVendorInvoicesPaymentDataFail(error))
  }
}
function* fetchVendorTicketData({id}) {
  try {
    const response = yield call(getVendorTicketData,id)
    yield put(getVendorTicketDataSuccess(response))
  } catch (error) {
    yield put(getVendorTicketDataFail(error))
  }
}

function* fetchVendorLedger({data}) {
  try {
    const response = yield call(getVendorLedger,data)
    yield put(getVendorLedgerSuccess(response))
  } catch (error) {
    yield put(getVendorLedgerFail(error))
  }
}

function* VendorSummaryReportSaga() {
  yield takeEvery(GET_VENDOR_INVOICES, fetchVendorInvoices)
  yield takeEvery(GET_VENDOR_LEDGER, fetchVendorLedger)
  yield takeEvery(GET_VENDOR_INVOICES_DATA, fetchVendorInvoicesData)
  yield takeEvery(
    GET_VENDOR_INVOICES_DATA_WITH_TDS,
    fetchVendorInvoicesDataWithTds
  )
  yield takeEvery(
    GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT,
    fetchVendorInvoicesDataWithOutstandingAmount
  )
  yield takeEvery(
    GET_VENDOR_INVOICES_PAYMENT_DATA,
    fetchVendorInvoicesPaymentData
  )
  yield takeEvery(GET_VENDOR_TICKET_DATA, fetchVendorTicketData)
}

export default VendorSummaryReportSaga
