/* EXPENSES */
export const GET_EXPENSES = "GET_EXPENSES"
export const GET_EXPENSES_SUCCESS = "GET_EXPENSES_SUCCESS"
export const GET_EXPENSES_FAIL = "GET_EXPENSES_FAIL"

/* EXPENSES DETAIL*/
export const GET_EXPENSE_DETAIL = "GET_EXPENSE_DETAIL"
export const GET_EXPENSE_DETAIL_SUCCESS = "GET_EXPENSE_DETAIL_SUCCESS"
export const GET_EXPENSE_DETAIL_FAIL = "GET_EXPENSE_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_EXPENSE = "ADD_NEW_EXPENSE"
 export const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS"
 export const ADD_EXPENSE_FAIL = "ADD_EXPENSE_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_EXPENSE = "UPDATE_EXPENSE"
 export const UPDATE_EXPENSE_SUCCESS = "UPDATE_EXPENSE_SUCCESS"
 export const UPDATE_EXPENSE_FAIL = "UPDATE_EXPENSE_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_EXPENSE = "DELETE_EXPENSE"
 export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS"
 export const DELETE_EXPENSE_FAIL = "DELETE_EXPENSE_FAIL"
