

//VENDOR List INvoicse

export const GET_VENDOR_INVOICES = "GET_VENDOR_INVOICES"
export const GET_VENDOR_INVOICES_SUCCESS = "GET_VENDOR_INVOICES_SUCCESS"
export const GET_VENDOR_INVOICES_FAIL = "GET_VENDOR_INVOICES_FAIL"

//VENDOR Data 
export const GET_VENDOR_INVOICES_DATA = "GET_VENDOR_INVOICES_DATA"
export const GET_VENDOR_INVOICES_DATA_SUCCESS = "GET_VENDOR_INVOICES_DATA_SUCCESS"
export const GET_VENDOR_INVOICES_DATA_FAIL = "GET_VENDOR_INVOICES_DATA_FAIL"

//VENDOR With Tds Data 
export const GET_VENDOR_INVOICES_DATA_WITH_TDS = "GET_VENDOR_INVOICES_DATA_WITH_TDS"
export const GET_VENDOR_INVOICES_DATA_WITH_TDS_SUCCESS = "GET_VENDOR_INVOICES_DATA_WITH_TDS_SUCCESS"
export const GET_VENDOR_INVOICES_DATA_WITH_TDS_FAIL = "GET_VENDOR_INVOICES_DATA_WITH_TDS_FAIL"

//VENDOR With Outstanding AMount
export const GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT = "GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT"
export const GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_SUCCESS = "GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_SUCCESS"
export const GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_FAIL = "GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT_FAIL"

//VENDOR PAyment
export const GET_VENDOR_INVOICES_PAYMENT_DATA = "GET_VENDOR_INVOICES_PAYMENT_DATA"
export const GET_VENDOR_INVOICES_PAYMENT_DATA_SUCCESS = "GET_VENDOR_INVOICES_PAYMENT_DATA_SUCCESS"
export const GET_VENDOR_INVOICES_PAYMENT_DATA_FAIL = "GET_VENDOR_INVOICES_PAYMENT_DATA_FAIL"

//VENDOR TICEKT DATA
export const GET_VENDOR_TICKET_DATA = "GET_VENDOR_TICKET_DATA"
export const GET_VENDOR_TICKET_DATA_SUCCESS = "GET_VENDOR_TICKET_DATA_SUCCESS"
export const GET_VENDOR_TICKET_DATA_FAIL = "GET_VENDOR_TICKET_DATA_FAIL"

//GET_VENDOR_LEDGER
export const GET_VENDOR_LEDGER = "GET_VENDOR_LEDGER"
export const GET_VENDOR_LEDGER_SUCCESS = "GET_VENDOR_LEDGER_SUCCESS"
export const GET_VENDOR_LEDGER_FAIL = "GET_VENDOR_LEDGER_FAIL"
