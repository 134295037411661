import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  addNewProposalMaster,
  getProposalMasterDetail,
  updateProposalMaster,
} from "store/actions"

const NewProposalTypeMaster = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const proposalMasterState = state => state.proposalMaster.proposalDetail.data
  const loadingState = state => state.proposalMaster.loading
  const [isEditMode, setIsEditMode] = useState(false)
  const proposalMasterProperties = createSelector(
    proposalMasterState,
    loadingState,
    (proposal, loading) => {
      return {
        proposal: proposal,
        loading: loading,
      }
    }
  )
  const { proposal, loading } = useSelector(proposalMasterProperties)
  const [codeId, setCodeId] = useState()
  const [isLoading, setLoading] = useState(loading)

  const vendorData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const codeId = url.pathname.split(
        "/settings/edit-proposal-type-master/"
      )[1]
      setCodeId(codeId)

      dispatch(getProposalMasterDetail(codeId))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      vendorData()
    }
  }, [dispatch])

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    }
  }, [location.pathname])



  const validation = useFormik({
    enableReinitialize: true,
    initialValues: isEditMode
      ? {
          proposal_type: (proposal && proposal.proposal_type) || "",
          is_active: Boolean(proposal && proposal.is_active) || false,
        }
      : {
          proposal_type: "",
          is_active: false,
        },
    validationSchema: Yup.object({
      proposal_type: Yup.string().required("Please Enter Goods & Services Category "),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      if (isEditMode === true) {
        dispatch(updateProposalMaster(codeId, values, props.router.navigate))
      } else if (isEditMode === false) {
        dispatch(addNewProposalMaster(values, props.router.navigate))
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <>
            <Row>
              <Col>
                <div className="d-flex gap-2 mb-3">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey", marginRight: "15px" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>

                  <span
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    {isEditMode
                      ? "Edit Goods & Services Category Master"
                      : "New Goods & Services Category Master"}{" "}
                  </span>
                </div>
              </Col>
              <Col>
                <Breadcrumbs title="Settings / Master configuration / Goods & Services Category Master" />
              </Col>
            </Row>
            <div className="mt-3"></div>
            <Card>
              <CardBody>
                <Form
                  autoComplete="off"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="formrow-proposal_type-Input">
                        Goods & Services Category*
                        </Label>
                        <Input
                          type="text"
                          name="proposal_type"
                          className="form-control"
                          id="formrow-proposal_type-Input"
                          placeholder="Enter Goods & Services Category "
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.proposal_type || ""}
                          invalid={
                            validation.touched.proposal_type &&
                            validation.errors.proposal_type
                              ? true
                              : false
                          }
                        />
                        {validation.touched.proposal_type &&
                        validation.errors.proposal_type ? (
                          <FormFeedback type="invalid">
                            {validation.errors.proposal_type}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col>
                      <div className="mb-2"></div>
                      <div>
                        <Label htmlFor="formrow-ispaid-Input">Is Active</Label>
                      </div>

                      <Col lg="12">
                        <div className="form-check form-check-inline">
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            id="is_active"
                            name="is_active"
                            onChange={e => {
                              validation.setFieldValue(
                                "is_active",
                                e.target.checked
                              )
                            }}
                            checked={Boolean(validation?.values?.is_active)}
                          />
                          <Label
                            className="form-check-Label"
                            htmlFor="is-Active"
                          >
                            <h6>Yes</h6>
                          </Label>
                        </div>
                        {validation.touched.is_active &&
                          validation.errors.is_active && (
                            <FormFeedback type="invalid">
                              {validation.errors.is_active}
                            </FormFeedback>
                          )}
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3 text-end">
                        <button type="submit" className="btn btn-primary w-md">
                          {isEditMode ? "Edit" : "Submit"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(NewProposalTypeMaster)

NewProposalTypeMaster.propTypes = {
  history: PropTypes.object,
}
