/* COMPLIANCES */
export const GET_COMPLIANCES = "GET_COMPLIANCES"
export const GET_COMPLIANCES_SUCCESS = "GET_COMPLIANCES_SUCCESS"
export const GET_COMPLIANCES_FAIL = "GET_COMPLIANCES_FAIL"
/* COMPLIANCES */

export const GET_ACTIVE_COMPLIANCES = "GET_ACTIVE_COMPLIANCES"
export const GET_ACTIVE_COMPLIANCES_SUCCESS = "GET_ACTIVE_COMPLIANCES_SUCCESS"
export const GET_ACTIVE_COMPLIANCES_FAIL = "GET_ACTIVE_COMPLIANCES_FAIL"

/* COMPLIANCES DETAIL*/
export const GET_COMPLIANCE_DETAIL = "GET_COMPLIANCE_DETAIL"
export const GET_COMPLIANCE_DETAIL_SUCCESS = "GET_COMPLIANCE_DETAIL_SUCCESS"
export const GET_COMPLIANCE_DETAIL_FAIL = "GET_COMPLIANCE_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_COMPLIANCE = "ADD_NEW_COMPLIANCE"
 export const ADD_COMPLIANCE_SUCCESS = "ADD_COMPLIANCE_SUCCESS"
 export const ADD_COMPLIANCE_FAIL = "ADD_COMPLIANCE_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_COMPLIANCE = "UPDATE_COMPLIANCE"
 export const UPDATE_COMPLIANCE_SUCCESS = "UPDATE_COMPLIANCE_SUCCESS"
 export const UPDATE_COMPLIANCE_FAIL = "UPDATE_COMPLIANCE_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_COMPLIANCE = "DELETE_COMPLIANCE"
 export const DELETE_COMPLIANCE_SUCCESS = "DELETE_COMPLIANCE_SUCCESS"
 export const DELETE_COMPLIANCE_FAIL = "DELETE_COMPLIANCE_FAIL"

//  Compliance

export const GET_PROJECT_COMPLIANCES = "GET_PROJECT_COMPLIANCES"
export const GET_PROJECT_COMPLIANCE_SUCCESS = "GET_PROJECT_COMPLIANCE_SUCCESS"
export const GET_PROJECT_COMPLIANCE_FAIL = "GET_PROJECT_COMPLIANCE_FAIL"
