export const GET_UNIT_MASTER_LIST = "GET_UNIT_MASTER_LIST"
export const GET_UNIT_MASTER_LIST_SUCCESS = "GET_UNIT_MASTER_LIST_SUCCESS"
export const GET_UNIT_MASTER_LIST_FAIL = "GET_UNIT_MASTER_LIST_FAIL"

export const GET_UNIT_MASTER_DETAIL = "GET_UNIT_MASTER_DETAIL"
export const GET_UNIT_MASTER_DETAIL_SUCCESS = "GET_UNIT_MASTER_DETAIL_SUCCESS"
export const GET_UNIT_MASTER_DETAIL_FAIL = "GET_UNIT_MASTER_DETAIL_FAIL"

export const ADD_NEW_UNIT_MASTER = "ADD_NEW_UNIT_MASTER"
export const ADD_NEW_UNIT_MASTER_SUCCESS = "ADD_NEW_UNIT_MASTER_SUCCESS"
export const ADD_NEW_UNIT_MASTER_FAIL = "ADD_NEW_UNIT_MASTER_FAIL"

export const UPDATE_UNIT_MASTER = "UPDATE_UNIT_MASTER"
export const UPDATE_UNIT_MASTER_SUCCESS = "UPDATE_UNIT_MASTER_SUCCESS"
export const UPDATE_UNIT_MASTER_FAIL = "UPDATE_UNIT_MASTER_FAIL"

export const DELETE_UNIT_MASTER = "DELETE_UNIT_MASTER"
export const DELETE_UNIT_MASTER_SUCCESS = "DELETE_UNIT_MASTER_SUCCESS"
export const DELETE_UNIT_MASTER_FAIL = "DELETE_UNIT_MASTER_FAIL"
