import {
  GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE,
  GET_SERVICE_REQUESTS,
  GET_SERVICE_REQUESTS_FAIL,
  GET_SERVICE_REQUESTS_SUCCESS,
  GET_SERVICE_REQUEST_DETAIL,
  GET_SERVICE_REQUEST_DETAIL_FAIL,
  GET_SERVICE_REQUEST_DETAIL_SUCCESS,
  ADD_NEW_SERVICE_REQUEST,
  ADD_SERVICE_REQUEST_SUCCESS,
  ADD_SERVICE_REQUEST_FAIL,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST_SUCCESS,
  UPDATE_SERVICE_REQUEST_FAIL,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_REQUEST_SUCCESS,
  DELETE_SERVICE_REQUEST_FAIL,
  GET_PROJECT_UNIT_CATEGORY,
  GET_PROJECT_UNIT_CATEGORY_SUCCESS,
  GET_PROJECT_UNIT_CATEGORY_FAIL,
  GET_MAINTENANCE_ISSUES,
  GET_MAINTENANCE_ISSUES_SUCCESS,
  GET_MAINTENANCE_ISSUES_FAIL,
  GET_CLIENT_INVOICES,
  GET_CLIENT_INVOICES_SUCCESS,
  GET_CLIENT_INVOICES_FAIL,
  GET_SUPPORT_TICKET_SUCCESS,
  GET_SUPPORT_TICKET_FAIL,
  GET_SUPPORT_TICKET,
  GET_SERVICE_REQUEST_TYPE,
  GET_SERVICE_REQUEST_TYPE_SUCCESS,
  GET_SERVICE_REQUEST_TYPE_FAIL,
  GET_ALL_SERVICE_REQUEST_LIST,
  GET_ALL_SERVICE_REQUEST_LIST_SUCCESS,
  GET_ALL_SERVICE_REQUEST_LIST_FAIL,
  GET_PENDING_SERVICE_REQUEST_LIST,
  GET_PENDING_SERVICE_REQUEST_LIST_SUCCESS,
  GET_PENDING_SERVICE_REQUEST_LIST_FAIL,
  GET_PROJECT_MANAGER_IN_SERVICE_REQUEST,
  GET_PROJECT_MANAGER_IN_SERVICE_REQUEST_SUCCESS,
  GET_PROJECT_MANAGER_IN_SERVICE_REQUEST_FAIL,
  GET_USERS_IN_SERVICE_REQUEST,
  GET_USERS_IN_SERVICE_REQUEST_SUCCESS,
  GET_USERS_IN_SERVICE_REQUEST_FAIL,
  GET_VENDORS_IN_SERVICE_REQUEST,
  GET_VENDORS_IN_SERVICE_REQUEST_SUCCESS,
  GET_VENDORS_IN_SERVICE_REQUEST_FAIL,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_FAIL,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST_FAIL,
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_REQUEST_SUCCESS,
  CREATE_SERVICE_REQUEST_FAIL,
  GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE_SUCCESS,
  GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE_FAIL,
} from "./actionTypes"

export const getServiceRequests = () => ({
  type: GET_SERVICE_REQUESTS,
})

export const getServiceRequestsSuccess = serviceRequests => ({
  type: GET_SERVICE_REQUESTS_SUCCESS,
  payload: serviceRequests,
})

export const getServiceRequestsFail = error => ({
  type: GET_SERVICE_REQUESTS_FAIL,
  payload: error,
})
//type of service request
export const getServiceRequestType = () => ({
  type: GET_SERVICE_REQUEST_TYPE,
})

export const getServiceRequestTypeSuccess = serviceRequestType => ({
  type: GET_SERVICE_REQUEST_TYPE_SUCCESS,
  payload: serviceRequestType,
})

export const getServiceRequestTypeFail = error => ({
  type: GET_SERVICE_REQUEST_TYPE_FAIL,
  payload: error,
})

export const addNewServiceRequest = (clientId, serviceRequest) => ({
  type: ADD_NEW_SERVICE_REQUEST,
  payload: { clientId, serviceRequest },
})

export const addServiceRequestSuccess = serviceRequest => ({
  type: ADD_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
})

export const addServiceRequestFail = error => ({
  type: ADD_SERVICE_REQUEST_FAIL,
  payload: error,
})

export const updateServiceRequest = (id, serviceRequest) => ({
  type: UPDATE_SERVICE_REQUEST,
  payload: { id, serviceRequest },
})

export const updateServiceRequestSuccess = serviceRequest => ({
  type: UPDATE_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
})

export const updateServiceRequestFail = error => ({
  type: UPDATE_SERVICE_REQUEST_FAIL,
  payload: error,
})

export const deleteServiceRequest = serviceRequest => ({
  type: DELETE_SERVICE_REQUEST,
  serviceRequest,
})

export const deleteServiceRequestSuccess = serviceRequest => ({
  type: DELETE_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
})

export const deleteServiceRequestFail = error => ({
  type: DELETE_SERVICE_REQUEST_FAIL,
  payload: error,
})

export const getServiceRequestDetail = serviceRequestId => ({
  type: GET_SERVICE_REQUEST_DETAIL,
  serviceRequestId,
})

export const getServiceRequestDetailSuccess = serviceRequestDetails => ({
  type: GET_SERVICE_REQUEST_DETAIL_SUCCESS,
  payload: serviceRequestDetails,
})

export const getServiceRequestDetailFail = error => ({
  type: GET_SERVICE_REQUEST_DETAIL_FAIL,
  payload: error,
})

////DEPENDNCY
//PROJECT UNIT CATEGORY
export const getProjectUnitCategory = id => ({
  type: GET_PROJECT_UNIT_CATEGORY,
  id,
})

export const getProjectUnitCategorySuccess = projectUnits => ({
  type: GET_PROJECT_UNIT_CATEGORY_SUCCESS,
  payload: projectUnits,
})

export const getProjectUnitCategoryFail = error => ({
  type: GET_PROJECT_UNIT_CATEGORY_FAIL,
  payload: error,
})

//Maintenance issues
export const getMaintenanceIssues = () => ({
  type: GET_MAINTENANCE_ISSUES,
})

export const getMaintenanceIssuesSuccess = maintenanceIssues => ({
  type: GET_MAINTENANCE_ISSUES_SUCCESS,
  payload: maintenanceIssues,
})

export const getMaintenanceIssuesFail = error => ({
  type: GET_MAINTENANCE_ISSUES_FAIL,
  payload: error,
})

//CLIENT INVOICES
export const getClientInvoices = id => ({
  type: GET_CLIENT_INVOICES,
  id,
})

export const getClientInvoicesSuccess = clientInvoices => ({
  type: GET_CLIENT_INVOICES_SUCCESS,
  payload: clientInvoices,
})

export const getClientInvoicesFail = error => ({
  type: GET_CLIENT_INVOICES_FAIL,
  payload: error,
})

//CLIENT INVOICES
export const getSupportTickets = id => ({
  type: GET_SUPPORT_TICKET,
  id,
})

export const getSupportTicketsSuccess = supportTickets => ({
  type: GET_SUPPORT_TICKET_SUCCESS,
  payload: supportTickets,
})

export const getSupportTicketsFail = error => ({
  type: GET_SUPPORT_TICKET_FAIL,
  payload: error,
})

//GET ALL SERVICE REQUEST

export const getAllServiceRequestList = (data) => ({
  type: GET_ALL_SERVICE_REQUEST_LIST,
  data,
})

export const getAllServiceRequestListSuccess = allServiceRequestList => ({
  type: GET_ALL_SERVICE_REQUEST_LIST_SUCCESS,
  payload: allServiceRequestList,
})

export const getAllServiceRequestListFail = error => ({
  type: GET_ALL_SERVICE_REQUEST_LIST_FAIL,
  payload: error,
})
//status wise
export const getAllServiceRequestListStatusWise = (id) => ({
  type: GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE,
  id,
})

export const getAllServiceRequestListStatusWiseSuccess = allServiceRequestList => ({
  type: GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE_SUCCESS,
  payload: allServiceRequestList,
})

export const getAllServiceRequestListStatusWiseFail = error => ({
  type: GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE_FAIL,
  payload: error,
})

//GET PENDING SERVICE REQUESTS

export const getPendingServiceRequestList = () => ({
  type: GET_PENDING_SERVICE_REQUEST_LIST,
})

export const getPendingServiceRequestListSuccess =
  pendingServiceRequestList => ({
    type: GET_PENDING_SERVICE_REQUEST_LIST_SUCCESS,
    payload: pendingServiceRequestList,
  })

export const getPendingServiceRequestListFail = error => ({
  type: GET_PENDING_SERVICE_REQUEST_LIST_FAIL,
  payload: error,
})

//GET PROJECT MANAGER 

export const getProjectManagerInServiceRequest = () => ({
  type: GET_PROJECT_MANAGER_IN_SERVICE_REQUEST,
})

export const getProjectManagerInServiceRequestSuccess = managers => ({
  type: GET_PROJECT_MANAGER_IN_SERVICE_REQUEST_SUCCESS,
  payload: managers,
})

export const getProjectManagerInServiceRequestFail = error => ({
  type: GET_PROJECT_MANAGER_IN_SERVICE_REQUEST_FAIL,
  payload: error,
})

//GET USERS 

export const getUsersInServiceRequest = (id) => ({
  type: GET_USERS_IN_SERVICE_REQUEST,
  id,
})

export const getUsersInServiceRequestSuccess = users => ({
  type: GET_USERS_IN_SERVICE_REQUEST_SUCCESS,
  payload: users,
})

export const getUsersInServiceRequestFail = error => ({
  type: GET_USERS_IN_SERVICE_REQUEST_FAIL,
  payload: error,
})

//GET VENDOR 

export const getVendorsInServiceRequest = () => ({
  type: GET_VENDORS_IN_SERVICE_REQUEST,
})

export const getVendorsInServiceRequestSuccess = vendors => ({
  type: GET_VENDORS_IN_SERVICE_REQUEST_SUCCESS,
  payload: vendors,
})

export const getVendorsInServiceRequestFail = error => ({
  type: GET_VENDORS_IN_SERVICE_REQUEST_FAIL,
  payload: error,
})


export const assignServiceRequest = (serviceId, serviceRequest,history) => ({
  type: ASSIGN_SERVICE_REQUEST,
  payload: { serviceId, serviceRequest,history },
})

export const assignServiceRequestSuccess = serviceRequest => ({
  type: ASSIGN__SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
})

export const assignServiceRequestFail = error => ({
  type: ASSIGN__SERVICE_REQUEST_FAIL,
  payload: error,
})

export const createServiceRequest = (serviceId, serviceRequest,history) => ({
  type: CREATE_SERVICE_REQUEST,
  payload: { serviceId, serviceRequest,history },
})

export const createServiceRequestSuccess = serviceRequest => ({
  type: CREATE_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
})

export const createServiceRequestFail = error => ({
  type: CREATE_SERVICE_REQUEST_FAIL,
  payload: error,
})

export const getMaterialMasterForServiceRequest = () => ({
  type: GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST,
})

export const getMaterialMasterForServiceRequestSuccess = materials => ({
  type: GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  payload: materials,
})

export const getMaterialMasterForServiceRequestFail = error => ({
  type: GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_FAIL,
  payload: error,
})

export const getUnitMasterForServiceRequest = () => ({
  type: GET_UNIT_MASTER_FOR_SERVICE_REQUEST,
})

export const getUnitMasterForServiceRequestSuccess = units => ({
  type: GET_UNIT_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  payload: units,
})

export const getUnitMasterForServiceRequestFail = error => ({
  type: GET_UNIT_MASTER_FOR_SERVICE_REQUEST_FAIL,
  payload: error,
})

