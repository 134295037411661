
//COMPANY FILTER

export const GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT = "GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT"
export const GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_SUCCESS = "GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_SUCCESS"
export const GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_FAIL = "GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT_FAIL"


//PROJECT FILTER

export const GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT="GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT"
export const GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_SUCCESS="GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_SUCCESS"
export const GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_FAIL="GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_FAIL"

//VENDOR FILTER

export const GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT="GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT"
export const GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_SUCCESS="GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_SUCCESS"
export const GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_FAIL="GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_FAIL"


export const GET_BILL_HISTORY_FOR_VENDOR_PAYMENT="GET_BILL_HISTORY_FOR_VENDOR_PAYMENT"
export const GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS="GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS"
export const GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_FAIL="GET_BILL_HISTORY_FOR_VENDOR_PAYMENT_FAIL"

export const GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT="GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT"
export const GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS="GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_SUCCESS"
export const GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_FAIL="GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT_FAIL"


export const GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST="GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST"
export const GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS="GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS"
export const GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL="GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL"


export const GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST="GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST"
export const GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS="GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_SUCCESS"
export const GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL="GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST_FAIL"

