import {
  GET_COMPANIES_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANY_DETAIL_FAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  GET_DOCUMENT_LIST_COMPANY_SUCCESS,
  GET_DOCUMENT_LIST_COMPANY_FAIL,
  ADD_DOCUMENT_COMPANY_SUCCESS,
  ADD_DOCUMENT_COMPANY_FAIL,
  DELETE_DOCUMENT_COMPANY_SUCCESS,
  DELETE_DOCUMENT_COMPANY_FAIL,
  GET_COMPANY_LOCATION_LIST_SUCCESS,
  GET_COMPANY_LOCATION_LIST_FAIL,
  ADD_COMPANY_LOCATION_SUCCESS,
  ADD_COMPANY_LOCATION_FAIL,
  UPDATE_COMPANY_LOCATION_SUCCESS,
  UPDATE_COMPANY_LOCATION_FAIL,
  DELETE_COMPANY_LOCATION_SUCCESS,
  DELETE_COMPANY_LOCATION_FAIL,
  GET_COMPANY_LOCATION_DETAIL_SUCCESS,
  GET_COMPANY_LOCATION_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  companies: [],
  docs: [],
  location: [],
  locationDetail: {},
  companyDetail: {},
  error: {},
  loading: true,
}

const companies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        loading: true,
        // loading: true
      }

    case GET_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload],
      }

    case ADD_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        companyDetail: action.payload,
      }

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.map(company =>
          company.id.toString() === action.payload.id.toString()
            ? { company, ...action.payload }
            : company
        ),
      }

    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.filter(
          company => company.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //DOCUMENTS
    case GET_DOCUMENT_LIST_COMPANY_SUCCESS:
      return {
        ...state,
        docs: action.payload,
        loading: true,
      }

    case GET_DOCUMENT_LIST_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DOCUMENT_COMPANY_SUCCESS:
      return {
        ...state,
        docs: [...state.docs, action.payload],
      }

    case ADD_DOCUMENT_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_DOCUMENT_COMPANY_SUCCESS:
      return {
        ...state,
        docs: state.docs.filter(
          doc => doc.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_DOCUMENT_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //LOCATION

    case GET_COMPANY_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        location: action.payload,
        loading: true,
      }

    case GET_COMPANY_LOCATION_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPANY_LOCATION_DETAIL_SUCCESS:
      return {
        ...state,
        locationDetail: action.payload,
        loading: true,
      }

    case GET_COMPANY_LOCATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_COMPANY_LOCATION_SUCCESS:
      return {
        ...state,
        location: [...state.location, action.payload],
      }

    case ADD_COMPANY_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_COMPANY_LOCATION_SUCCESS:
      return {
        ...state,
        location: state.location.map(location =>
          location.id.toString() === action.payload.id.toString()
            ? { location, ...action.payload }
            : location
        ),
      }

    case UPDATE_COMPANY_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COMPANY_LOCATION_SUCCESS:
      return {
        ...state,
        location: state.location.filter(
          location => location.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_COMPANY_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default companies
