import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route, Navigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
// Import all middleware
import Authmiddleware from "./routes/route"
import Spinners from "components/Common/Spinner"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

import Pages404 from "VipPages/Utility/pages-404"

import { useDispatch } from "react-redux"
import Dashboard from "VipPages/Dashboard"

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const dispatch = useDispatch()
  const loadingState = useSelector(state => state.Login.loading)
  const [isLoading, setLoading] = useState(loadingState)
  const loginUser = useSelector(state => state.Login)

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = getLayout(layoutType)
  const getComponentByPath = path => {
    const route = authProtectedRoutes.find(route => path === route.path)

    return route ? route.component : null
  }
  return (
    <React.Fragment>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <>
          <Routes>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))}

            {/* {authProtectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <Authmiddleware>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>
                }
                key={idx}
                exact={true}
              />
            ))} */}

            {/* <Route
              path='/dashboard'
              element={
                <Authmiddleware>
                    <Layout>
                      <Navigate to="/dashboard" />
                    </Layout>
                  </Authmiddleware>
              }
              exact={true}
            /> */}

            {(loginUser.permission || []).map(item => {
              const path = `/${item.module}${item.page}`
              return item.access === 1 ? (
                <Route
                  key={item.component_id}
                  path={path}
                  element={
                    <Authmiddleware>
                      <Layout>{getComponentByPath(path)}</Layout>
                    </Authmiddleware>
                  }
                  exact={true}
                />
              ) : (
                <Route
                  key={item.component_id}
                  path="*"
                  element={
                    <NonAuthLayout>
                      <Pages404 />
                    </NonAuthLayout>
                  }
                  exact={true}
                />
              )
            })}

            <Route
              path="*"
              element={
                <NonAuthLayout>
                  <Pages404 />
                </NonAuthLayout>
              }
              exact={true}
            />

            <Route
              path="/"
              element={
                user !== null ? (
                  <Authmiddleware>
                    <Layout>
                      <Navigate to="/dashboard" />
                    </Layout>
                  </Authmiddleware>
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>
          </Routes>
        </>
      )}
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
