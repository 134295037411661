import React, { useState, useEffect } from "react"
import VLogo from "../../assets/images/VIP/VLogo.png"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Formik } from "formik"
import Swal from "sweetalert2"

// import {
//   getUsers as onGetUsers,
//   addNewUser as onAddNewUser,
//   updateUser as onUpdateUser,
//   deleteUser as onDeleteUser,
// } from "store/contacts/actions"
import { isEmpty } from "lodash"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {
  getActiveCompliances,
  getProjectDetail,
  activeLandlord,
  getProjectManager,
  getCompanyForProject,
  getCompanyDetailForProject,
  getCompanyLocationDetailForProject,
  getCompanyLocationForProject,
} from "store/actions"
import { addNewProject, updateProject } from "helpers/fakebackend_helper"

const Addnewproject = () => {
  const [rows1, setrows1] = useState([{ id: 1 }])
  const [isEditMode, setIsEditMode] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const projectState = state => state.projects.projectDetail.data

  const companyState = state => state.projects.company.data
  const billingCompanyDetailState = state =>
    state.projects.companyDetail.company
  const companyLocationState = state => state.projects.location.data
  const companyLocationDetailState = state => state.projects.locationDetail.data
  const loadingState = state => state.projects.loading
  const ProjectManagerState = state => state.projects.manager.data
  const ActiveCompliancesState = state =>
    state.compliances.activecompliances.compliances
  const activeLanlordState = state => state.landlords.activelandlord.data
  const ActiveCompliances = useSelector(ActiveCompliancesState)
  const projectManagerDetails = useSelector(ProjectManagerState)
  const activeLandlordDetail = useSelector(activeLanlordState)
  const company = useSelector(companyState)
  const companyLocation = useSelector(companyLocationState)
  const billingCompanyDetail = useSelector(billingCompanyDetailState)
  const companyLocationDetail = useSelector(companyLocationDetailState)

  const projectsProperties = createSelector(
    projectState,
    loadingState,
    (projects, loading) => {
      return {
        projects: projects,
        loading: loading,
      }
    }
  )

  const { projects, loading } = useSelector(projectsProperties)
  const [projectId, setProjectId] = useState()
  const [isLoading, setLoading] = useState(loading)

  const projectData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }
      const projectId = url.pathname.split("/edit-project/")[1]
      setProjectId(projectId)
      dispatch(getProjectDetail(projectId))
    } catch (error) {
      console.error(error)
    }
  }
  const projectManagerData = () => {
    try {
      dispatch(getProjectManager())
    } catch (error) {
      console.error(error)
    }
  }
  const projectActiveCompliances = () => {
    try {
      dispatch(getActiveCompliances())
    } catch (error) {
      console.error(error)
    }
  }

  const getActiveLandlord = async () => {
    try {
      dispatch(activeLandlord())
    } catch (error) {
      console.error(error)
    }
  }

  const getLocationAndLocationDetail = async () => {
    try {
      dispatch(getCompanyLocationForProject())
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    dispatch(getCompanyForProject())
    if (location.pathname.includes("edit")) {
      projectData()
    }
    projectActiveCompliances()
    projectManagerData()
    getActiveLandlord()
  }, [dispatch])

  useEffect(() => {
    if (location.pathname.includes("edit-project")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: !isEditMode
      ? {
          project_name: "",
          property_type: "",
          project_type: "",
          project_use: "",
          project_area_sqft: "",
          total_floors: "",
          location: "",
          landlord_id: "",
          contract_terms_start_date: "",
          contract_terms_end_date: "",
          project_manager_id: "",
          parking_available: "1",
          car_parking_capability: "",
          bike_parking_capability: "",
          parking_type: "Free",
          operated_by: "By Vinder",
          branding_space_area: "",
          event_space_area: "",
          compliance_data: "",
          company_id: "",
          location_id: "",
        }
      : {
          project_name: (projects && projects.project_name) || "",
          property_type: (projects && projects.property_type) || "",
          project_type: (projects && projects.project_type) || "",
          project_use: (projects && projects.project_use) || "",
          project_area_sqft: (projects && projects.project_area_sqft) || "",
          total_floors: (projects && projects.total_floors) || "",
          location: (projects && projects.location) || "",
          landlord_id: (projects && projects.landlord_id) || "",
          contract_terms_start_date:
            (projects && projects.contract_terms_start_date) || "",
          contract_terms_end_date:
            (projects && projects.contract_terms_end_date) || "",
          project_manager_id: projects && projects.project_manager_id,
          engagement_approver: projects && projects.engagement_approver,
          parking_available:
            (projects && String(projects.parking_available)) || "0",
          car_parking_capability:
            (projects && projects.car_parking_capability) || "",
          bike_parking_capability:
            (projects && projects.bike_parking_capability) || "",
          parking_type: (projects && String(projects.parking_type)) || "Free",
          operated_by:
            (projects && String(projects.operated_by)) || "By Vinder",
          branding_space_area: (projects && projects.branding_space_area) || "",
          event_space_area: (projects && projects.event_space_area) || "",
          // previous_compliances: (projects && projects.compliances) || "",
          compliance_data: (projects && projects.compliances) || "",
          company_id: (projects && projects.company_id) || "",
          location_id: (projects && projects.location_id) || "",
        },

    validationSchema: Yup.object({
      project_name: Yup.string().required("Please Enter Project Name"),
      property_type: Yup.string().required("Please Enter Property Type"),
      project_type: Yup.string().required("Please Enter Property Type"),
      project_use: Yup.string().required("Please Enter Project Use"),
      project_manager_id: Yup.number().required(
        "Please Select Project Manager"
      ),
      engagement_approver: Yup.number().required(
        "Please Select Project EngageMent Approver"
      ),
      project_area_sqft: Yup.string()
        .matches(/^[0-9]+$/, "Project Area must only contain numbers")
        .required("Please Enter Project Area (sqft)"),
      total_floors: Yup.string()
        .matches(/^[0-9]+$/, "Total Floors must only contain numbers")
        .required("Please Enter Total Floors"),
      location: Yup.string().required("Please Enter Location"),
      landlord_id: Yup.number(),
      contract_terms_start_date: Yup.string().required(
        "Please Enter Contract Start Date"
      ),
      contract_terms_end_date: Yup.string().when(
        "contract_terms_start_date",
        (contract_terms_start_date, schema) => {
          return contract_terms_start_date
            ? schema
                .test(
                  "is-after-start",
                  "End date must be after start date",
                  function (value) {
                    const { contract_terms_start_date } = this.parent
                    if (!contract_terms_start_date || !value) return true // Allow null values
                    return new Date(value) > new Date(contract_terms_start_date)
                  }
                )
                .required("Please Enter Contract End Date")
            : schema
        }
      ),
      parking_available: Yup.string(),
      car_parking_capability: Yup.string().matches(
        /^[0-9]+$/,
        "Car Parking capability must only contain numbers"
      ),
      bike_parking_capability: Yup.string().matches(
        /^[0-9]+$/,
        "Bike Parking capability must only contain numbers"
      ),
      parking_type: Yup.string(),
      operated_by: Yup.string(),
      branding_space_area: Yup.string().matches(
        /^[0-9]+$/,
        "Branding Space Area must only contain numbers"
      ),
      event_space_area: Yup.string().matches(
        /^[0-9]+$/,
        "Event Space Area must only contain numbers"
      ),
      compliance_data: Yup.array().of(
        Yup.object().shape({
          project_compliance_id: Yup.string(),
          from_date: Yup.date().required("Please Enter From Date"),
          to_date: Yup.string().when("from_date", (from_date, schema) => {
            return from_date
              ? schema.test(
                  "is-after-start",
                  "To date must be after start date",
                  function (value) {
                    const { from_date } = this.parent
                    if (!from_date || !value) return true // Allow null values
                    return new Date(value) > new Date(from_date)
                  }
                )
              : schema
          }),
          // document: Yup.mixed().required("Please Upload Document"),
        })
      ),
      company_id: Yup.number().required("Please Select Billing Company"),
      location_id: Yup.number().required("Please Select Company's Location"),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        if (isEditMode === true) {
          const response = await updateProject(projectId, values)

          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Project has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/projects")
              }
            })
          } else {
            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has not been saved.",
            })
          }
        } else if (isEditMode === false) {
          // const response = await dispatch(addNewVendor(values));
          const response = await addNewProject(values)
          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Project has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/projects")
              }
            })
          } else {
            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Project has not been saved.",
            })
          }
        }
      } catch (error) {
        // Handle the error if needed
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  function handlePreview(file) {
    if (file) {
      window.open(file, "_blank")
    }
  }

  useEffect(() => {
    if (validation.values.company_id) {
      dispatch(getCompanyDetailForProject(validation.values.company_id))
      dispatch(getCompanyLocationForProject(validation.values.company_id))
    }
  }, [validation.values.company_id])

  useEffect(() => {
    if (validation.values.location_id) {
      dispatch(
        getCompanyLocationDetailForProject(validation.values.location_id)
      )
    }
  }, [validation.values.location_id])

  console.log(validation.values)
  // function handleAddRowNested() {
  //   const modifiedRows = [...rows1]
  //   modifiedRows.push({ id: modifiedRows.length + 1 })
  //   setrows1(modifiedRows)
  // }
  const handleAddRowNested = () => {
    const newRow = { id: rows1.length + 1 }
    setrows1([...rows1, newRow])
  }
  // function handleRemoveRow(id) {
  //   if (id !== 1) {
  //     var modifiedRows = [...rows1]
  //     modifiedRows = modifiedRows.filter(x => x["id"] !== id)
  //     setrows1(modifiedRows)
  //   }
  // }
  const handleRemoveRow = id => {
    if (rows1.length === 1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You cannot delete the last row!",
      })
    } else {
      const updatedRows = rows1.filter(row => row.id !== id)
      // Remove data of the row from compliance_data
      const updatedBillingComponents = validation.values.compliance_data.filter(
        (_, index) => index !== id
      )
      // Update validation.values.compliance_data with the updated array
      validation.setFieldValue("compliance_data", updatedBillingComponents)
      setrows1(updatedRows)
    }
  }

  useEffect(() => {
    if (validation && validation.values && validation.values.compliance_data) {
      // Update rows state based on the length of billing_components array
      setrows1(
        [...Array(validation.values.compliance_data.length)].map(
          (_, index) => ({ id: index })
        )
      )
    }
  }, [validation.values.compliance_data])
  return (
    <>
      <div className="page-content">
        <Row>
          <div className="d-flex gap-2 mb-3">
            <Col>
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey", marginRight: "15px" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>

              <span
                style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
              >
                {" "}
                {isEditMode ? "Edit Project" : "Add New Project"}{" "}
              </span>
            </Col>
            <Col>
              {isEditMode ? (
                <Breadcrumbs title=" Projects / Edit Project" />
              ) : (
                <Breadcrumbs title="Projects / Add New Project" />
              )}
            </Col>
          </div>
        </Row>
        {/* <div className="mt-3"></div> */}
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form autoComplete="off" onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="project_name">Project Name*</Label>
                          <Input
                            type="name"
                            name="project_name"
                            id="project_name"
                            placeholder="Enter Your Project Name "
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.project_name || ""}
                            className={`form-control ${
                              validation.touched.project_name &&
                              validation.errors.project_name
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.project_name &&
                          validation.errors.project_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.project_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="property_type">Property Type*</Label>
                          <select
                            name="property_type"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.property_type || ""}
                            className={`form-select ${
                              validation.touched.property_type &&
                              validation.errors.property_type
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.property_type && (
                              <option>Select Property Type</option>
                            )}
                            {/* <option value="0">Select Property Type</option> */}
                            <option value="Owned property">
                              Owned Property
                            </option>
                            <option value="Lease property">
                              Lease Property
                            </option>
                            <option value="Managed Property">
                              Managed Property
                            </option>
                            {validation.touched.property_type &&
                            validation.errors.property_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.property_type}
                              </FormFeedback>
                            ) : null}
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-projectuse-Input">
                            Project Use*
                          </Label>
                          <select
                            name="project_use"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.project_use || ""}
                            className={`form-select ${
                              validation.touched.project_use &&
                              validation.errors.project_use
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.project_use && (
                              <option>Select Project Use</option>
                            )}

                            <option value="Fully Operated by Vinder">
                              Fully Operated by Vinder
                            </option>
                            <option value="Partially Operated by Vinder">
                              Partially Operated by Vinder
                            </option>
                          </select>
                          {validation.touched.project_use &&
                          validation.errors.project_use ? (
                            <FormFeedback type="invalid">
                              {validation.errors.project_use}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-projectarea-Input">
                            Project Area(sq.ft)*
                          </Label>
                          <Input
                            type="text"
                            name="project_area_sqft"
                            placeholder="Enter Project Area in sq.ft "
                            id="project_area_sqft"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.project_area_sqft || ""}
                            className={`form-control ${
                              validation.touched.project_area_sqft &&
                              validation.errors.project_area_sqft
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.project_area_sqft &&
                          validation.errors.project_area_sqft ? (
                            <FormFeedback type="invalid">
                              {validation.errors.project_area_sqft}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-projecttype-Input">
                            Project Type*
                          </Label>
                          <select
                            // defaultValue="0"
                            // className="form-select"
                            name="project_type"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.project_type || ""}
                            className={`form-select ${
                              validation.touched.project_type &&
                              validation.errors.project_type
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.project_type && (
                              <option>Select Project Type</option>
                            )}
                            <option value="Shops">Shops</option>
                            <option value="Office">Office</option>
                            <option value="Mall">Mall</option>
                            <option value="Cinema">Cinema</option>
                            <option value="Mixed Use">Mixed Use</option>
                            <option value="Residential">Residential</option>
                            <option value="Hotel & Restaurant">
                              Hotel & Restaurant
                            </option>
                            <option value="Food Court">Food Court</option>
                            <option value="Banquet">Banquet</option>
                          </select>
                          {validation.touched.project_type &&
                          validation.errors.project_type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.project_type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-totalfloors-Input">
                            Total Floors*
                          </Label>
                          <Input
                            type="text"
                            id="total_floors"
                            name="total_floors"
                            placeholder="Enter Number of Floors "
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.total_floors || ""}
                            className={`form-control ${
                              validation.touched.total_floors &&
                              validation.errors.total_floors
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.total_floors &&
                          validation.errors.total_floors ? (
                            <FormFeedback type="invalid">
                              {validation.errors.total_floors}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="location">Location*</Label>
                          <Input
                            type="text"
                            id="location"
                            name="location"
                            placeholder="Enter Your Location (Address) "
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.location || ""}
                            className={`form-control ${
                              validation.touched.location &&
                              validation.errors.location
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.location &&
                          validation.errors.location ? (
                            <FormFeedback type="invalid">
                              {validation.errors.location}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="engagement_approver">
                            Engagement Approver*
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.engagement_approver} // Set the selected value here
                            name="engagement_approver"
                            className={`form-select ${
                              validation.touched.engagement_approver &&
                              validation.errors.engagement_approver
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.engagement_approver && (
                              <option>Select Engagement Approver</option>
                            )}

                            {(projectManagerDetails || []).map(item => (
                              <option key={item.id} value={item.id}>
                                {item.first_name} {item.middle_name}{" "}
                                {item.last_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.engagement_approver &&
                          validation.errors.engagement_approver ? (
                            <FormFeedback type="invalid">
                              {validation.errors.engagement_approver}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      {(validation.values.property_type === "Lease property" ||
                        validation.values.property_type ===
                          "Managed Property") && (
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="landlord_id">Landlord*</Label>
                            <select
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="landlord_id"
                              className={`form-select ${
                                validation.touched.landlord_id &&
                                validation.errors.landlord_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              {!validation.values.landlord_id && (
                                <option>Select Landlord</option>
                              )}

                              {(activeLandlordDetail || []).map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.landlord_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      )}
                    </Row>
                    {/* <Row>
                      <Label htmlFor="formrow-contractterm-Input">
                        Contract Term
                      </Label> */}
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="contract_terms_start_date">
                            Contract Terms StartDate*
                          </Label>
                          <Input
                            type="date"
                            id="contract_terms_start_date"
                            name="contract_terms_start_date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contract_terms_start_date}
                            className={`form-control ${
                              validation.touched.contract_terms_start_date &&
                              validation.errors.contract_terms_start_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.contract_terms_start_date &&
                          validation.errors.contract_terms_start_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contract_terms_start_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={3}>
                        <div className="mb-3">
                          <Label htmlFor="contract_terms_end_date">
                            Contract Terms validity End Date*
                          </Label>
                          <Input
                            type="date"
                            id="contract_terms_end_date"
                            name="contract_terms_end_date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contract_terms_end_date}
                            disabled={
                              !validation.values.contract_terms_start_date
                            }
                            className={`form-control ${
                              validation.touched.contract_terms_end_date &&
                              validation.errors.contract_terms_end_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.contract_terms_end_date &&
                          validation.errors.contract_terms_end_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contract_terms_end_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="project_manager_id">
                            Project Manager*
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.project_manager_id} // Set the selected value here
                            name="project_manager_id"
                            className={`form-select ${
                              validation.touched.project_manager_id &&
                              validation.errors.project_manager_id
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.project_manager_id && (
                              <option>Select Manager</option>
                            )}

                            {(projectManagerDetails || []).map(item => (
                              <option key={item.id} value={item.id}>
                                {item.first_name} {item.middle_name}{" "}
                                {item.last_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_manager_id &&
                          validation.errors.project_manager_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.project_manager_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <div className="mb-3">
                            <Label htmlFor="parking_available">
                              Parking Available*
                            </Label>
                            <Col lg="6">
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  id="parkingYes"
                                  name="parking_available"
                                  value={1}
                                  // onClick={e => {
                                  //   handleChange1(e)
                                  // }}
                                  checked={
                                    validation.values.parking_available === "1"
                                  }
                                  onChange={validation.handleChange}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="parkingYes"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <h6>Yes</h6>
                                </Label>
                              </div>
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  id="parkingNo"
                                  name="parking_available"
                                  value={0}
                                  checked={
                                    validation.values.parking_available === "0"
                                  }
                                  onChange={validation.handleChange}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="parkingNo"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <h6>No</h6>
                                </Label>
                              </div>
                            </Col>
                          </div>
                          {validation.values.parking_available === "1" && (
                            <div className="mb-3">
                              <Row>
                                <Label htmlFor="car_parking_capability">
                                  Number of Cars capability
                                </Label>
                                <Col>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="carsCapabilityTextBox"
                                      name="car_parking_capability"
                                      placeholder="Number of Cars capability "
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.car_parking_capability
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Label htmlFor="bike_parking_capability">
                                  Number of Bikes capability
                                </Label>
                                <Col>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="bikesCapabilityTextBox"
                                      name="bike_parking_capability"
                                      placeholder="Number of Bikes capability "
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values
                                          .bike_parking_capability
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Col lg="6">
                                <Label htmlFor="parkingType">
                                  Parking Type*
                                </Label>
                                <div className="form-check">
                                  <Input
                                    type="radio"
                                    id="parkingFree"
                                    name="parking_type"
                                    value="Free"
                                    checked={
                                      validation.values.parking_type === "Free"
                                    }
                                    onChange={validation.handleChange}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="parkingFree"
                                    style={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    <h6>Free</h6>
                                  </Label>
                                </div>
                                <div className="form-check">
                                  <Input
                                    type="radio"
                                    id="parkingPaid"
                                    name="parking_type"
                                    value="Paid"
                                    checked={
                                      validation.values.parking_type === "Paid"
                                    }
                                    onChange={validation.handleChange}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="parkingPaid"
                                    style={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    <h6>Paid</h6>
                                  </Label>
                                </div>
                              </Col>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="operated_by">Operated by</Label>
                          <Col lg="6">
                            <div className="form-check">
                              <Input
                                type="radio"
                                id="operated_by_vinder"
                                name="operated_by"
                                onBlur={validation.handleBlur}
                                value="By Vinder"
                                checked={
                                  validation.values.operated_by === "By Vinder"
                                }
                                onChange={validation.handleChange}
                                className="form-check-input"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="operated_by_vinder"
                                style={{
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                <h6>By Vinder</h6>
                              </Label>
                            </div>

                            <div className="form-check form-check">
                              <Input
                                type="radio"
                                id="operated_by_thirdparty"
                                name="operated_by"
                                onBlur={validation.handleBlur}
                                value="By Third Party"
                                checked={
                                  validation.values.operated_by ===
                                  "By Third Party"
                                }
                                onChange={validation.handleChange}
                                className="form-check-input"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="operated_bythirdparty"
                                style={{
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                <h6>By Third Party</h6>
                              </Label>
                            </div>
                            {validation.touched.operated_by &&
                            validation.errors.operated_by ? (
                              <FormFeedback type="invalid">
                                {validation.errors.operated_by}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    &nbsp;
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-branding-area-Input">
                            Branding Space Area(Sqft)
                          </Label>
                          <Input
                            type="text"
                            id="branding_space_area"
                            name="branding_space_area"
                            placeholder="Branding space Area"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.branding_space_area || ""}
                            className={`form-control ${
                              validation.touched.branding_space_area &&
                              validation.errors.branding_space_area
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.branding_space_area &&
                          validation.errors.branding_space_area ? (
                            <FormFeedback type="invalid">
                              {validation.errors.branding_space_area}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-space-area-Input">
                            Event Space Area(Sqft)
                          </Label>
                          <Input
                            type="text"
                            id="event_space_area"
                            name="event_space_area"
                            placeholder="Event space Area"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.event_space_area || ""}
                            className={`form-control ${
                              validation.touched.event_space_area &&
                              validation.errors.event_space_area
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.event_space_area &&
                          validation.errors.event_space_area ? (
                            <FormFeedback type="invalid">
                              {validation.errors.event_space_area}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div className="inner-repeater mb-4">
                      <Label>Compliance* </Label>
                      <table style={{ width: "100%" }}>
                        <tbody>
                          {(rows1 || []).map((formRow, key) => (
                            <tr key={key}>
                              <td>
                                <Row className="mb-2">
                                  <Col md="3">
                                    <Label>Select Compliance* </Label>
                                    <select
                                      type="text"
                                      value={
                                        validation.values.compliance_data[key]
                                          ?.project_compliance_id || ""
                                      }
                                      onChange={e =>
                                        validation.handleChange({
                                          target: {
                                            name: `compliance_data[${key}].project_compliance_id`,
                                            value: e.target.value,
                                          },
                                        })
                                      }
                                      onBlur={validation.handleBlur}
                                      name={`compliance_data[${key}].project_compliance_id`}
                                      className={`inner form-select ${
                                        validation.touched.compliance_data?.[
                                          key
                                        ]?.project_compliance_id &&
                                        validation.errors.compliance_data?.[key]
                                          ?.project_compliance_id
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    >
                                      {!validation.values.compliance_data?.[key]
                                        ?.project_compliance_id && (
                                        <option>Select Compliances</option>
                                      )}
                                      {ActiveCompliances?.map(item => (
                                        <option key={item.id} value={item.id}>
                                          {item.compliance_title}
                                        </option>
                                      ))}
                                    </select>
                                    {validation.touched.compliance_data?.[key]
                                      ?.project_compliance_id &&
                                      validation.errors.compliance_data?.[key]
                                        ?.project_compliance_id && (
                                        <div className="invalid-feedback">
                                          {
                                            validation.errors.compliance_data?.[
                                              key
                                            ]?.project_compliance_id
                                          }
                                        </div>
                                      )}
                                  </Col>
                                  <Col md="2">
                                    <Label>From* </Label>
                                    <Input
                                      type="date"
                                      value={
                                        validation.values.compliance_data[key]
                                          ?.from_date || ""
                                      }
                                      onChange={e =>
                                        validation.handleChange({
                                          target: {
                                            name: `compliance_data[${key}].from_date`,
                                            value: e.target.value,
                                          },
                                        })
                                      }
                                      onBlur={validation.handleBlur}
                                      name={`compliance_data[${key}].from_date`}
                                      className={`form-control ${
                                        validation.touched.compliance_data?.[
                                          key
                                        ]?.from_date &&
                                        validation.errors.compliance_data?.[key]
                                          ?.from_date
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    {validation.touched.compliance_data?.[key]
                                      ?.from_date &&
                                      validation.errors.compliance_data?.[key]
                                        ?.from_date && (
                                        <div className="invalid-feedback">
                                          {
                                            validation.errors.compliance_data?.[
                                              key
                                            ]?.from_date
                                          }
                                        </div>
                                      )}
                                  </Col>
                                  <Col md="2">
                                    <Label>To* </Label>

                                    <Input
                                      type="date"
                                      value={
                                        validation.values.compliance_data[key]
                                          ?.to_date || ""
                                      }
                                      onChange={e =>
                                        validation.handleChange({
                                          target: {
                                            name: `compliance_data[${key}].to_date`,
                                            value: e.target.value,
                                          },
                                        })
                                      }
                                      onBlur={validation.handleBlur}
                                      name={`compliance_data[${key}].to_date`}
                                      className={`form-control ${
                                        validation.touched.compliance_data?.[
                                          key
                                        ]?.to_date &&
                                        validation.errors.compliance_data?.[key]
                                          ?.to_date
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    {validation.touched.compliance_data?.[key]
                                      ?.to_date &&
                                      validation.errors.compliance_data?.[key]
                                        ?.to_date && (
                                        <div className="invalid-feedback">
                                          {
                                            validation.errors.compliance_data?.[
                                              key
                                            ]?.to_date
                                          }
                                        </div>
                                      )}
                                  </Col>

                                  <Col md="3">
                                    <Label
                                      htmlFor={`compliance_data[${key}].document`}
                                    >
                                      Upload File{" "}
                                      <Link>
                                        <i
                                          className="mdi mdi-document"
                                          onClick={() =>
                                            handlePreview(
                                              validation.values.compliance_data[
                                                key
                                              ]?.document_url
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          {validation.values.compliance_data[
                                            key
                                          ]?.document_url
                                            ? "PREVIEW PREVIOUS DOCUMENT"
                                            : ""}
                                        </i>
                                      </Link>
                                    </Label>
                                    <input
                                      type="file"
                                      id={`compliance_data[${key}].document`}
                                      name={`compliance_data[${key}].document`}
                                      onChange={e => {
                                        const selectedFile = e.target.files[0]
                                        validation.setFieldValue(
                                          `compliance_data[${key}].document`,
                                          selectedFile
                                        )
                                      }}
                                      onBlur={validation.handleBlur}
                                      className={`form-control ${
                                        validation.touched[
                                          `compliance_data[${key}].document`
                                        ] &&
                                        validation.errors[
                                          `compliance_data[${key}].document`
                                        ]
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    {validation.touched[
                                      `compliance_data[${key}].document`
                                    ] &&
                                      validation.errors[
                                        `compliance_data[${key}].document`
                                      ] && (
                                        <div className="invalid-feedback">
                                          {
                                            validation.errors[
                                              `compliance_data[${key}].document`
                                            ]
                                          }
                                        </div>
                                      )}
                                  </Col>

                                  <Col md="1">
                                    <Button
                                      color="primary"
                                      className="btn-block inner mt-4"
                                      id="unknown-btn"
                                      // style={{ width: "100%" }}
                                      onClick={e => {
                                        handleRemoveRow(formRow.id)
                                      }}
                                    >
                                      {" "}
                                      Delete{" "}
                                    </Button>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Button
                        onClick={() => {
                          handleAddRowNested()
                        }}
                        color="success"
                        className="mt-1"
                      >
                        Add Compliance
                      </Button>
                    </div>
                    <Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="company_id">Billing Company*</Label>
                            <select
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              // defaultValue="IN"
                              value={validation.values.company_id}
                              name="company_id"
                              className={`form-select ${
                                validation.touched.company_id &&
                                validation.errors.company_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              {!validation.values.company_id && (
                                <option>Select Billing Company</option>
                              )}

                              {(company || []).map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {validation.touched.company_id &&
                            validation.errors.company_id ? (
                              <FormFeedback type="invalid">
                                {validation.errors.company_id}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="location_id">
                              Billing Company's Location*
                            </Label>
                            <select
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              // defaultValue="IN"
                              value={validation.values.location_id}
                              name="location_id"
                              className={`form-select ${
                                validation.touched.location_id &&
                                validation.errors.location_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >  
                             
                                <option value=''>
                                  Select Billing Company's Location
                                </option>
                             

                              {(companyLocation || []).map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.location_title}
                                </option>
                              ))}
                            </select>
                            {validation.touched.location_id &&
                            validation.errors.location_id ? (
                              <FormFeedback type="invalid">
                                {validation.errors.location_id}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {validation.values.company_id && (
                          <Col lg={6}>
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Billing Company Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="rent_escalation_percent">
                                        Company Name
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="address_line_1">
                                        Address
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.address_line_1}
                                        ,{" "}
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.address_line_2}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pin_code">Pincode</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.pin_code}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="country_name">
                                        Country
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.country_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="state_name">State</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.state_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="city_name">City</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.city_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="gst_no">GST Number</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.gst_no}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pan_no">PAN Number</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.pan_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                        {validation.values.location_id && (
                          <Col>
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Billing Company's Location Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pan_no">Address</Label>
                                      <p>
                                        {companyLocationDetail &&
                                          companyLocationDetail.location_title}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pan_no">Country</Label>
                                      <p>
                                        {companyLocationDetail &&
                                          companyLocationDetail.country_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pan_no">State</Label>
                                      <p>
                                        {companyLocationDetail &&
                                          companyLocationDetail.state_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pan_no">City</Label>
                                      <p>
                                        {companyLocationDetail &&
                                          companyLocationDetail.city_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pan_no">GST No.</Label>
                                      <p>
                                        {companyLocationDetail &&
                                          companyLocationDetail.gst_no}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                      </Row>
                      <Col>
                        <div className="mb-3 text-end">
                          <button
                            type="submit"
                            className="btn btn-primary l-md"
                          >
                            {isEditMode ? "Edit Project" : "Create Project"}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Addnewproject
