import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "../Project/projectContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { getProjects, deleteProject } from "store/actions"

const AllProjects = props => {
  document.title = "Project "
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dispatch = useDispatch()

  const projectState = state => state.projects.projects.data
  const loadingState = state => state.projects.loading
  const data = useSelector(projectState) || []

  const projectsProperties = createSelector(
    projectState,
    loadingState,
    (projects, loading) => {
      return {
        projects: projects,
        loading: loading,
      }
    }
  )

  const { projects, loading } = useSelector(projectsProperties)

  useEffect(() => {
    dispatch(getProjects(user.user_id))
  }, [dispatch])

  const [isLoading, setLoading] = useState(loading)
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Property Type",
        accessor: "property_type",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.property_type}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project Name",
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project Type",
        accessor: "project_type",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.project_type}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project Area",
        accessor: "project_area_sqft",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.project_area_sqft}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  className="font-size-16 text-muted"
                  role="button"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                  {permission
                    .filter(
                      item =>
                        item.page === "edit-project/:id" && item.access === 1
                    )
                    .map((item, index) => (
                      <Link
                        key={index}
                        to={`/edit-project/${cellProps.row.original.id}`}
                      >
                        <DropdownItem href="#">Edit</DropdownItem>
                      </Link>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "delete-project" && item.access === 1
                    )
                    .map((item, index) => (
                      <DropdownItem
                        key={index}
                        href="#"
                        onClick={() => {
                          const userData = cellProps.row.original.id
                          onClickDelete(userData)
                        }}
                      >
                        Delete
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              {permission
                .filter(
                  item =>
                    item.page === "project-details/:id" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to={`/project-details/${cellProps.row.original.id}`}
                    className="text-success"
                  >
                    <i
                      className="mdi mdi-chevron-right font-size-18"
                      id="edittooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      View
                    </UncontrolledTooltip>
                  </Link>
                ))}
            </div>
          )
        },
      },
    ],
    []
  )

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteProject(deleteId.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
    dispatch(getProjects())
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Project list" breadcrumbItem="All Project" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AllProjects)
