import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//flatpickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Table,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  getAllProjectsForCustomerOustanding,
  getLedgerDetail,
  getProjectListByCustomerId,
  getUnitForConsumption,
} from "store/actions"
import { downloadLedger } from "helpers/fakebackend_helper"
import formatNumberWithCommas from "components/Common/CommaSeparator"
import { API_URL } from "helpers/url_helper"

const ViewLedger = props => {
  document.title = "Ledger"
  const dispatch = useDispatch()
  const ledgerState = state => state.oustandingReports.ledgerData.data
  const LedgerData = useSelector(ledgerState) || []
  const ledger = state => state.oustandingReports.ledgerData
  const ledgerOtherData = useSelector(ledger) || []
  const companyState = state =>
    state.oustandingReports?.ledgerData?.company_data
  const companyData = useSelector(companyState) || []
  const locationState = state =>
    state.oustandingReports?.ledgerData?.location_data
  const locationData = useSelector(locationState) || []
  const [customer, setCustomer] = useState("")
  console.log(companyData)
  const location = useLocation()

  const [type, setType] = useState()

  const navigate = useNavigate()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [project, setProject] = useState("")
  const UnitState = state => state.consumptionReport.unit.project_units
  const unitList = useSelector(UnitState) || []
  const [unit, setUnit] = useState("")
  const projectstate = state => state.common?.projectList?.data
  const projectList = useSelector(projectstate) || []

  const handleExport = async () => {
    const value = {
      type: "Ledger",
      project_id: project,
      client_id: customer,
      client_project_unit_id: unit,
      from_date: validation.values.from_date,
      to_date: validation.values.to_date,
      user_id: user.user_id,
    }

    const downloadUrl = await downloadLedger(value)
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  const validation = useFormik({
    initialValues: {
      from_date: "",
      to_date: "",
    },
    validate: values => {
      const errors = {}
      if (values.from_date && !values.to_date) {
        errors.to_date = "To date is required if from date is filled."
      }
      return errors
    },
  })
  const { id } = useParams()

  const sumValues = () => {
    let x1, x2
    if (
      ledgerOtherData &&
      parseFloat(ledgerOtherData.total_outstanding_amount) > 0
    ) {
      x2 = parseFloat(ledgerOtherData.total_outstanding_amount)
      x1 = 0.0
    } else {
      x2 = 0.0
      x1 = Math.abs(parseFloat(ledgerOtherData.total_outstanding_amount))
    }

    const value1 = (
      parseFloat(ledgerOtherData.total_billing_amount) + x1
    ).toFixed(2)
    const value2 = (
      parseFloat(ledgerOtherData.total_receipt_amount) + x2
    ).toFixed(2)

    return { value1, value2 }
  }

  useEffect(() => {
    if (id) {
      const value = {
        client_id: id,
      }
      dispatch(getLedgerDetail(value))
      dispatch(getProjectListByCustomerId(id))
      setCustomer(id)
    }
  }, [id])

  const handleSubmit = async () => {
    if (
      project ||
      customer ||
      unit ||
      validation.values.from_date ||
      validation.values.to_date
    ) {
      const value = {
        client_id: customer,
        project_id: project,
        client_project_unit_id: unit,
        from_date: validation.values.from_date,
        to_date: validation.values.to_date,
      }
      dispatch(getLedgerDetail(value))
    }
  }

  useEffect(() => {
    if (project !== "") {
      const value = {
        project_id: project,
        client_id: customer,
      }
      dispatch(getUnitForConsumption(value))
    } else if (project === "") {
      setUnit("")
    }
  }, [project])

  const handleSelectProject = ele => {
    const value = ele.value
    setProject(value)
  }
  const handleSelectUnit = ele => {
    const value = ele.value
    setUnit(value)
  }
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={10}>
              <Card className="overflow-hidden">
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr>
                          <td style={{ borderBottomWidth: 0 }}>
                            {" "}
                            <div className="d-flex gap-2 mb-3">
                              <i
                                className="mdi mdi-arrow-left font-size-20"
                                style={{ color: "grey", marginRight: "15px" }}
                                onClick={() => {
                                  navigate(
                                    "/reports/customer-oustanding-report"
                                  )
                                }}
                              ></i>

                              <span
                                style={{
                                  color: "black",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                }}
                              ></span>
                            </div>
                          </td>
                          <td style={{ borderBottomWidth: 0 }}>
                            <select
                              className="form-select select2 mb-3 mb-xxl-0"
                              defaultValue="Select Project"
                              onChange={e => handleSelectProject(e.target)}
                            >
                              <option value="">Select Project</option>
                              {(projectList || []).map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.project_name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td style={{ borderBottomWidth: 0 }}>
                            <select
                              className="form-select select2 mb-3 mb-xxl-0"
                              defaultValue="Select Unit"
                              onChange={e => handleSelectUnit(e.target)}
                              value={unit}
                            >
                              <option value="">Select Unit</option>
                              {(unitList || []).map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.unit_code}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td style={{ borderBottomWidth: 0 }}>
                            <div className="mb-3">
                              <Input
                                type="date"
                                className={`form-control mb-3 mb-xxl-0 ${
                                  validation.touched.from_date &&
                                  validation.errors.from_date
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="from_date"
                                placeholder="Select From date"
                                value={validation.values.from_date}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.from_date &&
                              validation.errors.from_date ? (
                                <div className="invalid-feedback">
                                  {validation.errors.from_date}
                                </div>
                              ) : null}
                            </div>
                          </td>
                          <td style={{ borderBottomWidth: 0 }}>
                            <div className="mb-3">
                              <Input
                                dateFormat="dd/mm/yyyy"
                                type="date"
                                className={`form-control mb-3 mb-xxl-0 ${
                                  validation.touched.to_date &&
                                  validation.errors.to_date
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="to_date"
                                placeholder="Select to date"
                                value={validation.values.to_date}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.to_date &&
                              validation.errors.to_date ? (
                                <div className="invalid-feedback">
                                  {validation.errors.to_date}
                                </div>
                              ) : null}
                            </div>
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              borderBottomWidth: 0,
                            }}
                          >
                            <button
                              className="btn btn-success btn-sm"
                              onClick={handleSubmit}
                              type="button"
                            >
                              Submit
                            </button>
                          </td>
                          {permission
                            .filter(
                              item =>
                                item.page === "/export-client-ledger" &&
                                item.access === 1
                            )
                            .map(index => (
                              <td
                                key={index}
                                style={{
                                  textAlign: "end",
                                  borderBottomWidth: 0,
                                }}
                              >
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={handleExport}
                                  type="button"
                                >
                                  Export
                                </button>
                              </td>
                            ))}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <Col md={8} lg={8} xl={10}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="table-responsive">
                  {companyData && companyData?.[0]?.name && companyData && project !== "" && (
                      <Row className="justify-content-center  mt-3 ms-0 me-0">
                        <h4 className="text-center text-dark">
                          {companyData && companyData?.[0]?.name}
                        </h4>
                        <h6 className="text-center ">
                          {locationData && locationData?.[0]?.location_title}
                        </h6>
                        <h6 className="text-center ">
                          <b> CIN : </b>{" "}
                          {companyData && companyData?.[0]?.cin_no} ||{" "}
                          <b> PAN : </b>{" "}
                          {companyData && companyData?.[0]?.pan_no}
                        </h6>

                        <h6 className="text-center ">
                          <b> Email :</b>{" "}
                          {locationData && locationData?.[0]?.account_email} ||{" "}
                          <b> Phone no : </b>{" "}
                          {locationData && locationData?.[0]?.contact_phone}
                        </h6>
                      </Row>
                    )}
                    <hr></hr>
                    <h4 className="text-center mt-4  text-dark">
                      Ledger Account{" "}
                    </h4>
                    {validation.values.from_date &&
                      validation.values.to_date && (
                        <p className="text-center text-dark mb-4">
                          From{" "}
                          {validation.values.from_date
                            ? new Date(
                                validation.values.from_date
                              ).toLocaleDateString("en-GB")
                            : "N/A"}{" "}
                          to{" "}
                          {validation.values.to_date
                            ? new Date(
                                validation.values.to_date
                              ).toLocaleDateString("en-GB")
                            : "N/A"}
                        </p>
                      )}

                    <Table className="table mb-0 mt-4">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingRight: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            <strong>Customer Name</strong>
                          </td>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingBottom: "15px",
                            }}
                          >
                            :
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              borderBottomWidth: 0,
                              paddingBottom: "15px",
                            }}
                          >
                            {(ledgerOtherData &&
                              ledgerOtherData.customer_name) ||
                              "NA"}
                          </td>

                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingRight: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            <strong>Customer Code</strong>
                          </td>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingBottom: "15px",
                            }}
                          >
                            :
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              borderBottomWidth: 0,
                              paddingLeft: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            {(ledgerOtherData &&
                              ledgerOtherData.customer_code) ||
                              "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingRight: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            <strong>Address</strong>
                          </td>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingBottom: "15px",
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingBottom: "15px",
                            }}
                          >
                            {(ledgerOtherData && ledgerOtherData.address) ||
                              "NA"}
                          </td>
                          <td
                            style={{
                              borderBottomWidth: 0,
                              paddingLeft: "15px",
                              paddingBottom: "15px",
                            }}
                          >
                            {" "}
                          </td>

                          {type === "project" || type === "projects" ? (
                            <>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingRight: "15px",
                                  paddingBottom: "15px",
                                }}
                              >
                                <strong>Project</strong>
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingBottom: "15px",
                                }}
                              >
                                :
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingBottom: "15px",
                                }}
                              >
                                {type === "project"
                                  ? ledgerOtherData?.project_names
                                  : "All"}
                              </td>
                            </>
                          ) : null}

                          {type === "company" || type === "companies" ? (
                            <>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingRight: "15px",
                                  paddingBottom: "15px",
                                }}
                              >
                                <strong>Company</strong>
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingBottom: "15px",
                                }}
                              >
                                :
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingBottom: "15px",
                                }}
                              >
                                {type === "company"
                                  ? ledgerOtherData?.company_names
                                  : "All"}
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: 0,
                                  paddingLeft: "15px",
                                  paddingBottom: "15px",
                                }}
                              >
                                {" "}
                              </td>
                            </>
                          ) : null}
                        </tr>
                      </tbody>
                    </Table>
                    <Table
                      style={{
                        border: "1px solid #EEE",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Voucher Date
                        </th>
                        <th
                          colSpan={2}
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Particulars{" "}
                        </th>

                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Voucher Type{" "}
                        </th>
                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Voucher No.{" "}
                        </th>
                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Debit
                        </th>
                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        >
                          Credit
                        </th>
                      </tr>
                      {LedgerData &&
                        LedgerData.map((d, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                                textWrap: "nowrap",
                              }}
                            >
                              {d.date}
                            </td>
                            <td
                              colSpan={2}
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                              }}
                            >
                              {d.description}
                            </td>
                            <td
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                              }}
                            >
                              {d.transaction_type}
                            </td>
                            <td
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                              }}
                            >
                              {d.bill_no}
                            </td>

                            <td
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                                textAlign: "end",
                              }}
                            >
                              {formatNumberWithCommas(d.amount_debit) === 0
                                ? ""
                                : formatNumberWithCommas(parseFloat(d.amount_debit).toFixed(2))}
                            </td>
                            <td
                              style={{
                                padding: "15px",
                                border: "1px solid #EEE",
                                borderCollapse: "collapse",
                                textAlign: "end",
                              }}
                            >
                              {formatNumberWithCommas(d.amount_credit) === 0
                                ? ""
                                : formatNumberWithCommas(parseFloat(d.amount_credit).toFixed(2))}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderTop: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        ></td>

                        <th
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderTop: "2px solid black",
                            borderCollapse: "collapse",
                          }}
                        >
                          {/* Total */}
                        </th>
                        <td
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderTop: "2px solid black",
                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          {formatNumberWithCommas(
                            ledgerOtherData?.total_billing_amount
                          ) || "0.00"}
                        </td>
                        <td
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderTop: "2px solid black",
                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          {formatNumberWithCommas(
                            ledgerOtherData?.total_receipt_amount
                          ) || "0.00"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderBottom: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        ></td>

                        <th
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderBottom: "2px solid black",
                            borderCollapse: "collapse",
                          }}
                        >
                          Closing Balance
                        </th>
                        <td
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderBottom: "2px solid black",

                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          {isNaN(
                            parseFloat(ledgerOtherData.total_outstanding_amount)
                          )
                            ? "0.00"
                            : parseFloat(
                                ledgerOtherData.total_outstanding_amount
                              ) < 0
                            ? formatNumberWithCommas(
                                Math.abs(
                                  parseFloat(
                                    ledgerOtherData.total_outstanding_amount
                                  )
                                ).toFixed(2)
                              )
                            : "0.00"}
                        </td>
                        <td
                          style={{
                            padding: "15px",
                            borderLeft: "1px solid #EEE",
                            borderRight: "1px solid #EEE",
                            borderBottom: "2px solid black",
                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          {parseFloat(
                            ledgerOtherData.total_outstanding_amount
                          ) > 0
                            ? formatNumberWithCommas(
                                ledgerOtherData.total_outstanding_amount
                              )
                            : "0.00"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderCollapse: "collapse",
                          }}
                        ></td>

                        <th
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderBottom: "2px solid black",
                            textAlign: "center",
                            borderCollapse: "collapse",
                          }}
                        >
                          Total
                        </th>
                        <td
                          style={{
                            padding: "15px",

                            border: "1px solid #EEE",
                            borderBottom: "2px solid black",
                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          <strong className="p-0">
                            {formatNumberWithCommas(sumValues()?.value1)}
                          </strong>
                        </td>
                        <td
                          style={{
                            padding: "15px",
                            border: "1px solid #EEE",
                            borderBottom: "2px solid black",

                            borderCollapse: "collapse",
                            textAlign: "end",
                          }}
                        >
                          <strong className="p-0">
                            {formatNumberWithCommas(sumValues()?.value2)}
                          </strong>
                        </td>
                      </tr>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ViewLedger.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ViewLedger)
