import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./VendorPaymentHistoryListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"

import { getPaymentHistoryForVendorPayment } from "store/actions"
import formatNumberWithCommas from "components/Common/CommaSeparator"
import Swal from "sweetalert2"
import { deletePaymentHistoryForVendorPayment } from "helpers/fakebackend_helper"

const VendorPaymentHistoryList = () => {
  const navigate = useNavigate()
  document.title = "Payment List "
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dispatch = useDispatch()
  const billPaymentListState = state =>
    state.VendorPaymentList.paymentHistory.data
  const billPayment = useSelector(billPaymentListState) || []

  useEffect(() => {
    dispatch(getPaymentHistoryForVendorPayment())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Payment No.",
        accessor: "payment_inv_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.payment_inv_no}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Payment Date",
        accessor: "payment_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.payment_date}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Project Name",
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.company_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.vendor_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Payment Amount",
        accessor: "amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {formatNumberWithCommas(cellProps.row.original.amount_paid) ||
                    ""}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "TDS Amount",
        accessor: "total_tds_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {formatNumberWithCommas(
                    cellProps.row.original.total_tds_amount
                  ) || ""}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Action",

        Cell: cellProps => {
          return (
            <>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original.payment_id
                  onClickDelete(userData)
                }}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = async () => {
    if (deleteId && deleteId.id) {
      const response = await deletePaymentHistoryForVendorPayment(deleteId.id)

      if (response.status) {
        dispatch(getPaymentHistoryForVendorPayment())
      }
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleUserClicks = () => {
    setUserList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Payment" breadcrumbItem="Payment History" />

          <Row>
            {/* {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : ( */}
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    isPagination={true}
                    columns={columns}
                    data={billPayment || []}
                    isGlobalFilter={true}
                    isShowingPageLength={true}
                    isAddUserList={true}
                    iscustomPageSizeOptions={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    tableClass="table align-middle table-nowrap table-hover"
                    theadClass="table-light"
                    paginationDiv="col-sm-12 col-md-7"
                    pagination="pagination pagination-rounded justify-content-end mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
            {/* )} */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorPaymentHistoryList
