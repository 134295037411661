import {
  GET_SACHSNCODE_SUCCESS,
  GET_SACHSNCODE_FAIL,
  GET_SACHSNCODE_DETAIL_SUCCESS,
  GET_SACHSNCODE_DETAIL_FAIL,
  ADD_NEW_SACHSNCODE_SUCCESS,
  ADD_NEW_SACHSNCODE_FAIL,
  UPDATE_SACHSNCODE_SUCCESS,
  UPDATE_SACHSNCODE_FAIL,
  DELETE_SACHSNCODE_SUCCESS,
  DELETE_SACHSNCODE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  code: [],
  addCode: [],
  codeDetail: {},
  error: {},
  loading: true,
}

const sachsn = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SACHSNCODE_SUCCESS:
      return {
        ...state,
        code: action.payload,
        loading: false,
      }

    case GET_SACHSNCODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_SACHSNCODE_SUCCESS:
      return {
        ...state,
        addCode: [...state.addCode, action.payload],
      }

    case ADD_NEW_SACHSNCODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SACHSNCODE_DETAIL_SUCCESS:
      return {
        ...state,
        codeDetail: action.payload,
        loading: false,
      }

    case GET_SACHSNCODE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_SACHSNCODE_SUCCESS:
      return {
        ...state,
        addCode: state.addCode.map(code =>
          code.id === action.payload.id ? { ...code, ...action.payload } : code
        ),
      }

    case UPDATE_SACHSNCODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SACHSNCODE_SUCCESS:
      return {
        ...state,
        code: state.code.data.filter(
          code => code.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_SACHSNCODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default sachsn
