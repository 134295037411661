import {
    GET_COMPLIANCES,
    GET_COMPLIANCES_FAIL,
    GET_COMPLIANCES_SUCCESS,
    GET_ACTIVE_COMPLIANCES,
    GET_ACTIVE_COMPLIANCES_FAIL,
    GET_ACTIVE_COMPLIANCES_SUCCESS,
    GET_COMPLIANCE_DETAIL,
    ADD_NEW_COMPLIANCE,
    ADD_COMPLIANCE_SUCCESS,
    ADD_COMPLIANCE_FAIL,
    UPDATE_COMPLIANCE,
    UPDATE_COMPLIANCE_SUCCESS,
    UPDATE_COMPLIANCE_FAIL,
    DELETE_COMPLIANCE,
    DELETE_COMPLIANCE_SUCCESS,
    DELETE_COMPLIANCE_FAIL,
    GET_COMPLIANCE_DETAIL_FAIL,
    GET_COMPLIANCE_DETAIL_SUCCESS,
    GET_PROJECT_COMPLIANCES,
    GET_PROJECT_COMPLIANCE_SUCCESS,
    GET_PROJECT_COMPLIANCE_FAIL
  } from "./actionTypes"
  
  export const getCompliances = () => ({
    type: GET_COMPLIANCES,
  })
  
  export const getCompliancesSuccess = compliances => ({
    type: GET_COMPLIANCES_SUCCESS,
    payload: compliances,
  })
  export const getActiveCompliances = () => ({
    type: GET_ACTIVE_COMPLIANCES,
  })
  
  export const getActiveCompliancesSuccess = compliances => ({
    type: GET_ACTIVE_COMPLIANCES_SUCCESS,
    payload: compliances,
  })
  
  export const getActiveCompliancesFail = error => ({
    type: GET_ACTIVE_COMPLIANCES_FAIL,
    payload: error,
  })

  export const addNewCompliance = compliance => ({
    type: ADD_NEW_COMPLIANCE,
    payload: compliance,
  })
  
  export const addComplianceSuccess = compliance => ({
    type: ADD_COMPLIANCE_SUCCESS,
    payload: compliance,
  })
  
  export const addComplianceFail = error => ({
    type: ADD_COMPLIANCE_FAIL,
    payload: error,
  })
  
  export const updateCompliance = (id,compliance) => ({
    type: UPDATE_COMPLIANCE,
    payload: {id,compliance},
  })
  
  export const updateComplianceSuccess = compliance => ({
    type: UPDATE_COMPLIANCE_SUCCESS,
    payload: compliance,
  })
  
  export const updateComplianceFail = error => ({
    type: UPDATE_COMPLIANCE_FAIL,
    payload: error,
  })
  
  export const deleteCompliance = compliance => ({
    type: DELETE_COMPLIANCE,
    payload: compliance,
  })
  
  export const deleteComplianceSuccess = compliance => ({
    type: DELETE_COMPLIANCE_SUCCESS,
    payload: compliance,
  })
  
  export const deleteComplianceFail = error => ({
    type: DELETE_COMPLIANCE_FAIL,
    payload: error,
  })
  
  export const getCompliancesFail = error => ({
    type: GET_COMPLIANCES_FAIL,
    payload: error,
  })
  
  export const getComplianceDetail = complianceId => ({
    type: GET_COMPLIANCE_DETAIL,
    complianceId,
  })
  
  export const getComplianceDetailSuccess = complianceDetails => ({
    type: GET_COMPLIANCE_DETAIL_SUCCESS,
    payload: complianceDetails,
  })
  
  export const getComplianceDetailFail = error => ({
    type: GET_COMPLIANCE_DETAIL_FAIL,
    payload: error,
  })
  
  export const getProjectCompliance = complianceId => ({
    type: GET_PROJECT_COMPLIANCES,
    payload: complianceId,
  })
  
  export const getProjectComplianceSuccess = complianceDetails => ({
    type: GET_PROJECT_COMPLIANCE_SUCCESS,
    payload: complianceDetails,
  })
  
  export const getProjectComplianceFail = error => ({
    type: GET_PROJECT_COMPLIANCE_FAIL,
    payload: error,
  })
  