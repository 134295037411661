import { call, put, takeEvery } from "redux-saga/effects"

import {
  
  GET_CONSUMPTION_FILTER,
  GET_CUSTOMER_FILTER_FOR_CONSUMPTION,
  GET_PROJECT_FILTER_FOR_CONSUMPTION,
  GET_UNIT_FOR_CONSUMPTION,
} from "./actionTypes"
import {
 
  getUnitForConsumptionSuccess,
  getUnitForConsumptionFail,
  getProjectFilterForConsumptionFail,
  getProjectFilterForConsumptionSuccess,
  getCustomerFilterForConsumptionFail,
  getCustomerFilterForConsumptionSuccess,
  getConsumptionFilterSuccess,
  getConsumptionFilterFail,
} from "./actions"

import { getConsumptionFilter, getCustomerFilterForConsumption, getProjectFilterForConsumption, getUnitForConsumption } from "helpers/fakebackend_helper"


function* fetchCustomerFilterForConsumption({ userId }) {
  try {
    const response = yield call(getCustomerFilterForConsumption, userId)
    yield put(getCustomerFilterForConsumptionSuccess(response))
  } catch (error) {
    yield put(getCustomerFilterForConsumptionFail(error))
  }
}

function* fetchProjectFilterForConsumption({ userId }) {
  try {
    const response = yield call(getProjectFilterForConsumption, userId)
    yield put(getProjectFilterForConsumptionSuccess(response))
  } catch (error) {
    yield put(getProjectFilterForConsumptionFail(error))
  }
}

function* fetchUnitForConsumption({ data }) {
  try {
    const response = yield call(getUnitForConsumption, data)
    yield put(getUnitForConsumptionSuccess(response))
  } catch (error) {
    yield put(getUnitForConsumptionFail(error))
  }
}
function* fetchConsumptionFilter({ data }) {
  try {
    const response = yield call(getConsumptionFilter, data)
    yield put(getConsumptionFilterSuccess(response))
  } catch (error) {
    yield put(getConsumptionFilterFail(error))
  }
}

function* ConsumptionReportSaga() {

  yield takeEvery(GET_CUSTOMER_FILTER_FOR_CONSUMPTION, fetchCustomerFilterForConsumption)
  yield takeEvery(GET_PROJECT_FILTER_FOR_CONSUMPTION, fetchProjectFilterForConsumption)
  yield takeEvery(GET_UNIT_FOR_CONSUMPTION, fetchUnitForConsumption)
  yield takeEvery(GET_CONSUMPTION_FILTER, fetchConsumptionFilter)
}

export default ConsumptionReportSaga
