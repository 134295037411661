export const GET_CLIENTS = "GET_CLIENTS"
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL"

export const GET_CLIENTS_LIST = "GET_CLIENTS_LIST"
export const GET_CLIENTS_LIST_SUCCESS = "GET_CLIENTS_LIST_SUCCESS"
export const GET_CLIENTS_LIST_FAIL = "GET_CLIENTS_LIST_FAIL"

export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT"
export const ADD_NEW_CLIENT_SUCCESS = "ADD_NEW_CLIENT_SUCCESS"
export const ADD_NEW_CLIENT_FAIL = "ADD_NEW_CLIENT_FAIL"

export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS"
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL"

export const DELETE_CLIENT = "DELETE_CLIENT"
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS"
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL"

// CLIENTS ASSIGNED PROJECT UNIT

export const GET_ASSIGNED_PROJECT = "GET_ASSIGNED_PROJECT"
export const GET_ASSIGNED_PROJECT_SUCCESS = "GET_ASSIGNED_PROJECT_SUCCESS"
export const GET_ASSIGNED_PROJECT_FAIL = "GET_ASSIGNED_PROJECT_FAIL"

export const GET_ASSIGNED_PROJECT_LIST = "GET_ASSIGNED_PROJECT_LIST"
export const GET_ASSIGNED_PROJECT_LIST_SUCCESS = "GET_ASSIGNED_PROJECT_LIST_SUCCESS"
export const GET_ASSIGNED_PROJECT_LIST_FAIL = "GET_ASSIGNED_PROJECT_LIST_FAIL"

export const ADD_ASSIGNED_PROJECT = "ADD_ASSIGNED_PROJECT"
export const ADD_ASSIGNED_PROJECT_SUCCESS = "ADD_ASSIGNED_PROJECT_SUCCESS"
export const ADD_ASSIGNED_PROJECT_FAIL = "ADD_ASSIGNED_PROJECT_FAIL"

export const UPDATE_ASSIGNED_PROJECT = "UPDATE_ASSIGNED_PROJECT"
export const UPDATE_ASSIGNED_PROJECT_SUCCESS = "UPDATE_ASSIGNED_PROJECT_SUCCESS"
export const UPDATE_ASSIGNED_PROJECT_FAIL = "UPDATE_ASSIGNED_PROJECT_FAIL"

export const DELETE_ASSIGNED_PROJECT = "DELETE_ASSIGNED_PROJECT"
export const DELETE_ASSIGNED_PROJECT_SUCCESS = "DELETE_ASSIGNED_PROJECT_SUCCESS"
export const DELETE_ASSIGNED_PROJECT_FAIL = "DELETE_ASSIGNED_PROJECT_FAIL"

export const GET_ALL_ASSIGNED_PROJECT_UNITS = "GET_ALL_ASSIGNED_PROJECT_UNITS"
export const GET_ALL_ASSIGNED_PROJECT_UNITS_SUCCESS = "GET_ALL_ASSIGNED_PROJECT_UNITS_SUCCESS"
export const GET_ALL_ASSIGNED_PROJECT_UNITS_FAIL = "GET_ALL_ASSIGNED_PROJECT_UNITS_FAIL"


// CLIENTS DOCUMENTATION

export const GET_CLIENT_DOCUMENTS = "GET_CLIENT_DOCUMENTS"
export const GET_CLIENT_DOCUMENTS_SUCCESS = "GET_CLIENT_DOCUMENTS_SUCCESS"
export const GET_CLIENT_DOCUMENTS_FAIL = "GET_CLIENT_DOCUMENTS_FAIL"

export const ADD_NEW_CLIENT_DOCUMENT = "ADD_NEW_CLIENT_DOCUMENT"
export const ADD_NEW_CLIENT_DOCUMENT_SUCCESS = "ADD_NEW_CLIENT_DOCUMENT_SUCCESS"
export const ADD_NEW_CLIENT_DOCUMENT_FAIL = "ADD_NEW_CLIENT_DOCUMENT_FAIL"

export const DELETE_CLIENT_DOCUMENT = "DELETE_CLIENT_DOCUMENT"
export const DELETE_CLIENT_DOCUMENT_SUCCESS = "DELETE_CLIENT_DOCUMENT_SUCCESS"
export const DELETE_CLIENT_DOCUMENT_FAIL = "DELETE_CLIENT_DOCUMENT_FAIL"

//REINVITE CLIENT

export const REINVITE_CLIENT = "REINVITE_CLIENT"
export const REINVITE_CLIENT_SUCCESS = "REINVITE_CLIENT_SUCCESS"
export const REINVITE_CLIENT_FAIL = "REINVITE_CLIENT_FAIL"

///PROJECT UNIT AND PROJECT
export const GET_PROJECT_LIST_FOR_CLIENT = "GET_PROJECT_LIST_FOR_CLIENT"
export const GET_PROJECT_LIST_FOR_CLIENT_SUCCESS = "GET_PROJECT_LIST_FOR_CLIENT_SUCCESS"
export const GET_PROJECT_LIST_FOR_CLIENT_FAIL = "GET_PROJECT_LIST_FOR_CLIENT_FAIL"

export const GET_PROJECT_UNIT_LIST_FOR_CLIENT = "GET_PROJECT_UNIT_LIST_FOR_CLIENT"
export const GET_PROJECT_UNIT_LIST_FOR_CLIENT_SUCCESS = "GET_PROJECT_UNIT_LIST_FOR_CLIENT_SUCCESS"
export const GET_PROJECT_UNIT_LIST_FOR_CLIENT_FAIL = "GET_PROJECT_UNIT_LIST_FOR_CLIENT_FAIL"

//GET CLIENT CODE 

export const GET_CLIENT_CODE = "GET_CLIENT_CODE"
export const GET_CLIENT_CODE_SUCCESS = "GET_CLIENT_CODE_SUCCESS"
export const GET_CLIENT_CODE_FAIL = "GET_CLIENT_CODE_FAIL"

export const GET_INVOICE_TYPE_MASTER_CLIENT_LIST ="GET_INVOICE_TYPE_MASTER_CLIENT_LIST"
export const GET_INVOICE_TYPE_MASTER_CLIENT_LIST_SUCCESS ="GET_INVOICE_TYPE_MASTER_CLIENT_LIST_SUCCESS"
export const GET_INVOICE_TYPE_MASTER_CLIENT_LIST_FAIL ="GET_INVOICE_TYPE_MASTER_CLIENT_LIST_FAIL"


export const GET_SACHSN_CODE_CLIENT = "GET_SACHSN_CODE_CLIENT"
export const GET_SACHSN_CODE_CLIENT_SUCCESS = "GET_SACHSN_CODE_CLIENT_SUCCESS"
export const GET_SACHSN_CODE_CLIENT_FAIL = "GET_SACHSN_CODE_CLIENT_FAIL"

export const GET_APPLICABLE_RATE_CLIENT_LIST = "GET_APPLICABLE_RATE_CLIENT_LIST"
export const GET_APPLICABLE_RATE_CLIENT_LIST_SUCCESS = "GET_APPLICABLE_RATE_CLIENT_LIST_SUCCESS"
export const GET_APPLICABLE_RATE_CLIENT_LIST_FAIL = "GET_APPLICABLE_RATE_CLIENT_LIST_FAIL"
