import {
  GET_PROPOSALS_FAIL,
  GET_PROPOSALS_SUCCESS,
  GET_PROPOSAL_DETAIL_FAIL,
  GET_PROPOSAL_DETAIL_SUCCESS,
  ADD_PROPOSAL_SUCCESS,
  ADD_PROPOSAL_FAIL,
  UPDATE_PROPOSAL_SUCCESS,
  UPDATE_PROPOSAL_FAIL,
  DELETE_PROPOSAL_SUCCESS,
  DELETE_PROPOSAL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  proposals: [],
  proposalDetail: {},
  error: {},
  loading: true,
}

const proposals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROPOSALS_SUCCESS:
      return {
        ...state,
        proposals: action.payload,
        loading: false,
      }

    case GET_PROPOSALS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposals: [...state.proposals, action.payload],
      }

    case ADD_PROPOSAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROPOSAL_DETAIL_SUCCESS:
      return {
        ...state,
        proposalDetail: action.payload,
      }

    case UPDATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposals: state.proposals.map(proposal =>
          proposal.id.toString() === action.payload.id.toString()
            ? { proposal, ...action.payload }
            : proposal
        ),
      }

    case UPDATE_PROPOSAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposals: state.proposals.filter(
          proposal => proposal.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_PROPOSAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROPOSAL_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default proposals
