import { call, put, takeEvery } from "redux-saga/effects"

//  Redux States
import {
  GET_COUNT_FOR_TICKETS_DASHBOARD,
  GET_CRITICAL_PRIORITY_TICKET_LIST,
  GET_HIGH_PRIORITY_TICKET_LIST,
  GET_LOW_PRIORITY_TICKET_LIST,
  GET_MEDIUM_PRIORITY_TICKET_LIST,
  GET_OPEN_CLOSE_TICKET_GRAPH,
  GET_RECENT_TICKETS_LIST,
  GET_TICKETS_STATUS_PIE_DIGRAM,
  GET_USER_VENDOR_WITH_MOST_TICKET,
} from "./actionTypes"
import {
  getCountForTicketsDashboardFail,
  getCountForTicketsDashboardSuccess,
  getCriticalPriorityTicketListFail,
  getCriticalPriorityTicketListSuccess,
  getHighPriorityTicketListFail,
  getHighPriorityTicketListSuccess,
  getLowPriorityTicketListFail,
  getLowPriorityTicketListSuccess,
  getMediumPriorityTicketListFail,
  getMediumPriorityTicketListSuccess,
  getOpenCloseTicketGraphFail,
  getOpenCloseTicketGraphSuccess,
  getRecentTicketListFail,
  getRecentTicketListSuccess,
  getTicketStatusPieDigramFail,
  getTicketStatusPieDigramSuccess,
  getUserVendorWithMostTicketFail,
  getUserVendorWithMostTicketSuccess,
} from "./actions"

import {
  getCountForTicketsDashboard,
  getCriticalPriorityTicketList,
  getHighPriorityTicketList,
  getLowPriorityTicketList,
  getMediumPriorityTicketList,
  getOpenCloseTicketGraph,
  getRecentTicketList,
  getTicketStatusPieDigram,
  getUserVendorWithMostTicket,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

function* fetchCountForTicketsDashboard() {
  try {
    const response = yield call(getCountForTicketsDashboard)
    yield put(getCountForTicketsDashboardSuccess(response))
  } catch (error) {
    yield put(getCountForTicketsDashboardFail(error))
  }
}
function* fetchCriticalPriorityTicketList() {
  try {
    const response = yield call(getCriticalPriorityTicketList)
    yield put(getCriticalPriorityTicketListSuccess(response))
  } catch (error) {
    yield put(getCriticalPriorityTicketListFail(error))
  }
}
function* fetchHighPriorityTicketList() {
  try {
    const response = yield call(getHighPriorityTicketList)
    yield put(getHighPriorityTicketListSuccess(response))
  } catch (error) {
    yield put(getHighPriorityTicketListFail(error))
  }
}
function* fetchMediumPriorityTicketList() {
  try {
    const response = yield call(getMediumPriorityTicketList)
    yield put(getMediumPriorityTicketListSuccess(response))
  } catch (error) {
    yield put(getMediumPriorityTicketListFail(error))
  }
}
function* fetchLowPriorityTicketList() {
  try {
    const response = yield call(getLowPriorityTicketList)
    yield put(getLowPriorityTicketListSuccess(response))
  } catch (error) {
    yield put(getLowPriorityTicketListFail(error))
  }
}
function* fetchRecentTicketList() {
  try {
    const response = yield call(getRecentTicketList)
    yield put(getRecentTicketListSuccess(response))
  } catch (error) {
    yield put(getRecentTicketListFail(error))
  }
}
function* fetchUserVendorWithMostTicket() {
  try {
    const response = yield call(getUserVendorWithMostTicket)
    yield put(getUserVendorWithMostTicketSuccess(response))
  } catch (error) {
    yield put(getUserVendorWithMostTicketFail(error))
  }
}

function* fetchOpenCloseTicketGraph({ payload: openCloseTicketGraph }) {
  try {
    const response = yield call(getOpenCloseTicketGraph, openCloseTicketGraph)
    yield put(getOpenCloseTicketGraphSuccess(response))
  } catch (error) {
    yield put(getOpenCloseTicketGraphFail(error))
  }
}
function* fetchTicketStatusPieDigram({ payload: ticketStatusPieDigram }) {
  try {
    const response = yield call(getTicketStatusPieDigram, ticketStatusPieDigram)
    yield put(getTicketStatusPieDigramSuccess(response))
  } catch (error) {
    yield put(getTicketStatusPieDigramFail(error))
  }
}

function* ticketsDashboardSaga() {
  yield takeEvery(
    GET_COUNT_FOR_TICKETS_DASHBOARD,
    fetchCountForTicketsDashboard
  )
  yield takeEvery(
    GET_CRITICAL_PRIORITY_TICKET_LIST,
    fetchCriticalPriorityTicketList
  )
  yield takeEvery(GET_HIGH_PRIORITY_TICKET_LIST, fetchHighPriorityTicketList)
  yield takeEvery(
    GET_MEDIUM_PRIORITY_TICKET_LIST,
    fetchMediumPriorityTicketList
  )
  yield takeEvery(GET_LOW_PRIORITY_TICKET_LIST, fetchLowPriorityTicketList)
  yield takeEvery(GET_RECENT_TICKETS_LIST, fetchRecentTicketList)
  yield takeEvery(
    GET_USER_VENDOR_WITH_MOST_TICKET,
    fetchUserVendorWithMostTicket
  )
  yield takeEvery(GET_OPEN_CLOSE_TICKET_GRAPH, fetchOpenCloseTicketGraph)
  yield takeEvery(GET_TICKETS_STATUS_PIE_DIGRAM, fetchTicketStatusPieDigram)
}

export default ticketsDashboardSaga
