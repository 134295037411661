import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import TableContainer from "./outstandingAmountDataContainer"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import {
  getVendorInvoices,
  getVendorInvoicesDataWithOutstandingAmount,
} from "store/actions"

const OutstandingAmountData = () => {
  const navigate = useNavigate()
  document.title = "Vendor Summary Report"
  const dispatch = useDispatch()
  const vendorSummaryList = state =>
    state.vendorSummaryReport.vendorInvoicesDataWithOustandingAmount.data
  const vendorInvoices = useSelector(vendorSummaryList) || []

  const loadingState = state => state.vendorSummaryReport.loading
  const loading = useSelector(loadingState) || []
  const [isLoading, setLoading] = useState(loading)

  const { id } = useParams()

  useEffect(() => {
    dispatch(getVendorInvoicesDataWithOutstandingAmount(id))
  }, [id])

  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">{cellProps.row.index + 1}</h5>
            </>
          )
        },
      },

      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.vendor_name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Invoice No",
        accessor: "invoice_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.invoice_no}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Invoice Date",
        accessor: "invoice_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.invoice_date}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Invoice Raised",
        accessor: "invoice_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.invoice_amount}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.amount_paid}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Tds Amount",
        accessor: "tds_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.tds_amount}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Outstanding Amount",
        accessor: "outstanding_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.outstanding_amount}
              </h5>
            </>
          )
        },
      },
    ],
    []
  )

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Vendor Summary Report"
            breadcrumbItem="Vendor Invoice Outstanding Amount List"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={vendorInvoices || []}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OutstandingAmountData
