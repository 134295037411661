import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_LIST,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_LIST_FAIL,
  ADD_NEW_CLIENT,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAIL,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  GET_ASSIGNED_PROJECT,
  GET_ASSIGNED_PROJECT_SUCCESS,
  GET_ASSIGNED_PROJECT_FAIL,
  GET_ASSIGNED_PROJECT_LIST,
  GET_ASSIGNED_PROJECT_LIST_SUCCESS,
  GET_ASSIGNED_PROJECT_LIST_FAIL,
  ADD_ASSIGNED_PROJECT,
  ADD_ASSIGNED_PROJECT_SUCCESS,
  ADD_ASSIGNED_PROJECT_FAIL,
  UPDATE_ASSIGNED_PROJECT,
  UPDATE_ASSIGNED_PROJECT_SUCCESS,
  UPDATE_ASSIGNED_PROJECT_FAIL,
  DELETE_ASSIGNED_PROJECT,
  DELETE_ASSIGNED_PROJECT_SUCCESS,
  DELETE_ASSIGNED_PROJECT_FAIL,
  GET_CLIENT_DOCUMENTS,
  GET_CLIENT_DOCUMENTS_SUCCESS,
  GET_CLIENT_DOCUMENTS_FAIL,
  ADD_NEW_CLIENT_DOCUMENT,
  ADD_NEW_CLIENT_DOCUMENT_SUCCESS,
  ADD_NEW_CLIENT_DOCUMENT_FAIL,
  DELETE_CLIENT_DOCUMENT,
  DELETE_CLIENT_DOCUMENT_SUCCESS,
  DELETE_CLIENT_DOCUMENT_FAIL,
  REINVITE_CLIENT,
  REINVITE_CLIENT_SUCCESS,
  REINVITE_CLIENT_FAIL,
  GET_ALL_ASSIGNED_PROJECT_UNITS,
  GET_ALL_ASSIGNED_PROJECT_UNITS_SUCCESS,
  GET_ALL_ASSIGNED_PROJECT_UNITS_FAIL,
  GET_PROJECT_LIST_FOR_CLIENT,
  GET_PROJECT_LIST_FOR_CLIENT_SUCCESS,
  GET_PROJECT_LIST_FOR_CLIENT_FAIL,
  GET_PROJECT_UNIT_LIST_FOR_CLIENT,
  GET_PROJECT_UNIT_LIST_FOR_CLIENT_SUCCESS,
  GET_PROJECT_UNIT_LIST_FOR_CLIENT_FAIL,
  GET_CLIENT_CODE,
  GET_CLIENT_CODE_SUCCESS,
  GET_CLIENT_CODE_FAIL,
  GET_SACHSN_CODE_CLIENT,
  GET_SACHSN_CODE_CLIENT_SUCCESS,
  GET_SACHSN_CODE_CLIENT_FAIL,
  GET_INVOICE_TYPE_MASTER_LIST,
  GET_INVOICE_TYPE_MASTER_LIST_SUCCESS,
  GET_INVOICE_TYPE_MASTER_LIST_FAIL,
  GET_INVOICE_TYPE_MASTER_CLIENT_LIST,
  GET_INVOICE_TYPE_MASTER_CLIENT_LIST_SUCCESS,
  GET_INVOICE_TYPE_MASTER_CLIENT_LIST_FAIL,
  GET_APPLICABLE_RATE_CLIENT_LIST,
  GET_APPLICABLE_RATE_CLIENT_LIST_SUCCESS,
  GET_APPLICABLE_RATE_CLIENT_LIST_FAIL
} from "./actionTypes"

export const getClients = (clientId) => ({
  type: GET_CLIENTS,
  clientId
})

export const getClientsSuccess = clients => ({
  type: GET_CLIENTS_SUCCESS,
  payload: clients,
})

export const getClientsFail = error => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
})

export const getClientsList = (id) => ({
  type: GET_CLIENTS_LIST,
  id
})

export const getClientsListSuccess = (clients) => ({
  type: GET_CLIENTS_LIST_SUCCESS,
  payload: clients,
})

export const getClientsListFail = error => ({
  type: GET_CLIENTS_LIST_FAIL,
  payload: error,
})

export const addNewClient = client => ({
  type: ADD_NEW_CLIENT,
  payload: client,
})

export const addNewClientSuccess = client => ({
  type: ADD_NEW_CLIENT_SUCCESS,
  payload: client,
})

export const addNewClientFail = error => ({
  type: ADD_NEW_CLIENT_FAIL,
  payload: error,
})

export const updateClient = client => ({
  type: UPDATE_CLIENT,
  payload: client,
})

export const updateClientSuccess = client => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: client,
})

export const updateClientFail = error => ({
  type: UPDATE_CLIENT_FAIL,
  payload: error,
})

export const deleteClient = client => ({
  type: DELETE_CLIENT,
  payload: client,
})

export const deleteClientSuccess = client => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: client,
})

export const deleteClientFail = error => ({
  type: DELETE_CLIENT_FAIL,
  payload: error,
})

// DOCUMENTS

export const getClientDocuments = (clientId) => ({
  type: GET_CLIENT_DOCUMENTS,
  clientId
})

export const getClientDocumentsSuccess = docs => ({
  type: GET_CLIENT_DOCUMENTS_SUCCESS,
  payload: docs,
})

export const getClientDocumentsFail = error => ({
  type: GET_CLIENT_DOCUMENTS_FAIL,
  payload: error,
})

export const addClientDocuments = docs => ({
  type: ADD_NEW_CLIENT_DOCUMENT,
  payload: docs,
})

export const addClientDocumentsSuccess = docs => ({
  type: ADD_NEW_CLIENT_DOCUMENT_SUCCESS,
  payload: docs,
})

export const addClientDocumentsFail = error => ({
  type: ADD_NEW_CLIENT_DOCUMENT_FAIL,
  payload: error,
})

export const deleteClientDocument = docs => ({
  type: DELETE_CLIENT_DOCUMENT,
  docs,
})

export const deleteClientDocumentSuccess = docs => ({
  type: DELETE_CLIENT_DOCUMENT_SUCCESS,
  payload: docs,
})

export const deleteClientDocumentFail = error => ({
  type: DELETE_CLIENT_DOCUMENT_FAIL,
  payload: error,
})

// CLIENTS ASSIGNED PROJECT UNIT

export const getAssignedProjectUnit = emp => ({
  type: GET_ASSIGNED_PROJECT,
  emp,
})

export const getAssignedProjectUnitSuccess = emp => ({
  type: GET_ASSIGNED_PROJECT_SUCCESS,
  payload: emp,
})

export const getAssignedProjectUnitFail = error => ({
  type: GET_ASSIGNED_PROJECT_FAIL,
  payload: error,
})

export const getAssignedProjectUnitList = emp => ({
  type: GET_ASSIGNED_PROJECT_LIST,
  emp,
})

export const getAssignedProjectUnitListSuccess = emp => ({
  type: GET_ASSIGNED_PROJECT_LIST_SUCCESS,
  payload: emp,
})

export const getAssignedProjectUnitListFail = error => ({
  type: GET_ASSIGNED_PROJECT_LIST_FAIL,
  payload: error,
})

export const addAssignedProjectUnit = (id,emp) => ({
  type: ADD_ASSIGNED_PROJECT,
  payload: {id,emp},
})

export const addAssignedProjectUnitSuccess = emp => ({
  type: ADD_ASSIGNED_PROJECT_SUCCESS,
  payload: emp,
})

export const addAssignedProjectUnitFail = error => ({
  type: ADD_ASSIGNED_PROJECT_FAIL,
  payload: error,
})

export const updateAssignedProjectUnit = emp => ({
  type: UPDATE_ASSIGNED_PROJECT,
  payload: emp,
})

export const updateAssignedProjectUnitSuccess = emp => ({
  type: UPDATE_ASSIGNED_PROJECT_SUCCESS,
  payload: emp,
})

export const updateAssignedProjectUnitFail = error => ({
  type: UPDATE_ASSIGNED_PROJECT_FAIL,
  payload: error,
})

export const deleteAssignedProjectUnit = emp => ({
  type: DELETE_ASSIGNED_PROJECT,
  payload: emp,
})

export const deleteAssignedProjectUnitSuccess = emp => ({
  type: DELETE_ASSIGNED_PROJECT_SUCCESS,
  payload: emp,
})

export const deleteAssignedProjectUnitFail = error => ({
  type: DELETE_ASSIGNED_PROJECT_FAIL,
  payload: error,
})

export const getAllAssignedProjects = () => ({
  type: GET_ALL_ASSIGNED_PROJECT_UNITS,
})

export const getAllAssignedProjectsSuccess = (clients) => ({
  type: GET_ALL_ASSIGNED_PROJECT_UNITS_SUCCESS,
  payload: clients,
})

export const getAllAssignedProjectsFail = error => ({
  type: GET_ALL_ASSIGNED_PROJECT_UNITS_FAIL,
  payload: error,
})

//REINVITE 

export const reinviteClient = emp => ({
  type: REINVITE_CLIENT,
  payload: emp,
})

export const reinviteClientSuccess = emp => ({
  type: REINVITE_CLIENT_SUCCESS,
  payload: emp,
})

export const reinviteClientFail = error => ({
  type: REINVITE_CLIENT_FAIL,
  payload: error,
})


//GET PROJECT AND PROEJCT UNIT

export const getProjectListForClient = (id) => ({
  type: GET_PROJECT_LIST_FOR_CLIENT,
  id
})

export const getProjectListForClientSuccess = clients => ({
  type: GET_PROJECT_LIST_FOR_CLIENT_SUCCESS,
  payload: clients,
})

export const getProjectListForClientFail = error => ({
  type: GET_PROJECT_LIST_FOR_CLIENT_FAIL,
  payload: error,
})

export const getProjectUnitListForClient = (clientId) => ({
  type: GET_PROJECT_UNIT_LIST_FOR_CLIENT,
  clientId
})

export const getProjectUnitListForClientSuccess = clients => ({
  type: GET_PROJECT_UNIT_LIST_FOR_CLIENT_SUCCESS,
  payload: clients,
})

export const getProjectUnitListForClientFail = error => ({
  type: GET_PROJECT_UNIT_LIST_FOR_CLIENT_FAIL,
  payload: error,
})

export const getClientCode = () => ({
  type: GET_CLIENT_CODE,
})

export const getClientCodeSuccess = (clients) => ({
  type: GET_CLIENT_CODE_SUCCESS,
  payload: clients,
})

export const getClientCodeFail = error => ({
  type: GET_CLIENT_CODE_FAIL,
  payload: error,
})

export const getSacHsnCodeClient = () => ({
  type: GET_SACHSN_CODE_CLIENT,
})

export const getSacHsnCodeClientSuccess = codes => ({
  type: GET_SACHSN_CODE_CLIENT_SUCCESS,
  payload: codes,
})

export const getSacHsnCodeClientFail = error => ({
  type: GET_SACHSN_CODE_CLIENT_FAIL,
  payload: error,
})

export const getInvoiceTypeMasterClientList = () => ({
  type: GET_INVOICE_TYPE_MASTER_CLIENT_LIST,
})

export const getInvoiceTypeMasterClientListSuccess = invoiceList => ({
  type: GET_INVOICE_TYPE_MASTER_CLIENT_LIST_SUCCESS,
  payload: invoiceList,
})

export const getInvoiceTypeMasterClientListFail = error => ({
  type: GET_INVOICE_TYPE_MASTER_CLIENT_LIST_FAIL,
  payload: error,
})

export const getApplicableRateClientList = () => ({
  type: GET_APPLICABLE_RATE_CLIENT_LIST,
})

export const getApplicableRateClientListSuccess = rateList => ({
  type: GET_APPLICABLE_RATE_CLIENT_LIST_SUCCESS,
  payload: rateList,
})

export const getApplicableRateClientListFail = error => ({
  type: GET_APPLICABLE_RATE_CLIENT_LIST_FAIL,
  payload: error,
})