import formatNumberWithCommas from "components/Common/CommaSeparator"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import "simplebar-react/dist/simplebar.min.css"
import { getProjectUnitData } from "store/actions"

const IsNotOccupiedModal = ({ isOpen, toggle, unitId }) => {
  const dispatch = useDispatch()
  const viewAssignedProjectUnitState = state => state.common.projectUnit.data
  const projectUnit = useSelector(viewAssignedProjectUnitState)

  useEffect(() => {
    if (isOpen === true) {
      dispatch(getProjectUnitData(unitId))
    }
  }, [unitId, dispatch, isOpen])

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-body">
        <Row className="justify-content-center">
          <Col lg={12}>
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                Project Unit Details{" "}
                <button
                  onClick={toggle}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </CardTitle>
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col lg={4}>
                    <Label className="form-label">Project</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.project_name}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Project Unit </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.unit_code}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Chargeable Area sqft</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {(projectUnit && projectUnit?.unit_area_sqft) || "NA"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Label className="form-label">Carpet Area Sqft</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {(projectUnit && projectUnit?.carpet_area_sqft) || "NA"}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Build Up Area Sqft </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {(projectUnit && projectUnit?.built_up_area_sqft) || "NA"}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">
                      Super Build Up Area Sqft
                    </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {(projectUnit && projectUnit?.super_built_up_area_sqft) ||
                        "NA"}{" "}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Label className="form-label">Unit Category</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.category_title}
                    </p>
                  </Col>

                  <Col lg={4}>
                    <Label className="form-label">Rent/Sale</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.type_of_project_unit === 1
                        ? "Sale"
                        : projectUnit?.type_of_project_unit === 1
                        ? "Sale"
                        : "NA"}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Floor</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.floor_no}
                    </p>
                  </Col>
                </Row>
                <Row>
                  {projectUnit?.maintenance_charges && (
                    <Col lg={4}>
                      <Label className="form-label">Maintenance Charges</Label>
                      <p className="text-dark" style={{ fontWeight: 600 }}>
                        {projectUnit &&
                          formatNumberWithCommas(
                            projectUnit?.maintenance_charges
                          )}
                      </p>
                    </Col>
                  )}

                  {projectUnit?.security_dept_of_electricity && (
                    <Col lg={4}>
                      <Label className="form-label">
                        Security Deposit for Electricity
                      </Label>
                      <p className="text-dark" style={{ fontWeight: 600 }}>
                        {(projectUnit &&
                          formatNumberWithCommas(
                            projectUnit?.security_dept_of_electricity
                          )) ||
                          "NA"}
                      </p>
                    </Col>
                  )}
                  {projectUnit?.security_dept_of_maintenance && (
                    <Col lg={4}>
                      <Label className="form-label">
                        Security Deposit for Maintenance
                      </Label>
                      <p className="text-dark" style={{ fontWeight: 600 }}>
                        {(projectUnit &&
                          formatNumberWithCommas(
                            projectUnit?.security_dept_of_maintenance
                          )) ||
                          "NA"}
                      </p>
                    </Col>
                  )}
                  {projectUnit?.minimum_sale_amount && (
                    <Col lg={4}>
                      <Label className="form-label">Minimum Sale Amount</Label>
                      <p className="text-dark" style={{ fontWeight: 600 }}>
                        {(projectUnit &&
                          formatNumberWithCommas(
                            projectUnit?.minimum_sale_amount
                          )) ||
                          "NA"}
                      </p>
                    </Col>
                  )}
                  {projectUnit?.maximum_sale_amount && (
                    <Col lg={4}>
                      <Label className="form-label">Maximum Sale Amount</Label>
                      <p className="text-dark" style={{ fontWeight: 600 }}>
                        {(projectUnit &&
                          formatNumberWithCommas(
                            projectUnit?.maximum_sale_amount
                          )) ||
                          "NA"}
                      </p>
                    </Col>
                  )}
                  <Col lg={4}>
                    <Label className="form-label">Furnished?</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {(projectUnit && projectUnit?.furnished) || "NA"}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Facilities</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {(projectUnit && projectUnit?.facilities_available_new) || "None"}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default IsNotOccupiedModal
