export const GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD = "GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD"
export const GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_SUCCESS = "GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_SUCCESS"
export const GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_FAIL = "GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD_FAIL"

export const GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST = "GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST"
export const GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_SUCCESS = "GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_SUCCESS"
export const GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_FAIL = "GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST_FAIL"

export const GET_FACING_MAINTENANCE_ISSUE_LIST = "GET_FACING_MAINTENANCE_ISSUE_LIST"
export const GET_FACING_MAINTENANCE_ISSUE_LIST_SUCCESS = "GET_FACING_MAINTENANCE_ISSUE_LIST_SUCCESS"
export const GET_FACING_MAINTENANCE_ISSUE_LIST_FAIL = "GET_FACING_MAINTENANCE_ISSUE_LIST_FAIL"

export const GET_SUPPORT_TICKET_LIST = "GET_SUPPORT_TICKET_LIST"
export const GET_SUPPORT_TICKET_LIST_SUCCESS = "GET_SUPPORT_TICKET_LIST_SUCCESS"
export const GET_SUPPORT_TICKET_LIST_FAIL = "GET_SUPPORT_TICKET_LIST_FAIL"

export const GET_RECENT_SERVICE_REQUEST_LIST ="GET_RECENT_SERVICE_REQUEST_LIST"
export const GET_RECENT_SERVICE_REQUEST_LIST_SUCCESS ="GET_RECENT_SERVICE_REQUEST_LIST_SUCCESS"
export const GET_RECENT_SERVICE_REQUEST_LIST_FAIL ="GET_RECENT_SERVICE_REQUEST_LIST_FAIL"

export const GET_TYPES_OF_REQUESTS_GRAPH = "GET_TYPES_OF_REQUESTS_GRAPH"
export const GET_TYPES_OF_REQUESTS_GRAPH_SUCCESS = "GET_TYPES_OF_REQUESTS_GRAPH_SUCCESS"
export const GET_TYPES_OF_REQUESTS_GRAPH_FAIL = "GET_TYPES_OF_REQUESTS_GRAPH_FAIL"

export const GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH = "GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH"
export const GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_SUCCESS = "GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_SUCCESS"
export const GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_FAIL = "GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH_FAIL"

export const GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM ="GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM"
export const GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_SUCCESS ="GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_SUCCESS"
export const GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_FAIL ="GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM_FAIL"
