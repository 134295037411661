export const GET_BILLING_CLIENT_LIST = "GET_BILLING_CLIENT_LIST"
export const GET_BILLING_CLIENT_LIST_SUCCESS ="GET_BILLING_CLIENT_LIST_SUCCESS"
export const GET_BILLING_CLIENT_LIST_FAIL = "GET_BILLING_CLIENT_LIST_FAIL"

export const GET_BILLING_CLIENT_DETAIL = "GET_BILLING_CLIENT_DETAIL"
export const GET_BILLING_CLIENT_DETAIL_SUCCESS ="GET_BILLING_CLIENT_DETAIL_SUCCESS"
export const GET_BILLING_CLIENT_DETAIL_FAIL = "GET_BILLING_CLIENT_DETAIL_FAIL"

export const GET_SERVICE_ENGAGEMENT = "GET_SERVICE_ENGAGEMENT"
export const GET_SERVICE_ENGAGEMENT_SUCCESS = "GET_SERVICE_ENGAGEMENT_SUCCESS"
export const GET_SERVICE_ENGAGEMENT_FAIL = "GET_SERVICE_ENGAGEMENT_FAIL"

export const GET_BILLING_COMPANY_LIST = "GET_BILLING_COMPANY_LIST"
export const GET_BILLING_COMPANY_LIST_SUCCESS = "GET_BILLING_COMPANY_LIST_SUCCESS"
export const GET_BILLING_COMPANY_LIST_FAIL = "GET_BILLING_COMPANY_LIST_FAIL"

export const GET_BILLING_COMPANY_DETAIL = "GET_BILLING_COMPANY_DETAIL"
export const GET_BILLING_COMPANY_DETAIL_SUCCESS = "GET_BILLING_COMPANY_DETAIL_SUCCESS"
export const GET_BILLING_COMPANY_DETAIL_FAIL = "GET_BILLING_COMPANY_DETAIL_FAIL"

export const  GET_SUPPLY_LIST = "GET_SUPPLY_LIST"
export const  GET_SUPPLY_LIST_SUCCESS = "GET_SUPPLY_LIST_SUCCESS"
export const  GET_SUPPLY_LIST_FAIL = "GET_SUPPLY_LIST_FAIL"

export const GET_PLACE_OF_SUPPLY = "GET_PLACE_OF_SUPPLY"
export const GET_PLACE_OF_SUPPLY_SUCCESS = "GET_PLACE_OF_SUPPLY_SUCCESS"
export const GET_PLACE_OF_SUPPLY_FAIL = "GET_PLACE_OF_SUPPLY_FAIL"

export const GET_APPLICABLE_RATE_LIST = "GET_APPLICABLE_RATE_LIST"
export const GET_APPLICABLE_RATE_LIST_SUCCESS ="GET_APPLICABLE_RATE_LIST_SUCCESS"
export const GET_APPLICABLE_RATE_LIST_FAIL = "GET_APPLICABLE_RATE_LIST_FAIL"

export const GET_INVOICE_TYPE_MASTER_LIST ="GET_INVOICE_TYPE_MASTER_LIST"
export const GET_INVOICE_TYPE_MASTER_LIST_SUCCESS ="GET_INVOICE_TYPE_MASTER_LIST_SUCCESS"
export const GET_INVOICE_TYPE_MASTER_LIST_FAIL ="GET_INVOICE_TYPE_MASTER_LIST_FAIL"

export const ADD_BILLING_SETUP = "ADD_BILLING_SETUP"
export const ADD_BILLING_SETUP_SUCCESS = "ADD_BILLING_SETUP_SUCCESS"
export const ADD_BILLING_SETUP_FAIL = "ADD_BILLING_SETUP_FAIL"

export const EDIT_BILLING_SETUP = "EDIT_BILLING_SETUP"
export const EDIT_BILLING_SETUP_SUCCESS = "EDIT_BILLING_SETUP_SUCCESS"
export const EDIT_BILLING_SETUP_FAIL = "EDIT_BILLING_SETUP_FAIL"

export const GET_INVOICE_NUMBER = "GET_INVOICE_NUMBER"
export const GET_INVOICE_NUMBER_SUCCESS ="GET_INVOICE_NUMBER_SUCCESS"
export const GET_INVOICE_NUMBER_FAIL = "GET_INVOICE_NUMBER_FAIL"


export const ADD_NEW_BILL = "ADD_NEW_BILL"
export const ADD_NEW_BILL_SUCCESS = "ADD_NEW_BILL_SUCCESS"
export const ADD_NEW_BILL_FAIL = "ADD_NEW_BILL_FAIL"

export const GET_BILLING_REGISTER = "GET_BILLING_REGISTER"
export const GET_BILLING_REGISTER_SUCCESS ="GET_BILLING_REGISTER_SUCCESS"
export const GET_BILLING_REGISTER_FAIL = "GET_BILLING_REGISTER_FAIL"


export const GET_BILLING_DATA = "GET_BILLING_DATA"
export const GET_BILLING_DATA_SUCCESS ="GET_BILLING_DATA_SUCCESS"
export const GET_BILLING_DATA_FAIL = "GET_BILLING_DATA_FAIL"

export const EDIT_BILL = "EDIT_BILL"
export const EDIT_BILL_SUCCESS = "EDIT_BILL_SUCCESS"
export const EDIT_BILL_FAIL = "EDIT_BILL_FAIL"

export const GET_INVOICE_PREVIEW_DETAIL = "GET_INVOICE_PREVIEW_DETAIL"
export const GET_INVOICE_PREVIEW_DETAIL_SUCCESS ="GET_INVOICE_PREVIEW_DETAIL_SUCCESS"
export const GET_INVOICE_PREVIEW_DETAIL_FAIL = "GET_INVOICE_PREVIEW_DETAIL_FAIL"

export const GET_PAYMENT_MODE = "GET_PAYMENT_MODE"
export const GET_PAYMENT_MODE_SUCCESS ="GET_PAYMENT_MODE_SUCCESS"
export const GET_PAYMENT_MODE_FAIL = "GET_PAYMENT_MODE_FAIL"

export const ADD_RECORD_PAYMENT = "ADD_RECORD_PAYMENT"
export const ADD_RECORD_PAYMENT_SUCCESS = "ADD_RECORD_PAYMENT_SUCCESS"
export const ADD_RECORD_PAYMENT_FAIL = "ADD_RECORD_PAYMENT_FAIL"

export const DELETE_BILLING_REGISTER = "DELETE_BILLING_REGISTER"
export const DELETE_BILLING_REGISTER_SUCCESS = "DELETE_BILLING_REGISTER_SUCCESS"
export const DELETE_BILLING_REGISTER_FAIL = "DELETE_BILLING_REGISTER_FAIL"

export const GET_RECORD_PAYMENT_DETAIL = "GET_RECORD_PAYMENT_DETAIL"
export const GET_RECORD_PAYMENT_DETAIL_SUCCESS ="GET_RECORD_PAYMENT_DETAIL_SUCCESS"
export const GET_RECORD_PAYMENT_DETAIL_FAIL = "GET_RECORD_PAYMENT_DETAIL_FAIL"

export const ADD_BILLING_RECEIPT = "ADD_BILLING_RECEIPT"
export const ADD_BILLING_RECEIPT_SUCCESS = "ADD_BILLING_RECEIPT_SUCCESS"
export const ADD_BILLING_RECEIPT_FAIL = "ADD_BILLING_RECEIPT_FAIL"

export const EDIT_BILLING_RECEIPT = "EDIT_BILLING_RECEIPT"
export const EDIT_BILLING_RECEIPT_SUCCESS = "EDIT_BILLING_RECEIPT_SUCCESS"
export const EDIT_BILLING_RECEIPT_FAIL = "EDIT_BILLING_RECEIPT_FAIL"

export const GET_BILLING_RECEIPT_LIST = "GET_BILLING_RECEIPT_LIST"
export const GET_BILLING_RECEIPT_LIST_SUCCESS ="GET_BILLING_RECEIPT_LIST_SUCCESS"
export const GET_BILLING_RECEIPT_LIST_FAIL = "GET_BILLING_RECEIPT_LIST_FAIL"

export const GET_BILL_INVOICE_NO_LIST ="GET_BILL_INVOICE_NO_LIST"
export const GET_BILL_INVOICE_NO_LIST_SUCCESS ="GET_BILL_INVOICE_NO_LIST_SUCCESS"
export const GET_BILL_INVOICE_NO_LIST_FAIL ="GET_BILL_INVOICE_NO_LIST_FAIL"

export const GET_RECEIPT_NUMBER = "GET_RECEIPT_NUMBER"
export const GET_RECEIPT_NUMBER_SUCCESS ="GET_RECEIPT_NUMBER_SUCCESS"
export const GET_RECEIPT_NUMBER_FAIL = "GET_RECEIPT_NUMBER_FAIL"

export const SEND_MAIL_TO_CUSTOMER = "SEND_MAIL_TO_CUSTOMER"
export const SEND_MAIL_TO_CUSTOMER_SUCCESS ="SEND_MAIL_TO_CUSTOMER_SUCCESS"
export const SEND_MAIL_TO_CUSTOMER_FAIL = "SEND_MAIL_TO_CUSTOMER_FAIL"

export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE"
export const DOWNLOAD_INVOICE_SUCCESS ="DOWNLOAD_INVOICE_SUCCESS"
export const DOWNLOAD_INVOICE_FAIL = "DOWNLOAD_INVOICE_FAIL"

export const GET_BILLING_RECEIPT_DETAIL = "GET_BILLING_RECEIPT_DETAIL"
export const GET_BILLING_RECEIPT_DETAIL_SUCCESS ="GET_BILLING_RECEIPT_DETAIL_SUCCESS"
export const GET_BILLING_RECEIPT_DETAIL_FAIL = "GET_BILLING_RECEIPT_DETAIL_FAIL"

export const DELETE_BILLING_RECORD = "DELETE_BILLING_RECORD"
export const DELETE_BILLING_RECORD_SUCCESS = "DELETE_BILLING_RECORD_SUCCESS"
export const DELETE_BILLING_RECORD_FAIL = "DELETE_BILLING_RECORD_FAIL"

export const GET_BILLING_SETUP_DETAIL = "GET_BILLING_SETUP_DETAIL"
export const GET_BILLING_SETUP_DETAIL_SUCCESS ="GET_BILLING_SETUP_DETAIL_SUCCESS"
export const GET_BILLING_SETUP_DETAIL_FAIL = "GET_BILLING_SETUP_DETAIL_FAIL"

export const DELETE_BILLING_RECORD_PAYMENT = "DELETE_BILLING_RECORD_PAYMENT"
export const DELETE_BILLING_RECORD_PAYMENT_SUCCESS = "DELETE_BILLING_RECORD_PAYMENT_SUCCESS"
export const DELETE_BILLING_RECORD_PAYMENT_FAIL = "DELETE_BILLING_RECORD_PAYMENT_FAIL"

export const GET_SACHSN_CODE_BILLING_SETUP = "GET_SACHSN_CODE_BILLING_SETUP"
export const GET_SACHSN_CODE_BILLING_SETUP_SUCCESS = "GET_SACHSN_CODE_BILLING_SETUP_SUCCESS"
export const GET_SACHSN_CODE_BILLING_SETUP_FAIL = "GET_SACHSN_CODE_BILLING_SETUP_FAIL"

export const GET_SACHSN_CODE_BILLING = "GET_SACHSN_CODE_BILLING"
export const GET_SACHSN_CODE_BILLING_SUCCESS = "GET_SACHSN_CODE_BILLING_SUCCESS"
export const GET_SACHSN_CODE_BILLING_FAIL = "GET_SACHSN_CODE_BILLING_FAIL"

export const GET_RECURRING_BILL = "GET_RECURRING_BILL"
export const GET_RECURRING_BILL_SUCCESS ="GET_RECURRING_BILL_SUCCESS"
export const GET_RECURRING_BILL_FAIL = "GET_RECURRING_BILL_FAIL"

//Assigned Project Unit list 
export const GET_ASSIGNED_PROJECT_UNIT_IN_BILLING = "GET_ASSIGNED_PROJECT_UNIT_IN_BILLING"
export const GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_SUCCESS ="GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_SUCCESS"
export const GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_FAIL = "GET_ASSIGNED_PROJECT_UNIT_IN_BILLING_FAIL"

//Project List in Billing
export const GET_PROJECT_LIST_IN_BILLING = "GET_PROJECT_LIST_IN_BILLING"
export const GET_PROJECT_LIST_IN_BILLING_SUCCESS ="GET_PROJECT_LIST_IN_BILLING_SUCCESS"
export const GET_PROJECT_LIST_IN_BILLING_FAIL = "GET_PROJECT_LIST_IN_BILLING_FAIL"

//Project Details in Billing
export const GET_PROJECT_DETAIL_IN_BILLING = "GET_PROJECT_DETAIL_IN_BILLING"
export const GET_PROJECT_DETAIL_IN_BILLING_SUCCESS ="GET_PROJECT_DETAIL_IN_BILLING_SUCCESS"
export const GET_PROJECT_DETAIL_IN_BILLING_FAIL = "GET_PROJECT_DETAIL_IN_BILLING_FAIL"

//Project Unit Details in Billing
export const GET_PROJECT_UNIT_DETAIL_IN_BILLING = "GET_PROJECT_UNIT_DETAIL_IN_BILLING"
export const GET_PROJECT_UNIT_DETAIL_IN_BILLING_SUCCESS ="GET_PROJECT_UNIT_DETAIL_IN_BILLING_SUCCESS"
export const GET_PROJECT_UNIT_DETAIL_IN_BILLING_FAIL = "GET_PROJECT_UNIT_DETAIL_IN_BILLING_FAIL"

//Client List in Billing receipt
export const GET_BILLING_CLIENT_LIST_IN_RECEIPT = "GET_BILLING_CLIENT_LIST_IN_RECEIPT"
export const GET_BILLING_CLIENT_LIST_IN_RECEIPT_SUCCESS ="GET_BILLING_CLIENT_LIST_IN_RECEIPT_SUCCESS"
export const GET_BILLING_CLIENT_LIST_IN_RECEIPT_FAIL = "GET_BILLING_CLIENT_LIST_IN_RECEIPT_FAIL"

//Company List in Billing receipt
export const GET_BILLING_COMPANY_LIST_IN_RECEIPT = "GET_BILLING_COMPANY_LIST_IN_RECEIPT"
export const GET_BILLING_COMPANY_LIST_IN_RECEIPT_SUCCESS ="GET_BILLING_COMPANY_LIST_IN_RECEIPT_SUCCESS"
export const GET_BILLING_COMPANY_LIST_IN_RECEIPT_FAIL = "GET_BILLING_COMPANY_LIST_IN_RECEIPT_FAIL"

//Company Details in Billing receipt
export const GET_BILLING_COMPANY_DETAIL_IN_RECEIPT = "GET_BILLING_COMPANY_DETAIL_IN_RECEIPT"
export const GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_SUCCESS ="GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_SUCCESS"
export const GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_FAIL = "GET_BILLING_COMPANY_DETAIL_IN_RECEIPT_FAIL"

//Outstanding amount Details in Billing receipt
export const GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT = "GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT"
export const GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_SUCCESS ="GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_SUCCESS"
export const GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_FAIL = "GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT_FAIL"

//location List in Billing receipt
export const GET_GST_LOCATION_LIST = "GET_GST_LOCATION_LIST"
export const GET_GST_LOCATION_LIST_SUCCESS ="GET_GST_LOCATION_LIST_SUCCESS"
export const GET_GST_LOCATION_LIST_FAIL = "GET_GST_LOCATION_LIST_FAIL"

//location detail List in Billing receipt
export const GET_GST_LOCATION_DETAIL = "GET_GST_LOCATION_DETAIL"
export const GET_GST_LOCATION_DETAIL_SUCCESS ="GET_GST_LOCATION_DETAIL_SUCCESS"
export const GET_GST_LOCATION_DETAIL_FAIL = "GET_GST_LOCATION_DETAIL_FAIL"

//COMPANY FILTER

export const GET_COMPANY_FILTER = "GET_COMPANY_FILTER"
export const GET_COMPANY_FILTER_SUCCESS = "GET_COMPANY_FILTER_SUCCESS"
export const GET_COMPANY_FILTER_FAIL = "GET_COMPANY_FILTER_FAIL"

//COMPANY LOCATION FILTER

export const GET_COMPANY_LOCATION_FILTER = "GET_COMPANY_LOCATION_FILTER"
export const GET_COMPANY_LOCATION_FILTER_SUCCESS = "GET_COMPANY_LOCATION_FILTER_SUCCESS"
export const GET_COMPANY_LOCATION_FILTER_FAIL = "GET_COMPANY_LOCATION_FILTER_FAIL"

//CUSTOMER FILTER

export const GET_CUSTOMER_FILTER = "GET_CUSTOMER_FILTER"
export const GET_CUSTOMER_FILTER_SUCCESS = "GET_CUSTOMER_FILTER_SUCCESS"
export const GET_CUSTOMER_FILTER_FAIL = "GET_CUSTOMER_FILTER_FAIL"

//PROJECT FILTER

export const GET_PROJECT_FILTER="GET_PROJECT_FILTER"
export const GET_PROJECT_FILTER_SUCCESS="GET_PROJECT_FILTER_SUCCESS"
export const GET_PROJECT_FILTER_FAIL="GET_PROJECT_FILTER_FAIL"

//FINANCIAL YEAR FILTER

export const GET_FINANCIAL_YEAR_FILTER="GET_FINANCIAL_YEAR_FILTER"
export const GET_FINANCIAL_YEAR_FILTER_SUCCESS="GET_FINANCIAL_YEAR_FILTER_SUCCESS"
export const GET_FINANCIAL_YEAR_FILTER_FAIL="GET_FINANCIAL_YEAR_FILTER_FAIL"


//Receipt
//COMPANY FILTER

export const GET_COMPANY_FILTER_FOR_RECEIPT = "GET_COMPANY_FILTER_FOR_RECEIPT"
export const GET_COMPANY_FILTER_FOR_RECEIPT_SUCCESS = "GET_COMPANY_FILTER_FOR_RECEIPT_SUCCESS"
export const GET_COMPANY_FILTER_FOR_RECEIPT_FAIL = "GET_COMPANY_FILTER_FOR_RECEIPT_FAIL"

//COMPANY LOCATION FILTER

export const GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT = "GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT"
export const GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_SUCCESS = "GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_SUCCESS"
export const GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_FAIL = "GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT_FAIL"

//CUSTOMER FILTER

export const GET_CUSTOMER_FILTER_FOR_RECEIPT = "GET_CUSTOMER_FILTER_FOR_RECEIPT"
export const GET_CUSTOMER_FILTER_FOR_RECEIPT_SUCCESS = "GET_CUSTOMER_FILTER_FOR_RECEIPT_SUCCESS"
export const GET_CUSTOMER_FILTER_FOR_RECEIPT_FAIL = "GET_CUSTOMER_FILTER_FOR_RECEIPT_FAIL"

//PROJECT FILTER

export const GET_PROJECT_FILTER_FOR_RECEIPT="GET_PROJECT_FILTER_FOR_RECEIPT"
export const GET_PROJECT_FILTER_FOR_RECEIPT_SUCCESS="GET_PROJECT_FILTER_FOR_RECEIPT_SUCCESS"
export const GET_PROJECT_FILTER_FOR_RECEIPT_FAIL="GET_PROJECT_FILTER_FOR_RECEIPT_FAIL"

//FINANCIAL YEAR FILTER

export const GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT="GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT"
export const GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_SUCCESS="GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_SUCCESS"
export const GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_FAIL="GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT_FAIL"

export const GET_RECEIPT_PREVIEW = "GET_RECEIPT_PREVIEW"
export const GET_RECEIPT_PREVIEW_SUCCESS = "GET_RECEIPT_PREVIEW_SUCCESS"
export const GET_RECEIPT_PREVIEW_FAIL = "GET_RECEIPT_PREVIEW_FAIL"

export const SEND_RECEIPT_MAIL_TO_CUSTOMER = "SEND_RECEIPT_MAIL_TO_CUSTOMER"
export const SEND_RECEIPT_MAIL_TO_CUSTOMER_SUCCESS = "SEND_RECEIPT_MAIL_TO_CUSTOMER_SUCCESS"
export const SEND_RECEIPT_MAIL_TO_CUSTOMER_FAIL = "SEND_RECEIPT_MAIL_TO_CUSTOMER_FAIL"

//CONSUMPTION REGISTER

export const GET_CONSUMPTION_REGISTER_LIST = "GET_CONSUMPTION_REGISTER_LIST"
export const GET_CONSUMPTION_REGISTER_LIST_SUCCESS = "GET_CONSUMPTION_REGISTER_LIST_SUCCESS"
export const GET_CONSUMPTION_REGISTER_LIST_FAIL = "GET_CONSUMPTION_REGISTER_LIST_FAIL"

export const GET_CONSUMPTION_DATA = "GET_CONSUMPTION_DATA"
export const GET_CONSUMPTION_DATA_SUCCESS = "GET_CONSUMPTION_DATA_SUCCESS"
export const GET_CONSUMPTION_DATA_FAIL = "GET_CONSUMPTION_DATA_FAIL"

export const ADD_NEW_CONSUMPTION = "ADD_NEW_CONSUMPTION"
export const ADD_NEW_CONSUMPTION_SUCCESS = "ADD_NEW_CONSUMPTION_SUCCESS"
export const ADD_NEW_CONSUMPTION_FAIL = "ADD_NEW_CONSUMPTION_FAIL"

export const EDIT_CONSUMPTION = "EDIT_CONSUMPTION"
export const EDIT_CONSUMPTION_SUCCESS = "EDIT_CONSUMPTION_SUCCESS"
export const EDIT_CONSUMPTION_FAIL = "EDIT_CONSUMPTION_FAIL"

export const DELETE_CONSUMPTION_REGISTER = "DELETE_CONSUMPTION_REGISTER"
export const DELETE_CONSUMPTION_REGISTER_SUCCESS = "DELETE_CONSUMPTION_REGISTER_SUCCESS"
export const DELETE_CONSUMPTION_REGISTER_FAIL = "DELETE_CONSUMPTION_REGISTER_FAIL"

//credit Note
export const GET_BILLING_CREDIT_NOTE_LIST = "GET_BILLING_CREDIT_NOTE_LIST"
export const GET_BILLING_CREDIT_NOTE_LIST_SUCCESS = "GET_BILLING_CREDIT_NOTE_LIST_SUCCESS"
export const GET_BILLING_CREDIT_NOTE_LIST_FAIL = "GET_BILLING_CREDIT_NOTE_LIST_FAIL"


export const GET_CREDIT_NUMBER = "GET_CREDIT_NUMBER"
export const GET_CREDIT_NUMBER_SUCCESS ="GET_CREDIT_NUMBER_SUCCESS"
export const GET_CREDIT_NUMBER_FAIL = "GET_CREDIT_NUMBER_FAIL"

export const GET_COMPONENTS_FOR_CREDIT_NOTE = "GET_COMPONENTS_FOR_CREDIT_NOTE"
export const GET_COMPONENTS_FOR_CREDIT_NOTE_SUCCESS ="GET_COMPONENTS_FOR_CREDIT_NOTE_SUCCESS"
export const GET_COMPONENTS_FOR_CREDIT_NOTE_FAIL = "GET_COMPONENTS_FOR_CREDIT_NOTE_FAIL"

export const ADD_CREDIT_NOTE = "ADD_CREDIT_NOTE"
export const ADD_CREDIT_NOTE_SUCCESS ="ADD_CREDIT_NOTE_SUCCESS"
export const ADD_CREDIT_NOTE_FAIL = "ADD_CREDIT_NOTE_FAIL"

export const EDIT_CREDIT_NOTE = "EDIT_CREDIT_NOTE"
export const EDIT_CREDIT_NOTE_SUCCESS ="EDIT_CREDIT_NOTE_SUCCESS"
export const EDIT_CREDIT_NOTE_FAIL = "EDIT_CREDIT_NOTE_FAIL"

export const GET_CREDIT_NOTE_REASONS = "GET_CREDIT_NOTE_REASONS"
export const GET_CREDIT_NOTE_REASONS_SUCCESS ="GET_CREDIT_NOTE_REASONS_SUCCESS"
export const GET_CREDIT_NOTE_REASONS_FAIL = "GET_CREDIT_NOTE_REASONS_FAIL"

export const GET_CREDIT_NOTE_INVOICE_LIST = "GET_CREDIT_NOTE_INVOICE_LIST"
export const GET_CREDIT_NOTE_INVOICE_LIST_SUCCESS ="GET_CREDIT_NOTE_INVOICE_LIST_SUCCESS"
export const GET_CREDIT_NOTE_INVOICE_LIST_FAIL = "GET_CREDIT_NOTE_INVOICE_LIST_FAIL"

export const GET_CREDIT_DATA = "GET_CREDIT_DATA"
export const GET_CREDIT_DATA_SUCCESS ="GET_CREDIT_DATA_SUCCESS"
export const GET_CREDIT_DATA_FAIL = "GET_CREDIT_DATA_FAIL"

export const DELETE_CREDIT_NOTE = "DELETE_CREDIT_NOTE"
export const DELETE_CREDIT_NOTE_SUCCESS ="DELETE_CREDIT_NOTE_SUCCESS"
export const DELETE_CREDIT_NOTE_FAIL = "DELETE_CREDIT_NOTE_FAIL"

export const GET_CREDIT_INVOICE_PREVIEW_DETAIL = "GET_CREDIT_INVOICE_PREVIEW_DETAIL"
export const GET_CREDIT_INVOICE_PREVIEW_DETAIL_SUCCESS ="GET_CREDIT_INVOICE_PREVIEW_DETAIL_SUCCESS"
export const GET_CREDIT_INVOICE_PREVIEW_DETAIL_FAIL = "GET_CREDIT_INVOICE_PREVIEW_DETAIL_FAIL"