import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Label,
  Input,
  navigate,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import Swal from "sweetalert2"

import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import VLogo from "../../../assets/images/VIP/VLogo.png"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import { getExpenseDetail } from "store/masters/expense/actions"
import {
  addNewConsumption,
  addNewExpense,
  updateExpense,
} from "helpers/fakebackend_helper"
import {
  getAssignedProjectUnitListInBilling,
  getBillingClientDetail,
  getBillingClientList,
  getBillingCompanyDetail,
  getConsumptionData,
  getConsumptionRegisterList,
  getProjectDetailInBilling,
  getProjectListInBilling,
  getProjectUnitDetailInBilling,
} from "store/actions"
// import { updateExpense } from 'helpers/fakebackend_helper';
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"

const NewConsumption = props => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)

  const dispatch = useDispatch()
  const BillingState = state => state.billing

  const AllBillingProperties = createSelector(
    BillingState,

    billing => ({
      clientList: billing.clientList.data,
      clientDetail: billing.clientDetail.client,
      engagementService: billing.serviceEngagement.project_unit,
      billingCompanyList: billing.companyList.companies,
      billingCompanyDetail: billing.companyDetail.company,
      billingCompanylocation: billing.companyDetail.location,
      typeOfSupplyList: billing.supplyList.data,
      placeOfSupplyList: billing.placeOfSupply.data,
      applicableRateList: billing.applicableRateList.data,
      invoiceTypeMasterList: billing.invoiceTypeMasterList.data,
      invoiceNumber: billing.invoiceNumber.invoice_number,
      sacHsnCode: billing.codesBilling.data,
      assignedProjectUnitList: billing.assignedProjectUnit.project_units,
      projectList: billing.projectList.data,
      projectDetail: billing.projectDetail.data,
      projectUnitDetail: billing.projectUnitDetail.project_unit,
      consumptionDataList: billing?.consumptionRegister?.data,
      consumptionClosing: billing?.consumptionData?.closing_unit,
      LatestEntry: billing?.consumptionData?.latest_data,
    })
  )

  const {
    clientList,

    assignedProjectUnitList,
    projectList,
    consumptionDataList,
    consumptionClosing,
    LatestEntry,
  } = useSelector(AllBillingProperties)

  // console.log("consumptionClosing", consumptionClosing)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  useEffect(() => {
    if (user) {
      dispatch(getProjectListInBilling(user.user_id))
    }
  }, [user])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      client: "",
      client_id: "",
      company_id: "",
      client_project_unit_id: "",
      project_id: "",
      location_id: "",
      company_id: "",
      consumption_type: "",
      month: new Date().getMonth() + 1 || "6",
      year: new Date().getFullYear() || "2024",
      dated: new Date().toISOString().split("T")[0] || "",
      opening_unit: 0,
      closing_unit: 0,
      filterMonth: new Date().getMonth() + 1 || "6",
      filterYear: new Date().getFullYear() || "2024",
    },
    validationSchema: Yup.object({
      opening_unit: Yup.number()
        .required("Opening unit is required")
        .min(0, "Opening unit must be at least 0"),
      closing_unit: Yup.number()
        .required("Closing unit is required")
        .test(
          "is-greater-or-equal",
          "Closing unit must be greater than or equal to opening unit",
          function (value) {
            const { opening_unit } = this.parent
            return value >= opening_unit
          }
        ),
      dated: Yup.date()
        .required("Date is required")
        .test(
          "is-past-or-today",
          "Date must be today or a past date",
          function (value) {
            // Convert date to IST
            const toIST = date => {
              // Create a new date instance
              let istDate = new Date(date)

              // Adjust for IST timezone (UTC + 5:30)
              istDate.setMinutes(
                istDate.getMinutes() + istDate.getTimezoneOffset() + 330
              )

              // Format the date as YYYY-MM-DD
              const year = istDate.getFullYear()
              const month = ("0" + (istDate.getMonth() + 1)).slice(-2) // months are 0-indexed
              const day = ("0" + istDate.getDate()).slice(-2)

              return `${year}-${month}-${day}`
            }

            const today = toIST(new Date())
            const inputDate = toIST(new Date(value))

            console.log(inputDate, today)
            return inputDate <= today
          }
        ),
    }),

    onSubmit: async values => {
      // dispatch(addNewConsumption(props.router.navigate, values))
    },
  })

  console.log(validation)
  const navigate = useNavigate()

  useEffect(() => {
    // console.log("validation.values.client_id", validation.values.client_id)
    if (validation.values.client_id) {
      const value = {
        project_id: validation.values.project_id,
        client_id: validation.values.client_id,
      }
      dispatch(getAssignedProjectUnitListInBilling(value))
    }
  }, [validation.values.client_id])

  useEffect(() => {
    if (validation.values.project_id) {
      dispatch(getBillingClientList(validation.values.project_id))
      dispatch(getProjectDetailInBilling(validation.values.project_id))
    }
  }, [validation.values.project_id])

  useEffect(() => {
    console.log(
      "validation.values.client_project_unit_id",
      validation.values.client_project_unit_id
    )
    if (validation.values.client_project_unit_id) {
      dispatch(
        getProjectUnitDetailInBilling(validation.values.client_project_unit_id)
      )
    }
  }, [validation.values.client_project_unit_id])

  useEffect(() => {
    if (validation.values.client_id) {
      const clientId = validation.values.client_id
      dispatch(dispatch(getBillingClientDetail(clientId)))
    }
    // validation.setFieldValue("client_id", clientId)
  }, [validation.values.client_id])

  useEffect(() => {
    if (validation.values.client_project_unit_id) {
      console.log("company")
      dispatch(
        getBillingCompanyDetail(validation.values.client_project_unit_id)
      )
    }
  }, [validation.values.client_project_unit_id])
  useEffect(() => {
    //check if the current path includes 'edit-expense'
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const [selectedOption, setSelectedOption] = useState()

  const handleChange1 = () => {
    const newValue = !selectedOption
    setSelectedOption(newValue)
    validation.setFieldValue("is_active", newValue)
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const years = []
  const startYear = 2022
  const endYear = new Date().getFullYear()

  // Create an array of years from startYear to endYear
  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }

  // Sort the array in descending order
  years.sort((a, b) => b - a)

  // Move the current year to the top of the sorted array
  const currentYearIndex = years.indexOf(new Date().getFullYear())
  if (currentYearIndex !== -1) {
    const currentYear = years.splice(currentYearIndex, 1)[0]
    years.unshift(currentYear)
  }

  const handleConsumptionSave = async () => {
    try {
      const values = {
        year: validation.values.filterYear,
        month: validation.values.filterMonth,
        consumption_type: validation.values.consumption_type,
        client_project_unit_id: validation.values.client_project_unit_id,
      }
      const consumptionSubmit = await addNewConsumption(validation.values)
      if (consumptionSubmit.status === true) {
        validation.setFieldValue("opening_unit", "")
        validation.setFieldValue("closing_unit", "")
        validation.setFieldValue("client_project_unit_id", "")
        validation.setFieldValue("consumption_type", "")
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Your Consumption has been Saved.",
        }).then(result => {
          if (result.isConfirmed) {
            dispatch(getConsumptionRegisterList(values))
          }
        })
      }
    } catch (error) {
      Swal.fire({
        title: "Failure",
        icon: "error",
        text: error.response.data.message || "Your work has been saved.",
      })
    }
  }

  useEffect(() => {
    if (
      validation.values.client_project_unit_id &&
      validation.values.filterMonth &&
      validation.values.filterYear
      // validation.values.dated
    ) {
      const values = {
        // date: validation.values.dated,
        year: validation.values.filterYear,
        month: validation.values.filterMonth,
        consumption_type: validation.values.consumption_type,
        client_project_unit_id: validation.values.client_project_unit_id,
      }
      dispatch(getConsumptionRegisterList(values))
    }
  }, [
    validation.values.client_project_unit_id,
    validation.values.filterYear,
    validation.values.filterMonth,
    validation.values.consumption_type,
    // validation.values.dated,
  ])

  useEffect(() => {
    if (
      validation.values.client_project_unit_id &&
      validation.values.consumption_type &&
      validation.values.dated
    ) {
      validation.setFieldValue("opening_unit", 0)

      const values = {
        date: validation.values.dated,
        consumption_type: validation.values.consumption_type,
        client_project_unit_id: validation.values.client_project_unit_id,
      }

      dispatch(getConsumptionData(values))
    }
  }, [
    validation.values.client_project_unit_id,
    validation.values.dated,
    validation.values.consumption_type,
  ])
  console.log("consumptionClosing", consumptionClosing)

  useEffect(() => {
    if (consumptionClosing > 0) {
      console.log("consumptionClosing", consumptionClosing)
      validation.setFieldValue("opening_unit", consumptionClosing)
      validation.setFieldValue("closing_unit", "")
    } else {
      validation.setFieldValue("opening_unit", 0)
      validation.setFieldValue("closing_unit", "")
    }
  }, [validation.values.dated, consumptionClosing])
  useEffect(() => {
    if (validation.values.client_project_unit_id === "") {
      validation.setFieldValue("opening_unit", "")
      validation.setFieldValue("closing_unit", "")
    }
  }, [validation.values.client_project_unit_id])

  const handleReset = async () => {
    try {
      const values = {
        year: validation.values.filterYear,
        month: validation.values.filterMonth,
        consumption_type: validation.values.consumption_type,
        client_project_unit_id: validation.values.client_project_unit_id,
      }

      const newValues = {
        ...validation.values,
        closing_unit: -validation.values.opening_unit,
      }

      // Show confirmation dialog before submitting
      const confirmResult = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to reset opening Unit?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel",
      })

      if (confirmResult.isConfirmed) {
        const consumptionSubmit = await addNewConsumption(newValues)

        if (consumptionSubmit.status === true) {
          validation.setFieldValue("opening_unit", "")
          validation.setFieldValue("closing_unit", "")
          validation.setFieldValue("client_project_unit_id", "")
          validation.setFieldValue("consumption_type", "")

          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Opening Unit has been Reset.",
          }).then(result => {
            if (result.isConfirmed) {
              dispatch(getConsumptionRegisterList(values))
            }
          })
        }
      } else {
        Swal.fire("Cancelled", "Your action was cancelled.", "info")
      }
    } catch (error) {
      Swal.fire({
        title: "Failure",
        icon: "error",
        text: error.response?.data?.message || "An error occurred.",
      })
    }
  }

  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {" "}
              Add New Consumption
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Consumption" />
        </Col>
      </Row>
      <div className="mt-3"></div>

      <Card>
        <CardBody>
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="mb-3">Client Details</CardTitle> */}
                    <Row>
                      <Col xxl={2} lg={4}>
                        <div className="mb-3">
                          <Label>Select Project</Label>

                          <select
                            className={`form-select ${
                              validation.touched.project_id &&
                              validation.errors.project_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="project_id"
                            value={validation.values.project_id || ""}
                          >
                            <option value="">Select Project</option>
                            {(projectList || []).map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_id &&
                            validation.errors.project_id && (
                              <div className="invalid-feedback">
                                {validation.errors.project_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col xxl={2} lg={4}>
                        <Label>Select Client</Label>

                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.client_id &&
                              validation.errors.client_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="client_id"
                            value={validation.values.client_id || ""}
                          >
                            <option value="">Select Client</option>
                            {(clientList || []).map(client => (
                              <option key={client.id} value={client.id}>
                                {client.customer_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.client_id &&
                            validation.errors.client_id && (
                              <div className="invalid-feedback">
                                {validation.errors.client_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col xxl={2} lg={4}>
                        <div className="mb-3">
                          <Label>Select Unit</Label>

                          <select
                            className={`form-select ${
                              validation.touched.client_project_unit_id &&
                              validation.errors.client_project_unit_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="client_project_unit_id"
                            value={validation.values.client_project_unit_id}
                          >
                            <option value="">Select Project Unit</option>
                            {(assignedProjectUnitList || []).map(company => (
                              <option key={company.id} value={company.id}>
                                {company.unit_code}
                              </option>
                            ))}
                          </select>
                          {validation.touched.client_project_unit_id &&
                            validation.errors.client_project_unit_id && (
                              <div className="invalid-feedback">
                                {validation.errors.client_project_unit_id}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={4} xxl={2}>
                        <div className="mb-3">
                          <Label>Select Consumption Type</Label>
                          <select
                            className={`form-select ${
                              validation.touched.consumption_type &&
                              validation.errors.consumption_type
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="consumption_type"
                            value={validation.values.consumption_type || ""}
                          >
                            <option value="">Select Consumption Type</option>
                            <option value="Water">Water</option>
                            <option value="Gas">Gas</option>
                            <option value="Electricity">Electricity</option>
                            <option value="DG">DG</option>
                          </select>
                          {validation.touched.consumption_type &&
                            validation.errors.consumption_type && (
                              <div className="invalid-feedback">
                                {validation.errors.consumption_type}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={4} xxl={3}>
                        {validation.values.client_project_unit_id !== "" &&
                          validation.values.consumption_type !== "" &&
                          LatestEntry && (
                            <Card style={{ backgroundColor: "antiquewhite" }}>
                              <CardBody>
                                <table>
                                  <thead>
                                    <tr>
                                      <th colSpan="3">Latest Entry</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Date</td>
                                      <td>:</td>
                                      <td>{LatestEntry.date || "NA"}</td>
                                    </tr>
                                    <tr>
                                      <td>Closing Unit</td>
                                      <td>:</td>
                                      <td>
                                        {LatestEntry.closing_unit !== undefined
                                          ? LatestEntry.closing_unit < 0
                                            ? 0
                                            : LatestEntry.closing_unit
                                          : "NA"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Entry By</td>
                                      <td>:</td>
                                      <td>{LatestEntry.created_by || "NA"}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </CardBody>
                            </Card>
                          )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {validation.values.client_project_unit_id !== "" &&
              validation.values.client_id !== "" &&
              validation.values.project_id !== "" &&
              validation.values.consumption_type !== "" && (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col lg={4} xxl={2}>
                            <div className="mb-3">
                              <Label>Select Date</Label>
                              <Input
                                type="date"
                                className={`form-control ${
                                  validation.touched.dated &&
                                  validation.errors.dated
                                    ? "is-invalid"
                                    : ""
                                }`}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="dated"
                                value={validation.values.dated || ""}
                              />
                              {validation.touched.dated &&
                                validation.errors.dated && (
                                  <div className="invalid-feedback">
                                    {validation.errors.dated}
                                  </div>
                                )}
                            </div>
                          </Col>

                          <Col lg={4} xxl={2}>
                            <div className="mb-3">
                              <Label>Opening Unit</Label>
                              <Input
                                type="text"
                                className={`form-control ${
                                  validation.touched.opening_unit &&
                                  validation.errors.opening_unit
                                    ? "is-invalid"
                                    : ""
                                }`}
                                disabled={consumptionClosing > 0}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="opening_unit"
                                placeholder="Enter Opening Unit"
                                value={validation.values.opening_unit || ""}
                              />
                              {validation.touched.opening_unit &&
                                validation.errors.opening_unit && (
                                  <div className="invalid-feedback">
                                    {validation.errors.opening_unit}
                                  </div>
                                )}
                              {validation?.values?.client_project_unit_id &&
                                validation?.values?.consumption_type &&
                                consumptionClosing > 0 && (
                                  <div>
                                    <Link onClick={handleReset}>
                                      <p> Reset Opening Unit</p>
                                    </Link>
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col lg={4} xxl={2}>
                            <div className="mb-3">
                              <Label>Closing Unit</Label>
                              <Input
                                type="text"
                                className={`form-control ${
                                  validation.touched.closing_unit &&
                                  validation.errors.closing_unit
                                    ? "is-invalid"
                                    : ""
                                }`}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="closing_unit"
                                placeholder="Enter Closing Unit"
                                value={validation.values.closing_unit || ""}
                              />
                              {validation.touched.closing_unit &&
                                validation.errors.closing_unit && (
                                  <div className="invalid-feedback">
                                    {validation.errors.closing_unit}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col lg={4} xxl={2}>
                            <div className="mb-3">
                              <Label>Total Consumption</Label>
                              <Input
                                type="text"
                                disabled
                                className="form-control "
                                // onChange={validation.handleChange}
                                name="total"
                                placeholder="Total"
                                value={
                                  parseFloat(
                                    validation.values.closing_unit || 0
                                  ) -
                                    parseFloat(
                                      validation.values.opening_unit || 0
                                    ) || 0
                                }
                              />
                            </div>
                          </Col>
                          {
                            // validation.values.date &&
                            !validation.errors.opening_unit &&
                              !validation.errors.closing_unit && (
                                <Col>
                                  <Label></Label>
                                  <div className="mb-3 mt-1">
                                    <button
                                      type="button"
                                      className="btn btn-primary w-md"
                                      onClick={e => {
                                        e.preventDefault()
                                        // Assuming handleConsumptionSave is defined and will handle the save operation
                                        handleConsumptionSave()
                                      }}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </Col>
                              )
                          }
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row className="justify-content-end">
                      <CardTitle> Consumption List</CardTitle>
                      <Col xxl={2} lg={4}>
                        <Label htmlFor="month">Select Filter Month</Label>
                        <div className="mb-2" id="datepicker1">
                          <Input
                            type="select"
                            id="filterMonth"
                            name="filterMonth"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.filterMonth}
                            className={`form-control ${
                              validation.touched.filterMonth &&
                              validation.errors.filterMonth
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Month</option>
                            {months.map((month, index) => (
                              <option key={index + 1} value={index + 1}>
                                {month}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.filterMonth &&
                          validation.errors.filterMonth ? (
                            <FormFeedback type="invalid">
                              {validation.errors.filterMonth}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xxl={2} lg={4}>
                        <Label htmlFor="year">Select Filter Year</Label>
                        <div className="mb-2" id="datepicker2">
                          <Input
                            type="select"
                            id="filterYear"
                            name="filterYear"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.filterYear}
                            className={`form-control ${
                              validation.touched.filterYear &&
                              validation.errors.filterYear
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Year</option>
                            {years.map(year => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.filterYear &&
                          validation.errors.filterYear ? (
                            <FormFeedback type="invalid">
                              {validation.errors.filterYear}
                            </FormFeedback>
                          ) : null}
                        </div>{" "}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-2">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Opening Unit</th>
                                <th>Closing Unit</th>
                                <th>Total Consumption</th>
                                <th>Created By</th>
                                <th>Created On</th>

                                {/* Add more table headers as needed */}
                              </tr>
                            </thead>
                            <tbody>
                              {(consumptionDataList || []).map(
                                (data, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.date}</td>
                                    <td>{data.opening_unit}</td>
                                    <td>{data.closing_unit}</td>
                                    <td>{data.unit_consumption}</td>
                                    <td>{data.created_by}</td>
                                    <td>{data?.created_on}</td>

                                    {/* <td>
                                  <button
                                    // className="btn btn-danger btn-sm"
                                    type="button "
                                    style={{
                                      border: "none",
                                      background: "none",
                                      padding: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      onClickDelete(data.payment_id)
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-delete"
                                      style={{ color: "red", fontSize: "18px" }}
                                    />
                                  </button>
                                </td> */}
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default withRouter(NewConsumption)

NewConsumption.propTypes = {
  history: Proptypes.object,
}
