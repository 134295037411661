import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import VLogo from "../../assets/images/VIP/VLogo.png"
// import {
//   addNewTimeregion,
//   getTimeregion,
//   getTimeregionFail,
// } from "store/actions"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  FormGroup,
  InputGroup,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { addNewTimeregion } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"
import { getCurrency, getDateTimeFormat, getTimeZone } from "store/actions"
import { useSelector } from "react-redux"

const TimeRegion = props => {
  const { data } = props

  const dispatch = useDispatch()
  const dateTimeFormatState = state => state.regions.dateAndTime.data
  const dateAndTime = useSelector(dateTimeFormatState)

  const timeZoneState = state => state.regions.timeZone.timezones
  const timeZone = useSelector(timeZoneState)

  const currencyState = state => state.regions.currency.currencies
  const currency = useSelector(currencyState)

  useEffect(() => {
    const fetchData = () => {
      dispatch(getDateTimeFormat())
      dispatch(getTimeZone())
      dispatch(getCurrency())
    }

    fetchData()
  }, [dispatch])

  const validation1 = useFormik({
    initialValues: {
      //Time/ Region
      datetime_format: (data && data.datetime_format) || "",
      time_zone: (data && data.time_zone) || "",
      currency: (data && data.currency) || "",
    },
    validation1Schema: {
      //Time/ Region
      datetime_format: Yup.string().required("Please Select Time Format"),
      time_zone: Yup.string().required("Please Select Timezone"),
      currency: Yup.string().required("Please Select Currency"),
    },

    onSubmit: async values => {
      

      try {
        // const response = dispatch(addNewTimeregion(values))
        const response = await addNewTimeregion(values)
        if (response && response.status === true) {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Time/Region has been Submitted.",
          })
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Network Error:", error.response.data.message)
      }
    },
  })

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Time / Region</CardTitle>
        <Form autoComplete="off">
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="datetime_format">
                  Date time format throughout the application
                </Label>
                <select
                  name="datetime_format"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.datetime_format || ""}
                  className={`form-select ${
                    validation1.touched.datetime_format &&
                    validation1.errors.datetime_format
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  {!validation1.values.datetime_format && (
                    <option>Select Time Format</option>
                  )}
                  {(dateAndTime || []).map(item => (
                    <option key={item.id} value={item.id}>
                      {item.datetime_format}
                    </option>
                  ))}
                </select>
                {validation1.touched.datetime_format &&
                validation1.errors.datetime_format ? (
                  <FormFeedback type="invalid">
                    {validation1.errors.datetime_format}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="time_zone">Time zone selection</Label>
                <select
                  name="time_zone"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.time_zone || ""}
                  className={`form-select ${
                    validation1.touched.time_zone &&
                    validation1.errors.time_zone
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  {!validation1.values.time_zone && (
                    <option>Select Timezone</option>
                  )}

                  {(timeZone || []).map(item => (
                    <option key={item.id} value={item.id}>
                      {item.timezone}
                    </option>
                  ))}
                </select>
                {validation1.touched.time_zone &&
                validation1.errors.time_zone ? (
                  <FormFeedback type="invalid">
                    {validation1.errors.time_zone}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="time_zone">Currency selection</Label>
                <select
                  name="currency"
                  onChange={validation1.handleChange}
                  onBlur={validation1.handleBlur}
                  value={validation1.values.currency || ""}
                  className={`form-select ${
                    validation1.touched.currency && validation1.errors.currency
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  {!validation1.values.currency && (
                    <option>Select Currency</option>
                  )}
                  {(currency || []).map(item => (
                    <option key={item.id} value={item.id}>
                      {item.currency_name}
                    </option>
                  ))}
                </select>
                {validation1.touched.currency && validation1.errors.currency ? (
                  <FormFeedback type="invalid">
                    {validation1.errors.currency}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary w-md"
                onClick={validation1.handleSubmit}
              >
                Save Changes
              </button>
            </div>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}
export default TimeRegion
