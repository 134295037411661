import { downloadProjectUnitDataExcel } from "helpers/fakebackend_helper";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Table } from "reactstrap";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { newUnitAssignedList } from "store/actions";

const NewUnitsAssignedModal = ({ isOpen, toggle, filterResponse }) => {
  const dispatch = useDispatch();

  const newUnitListState = state => state.Dashboard?.unitList?.project_units;
  const newUnitList = useSelector(newUnitListState);

  useEffect(() => {
    if(isOpen===true){

    dispatch(newUnitAssignedList({ filter: filterResponse }));
    }
  }, [filterResponse, dispatch,isOpen]);

  const handleExport = async() => {
    // Construct the URL for download
    const value = {
      filter:filterResponse,
      type: 'NewUnits'
    } 
    const downloadUrl = await downloadProjectUnitDataExcel(value)
   
  }
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          New Units Assigned
        </h5>
        <button
          onClick={handleExport}
          type="button"
          className="btn btn-primary btn-sm me-4"
        >
          Export
        </button>
        <button
          onClick={toggle}
          type="button"
          className="close mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <SimpleBar style={{ maxHeight: "60vh", overflowX: "auto" }}>
          <div style={{ minWidth: "100%" }}>
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Unit Code</th>
                  <th>Agreement Start Date</th>
                  <th>Agreement End Date</th>
                </tr>
              </thead>
              <tbody>
                {(newUnitList || []).map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{row.project_name}</td>
                    <td>{row.customer_name}</td>
                    <td>{row.unit_code}</td>
                    <td>{row.agreement_start_date}</td>
                    <td>{row.agreement_end_date}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </SimpleBar>
      </div>
    </Modal>
  );
};

export default NewUnitsAssignedModal;
