/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* USERS DETAIL*/
export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_USER = "ADD_NEW_USER"
 export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
 export const ADD_USER_FAIL = "ADD_USER_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_USER = "UPDATE_USER"
 export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
 export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_USER = "DELETE_USER"
 export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
 export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

 // Documentation

 export const GET_DOCUMENTATION = "GET_DOCUMENTATION"
 export const GET_DOCUMENTATION_SUCCESS = "GET_DOCUMENTATION_SUCCESS"
 export const GET_DOCUMENTATION_FAIL = "GET_DOCUMENTATION_FAIL"

 export const ADD_DOCUMENTATION = "ADD_DOCUMENTATION"
 export const ADD_DOCUMENTATION_SUCCESS = "ADD_DOCUMENTATION_SUCCESS"
 export const ADD_DOCUMENTATION_FAIL = "ADD_DOCUMENTATION_FAIL"

 export const UPDATE_DOCUMENTATION = "UPDATE_DOCUMENTATION"
 export const UPDATE_DOCUMENTATION_SUCCESS = "UPDATE_DOCUMENTATION_SUCCESS"
 export const UPDATE_DOCUMENTATION_FAIL = "UPDATE_DOCUMENTATION_FAIL"

 export const DELETE_DOCUMENTATION = "DELETE_DOCUMENTATION"
 export const DELETE_DOCUMENTATION_SUCCESS = "DELETE_DOCUMENTATION_SUCCESS"
 export const DELETE_DOCUMENTATION_FAIL = "DELETE_DOCUMENTATION_FAIL"

 //PAST EMPLOYMENT

 export const GET_PAST_EMPLOYMENT = "GET_PAST_EMPLOYMENT"
 export const GET_PAST_EMPLOYMENT_SUCCESS = "GET_PAST_EMPLOYMENT_SUCCESS"
 export const GET_PAST_EMPLOYMENT_FAIL = "GET_PAST_EMPLOYMENT_FAIL"

 export const GET_PAST_EMPLOYMENT_DETAIL = "GET_PAST_EMPLOYMENT_DETAIL"
 export const GET_PAST_EMPLOYMENT_DETAIL_SUCCESS = "GET_PAST_EMPLOYMENT_DETAIL_SUCCESS"
 export const GET_PAST_EMPLOYMENT_DETAIL_FAIL = "GET_PAST_EMPLOYMENT_DETAIL_FAIL"

 export const ADD_PAST_EMPLOYMENT = "ADD_PAST_EMPLOYMENT"
 export const ADD_PAST_EMPLOYMENT_SUCCESS = "ADD_PAST_EMPLOYMENT_SUCCESS"
 export const ADD_PAST_EMPLOYMENT_FAIL = "ADD_PAST_EMPLOYMENT_FAIL"

 export const UPDATE_PAST_EMPLOYMENT = "UPDATE_PAST_EMPLOYMENT"
 export const UPDATE_PAST_EMPLOYMENT_SUCCESS = "UPDATE_PAST_EMPLOYMENT_SUCCESS"
 export const UPDATE_PAST_EMPLOYMENT_FAIL = "UPDATE_PAST_EMPLOYMENT_FAIL"

 export const DELETE_PAST_EMPLOYMENT = "DELETE_PAST_EMPLOYMENT"
 export const DELETE_PAST_EMPLOYMENT_SUCCESS = "DELETE_EMPLOYMENT_SUCCESS"
 export const DELETE_PAST_EMPLOYMENT_FAIL = "DELETE_EMPLOYMENT_FAIL"

 // ASSIGNED CUSTOMER

 export const GET_ASSIGNED_CUSTOMER = "GET_ASSIGNED_CUSTOMER"
 export const GET_ASSIGNED_CUSTOMER_SUCCESS = "GET_ASSIGNED_CUSTOMER_SUCCESS"
 export const GET_ASSIGNED_CUSTOMER_FAIL = "GET_ASSIGNED_CUSTOMER_FAIL"

 //REINVITE 
 
 export const REINVITE = "POST_REINVITE"
 export const REINVITE_SUCCESS = "REINVITE_SUCCESS"
 export const REINVITE_FAIL = "REINVITE_FAIL"

 //PERMISSION



 export const GET_USER_PERMISSION = "GET_USER_PERMISSION"
 export const GET_USER_PERMISSION_SUCCESS = "GET_USER_PERMISSION_SUCCESS"
 export const GET_USER_PERMISSION_FAIL = "GET_USER_PERMISSION_FAIL"

 export const ADD_USER_PERMISSION="ADD_USER_PERMISSION"
 export const ADD_USER_PERMISSION_SUCCESS="ADD_USER_PERMISSION_SUCCESS"
 export const ADD_USER_PERMISSION_FAIL="ADD_USER_PERMISSION_FAIL"

 //RESET USER PASSWORD

 export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD"
 export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS"
 export const RESET_USER_PASSWORD_FAIL = "RESET_USER_PASSWORD_FAIL"

 //GET EMPLOYEE CODE 

 export const GET_EMPLOYEE_CODE = "GET_EMPLOYEE_CODE"
 export const GET_EMPLOYEE_CODE_SUCCESS = "GET_EMPLOYEE_CODE_SUCCESS"
 export const GET_EMPLOYEE_CODE_FAIL = "GET_EMPLOYEE_CODE_FAIL"

 // GET PROJECT NAMES FOR USER
 
 export const GET_PROJECT_NAME_FOR_USER = "GET_PROJECT_NAME_FOR_USER"
 export const GET_PROJECT_NAME_FOR_USER_SUCCESS = "GET_PROJECT_NAME_FOR_USER_SUCCESS"
 export const GET_PROJECT_NAME_FOR_USER_FAIL = "GET_PROJECT_NAME_FOR_USER_FAIL"


