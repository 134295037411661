import { assignServiceRequest } from "./actions"
import {
  GET_SERVICE_REQUESTS_FAIL,
  GET_SERVICE_REQUESTS_SUCCESS,
  GET_SERVICE_REQUEST_DETAIL_FAIL,
  GET_SERVICE_REQUEST_DETAIL_SUCCESS,
  ADD_SERVICE_REQUEST_SUCCESS,
  ADD_SERVICE_REQUEST_FAIL,
  UPDATE_SERVICE_REQUEST_SUCCESS,
  UPDATE_SERVICE_REQUEST_FAIL,
  DELETE_SERVICE_REQUEST_SUCCESS,
  DELETE_SERVICE_REQUEST_FAIL,
  GET_PROJECT_UNIT_CATEGORY_SUCCESS,
  GET_PROJECT_UNIT_CATEGORY_FAIL,
  GET_MAINTENANCE_ISSUES_SUCCESS,
  GET_MAINTENANCE_ISSUES_FAIL,
  GET_CLIENT_INVOICES_SUCCESS,
  GET_CLIENT_INVOICES_FAIL,
  GET_SUPPORT_TICKET_SUCCESS,
  GET_SUPPORT_TICKET_FAIL,
  GET_SERVICE_REQUEST_TYPE_FAIL,
  GET_SERVICE_REQUEST_TYPE_SUCCESS,
  GET_ALL_SERVICE_REQUEST_LIST_SUCCESS,
  GET_ALL_SERVICE_REQUEST_LIST_FAIL,
  GET_PENDING_SERVICE_REQUEST_LIST_SUCCESS,
  GET_PENDING_SERVICE_REQUEST_LIST_FAIL,
  GET_PROJECT_MANAGER_IN_SERVICE_REQUEST_SUCCESS,
  GET_PROJECT_MANAGER_IN_SERVICE_REQUEST_FAIL,
  GET_USERS_IN_SERVICE_REQUEST_SUCCESS,
  GET_USERS_IN_SERVICE_REQUEST_FAIL,
  GET_VENDORS_IN_SERVICE_REQUEST_SUCCESS,
  GET_VENDORS_IN_SERVICE_REQUEST_FAIL,
  ASSIGN__SERVICE_REQUEST_SUCCESS,
  ASSIGN__SERVICE_REQUEST_FAIL,
  CREATE_SERVICE_REQUEST_SUCCESS,
  CREATE_SERVICE_REQUEST_FAIL,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_FAIL,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST_SUCCESS,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST_FAIL,
  GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE_SUCCESS,
  GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  serviceRequests: [],
  addServiceRequest: [],
  assignServiceRequest: [],
  serviceRequestType: [],
  projectUnits: [],
  maintenanceIssues: [],
  clientInvoices: [],
  supportTickets: [],
  allServiceRequestList: [],
  allServiceRequestListStatusWise: [],
  pendingServiceRequestList: [],
  createServiceRequest: [],
  managers: [],
  vendors: [],
  users: [],
  materials: [],
  units: [],
  serviceRequestDetail: {},
  error: {},
  loading: true,
}

const serviceRequests = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_REQUESTS_SUCCESS:
      return {
        ...state,
        serviceRequests: action.payload,
        loading: false,
      }

    case GET_SERVICE_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERVICE_REQUEST_TYPE_SUCCESS:
      return {
        ...state,
        serviceRequestType: action.payload,
        loading: false,
      }

    case GET_SERVICE_REQUEST_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        addServiceRequest: [...state.addServiceRequest, action.payload],
      }

    case ADD_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERVICE_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        serviceRequestDetail: action.payload,
      }

    case UPDATE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        serviceRequests: state.serviceRequests.map(serviceRequest =>
          serviceRequest.id.toString() === action.payload.id.toString()
            ? { serviceRequest, ...action.payload }
            : serviceRequest
        ),
      }

    case UPDATE_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        serviceRequests: state.serviceRequests.filter(
          serviceRequest =>
            serviceRequest.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERVICE_REQUEST_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //PROJECT UNIT CATEGORY
    case GET_PROJECT_UNIT_CATEGORY_SUCCESS:
      return {
        ...state,
        projectUnits: action.payload,
        loading: false,
      }

    case GET_PROJECT_UNIT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //MAINTENANCE ISSUES
    case GET_MAINTENANCE_ISSUES_SUCCESS:
      return {
        ...state,
        maintenanceIssues: action.payload,
        loading: false,
      }

    case GET_MAINTENANCE_ISSUES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //CLIENT INVOICES
    case GET_CLIENT_INVOICES_SUCCESS:
      return {
        ...state,
        clientInvoices: action.payload,
        loading: false,
      }

    case GET_CLIENT_INVOICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //SUPPORT TICKET
    case GET_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        supportTickets: action.payload,
        loading: false,
      }

    case GET_SUPPORT_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //All Service Request

    case GET_ALL_SERVICE_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        allServiceRequestList: action.payload,
        loading: false,
      }

    case GET_ALL_SERVICE_REQUEST_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //All Service Request status wise

    case GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE_SUCCESS:
      return {
        ...state,
        allServiceRequestListStatusWise: action.payload,
        loading: false,
      }

    case GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //Pending Service Request List

    case GET_PENDING_SERVICE_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        pendingServiceRequestList: action.payload,
        loading: false,
      }

    case GET_PENDING_SERVICE_REQUEST_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // GET PROJECT MANAGER
    case GET_PROJECT_MANAGER_IN_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        managers: action.payload,
        loading: false,
      }

    case GET_PROJECT_MANAGER_IN_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // GET USERS

    case GET_USERS_IN_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      }

    case GET_USERS_IN_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // GET VENDORS

    case GET_VENDORS_IN_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        vendors: action.payload,
        loading: false,
      }

    case GET_VENDORS_IN_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ASSIGN__SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        assignServiceRequest: [...state.assignServiceRequest, action.payload],
      }

    case ASSIGN__SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case CREATE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        createServiceRequest: [...state.createServiceRequest, action.payload],
      }

    case CREATE_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        materials: action.payload,
        loading: false,
      }

    case GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_UNIT_MASTER_FOR_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        units: action.payload,
        loading: false,
      }

    case GET_UNIT_MASTER_FOR_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default serviceRequests
