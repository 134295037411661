import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_USERS,
  GET_USER_DETAIL,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER,
  GET_DOCUMENTATION,
  ADD_DOCUMENTATION,
  UPDATE_DOCUMENTATION,
  DELETE_DOCUMENTATION,
  GET_PAST_EMPLOYMENT,
  ADD_PAST_EMPLOYMENT,
  UPDATE_PAST_EMPLOYMENT,
  DELETE_PAST_EMPLOYMENT,
  GET_ASSIGNED_CUSTOMER,
  GET_PAST_EMPLOYMENT_DETAIL,
  REINVITE,
  GET_USER_PERMISSION,
  ADD_USER_PERMISSION,
  RESET_USER_PASSWORD,
  GET_EMPLOYEE_CODE,
  GET_PROJECT_NAME_FOR_USER,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserDetailSuccess,
  getUserDetailFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getDocumentationSuccess,
  getDocumentationFail,
  addDocumentationSuccess,
  addDocumentationFail,
  updateDocumentationSuccess,
  updateDocumentationFail,
  deleteDocumentationSuccess,
  deleteDocumentationFail,
  getPastEmploymentSuccess,
  getPastEmploymentFail,
  getPastEmploymentDetailSuccess,
  getPastEmploymentDetailFail,
  addPastEmploymentSuccess,
  addPastEmploymentFail,
  updatePastEmploymentSuccess,
  updatePastEmploymentFail,
  deletePastEmploymentSuccess,
  deletePastEmploymentFail,
  getAssignedCustomerSuccess,
  getAssignedCustomerFail,
  reinviteSuccess,
  reinviteFail,
  getUserPermissionSuccess,
  getUserPermissionFail,
  addNewUserRoleSuccess,
  addNewUserRoleFail,
  resetUserPasswordSuccess,
  resetUserPasswordFail,
  getEmployeeCodeSuccess,
  getEmployeeCodeFail,
  getProjectNamesForUserSuccess,
  getProjectNamesForUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUsers,
  getUserDetails,
  addNewUser,
  updateUser,
  deleteUser,
  getDocumentation,
  addDocumentation,
  updateDocumentation,
  deleteDocumentation,
  getPastEmployment,
  addPastEmployment,
  updatePastEmployment,
  deletePastEmployment,
  getAssignedCustomer,
  getPastEmploymentDetail,
  reinvite,
  getUserPermission,
  addNewUserRole,
  resetUserPassword,
  getEmployeeCode,
  getProjectNamesForUser,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserDetail({ userId }) {
  try {
    const response = yield call(getUserDetails, userId)
    yield put(getUserDetailSuccess(response))
  } catch (error) {
    yield put(getUserDetailFail(error))
  }
}

function* onUpdateUser({ payload: { id, user } }) {
  try {
    const response = yield call(updateUser, id, user)
    yield put(updateUserSuccess(response))
    // toast.success("User Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateUserFail(error))
    // toast.error("User Update Failded", { autoClose: 2000 })
  }
}

function* onDeleteUser({ user }) {
  try {
    const response = yield call(deleteUser, user)
    if (response.status === true) {
      // loadingSwal.close()
      yield put(deleteUserSuccess(response))

      // Swal.fire({
      //   title: "Success",
      //   icon: "success",
      //   text: "User has been deleted Successfully.",
      // })
    } else {
      Swal.fire({
        title: "failure",
        icon: "error",
        text: "User has been deleted Successfully.",
      })
    }
  } catch (error) {
    yield put(deleteUserFail(error))
    console.error("Error:", error)
    Swal.fire({
      title: "Error",
      icon: "error",
      text:
        error.response.data.message ||
        "An error occurred while saving your Billing Setup.",
    })
  }
}

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield call(addNewUser, user)
    yield put(addUserSuccess(response))
    // toast.success("User Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addUserFail(error))
    // toast.error("User Add Failded", { autoClose: 2000 })
  }
}

function* fetchDocumentation({ userId }) {
  try {
    const response = yield call(getDocumentation, userId)
    yield put(getDocumentationSuccess(response))
  } catch (error) {
    yield put(getDocumentationFail(error))
  }
}

function* onAddDocumentation({ payload: user }) {
  try {
    const response = yield call(addDocumentation, user)
    yield put(addDocumentationSuccess(response))
  } catch (error) {
    yield put(addDocumentationFail(error))
  }
}

function* onUpdateDocumentation({ payload: { id, user } }) {
  try {
    const response = yield call(updateDocumentation, id, user)
    yield put(updateDocumentationSuccess(response))
  } catch (error) {
    yield put(updateDocumentationFail(error))
  }
}

function* onDeleteDocumentation({ docs }) {
  try {
    const response = yield call(deleteDocumentation, docs)
    yield put(deleteDocumentationSuccess(response))
    // toast.success("User Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteDocumentationFail(error))
    // toast.error("User Delete Failded", { autoClose: 2000 })
  }
}

function* fetchPastEmployment({ emp }) {
  try {
    const response = yield call(getPastEmployment, emp)
    yield put(getPastEmploymentSuccess(response))
  } catch (error) {
    yield put(getPastEmploymentFail(error))
  }
}
function* fetchPastEmploymentDetail({ emp }) {
  try {
    const response = yield call(getPastEmploymentDetail, emp)

    yield put(getPastEmploymentDetailSuccess(response))
  } catch (error) {
    yield put(getPastEmploymentDetailFail(error))
  }
}

function* onAddPastEmployment({ payload: { id, user } }) {
  try {
    const response = yield call(addPastEmployment, id, user)
    yield put(addPastEmploymentSuccess(response))
    // toast.success("User Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addPastEmploymentFail(error))
    // toast.error("User Add Failded", { autoClose: 2000 })
  }
}

function* onUpdatePastEmployment({ payload: { id, user } }) {
  try {
    const response = yield call(updatePastEmployment, id, user)
    yield put(updatePastEmploymentSuccess(response))
    // toast.success("User Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updatePastEmploymentFail(error))
    // toast.error("User Update Failded", { autoClose: 2000 })
  }
}

function* onDeletePastEmployment({ payload: user }) {
  try {
    const response = yield call(deletePastEmployment, user)
    yield put(deletePastEmploymentSuccess(response))
    // toast.success("User Delete Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deletePastEmploymentFail(error))
    // toast.error("User Delete Failded", { autoClose: 2000 })
  }
}

function* fetchAssignedCustomer({ userId }) {
  try {
    const response = yield call(getAssignedCustomer, userId)
    yield put(getAssignedCustomerSuccess(response))
  } catch (error) {
    yield put(getAssignedCustomerFail(error))
  }
}

function* reinviteApi({ payload: user }) {
  try {
    const response = yield call(reinvite, user)
    yield put(reinviteSuccess(response))
  } catch (error) {
    yield put(reinviteFail(error))
  }
}

function* fetchUserPermission({ userId }) {
  try {
    const response = yield call(getUserPermission, userId)
    yield put(getUserPermissionSuccess(response))
  } catch (error) {
    yield put(getUserPermissionFail(error))
  }
}

function* onAddNewUserRole({ payload: { id, user } }) {
  try {
    const response = yield call(addNewUserRole, id, user)
    yield put(addNewUserRoleSuccess(response))
    // toast.success("User Add Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addNewUserRoleFail(error))
    // toast.error("User Add Failded", { autoClose: 2000 })
  }
}

function* onResetUserPassword({ payload: { user, history } }) {
  try {
    const response = yield call(resetUserPassword, user, history)
    yield put(resetUserPasswordSuccess(response))
  } catch (error) {
    yield put(resetUserPasswordFail(error))
  }
}

function* fetchEmployeeCode() {
  try {
    const response = yield call(getEmployeeCode)
    yield put(getEmployeeCodeSuccess(response))
  } catch (error) {
    yield put(getEmployeeCodeFail(error))
  }
}
function* fetchProjectNamesForUser() {
  try {
    const response = yield call(getProjectNamesForUser)
    yield put(getProjectNamesForUserSuccess(response))
  } catch (error) {
    yield put(getProjectNamesForUserFail(error))
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)

  yield takeEvery(GET_DOCUMENTATION, fetchDocumentation)
  yield takeEvery(ADD_DOCUMENTATION, onAddDocumentation)
  yield takeEvery(UPDATE_DOCUMENTATION, onUpdateDocumentation)
  yield takeEvery(DELETE_DOCUMENTATION, onDeleteDocumentation)

  yield takeEvery(GET_PAST_EMPLOYMENT, fetchPastEmployment)
  yield takeEvery(GET_PAST_EMPLOYMENT_DETAIL, fetchPastEmploymentDetail)
  yield takeEvery(ADD_PAST_EMPLOYMENT, onAddPastEmployment)
  yield takeEvery(UPDATE_PAST_EMPLOYMENT, onUpdatePastEmployment)
  yield takeEvery(DELETE_PAST_EMPLOYMENT, onDeletePastEmployment)

  yield takeEvery(GET_ASSIGNED_CUSTOMER, fetchAssignedCustomer)

  yield takeEvery(REINVITE, reinviteApi)

  yield takeEvery(GET_USER_PERMISSION, fetchUserPermission)
  yield takeEvery(ADD_USER_PERMISSION, onAddNewUserRole)

  yield takeEvery(RESET_USER_PASSWORD, onResetUserPassword)

  yield takeEvery(GET_EMPLOYEE_CODE, fetchEmployeeCode)
  yield takeEvery(GET_PROJECT_NAME_FOR_USER, fetchProjectNamesForUser)
}

export default usersSaga
