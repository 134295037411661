/* ALL_PROJECTS */
export const GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING = "GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING"
export const GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_SUCCESS = "GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_SUCCESS"
export const GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_FAIL = "GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING_FAIL"

/* GET_FINANCIAL_YEAR_FOR_OUTSTANDING */
export const GET_FINANCIAL_YEAR_FOR_OUTSTANDING = "GET_FINANCIAL_YEAR_FOR_OUTSTANDING"
export const GET_FINANCIAL_YEAR_FOR_OUTSTANDING_SUCCESS = "GET_FINANCIAL_YEAR_FOR_OUTSTANDING_SUCCESS"
export const GET_FINANCIAL_YEAR_FOR_OUTSTANDING_FAIL = "GET_FINANCIAL_YEAR_FOR_OUTSTANDING_FAIL"

/* GET LEDGER DETAIL*/
export const GET_LEDGER_DETAIL = "GET_LEDGER_DETAIL"
export const GET_LEDGER_DETAIL_SUCCESS = "GET_LEDGER_DETAIL_SUCCESS"
export const GET_LEDGER_DETAIL_FAIL = "GET_LEDGER_DETAIL_FAIL"

/* ALL_COMPANIES */
export const GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING = "GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING"
export const GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_SUCCESS = "GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_SUCCESS"
export const GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_FAIL = "GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING_FAIL"

/* PROJECT OCCUPANCY DETAIL*/
export const GET_REPORT_FOR_CUSTOMER_OUTSTANDING = "GET_REPORT_FOR_CUSTOMER_OUTSTANDING"
export const GET_REPORT_FOR_CUSTOMER_OUTSTANDING_SUCCESS = "GET_REPORT_FOR_CUSTOMER_OUTSTANDING_SUCCESS"
export const GET_REPORT_FOR_CUSTOMER_OUTSTANDING_FAIL = "GET_REPORT_FOR_CUSTOMER_OUTSTANDING_FAIL"