

const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return "blue"
    case 2:
      return "violet"
    case 3:
      return "orange"
    case 4:
      return "green"
    case 5:
      return "red"
    case 6:
      return "brown"

    default:
      return "black"
  }
};

export default getStatusColor;
