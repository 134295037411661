import {
  GET_UNITS_FAIL,
  GET_UNITS_SUCCESS,
  GET_UNIT_DETAIL_FAIL,
  GET_UNIT_DETAIL_SUCCESS,
  ADD_UNIT_SUCCESS,
  ADD_UNIT_FAIL,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAIL,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAIL,
  GET_ACTIVE_UNITS_SUCCESS,
  GET_ACTIVE_UNITS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  units: [],
  activeUnits: [],
  unitDetail: {},
  error: {},
  loading: true
};

const units = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UNITS_SUCCESS:
      return {
        ...state,
        units: action.payload,
        loading: true
        // loading: true
      };

    case GET_ACTIVE_UNITS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ACTIVE_UNITS_SUCCESS:
      return {
        ...state,
        activeUnits: action.payload,
        loading: true
        // loading: true
      };

    case GET_UNITS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_UNIT_SUCCESS:
      return {
        ...state,
        units: [...state.units, action.payload],
      };

    case ADD_UNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_UNIT_DETAIL_SUCCESS:
      return {
        ...state,
        unitDetail: action.payload,
      };

    case UPDATE_UNIT_SUCCESS:
      return {
        ...state,
        units: state.units.map(unit =>
          unit.id.toString() === action.payload.id.toString()
            ? { unit, ...action.payload }
            : unit
        ),
      };

    case UPDATE_UNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_UNIT_SUCCESS:
      return {
        ...state,
        units: state.units.data.filter(
          unit => unit.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_UNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    case GET_UNIT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default units;
