/* ALL_PROJECTS */
export const GET_COUNT_FOR_DASHBOARD = "GET_COUNT_FOR_DASHBOARD"
export const GET_COUNT_FOR_DASHBOARD_SUCCESS = "GET_COUNT_FOR_DASHBOARD_SUCCESS"
export const GET_COUNT_FOR_DASHBOARD_FAIL = "GET_COUNT_FOR_DASHBOARD_FAIL"

// GET VACANT PROJECT UNIT LIST 

export const GET_VACANT_PROJECT_UNIT_LIST = "GET_VACANT_PROJECT_UNIT_LIST"
export const GET_VACANT_PROJECT_UNIT_LIST_SUCCESS = "GET_VACANT_PROJECT_UNIT_LIST_SUCCESS"
export const GET_VACANT_PROJECT_UNIT_LIST_FAIL = "GET_VACANT_PROJECT_UNIT_LIST_FAIL"

export const ACTIVITY_FILTER = "ACTIVITY_FILTER"
export const ACTIVITY_FILTER_SUCCESS = "ACTIVITY_FILTER_SUCCESS"
export const ACTIVITY_FILTER_FAIL = "ACTIVITY_FILTER_FAIL"

export const NEW_CUSTOMER_LIST = "NEW_CUSTOMER_LIST"
export const NEW_CUSTOMER_LIST_SUCCESS = "NEW_CUSTOMER_LIST_SUCCESS"
export const NEW_CUSTOMER_LIST_FAIL = "NEW_CUSTOMER_LIST_FAIL"

export const NEW_UNIT_ASSIGNED_LIST = "NEW_UNIT_ASSIGNED_LIST"
export const NEW_UNIT_ASSIGNED_LIST_SUCCESS = "NEW_UNIT_ASSIGNED_LIST_SUCCESS"
export const NEW_UNIT_ASSIGNED_LIST_FAIL = "NEW_UNIT_ASSIGNED_LIST_FAIL"

export const BILL_AMOUNT_LIST = "BILL_AMOUNT_LIST"
export const BILL_AMOUNT_LIST_SUCCESS = "BILL_AMOUNT_LIST_SUCCESS"
export const BILL_AMOUNT_LIST_FAIL = "BILL_AMOUNT_LIST_FAIL"

export const PAYMENT_AMOUNT_LIST = "PAYMENT_AMOUNT_LIST"
export const PAYMENT_AMOUNT_LIST_SUCCESS = "PAYMENT_AMOUNT_LIST_SUCCESS"
export const PAYMENT_AMOUNT_LIST_FAIL = "PAYMENT_AMOUNT_LIST_FAIL"

export const NEW_CUSTOMER_GRAPH = "NEW_CUSTOMER_GRAPH"
export const NEW_CUSTOMER_GRAPH_SUCCESS = "NEW_CUSTOMER_GRAPH_SUCCESS"
export const NEW_CUSTOMER_GRAPH_FAIL = "NEW_CUSTOMER_GRAPH_FAIL"

export const NEW_UNITS_ASSIGNED_GRAPH = "NEW_UNITS_ASSIGNED_GRAPH"
export const NEW_UNITS_ASSIGNED_GRAPH_SUCCESS = "NEW_UNITS_ASSIGNED_GRAPH_SUCCESS"
export const NEW_UNITS_ASSIGNED_GRAPH_FAIL = "NEW_UNITS_ASSIGNED_GRAPH_FAIL"

export const BILL_AND_PAYMENT_GRAPH = "BILL_AND_PAYMENT_GRAPH"
export const BILL_AND_PAYMENT_GRAPH_SUCCESS = "BILL_AND_PAYMENT_GRAPH_SUCCESS"
export const BILL_AND_PAYMENT_GRAPH_FAIL = "BILL_AND_PAYMENT_GRAPH_FAIL"
//dashbaoard modal
export const CUSTOMER_OUTSTANTDING_LIST = "CUSTOMER_OUTSTANTDING_LIST"
export const CUSTOMER_OUTSTANTDING_LIST_SUCCESS = "CUSTOMER_OUTSTANTDING_LIST_SUCCESS"
export const CUSTOMER_OUTSTANTDING_LIST_FAIL = "CUSTOMER_OUTSTANTDING_LIST_FAIL"

export const NEW_VENDOR_LIST = "NEW_VENDOR_LIST"
export const NEW_VENDOR_LIST_SUCCESS = "NEW_VENDOR_LIST_SUCCESS"
export const NEW_VENDOR_LIST_FAIL = "NEW_VENDOR_LIST_FAIL"

export const VENDOR_OUTSTANDING_LIST = "VENDOR_OUTSTANDING_LIST"
export const VENDOR_OUTSTANDING_LIST_SUCCESS = "VENDOR_OUTSTANDING_LIST_SUCCESS"
export const VENDOR_OUTSTANDING_LIST_FAIL = "VENDOR_OUTSTANDING_LIST_FAIL"

export const VENDOR_PAYMENT_LIST = "VENDOR_PAYMENT_LIST"
export const VENDOR_PAYMENT_LIST_SUCCESS = "VENDOR_PAYMENT_LIST_SUCCESS"
export const VENDOR_PAYMENT_LIST_FAIL = "VENDOR_PAYMENT_LIST_FAIL"

export const VENDOR_INVOICE_LIST = "VENDOR_INVOICE_LIST"
export const VENDOR_INVOICE_LIST_SUCCESS = "VENDOR_INVOICE_LIST_SUCCESS"
export const VENDOR_INVOICE_LIST_FAIL = "VENDOR_INVOICE_LIST_FAIL"

export const VENDOR_TICKET_LIST = "VENDOR_TICKET_LIST"
export const VENDOR_TICKET_LIST_SUCCESS = "VENDOR_TICKET_LIST_SUCCESS"
export const VENDOR_TICKET_LIST_FAIL = "VENDOR_TICKET_LIST_FAIL"

export const STATUS_WISE_TICKET_LIST = "STATUS_WISE_TICKET_LIST"
export const STATUS_WISE_TICKET_LIST_SUCCESS = "STATUS_WISE_TICKET_LIST_SUCCESS"
export const STATUS_WISE_TICKET_LIST_FAIL = "STATUS_WISE_TICKET_LIST_FAIL"

