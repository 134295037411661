import {
  GET_ROLES,
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,
  GET_ROLE_DETAIL,
  ADD_NEW_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  GET_ROLE_DETAIL_FAIL,
  GET_ROLE_DETAIL_SUCCESS,
  GET_MODULES,
  GET_MODULES_SUCCESS,
  GET_MODULES_FAIL,
  
  GET_MANAGE_PERMISSION,
  GET_MANAGE_PERMISSION_SUCCESS,
  GET_MANAGE_PERMISSION_FAIL,
} from "./actionTypes"

export const getRoles = () => ({
  type: GET_ROLES,
})

export const getRolesSuccess = roles => ({
  type: GET_ROLES_SUCCESS,
  payload: roles,
})

export const getModules = id => ({
  type: GET_MODULES,
  id,
})

export const getModulesSuccess = roles => ({
  type: GET_MODULES_SUCCESS,
  payload: roles,
})
export const getModulesFail = error => ({
  type: GET_MODULES_FAIL,
  payload: error,
})

export const getManagePermission = id => ({
  type: GET_MANAGE_PERMISSION,
  id,
})

export const getManagePermissionSuccess = roles => ({
  type: GET_MANAGE_PERMISSION_SUCCESS,
  payload: roles,
})
export const getManagePermissionFail = error => ({
  type: GET_MANAGE_PERMISSION_FAIL,
  payload: error,
})

export const addNewRole = role => ({
  type: ADD_NEW_ROLE,
  payload: role,
})

export const addRoleSuccess = role => ({
  type: ADD_ROLE_SUCCESS,
  payload: role,
})

export const addRoleFail = error => ({
  type: ADD_ROLE_FAIL,
  payload: error,
})

export const updateRole = (id, role) => ({
  type: UPDATE_ROLE,
  payload: { id, role },
})

export const updateRoleSuccess = role => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: role,
})

export const updateRoleFail = error => ({
  type: UPDATE_ROLE_FAIL,
  payload: error,
})

export const deleteRole = role => ({
  type: DELETE_ROLE,
  payload: role,
})

export const deleteRoleSuccess = role => ({
  type: DELETE_ROLE_SUCCESS,
  payload: role,
})

export const deleteRoleFail = error => ({
  type: DELETE_ROLE_FAIL,
  payload: error,
})

export const getRolesFail = error => ({
  type: GET_ROLES_FAIL,
  payload: error,
})

export const getRoleDetail = roleId => ({
  type: GET_ROLE_DETAIL,
  roleId,
})

export const getRoleDetailSuccess = roleDetails => ({
  type: GET_ROLE_DETAIL_SUCCESS,
  payload: roleDetails,
})

export const getRoleDetailFail = error => ({
  type: GET_ROLE_DETAIL_FAIL,
  payload: error,
})
