import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./recurringBillListContainer"
import Select from "react-select"
import { API_URL } from "helpers/url_helper"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  getBillingRegisterList,
  getServiceRequests,
  deleteBillingRegister,
  sendEmailToCustomer,
  downloadInvoice,
  getRecurringBillList,
} from "store/actions"

const RecurringBills = () => {
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  document.title = "Recurring Bill List"

  const dispatch = useDispatch()
  const recurringBillListState = state => state.billing.recurringBills.data

  const recurringBillList = useSelector(recurringBillListState) || []

  useEffect(() => {
    dispatch(getRecurringBillList())
  }, [dispatch])

  const sendMail = value => {
    dispatch(sendEmailToCustomer(value))
  }
  const handleDownloadInvoice = billingId => {
    // Construct the URL for download
    const downloadUrl = `${API_URL}/api/download-invoice-pdf/${billingId}`

    // Create an anchor element
    const anchorElement = document.createElement("a")
    anchorElement.href = downloadUrl
    anchorElement.target = "_blank" // Open in a new tab
    anchorElement.download = "invoice.pdf" // Optional: specify a filename for the downloaded file

    // Append the anchor element to the document body (required in some browsers)
    document.body.appendChild(anchorElement)

    // Trigger a click event on the anchor element
    anchorElement.click()

    // Clean up: remove the anchor element from the document body
    document.body.removeChild(anchorElement)
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Customer</span>
          </div>
        ),
        accessor: "customer_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  // to={`/service-request/view-service/${cellProps.row.original.id}`}
                >
                  {cellProps.row.original.customer_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Unit",
        accessor: "unit_code",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">{cellProps.row.original.unit_code}</Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project",
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Date of Invoice",
        accessor: "date_of_invoice",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.date_of_invoice}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Bill Amount",
        accessor: "admin_billing_item_detail[0].total_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original?.admin_billing_item_detail[0]?.total_amount}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Status",
        accessor: "is_paid",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <span
                className={
                  cellProps.row.original.is_paid === "Paid"
                    ? "text-success"
                    : "text-danger"
                }
              >
                {cellProps.row.original.is_paid === "Paid" ? "Paid " : "Unpaid"}
              </span>
            </div>
          )
        },
      },
      {
        Header: "Bill No.",
        accessor: "bill_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.bill_no}
                </Link>
              </h5>
            </>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteBillingRegister(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    dispatch(getBillingRegisterList())
    dispatch(getBillingRegisterList())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const [selectedStatus, setselectedStatus] = useState(null)
  function handleSelectGroup(selectedStatus) {
    setselectedStatus(selectedStatus)
  }
  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Billing" breadcrumbItem="Recurring Billing " />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={recurringBillList}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecurringBills
