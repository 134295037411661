import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import Swal from "sweetalert2"
import { useFormik } from "formik"
import altImg from "../../assets/images/VIP/add_photo_alternate.png"
import VLogo from "../../assets/images/VIP/VLogo.png"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Toast,
  ToastHeader,
  ToastBody,
  navigate,
  FormFeedback,
} from "reactstrap"

import classnames from "classnames"
import { Link, useNavigate } from "react-router-dom"
//Import Breadcrumb
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
// import { addNewCompany, getCompanies, getCompanyDetails, updateCompany } from "helpers/fakebackend_helper"
import { getCompanies, getCompanyDetail } from "../../store/company/actions"
import { addNewCompany, updateCompany } from "helpers/fakebackend_helper"
import { getCities, getCountries, getStates } from "store/actions"
import { error } from "toastr"

const accountsetuppage = () => {
  const [toast2, setToast2] = useState(false)
  const [companyId, setCompanyId] = useState()
  const [isEditMode, setIsEditMode] = useState(false)

  const dispatch = useDispatch()
  const companyDetail = state => state.companies.companyDetail.company
  const company = useSelector(companyDetail)

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const CompanyProperties = createSelector(companyDetail, getCompanyDetail => ({
    error: getCompanyDetail.error,
  }))

  const companyData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const companyId = url.pathname.split("/edit-company/")[1]
      setCompanyId(companyId)
      // const response =  dispatch(getCompanyDetail(companyId))
      dispatch(getCompanyDetail(companyId))

      // setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      companyData()
    }
    dispatch(getCountries())
  }, [dispatch])

  // const [selectedOption1, setSelectedOption1] = useState(
  //   !isEditMode ? "Monthly" : company?.gst_filing_r1_frequency.toString()
  // )
  // const [selectedOption2, setSelectedOption2] = useState(
  //   !isEditMode
  //     ? "Monthly"
  //     : company?.gst_filing_3b_payment_frequency.toString()
  // )
  // const [selectedOption3, setSelectedOption3] = useState(
  //   !isEditMode ? "Monthly" : company?.gst_filing_3b_frequency.toString()
  // )

  const [logoFile, setLogoFile] = useState(company?.logo) // State to manage the selected logo file
  const [newUserImage, setNewUserImage] = useState(null)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isEditMode
      ? {
          // TabPane 1
          logo: (company && company.logo) || "",
          name: (company && company.name) || "",
          address_line_1: (company && company.address_line_1) || "",
          address_line_2: (company && company.address_line_2) || "",
          state: (company && company.state) || "",
          city: (company && company.city) || "",
          pin_code: (company && company.pin_code) || "",
          // contact_phone: (company && company.contact_phone) || "",
          // account_email: (company && company.account_email) || "",
          //TabPane 2
          gst_no: (company && company.gst_no) || "",
          pan_no: (company && company.pan_no) || "",
          cin_no: (company && company.cin_no) || "",
          // bank_name: (company && company.bank_name) || "",
          // bank_branch: (company && company.bank_branch) || "",
          // bank_account_no: (company && company.bank_account_no) || "",
          // bank_ifsc: (company && company.bank_ifsc) || "",

          // //TabPane 3
          // bill_prefix: (company && company.bill_prefix) || "",
          // bill_starting_no: (company && company.bill_starting_no) || "",
          // receipt_starting_no: (company && company.receipt_starting_no) || "",
          // expense_voucher_prefix:
          //   (company && company.expense_voucher_prefix) || "",
          // expense_voucher_starting_no:
          //   (company && company.expense_voucher_starting_no) || "",
          // payment_voucher_prefix:
          //   (company && company.payment_voucher_prefix) || "",
          // payment_voucher_starting_no:
          //   (company && company.payment_voucher_starting_no) || "",
          // receipt_prefix: (company && company.receipt_prefix) || "",
          // receipt_starting_no: (company && company.receipt_starting_no) || "",

          // //TabPane 4
          // einvoice_api_username:
          //   (company && company.einvoice_api_username) || "",
          // einvoice_api_password:
          //   (company && company.einvoice_api_password) || "",

          //TabPane 5
          is_default: 1,
          is_active: 1,
          // gst_filing_r1_frequency: company && company.gst_filing_r1_frequency,
          // gst_filing_3b_payment_frequency:
          //   company && company.gst_filing_3b_payment_frequency,
          // gst_filing_3b_frequency: company && company.gst_filing_3b_frequency,
          country: (company && company.country) || "IN",
        }
      : {
          // TabPane 1
          logo: "",
          name: "",
          address_line_1: "",
          address_line_2: "",
          state: "",
          city: "",
          pin_code: "",
          contact_phone: "",
          account_email: "",
          //TabPane 2
          // gst_no: "",
          pan_no: "",
          cin_no: "",
          // bank_name: "",
          // bank_branch: "",
          // bank_account_no: "",
          // bank_ifsc: "",

          // //TabPane 3
          // bill_prefix: "",
          // bill_starting_no: "",
          // receipt_starting_no: "",
          // expense_voucher_prefix: "",
          // expense_voucher_starting_no: "",
          // payment_voucher_prefix: "",
          // payment_voucher_starting_no: "",
          // receipt_prefix: "",
          // receipt_starting_no: "",

          // //TabPane 4
          // einvoice_api_username: "",
          // einvoice_api_password: "",

          //TabPane 5
          is_default: 1,
          is_active: 1,
          gst_filing_r1_frequency: "Monthly",
          gst_filing_3b_payment_frequency: "Monthly",
          gst_filing_3b_frequency: "Monthly",
          country: "IN",
        },

    validationSchema: Yup.object({
      //Table 1
      name: Yup.string().required("Please Enter Company Name"),
      address_line_1: Yup.string().required("Please Enter Address Line 1"),
      address_line_2: Yup.string(),
      country: Yup.string().required("Please Select Country"),
      state: Yup.number().required("Please Select State"),
      city: Yup.number().required("Please Select City"),
      // pin_code: Yup.number().required("Please Enter Pin code").min(10000,).max(999999),
      pin_code: Yup.string()
        .matches(
          /^[0-9]{6}$/,
          "Pincode must be 6 digits and only contain numbers"
        )
        .required("Please enter Pincode"),

      // contact_phone: Yup.string()
      //   .matches(
      //     /^[0-9]{10,12}$/,
      //     "Contact Number should be between 10 and 12 digits and only contain numbers"
      //   )
      //   .required("Please Enter Contact Number"),
      // account_email: Yup.string()
      //   .email("Invalid email format")
      //   .required("Please Enter Email Id"),

      // //table 2
      // gst_no: Yup.string().matches(
      //   /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/,
      //   "Invalid GST number"
      // ),
      pan_no: Yup.string().matches(
        /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/,
        "Invalid PAN card number"
      ),
      cin_no: Yup.string(),
      // bank_name: Yup.string(),
      // bank_branch: Yup.string(),
      // bank_account_no: Yup.number(),
      // bank_ifsc: Yup.string(),

      // //table 3
      // bill_prefix: Yup.string(),
      // bill_starting_no: Yup.number(),
      // expense_voucher_prefix: Yup.string(),
      // expense_voucher_starting_no: Yup.number(),
      // payment_voucher_prefix: Yup.string(),
      // payment_voucher_starting_no: Yup.number(),
      // receipt_prefix: Yup.string(),
      // receipt_starting_no: Yup.number(),

      // //table 4
      // einvoice_api_username: Yup.string(),
      // einvoice_api_password: Yup.string(),

      //table 5
      gst_filing_r1_frequency: Yup.string(),
      gst_filing_3b_payment_frequency: Yup.string(),
      gst_filing_3b_frequency: Yup.string(),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        if (isEditMode === true) {
          const response = await updateCompany(companyId, values)

          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Company has been updated.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/companies")
              }
            })
          } else {
            loadingSwal.close()

            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your work has not been saved.",
            })
          }
        } else if (isEditMode === false) {
          const response = await addNewCompany(values)
          if (response && response.status === true) {
            loadingSwal.close()

            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Your Company has been created.",
            }).then(result => {
              if (result.isConfirmed) {
                navigate("/settings/companies")
              }
            })
          } else {
            Swal.fire({
              title: "failure",
              icon: "error",
              text: "Your Company has not been saved.",
            })
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState("")
  useEffect(() => {
    dispatch(getStates(countryName))
  }, [countryName])
  useEffect(() => {
    if (validation.values.state) {
      dispatch(getCities(stateId))
    }
  }, [stateId])
  useEffect(() => {
    setLogoFile(company?.logo)
  }, [company])

  useEffect(() => {
    setCountryName(validation.values.country)
    setStateId(validation.values.state)
  }, [validation])

  const navigate = useNavigate()
  const toggleToast = () => {
    setToast(!toast)
  }
  const toggleToast2 = () => {
    setToast2(!toast2)
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  //meta title
  document.title = "Company Setup"

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 6) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  const handleNewUserImageChange = event => {
    const file = event.target.files[0]

    if (!file) {
      setNewUserImage(null)
      return
    }

    setNewUserImage(file)

    validation.setFieldValue("logo", file)

    if (file) {
      const reader = new FileReader()

      reader.onloadend = () => {
        const fileData = reader.result
        setLogoFile(fileData)
      }

      reader.readAsDataURL(file)
    }
  }

  const handleLogoFileChange = event => {
    const file = event.target.files[0]

    if (!file) {
      setLogoFile(null)
      return
    }

    setLogoFile(file)

    validation.setFieldValue("logo", file)

    if (file) {
      const reader = new FileReader()

      reader.onloadend = () => {
        const fileData = reader.result
        setLogoFile(fileData)
      }

      reader.readAsDataURL(file)
    }
  }

  const handleChange1 = event => {
    setSelectedOption1(event.target.value)

    validation.setFieldValue("gst_filing_r1_frequency", event.target.value)
  }

  const handleChange2 = event => {
    setSelectedOption2(event.target.value)
    validation.setFieldValue(
      "gst_filing_3b_payment_frequency",
      event.target.value
    )
  }

  const handleChange3 = event => {
    setSelectedOption3(event.target.value)
    validation.setFieldValue("gst_filing_3b_frequency", event.target.value)
  }

  const handleNextTab = () => {
    validation.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        toggleTab(activeTab + 1)
      } else {
        validation.setErrors(errors)
        const touchedFields = Object.keys(validation.values).reduce(
          (acc, fieldName) => {
            acc[fieldName] = true
            return acc
          },
          {}
        )
        validation.setTouched(touchedFields)
      }
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <React.Fragment>
          <i
            className="mdi mdi-arrow-left font-size-20"
            style={{ color: "grey", marginRight: "20px" }}
            onClick={() => navigate(-1)}
          ></i>
          <span style={{ color: "black", fontWeight: "500", fontSize: "18px" }}>
            {isEditMode ? "Edit Company" : "Company Setup"}
          </span>
        </React.Fragment>
        <div className="mt-3"></div>
        <Container fluid={true}>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Admin Profile
                          </NavLink>
                        </NavItem>
                        {/* <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Company Financial
                            <span className="number">2.</span> GST Filing

                            Details
                          </NavLink>
                        </NavItem> */}
                        {/* <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> Company Prefixes
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">4.</span> E-Invoice Login
                            Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 5 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 5 })}
                            onClick={() => {
                              setactiveTab(5)
                            }}
                            disabled={!(passedSteps || []).includes(5)}
                          >
                            <span className="number">5.</span> GST Filing
                            Details
                          </NavLink>
                        </NavItem> */}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            <Row>
                              <div className="mb-3">
                                <div className="framexx">
                                  <label
                                    className="rounded-2"
                                    style={{
                                      width: "150px",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                      borderRadius: "10px",
                                      border: "1px solid rgba(0, 0, 0, 0.10)",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {!isEditMode ? (
                                      <>
                                        {newUserImage ? (
                                          <img
                                            className="object-fit-fill rounded-2"
                                            src={URL.createObjectURL(
                                              newUserImage
                                            )}
                                            alt=""
                                            style={{
                                              width: "100%",
                                              borderRadius: "10px",
                                              maxHeight: "150px",
                                              minHeight: "150px",
                                            }}
                                          />
                                        ) : (
                                          <i
                                            className="mdi mdi-image-outline"
                                            style={{
                                              fontSize: "60px",
                                              padding: "30px",
                                            }}
                                          ></i>
                                        )}
                                        <input
                                          type="file"
                                          accept="image/*"
                                          onChange={handleNewUserImageChange}
                                          style={{ display: "none" }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {logoFile ? (
                                          typeof logoFile === "string" ? (
                                            <img
                                              className="object-fit-fill rounded-2"
                                              src={logoFile}
                                              alt="No Photo"
                                              style={{
                                                width: "100%",
                                                borderRadius: "10px",
                                                maxHeight: "150px",
                                                minHeight: "150px",

                                                height: "150px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              className="object-fit-fill rounded-2"
                                              src={URL.createObjectURL(
                                                logoFile
                                              )}
                                              alt=""
                                              style={{
                                                width: "100%",
                                                borderRadius: "10px",
                                                maxHeight: "150px",
                                                minHeight: "150px",
                                              }}
                                            />
                                          )
                                        ) : (
                                          <i
                                            className="mdi mdi-image-outline"
                                            style={{
                                              fontSize: "60px",
                                              padding: "30px",
                                            }}
                                          ></i>
                                        )}
                                        <input
                                          type="file"
                                          accept="image/*"
                                          onChange={handleLogoFileChange}
                                          style={{ display: "none" }}
                                        />
                                      </>
                                    )}
                                  </label>
                                  {/* </div> */}
                                  {/* <div className="divxx"> */}
                                  <Col>
                                    <div className="mb-2">
                                      <Label className="name-input">
                                        Company Name*
                                      </Label>

                                      <Input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        id="name-input"
                                        placeholder="Enter Your Company Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                          validation.touched.name &&
                                          validation.errors.name
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.name &&
                                      validation.errors.name ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.name}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                    {/* </div> */}
                                  </Col>
                                </div>
                              </div>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-address_line_1">
                                    Address Line 1*
                                  </Label>
                                  <Input
                                    type="text"
                                    id="address_line_1"
                                    name="address_line_1"
                                    className="form-control "
                                    placeholder="Enter Your Address Line 1"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.address_line_1 &&
                                      validation.errors.address_line_1
                                        ? true
                                        : false
                                    }
                                    value={
                                      validation.values.address_line_1 || ""
                                    }
                                  />
                                  {validation.touched.address_line_1 &&
                                  validation.errors.address_line_1 ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.address_line_1}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-address_line_2">
                                    Address Line 2
                                  </Label>
                                  <Input
                                    type="text"
                                    id="address_line_2"
                                    className="form-control"
                                    placeholder="Enter Your Address Line 2"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.address_line_2 || ""
                                    }
                                    invalid={
                                      validation.touched.address_line_2 &&
                                      validation.errors.address_line_2
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.address_line_2 &&
                                  validation.errors.address_line_2 ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.address_line_2}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="country">Country*</Label>
                                  <select
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    // defaultValue="IN"
                                    value={validation.values.country}
                                    name="country"
                                    className={`form-select ${
                                      validation.touched.country &&
                                      validation.errors.country
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  >
                                    {!validation.values.country && (
                                      <option>Enter Your Country</option>
                                    )}

                                    {(countries || []).map(item => (
                                      <option key={item.id} value={item.id}>
                                        {item.country_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="stateno-input">State*</Label>
                                  <select
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    name="state"
                                    value={validation.values.state}
                                    className={`form-select ${
                                      validation.touched.state &&
                                      validation.errors.state
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  >
                                    {!validation.values.state && (
                                      <option>Enter Your States</option>
                                    )}

                                    {(states || []).map(item => (
                                      <option key={item.id} value={item.id}>
                                        {item.state_name}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.touched.state &&
                                  validation.errors.state ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.state}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-email-input4">
                                    City*
                                  </Label>
                                  <select
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    name="city"
                                    value={validation.values.city}
                                    className={`form-select ${
                                      validation.touched.city &&
                                      validation.errors.city
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  >
                                    {!validation.values.city && (
                                      <option>Enter Your City</option>
                                    )}

                                    {(cities || []).map(item => (
                                      <option key={item.id} value={item.id}>
                                        {item.city_name}
                                      </option>
                                    ))}
                                  </select>
                                  {validation.touched.city &&
                                  validation.errors.city ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.city}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="pin_code">Pincode*</Label>
                                  <Input
                                    type="text"
                                    name="pin_code"
                                    className="form-control"
                                    id="pin_code"
                                    placeholder="Enter Your Pincode"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.pin_code || ""}
                                    invalid={
                                      validation.touched.pin_code &&
                                      validation.errors.pin_code
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.pin_code &&
                                  validation.errors.pin_code ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.pin_code}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="contact_phone-input3">
                                    Contact No.*
                                  </Label>
                                  <Input
                                    type="text"
                                    name="contact_phone"
                                    className="form-control"
                                    id="contact_phone-input3"
                                    placeholder="Enter Your Contact No."
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.contact_phone || ""
                                    }
                                    invalid={
                                      validation.touched.contact_phone &&
                                      validation.errors.contact_phone
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.contact_phone &&
                                  validation.errors.contact_phone ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.contact_phone}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="Email-input3">
                                    Account Email Id*
                                  </Label>
                                  <Input
                                    type="text"
                                    name="account_email"
                                    className="form-control"
                                    id="Email-input3"
                                    placeholder="example@mail.com"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.account_email || ""
                                    }
                                    invalid={
                                      validation.touched.account_email &&
                                      validation.errors.account_email
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.account_email &&
                                  validation.errors.account_email ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.account_email}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row> */}
                            <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-cstno-input7">
                                      CIN No.
                                    </Label>
                                    <Input
                                      type="text"
                                      name="cin_no"
                                      className="form-control"
                                      id="basicpill-cstno-input7"
                                      placeholder="Enter Your CIN No."
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.cin_no || ""}
                                      invalid={
                                        validation.touched.cin_no &&
                                        validation.errors.cin_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.cin_no &&
                                    validation.errors.cin_no ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.cin_no}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-vatno-input6">
                                      Business PAN No.
                                    </Label>
                                    <Input
                                      type="text"
                                      name="pan_no"
                                      className="form-control"
                                      id="basicpill-vatno-input6"
                                      placeholder="Enter Your Business PAN No."
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.pan_no || ""}
                                      invalid={
                                        validation.touched.pan_no &&
                                        validation.errors.pan_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.pan_no &&
                                    validation.errors.pan_no ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.pan_no}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                          </Form>
                        </TabPane>
                      
                        <TabPane tabId={2}>
                          <Row>
                            <div className="row justify-content-center">
                              <Col lg="6">
                                <div className="mb-4">
                                  <Label
                                    className="d-block mb-3"
                                    htmlFor="r1frequency"
                                  >
                                    R1 Frequency*
                                  </Label>
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="r1frequency_monthly"
                                      name="gst_filing_r1_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Monthly"
                                      onChange={e => {
                                        handleChange1(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_r1_frequency === "Monthly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_r1_frequency &&
                                        validation.errors
                                          .gst_filing_r1_frequency
                                          ? true
                                          : false
                                      }
                                      // checked={validation.values.gst_filing_r1_frequency === "Monthly"}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="r1frequency_monthly"
                                    >
                                      Monthly
                                    </Label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="r1frequency_quaterly"
                                      name="gst_filing_r1_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Quaterly"
                                      onChange={e => {
                                        handleChange1(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_r1_frequency ===
                                        "Quaterly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_r1_frequency &&
                                        validation.errors
                                          .gst_filing_r1_frequency
                                          ? true
                                          : false
                                      }
                                      // checked={validation.values.gst_filing_r1_frequency === "Quaterly" ? true : false}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="r1frequency_quaterly"
                                    >
                                      Quarterly
                                    </Label>
                                  </div>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-4">
                                  <Label
                                    className="d-block mb-3"
                                    htmlFor="3bpayment"
                                  >
                                    3B Payment*
                                  </Label>
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="3bpayment_monthly"
                                      name="gst_filing_3b_payment_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Monthly"
                                      onChange={e => {
                                        handleChange2(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_3b_payment_frequency ===
                                        "Monthly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_3b_payment_frequency &&
                                        validation.errors
                                          .gst_filing_3b_payment_frequency
                                          ? true
                                          : false
                                      }
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="3bpayment_monthly"
                                    >
                                      Monthly
                                    </Label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="3bpayment_quaterly"
                                      name="gst_filing_3b_payment_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Quaterly"
                                      onChange={e => {
                                        handleChange2(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_3b_payment_frequency ===
                                        "Quaterly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_3b_payment_frequency &&
                                        validation.errors
                                          .gst_filing_3b_payment_frequency
                                          ? true
                                          : false
                                      }
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="3bpayment_quaterly"
                                    >
                                      Quarterly
                                    </Label>
                                  </div>
                                  <div
                                    className="form-check form-check-inline"
                                    style={{ clear: "both" }}
                                  >
                                    {/* This div with "clear: both" will force the next content to start on a new line */}
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className="row justify-content">
                              <Col lg="6">
                                <div className="mb-4">
                                  <Label className="d-block mb-3">
                                    3B Frequency*
                                  </Label>
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="3bpayment_monthly"
                                      name="gst_filing_3b_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Monthly"
                                      onChange={e => {
                                        handleChange3(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_3b_frequency === "Monthly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_3b_frequency &&
                                        validation.errors
                                          .gst_filing_3b_frequency
                                          ? true
                                          : false
                                      }
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="3bpayment_monthly"
                                    >
                                      Monthly
                                    </Label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check">
                                    <Input
                                      type="radio"
                                      id="3bpayment_quaterly"
                                      name="gst_filing_3b_frequency"
                                      className="form-check-input"
                                      // onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value="Quaterly"
                                      onChange={e => {
                                        handleChange3(e)
                                      }}
                                      checked={
                                        validation.values
                                          .gst_filing_3b_frequency ===
                                        "Quaterly"
                                      }
                                      invalid={
                                        validation.touched
                                          .gst_filing_3b_frequency &&
                                        validation.errors
                                          .gst_filing_3b_frequency
                                          ? true
                                          : false
                                      }
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="3bpayment_quaterly"
                                    >
                                      Quarterly
                                    </Label>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </Row>
                        </TabPane>
                        {/* <TabPane tabId={6}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Confirm Detail</h5>
                                  <p className="text-muted">
                                    Please Check your Details.
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane> */}
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        
                        <li
                          className={activeTab === 2 ? "next disabled" : "next"}
                        >
                          {activeTab === 1 ? (
                            <Link to="#" onClick={validation.handleSubmit}>
                              Submit
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              // onClick={() => {
                              //   toggleTab(activeTab + 1)
                              // }}
                              onClick={handleNextTab}
                            >
                              Next
                            </Link>
                          )}
                        </li>
                        {/* {toast2 && (
                          <div
                            className="position-fixed top-0 end-0 p-3 "
                            style={{ zIndex: "1005" }}
                          >
                            <Toast isOpen={toast2} role="aleart">
                              <ToastHeader toggle={() => setToast2(!toast2)}>
                                <img
                                  src={VLogo}
                                  alt=""
                                  className="me-2"
                                  height="18"
                                />
                                <strong className="me-auto">VIP Mall</strong>
                              </ToastHeader>
                              <ToastBody>
                                {!isEditMode
                                  ? "Submitted Successfully"
                                  : "Edited Successfully"}
                              </ToastBody>
                            </Toast>
                          </div>
                        )} */}
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default accountsetuppage
