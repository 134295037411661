import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  FormFeedback,
  Col,
  Row,
  Label,
  Form,
  Input,
  Card,
  CardBody,
  Modal,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import Swal from "sweetalert2"

import {
  getMaterialMasterForServiceRequest,
  getServiceRequestTicketDetail,
  getUnitMasterForServiceRequest,
} from "store/actions"
import { addOtherCostInTicket, sendForReviewInTicket, updateStatusInTicket } from "helpers/fakebackend_helper"
import Dropzone from "react-dropzone"

const LargeModal = ({ isOpen, toggle, id }) => {
  const dispatch = useDispatch()

  const materialMasterState = state => state.serviceRequests.materials.data
  const materialMaster = useSelector(materialMasterState) || []

  const unitMasterState = state => state.serviceRequests.units.data
  const unitMaster = useSelector(unitMasterState) || []

  useEffect(() => {
    dispatch(getMaterialMasterForServiceRequest())
    dispatch(getUnitMasterForServiceRequest())
  }, [dispatch])


  
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status:11,
      reject_description:'',
    },

    validationSchema: Yup.object({}),
    onSubmit: async values => {
      let loadingSwal
      try {
        loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })

        const response = await updateStatusInTicket(id, values)

        if (response && response.status === true) {
          loadingSwal.close()

          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Rejected Successfully",
          }).then(result => {
            if (result.isConfirmed) {
              dispatch(getServiceRequestTicketDetail(id))
              toggle()
            }
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "Failure",
            icon: "error",
            text: "Your Reject Request has not been Submitted.",
          })
        }
      } catch (error) {
        console.error("Error:", error)
        if (loadingSwal) {
          loadingSwal.close()
        }

        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response?.data?.message ||
            "An error occurred while Submitting.",
        })
      }
    },
  })
  console.log(validation.values)

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Rework 
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Card>
          <CardBody>
            <Form
              autoComplete="off"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Reason for Rejection</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Input
                            type="textarea"
                            name={`reject_description`}
                            onChange={validation.handleChange}
                            placeholder="Enter Reason for Rework"
                          />
                        </td>

                       
                       
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Row>
              <Row>
                <div className="mb-3 mt-4 text-center">
                  <button type="submit" className="btn btn-primary ">
                     Reject
                  </button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  )
}

export default LargeModal
