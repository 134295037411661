import {
  GET_PROFILE_DETAIL_FAIL,
  GET_PROFILE_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  detail: [],
  error: {},
  loading: true,
}

const myProfile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: true,
        // loading: true
      }

    case GET_PROFILE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default myProfile
